import { Chip } from "@mui/material";
import styled, { DefaultTheme } from "styled-components";

interface StyledChipProps {
  $isWarning?: boolean;
  $isDisabled?: boolean;
  $isEdited?: boolean;
  $isError?: boolean;
}

function getBorder(
  theme: DefaultTheme,
  isWarning?: boolean,
  isEdited?: boolean,
  isError?: boolean
) {
  if (isError) {
    return `1px solid ${theme.palettes.secondary.error};`;
  }
  if (isWarning) {
    return `1px solid ${theme.palettes.secondary.warning}`;
  }
  if (isEdited) return `1px solid ${theme.palettes.secondary.info}`;
  return "unset";
}

export const StyledChip = styled(Chip)<StyledChipProps>`
  &.MuiChip-root {
    border: ${(props) =>
      getBorder(
        props.theme,
        props.$isWarning,
        props.$isEdited,
        props.$isError
      )};
    height: 100%;
    color: ${(props) => props.theme.palettes.grayscale.darkest};
  }
`;
