import { includes, sortBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components";
import { isSecurityLevelNonLimited } from "../../../../app/helpers/securityLevelHelper";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { useUserCanEditDocument } from "../../../../app/hooks/permissions/useUserCanEditDocument";
import { selectIsLoadingDetailsMetaData } from "../../../../app/slices/documentDetailsSlice";
import {
  clearEditedDocumentSecurity,
  selectCurrentDocumentAccessGroups,
  selectCurrentDocumentMainCategory,
  selectCurrentDocumentSecurityLevel,
  selectDocumentMainCategory,
  setEditedAccessGroup,
} from "../../../../app/slices/documentMetadataSlice";
import {
  selectAccessGroupsValidationStatus,
  selectSecuritySectionStatus,
} from "../../../../app/slices/documentMetadataValidationSlice";
import { SectionName } from "../../../../app/slices/models/documentDetailsModels";
import { CircularProgressBar } from "../../../../controls/ProgressIndicators/CircularProgressBar";
import { CurrentSelfHelp } from "../../../../models/CurrentSelfHelpType";
import { ComboBoxListItem } from "../../../../models/autocompleteValue";
import { ValidationStatus } from "../../../../models/validationRule";
import { DetailsAutoComplete } from "../../inputs/DetailsAutoComplete";
import { DetailsSection, DetailsSectionProps } from "../DetailsSection";
import SecurityLevelControl from "./SecurityLevelControl";
import AccessGroupsValidation from "./validation/AccessGroupsValidation";
import { initialEditableState } from "../../../../app/hooks/permissions/models/state";
import { useDocumentFlow } from "../../../../app/hooks/document/useDocumentFlow";
import { useDocumentEditedMode } from "../../../../app/hooks/document/useDocumentEditedMode";
import { useDocumentDetailsAccessGroups } from "../../../../app/hooks/document/useDocumentDetailsAccessGroups";

export function SecuritySection(props: DetailsSectionProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const mainCategory = useAppSelector(selectDocumentMainCategory);
  const currentMainCategory = useAppSelector(selectCurrentDocumentMainCategory);

  const accessGroupDocumentMetadata = useAppSelector(
    selectCurrentDocumentAccessGroups
  );
  const securityLevelDocumentMetadata = useAppSelector(
    selectCurrentDocumentSecurityLevel
  );

  const isLoadingDetailsMetaData = useAppSelector(
    selectIsLoadingDetailsMetaData
  );
  const [editableState, setEditableState] = useState(initialEditableState);
  const { canEditDocument } = useUserCanEditDocument();
  const {
    fetchAccessGroups,
    accessGroups,
    isAccessGroupsLoading,
    isAccessGroupsError,
  } = useDocumentDetailsAccessGroups();

  useEffect(() => {
    setEditableState(canEditDocument(true));
  }, [canEditDocument]);
  const { securitySection } = useDocumentEditedMode();
  const accessGroupsValidationStatus = useAppSelector(
    selectAccessGroupsValidationStatus
  );
  const securitySectionStatus = useAppSelector(selectSecuritySectionStatus);
  const isValidationWarning =
    securitySectionStatus === ValidationStatus.warning;
  const isValidationError = securitySectionStatus === ValidationStatus.error;
  const isLoading = useMemo(
    () => isLoadingDetailsMetaData || isAccessGroupsLoading,
    [isLoadingDetailsMetaData, isAccessGroupsLoading]
  );
  const { isNewDocument } = useDocumentFlow();

  const documentOnlyAccessGroups = useMemo(
    () => accessGroups.filter((x) => x.isOnlyInDocument).map((x) => x.name),
    [accessGroups]
  );

  const handleRefreshView = useCallback(() => {
    fetchAccessGroups(true);
  }, [fetchAccessGroups]);

  const onAccessGroupChangeHandler = useCallback(
    (newValues: ComboBoxListItem[]) => {
      dispatch(setEditedAccessGroup(newValues));
    },
    [dispatch]
  );

  const mapAccessGroups = useCallback(
    (
      accessGroups: string[],
      editedAccessGroups?: string[],
      documentOnlyAccessGroups?: string[],
      allAccessGroups?: string[]
    ) => {
      const accessGroupItems = accessGroups.map<ComboBoxListItem>((value) => {
        return {
          key: value,
          value: value,
          isEdited: includes(editedAccessGroups, value),
          isWarning:
            includes(documentOnlyAccessGroups, value) ||
            !includes(allAccessGroups, value),
        };
      });

      return sortBy(accessGroupItems, ["isEdited", "value"]);
    },
    []
  );

  const availableAccessGroups = useMemo(
    () =>
      mapAccessGroups(
        accessGroups
          .filter(
            (x) =>
              !accessGroupDocumentMetadata.includes(x.name) &&
              !x.isOnlyInDocument
          )
          .map((x) => x.name)
      ),
    [accessGroupDocumentMetadata, accessGroups, mapAccessGroups]
  );

  const selectedAccessGroups = useMemo(
    () =>
      mapAccessGroups(
        accessGroupDocumentMetadata,
        securitySection.editedAccessGroups,
        documentOnlyAccessGroups,
        accessGroups.map((x) => x.name)
      ),
    [
      accessGroupDocumentMetadata,
      securitySection.editedAccessGroups,
      documentOnlyAccessGroups,
      mapAccessGroups,
      accessGroups,
    ]
  );

  const discardSecurityChanges = useCallback(() => {
    dispatch(clearEditedDocumentSecurity());
  }, [dispatch]);

  const disableLimitedSecurityLevels = useMemo(() => {
    if (isLoading) {
      return false;
    }

    if (isNewDocument) {
      return false;
    }

    const validSelectedAccessGroups = selectedAccessGroups.filter(
      (value) => !value.isWarning
    );
    if (
      validSelectedAccessGroups.length === 0 &&
      availableAccessGroups.length === 0
    ) {
      return true;
    }

    return false;
  }, [
    isLoading,
    selectedAccessGroups,
    availableAccessGroups.length,
    isNewDocument,
  ]);

  useEffect(() => {
    fetchAccessGroups(currentMainCategory === mainCategory);
  }, [fetchAccessGroups, currentMainCategory, mainCategory, dispatch]);

  const noOptionText = useMemo(() => {
    if (isNewDocument && !currentMainCategory) {
      return "Main category is not selected. Please select Main category in order to load dictionary data.";
    }
  }, [isNewDocument, currentMainCategory]);

  return (
    <DetailsSection
      {...props}
      section={SectionName.Security}
      name="Security"
      selfHelpType={CurrentSelfHelp.DetailsSecurity}
      isEdited={
        (securitySection.securitySectionIsEdited ||
          securitySection.editedAccessGroups.length > 0) &&
        !isLoading
      }
      isLoadingDetailsMetaData={isLoading}
      onDiscardChanges={discardSecurityChanges}
      isValidationError={isValidationError && !isLoading}
      isWarning={isValidationWarning && !isLoading}
      isEditable={editableState.isEditable}
      notEditableReason={editableState.lockMessage}
    >
      {isLoadingDetailsMetaData ? (
        <CircularProgressBar
          size={theme.circularProgress.medium}
          space={theme.circularProgressWrapper.defaultHeight}
          color="secondary"
        />
      ) : (
        <>
          <SecurityLevelControl
            disableLimitedSecurityLevels={disableLimitedSecurityLevels}
            selectedAccessGroups={selectedAccessGroups}
          />
          {!isSecurityLevelNonLimited(securityLevelDocumentMetadata) && (
            <DetailsAutoComplete
              id="access-groups"
              pillsClassName="access-group-pill"
              labelText="Access Groups"
              onRefreshHandler={handleRefreshView}
              isLoading={isAccessGroupsLoading}
              isDictionaryDataFetchingError={isAccessGroupsError}
              selected={selectedAccessGroups}
              items={availableAccessGroups}
              isEditable={editableState.isEditable}
              isDisabled={!editableState.isEditable}
              multiple={true}
              onChangeHandler={onAccessGroupChangeHandler}
              isEdited={securitySection.accessGroupIsEdited}
              notEditableMessage={editableState.lockMessage}
              omitDataQualityCheck={true}
              isError={
                accessGroupsValidationStatus === ValidationStatus.error &&
                !isLoading
              }
              isWarning={
                accessGroupsValidationStatus === ValidationStatus.warning &&
                !isLoading
              }
              errorMessages={[]}
              noOptionText={noOptionText}
            />
          )}
          <AccessGroupsValidation
            selectedAccessGroups={selectedAccessGroups}
            isLoadingAccessGroups={isAccessGroupsLoading}
          />
        </>
      )}
    </DetailsSection>
  );
}
