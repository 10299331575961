import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function AboutStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The Scheduled Action feature is used to plan actions on a document and
        to notify specified recipients about them.
      </Paragraph>
    </StepContentDiv>
  );
}
