import { CircularProgress } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useTheme } from "styled-components";
import { useLazyGetTagCategoriesQuery } from "../../../apis/tagsApi";
import {
  ErrorContainerProps,
  ErrorDisplay,
} from "../../../app/errorHandling/ErrorDisplay";
import { openSnackbar } from "../../../app/helpers/snackBarHelper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import { SectionName } from "../../../app/slices/models/documentDetailsModels";
import { selectSelectedTag } from "../../../app/slices/tagsViewSlice";
import { PaperControl } from "../../../controls/Paper/PaperControl";
import { PaperContentDiv } from "../../../controls/Paper/SC/PaperContentDiv";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../models/selfHelp/selfHelpSection";
import { SnackbarType } from "../../../models/snackbar";
import { NoTagsFound } from "../TagValues/NoTagsFound";
import { Breadcrumb } from "./Braedcrumb/Breadcrumb";
import { NoCategoriesFound } from "./NoCategoriesFound";
import { GroupsColumnDiv } from "./SC/GroupsColumnDiv";
import { GroupsList } from "./SC/GroupsList";
import { PaperContentCenterDiv } from "./SC/PaperContentCenterDiv";

export interface Category {
  id: string;
  name: string;
  breadcrumb: string[];
}

const errorContainerStyles: ErrorContainerProps = {
  padding: "0px",
};

function CategoriesGroupColumn() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const selectedTag = useAppSelector(selectSelectedTag);
  const [
    getTagCategories,
    { data: categories, isError, isLoading, isFetching, isUninitialized },
  ] = useLazyGetTagCategoriesQuery();

  const fetchCategories = useCallback(() => {
    if (selectedTag?.id) {
      void getTagCategories(selectedTag.id, true);
    }
  }, [selectedTag?.id, getTagCategories]);

  useEffect(() => {
    if (isError) {
      openSnackbar(
        dispatch,
        "Error while loading Tag categories",
        SnackbarType.error
      );
    }
  }, [isError, dispatch]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const getContent = () => {
    if (!selectedTag) {
      return (
        <PaperContentCenterDiv id="tag-categories-no-content-div">
          <NoTagsFound
            title="No Tag selected"
            description="Please select a Tag to display its values"
          />
        </PaperContentCenterDiv>
      );
    }

    if (isLoading || isFetching || isUninitialized) {
      return (
        <PaperContentCenterDiv>
          <CircularProgress
            sx={{ color: theme.palettes.primary.main }}
            size="30px"
          />
        </PaperContentCenterDiv>
      );
    }

    if (isError) {
      return (
        <PaperContentCenterDiv>
          <ErrorDisplay
            errorId={"categories"}
            errorMessageTitle={"Something went wrong"}
            errorDescription={"Error while loading categories."}
            refreshFunction={fetchCategories}
            showReloadButton={true}
            showDash={false}
            doCenterHeader={true}
            errorContainerProps={errorContainerStyles}
          />
        </PaperContentCenterDiv>
      );
    }

    if (categories?.length === 0 || !categories) {
      return (
        <PaperContentCenterDiv id="categories-not-found-div">
          <NoCategoriesFound />
        </PaperContentCenterDiv>
      );
    }

    return (
      <PaperContentDiv id="categories-content-div">
        <GroupsList id={"categories-list"} dense={true}>
          {categories.map((c) => (
            <Breadcrumb
              key={c.id}
              categoryId={c.id}
              separator=">"
              categories={c.path}
              tagId={selectedTag.id}
            />
          ))}
        </GroupsList>
      </PaperContentDiv>
    );
  };

  return (
    <GroupsColumnDiv width={100} id={"tag-categories-column"}>
      <PaperControl
        title={"Categories"}
        currentSelfHelp={CurrentSelfHelp.TagCategories}
        selfHelpPage={SelfHelpPage.Tags}
        selfHelpSectionName={SectionName.Tags}
        useHeader={true}
      >
        {getContent()}
      </PaperControl>
    </GroupsColumnDiv>
  );
}

export default CategoriesGroupColumn;
