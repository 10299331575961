import { Stack } from "@mui/material";
import {
  getGridStringOperators,
  GridColDef,
  GridColumnVisibilityModel,
  GridComparatorFn,
  GridFilterModel,
  GridRenderCellParams,
  GridRowsProp,
  GridSlots,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import { setSelectedNode } from "../../../app/slices/ownerCategoryTreeSlice";
import {
  selectPublisherDirectCategoriesDensity,
  selectPublishersDirectCategoriesColumnVisibility,
  selectPublishersDirectCategoriesPageSize,
  setPublisherDirectCategoriesColumnVisibility,
  setPublisherDirectCategoriesDensity,
  setPublisherDirectCategoriesPageSize,
} from "../../../app/slices/publisherDirectCategoriesSlice";
import {
  setSelectedBreadcrumbCategory,
  setSelectedCategoryId,
  setSelectedCategoryName,
} from "../../../app/slices/publishersListSlice";
import {
  setPublishersTabType,
  setSelectedPublisher,
} from "../../../app/slices/publishersViewSlice";
import { DirectPublisherCategory } from "../../../models/directPublisherCategory";
import { Person } from "../../../models/person";
import {
  PublisherRole,
  PublisherRoleType,
} from "../../../models/publisherRoles";
import {
  getRoleTypeOperator,
  gridWrapOperator,
  rolesComparer,
} from "../helpers/dataGridHelpers";
import { usePublisherDirectCategories } from "../helpers/usePublisherDirectCategories";
import { PublishersTabType } from "../Publishers";
import { Loader } from "../tabs/categoryTab/publishers/TableControl/Loader";
import { OnError } from "../tabs/categoryTab/publishers/TableControl/OnError";
import { StyledGridColumnsPanel } from "../tabs/categoryTab/publishers/TableControl/SC/StyledGridColumnsPanel";
import { StyledGridFilterPanel } from "../tabs/categoryTab/publishers/TableControl/SC/StyledGridFilterPanel";
import { RoleCell } from "../tabs/categoryTab/publishers/TableControl/TableCell/RoleCell";
import { TableToolbar } from "../tabs/categoryTab/publishers/TableControl/TableToolbar";
import { Breadcrumb } from "./categoryInfo/breadcrumb/Breadcrumb";
import { NoResults } from "./NoResults";
import { StyledCategoriesDataGrid } from "./SC/StyledCategoriesDataGrid";
import { SectionName } from "../../../app/slices/models/documentDetailsModels";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../models/selfHelp/selfHelpSection";

enum GridColumns {
  Category = "Category",
  Publisher = "Publisher",
  SuperUser = "Super User",
  Approver = "Approver",
  Owner = "Owner",
}

interface PublisherCategoriesProps {
  person: Person;
}

const roleColumnNames = Object.values(GridColumns).filter(
  (x) => x !== GridColumns.Category
);

const categoryNameComparer: GridComparatorFn<DirectPublisherCategory> = (
  v1,
  v2
) =>
  v1.categoryPath
    .map((x) => x.name)
    .join("")
    .toLocaleLowerCase()
    .localeCompare(
      v2.categoryPath
        .map((x) => x.name)
        .join("")
        .toLocaleLowerCase()
    );

export function PublisherCategories(props: PublisherCategoriesProps) {
  const dispatch = useAppDispatch();
  const pageSize = useAppSelector(selectPublishersDirectCategoriesPageSize);
  const density = useAppSelector(selectPublisherDirectCategoriesDensity);

  const columnVisibilityModel = useAppSelector(
    selectPublishersDirectCategoriesColumnVisibility
  );
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [currentFilterField, setCurrentFilterField] = useState(
    GridColumns.Category as string
  );
  const [previousFilterModels, setPreviousFilterModels] = useState<
    GridFilterModel[]
  >([]);
  const {
    publisherCategories,
    isLoadingCategories,
    isCategoriesError,
    isNotFoundError,
    fetchCategories,
  } = usePublisherDirectCategories(props.person.email);

  const onFilterModelChange = (newFilterModel: GridFilterModel) => {
    if (
      newFilterModel.items.length === 1 &&
      newFilterModel.items[0].field !== currentFilterField
    ) {
      const newFilter = newFilterModel.items[0];
      setFilterModel(
        previousFilterModels.find(
          (x) => x.items.length === 1 && x.items[0].field === newFilter.field
        ) ?? newFilterModel
      );
      setPreviousFilterModels((prev) =>
        prev
          .filter(
            (x) =>
              x.items.length === 1 && x.items[0].field !== currentFilterField
          )
          .concat(filterModel)
      );
      setCurrentFilterField(newFilter.field);
    } else {
      setFilterModel(newFilterModel);

      if (newFilterModel.items.length > 0) {
        setCurrentFilterField(newFilterModel.items[0].field);
      }
    }
  };

  useEffect(() => {
    const gridFilterModels: GridFilterModel[] = [];

    for (const columnName of roleColumnNames) {
      gridFilterModels.push({
        items: [
          {
            field: columnName,
            operator: "In",
            value: [PublisherRoleType.Assigned, PublisherRoleType.NotAssigned],
          },
        ],
      });
    }

    setPreviousFilterModels(gridFilterModels);
  }, []);

  useEffect(() => {
    if (!columnVisibilityModel) {
      const newColumnVisibilityModel: GridColumnVisibilityModel = {};

      for (const columnName of roleColumnNames) {
        newColumnVisibilityModel[columnName] = true;
      }
      newColumnVisibilityModel[GridColumns.Category] = true;
      newColumnVisibilityModel[GridColumns.Approver] = true;

      dispatch(
        setPublisherDirectCategoriesColumnVisibility(newColumnVisibilityModel)
      );
    }
  }, [columnVisibilityModel, dispatch]);

  const onCategoryNameClick = useCallback(
    (categoryId: string, categoryName: string) => {
      dispatch(setPublishersTabType(PublishersTabType.Category));
      dispatch(setSelectedPublisher(undefined));
      dispatch(setSelectedNode(categoryId));
      dispatch(setSelectedCategoryId(categoryId));
      dispatch(setSelectedBreadcrumbCategory(categoryId));
      dispatch(setSelectedCategoryName(categoryName));
    },
    [dispatch]
  );

  const columns: GridColDef[] = useMemo(() => {
    const columns: GridColDef[] = [
      {
        field: GridColumns.Category,
        headerName: GridColumns.Category,
        renderCell: (
          params: GridRenderCellParams<
            DirectPublisherCategory,
            DirectPublisherCategory
          >
        ) => (
          <Breadcrumb
            isGray={!params.value?.hasOwnerAccess}
            categoryId={params.value?.categoryId ?? ""}
            separator={">"}
            categories={
              params.value?.categoryPath.map((x) => ({
                categoryId: x.categoryId,
                categoryTranslation: x.name,
                isDeleted: x.isDeleted,
                isInternalVisibleOnly: x.isInternalVisibleOnly,
                lifeCyclePhase: x.lifeCyclePhase,
              })) ?? []
            }
            onClick={onCategoryNameClick}
          />
        ),
        flex: 2,
        hideable: false,
        minWidth: 400,
        sortComparator: categoryNameComparer,
        filterOperators: getGridStringOperators().map((operator) =>
          gridWrapOperator(operator, (value) => {
            const category = value as DirectPublisherCategory | undefined;

            if (category) {
              return category.categoryName;
            }

            return undefined;
          })
        ),
      },
    ];

    for (const columnName of roleColumnNames) {
      columns.push({
        field: columnName,
        headerName: columnName,
        renderCell: (
          params: GridRenderCellParams<PublisherRole, PublisherRole>
        ) => {
          const publisherRole = params.value;
          if (publisherRole) {
            return <RoleCell {...publisherRole} />;
          }

          return <></>;
        },
        flex: 0.5,
        hideable: columnName !== GridColumns.Approver,
        maxWidth: 150,
        filterOperators: getRoleTypeOperator(
          Object.values(PublisherRoleType).filter(
            (x) => x !== PublisherRoleType.Inherited
          )
        ),
        sortComparator: rolesComparer,
      });
    }

    return columns;
  }, [onCategoryNameClick]);

  const rows: GridRowsProp = useMemo(() => {
    if (!publisherCategories) {
      return [];
    }

    let gridProps: GridRowsProp = [];
    for (const [i, row] of publisherCategories.entries()) {
      const rowModel: GridValidRowModel = {
        id: i,
      };
      rowModel[GridColumns.Category] = row;

      for (const columnName of roleColumnNames) {
        const name = columnName.split(" ").join("");
        const foundRole = row.roles.find((x) => x.toString() === name);
        rowModel[columnName] = {
          role: foundRole,
          assignedInCategoryId: row.categoryId,
          assignedInCategoryName: "",
          assignedInCategoryPath: "",
          roleType: foundRole
            ? PublisherRoleType.Assigned
            : PublisherRoleType.NotAssigned,
        };
      }
      gridProps = gridProps.concat(rowModel);
    }

    return gridProps;
  }, [publisherCategories]);

  return (
    <StyledCategoriesDataGrid
      disableRowSelectionOnClick
      rows={isNotFoundError ? [] : rows}
      columns={columns}
      filterModel={filterModel}
      onFilterModelChange={onFilterModelChange}
      onPaginationModelChange={(newModel) =>
        dispatch(setPublisherDirectCategoriesPageSize(newModel.pageSize))
      }
      onDensityChange={(newDensity) =>
        dispatch(setPublisherDirectCategoriesDensity(newDensity))
      }
      density={density}
      initialState={{
        pagination: { paginationModel: { pageSize: pageSize } },
        sorting: {
          sortModel: [
            {
              field: GridColumns.Category,
              sort: "asc",
            },
          ],
        },
      }}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(newModel) => {
        dispatch(setPublisherDirectCategoriesColumnVisibility(newModel));
      }}
      slots={{
        noRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No results found.
          </Stack>
        ),
        toolbar: TableToolbar,
        columnsPanel: StyledGridColumnsPanel as GridSlots["columnsPanel"],
        filterPanel: StyledGridFilterPanel,
        loadingOverlay: () => {
          if (isLoadingCategories) {
            return <Loader />;
          }

          if (isCategoriesError) {
            return (
              <OnError
                refreshFn={fetchCategories}
                errorId="publisher-categories"
                errorDescription="Error while loading publisher categories"
              />
            );
          }

          if (isNotFoundError) {
            return <NoResults />;
          }

          return <></>;
        },
      }}
      slotProps={{
        toolbar: {
          person: props.person,
          displaySearch: true,
          currentSelfHelp: CurrentSelfHelp.PublishersPublisher,
          selfHelpPage: SelfHelpPage.Publishers,
          sectionName: SectionName.PublishersPublisher,
        },
      }}
      getRowHeight={() => "auto"}
      getEstimatedRowHeight={() => 25}
      pageSizeOptions={[10, 25, 50, 100]}
      loading={isLoadingCategories || isCategoriesError || isNotFoundError}
    />
  );
}
