import { createApi } from "@reduxjs/toolkit/query/react";
import { DirectPublisherCategory } from "../models/directPublisherCategory";
import { PublisherRoles } from "../models/publisherRoles";
import { publishApiBaseQuery } from "./baseQueries";

export const publishersRolesApi = createApi({
  reducerPath: "publishersRolesApi",
  baseQuery: publishApiBaseQuery,
  endpoints: (builder) => ({
    getPublishersRolesList: builder.query<PublisherRoles[], string>({
      query: (categoryId) => ({
        url: `Publishers/Categories/${categoryId}`,
        method: "GET",
        keepUnusedDataFor: 0,
      }),
    }),
    getDirectPublisherCategories: builder.query<
      DirectPublisherCategory[],
      string
    >({
      query: (email) => ({
        url: `Publishers/${email}/Categories`,
        method: "GET",
        keepUnusedDataFor: 0,
      }),
    }),
  }),
});

export const {
  useLazyGetPublishersRolesListQuery,
  useLazyGetDirectPublisherCategoriesQuery,
} = publishersRolesApi;

export default publishersRolesApi.reducer;
