import { StepContentDiv } from "../../../SC/StepContentDiv";

export function VerticalSplitterStep() {
  return (
    <StepContentDiv>
      The Navigation and documents list section can be resized by dragging and
      dropping this splitter.
    </StepContentDiv>
  );
}
