import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot_ds from "../../../screenshots/securityLevel/ds_sec_lvl.png";
import { Screenshot } from "../../../SC/Screenshot";

export function SectionStepThree() {
    return (
        <StepContentDiv>
            <Paragraph>
                In the Download Section, documents with non - public access are marked as &apos;For approved users only&apos;.
                <Screenshot src={screenshot_ds} />
            </Paragraph>
        </StepContentDiv>
    );
}
