import styled from "styled-components";
import {
  getSectionHeaderColor,
  getValidationStatusText,
} from "../../../../app/helpers/validationHelper";

export interface SectionHeaderStatusProps {
  isValidationError?: boolean;
  isWarning?: boolean;
  isEdited: boolean;
  isCompactView: boolean;
  setValidationErrorText: (state: string | undefined) => void;
  setValidationErrorColor: (state: string | undefined) => void;
}

export const SectionValidationStatusText = styled.span<{ color: string }>`
  white-space: nowrap;
  color: ${(props) => props.color};
`;

const SectionHeaderStatus = (props: SectionHeaderStatusProps) => {
  const showValidationText = () => {
    return getValidationStatusText(
      props.isValidationError,
      props.isEdited,
      props.isWarning
    );
  };

  const showTooltip = () => {
    props.setValidationErrorText(
      getValidationStatusText(
        props.isValidationError,
        props.isEdited,
        props.isWarning
      )
    );
    props.setValidationErrorColor(
      getSectionHeaderColor(
        props.isValidationError,
        props.isEdited,
        props.isWarning
      )
    );

    return <></>;
  };

  return (
    <SectionValidationStatusText
      color={getSectionHeaderColor(
        props.isValidationError,
        props.isEdited,
        props.isWarning
      )}
    >
      {props.isCompactView ? showTooltip() : showValidationText()}
    </SectionValidationStatusText>
  );
};

export default SectionHeaderStatus;
