import { ConfirmationDialogControl } from "../../../../../../controls/Dialog/ConfirmationDialogControl";
import { StyledDialogContentText } from "../../../../selfHelpPopup/SC/StyledDialogContentText";

interface ActionConfirmationDialogProps {
  id: string;
  onCloseClick: (e: React.MouseEvent) => void;
  onYesClick: (e: React.MouseEvent) => void;
  onNoClick: (e: React.MouseEvent) => void;
  isOpen: boolean;
}

export function ActionConfirmationDialog(props: ActionConfirmationDialogProps) {
  return (
    <ConfirmationDialogControl
      id={props.id}
      title="Do you wish to delete this scheduled action?"
      onYesClick={props.onYesClick}
      onNoClick={props.onNoClick}
      onCloseClick={props.onCloseClick}
      maxWidth="sm"
      isOpen={props.isOpen}
      content={
        <StyledDialogContentText id="alert-dialog-description">
          This action is irreversible, scheduled action will disappear
          completely from document revision.
        </StyledDialogContentText>
      }
    />
  );
}
