import { useEffect } from "react";
import {
  useLazyGetAccessGroupsForEditQuery,
  useLazyGetAllAccessGroupsQuery,
} from "../../../apis/accessGroupsApi";
import {
  useLazyGetAllDocumentKindsQuery,
  useLazyGetDocumentKindsForEditQuery,
} from "../../../apis/documentKindsApi";
import { useLazyGetPublisherCategorySettingsQuery } from "../../../apis/publisherCategoriesSettingsApi";
import {
  selectReloadSettings,
  setAccessGroups,
  setDocumentKindIds,
  setPermission,
  setSettings,
  setReloadSettings,
  selectCategoryId,
  setAllDocumentKinds,
  setAllAccessGroups,
  setIsEditable,
} from "../../slices/publisherCategoriesSettingsSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useLazyGetMyPermissionsInCategoryQuery } from "../../../apis/publishersApi";
import _ from "lodash";
import { PermissionInCategory } from "../../../models/permissionsInCategory";

export function useDictionaryListsFetch() {
  const dispatch = useAppDispatch();
  const reloadSettings = useAppSelector(selectReloadSettings);
  const categoryId = useAppSelector(selectCategoryId);

  const [
    getAllDocumentKinds,
    { data: allDocumentKinds, isError: isErrorAllDocumentKinds },
  ] = useLazyGetAllDocumentKindsQuery();

  const [
    getAllSelectedAccessGroups,
    { data: allAccessGroup, isError: isErrorAllAccessGroup },
  ] = useLazyGetAllAccessGroupsQuery();

  const [
    getMyPermissions,
    {
      data: permissions,
      isError: isErrorMyPermission,
      isLoading: isLoadingMyPermission,
    },
  ] = useLazyGetMyPermissionsInCategoryQuery();

  const [
    getSelectedAccessGroups,
    {
      data: categoryAccessGroups,
      isLoading: isLoadingAccessGroup,
      isFetching: isFetchingAccessGroup,
      isError: isErrorAccessGroup,
    },
  ] = useLazyGetAccessGroupsForEditQuery();

  const [
    getSelectedDocumentKinds,
    {
      data: categoryDocumnetKinds,
      isLoading: isLoadingDocumentKinds,
      isFetching: isFetchingDocumentKinds,
      isError: isErrorDocumentKinds,
    },
  ] = useLazyGetDocumentKindsForEditQuery();

  const [
    getCategorySettings,
    {
      data: categorySettings,
      isLoading: isLoadingCategorySettings,
      isFetching: isFetchingCategorySettings,
      isError: isErrorCategorySettings,
    },
  ] = useLazyGetPublisherCategorySettingsQuery();

  const isLoading =
    isLoadingDocumentKinds ||
    isLoadingAccessGroup ||
    isFetchingAccessGroup ||
    isFetchingDocumentKinds ||
    isLoadingCategorySettings ||
    isFetchingCategorySettings ||
    isLoadingMyPermission;

  const isError =
    isErrorAccessGroup ||
    isErrorDocumentKinds ||
    isErrorCategorySettings ||
    isErrorAllAccessGroup ||
    isErrorAllDocumentKinds ||
    isErrorMyPermission;

  useEffect(() => {
    void getAllDocumentKinds(null);
  }, [getAllDocumentKinds]);

  useEffect(() => {
    void getAllSelectedAccessGroups(null);
  }, [getAllSelectedAccessGroups]);

  useEffect(() => {
    if (categoryId) {
      void getSelectedDocumentKinds(categoryId);
      void getSelectedAccessGroups(categoryId);
      void getCategorySettings(categoryId);
      void getMyPermissions(categoryId);
    }
  }, [
    getCategorySettings,
    getSelectedAccessGroups,
    getSelectedDocumentKinds,
    getMyPermissions,
    categoryId,
  ]);

  useEffect(() => {
    if (allDocumentKinds) {
      dispatch(setAllDocumentKinds(allDocumentKinds));
    }
  }, [allDocumentKinds, dispatch]);

  useEffect(() => {
    if (allAccessGroup) {
      dispatch(setAllAccessGroups(allAccessGroup));
    }
  }, [allAccessGroup, dispatch]);

  useEffect(() => {
    if (categoryAccessGroups) {
      const onlyInCateogry = categoryAccessGroups.filter(
        (x) => !x.isOnlyInDocument
      );
      dispatch(setAccessGroups(onlyInCateogry.map((x) => x.groupId)));
    }
  }, [dispatch, categoryAccessGroups]);

  useEffect(() => {
    if (categoryDocumnetKinds) {
      const onlyInCategory = categoryDocumnetKinds.filter(
        (x) => !x.isOnlyInDocument
      );

      dispatch(setDocumentKindIds(onlyInCategory.map((x) => x.id)));
    }
  }, [dispatch, categoryDocumnetKinds]);

  useEffect(() => {
    if (categorySettings) {
      dispatch(
        setPermission({
          deleteDocument: categorySettings.deleteDocument,
          changePopularCategory: categorySettings.changePopularCategory,
          editDocumentIdentity: categorySettings.editDocumentIdentity,
        })
      );
      dispatch(
        setSettings({
          permanentlyDeletedInDays: categorySettings.permanentlyDeletedInDays,
          approvalWorkflowEnabled: categorySettings.approvalWorkflowEnabled,
          highLevelWarningThreshold: categorySettings.highLevelWarningThreshold,
        })
      );
    }

    if (permissions) {
      dispatch(
        setIsEditable(
          _.includes(
            permissions,
            PermissionInCategory.CanChangeCategorySettings
          )
        )
      );
    }
  }, [dispatch, categorySettings, permissions]);

  useEffect(() => {
    if (reloadSettings) {
      void getSelectedAccessGroups(categoryId);
      void getSelectedDocumentKinds(categoryId);
      void getCategorySettings(categoryId);
      void getAllDocumentKinds(null);
      void getAllSelectedAccessGroups(null);
      void getMyPermissions(categoryId);

      dispatch(setReloadSettings(false));
    }
  }, [
    dispatch,
    getSelectedAccessGroups,
    getSelectedDocumentKinds,
    getCategorySettings,
    getAllDocumentKinds,
    getAllSelectedAccessGroups,
    getMyPermissions,
    reloadSettings,
    categoryId,
  ]);

  return { isLoading, isError };
}
