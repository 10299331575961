import { DialogActions, DialogContent } from "@mui/material";
import { IconButtonControl } from "../../../../../controls/Buttons/IconButtonControl";
import { DialogButton } from "../../../../../controls/Dialog/SC/DialogButton";
import { StyledDialog } from "../../../../../controls/Dialog/SC/StyledDialog";
import { StyledDialogTitle } from "../../../../../controls/Dialog/SC/StyledDialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { IconButtonControlDiv } from "../../SC/IconButtonControlDiv";
import { StyledDialogContentText } from "../../../selfHelpPopup/SC/StyledDialogContentText";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";

export interface NewRevisionPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onArchive: () => void;
  onDelete: () => void;
}

export function NewRevisionPopup(props: NewRevisionPopupProps) {
  return (
    <StyledDialog
      id={"set-new-revision-dialog"}
      open={props.isOpen}
      onClose={props.onClose}
    >
      <StyledDialogTitle id={"set-new-revision-dialog-title"}>
        {"Publishing new revision"}
        <IconButtonControlDiv>
          <IconButtonControl
            id={"set-new-revision-dialog-close-button"}
            popupText={TooltipMsg.Close}
            onClick={props.onClose}
            icon={<CloseIcon fontSize="inherit" />}
          />
        </IconButtonControlDiv>
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText id="set-new-revision-dialog-description">
          What would you like to do with the previous revision of this document?
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogButton
          id="set-new-revision-dialog-archive-button"
          color="primary"
          onClick={props.onArchive}
          autoFocus
        >
          {"Archive"}
        </DialogButton>
        <DialogButton
          id="set-new-revision-dialog-delete-button"
          color="primary"
          onClick={props.onDelete}
          autoFocus
        >
          {"Delete"}
        </DialogButton>
        <DialogButton
          id={"set-new-revision-dialog-cancel-button"}
          color="secondary"
          onClick={props.onClose}
        >
          {"Cancel"}
        </DialogButton>
      </DialogActions>
    </StyledDialog>
  );
}
