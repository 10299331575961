import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { ContextMenuStepProps } from "./models";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export function HardDeleteStep(props: ContextMenuStepProps) {
    const theme = useTheme();

    return (
        <StepContentDiv>
            <Paragraph>
                {!props.isVisible && <><DeleteForeverIcon style={{
                    fontSize: "16px",
                    verticalAlign: "middle",
                    color: theme.palettes.grayscale.dark
                }} /><b> HARD DELETE</b> - </>}
                This action permanently removes the document from the database before the default 90-day period. It is only visible for documents in the &quot;Deleted&quot; state.
            </Paragraph>
        </StepContentDiv>
    );
}
