import { createStep } from "../../../helpers/selfHelpBuilder";
import { SearchBoxStep3 } from "./SearchBoxStep3";
import { SectionStep1 } from "./SectionStep1";
import { SectionStep2 } from "./SectionStep2";
import { SectionStep4 } from "./SectionStep4";


export const documentKindsSteps = () => {
  return [
    createStep("#cms-document-kinds-paper", <SectionStep1 />, 420, "right"),
    createStep("#cms-document-kinds-paper", <SectionStep2 />, 420, "right"),
    createStep("#document-kind-search-textfield", <SearchBoxStep3 />, 420, "right"),
    createStep("#cms-document-kinds-paper", <SectionStep4 />, 420, "right"),
  ];
};
