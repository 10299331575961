import styled from "styled-components";

interface SectionHeaderSpanProps {
  disabled?: boolean;
}

export const SectionHeaderSpan = styled.span<SectionHeaderSpanProps>`
  font-weight: 700;
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: ${(props) =>
    props.disabled
      ? props.theme.palettes.grayscale.medium
      : props.theme.palettes.grayscale.darkest};
  font-size: ${(props) => props.theme.typography.fontSizes.headerT1}px;
`;
