import IconButton from "@mui/material/IconButton";
import { StyledErrorIcon } from "../../../../controls/FormHelper/SC/StyledErrorIcon";
import { GroupErrorSpan } from "./styledComponents/GroupErrorSpan";
import RefreshIcon from "@mui/icons-material/Refresh";

export interface DocumentListGroupErrorProps {
  errorMessage: string;
  reloadDataHandler?: () => void;
}

export function DocumentListGroupError(props: DocumentListGroupErrorProps) {
  return (
    <>
      <GroupErrorSpan id="group-error-span">
        <StyledErrorIcon $paddingRight={4} color="error" fontSize="inherit" />
        {props.errorMessage}
      </GroupErrorSpan>
      <IconButton onMouseDown={props.reloadDataHandler} size="small">
        <RefreshIcon color="error" fontSize="inherit" />
      </IconButton>
    </>
  );
}
