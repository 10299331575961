import styled from "styled-components";
import { ToggleButtonGroup } from "@mui/material";

interface StyledToggleButtonGroupProps {
  height: number;
}

export const StyledToggleButtonGroup = styled(
  ToggleButtonGroup
)<StyledToggleButtonGroupProps>`
  &.MuiToggleButtonGroup-root {
    background-color: ${(props) => props.theme.palettes.grayscale.white};
    outline: 1px solid ${(props) => props.theme.palettes.grayscale.light};
    height: ${(props) => props.height}px;
    max-height: ${(props) => props.height}px;
  }

  & .MuiToggleButtonGroup-grouped {
    margin: 3px;
    border: 0;

    & .mui-disabled: {
      border: 0;
    }

    & :not(:first-of-type): {
      border-radius: 2px;
    }

    & :first-of-type: {
      border-radius: 2px;
    }
  }
`;
