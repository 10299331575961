import styled from "styled-components";
import { ScreenSplashEnum } from "../../../models/screenSplash";

export interface ScreenSplashDivProps {
  splashScreen: ScreenSplashEnum;
}

export const ScreenSplashDiv = styled.div<ScreenSplashDivProps>`
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: ${(props) =>
    props.splashScreen === ScreenSplashEnum.None ? "none" : "flex"};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
