import { ListItemButton, ListItemText, ListItemIcon } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { SearchControl } from "../Config/search/SearchControl";
import { StyledCheckbox } from "../../../controls/StyledComponents/StyledCheckbox";
import { CircularProgressBar } from "../../../controls/ProgressIndicators/CircularProgressBar";
import { useTheme } from "styled-components";
import { StyledList } from "../SC/StyledList";
import { useAppSelector } from "../../../app/hooks/hooks";
import { selectCategoryId } from "../../../app/slices/publisherCategoriesSettingsSlice";

export interface ListItems {
  id: number;
  name: string;
}

interface ListControlProps {
  id: string;
  items?: ListItems[];
  selectedItems?: ListItems[];
  editedValues: number[];
  placeholder: string;
  sortList: boolean;
  disabled: boolean;
  isLoading: boolean;
  selectEventHandler: (id: number) => void;
  removeEventHandler: (id: number) => void;
}

export function ListControl(props: ListControlProps) {
  const [treeItems, setTreeItems] = useState<ListItems[]>([]);
  const [filteredItems, setFilteredItems] = useState<ListItems[]>([]);
  const [checked, setChecked] = useState<number[]>([]);
  const theme = useTheme();
  const categoryId = useAppSelector(selectCategoryId);
  const listContainerRef = useRef<HTMLUListElement | null>(null);

  const sortAlphabetically = (a: ListItems, b: ListItems) =>
    a.name.localeCompare(b.name);

  const scrollToTop = () => {
    if (listContainerRef.current) {
      listContainerRef.current.scrollTop = 0;
    }
  };

  const prepareItems = useCallback(
    (items: ListItems[], selectedItems: ListItems[]) => {
      const selectedItemsIds = selectedItems.map((x) => x.id);
      const notSelectedItems = items.filter(
        (x) => !selectedItemsIds.includes(x.id)
      );

      return selectedItems.concat(notSelectedItems).map((x) => {
        return {
          id: x.id,
          name: x.name,
        };
      });
    },
    []
  );

  const handleToggle = useCallback(
    (id: number) => () => {
      if (!props.disabled) {
        const currentIndex = checked.indexOf(id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
          newChecked.push(id);
          props.selectEventHandler(id);
        } else {
          newChecked.splice(currentIndex, 1);
          props.removeEventHandler(id);
        }

        setChecked(newChecked);
      }
    },
    [props, checked]
  );

  useEffect(() => {
    if (props.items && props.selectedItems && props.sortList) {
      const sortedItems = [...props.items].sort(sortAlphabetically);
      const sortedSelectedItems = [...props.selectedItems].sort(
        sortAlphabetically
      );

      const values = prepareItems(sortedItems, sortedSelectedItems);
      setTreeItems(values);
      setFilteredItems(values);
      setChecked(sortedSelectedItems.map((x) => x.id));
    }
  }, [props, prepareItems]);

  useEffect(() => {
    setTreeItems([]);
    setFilteredItems([]);
  }, [categoryId]);

  let content = <></>;

  if (treeItems.length === 0 || props.isLoading) {
    content = (
      <CircularProgressBar
        size={theme.circularProgress.medium}
        space={theme.circularProgressWrapper.defaultHeight}
        color="secondary"
      />
    );
  } else {
    content = (
      <>
        {filteredItems.map((value: ListItems) => {
          const labelId = `transfer-list-item-${value.id}-label`;
          return (
            <ListItemButton
              key={value.id}
              role="listitem"
              onClick={handleToggle(value.id)}
              sx={{
                padding: 0,
                height: "32px",
                cursor: props.disabled ? "initial" : "pointer",
              }}
              disableRipple={props.disabled}
            >
              <ListItemIcon sx={{ padding: 0, margin: 0, minWidth: "0px" }}>
                <StyledCheckbox
                  checked={checked.includes(value.id)}
                  tabIndex={-1}
                  disabled={props.disabled}
                  disableRipple
                  $isEdited={props.editedValues.includes(value.id)}
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  style: { whiteSpace: "normal" },
                }}
                id={labelId}
                primary={value.name}
              />
            </ListItemButton>
          );
        })}
      </>
    );
  }

  return (
    <>
      <SearchControl
        id={props.id}
        baseValues={treeItems}
        changeHandler={(e) => {
          setFilteredItems(e);
          scrollToTop();
        }}
        placeholder={props.placeholder}
      />

      <StyledList ref={listContainerRef} dense={true} role="list">
        {content}
      </StyledList>
    </>
  );
}
