import { useCallback } from "react";
import { useAppDispatch } from "../../../../../../../app/hooks/hooks";
import { setSelectedNode } from "../../../../../../../app/slices/ownerCategoryTreeSlice";
import {
  setSelectedCategoryId,
  setSelectedCategoryName,
} from "../../../../../../../app/slices/publishersListSlice";
import { TooltipTop } from "../../../../../../../controls/Tooltips/ToolipTop";
import { RoleInCategory } from "../../../../../../../models/rolesInCategory";
import { RoleDiv } from "./SC/RoleDiv";

interface NoAccessContentProps {
  role: RoleInCategory;
  categoryPath: string;
  inheritedCategoryName: string;
  categoryId: string;
}

export function InheritedAccessContent(props: NoAccessContentProps) {
  const dispatch = useAppDispatch();

  const onCategoryClick = useCallback(() => {
    dispatch(setSelectedCategoryId(props.categoryId));
    dispatch(setSelectedNode(props.categoryId));
    dispatch(setSelectedCategoryName(props.inheritedCategoryName));
  }, [dispatch, props.categoryId, props.inheritedCategoryName]);

  return (
    <TooltipTop
      title={props.role + " role inherited from category " + props.categoryPath}
    >
      <RoleDiv onClick={onCategoryClick}>
        {"(" + props.inheritedCategoryName + ")"}
      </RoleDiv>
    </TooltipTop>
  );
}
