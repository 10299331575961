import styled from "styled-components";

export const PillsDiv = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: no-wrap;
  align-items: center;
  overflow: hidden;
  padding-left: 4px;
`;
