import { TooltipTop } from "../../../controls/Tooltips/ToolipTop";
import { StyledTreeItem } from "../SC/StyledTreeItem";
import StyledSpan from "../SC/StyledSpan";
import StyledRefreshIcon from "../SC/StyledRefreshIcon";
import StyledIconButton from "../SC/StyledIconButton";
import StyledErrorIcon from "../SC/StyledErrorIcon";
import StyledTypography from "../SC/StyledTypography";

export interface CategoryTreeItemErrorMessageProps {
  display?: string;
  marginLeft?: string;
  paddingLeft?: string;
}
interface StyledCategoryTreeItemErrorMessageProps {
  errorMessage?: string;
  treeItemMessageStyles?: CategoryTreeItemErrorMessageProps;
  tooltipTitle?: string;
  refreshFunction?: () => void;
}

const StyledCategoryTreeItemErrorMessage = ({
  errorMessage = "",
  refreshFunction,
  treeItemMessageStyles,
  tooltipTitle = "",
}: StyledCategoryTreeItemErrorMessageProps) => {
  return (
    <StyledTreeItem
      display={treeItemMessageStyles?.display}
      paddingLeft={treeItemMessageStyles?.paddingLeft}
      marginLeft={treeItemMessageStyles?.marginLeft}
    >
      <TooltipTop title={tooltipTitle}>
        <>
          <StyledSpan>
            <StyledErrorIcon color="error" />
            <StyledTypography>{errorMessage}</StyledTypography>
            <StyledIconButton onMouseDown={refreshFunction}>
              <StyledRefreshIcon color="action" />
            </StyledIconButton>
          </StyledSpan>
        </>
      </TooltipTop>
    </StyledTreeItem>
  );
};

export default StyledCategoryTreeItemErrorMessage;
