import styled from "styled-components";

export interface SeparatorSpanProps {
  $isResizable: boolean;
}

function getCursor(isResizable: boolean) {
  if (isResizable) {
    return "col-resize";
  }

  return "default";
}

export const SeparatorSpan = styled.span<SeparatorSpanProps>`
  transition: opacity 0.5s;
  width: 8px;
  padding: 8px 0 8px 5px;
  height: 100%;
  background-clip: content-box;
  box-sizing: border-box;
  cursor: ${(props) => getCursor(props.$isResizable)};
  background-color: ${(props) => props.theme.palettes.grayscale.medium};
  opacity: 1;
  &:hover {
    padding: ${(props) => (props.$isResizable ? "0 0 0 6px" : "8px 0 8px 6px")};
    background-color: ${(props) =>
      props.$isResizable
        ? props.theme.palettes.grayscale.dark
        : props.theme.palettes.grayscale.medium};
  }
`;
