import { useAppSelector } from "../../../../app/hooks/hooks";
import { selectDensity } from "../../../../app/slices/documentListSlice";
import { selectDocumentColumn } from "../../../../app/slices/documentListViewSlice";
import { DocumentsColumn } from "../../../../app/slices/models/documentsModels";
import { CellSkeletonDiv } from "./styledComponents/CellSkeletonDiv";
import { DocumentsListItemDiv } from "./styledComponents/DocumentsListItemDiv";
import { StyledCellSkeleton } from "./styledComponents/StyledCellSkeleton";
import { StyledRowSkeleton } from "./styledComponents/StyledRowSkeleton";

interface MuiSkeletonProps {
  width?: number;
  zebraStripe: boolean;
  isResizing: boolean;
  insideGroup?: boolean;
}

export function DocumentListItemSkeleton(props: MuiSkeletonProps) {
  const widthRatio = 0.2;
  const density = useAppSelector(selectDensity);
  const documentColumn: DocumentsColumn[] =
    useAppSelector(selectDocumentColumn);

  return (
    <DocumentsListItemDiv
      id={"document-list-item-div-"}
      $isGray={false}
      density={density}
      $zebraStripe={props.zebraStripe}
      insideGroup={props.insideGroup}
    >
      {props.isResizing && (
        <StyledRowSkeleton
          variant={"rectangular"}
          density={density}
          zebraStripe={props.zebraStripe}
        />
      )}

      {!props.isResizing &&
        documentColumn.map((column: DocumentsColumn, index) => {
          return (
            column.isVisible && (
              <CellSkeletonDiv key={index} width={column.size}>
                {index != 0 && (
                  <StyledCellSkeleton
                    width={column.size - column.size * widthRatio}
                    variant={"text"}
                  />
                )}
              </CellSkeletonDiv>
            )
          );
        })}
    </DocumentsListItemDiv>
  );
}
