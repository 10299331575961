import { createApi } from "@reduxjs/toolkit/query/react";
import Language from "../models/language";
import { publishApiBaseQuery } from "./baseQueries";

export const languagesApi = createApi({
  reducerPath: "languagesApi",
  baseQuery: publishApiBaseQuery,
  endpoints: (builder) => ({
    getLanguagesListByCategoryId: builder.query<Language[], string>({
      query: (categoryId) => ({
        url: `Languages/${categoryId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 3600,
    }),
    getLanguagesList: builder.query<Language[], null>({
      query: () => ({
        url: `Languages`,
        method: "GET",
      }),

      // The data will be cached for 1 hour even if the query is not active, because its dictionary data
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const {
  useGetLanguagesListQuery,
  useLazyGetLanguagesListQuery,
  useGetLanguagesListByCategoryIdQuery,
  useLazyGetLanguagesListByCategoryIdQuery,
} = languagesApi;
export default languagesApi.reducer;
