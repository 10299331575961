import { createStep } from "../../../helpers/selfHelpBuilder";
import { SectionStep1 } from "./SectionStep1";
import { SectionStep2 } from "./SectionStep2";
import { SectionStep3 } from "./SectionStep3";


export const cmsNavigationSteps = () => {
  return [
    createStep(".left-pane", <SectionStep1 />, 520, "right"),
    createStep(".left-pane", <SectionStep2 />, 520, "right"),
    createStep(".left-pane", <SectionStep3 />, 520, "right"),
  ];
};
