import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function CloseStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                This action closes the document without saving any changes. A prompt will appear if any changes have been made to prevent accidental loss of modifications.
            </Paragraph>
        </StepContentDiv>
    );
}
