import { find } from "lodash";
import {
  SelectedCategory,
  selectSelectedCategoryNodes,
} from "../../../../../app/slices/documentMetadataSlice";
import { useAppSelector } from "../../../../../app/hooks/hooks";
import { useMemo } from "react";
import { ValidationControl } from "../../../../../controls/Autocomplete/ValidationControl";

export function ValidationMessage() {
  const selectedCategoryNodes = useAppSelector(selectSelectedCategoryNodes);

  const showValidationMessage = useMemo(() => {
    return find(selectedCategoryNodes, (category: SelectedCategory) => {
      return category.isHighLevelCategory && category.hasChildren;
    });
  }, [selectedCategoryNodes]);

  if (showValidationMessage) {
    return (
      <ValidationControl
        isWarning={true}
        warningMessage="It looks like you try to add high level category to a document. This should be done only for very wide scope documents (for example: catalogue for a whole offering)"
      />
    );
  }
  return <></>;
}
