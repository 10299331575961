import { useCallback, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import DocumentListItem from "../../models/documentList/documentListItem";
import { Workflow } from "../../models/documentList/types/workflow";
import { getNonEmptyDocumentIdentity } from "../helpers/documentIdentityHelper";
import { routing } from "../../components/pageLayout/PageLayout";
import { Identifier } from "../../models/documentDetails/documentMetadata";

const draftRouteWorkflows = [Workflow.Approval, Workflow.Draft];

export function useRouteHelper() {
  const { documentNumber, languageCodes, part, revision } = useParams();
  const location = useLocation();

  const detailsRoute = useMemo(() => {
    if (documentNumber && languageCodes && part && revision) {
      const route = `${encodeURIComponent(
        documentNumber
      )}/${languageCodes}/${encodeURIComponent(part)}/Revisions/${revision}`;
      if (location.pathname.includes("/Draft")) {
        return `${route}/Draft`;
      }

      return route;
    }
  }, [documentNumber, languageCodes, part, revision, location]);

  const getDetailsIdentity = useCallback(
    (
      languageCodes: string[],
      part: string,
      workflow: Workflow,
      documentNumber: string,
      revision: string
    ) => {
      const languages =
        languageCodes.length === 0 ? "_" : languageCodes.join("");
      const parts = part !== "" ? part : "_";
      const draft = draftRouteWorkflows.includes(workflow) ? "/Draft" : "";

      return `${encodeURIComponent(
        documentNumber
      )}/${languages}/${encodeURIComponent(
        parts
      )}/Revisions/${revision}${draft}`;
    },
    []
  );

  const getUrlToDocumentByIdentity = useCallback(
    (identifier: Identifier) => {
      const identity = getNonEmptyDocumentIdentity(identifier.identity);
      return getDetailsIdentity(
        identity.documentLanguageCodes,
        identity.documentPart,
        identifier.workflow,
        identity.documentNumber,
        identity.revision
      );
    },
    [getDetailsIdentity]
  );

  const getRevisionAvailableUrlToDocumentByIdentity = useCallback(
    (identifier: Identifier) => {
      const identity = getNonEmptyDocumentIdentity(identifier.identity);
      const languages =
        identity.documentLanguageCodes.length === 0
          ? "_"
          : identity.documentLanguageCodes.join("");
      const parts = identity.documentPart !== "" ? identity.documentPart : "_";

      return `${encodeURIComponent(
        identity.documentNumber
      )}/${languages}/${encodeURIComponent(parts)}`;
    },
    []
  );

  const getDocumentIdentifier = (item: DocumentListItem): Identifier => {
    return {
      identity: {
        documentLanguageCodes: item.languageCodes,
        documentNumber: item.documentNumber,
        documentPart: item.part,
        revision: item.revision,
      },
      workflow: item.workflow,
    };
  };

  const getDetailsRoute = useCallback(
    (identifier: Identifier) => {
      const identity = getNonEmptyDocumentIdentity(identifier.identity);
      const details = getDetailsIdentity(
        identity.documentLanguageCodes,
        identity.documentPart,
        identifier.workflow,
        identity.documentNumber,
        identity.revision
      );
      return `${routing.details}/${details}`;
    },
    [getDetailsIdentity]
  );

  const getDocumentListItemId = useCallback((item: DocumentListItem) => {
    const draft = draftRouteWorkflows.includes(item.workflow) ? "-draft" : "";
    const languages =
      item.languageCodes.length === 0 ? "_" : item.languageCodes.join("");
    const part = item.part === "" ? "_" : item.part;
    return `${item.documentNumber}-${languages}-${part}-${item.revision}${draft}`;
  }, []);

  const isNewDocumentRoute = useMemo(() => {
    return location.pathname.includes(routing.newDocument);
  }, [location]);

  const currentPath = useMemo(() => location.pathname, [location]);

  return {
    getDetailsRoute,
    getDocumentListItemId,
    getUrlToDocumentByIdentity,
    getDocumentIdentifier,
    getRevisionAvailableUrlToDocumentByIdentity,
    isNewDocumentRoute,
    detailsRoute,
    currentPath,
  };
}
