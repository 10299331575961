import styled from "styled-components";

export const CategoryBreadcrumbDiv = styled.div`
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
  &:hover {
    background-color: ${(props) => props.theme.palettes.grayscale.lightest};
  }
`;
