import styled from "styled-components";

export const PaneElementDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  scrollbar-gutter: stable;
  overflow-y: auto;
  padding-right: 4px;
  margin-right: 4px;
  margin-left: 8px;

  &.wide-scrollbar {
    margin-right: 1px;
  }
`;
