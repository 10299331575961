import styled from "styled-components";
import { Checkbox } from "@mui/material";

interface StyledCheckboxProps {
  $isEdited?: boolean;
}

export const StyledCheckbox = styled(Checkbox)<StyledCheckboxProps>`
  &.Mui-checked {
    color: ${(props) =>
      props.disabled
        ? props.theme.palettes.grayscale.disabledBackground
        : props.$isEdited
          ? props.theme.palettes.secondary.info
          : props.theme.palettes.grayscale.dark} !important;
  }
  &.MuiCheckbox-root {
    padding: 9px 9px 9px 9px;
    color: ${(props) =>
      props.$isEdited
        ? props.theme.palettes.secondary.info
        : "unset"} !important;
  }

  &.MuiCheckbox-root:hover {
    background-color: ${(props) => props.theme.palettes.grayscale.lightest};
  }
`;
