import {
  contentMinWidth,
  documentHeaderLeftPadding,
  documentHeaderRightPadding,
} from "./documentsModels";

export const navigationMinWidth = 350;
export const navigationDefaultWidth = 350;

export const windowMinWidth = contentMinWidth + navigationMinWidth;
export const windowMinHeight = 520;

export const margins: number =
  documentHeaderLeftPadding + documentHeaderRightPadding;

export const elementsWithCustomScrollbar = [
  "CategoryTreeBox",
  "navigation-multiple-filters-div",
  "Documents-list-items-div",
  "details-left-section-container",
  "details-middle-section-container",
  "details-right-section-container",
];
