import { useCallback, useEffect, useState } from "react";
import { TooltipTop } from "../../../../controls/Tooltips/ToolipTop";
import { ComboBoxListItem } from "../../../../models/autocompleteValue";
import { DocumentKindsPopup } from "../../../popup/DocumentKindsPopup";
import { DetailsAutoComplete } from "../../inputs/DetailsAutoComplete";
import { DocumentKindsInfoIcon } from "../../inputs/SC/DocumentKindsInfoIcon";
import {
  selectCurrentDocumentKind,
  selectCurrentDocumentMainCategory,
  setEditedDocumentKind,
} from "../../../../app/slices/documentMetadataSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import {
  selectIsDocumentKindValidationError,
  selectIsPublishValidationOn,
  setDocumentKindValidationStatus,
} from "../../../../app/slices/documentMetadataValidationSlice";
import { ValidationStatus } from "../../../../models/validationRule";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import { useDocumentDetailsDocumentKinds } from "../../../../app/hooks/document/useDocumentDetailsDocumentKinds";

interface DocumentKindControlProps {
  isEdited: boolean;
  isEditable: boolean;
  notEditableReason?: TooltipMsg;
  isLoadingDetailsMetadata: boolean;
}

export function DocumentKindControl(props: DocumentKindControlProps) {
  const dispatch = useAppDispatch();
  const [emptyError, setEmptyError] = useState<string | undefined>(undefined);
  const [notAvailableError, setNotAvailableError] = useState<
    string | undefined
  >(undefined);
  const documentKind = useAppSelector(selectCurrentDocumentKind);
  const isPublishValidationOn = useAppSelector(selectIsPublishValidationOn);
  const currentDocumentMainCategory = useAppSelector(
    selectCurrentDocumentMainCategory
  );
  const isDocumentKindValidationError = useAppSelector(selectIsDocumentKindValidationError);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const {
    fetchAllDocumentKinds,
    fetchDocumentKinds,
    sortedAllDocumentKinds,
    sortedDocumentKinds,
    isErrorAllDocumentKinds,
    isDocumentKindsError,
    isLoading,
  } = useDocumentDetailsDocumentKinds();

  useEffect(() => {
    if (!currentDocumentMainCategory) {
      void fetchAllDocumentKinds(props.isLoadingDetailsMetadata);
    } else {
      fetchDocumentKinds(props.isLoadingDetailsMetadata);
    }
  }, [
    fetchAllDocumentKinds,
    fetchDocumentKinds,
    props.isLoadingDetailsMetadata,
    currentDocumentMainCategory,
  ]);

  const handlePopupOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const onDocumentKindChangeHandler = (newValue: ComboBoxListItem[]) => {
    dispatch(setEditedDocumentKind(newValue[0].value));
  };

  const handleRefreshView = useCallback(() => {
    if (!currentDocumentMainCategory) {
      void fetchAllDocumentKinds(props.isLoadingDetailsMetadata);
    } else {
      fetchDocumentKinds(props.isLoadingDetailsMetadata);
    }
  }, [
    fetchDocumentKinds,
    fetchAllDocumentKinds,
    props.isLoadingDetailsMetadata,
    currentDocumentMainCategory,
  ]);

  const documentKindsItems = !currentDocumentMainCategory
    ? sortedAllDocumentKinds.map((dc) => {
      return { key: dc.name, value: dc.name } as ComboBoxListItem;
    })
    : sortedDocumentKinds.map((dc) => {
      return { key: dc.name, value: dc.name } as ComboBoxListItem;
    });

  useEffect(() => {
    if (isLoading || props.isLoadingDetailsMetadata || !currentDocumentMainCategory || sortedDocumentKinds.length == 0 || !documentKind || sortedDocumentKinds.some((x) => x.name === documentKind)) {
      setNotAvailableError(undefined);
    } else {
      setNotAvailableError(
        "Document kind not available for selected Main Category"
      );
    }
  }, [currentDocumentMainCategory, dispatch, documentKind, isLoading, props.isLoadingDetailsMetadata, sortedDocumentKinds]);

  useEffect(() => {
    if (isLoading || props.isLoadingDetailsMetadata || documentKind) {
      setEmptyError(undefined);
    } else {
      setEmptyError("Document kind cannot be empty");
    }
  }, [documentKind, isLoading, props.isLoadingDetailsMetadata]);

  useEffect(() => {
    if (isLoading || props.isLoadingDetailsMetadata) {
      dispatch(setDocumentKindValidationStatus(ValidationStatus.success));
    }
    else if (notAvailableError) {
      dispatch(setDocumentKindValidationStatus(ValidationStatus.error));
    }
    else if (emptyError) {
      if (isPublishValidationOn) {
        dispatch(setDocumentKindValidationStatus(ValidationStatus.error));
      } else {
        dispatch(setDocumentKindValidationStatus(ValidationStatus.notVisibleError));
      }
    } else {
      dispatch(setDocumentKindValidationStatus(ValidationStatus.success));
    }
  }, [dispatch, emptyError, isLoading, isPublishValidationOn, notAvailableError, props.isLoadingDetailsMetadata]);

  const getMessages = () => {
    const messages = [];
    if (emptyError) {
      messages.push(emptyError);
    }

    if (notAvailableError) {
      messages.push(notAvailableError);
    }

    return messages;
  };

  return (
    <>
      <DocumentKindsPopup
        open={isPopupOpen}
        selected={documentKind}
        onClose={handlePopupClose}
      />
      <DetailsAutoComplete
        id="document-kind"
        labelText="Document kind"
        isEditable={props.isEditable}
        isDisabled={!props.isEditable}
        notEditableMessage={props.notEditableReason}
        onRefreshHandler={handleRefreshView}
        isError={isDocumentKindValidationError}
        errorMessages={getMessages()}
        isDictionaryDataFetchingError={
          isDocumentKindsError || isErrorAllDocumentKinds
        }
        infoIcon={
          <TooltipTop title={TooltipMsg.DocumentKindsInfo}>
            <DocumentKindsInfoIcon />
          </TooltipTop>
        }
        onInfoIconClick={handlePopupOpen}
        selected={[{ key: documentKind, value: documentKind }]}
        items={documentKindsItems}
        isLoading={props.isLoadingDetailsMetadata}
        onChangeHandler={onDocumentKindChangeHandler}
        isEdited={props.isEdited}
        multiple={false}
      />
    </>
  );
}
