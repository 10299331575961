import { useAppSelector } from "../../../app/hooks/hooks";
import { PermissionsEditableState } from "../../../app/hooks/permissions/useUserPermissionsInDocument";
import { selectIsWindowTooSmall } from "../../../app/slices/commonSlice";
import { selectIsChangingWorkflow } from "../../../app/slices/documentDetailsSlice";
import { selectDocumentStatus, selectDocumentWorkflow } from "../../../app/slices/documentMetadataSlice";
import { GoToLatestRevisionButton } from "./buttons/GoToLatestRevisionButton";
import { HardDeleteButton } from "./buttons/hardDelete/HardDeleteButton";
import { SetStateButton } from "./buttons/SetStateButton";

export interface DeletedDocumentButtonsProps {
    hasPermissionToEditDocument: PermissionsEditableState;
    hasPermissionToDeleteDocument: PermissionsEditableState;
}

export function DeletedDocumentButtons(props: DeletedDocumentButtonsProps) {
    const isWindowToSmall = useAppSelector(selectIsWindowTooSmall);
    const documentStatus = useAppSelector(selectDocumentStatus);
    const workflow = useAppSelector(selectDocumentWorkflow);
    const isChangingWorkflow = useAppSelector(selectIsChangingWorkflow);

    return <>
        <SetStateButton
            disabled={isChangingWorkflow}
            workflow={workflow}
            isCompactView={isWindowToSmall}
        />
        <HardDeleteButton
            disabled={!props.hasPermissionToDeleteDocument.isEditable}
            disabledTooltipText={props.hasPermissionToDeleteDocument.lockMessage}
            isCompactView={isWindowToSmall}
        />
        {!documentStatus.isLatest && (
            <GoToLatestRevisionButton
                latestRevision={documentStatus.latestRevision}
                isCompactView={isWindowToSmall}
            />)}
    </>
}