import styled from "styled-components";


export const StyledTreeItem = styled.span<{
    marginLeft?: string;
    paddingLeft?: string;
    display?: string;
  }>`
    display: flex;
    word-break: break-all;
    display: ${({ display = "flex" }) => display};
    margin-left: ${({ marginLeft = "40px" }) => marginLeft};
    padding-left: ${({ paddingLeft = "0px" }) => paddingLeft};
  `;
  