import { CircularProgress } from "@mui/material";
import { CircularProgressDiv } from "./SC/CircularProgressDiv";
import { ProgressSpan } from "./SC/ProgressSpan";

export interface CircularProgressProps {
  size: number;
  space?: number;
  message?: string;
  messageFontSize?: number;
  color: "primary" | "secondary";
}

export function CircularProgressBar(props: CircularProgressProps) {
  return (
    <CircularProgressDiv height={props.space} id="circular-progress-div">
      <CircularProgress size={props.size} color={props.color} />
      {props.message !== undefined && (
        <ProgressSpan size={props.messageFontSize}>
          {props.message}
        </ProgressSpan>
      )}
    </CircularProgressDiv>
  );
}
