import styled from "styled-components";

export const StyledCategoryItemSpan = styled.span`
  cursor: pointer;
  padding: 4px 0px 4px 9px;
  font-size: ${(props) => props.theme.typography.fontSizes.medium}px;
  line-height: 1.13;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
