import { useAppSelector } from "../../../../../app/hooks/hooks";
import { useRouteHelper } from "../../../../../app/hooks/useRouteHelper";
import { selectDensity } from "../../../../../app/slices/documentListSlice";
import { Density } from "../../../../../app/slices/models/documentsModels";
import DocumentListItem from "../../../../../models/documentList/documentListItem";
import { WordBreakType } from "../../../../../models/documentList/types/WordBreakType";
import { EllipsisText } from "./EllipsisText";
import { StyledLink } from "./styledComponents/StyledLink";

export interface TitleCellProps {
  item: DocumentListItem;
  width: number;
  id: string;
  isGray: boolean;
}

export function TitleCell(props: TitleCellProps) {
  const { getDetailsRoute, getDocumentIdentifier } = useRouteHelper();
  const density = useAppSelector(selectDensity);

  return (
    <StyledLink
      width={props.width}
      $isGray={props.isGray}
      to={getDetailsRoute(getDocumentIdentifier(props.item))}
    >
      <EllipsisText
        useTooltip={true}
        id={props.id}
        width={props.width}
        emptyContentPlaceholder={props.item.fileName}
        wordBreak={WordBreakType.BreakWord}
        isBolded={!props.isGray}
        fillIfEmpty={true}
        content={props.item.title}
        $paddingRight={8}
        flexGrow={1}
        enableEllipsis={density !== Density.Comfort}
      />
    </StyledLink>
  );
}
