import { useTheme } from "styled-components";
import { useAppSelector } from "../../../../app/hooks/hooks";
import { rootCategoryId } from "../../../../app/slices/navigationSlice";
import { TreeViewSkeleton } from "../../../../controls/CategoryTree/skeleton/TreeViewSkeleton";
import { CategoryTreeDiv } from "./SC/CategoryTreeDiv";
import { CategoryTreeHeaderDiv } from "./SC/CategoryTreeHeaderDiv";
import { CategoryTreeTitleSpan } from "./SC/CategoryTreeTitleSpan";
import { CategoryTreeViewDiv } from "./SC/CategoryTreeViewDiv";
import { CategoryTreeControl } from "./control/CategoryTreeControl";
import { selectCategoryById } from "../../../../app/slices/ownerCategoryTreeSlice";
import { IconButtonControl } from "../../../../controls/Buttons/IconButtonControl";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { NavigationDiv } from "../../../documentsNavigation/SC/NavigationDiv";
import { CategorySearch } from "./categorySearch/CategorySearch";
import { SectionName } from "../../../../app/slices/models/documentDetailsModels";
import { SelfHelpButtonControl } from "../../../../controls/Buttons/SelfHelpButtonControl";
import { CurrentSelfHelp } from "../../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../../models/selfHelp/selfHelpSection";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";

const minQueryLength = 3;

interface CategoryTreeProps {
  isLoading: boolean;
  isSuccess: boolean;
  isMinimized: boolean;
  showSearchbar: boolean;
  maxCategoryLevelShow: number | null;
  colorCategoriesByLevel: number;
  onToggleClick: () => void;
  onCategorySelect: (categoryId: string, categoryName: string) => void;
  currentSelfHelp: CurrentSelfHelp;
  selfHelpPage: SelfHelpPage;
  selfHelpSectionName: SectionName;
}

export function CategoryTree({
  isLoading,
  isMinimized,
  maxCategoryLevelShow,
  showSearchbar,
  colorCategoriesByLevel,
  onToggleClick,
  onCategorySelect,
  currentSelfHelp,
  selfHelpPage,
  selfHelpSectionName
}: CategoryTreeProps) {
  const theme = useTheme();
  const rootCategory = useAppSelector((state) =>
    selectCategoryById(state, rootCategoryId)
  );

  let content = <></>;

  if (isLoading) {
    content = (
      <TreeViewSkeleton
        defaultPadding={theme.shapes.defaultCategoryTreePadding}
        width={240}
        numberOfLevels={6}
      />
    );
  } else if (rootCategory) {
    content = (
      <CategoryTreeControl
        colorCategoriesByLevel={colorCategoriesByLevel}
        maxCategoryLevelShow={maxCategoryLevelShow}
        categoryId={rootCategoryId}
        onCategorySelect={onCategorySelect}
      />
    );
  }

  if (isMinimized) {
    return <NavigationDiv $isMinimized={isMinimized} id="navigation-div" />;
  }

  return (
    <CategoryTreeDiv>
      <CategoryTreeHeaderDiv>
        <CategoryTreeTitleSpan>Select category</CategoryTreeTitleSpan>
        <SelfHelpButtonControl
          currentSelfHelp={currentSelfHelp}
          page={selfHelpPage}
          section={selfHelpSectionName}
        />
        <IconButtonControl
          id="navigation-collapse-button"
          popupText={TooltipMsg.Hide}
          icon={<KeyboardDoubleArrowLeftIcon fontSize="inherit" />}
          onClick={onToggleClick}
        />
      </CategoryTreeHeaderDiv>
      {showSearchbar && (
        <CategorySearch
          isMinimized={isMinimized}
          minQueryLength={minQueryLength}
        />
      )}
      <CategoryTreeViewDiv id="category-tree-box">
        {content}
      </CategoryTreeViewDiv>
    </CategoryTreeDiv>
  );
}
