export enum PermissionInCategory {
  CanEditDocument = "CanEditDocument",
  CanSetPopularCategory = "CanSetPopularCategory",
  CanEditDocumentIdentity = "CanEditDocumentIdentity",
  CanApproveDocument = "CanApproveDocument",
  CanEditServiceDocument = "CanEditServiceDocument",
  CanEditAccessGroups = "CanEditAccessGroups",
  CanChangeUserAccess = "CanChangeUserAccess",
  CanDeleteDocument = "CanDeleteDocument",
  CanExportPublishersEmails = "CanExportPublishersEmails",
  CanChangeCategorySettings = "CanChangeCategorySettings",
}
