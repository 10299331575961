import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { setPublishersViewInLocalStorage } from "../../../../app/hooks/localStorage";
import useWindowDimensions from "../../../../app/hooks/useWindowDimensions";
import { selectIsWindowTooSmall } from "../../../../app/slices/commonSlice";
import { navigationMinWidth } from "../../../../app/slices/models/contentModels";
import {
  selectIsNavigationMinimized,
  setIsNavigationMinimized,
} from "../../../../app/slices/ownerCategoryTreeSlice";
import {
  selectNavigationFlex,
  selectPublishersFlex,
  setNavigationFlex,
  setPublishersFlex,
} from "../../../../app/slices/publishersViewSlice";
import {
  HandlerProps,
  ReflexContainer,
  ReflexElement,
  ReflexSplitter,
} from "../../../../controls/Reflex";
import { CategoryTree } from "../../../cms/Config/navigation/CategoryTree";
import { DialogContentDiv } from "../../../details/sections/categories/addCategories/SC/DialogContentDiv";
import { ToggleNavigation } from "../../../documentsNavigation/ToggleNavigation";
import { TabDiv } from "../../SC/TabDiv";
import { PublishersSection } from "./publishers/PublishersSection";
import {
  selectSelectedCategoryId,
  setSelectedCategoryId,
  setSelectedCategoryName,
} from "../../../../app/slices/publishersListSlice";
import { CurrentSelfHelp } from "../../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../../models/selfHelp/selfHelpSection";
import { SectionName } from "../../../../app/slices/models/documentDetailsModels";

const publishersMinWidth = 680;
const hiddenNavigationWitdh = 0;
const navigationMinimizedWidth = 20;

interface CategoryTabProps {
  isSuccessCategories: boolean;
  isLoadingCategories: boolean;
}

const clamp = (value: number) => +Math.max(0, Math.min(1, value)).toFixed(2);

export function CategoryTab(props: CategoryTabProps) {
  const selectedCategoryId = useAppSelector(selectSelectedCategoryId);
  const navigationFlex = useAppSelector(selectNavigationFlex);
  const publishersFlex = useAppSelector(selectPublishersFlex);
  const isMinimizedByUser = useAppSelector(selectIsNavigationMinimized);
  const isWindowTooSmall = useAppSelector(selectIsWindowTooSmall);
  const { windowWidth } = useWindowDimensions();
  const dispatch = useAppDispatch();

  const maxNavigationWidth = useMemo(
    () => windowWidth - publishersMinWidth,
    [windowWidth]
  );
  const isMinimized = useMemo(
    () => isMinimizedByUser || isWindowTooSmall,
    [isMinimizedByUser, isWindowTooSmall]
  );

  const onChangeSizeClick = useCallback(() => {
    dispatch(setIsNavigationMinimized(!isMinimized));
  }, [isMinimized, dispatch]);

  const onNavigationStopResize = useCallback(
    (args: HandlerProps) => {
      if (!args.component.props.flex) {
        return;
      }

      const el = args.domElement as Element;
      const navFlex = clamp(
        el.clientWidth > navigationMinWidth
          ? args.component.props.flex
          : navigationMinWidth / windowWidth
      );
      const pubFlex = 1 - navFlex;

      dispatch(setNavigationFlex(navFlex));
      dispatch(setPublishersFlex(pubFlex));
    },
    [windowWidth, dispatch]
  );

  const setSelectedCategory = (categoryId: string, categoryName: string) => {
    dispatch(setSelectedCategoryId(categoryId));
    dispatch(setSelectedCategoryName(categoryName));
  };

  useEffect(() => {
    const publishersFlex =
      (windowWidth -
        (isMinimized
          ? navigationMinimizedWidth
          : navigationFlex * windowWidth)) /
      windowWidth;
    let clampedPubFlex = clamp(publishersFlex);
    let navFlex = 1 - clampedPubFlex;

    if (!isMinimized) {
      const navWidth = navFlex * windowWidth;

      if (navWidth < navigationMinWidth) {
        navFlex = Math.max(navFlex, clamp(navigationMinWidth / windowWidth));
        clampedPubFlex = clamp(1 - navFlex);
      }
    }

    dispatch(setPublishersFlex(clampedPubFlex));
    dispatch(setNavigationFlex(navFlex));
  }, [navigationFlex, windowWidth, isMinimized, dispatch]);

  useEffect(() => {
    setPublishersViewInLocalStorage({
      navigationFlex: navigationFlex,
      publishersFlex: publishersFlex,
    });
  }, [navigationFlex, publishersFlex]);

  return (
    <TabDiv id="category-tab-div">
      <ReflexContainer orientation="vertical" windowResizeAware={true}>
        <ReflexElement
          flex={isMinimized ? hiddenNavigationWitdh : navigationFlex}
          onStopResize={onNavigationStopResize}
          className="left-pane"
          minSize={isMinimized ? navigationMinimizedWidth : navigationMinWidth}
          maxSize={isMinimized ? navigationMinimizedWidth : maxNavigationWidth}
        >
          <DialogContentDiv>
            <CategoryTree
              showSearchbar={true}
              colorCategoriesByLevel={-1}
              isMinimized={isMinimized}
              onToggleClick={onChangeSizeClick}
              isLoading={props.isLoadingCategories}
              isSuccess={props.isSuccessCategories}
              maxCategoryLevelShow={null}
              onCategorySelect={setSelectedCategory}
              currentSelfHelp={CurrentSelfHelp.PublishersNavigation}
              selfHelpPage={SelfHelpPage.Publishers}
              selfHelpSectionName={SectionName.PublishersNavigation}
            />
            <ToggleNavigation
              onToggleClick={onChangeSizeClick}
              isMinimized={isMinimized}
            />
          </DialogContentDiv>
        </ReflexElement>
        <ReflexSplitter />
        <ReflexElement
          flex={publishersFlex}
          minSize={publishersMinWidth}
          className="center-pane"
        >
          <PublishersSection selectedCategoryId={selectedCategoryId} />
        </ReflexElement>
      </ReflexContainer>
    </TabDiv>
  );
}
