import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/categories/categories.png";
import { Screenshot } from "../../../SC/Screenshot";

interface SelectedCategoriesStepProps {
    showScreenshots?: boolean;
}

export function SelectedCategoriesStep(props: SelectedCategoriesStepProps) {
    const theme = useTheme()
    return (
        <StepContentDiv>
            <Paragraph>
                Categories can have two states:
                <ul>
                    <li><span style={{ color: theme.palettes.grayscale.medium }}> Gray, with a normal font</span>:
                        These are categories for which you do not have access rights to edit, add, or remove
                    </li>
                    <li><b>Black, bolded font</b>: These are categories that you can edit, add, or remove</li>
                </ul>
            </Paragraph>
            {props.showScreenshots &&
                <Paragraph>
                    <Screenshot src={screenshot} />
                </Paragraph>
            }
        </StepContentDiv>
    );
}
