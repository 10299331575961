import { css } from "styled-components";

export interface StyledIconProps {
  $color: string;
}

export const styledIconCss = css<StyledIconProps>`
  font-size: 12px !important;
  padding-right: 4px;
  cursor: default;
  color: ${(props) => props.$color};
`;
