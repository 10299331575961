import { useRef, useState, DragEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { SortBy } from "../../../../models/documentList/types/sortBy";
import { SeparatorSpan } from "../../documentsHeader/SC/SeparatorSpan";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  setSortBy,
  setSortDescending,
  selectSortBy,
  selectSortDescending,
  selectIsDocumentsListLoading,
} from "../../../../app/slices/documentListSlice";
import { DocumentsColumnType } from "../../../../models/documentList/types/DocumentColumnType";
import { DocumentsListHeaderCellDiv } from "./SC/DocumentsListHeaderCellDiv";
import { TitleSpan } from "../documentListItem/styledComponents/TitleSpan";
import { TableSortLabelStyled } from "./SC/TableSortLabelStyled";
import { EllipsisText } from "../documentListItem/cells/EllipsisText";
import { selectFitToScreen } from "../../../../app/slices/contentSlice";
import {
  resizeColumns,
  setColumnWidth,
  setDefaultColumnWidth,
} from "../../../../app/slices/documentListViewSlice";
import { WordBreakType } from "../../../../models/documentList/types/WordBreakType";
import { TooltipTop } from "../../../../controls/Tooltips/ToolipTop";
import { CurrentSelfHelp } from "../../../../models/CurrentSelfHelpType";
import { SelfHelpButtonControl } from "../../../../controls/Buttons/SelfHelpButtonControl";
import { SelfHelpPage } from "../../../../models/selfHelp/selfHelpSection";
import { SectionName } from "../../../../app/slices/models/documentDetailsModels";

export interface DocumentsHeaderCellProps {
  name?: string;
  fullName?: string;
  width: number;
  minWidth: number;
  isResizable: boolean;
  isSortable: boolean;
  isVisible: boolean;
  type: DocumentsColumnType;
}

export function DocumentsListHeaderCell(props: DocumentsHeaderCellProps) {
  const resizableDiv = useRef<HTMLDivElement>(null);
  const [initialPos, setInitialPos] = useState<number>(0);
  const sortBy = useAppSelector(selectSortBy);
  const sortDescending = useAppSelector(selectSortDescending);
  const fitToScreen = useAppSelector(selectFitToScreen);
  const isDocumentListLoading = useAppSelector(selectIsDocumentsListLoading);

  const dispatch = useAppDispatch();

  const initial = (e: DragEvent<HTMLDivElement>) => {
    setInitialPos(e.clientX);
  };

  const changeSortBy = (e: React.MouseEvent, type: DocumentsColumnType) => {
    switch (type) {
      case DocumentsColumnType.DocumentNumber: {
        dispatch(setSortDescending(SortBy.Number));
        dispatch(setSortBy(SortBy.Number));
        break;
      }
      case DocumentsColumnType.Title: {
        dispatch(setSortDescending(SortBy.Title));
        dispatch(setSortBy(SortBy.Title));
        break;
      }
      case DocumentsColumnType.DocumentKind: {
        dispatch(setSortDescending(SortBy.DocumentKind));
        dispatch(setSortBy(SortBy.DocumentKind));
        break;
      }
      case DocumentsColumnType.Published: {
        dispatch(setSortDescending(SortBy.PublishDate));
        dispatch(setSortBy(SortBy.PublishDate));
        break;
      }
      default:
        e.preventDefault();
        break;
    }
  };

  const drag = (e: DragEvent<HTMLDivElement>, type: DocumentsColumnType) => {
    if (e.screenX === 0 || !props.isResizable) {
      return;
    }

    if (resizableDiv.current != null) {
      const resizeValue = e.clientX - initialPos;
      if (fitToScreen) {
        dispatch(resizeColumns({ type, resizeValue }));
      } else {
        dispatch(setColumnWidth({ type, resizeValue }));
      }
    }
  };

  const resetWidth = (type: DocumentsColumnType) => {
    dispatch(
      setDefaultColumnWidth({
        type: type,
        fitToScreen: fitToScreen,
      })
    );
  };

  function handleDragOver(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";

    return false;
  }

  const showSortIcon = props.isSortable && props.width > props.minWidth;
  return (
    <TooltipTop title={props.fullName ? props.fullName : ""}>
      <DocumentsListHeaderCellDiv
        onDragOver={handleDragOver}
        id={"documents-list-header-cell-" + props.type}
        ref={resizableDiv}
        width={props.width}
        $minWidth={props.width}
        $maxWidth={props.width}
        $isVisible={props.isVisible}
        $isSorted={sortBy.valueOf() === props.type.valueOf()}
        type={props.type}
      >
        {props.name !== undefined && props.isSortable && (
          <TableSortLabelStyled
            active={sortBy.valueOf() === props.type.valueOf()}
            width={props.width - 12}
            direction={sortDescending ? "desc" : "asc"}
            onClick={(e) => changeSortBy(e, props.type)}
            hideSortIcon={!showSortIcon}
            $isSorted={sortBy.valueOf() === props.type.valueOf()}
            IconComponent={ArrowDropDownIcon}
          >
            <EllipsisText
              useTooltip={false}
              enableEllipsis={true}
              width={props.width}
              fillIfEmpty={true}
              wordBreak={WordBreakType.BreakAll}
              content={props.name}
              $paddingLeft={5}
              flexGrow={1}
            />
          </TableSortLabelStyled>
        )}
        {props.name !== undefined && !props.isSortable && (
          <TitleSpan width={props.width - 20} $cursorPointer={props.isSortable}>
            <EllipsisText
              useTooltip={false}
              enableEllipsis={true}
              width={props.width}
              fillIfEmpty={true}
              wordBreak={WordBreakType.BreakAll}
              content={props.name}
              $paddingLeft={5}
              flexGrow={1}
            />
          </TitleSpan>
        )}
        {props.name !== undefined && (
          <SeparatorSpan
            className="document-header-separator"
            $isResizable={props.isResizable}
            draggable={props.isResizable}
            onDragStart={initial}
            onDragEnd={(e: DragEvent<HTMLDivElement>) => drag(e, props.type)}
            onDoubleClick={() => resetWidth(props.type)}
          ></SeparatorSpan>
        )}
        {props.type === DocumentsColumnType.Options && (
          <SelfHelpButtonControl
            currentSelfHelp={CurrentSelfHelp.Documents}
            page={SelfHelpPage.List}
            section={SectionName.Documents}
            disabled={isDocumentListLoading}
          />
        )}
      </DocumentsListHeaderCellDiv>
    </TooltipTop>
  );
}
