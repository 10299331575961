import { createStep } from "../../../helpers/selfHelpBuilder";
import { DeleteDocumentStep2 } from "./DeleteDocumentStep2";
import { DocumentIdentityStep4 } from "./DocumentIdentityStep4";
import { PopularCategoryStep3 } from "./PopularCategoryStep3";
import { SectionStep1 } from "./SectionStep1";
import { SectionStep5 } from "./SectionStep5";


export const permissionsSteps = () => {
  return [
    createStep("#cms-permissions-paper", <SectionStep1 />, 520, "left"),
    createStep("#cms-permissions-delete-document", <DeleteDocumentStep2 />, 520, "left"),
    createStep("#cms-permissions-change-popular-category", <PopularCategoryStep3 />, 520, "left"),
    createStep("#cms-permissions-document-identity", <DocumentIdentityStep4 />, 520, "left"),
    createStep("#cms-permissions-paper", <SectionStep5 />, 520, "left"),
  ];
};
