import { Link } from "@mui/material";
import styled from "styled-components";

interface StyledLinkProps {
  $splashScreen?: boolean;
}

export const StyledLink = styled(Link)<StyledLinkProps>`
  &.MuiLink-root {
    color: ${(props) => props.theme.palettes.grayscale.darkest};
    text-decoration-color: ${(props) => props.theme.palettes.grayscale.darkest};
    font-size: ${(props) =>
      props.$splashScreen
        ? props.theme.typography.fontSizes.splashScreenMessage
        : props.theme.typography.fontSizes.medium}px;

    &:visited {
      color: ${(props) => props.theme.palettes.grayscale.dark};
      text-decoration-color: ${(props) => props.theme.palettes.grayscale.dark};
    }

    &:hover,
    &:active {
      color: ${(props) => props.theme.palettes.primary.main};
      text-decoration-color: ${(props) => props.theme.palettes.primary.main};
    }

    &:hover {
      background-color: ${(props) => props.theme.palettes.grayscale.lighter};
    }

    &:active {
      background-color: ${(props) => props.theme.palettes.grayscale.light};
    }

    &:focus-visible {
      outline: none;
      border: ${(props) => `1px solid ${props.theme.palettes.secondary.info}`};
      border-radius: 2px;
    }
  }
`;
