import styled from "styled-components";

export const SearchInputDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  margin-right: 12px;
  margin-left: 12px;
  margin-bottom: 6px;
`;
