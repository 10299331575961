import styled from "styled-components";

interface TreeItemNamePartSpanProps {
  highlighted: boolean;
}

export const TreeItemNamePartSpan = styled.span<TreeItemNamePartSpanProps>`
  white-space: pre;
  background-color: ${(props) =>
    props.highlighted
      ? props.theme.palettes.secondary.highlight
      : "transparent"};
`;
