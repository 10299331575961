import { PaperContentCenterDiv } from "./SC/PaperContentCenterDiv";
import { ErrorDisplay } from "../../../../../../app/errorHandling/ErrorDisplay";

interface ErrorProps {
  errorId?: string;
  errorMessageTitle?: string;
  errorDescription?: string;
  refreshFn: () => void;
}

export function OnError(props: ErrorProps) {
  return (
    <PaperContentCenterDiv>
      <ErrorDisplay
        errorId={props.errorId ?? "publishers"}
        errorMessageTitle={props.errorMessageTitle ?? "Something went wrong"}
        errorDescription={
          props.errorDescription ??
          "Error while loading publisher roles in category"
        }
        refreshFunction={props.refreshFn}
        showDash={false}
        showReloadButton={true}
      />
    </PaperContentCenterDiv>
  );
}
