import { createStep } from "../../../helpers/selfHelpBuilder";
import { ColumnButtonStep2 } from "./ColumnButtonStep2";
import { DensityButtonStep6 } from "./DensityButtonStep6";
import { FiltersButtonStep3 } from "./FiltersButtonStep3";
import { FiltersButtonStep4 } from "./FiltersButtonStep4";
import { FiltersButtonStep5 } from "./FiltersButtonStep5";
import { ListStep1 } from "./ListStep1";
import { ListStep7 } from "./ListStep7";
import { ListStep8 } from "./ListStep8";
import { PagingStep9 } from "./PagingStep9";

export const publisherSteps = () => {
  return [
    createStep(".MuiDataGrid-columnHeaders", <ListStep1 />, 920, "top"),
    createStep("#data-grid-columns-button", <ColumnButtonStep2 />, 520, "bottom"),
    createStep("#data-grid-filter-button", <FiltersButtonStep3 />, 520, "bottom"),
    createStep("#data-grid-filter-button", <FiltersButtonStep4 />, 520, "bottom"),
    createStep("#data-grid-filter-button", <FiltersButtonStep5 />, 520, "bottom"),
    createStep("#data-grid-density-button", <DensityButtonStep6 />, 520, "right"),
    createStep(".MuiDataGrid-columnHeaders", <ListStep7 />, 520, "bottom"),
    createStep(".MuiDataGrid-columnHeaders", <ListStep8 />, 520, "bottom"),
    createStep(".MuiTablePagination-root", <PagingStep9 />, 620, "top"),
  ];
};
