import styled from "styled-components";

export const RoleDiv = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
`;
