import { ListItemText } from "@mui/material";
import styled from "styled-components";
export const StyledListItemText = styled(ListItemText)`
  .MuiListItemText-primary {
    font-size: ${(props) => props.theme.typography.fontSizes.large}px;
    font-weight: bold;
  }

  .MuiListItemText-secondary {
    font-size: ${(props) => props.theme.typography.fontSizes.large}px;
  }

  &.MuiListItemText-root {
    min-height: 39px;
    margin: 6px 0px 6px 0px;
  }
`;
