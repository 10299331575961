import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { StyledLi } from "../../../SC/StyledLi";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ShareIcon from "@mui/icons-material/Share";
import StorageIcon from "@mui/icons-material/Storage";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export function ActionColumnStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The{" "}
        <MoreVertIcon
          style={{
            fontSize: "16px",
            verticalAlign: "middle",
          }}
        />{" "}
        &quot;Three dots&quot; button opens a menu with various actions.
      </Paragraph>
      <Paragraph>
        The availability of these actions may be greyed out depending on the
        user&apos;s privileges.
      </Paragraph>
      <ul>
        <StyledLi>
          <ArrowForwardIcon
            style={{
              fontSize: "16px",
              verticalAlign: "middle",
            }}
          />
          Open Details: This action opens the document details.
        </StyledLi>
        <StyledLi>
          <ShareIcon
            style={{
              fontSize: "16px",
              verticalAlign: "middle",
            }}
          />
          Share URL: This action allows the user to copy the document URL.
        </StyledLi>
        <StyledLi>
          New Revision: This action opens a pre-filled template for creating a
          new document revision.
        </StyledLi>
        <StyledLi>
          New Part Version: This action opens a pre-filled template for creating
          a new document part version.
        </StyledLi>
        <StyledLi>
          New Language Version: This action opens a pre-filled template for
          creating a new document language version.
        </StyledLi>
        <StyledLi>
          New from Template: This action opens a pre-filled template with most
          fields copied from the original document.
        </StyledLi>
        <StyledLi>
          <StorageIcon
            style={{
              fontSize: "16px",
              verticalAlign: "middle",
            }}
          />
          Archive: This action allows the user to archive the selected document.
        </StyledLi>
        <StyledLi>
          <DeleteForeverIcon
            style={{
              fontSize: "16px",
              verticalAlign: "middle",
            }}
          />
          Delete: This action allows the user to delete the selected document.
        </StyledLi>
      </ul>
    </StepContentDiv>
  );
}
