import styled from "styled-components";

export const CategoryTreeTitleSpan = styled.span`
  font-weight: 500;
  font-size: ${(props) => props.theme.typography.fontSizes.headerT2}px;
  -moz-box-flex: 1;
  flex-grow: 1;
  padding-bottom: 6px;
  cursor: default;
`;
