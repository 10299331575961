import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function FileNameStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                The attachment refers to the actual document that is intended for publication.
            </Paragraph>
            <Paragraph>
                It is important to note that <b>the attachment cannot contain any confidential or strictly confidential information.</b>
            </Paragraph>
            <Paragraph>
                When naming the file, it is recommended to avoid spaces or special characters. A good practice is to include the document number, language, part revision, and title in the file name.
            </Paragraph>
            <Paragraph>
                It is preferable to use non-editable formats, such as PDF, instead of editable ones like DOCX.
            </Paragraph>
            <Paragraph>
                The content of attachments in text formats can be searched using the ABB Library search mechanism in the Download Center. The content of documents is accessible to the ABB Librarian chatbot and other chatbots connected to the ABB Library.</Paragraph>
        </StepContentDiv >
    );
}
