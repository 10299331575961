import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { GridFilterInputValueProps } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { PublisherRoleType } from "../../../../../../models/publisherRoles";

interface RoleTypeInputProps extends GridFilterInputValueProps {
  roleTypes?: PublisherRoleType[];
}

export function RoleTypeInput(props: RoleTypeInputProps) {
  const { item, applyValue } = props;
  const roleTypes = useMemo(
    () =>
      props.roleTypes ? props.roleTypes : Object.values(PublisherRoleType),
    [props.roleTypes]
  );
  const [roles, setRoles] = useState<PublisherRoleType[]>([]);

  useEffect(() => {
    const itemValue = (item.value as PublisherRoleType[] | undefined) ?? [];
    setRoles(itemValue);
  }, [item.value]);

  return (
    <FormControl fullWidth variant="standard">
      <InputLabel id="roles-select-label">Role</InputLabel>
      <Select
        id="roles-select"
        multiple
        value={roles}
        onChange={(event: SelectChangeEvent<PublisherRoleType[]>) => {
          const val = event.target.value;
          applyValue({
            ...item,
            // eslint-disable-next-line
            value: typeof val === "string" ? (val as string).split(",") : val,
          });
        }}
      >
        {roleTypes.map((role) => (
          <MenuItem key={role} value={role}>
            {role}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
