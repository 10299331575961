import styled from "styled-components";
import WarningIcon from "@mui/icons-material/Warning";

interface StyledWarningIconProps {
  margin: number;
}
export const StyledWarningIcon = styled(WarningIcon)<StyledWarningIconProps>`
  vertical-align: middle;
  margin-right: ${(props) => props.margin}px;
  color: ${(props) => props.theme.palettes.secondary.warning};
`;
