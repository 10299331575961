import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/identity/link_with_doc_number.png";

export function DocumentNumberFirstStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The document number is utilized for document identification. It is incorporated in the document download URL, document details URL, and can be employed in free text searching.
      </Paragraph>
      <Paragraph>
        <Screenshot src={screenshot} />
      </Paragraph>
      <Paragraph>
        When presenting the same information in various formats (such as PDF, video, image, or Word) or languages, it is imperative to utilize the same document number.
      </Paragraph>
    </StepContentDiv>
  );
}
