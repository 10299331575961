export default interface TagsListItem {
  id: string;
  displayName: string;
  type: TagTypeEnum;
  canEdit: boolean;
}

export enum TagTypeEnum {
  MultiValueTag = "MultiValueTag",
  SingleValueTag = "SingleValueTag",
  SpecialTag = "SpecialTag",
}

export function splitCamelCase(input: string): string {
  return input.replace(/([a-z])([A-Z])/g, "$1 $2");
}
