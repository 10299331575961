export enum SnackbarType {
  error = "error",
  warning = "warning",
  info = "info",
  success = "success",
}

export interface SnackbarData {
  message: string;
  type: SnackbarType;
}
