import { useLayoutEffect, useState } from "react";
import { useAppSelector } from "../../../../../app/hooks/hooks";
import { selectDensity } from "../../../../../app/slices/documentListSlice";
import { Density } from "../../../../../app/slices/models/documentsModels";
import { LanguagePill } from "../pills/LanguagePill";
import { LanguagePillsDiv } from "../pills/styledComponents/LanguagePillsDiv";

export interface LanguagesCellProps {
  languageCodes: string[];
  isGray: boolean;
  width: number;
}

export function LanguagesCell(props: LanguagesCellProps) {
  const languageNames = new Intl.DisplayNames(["en"], { type: "language" });
  const density = useAppSelector(selectDensity);
  const [languages, setLanguages] = useState<string[]>([]);

  const singlePillWidth = 27;

  useLayoutEffect(() => {
    function calculatePillsCount(width: number): number {
      const singleRowPills = Math.trunc(width / singlePillWidth);

      if (density === Density.Standard) {
        return singleRowPills * 2;
      } else if (density === Density.Compact) {
        return singleRowPills;
      } else return Number.MAX_VALUE;
    }

    const maxPillsCount = calculatePillsCount(props.width);

    if (props.languageCodes.length > maxPillsCount) {
      setLanguages(props.languageCodes.slice(0, maxPillsCount - 1));
    } else {
      setLanguages(props.languageCodes);
    }
  }, [density, props.width, props.languageCodes]);

  function mapIsoCodes(languageCodes: string[]): string {
    const languages: string[] = languageCodes.map((isoCode) => {
      return languageNames.of(isoCode) ?? "";
    });
    languages.sort();
    return languages.join(", ");
  }

  const ellipsisPill = (
    <LanguagePill
      isGray={props.isGray}
      isoCode="..."
      languageName={mapIsoCodes(props.languageCodes)}
    />
  );

  return (
    <LanguagePillsDiv className="language-pills-div" density={density}>
      {languages.map((isoCode, index) => {
        return (
          <LanguagePill
            isGray={props.isGray}
            key={index}
            isoCode={isoCode}
            languageName={languageNames.of(isoCode) ?? ""}
          />
        );
      })}

      {languages.length < props.languageCodes.length ? ellipsisPill : null}
    </LanguagePillsDiv>
  );
}
