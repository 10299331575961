import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStepTwo() {
    return (
        <StepContentDiv>
            <Paragraph>
                Country Market Availability is utilized in the Download Section to showcase documents specifically intended for the user&apos;s country.
            </Paragraph>
            <Paragraph>
                On the other hand, the Download Center is not influenced by Country Market Availability and displays all documents, irrespective of countries.
            </Paragraph>
        </StepContentDiv>
    );
}
