import styled from "styled-components";

interface AttachmentInfoPartDivProps {
  $useMaxWidth?: boolean;
}

export const AttachmentInfoPartDiv = styled.div<AttachmentInfoPartDivProps>`
  display: flex;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding-right: 6px;

  &:last-child {
    padding-right: 0;
  }

  ${(props) => props.$useMaxWidth && `max-width: 100%`}
`;
