import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { ValidationStatus } from "../../models/validationRule";
import { RootState } from "../store";
import { filter } from "lodash";

export interface DocumentMetadataValidationStatus {
  isDraftValidationOn: boolean;
  isPublishDocumentValidationOn: boolean;
  documentPartValidationStatus: ValidationStatus;
  documentNumberValidationStatus: ValidationStatus;
  fileNameValidationStatus: ValidationStatus;
  languagesValidationStatus: ValidationStatus;
  summaryValidationStatus: ValidationStatus;
  documentKindValidationStatus: ValidationStatus;
  titlesValidationStatus: ValidationStatus;
  accessGroupsValidationStatus: ValidationStatus;
  securityLevelValidationStatus: ValidationStatus;
  categoriesValidationStatus: ValidationStatus;
  cmaValidationStatus: ValidationStatus;
  tagsWithValidationWarning: string[];
}

const getInitialState = (): DocumentMetadataValidationStatus => ({
  isDraftValidationOn: false,
  isPublishDocumentValidationOn: false,
  documentPartValidationStatus: ValidationStatus.success,
  documentNumberValidationStatus: ValidationStatus.success,
  fileNameValidationStatus: ValidationStatus.success,
  languagesValidationStatus: ValidationStatus.success,
  summaryValidationStatus: ValidationStatus.success,
  documentKindValidationStatus: ValidationStatus.success,
  titlesValidationStatus: ValidationStatus.success,
  accessGroupsValidationStatus: ValidationStatus.success,
  securityLevelValidationStatus: ValidationStatus.success,
  categoriesValidationStatus: ValidationStatus.success,
  cmaValidationStatus: ValidationStatus.success,
  tagsWithValidationWarning: [],
});

const initialState: DocumentMetadataValidationStatus = getInitialState();

export const documentMetadataValidationSlice = createSlice({
  name: "documentMetadataValidation",
  initialState,
  reducers: {
    setIsPublishDocumentValidationOn: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isPublishDocumentValidationOn = action.payload;
    },
    setIsDraftValidationOn: (state, action: PayloadAction<boolean>) => {
      state.isDraftValidationOn = action.payload;
    },
    setDocumentPartValidationStatus: (
      state,
      action: PayloadAction<ValidationStatus>
    ) => {
      state.documentPartValidationStatus = action.payload;
    },
    setDocumentNumberValidationStatus: (
      state,
      action: PayloadAction<ValidationStatus>
    ) => {
      state.documentNumberValidationStatus = action.payload;
    },
    setLanguagesValidationStatus: (
      state,
      action: PayloadAction<ValidationStatus>
    ) => {
      state.languagesValidationStatus = action.payload;
    },
    setFileNameValidationStatus: (
      state,
      action: PayloadAction<ValidationStatus>
    ) => {
      state.fileNameValidationStatus = action.payload;
    },
    setTitlesValidationStatus: (
      state,
      action: PayloadAction<ValidationStatus>
    ) => {
      state.titlesValidationStatus = action.payload;
    },
    setDocumentKindValidationStatus: (
      state,
      action: PayloadAction<ValidationStatus>
    ) => {
      state.documentKindValidationStatus = action.payload;
    },
    setSummaryValidationStatus: (
      state,
      action: PayloadAction<ValidationStatus>
    ) => {
      state.summaryValidationStatus = action.payload;
    },
    setAccessGroupsValidationStatus: (
      state,
      action: PayloadAction<ValidationStatus>
    ) => {
      state.accessGroupsValidationStatus = action.payload;
    },
    setSecurityLevelValidationStatus: (
      state,
      action: PayloadAction<ValidationStatus>
    ) => {
      state.securityLevelValidationStatus = action.payload;
    },
    setCategoriesValidationStatus: (
      state,
      action: PayloadAction<ValidationStatus>
    ) => {
      state.categoriesValidationStatus = action.payload;
    },
    setCmaValidationStatus: (
      state,
      action: PayloadAction<ValidationStatus>
    ) => {
      state.cmaValidationStatus = action.payload;
    },
    addTagWithValidationWarning: (state, action: PayloadAction<string>) => {
      state.tagsWithValidationWarning = [
        ...state.tagsWithValidationWarning,
        action.payload,
      ];
    },
    removeTagWithValidationWarning: (state, action: PayloadAction<string>) => {
      const copy = [...state.tagsWithValidationWarning];
      state.tagsWithValidationWarning = filter(copy, (value) => {
        return value !== action.payload;
      });
    },
    resetDocumentMetadataValidation: (state) => {
      state.documentPartValidationStatus = ValidationStatus.success;
      state.documentNumberValidationStatus = ValidationStatus.success;
      state.fileNameValidationStatus = ValidationStatus.success;
      state.languagesValidationStatus = ValidationStatus.success;
      state.summaryValidationStatus = ValidationStatus.success;
      state.documentKindValidationStatus = ValidationStatus.success;
      state.titlesValidationStatus = ValidationStatus.success;
      state.accessGroupsValidationStatus = ValidationStatus.success;
      state.securityLevelValidationStatus = ValidationStatus.success;
      state.categoriesValidationStatus = ValidationStatus.success;
      state.cmaValidationStatus = ValidationStatus.success;
      state.isPublishDocumentValidationOn = false;
      state.tagsWithValidationWarning = [];
    },
  },
});

const documentMetadataValidationSelector = (state: RootState) =>
  state.documentMetadataValidation;

export const selectIsPublishValidationOn = createSelector(
  [documentMetadataValidationSelector],
  (state) => state.isPublishDocumentValidationOn
);

export const selectIsDraftValidationOn = createSelector(
  [documentMetadataValidationSelector],
  (state) => state.isDraftValidationOn
);

export const selectHasCurrentDocumentAnyValidationErrors = createSelector(
  [documentMetadataValidationSelector],
  (state) =>
    state.documentPartValidationStatus === ValidationStatus.error ||
    state.documentNumberValidationStatus === ValidationStatus.error ||
    state.languagesValidationStatus === ValidationStatus.error ||
    state.summaryValidationStatus === ValidationStatus.error ||
    state.documentKindValidationStatus === ValidationStatus.error ||
    state.titlesValidationStatus === ValidationStatus.error ||
    state.accessGroupsValidationStatus === ValidationStatus.error ||
    state.securityLevelValidationStatus === ValidationStatus.error ||
    state.categoriesValidationStatus === ValidationStatus.error ||
    state.fileNameValidationStatus === ValidationStatus.error ||
    state.cmaValidationStatus === ValidationStatus.error
);

export const selectHasDescriptionSectionAnyValidationErrors = createSelector(
  [documentMetadataValidationSelector],
  (state) =>
    state.summaryValidationStatus === ValidationStatus.error ||
    state.documentKindValidationStatus === ValidationStatus.error
);

export const selectHasAttachmentSectionAnyValidationErrors = createSelector(
  [documentMetadataValidationSelector],
  (state) => state.fileNameValidationStatus == ValidationStatus.error
);

export const selectHasCategorySectionAnyValidationErrors = createSelector(
  [documentMetadataValidationSelector],
  (state) => state.categoriesValidationStatus == ValidationStatus.error
);

export const selectHasIdentitySectionAnyValidationErrors = createSelector(
  [documentMetadataValidationSelector],
  (state) =>
    state.documentNumberValidationStatus === ValidationStatus.error ||
    state.documentPartValidationStatus === ValidationStatus.error ||
    state.languagesValidationStatus === ValidationStatus.error
);

export const selectHasTitlesSectionAnyValidationErrors = createSelector(
  [documentMetadataValidationSelector],
  (state) => state.titlesValidationStatus === ValidationStatus.error
);

export const selectTitlesValidationStatus = createSelector(
  [documentMetadataValidationSelector],
  (state) => state.titlesValidationStatus
);

export const selectHasTagsSectionAnyValidationWarnings = createSelector(
  [documentMetadataValidationSelector],
  (state) => state.tagsWithValidationWarning.length > 0
);

export const selectAccessGroupsValidationStatus = createSelector(
  [documentMetadataValidationSelector],
  (state) => state.accessGroupsValidationStatus
);

export const selectIsLanguagesValidationError = createSelector(
  [documentMetadataValidationSelector],
  (state) => state.languagesValidationStatus === ValidationStatus.error
);

export const selectIsSecurityLevelValidationError = createSelector(
  [documentMetadataValidationSelector],
  (state) => state.securityLevelValidationStatus === ValidationStatus.error
);

export const selectIsDocumentKindValidationError = createSelector(
  [documentMetadataValidationSelector],
  (state) => state.documentKindValidationStatus === ValidationStatus.error
);

export const selectSecuritySectionStatus = createSelector(
  [documentMetadataValidationSelector],
  (state) => {
    const errorStatuses = [ValidationStatus.error, ValidationStatus.warning];
    for (const status of errorStatuses) {
      if (
        state.accessGroupsValidationStatus === status ||
        state.securityLevelValidationStatus === status
      ) {
        return status;
      }
    }

    return ValidationStatus.success;
  }
);

export const selectHasCategoriesSectionValidationErrors = createSelector(
  [documentMetadataValidationSelector],
  (state) => state.categoriesValidationStatus === ValidationStatus.error
);

export const selectIsCmaValidationError = createSelector(
  [documentMetadataValidationSelector],
  (state) => state.cmaValidationStatus === ValidationStatus.error
);

export const selectHasAnySectionNotVisiblePublishError = createSelector(
  [documentMetadataValidationSelector],
  (state) => {
    return (
      state.categoriesValidationStatus === ValidationStatus.notVisibleError ||
      state.documentKindValidationStatus === ValidationStatus.notVisibleError ||
      state.documentNumberValidationStatus ===
      ValidationStatus.notVisibleError ||
      state.languagesValidationStatus === ValidationStatus.notVisibleError ||
      state.fileNameValidationStatus === ValidationStatus.notVisibleError ||
      state.titlesValidationStatus === ValidationStatus.notVisibleError ||
      state.cmaValidationStatus === ValidationStatus.notVisibleError
    );
  }
);

export const {
  setIsDraftValidationOn,
  setIsPublishDocumentValidationOn,
  setDocumentNumberValidationStatus,
  setTitlesValidationStatus,
  setDocumentKindValidationStatus,
  setSummaryValidationStatus,
  setDocumentPartValidationStatus,
  setFileNameValidationStatus,
  setLanguagesValidationStatus,
  setAccessGroupsValidationStatus,
  setSecurityLevelValidationStatus,
  setCategoriesValidationStatus,
  resetDocumentMetadataValidation,
  addTagWithValidationWarning,
  removeTagWithValidationWarning,
  setCmaValidationStatus,
} = documentMetadataValidationSlice.actions;
