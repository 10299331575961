import { ApprovalWorkflowDiv } from "./SC/ApprovalWorkflowDiv";
import { NameSpan } from "../SC/NameSpan";
import { SwitchDiv } from "./SC/SwitchDiv";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import {
  selectCurrentApprovalWorkflowEnabled,
  selectSettingsApprovalWorkflowIsEdited,
  setCurrentApprovalWorkflowEnabled,
} from "../../../../../app/slices/publisherCategoriesSettingsSlice";
import { StyledSwitch } from "./SC/StyledSwitch";

interface ApprovalWorkflowProps {
  disabled?: boolean;
}

export function ApprovalWorkflow(props: ApprovalWorkflowProps) {
  const dispatch = useAppDispatch();
  const approvalWorkflow = useAppSelector(selectCurrentApprovalWorkflowEnabled);
  const isEdited = useAppSelector(selectSettingsApprovalWorkflowIsEdited);

  const switchChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCurrentApprovalWorkflowEnabled(event.target.checked));
  };

  return (
    <ApprovalWorkflowDiv id="cms-approval-workflow-div">
      <SwitchDiv>
        <StyledSwitch
          disabled={props.disabled}
          checked={approvalWorkflow}
          onChange={switchChangeHandler}
          $isEdited={isEdited}
        />
      </SwitchDiv>
      <NameSpan>Approval workflow enabled</NameSpan>
    </ApprovalWorkflowDiv>
  );
}
