import styled from "styled-components";

export const HeaderDiv = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  height: 40px;
  padding-top: 4px;    
`;
