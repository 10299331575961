import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { DocumentSecurityLevel } from "../../../../../models/documentSecurityLevel";
import { resetPageNumber } from "../../../../../app/slices/documentListSlice";
import {
  selectSecurityLevelFilter,
  setSecurityLevelsFilter,
} from "../../../../../app/slices/navigationSlice";
import { SectionHeaderDiv } from "../../SC/SectionHeaderDiv";
import { SingleFilterDiv } from "../../SC/SingleFilterDiv";
import { TitleSpan } from "../../SC/TitleSpan";
import { StyledToggleButton } from "./styledComponents/StyledToggleButton";
import { StyledToggleButtonGroup } from "./styledComponents/StyledToggleButtonGroup";
import { InputDiv } from "../../SC/InputDiv";
import { SecurityLevelText } from "./styledComponents/SecurityLevelText";
import { TooltipTop } from "../../../../../controls/Tooltips/ToolipTop";
import { useTheme } from "styled-components";
import { ExternalLimitedAndInternalLimited, ExternalLimitedAndInternalPublic, ExternalPublicAndInternalPublic, HiddenFromExternalAndInternalPublic, InternalLimited, InternalPublic } from "../../../../../models/securityLevels";

export function SecurityLevelsFilter() {
  const dispatch = useAppDispatch();
  const securityLevelFilters = useAppSelector(selectSecurityLevelFilter);
  const theme = useTheme();

  const handleSecurityLevelsChange = (
    event: React.MouseEvent,
    value: DocumentSecurityLevel[]
  ) => {
    event.preventDefault();
    dispatch(setSecurityLevelsFilter(value));
    dispatch(resetPageNumber());
  };

  return (
    <SingleFilterDiv id="security-level-filter">
      <SectionHeaderDiv>
        <TitleSpan fontSize={theme.typography.fontSizes.headerT4}>
          Security level
        </TitleSpan>
      </SectionHeaderDiv>
      <InputDiv>
        <StyledToggleButtonGroup
          id="security-levels-toggle-button-group"
          value={securityLevelFilters}
          onChange={handleSecurityLevelsChange}
          size="medium"
          fullWidth
        >
          <StyledToggleButton
            id="security-level-ep-ip"
            size="medium"
            value={DocumentSecurityLevel.ExternalPublicAndInternalPublic}
          >
            <TooltipTop title={ExternalPublicAndInternalPublic}>
              <SecurityLevelText> EP+IP</SecurityLevelText>
            </TooltipTop>
          </StyledToggleButton>

          <StyledToggleButton
            id="security-level-h-ip"
            size="medium"
            value={DocumentSecurityLevel.HiddenFromExternalAndInternalPublic}
          >
            <TooltipTop title={HiddenFromExternalAndInternalPublic}>
              <SecurityLevelText>H+IP</SecurityLevelText>
            </TooltipTop>
          </StyledToggleButton>
          <StyledToggleButton
            id="security-level-el-ip"
            size="medium"
            value={DocumentSecurityLevel.ExternalLimitedAndInternalPublic}
          >
            <TooltipTop title={ExternalLimitedAndInternalPublic}>
              <SecurityLevelText>EL+IP</SecurityLevelText>
            </TooltipTop>
          </StyledToggleButton>
          <StyledToggleButton
            id="security-level-el-il"
            size="medium"
            value={DocumentSecurityLevel.ExternalLimitedAndInternalLimited}
          >
            <TooltipTop title={ExternalLimitedAndInternalLimited}>
              <SecurityLevelText>EL+IL</SecurityLevelText>
            </TooltipTop>
          </StyledToggleButton>
          <StyledToggleButton
            id="security-level-ep"
            size="medium"
            value={DocumentSecurityLevel.InternalPublic}
          >
            <TooltipTop title={InternalPublic}>
              <SecurityLevelText>IP</SecurityLevelText>
            </TooltipTop>
          </StyledToggleButton>
          <StyledToggleButton
            id="security-level-il"
            size="medium"
            value={DocumentSecurityLevel.InternalLimited}
          >
            <TooltipTop title={InternalLimited}>
              <SecurityLevelText>IL</SecurityLevelText>
            </TooltipTop>
          </StyledToggleButton>
        </StyledToggleButtonGroup>
      </InputDiv>
    </SingleFilterDiv >
  );
}
