import styled, { DefaultTheme } from "styled-components";

interface StyledSpanProps {
  color: "red" | "blue" | "grey";
}

function getColor(theme: DefaultTheme, color: string) {
  if (color === "red") return theme.palettes.primary.main;
  if (color === "blue") return theme.palettes.secondary.info;
  return theme.palettes.grayscale.dark;
}

export const StyledSpan = styled.span<StyledSpanProps>`
  color: ${(props) => getColor(props.theme, props.color)};
  font-weight: bold;
`;
