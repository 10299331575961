import TableRowsIcon from "@mui/icons-material/TableRows";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import {
  selectDensity,
  setDensity,
} from "../../../../../app/slices/documentListSlice";
import { Density } from "../../../../../app/slices/models/documentsModels";
import { ButtonDiv } from "../../SC/ButtonDiv";
import { TextButtonControl } from "../../../../../controls/Buttons/TextButtonControl";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";

interface RowDensityProps {
  isCompactView: boolean;
}

export function RowDensity(props: RowDensityProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const density = useAppSelector(selectDensity);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetDensity = (density: Density) => {
    dispatch(setDensity(density));
    setAnchorEl(null);
  };

  let startIcon;
  switch (density) {
    case Density.Compact:
      startIcon = <ViewHeadlineIcon fontSize="small" />;
      break;
    case Density.Comfort:
      startIcon = <ViewStreamIcon fontSize="small" />;
      break;
    default:
      // Density.Standard
      startIcon = <TableRowsIcon fontSize="small" />;
      break;
  }
  return (
    <ButtonDiv>
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.primaryButtonHeight}
        icon={startIcon}
        id="row-density-button"
        tooltipText={TooltipMsg.ChangeDensity}
        text={density.toUpperCase()}
        onClick={handleClick}
        isVertical={false}
        isVisible={true}
        isCompactView={props.isCompactView}
      />
      <Menu
        id="row-density-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleSetDensity(Density.Compact);
          }}
        >
          <ViewHeadlineIcon />
          Compact
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleSetDensity(Density.Standard);
          }}
        >
          <TableRowsIcon />
          Standard
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleSetDensity(Density.Comfort);
          }}
        >
          <ViewStreamIcon />
          Comfort
        </MenuItem>
      </Menu>
    </ButtonDiv>
  );
}
