import { useCallback, useEffect, useState } from "react";
import { getWorkflowName } from "../../../../app/helpers/getTooltips";
import { useAppSelector } from "../../../../app/hooks/hooks";
import { useRouteHelper } from "../../../../app/hooks/useRouteHelper";
import {
  selectContentWidth,
  selectFitToScreen,
  selectIsResizing,
} from "../../../../app/slices/contentSlice";
import { selectDensity } from "../../../../app/slices/documentListSlice";
import { selectDocumentColumn } from "../../../../app/slices/documentListViewSlice";
import {
  Density,
  DocumentsColumn,
} from "../../../../app/slices/models/documentsModels";
import { TooltipTop } from "../../../../controls/Tooltips/ToolipTop";
import approvalIcon from "../../../../icons/single_doc_approval.svg";
import archivedIcon from "../../../../icons/single_doc_archived.svg";
import deletedIcon from "../../../../icons/single_doc_deleted.svg";
import draftIcon from "../../../../icons/single_doc_draft.svg";
import publishedIcon from "../../../../icons/single_doc_published.svg";
import DocumentListItem from "../../../../models/documentList/documentListItem";
import { DocumentsColumnType } from "../../../../models/documentList/types/DocumentColumnType";
import { WordBreakType } from "../../../../models/documentList/types/WordBreakType";
import { Workflow } from "../../../../models/documentList/types/workflow";
import { StyledDocumentIcon } from "../../SC/StyledDocumentIcon";
import { DateCell } from "./cells/DateCell";
import { DocumentInfo } from "./cells/DocumentInfo";
import { DocumentsCell } from "./cells/DocumentsCell";
import { EllipsisText } from "./cells/EllipsisText";
import { LanguagesCell } from "./cells/LanguagesCell";
import { TitleCell } from "./cells/TitleCell";
import DocumentOptions from "./documentOptions/DocumentOptions";
import { SecurityLevelPill } from "./pills/SecurityLevelPill";
import { PillsDiv } from "./pills/styledComponents/PillsDiv";
import { ChevronCellDiv } from "./styledComponents/ChevronCellDiv";
import { ChevronDiv } from "./styledComponents/ChevronDiv";
import { DocumentNumberDiv } from "./styledComponents/DocumentNumberDiv";
import { DocumentsListItemDiv } from "./styledComponents/DocumentsListItemDiv";
import { WorkflowDiv } from "./styledComponents/WorfklowDiv";
import { DocumentIdentity } from "../../../../models/documentList/documentListSearchCriteria";
import { DocumentListItemSkeleton } from "./DocumentListItemSkeleton";

export interface DocumentsListItemProps {
  documentListItem: DocumentListItem;
  index: number;
  zebraStripe: boolean;
  insideGroup?: boolean;
  isLoading?: boolean;
  reloadAfterWorkflowChange: (document: DocumentIdentity) => void;
}

const getIcon = (workflow: Workflow) => {
  switch (workflow) {
    case Workflow.Approval:
      return approvalIcon;
    case Workflow.Archived:
      return archivedIcon;
    case Workflow.Draft:
      return draftIcon;
    case Workflow.Deleted:
      return deletedIcon;
    case Workflow.Published:
      return publishedIcon;
  }
};

export function DocumentsListItem(props: DocumentsListItemProps) {
  const density = useAppSelector(selectDensity);
  const fitToScreen = useAppSelector(selectFitToScreen);
  const contentWidth = useAppSelector(selectContentWidth);
  const isResizing = useAppSelector(selectIsResizing);
  const documentColumn: DocumentsColumn[] =
    useAppSelector(selectDocumentColumn);
  const isGray = !props.documentListItem.hasAccess;
  const [documentWorkflow, setDocumentWorkflow] = useState(
    props.documentListItem.workflow
  );
  const [isLoading, setIsLoading] = useState(false);
  const { getDocumentListItemId } = useRouteHelper();

  const onWorkflowChange = useCallback(() => {
    setIsLoading(true);
    props.reloadAfterWorkflowChange({
      documentLanguageCodes: props.documentListItem.languageCodes,
      documentNumber: props.documentListItem.documentNumber,
      documentPart: props.documentListItem.part,
    });
  }, [props]);

  useEffect(() => {
    setDocumentWorkflow(props.documentListItem.workflow);
  }, [props.documentListItem.workflow]);

  useEffect(() => {
    if (props.isLoading === false) setIsLoading(props.isLoading);
  }, [props.isLoading]);

  if (isLoading)
    return (
      <DocumentListItemSkeleton
        key={props.index}
        width={contentWidth}
        zebraStripe={props.index % 2 === 0}
        isResizing={isResizing && fitToScreen}
      />
    );

  return (
    <DocumentsListItemDiv
      id={
        "document-list-item-div-" +
        getDocumentListItemId(props.documentListItem)
      }
      className="single-document-list-item"
      $isGray={isGray}
      density={density}
      $zebraStripe={props.zebraStripe}
      insideGroup={props.insideGroup}
    >
      {documentColumn.map((column: DocumentsColumn, index) => (
        <DocumentsCell
          key={index}
          width={column.size}
          minWidth={column.size}
          maxWidth={column.size}
          isVisible={column.isVisible}
          type={column.type}
          fitToScreen={fitToScreen}
        >
          {column.type === DocumentsColumnType.Chevron ? (
            <ChevronCellDiv>
              <ChevronDiv />
              <WorkflowDiv
                $isGray={isGray}
                className="single-document-workflow-div"
              >
                <TooltipTop title={getWorkflowName(documentWorkflow)}>
                  <StyledDocumentIcon
                    className="workflow-icon"
                    src={getIcon(documentWorkflow)}
                    alt="Document icon"
                  />
                </TooltipTop>
              </WorkflowDiv>
            </ChevronCellDiv>
          ) : column.type === DocumentsColumnType.DocumentNumber ? (
            <DocumentNumberDiv>
              <EllipsisText
                useTooltip={true}
                width={column.size}
                fillIfEmpty={true}
                wordBreak={WordBreakType.BreakAll}
                content={props.documentListItem.documentNumber}
                $paddingRight={8}
                flexGrow={1}
                enableEllipsis={density !== Density.Comfort}
              />
            </DocumentNumberDiv>
          ) : column.type === DocumentsColumnType.Title ? (
            <TitleCell
              item={props.documentListItem}
              id={
                "document-list-item-title-gm-" +
                getDocumentListItemId(props.documentListItem)
              }
              width={column.size}
              isGray={isGray}
            />
          ) : column.type === DocumentsColumnType.DocumentKind ? (
            <EllipsisText
              useTooltip={true}
              width={column.size}
              fillIfEmpty={false}
              wordBreak={WordBreakType.BreakWord}
              content={props.documentListItem.documentKind}
              $paddingRight={8}
              flexGrow={1}
              enableEllipsis={density !== Density.Comfort}
            />
          ) : column.type === DocumentsColumnType.Language ? (
            <LanguagesCell
              width={column.size}
              isGray={isGray}
              languageCodes={props.documentListItem.languageCodes}
            />
          ) : column.type === DocumentsColumnType.Part ? (
            <EllipsisText
              useTooltip={true}
              width={column.size}
              fillIfEmpty={false}
              wordBreak={WordBreakType.BreakAll}
              content={props.documentListItem.part}
              $paddingRight={8}
              flexGrow={1}
              enableEllipsis={density !== Density.Comfort}
            />
          ) : column.type === DocumentsColumnType.Revision ? (
            <EllipsisText
              useTooltip={true}
              width={column.size}
              fillIfEmpty={true}
              wordBreak={WordBreakType.None}
              content={props.documentListItem.revision}
              $paddingRight={8}
              flexGrow={1}
              enableEllipsis={density !== Density.Comfort}
            />
          ) : column.type === DocumentsColumnType.Published ? (
            <DateCell
              width={column.size}
              date={props.documentListItem.publishDate}
              workflow={documentWorkflow}
            />
          ) : column.type === DocumentsColumnType.SecurityLevel ? (
            <PillsDiv>
              <SecurityLevelPill
                isGray={isGray}
                isInternal={false}
                securityLevel={props.documentListItem.securityLevel}
              />
              <SecurityLevelPill
                isGray={isGray}
                isInternal={true}
                securityLevel={props.documentListItem.securityLevel}
              />
            </PillsDiv>
          ) : column.type === DocumentsColumnType.Publisher ? (
            <EllipsisText
              useTooltip={true}
              width={column.size}
              fillIfEmpty={true}
              wordBreak={WordBreakType.BreakWord}
              content={props.documentListItem.publisher}
              $paddingRight={8}
              flexGrow={1}
              enableEllipsis={density !== Density.Comfort}
            />
          ) : column.type === DocumentsColumnType.Info ? (
            <DocumentInfo
              hasAccess={props.documentListItem.hasAccess}
              isCMA={props.documentListItem.areCountryRestrictionsEnabled}
              isFromServiceIntegration={
                props.documentListItem.isFromServiceIntegration
              }
              isLinked={props.documentListItem.isLinked}
              hasScheduledAction={props.documentListItem.hasScheduledAction}
            />
          ) : column.type === DocumentsColumnType.Options ? (
            <DocumentOptions
              documentItem={props.documentListItem}
              isGrouped={false}
              workflow={documentWorkflow}
              url={props.documentListItem.url}
              onWorkflowChange={onWorkflowChange}
            />
          ) : (
            <span></span>
          )}
        </DocumentsCell>
      ))}
    </DocumentsListItemDiv>
  );
}
