import {
  createListenerMiddleware,
  isAnyOf,
  TypedStartListening,
} from "@reduxjs/toolkit";
import {
  publishersListLocalStorageKey,
  setParamsInLocalStorage,
} from "../../../app/hooks/localStorage";
import {
  PublishersListState,
  setPublishersColumnVisibility,
  setPublishersDensity,
  setPublishersPageSize,
} from "../../../app/slices/publishersListSlice";
import { AppDispatch, RootState } from "../../../app/store";

export const publishersListocalStorageMiddleware = createListenerMiddleware();

function getPublishersListState(state: PublishersListState) {
  return {
    pageSize: state.pageSize,
    density: state.density,
    columnVisibilityModel: state.columnVisibilityModel,
  };
}

type AppStartListening = TypedStartListening<RootState, AppDispatch>;

const startAppListening =
  publishersListocalStorageMiddleware.startListening as AppStartListening;

startAppListening({
  matcher: isAnyOf(
    setPublishersDensity,
    setPublishersPageSize,
    setPublishersColumnVisibility
  ),
  effect: (_, listenerApi) => {
    setParamsInLocalStorage(
      publishersListLocalStorageKey,
      getPublishersListState(listenerApi.getState().publishersList)
    );
  },
});
