import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function PopularCategoryStep3() {
  return (
    <StepContentDiv>
      <Paragraph>
        In this section, permission to mark documents as popular in the selected
        category and its subcategories can be granted to either super users or
        to both super users and publishers.
      </Paragraph>
      <Paragraph>
        Marking a document as popular in the category will result in its
        appearance in the “Popular” documents section of the Download Section
        widget for the corresponding category. Popular documents are those that
        ABB wishes to promote.
      </Paragraph>
      <Paragraph>The chosen option is highlighted in red.</Paragraph>
    </StepContentDiv>
  );
}
