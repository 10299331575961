import { difference, filter, find, isEqual } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTheme } from "styled-components";
import { useLazyGetCategoryPathsQuery } from "../../../../apis/categoriesListApi";
import { ErrorDisplay } from "../../../../app/errorHandling/ErrorDisplay";
import { convertCategoryPathToCategoryGroup } from "../../../../app/helpers/categoriesHelper";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import {
  clearAccessGroups,
  clearDocumentKind,
  clearEditedDocumentCategories,
  selectCurrentDocumentCategories,
  selectCurrentDocumentMainCategory,
  setCategorySearch,
  setDocumentCategories,
  setLongCategoryPathsIds,
  setSelectedCategoryNodes,
  setVisibleCategorySearch,
} from "../../../../app/slices/documentMetadataSlice";
import { SectionName } from "../../../../app/slices/models/documentDetailsModels";
import { clearPublisherCategories } from "../../../../app/slices/publisherCategoryTreeSlice";
import { DiscreetButtonControl } from "../../../../controls/Buttons/DiscreetButtonControl";
import { FormHelperControl } from "../../../../controls/FormHelper/FormHelperControl";
import { CircularProgressBar } from "../../../../controls/ProgressIndicators/CircularProgressBar";
import { CurrentSelfHelp } from "../../../../models/CurrentSelfHelpType";
import {
  Category,
  CategoryPath,
} from "../../../../models/documentDetails/documentMetadata";
import { SectionFieldDiv } from "../../inputs/SC/SectionFieldDiv";
import { DetailsSection, DetailsSectionProps } from "../DetailsSection";
import { CategoryGroupControl } from "./CategoryGroupControl";
import { CategoryBreadcrumbsDiv } from "./SC/CategoryBreadcrumbsDiv";
import { AddCategoriesDialog } from "./addCategories/AddCategoriesDialog";
import { publisherCategoriesApi } from "../../../../apis/publisherCategoriesApi";
import { selectIsLoadingDetailsMetaData } from "../../../../app/slices/documentDetailsSlice";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import {
  selectCategoryGroups,
  setCategoryPermissions,
  setCategoryGroups,
} from "../../../../app/slices/categoriesSlice";
import {
  selectHasCategorySectionAnyValidationErrors,
  selectIsPublishValidationOn,
  setCategoriesValidationStatus,
} from "../../../../app/slices/documentMetadataValidationSlice";
import { ValidationStatus } from "../../../../models/validationRule";
import { useUserPermissionsInCategory } from "../../../../app/hooks/permissions/useUserPermissionsInCategory";
import { initialEditableState } from "../../../../app/hooks/permissions/models/state";
import { useUserCanEditDocument } from "../../../../app/hooks/permissions/useUserCanEditDocument";
import { useDocumentEditedMode } from "../../../../app/hooks/document/useDocumentEditedMode";
import { useDocumentCategoriesState } from "../../../../app/hooks/document/useDocumentCategoriesState";
import { useDocumentFlow } from "../../../../app/hooks/document/useDocumentFlow";

export function CategoriesSection(props: DetailsSectionProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { isNewDocumentVersion } = useDocumentFlow();
  const isPublishValidationOn = useAppSelector(selectIsPublishValidationOn);
  const hasCategorySectionAnyValidationError = useAppSelector(
    selectHasCategorySectionAnyValidationErrors
  );
  const { categoriesSection } = useDocumentEditedMode();
  const [categoryPaths, setCategoryPaths] = useState<
    CategoryPath[] | undefined
  >(undefined);
  const dispatch = useAppDispatch();
  const categoryGroups = useAppSelector(selectCategoryGroups);
  const documentCategories: Category[] = useAppSelector(
    selectCurrentDocumentCategories
  );
  const documentMainCategory = useAppSelector(
    selectCurrentDocumentMainCategory
  );
  const isLoadingDetailsMetaData = useAppSelector(
    selectIsLoadingDetailsMetaData
  );
  const theme = useTheme();
  const setNewVersionCategories = useRef(true);

  const [noMainCategoryError, setNoMainCategoryError] = useState<{
    message: string;
    isVisible: boolean;
  }>();
  const [mainCategoryDeletedError, setMainCategoryDeletedError] = useState<
    string | undefined
  >();
  const [
    otherCategoriesAreDeletedWarning,
    setOtherCategoriesAreDeletedWarning,
  ] = useState<string | undefined>();

  const [validationStatus, setValidationStatus] = useState({
    isWarning: false,
    isError: false,
    isNotVisibleError: false,
  });

  const { hasAccessToAnyDocumentCategory, canAddCategories } =
    useUserPermissionsInCategory(undefined);
  const { categoriesWereModified } = useDocumentCategoriesState();

  const [editableState, setEditableState] = useState(initialEditableState);
  const { canEditDocument } = useUserCanEditDocument();

  useEffect(() => {
    setEditableState(canEditDocument(true));
  }, [canEditDocument]);

  const [
    getCategoryPaths,
    {
      data: categoryPathsResponse,
      isLoading: isCategoryPathsLoading,
      isFetching: isCategoryPathsFetching,
      isError: isCategoryPathsError,
    },
  ] = useLazyGetCategoryPathsQuery();

  const everyCategoriesAreFromServiceIntegration = useCallback(() => {
    return documentCategories.every((x) => x.isFromServiceIntegration === true);
  }, [documentCategories]);

  const fetchCategoryPaths = useCallback(() => {
    if (!isLoadingDetailsMetaData) {
      const idsInDocument = documentCategories.map((c) => {
        return c.cid;
      });

      const idsInState = categoryPaths?.map((c) => {
        return c.categoryId;
      });

      if (!isEqual(idsInDocument, idsInState)) {
        void getCategoryPaths(
          {
            categoryIds: [...documentCategories.map((c) => c.cid)],
          },
          true
        );
      }
    }
  }, [
    isLoadingDetailsMetaData,
    documentCategories,
    getCategoryPaths,
    categoryPaths,
  ]);

  useEffect(() => {
    fetchCategoryPaths();
  }, [fetchCategoryPaths]);

  const handleRefreshView = useCallback(() => {
    fetchCategoryPaths();
  }, [fetchCategoryPaths]);

  useEffect(() => {
    if (categoryPathsResponse) {
      if (isNewDocumentVersion) {
        const userCategories = categoryPathsResponse.filter(
          (category) => category.canEditDocument || category.canChangePopular
        );
        setCategoryPaths(userCategories);

        if (
          categoryPathsResponse.length > 0 &&
          documentCategories.length > 0 &&
          setNewVersionCategories.current
        ) {
          const removeWithoutAccess =
            difference(
              documentCategories.map((c) => c.cid),
              userCategories.map((c) => c.categoryId)
            ).length > 0;

          if (removeWithoutAccess) {
            dispatch(
              setDocumentCategories(
                documentCategories.filter((c) =>
                  userCategories.map((c) => c.categoryId).includes(c.cid)
                )
              )
            );
          }

          setNewVersionCategories.current = false;
        }
      } else {
        setCategoryPaths(categoryPathsResponse);
      }
    }
  }, [
    dispatch,
    categoryPathsResponse,
    isNewDocumentVersion,
    documentCategories,
  ]);

  useEffect(() => {
    if (categoryPaths && categoryPaths.length != documentCategories.length) {
      const newValue = filter(categoryPaths, (catPath) => {
        return find(documentCategories, ["cid", catPath.categoryId]);
      }) as CategoryPath[];

      if (newValue.length != categoryPaths.length) setCategoryPaths(newValue);
    }
  }, [categoryPaths, documentCategories]);

  useEffect(() => {
    if (categoryPaths) {
      setMainCategoryDeletedError(undefined);
      setOtherCategoriesAreDeletedWarning(undefined);
      const longCategoryPathIds: string[] = [];
      categoryPaths.forEach((c) => {
        if (c.categoryPath.length > 4) {
          longCategoryPathIds.push(c.categoryId);
        }

        c.categoryPath.forEach((cp) => {
          if (cp.isDeleted)
            if (c.categoryId === documentMainCategory?.cid) {
              setMainCategoryDeletedError("Document main category is deleted.");
            } else {
              setOtherCategoriesAreDeletedWarning(
                "Some of the selected categories are deleted."
              );
            }
        });
      });

      dispatch(setLongCategoryPathsIds(longCategoryPathIds));
      dispatch(
        setCategoryGroups(convertCategoryPathToCategoryGroup(categoryPaths))
      );

      const categoryPermissions = categoryPaths.map((categoryPath) => {
        return {
          cid: categoryPath.categoryId,
          canEditDocument: categoryPath.canEditDocument,
          canChangePopular: categoryPath.canChangePopular,
          canApproveDocument: categoryPath.canApproveDocument,
          isApprovalRequired: categoryPath.isApprovalRequired,
        };
      });

      dispatch(setCategoryPermissions(categoryPermissions));
    }
  }, [categoryPaths, dispatch, documentMainCategory, isNewDocumentVersion]);

  useEffect(() => {
    if (documentMainCategory === undefined) {
      setNoMainCategoryError({
        message: "You have to select document main category",
        isVisible: isPublishValidationOn,
      });
      dispatch(clearDocumentKind());
      dispatch(clearAccessGroups());
    } else {
      setNoMainCategoryError(undefined);
    }
  }, [dispatch, documentMainCategory, isPublishValidationOn]);

  useEffect(() => {
    setValidationStatus({
      isError:
        (noMainCategoryError != undefined && noMainCategoryError.isVisible) ||
        mainCategoryDeletedError != undefined,
      isWarning: otherCategoriesAreDeletedWarning != undefined,
      isNotVisibleError:
        !!noMainCategoryError && !noMainCategoryError.isVisible,
    });
  }, [
    noMainCategoryError,
    mainCategoryDeletedError,
    otherCategoriesAreDeletedWarning,
  ]);

  useEffect(() => {
    if (validationStatus.isError) {
      dispatch(setCategoriesValidationStatus(ValidationStatus.error));
    } else if (validationStatus.isNotVisibleError) {
      dispatch(setCategoriesValidationStatus(ValidationStatus.notVisibleError));
    } else {
      dispatch(setCategoriesValidationStatus(ValidationStatus.success));
    }
  }, [dispatch, validationStatus.isError, validationStatus.isNotVisibleError]);

  const getMessage = () => {
    const messages = [];

    if (noMainCategoryError && noMainCategoryError.isVisible) {
      messages.push(noMainCategoryError.message);
    }

    if (mainCategoryDeletedError) {
      messages.push(mainCategoryDeletedError);
    }

    if (otherCategoriesAreDeletedWarning) {
      messages.push(otherCategoriesAreDeletedWarning);
    }

    return messages;
  };

  let content: React.ReactNode = null;
  if (
    isLoadingDetailsMetaData ||
    isCategoryPathsLoading ||
    isCategoryPathsFetching
  ) {
    content = (
      <SectionFieldDiv id="details-section-categories-categories">
        <CircularProgressBar
          size={theme.circularProgress.medium}
          space={theme.circularProgressWrapper.defaultHeight}
          color="secondary"
        />
      </SectionFieldDiv>
    );
  } else {
    content = (
      <SectionFieldDiv id="details-section-categories-categories">
        <CategoryBreadcrumbsDiv id="details-section-categories-breadcrumbs-container">
          {categoryGroups.length === 0 && (
            <FormHelperControl
              isInfo={true}
              isWarning={false}
              isError={false}
              texts={[
                "At least one category has to be selected before Access Groups and Tags can be provided",
              ]}
            />
          )}
          {categoryGroups.map((group) => {
            return (
              <CategoryGroupControl
                categoryGroup={group}
                key={group.topCategoryId}
              />
            );
          })}
        </CategoryBreadcrumbsDiv>
        {(validationStatus.isWarning || validationStatus.isError) && (
          <FormHelperControl
            isWarning={validationStatus.isWarning}
            isError={validationStatus.isError}
            texts={getMessage()}
          />
        )}
      </SectionFieldDiv>
    );
  }

  const getContent = (isCategoryPathsError: boolean) => {
    if (isCategoryPathsError) {
      return (
        <ErrorDisplay
          errorId={"category"}
          errorMessageTitle={"Something went wrong"}
          errorDescription={"Categories loading failed"}
          refreshFunction={handleRefreshView}
          showDash={false}
          showReloadButton={true}
        />
      );
    }

    return (
      <>
        {content}
        <DiscreetButtonControl
          disabled={
            isLoadingDetailsMetaData ||
            isCategoryPathsLoading ||
            !canAddCategories.isEditable
          }
          disabledTooltipText={
            canAddCategories.isEditable
              ? undefined
              : canAddCategories.lockMessage ?? TooltipMsg.NotLatestRevision
          }
          id="details-section-categories-add-categories-button"
          text="Add categories"
          onClick={() => {
            dispatch(publisherCategoriesApi.util.resetApiState());
            dispatch(setVisibleCategorySearch(""));
            dispatch(setSelectedCategoryNodes([]));
            dispatch(setCategorySearch(""));
            dispatch(clearPublisherCategories());
            setIsOpen(true);
          }}
        />
        <AddCategoriesDialog
          isOpen={isOpen}
          onCloseClick={() => {
            setIsOpen(false);
          }}
        />
      </>
    );
  };

  const discardCategoriesSection = useCallback(() => {
    dispatch(clearEditedDocumentCategories());
  }, [dispatch]);

  const getIcon = useCallback((): "link" | "serviceIntegration" | undefined => {
    if (editableState.isEditable) {
      return undefined;
    }

    if (everyCategoriesAreFromServiceIntegration()) return "serviceIntegration";

    if (
      canAddCategories.isEditable &&
      (hasAccessToAnyDocumentCategory || categoriesWereModified)
    ) {
      return "link";
    }

    return undefined;
  }, [
    editableState.isEditable,
    canAddCategories.isEditable,
    everyCategoriesAreFromServiceIntegration,
    hasAccessToAnyDocumentCategory,
    categoriesWereModified,
  ]);

  const getReason = useCallback((): TooltipMsg | undefined => {
    if (editableState.isEditable) {
      return undefined;
    }

    if (everyCategoriesAreFromServiceIntegration())
      return TooltipMsg.ServiceIntegration;

    if (
      (hasAccessToAnyDocumentCategory || categoriesWereModified) &&
      canAddCategories.isEditable
    ) {
      return TooltipMsg.LinkedDocument;
    }

    return editableState.lockMessage;
  }, [
    editableState.isEditable,
    editableState.lockMessage,
    everyCategoriesAreFromServiceIntegration,
    hasAccessToAnyDocumentCategory,
    canAddCategories.isEditable,
    categoriesWereModified,
  ]);

  return (
    <DetailsSection
      {...props}
      section={SectionName.Categories}
      name="Categories"
      selfHelpType={CurrentSelfHelp.DetailsCategories}
      isEdited={categoriesSection.categoriesAreEdited}
      isValidationError={hasCategorySectionAnyValidationError}
      isLoadingDetailsMetaData={
        isLoadingDetailsMetaData || isCategoryPathsLoading
      }
      onDiscardChanges={discardCategoriesSection}
      isEditable={
        editableState.isEditable ||
        ((hasAccessToAnyDocumentCategory || categoriesWereModified) &&
          canAddCategories.isEditable &&
          !everyCategoriesAreFromServiceIntegration())
      }
      isWarning={validationStatus.isWarning}
      notEditableReason={getReason()}
      customIcon={getIcon()}
    >
      {getContent(isCategoryPathsError)}
    </DetailsSection>
  );
}
