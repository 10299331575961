import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { RetryOptions } from "@reduxjs/toolkit/dist/query/retry";
import {
  BaseQueryApi,
  FetchArgs,
  createApi,
  retry,
} from "@reduxjs/toolkit/query/react";
import { openSnackbar } from "../app/helpers/snackBarHelper";
import {
  setEditedAttachmentId,
  setPendingUploadId,
} from "../app/slices/documentMetadataSlice";
import {
  clearFileUploadError,
  setFileUploadErrorMessage,
  setFileUploadRetryCounter,
  setIsFileUploadError,
} from "../app/slices/fileUploadSlice";
import { RootState } from "../app/store";
import { SnackbarType } from "../models/snackbar";
import { publishApiBaseQuery } from "./baseQueries";

interface CreateAttachmentInput {
  fileName: string;
  fileSize: number;
}

interface CreateAttachmentOutput {
  uploadId: string;
  chunkSize: number;
}

export interface ValidationErrors {
  data: ErrorData | undefined;
}

interface ErrorData {
  errors: string[];
}

interface UploadChunkInput {
  uploadId: string;
  partNumber: number;
  file: FormData;
}

interface UploadResponse {
  status: string;
}

interface UploadCompleteResponse {
  status: string;
  attachmentId: string;
}

export interface UploadRequest {
  uploadId: string;
}

export interface AttachmentRequest {
  attachmentId: string;
}

const timeToRetry = 30000;
const countTime = 1000;
let retryTimer: NodeJS.Timer | undefined = undefined;

/*eslint-disable*/
const retryBackoff = async (attempt: number, maxRetries: number) => {
  await new Promise((resolve) =>
    setTimeout((res) => resolve(res), timeToRetry)
  );
};

const retryCondition = (
  error: FetchBaseQueryError,
  args: FetchArgs,
  extraArgs: {
    attempt: number;
    baseQueryApi: BaseQueryApi;
    extraOptions: {} & RetryOptions;
  },
  snackbarMessage: string,
  errorMessage: string
) => {
  const status = error.status;
  const isAborted = args.signal?.aborted;

  const isRetryStatusError =
    (typeof status === "number" && status >= 500) ||
    status === "FETCH_ERROR" ||
    status === "TIMEOUT_ERROR";
  const isRetryError =
    (extraArgs.extraOptions.maxRetries === undefined ||
      extraArgs.extraOptions.maxRetries != 0) &&
    isRetryStatusError &&
    !isAborted;

  if (!isAborted) {
    openSnackbar(
      extraArgs.baseQueryApi.dispatch,
      snackbarMessage,
      SnackbarType.error
    );
  }

  if (isRetryError) {
    let counter = timeToRetry / countTime;
    extraArgs.baseQueryApi.dispatch(setIsFileUploadError(true));
    extraArgs.baseQueryApi.dispatch(setFileUploadRetryCounter(counter));
    extraArgs.baseQueryApi.dispatch(setFileUploadErrorMessage(errorMessage));

    clearInterval(retryTimer);
    retryTimer = setInterval(() => {
      if (counter >= 1) {
        counter--;
        extraArgs.baseQueryApi.dispatch(setFileUploadRetryCounter(counter));
      }
    }, countTime);
  }

  return isRetryError;
};

const retryBaseQuery = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    const argsInfo = args as FetchArgs;

    const rootState = api.getState() as RootState;
    const isRetrying = rootState.fileUpload.isUploadError;
    const isAborted = argsInfo.signal?.aborted;

    if (isAborted) {
      if (!isRetrying && retryTimer) {
        clearInterval(retryTimer);
      }

      retry.fail("Retry failed due to abort");
    }

    api.dispatch(setIsFileUploadError(false));

    const result = await publishApiBaseQuery(args, api, extraOptions);

    api.dispatch(clearFileUploadError());
    if (retryTimer) {
      clearInterval(retryTimer);
    }

    return result;
  }
);

export const fileUploadApi = createApi({
  reducerPath: "fileUploadApi",
  baseQuery: retryBaseQuery,
  endpoints: (builder) => ({
    createAttachment: builder.mutation<
      CreateAttachmentOutput,
      CreateAttachmentInput & { signal?: AbortSignal }
    >({
      query: ({ fileName, fileSize, signal }) => ({
        url: "Attachments/Upload",
        method: "POST",
        body: {
          fileName,
          fileSize,
        },
        signal,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setPendingUploadId(data.uploadId));
        } catch (error) {}
      },
      extraOptions: {
        backoff: retryBackoff,
        retryCondition: (error, args, extraArgs) =>
          retryCondition(
            error,
            args,
            extraArgs,
            "Error while creating file upload",
            "File create will be retried in:"
          ),
      },
    }),
    uploadChunk: builder.mutation<
      UploadResponse,
      UploadChunkInput & { signal?: AbortSignal }
    >({
      query: ({ uploadId, partNumber, file, signal }) => ({
        url: `Attachments/Upload/${uploadId}/chunks/${partNumber}`,
        method: "PUT",
        body: file,
        signal,
      }),
      extraOptions: {
        backoff: retryBackoff,
        retryCondition: (error, args, extraArgs) =>
          retryCondition(
            error,
            args,
            extraArgs,
            "Error while uploading file",
            "File upload will be retried in:"
          ),
      },
    }),
    completeAttachmentUpload: builder.mutation<
      UploadCompleteResponse,
      UploadRequest & { signal?: AbortSignal }
    >({
      query: ({ uploadId, signal }) => ({
        url: `Attachments/Upload/${uploadId}`,
        method: "POST",
        signal,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setEditedAttachmentId(data.attachmentId));
        } catch (error) {}
      },
      extraOptions: {
        backoff: retryBackoff,
        retryCondition: (error, args, extraArgs) =>
          retryCondition(
            error,
            args,
            extraArgs,
            "Error while completing file upload",
            "File upload will be retried in:"
          ),
      },
    }),
  }),
});

export const {
  useCreateAttachmentMutation,
  useUploadChunkMutation,
  useCompleteAttachmentUploadMutation,
} = fileUploadApi;

export default fileUploadApi;
