import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useCallback, useEffect } from "react";
import { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import {
  selectAllGroupAccordionState,
  selectAreAllGroupsExpanded,
  selectLanguageGroup,
  setLanguageGroup,
  selectDocumentGroup,
} from "../../../../app/slices/documentListSlice";
import { checkboxMinWidth } from "../../../../app/slices/models/documentsModels";
import { DocumentLanguageGroup } from "../../../../models/documentList/groupedDocumentTreeResult";
import { AccordionState } from "../../../../models/documentList/types/accordionState";
import { ChevronDiv } from "../documentListItem/styledComponents/ChevronDiv";
import { PartsAccordion } from "./PartsAccordion";
import { GroupCountSC } from "./styledComponents/GroupCountSC";
import { GroupNameTypography } from "./styledComponents/GroupNameTypography";
import { StyledAccordion } from "./styledComponents/StyledAccordion";
import { StyledAccordionDetails } from "./styledComponents/StyledAccordionDetails";
import { StyledAccordionSummary } from "./styledComponents/StyledAccordionSummary";

export interface LanguagesAccordionProps {
  languageGroupId: string;
  documentNumber: string;
  languageGroup: DocumentLanguageGroup;
  zebraStripe: boolean;
}

export function LanguagesAccordion(props: LanguagesAccordionProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const allGroupAccordionState = useAppSelector(selectAllGroupAccordionState);
  const areAllGroupsExpanded = useAppSelector(selectAreAllGroupsExpanded);
  const languageGroup = useAppSelector((state) =>
    selectLanguageGroup(state, props.documentNumber, props.languageGroupId)
  );
  const isDocumentGroupExpanded = useAppSelector((state) =>
    selectDocumentGroup(state, props.documentNumber)
  );

  const onExpandedToggle = useCallback(
    (expand: boolean) => {
      const languageId = {
        documentNumber: props.documentNumber,
        languageGroupId: props.languageGroupId,
      };
      if (expand) {
        dispatch(
          setLanguageGroup({
            ...languageId,
            expandedLanguage: {
              isExpanded: true,
              shouldExpandOnExpandAll: false,
            },
          })
        );
      } else {
        dispatch(
          setLanguageGroup({
            ...languageId,
            expandedLanguage: {
              isExpanded: false,
              shouldExpandOnExpandAll: false,
            },
          })
        );
      }
    },
    [props.documentNumber, props.languageGroupId, dispatch]
  );

  useEffect(() => {
    if (languageGroup === undefined && !!isDocumentGroupExpanded) {
      onExpandedToggle(true);
    }
  }, [languageGroup, isDocumentGroupExpanded, onExpandedToggle, dispatch]);

  useEffect(() => {
    if (
      allGroupAccordionState === AccordionState.Expanded &&
      areAllGroupsExpanded &&
      isDocumentGroupExpanded &&
      !languageGroup?.isExpanded &&
      (languageGroup === undefined || languageGroup.shouldExpandOnExpandAll)
    ) {
      onExpandedToggle(true);
    }
  }, [
    languageGroup,
    allGroupAccordionState,
    areAllGroupsExpanded,
    isDocumentGroupExpanded,
    onExpandedToggle,
  ]);

  return (
    <StyledAccordion
      id={
        "document-list-group-languages-accordion-" +
        props.languageGroup.isoCodes.toString()
      }
      disableGutters={true}
      square={true}
      expanded={!!languageGroup?.isExpanded}
      onChange={() => onExpandedToggle(!languageGroup?.isExpanded)}
      $isGray={false}
      $useBorder={false}
      $zebraStripe={props.zebraStripe}
    >
      <StyledAccordionSummary
        $paddingLeft={checkboxMinWidth}
        $maxHeight={theme.density.compactRowHeight + "px"}
        $minHeight={theme.density.compactRowHeight + "px"}
      >
        <ChevronDiv>
          {languageGroup?.isExpanded ? (
            <ExpandMoreIcon
              className="document-list-item-group-language-expand"
              fontSize="small"
            />
          ) : (
            <KeyboardArrowRightIcon
              className="document-list-item-group-language-close"
              fontSize="small"
            />
          )}
        </ChevronDiv>
        <GroupNameTypography className="document-list-item-group-language-name">
          {props.languageGroup.languageNames.join(", ")}
        </GroupNameTypography>
        <GroupCountSC>
          ({props.languageGroup.count}{" "}
          {props.languageGroup.count === 1 ? "version" : "versions"})
        </GroupCountSC>
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        {props.languageGroup.parts
          .slice()
          .sort((partPrevious, partNext) =>
            partPrevious.name.toLocaleLowerCase() >
            partNext.name.toLocaleLowerCase()
              ? 1
              : -1
          )
          .map((part) => {
            return (
              <PartsAccordion
                key={part.name}
                partGroup={part}
                languageGroup={props.languageGroup.isoCodes}
                documentNumber={props.documentNumber}
                zebraStripe={props.zebraStripe}
                languageGroupId={props.languageGroupId}
              />
            );
          })}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}
