import { TooltipTop } from "../../../../../controls/Tooltips/ToolipTop";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";
import { StyledEllipsisButton } from "./SC/StyledEllipsisButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

interface BreadcrumbEllipsisButtonProps {
  onClick: () => void;
}

export function BreadcrumbEllipsisButton(props: BreadcrumbEllipsisButtonProps) {
  return (
    <TooltipTop title={TooltipMsg.ShowWholePath}>
      <StyledEllipsisButton onClick={props.onClick}>
        <MoreHorizIcon fontSize="small" />
      </StyledEllipsisButton>
    </TooltipTop>
  );
}
