import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function PublisherListStep8() {
    return (
        <StepContentDiv>
            <Paragraph>
                There are 4 types of roles and responsibilities:
                <ul>
                    <li>
                        Publisher – A basic role that grants publishing access.
                    </li>
                    <li>
                        Superuser – A role available to experienced users with additional rights, such as marking documents as popular, deleting documents, and changing document identities. The specific rights depend on the Category Owner&apos;s decision regarding what is available for the Publisher and Super User roles within a specific category. These settings can be reviewed in the Settings section for each category.
                    </li>
                    <li>
                        Approver – A role that allows users to review documents published by publishers in categories with an approval workflow (available only in specified categories).
                    </li>
                    <li>
                        Owner – A role with the highest level of responsibility. The owner is responsible for content decisions, managing category settings, approving user&apos;s rights in a category and managing their roles.
                    </li>
                </ul>
            </Paragraph>
        </StepContentDiv>
    );
}
