import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { IconButtonControl } from "../../../controls/Buttons/IconButtonControl";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import { TitleSpan } from "../filters/SC/TitleSpan";
import CategoryNavigationTree from "./CategoryNavigationTree";
import CategoryTreeViewNavigation from "./CategoryTreeViewNavigation";
import { CategoryTreeHeaderDiv } from "./SC/CategoryTreeHeaderDiv";
import { CategoryTreeNavigationCheckboxes } from "./SC/CategoryTreeNavigationCheckboxesSC";
import { CategoryTreeNavigation } from "./SC/CategoryTreeNavigationSC";
import { CategoryTreeBox } from "./SC/CategoryTreeSC";
import { useState } from "react";
import { SelfHelpButtonControl } from "../../../controls/Buttons/SelfHelpButtonControl";
import { SelfHelpPage } from "../../../models/selfHelp/selfHelpSection";
import { SectionName } from "../../../app/slices/models/documentDetailsModels";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";

interface CategoryTreeContainerProps {
  onToggleClick: () => void;
}

export function CategoryTreeContainer(props: CategoryTreeContainerProps) {
  const [isError, setIsError] = useState(false);

  return (
    <CategoryTreeNavigation id="CategoryTreeNavigation">
      <CategoryTreeHeaderDiv>
        <TitleSpan>Navigation</TitleSpan>
        <SelfHelpButtonControl
          page={SelfHelpPage.List}
          section={SectionName.Navigation}
          currentSelfHelp={CurrentSelfHelp.Navigation}
        />
        <IconButtonControl
          id="navigation-collapse-button"
          popupText={TooltipMsg.Hide}
          icon={<KeyboardDoubleArrowLeftIcon fontSize="inherit" />}
          onClick={props.onToggleClick}
        />
      </CategoryTreeHeaderDiv>
      <CategoryTreeBox id="CategoryTreeBox">
        <CategoryNavigationTree setIsError={setIsError} />
      </CategoryTreeBox>
      <CategoryTreeNavigationCheckboxes id="CategoryTreeNavigationCheckboxes">
        <CategoryTreeViewNavigation isError={isError} />
      </CategoryTreeNavigationCheckboxes>
    </CategoryTreeNavigation>
  );
}
