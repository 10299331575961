import { CircularProgress } from "@mui/material";

interface NavigationCategoryFacetsProgress {
  categoryId: string;
}

function NavigationCategoryFacetsProgress({
  categoryId,
}: NavigationCategoryFacetsProgress) {
  return (
    <CircularProgress
      id={`${categoryId}-circular-progress`}
      size={10}
      color="secondary"
      sx={{
        marginLeft: "2.5px",
      }}
    />
  );
}

export default NavigationCategoryFacetsProgress;
