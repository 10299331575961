import { ContentDiv } from "./styledComponents/ContentDiv";
import { HeaderDiv } from "./styledComponents/HeaderDiv";
import { NoResourceFoundDiv } from "./styledComponents/NoResourceFoundDiv";

interface NoResourceFoundProps {
  headerMessage: string;
  contentMessage: string;
  headerId?: string;
}

export function NoResourceFound({
  headerMessage,
  contentMessage,
  headerId,
}: NoResourceFoundProps) {
  return (
    <NoResourceFoundDiv>
      <HeaderDiv id={headerId ?? ""}>{headerMessage}</HeaderDiv>
      <ContentDiv>{contentMessage}</ContentDiv>
    </NoResourceFoundDiv>
  );
}
