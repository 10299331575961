import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks/hooks";
import { selectIsResizing } from "../../../app/slices/contentSlice";
import {
  selectIsBreakingDocumentLink,
  selectIsUpdatingDocument,
} from "../../../app/slices/documentDetailsSlice";
import { selectIsDocumentsListFetching } from "../../../app/slices/documentListSlice";
import { LinearProgressBar } from "../../../controls/ProgressIndicators/LinearProgressBar";
import { LinearProgressDiv } from "../../../controls/ProgressIndicators/SC/LinearProgressDiv";
import { DetailsContextMenu } from "../../details/contextMenu/DetailsContextMenu";
import { DocumentsHeader } from "../../documents/documentsHeader/DocumentsHeader";
import { ContextMenuHeaderDiv } from "../SC/ContextMenuHeaderDiv";
import { ContextMenuListSettingsDiv } from "../SC/ContextMenuListSettingsDiv";
import { StyledDivider } from "../SC/StyledDivider";

interface ContextMenuHeaderProps {
  useLoader?: boolean;
  isError?: boolean;
}

export function ContextMenuHeader(props: ContextMenuHeaderProps) {
  const location = useLocation();
  const isDetailsPath = location.pathname.includes("/document");
  const isDocumentListPath = location.pathname == "/";
  const isResizing = useAppSelector(selectIsResizing);
  const isDocumentListDataFetching = useAppSelector(
    selectIsDocumentsListFetching
  );
  const isBreakingDocumentLink = useAppSelector(selectIsBreakingDocumentLink);
  const isUpdatingDocument = useAppSelector(selectIsUpdatingDocument);
  const [isDataFetching, setIsDataFetching] = useState(false);
  const justifyContent = isDetailsPath ? "end" : "";

  useEffect(() => {
    if (isDocumentListPath) {
      setIsDataFetching(isDocumentListDataFetching);
    }

    if (isDetailsPath) {
      setIsDataFetching(isUpdatingDocument || isBreakingDocumentLink);
    }
  }, [
    isDocumentListPath,
    isDetailsPath,
    isDocumentListDataFetching,
    isUpdatingDocument,
    isBreakingDocumentLink,
  ]);

  const getContent = () => {
    if (isDocumentListPath) return <DocumentsHeader />;
    if (isDetailsPath)
      return (
        <DetailsContextMenu
          isError={props.isError}
          isDataFetching={isDataFetching}
        />
      );

    return <></>;
  };

  return (
    <ContextMenuHeaderDiv id="context-menu-header-div">
      <ContextMenuListSettingsDiv
        $justifyContent={justifyContent}
        $loaderPadding={props.useLoader}
        id="context-menu-header-settings-div"
      >
        {!isResizing && getContent()}
      </ContextMenuListSettingsDiv>
      {props.useLoader && (
        <LinearProgressDiv
          data-active={isDataFetching}
          $isVisible={isDataFetching}
          id="context-menu-linear-progress-div"
        >
          <LinearProgressBar />
        </LinearProgressDiv>
      )}
      <StyledDivider variant="fullWidth" />
    </ContextMenuHeaderDiv>
  );
}
