import styled from "styled-components";

interface ContextMenuButtonsDivProps {
  $justify?: string;
}

export const ContextMenuButtonsDiv = styled.div<ContextMenuButtonsDivProps>`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: ${(props) =>
    props.$justify ? props.$justify : "space-between"};
  flex-grow: 1;
`;
