import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function PublisherTabStep3() {
    return (
        <StepContentDiv>
            <Paragraph>
                The Publisher section is used to present all publisher rights for selected publisher.
            </Paragraph>
        </StepContentDiv>
    );
}
