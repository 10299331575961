import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../app/hooks/hooks";
import {
  selectUserEmail,
  selectUserName,
} from "../../../../app/slices/authenticationSlice";
import { selectSelectedPublisher } from "../../../../app/slices/publishersViewSlice";
import { Person } from "../../../../models/person";
import { PublisherTabDiv } from "../../SC/PublisherTabDiv";
import { TabDiv } from "../../SC/TabDiv";
import { PublisherCategories } from "../../publisherCategories/PublisherCategories";

export function PublisherTab() {
  const selectedPublisher = useAppSelector(selectSelectedPublisher);
  const currentUserEmail = useAppSelector(selectUserEmail);
  const currentUserName = useAppSelector(selectUserName);
  const [currentPerson, setCurrentPerson] = useState<Person>({
    name: currentUserName,
    email: currentUserEmail,
  });

  useEffect(() => {
    let person: Person = {
      name: currentUserName,
      email: currentUserEmail,
    };

    if (selectedPublisher) {
      person = {
        name: selectedPublisher.name,
        email: selectedPublisher.email,
      };
    }

    setCurrentPerson(person);
  }, [selectedPublisher, currentUserName, currentUserEmail]);

  return (
    <TabDiv id="publisher-tab-div">
      <PublisherTabDiv>
        <PublisherCategories person={currentPerson} />
      </PublisherTabDiv>
    </TabDiv>
  );
}
