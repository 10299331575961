import styled from "styled-components";
import { ToggleButton } from "@mui/material";

interface StyledToggleButtonProps {
  $isEdited?: boolean;
}

export const StyledToggleButton = styled(ToggleButton)<StyledToggleButtonProps>`
  &.MuiButtonBase-root {
    padding: 0px 2px;
    line-height: normal;

    &.MuiButtonBase-root.Mui-selected:not(.disabled-with-tooltip) {
      color: ${(props) => props.theme.palettes.grayscale.white};
      background-color: ${(props) =>
        props.$isEdited
          ? props.theme.palettes.secondary.info
          : props.theme.palettes.primary.main};
      font-weight: 800;
    }

    &.disabled-with-tooltip:not(.Mui-selected) {
      &:hover {
        background-color: unset;
      }
    }

    &.disabled-with-tooltip.Mui-selected {
      &:hover {
        background-color: ${(props) =>
          props.theme.palettes.grayscale.disabledHighlight};
      }

      outline: ${(props) =>
        "1px solid " + props.theme.palettes.secondary.error};
    }
  }
`;
