import { createApi } from "@reduxjs/toolkit/query/react";
import { publishApiBaseQuery } from "./baseQueries";
import DocumentMetadata, {
  Identity,
} from "../models/documentDetails/documentMetadata";

export interface DocumentDraftRequest {
  identity: Identity;
  documentMetadata: DocumentMetadata;
}

export const documentDraftApi = createApi({
  reducerPath: "documentDraftApi",
  baseQuery: publishApiBaseQuery,
  endpoints: (builder) => ({
    createDraftForNewDocument: builder.mutation<null, DocumentDraftRequest>({
      query: (data: {
        identity: Identity;
        documentMetadata: DocumentMetadata;
      }) => ({
        url: `Documents/${encodeURIComponent(
          data.identity.documentNumber
        )}/${data.identity.documentLanguageCodes.join("")}/${encodeURIComponent(
          data.identity.documentPart
        )}/Revisions/${data.identity.revision}/Drafts/NewDocument`,
        method: "POST",
        body: data.documentMetadata,
      }),
    }),
    discardDocumentDraft: builder.query<null, Identity>({
      query: (identity: Identity) => ({
        url: `Documents/${encodeURIComponent(
          identity.documentNumber
        )}/${identity.documentLanguageCodes.join("")}/${encodeURIComponent(
          identity.documentPart
        )}/Revisions/${identity.revision}/Draft`,
        method: "DELETE",
      }),
    }),
    updateDraftForNewDocument: builder.mutation<null, DocumentDraftRequest>({
      query: (data: {
        identity: Identity;
        documentMetadata: DocumentMetadata;
      }) => ({
        url: `Documents/${encodeURIComponent(
          data.identity.documentNumber
        )}/${data.identity.documentLanguageCodes.join("")}/${encodeURIComponent(
          data.identity.documentPart
        )}/Revisions/${data.identity.revision}/Drafts/NewDocument`,
        method: "PATCH",
        body: data.documentMetadata,
      }),
    }),
    createOrUpdateDraftForExistingDocument: builder.mutation<
      null,
      DocumentDraftRequest
    >({
      query: (data: {
        identity: Identity;
        documentMetadata: DocumentMetadata;
      }) => ({
        url: `Documents/${encodeURIComponent(
          data.identity.documentNumber
        )}/${data.identity.documentLanguageCodes.join("")}/${encodeURIComponent(
          data.identity.documentPart
        )}/Revisions/${data.identity.revision}/Drafts/EditDocument`,
        method: "PUT",
        body: data.documentMetadata,
      }),
    }),
    createOrUpdateDraftForNewRevision: builder.mutation<
      null,
      DocumentDraftRequest
    >({
      query: (data: {
        identity: Identity;
        documentMetadata: DocumentMetadata;
      }) => ({
        url: `Documents/${encodeURIComponent(
          data.identity.documentNumber
        )}/${data.identity.documentLanguageCodes.join("")}/${encodeURIComponent(
          data.identity.documentPart
        )}/Revisions/Drafts/NewRevision`,
        method: "PUT",
        body: data.documentMetadata,
      }),
    }),
    generateDocumentNumber: builder.query<string, null>({
      query: () => ({
        url: `Documents/DocumentNumber`,
        method: "GET",
        responseHandler: "text",
      }),
      keepUnusedDataFor: 0.1,
    }),
  }),
});

export const {
  useCreateOrUpdateDraftForExistingDocumentMutation,
  useCreateOrUpdateDraftForNewRevisionMutation,
  useUpdateDraftForNewDocumentMutation,
  useCreateDraftForNewDocumentMutation,
  useGenerateDocumentNumberQuery,
  useLazyGenerateDocumentNumberQuery,
  useDiscardDocumentDraftQuery,
  useLazyDiscardDocumentDraftQuery,
} = documentDraftApi;
export default documentDraftApi.reducer;
