import styled from "styled-components";

export const BreadcrumbList = styled.ol`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  list-style: none;
  line-height: 1.5;
`;
