import { DialogControl } from "../../controls/Dialog/DialogControl";
import { StyledDialogContentText } from "../details/selfHelpPopup/SC/StyledDialogContentText";

interface ZoomPopupProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const ZoomPopup = (props: ZoomPopupProps) => {
  const handleClose = props.handleClose;

  return (
    <DialogControl
      id={"zoom-popup"}
      isOpen={props.isOpen}
      fullWidth
      useActions={true}
      title={"Zoom in/out"}
      content={
        <StyledDialogContentText>
          {
            "In order to zoom in or out please use CTRL + MouseWheelUp or CTRL + MouseWheelDown. Alternatively, please use your browser's Zoom options (usually under ... icon for browser settings)."
          }
        </StyledDialogContentText>
      }
      onCloseClick={handleClose}
      maxWidth={"sm"}
      cancelText={"Close"}
      hideConfirmButton={true}
    />
  );
};
