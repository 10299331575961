import { Checkbox } from "@mui/material";
import styled from "styled-components";

export const CategoryTreeFiltersCheckbox = styled(Checkbox)`
  &.Mui-checked {
    color: ${(props) => props.theme.palettes.grayscale.dark} !important;
  }

  &.MuiCheckbox-root {
    padding: 1px 2px 0px 8px;
  }

  &.MuiCheckbox-root:hover {
    background-color: transparent;
  }

  .MuiSvgIcon-root {
    font-size: font-size: ${(props) =>
      props.theme.typography.fontSizes.medium}px;
  }
`;
