import { DialogControl } from "../../../../../../controls/Dialog/DialogControl";
import { StyledDialogContentText } from "../../../../selfHelpPopup/SC/StyledDialogContentText";

interface WarningPopupProps {
  title: string;
  id: string;
  content: string;
  isOpen: boolean;
  onCloseClick: () => void;
  onConfirmClick: () => void;
}

function WarningPopup(props: WarningPopupProps) {
  return (
    <DialogControl
      id={props.id}
      title={props.title}
      onCloseClick={props.onCloseClick}
      onConfirmClick={props.onConfirmClick}
      maxWidth="sm"
      useActions={true}
      isOpen={props.isOpen}
      content={
        <StyledDialogContentText id="alert-dialog-description">
          {props.content}
        </StyledDialogContentText>
      }
      confirmText="Yes, I accept these values will be removed"
      cancelText="No, I will review this document"
    />
  );
}

export default WarningPopup;
