import { TableSortLabel } from "@mui/material";
import styled from "styled-components";

export interface TableSortLabelStyledProps {
  $isSorted: boolean;
  width: number;
}

function getMargin(isSorted: boolean) {
  if (isSorted) return "3px";
  return "unset";
}

export const TableSortLabelStyled = styled(
  TableSortLabel
)<TableSortLabelStyledProps>`
  &.MuiTableSortLabel-root {
    width: ${(props) => props.width}px;
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;
    margin-top: ${(props) => getMargin(props.$isSorted)};
  }

  &.MuiTableSortLabel-root .MuiSvgIcon-root {
    padding: 0;
    margin: 0;
  }
`;
