/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dashboard } from "../dashboard/Dashboard";
import { ContentDiv } from "./SC/ContentDiv";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import useWindowDimensions from "../../app/hooks/useWindowDimensions";
import { useEffect } from "react";
import { contentMinWidth } from "../../app/slices/models/documentsModels";
import {
  HandlerProps,
  ReflexContainer,
  ReflexElement,
} from "../../controls/Reflex";
import { selectIsWindowTooSmall } from "../../app/slices/commonSlice";
import {
  margins,
  navigationDefaultWidth,
  navigationMinWidth,
  windowMinWidth,
} from "../../app/slices/models/contentModels";
import {
  selectNavigationWidth,
  selectContentWidth,
  selectFitToScreen,
  setNavigationWidth,
  setContentWidth,
  setIsResizing,
} from "../../app/slices/contentSlice";
import {
  selectFiltersFlex,
  selectIsNavigationMinimized,
  setIsNavigationMinimized,
} from "../../app/slices/navigationSlice";
import { ToggleNavigation } from "../documentsNavigation/ToggleNavigation";
import { DocumentsNavigation } from "../documentsNavigation/DocumentsNavigation";
import {
  setSplitViewInLocalStorage,
  setNavigationSplitViewInLocalStorage,
} from "../../app/hooks/localStorage";
import { StyledReflexSplitter } from "./SC/StyledReflexSplitter";

const minimizedSize = 20;

export function MainView() {
  const dispatch = useAppDispatch();
  const { windowWidth } = useWindowDimensions();
  const navigationWidth = useAppSelector(selectNavigationWidth);
  const contentWidth = useAppSelector(selectContentWidth);
  const fitToScreen = useAppSelector(selectFitToScreen);
  const isMinimizedByUser = useAppSelector(selectIsNavigationMinimized);
  const isWindowTooSmall = useAppSelector(selectIsWindowTooSmall);
  const maxNavigationWidth = windowWidth - contentMinWidth;
  const filtersFlex = useAppSelector(selectFiltersFlex);
  const isMinimized = isMinimizedByUser || isWindowTooSmall;
  useEffect(() => {
    dispatch(
      setContentWidth(
        windowWidth - (isMinimized ? minimizedSize : navigationWidth)
      )
    );
  }, [navigationWidth, windowWidth, isMinimized, dispatch]);

  useEffect(() => {
    setSplitViewInLocalStorage({
      navigationWidth: navigationWidth,
      contentWidth: contentWidth,
      fitToScreen: fitToScreen,
    });
  }, [navigationWidth, contentWidth, fitToScreen]);

  useEffect(() => {
    setNavigationSplitViewInLocalStorage({
      filtersFlex: filtersFlex,
      isMinimized: isMinimizedByUser,
    });
  }, [filtersFlex, isMinimizedByUser]);

  const onChangeSizeClick = () => {
    dispatch(setIsNavigationMinimized(!isMinimizedByUser));
  };

  const onNavigationStopResize = (args: HandlerProps) => {
    const el = args.domElement as Element;
    if (windowWidth < windowMinWidth) {
      return;
    }

    if (el.clientWidth > navigationMinWidth) {
      dispatch(setNavigationWidth(el.clientWidth));
      dispatch(setContentWidth(windowWidth - el.clientWidth));
    } else {
      dispatch(setNavigationWidth(navigationMinWidth));
      dispatch(setContentWidth(windowWidth - navigationMinWidth));
    }
  };

  const onStartResize = () => {
    dispatch(setIsResizing(true));
  };

  const onStopResize = () => {
    setTimeout(() => {
      dispatch(setIsResizing(false));
    }, 200);
  };

  return (
    <ContentDiv id="content-div">
      <ReflexContainer orientation="vertical" windowResizeAware={true}>
        <ReflexElement
          flex={navigationWidth / windowWidth}
          onStopResize={onNavigationStopResize}
          className="left-pane"
          minSize={isMinimized ? minimizedSize : navigationDefaultWidth}
          maxSize={isMinimized ? minimizedSize : maxNavigationWidth}
        >
          <DocumentsNavigation
            isMinimized={isMinimized}
            onToggleClick={onChangeSizeClick}
          />
          <ToggleNavigation
            onToggleClick={onChangeSizeClick}
            isMinimized={isMinimized}
          />
        </ReflexElement>

        <StyledReflexSplitter
          id="dashboard-vertical-splitter"
          $isMinimized={isMinimized}
          onStartResize={onStartResize}
          onStopResize={onStopResize}
        />
        <ReflexElement
          flex={(contentWidth + margins) / windowWidth}
          className="right-pane"
        >
          <Dashboard />
        </ReflexElement>
      </ReflexContainer>
    </ContentDiv>
  );
}
