import { useCallback, useEffect, useState } from "react";
import {
  FileInfo,
  extractFileInfoFromFileName,
} from "../../../../app/helpers/attachmentHelper";
import { useDocumentEditedMode } from "../../../../app/hooks/document/useDocumentEditedMode";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { initialEditableState } from "../../../../app/hooks/permissions/models/state";
import { useUserCanEditDocument } from "../../../../app/hooks/permissions/useUserCanEditDocument";
import { useUserPermissionsInDocument } from "../../../../app/hooks/permissions/useUserPermissionsInDocument";
import {
  selectIsBreakingDocumentLink,
  selectIsLoadingDetailsMetaData,
} from "../../../../app/slices/documentDetailsSlice";
import {
  clearEditedDocumentAttachment,
  selectCurrentDocumentAttachment,
} from "../../../../app/slices/documentMetadataSlice";
import {
  selectHasAttachmentSectionAnyValidationErrors,
  setIsDraftValidationOn,
} from "../../../../app/slices/documentMetadataValidationSlice";
import {
  clearAttachmentInfo,
  selectFileUploadRetryCounter,
  selectFileUploadRetryErrorMessage,
  selectIsAttachmentUploadInProgress,
  selectIsFileUploadError,
  selectIsFileUploading,
  setShouldAbortFileUpload,
} from "../../../../app/slices/fileUploadSlice";
import { SectionName } from "../../../../app/slices/models/documentDetailsModels";
import { FormHelperControl } from "../../../../controls/FormHelper/FormHelperControl";
import { CurrentSelfHelp } from "../../../../models/CurrentSelfHelpType";
import { DetailsSection, DetailsSectionProps } from "../DetailsSection";
import { AttachmentInfo } from "./AttachmentInfo";
import { AttachmentUploadControl } from "./AttachmentUploadControl";
import { FileNameControl } from "./FileNameControl";

interface AttachmentSectionProps extends DetailsSectionProps {
  name?: string;
  size?: number;
  extension?: string;
}

export function AttachmentSection(props: AttachmentSectionProps) {
  const dispatch = useAppDispatch();
  const isLoadingDetailsMetaData = useAppSelector(
    selectIsLoadingDetailsMetaData
  );
  const [attachmentInfo, setAttachmentInfo] = useState<FileInfo>({
    extension: "",
    name: "",
  });
  const isAttachmentUploadInProgress = useAppSelector(
    selectIsAttachmentUploadInProgress
  );
  const currentDocumentAttachment = useAppSelector(
    selectCurrentDocumentAttachment
  );
  const isUploadRetryError = useAppSelector(selectIsFileUploadError);
  const retryCounter = useAppSelector(selectFileUploadRetryCounter);
  const uploadErrorMessage = useAppSelector(selectFileUploadRetryErrorMessage);

  const hasValidationErrors = useAppSelector(
    selectHasAttachmentSectionAnyValidationErrors
  );
  const { attamentSection } = useDocumentEditedMode();
  const isFileUploading = useAppSelector(selectIsFileUploading);
  const isBreakingDocumentLink = useAppSelector(selectIsBreakingDocumentLink);
  const [editableState, setEditableState] = useState(initialEditableState);
  const { canEditDocument } = useUserCanEditDocument();

  useEffect(() => {
    setEditableState(canEditDocument(false));
  }, [canEditDocument]);

  const { hasPermissionToDownloadAttachment } =
    useUserPermissionsInDocument(false);

  useEffect(() => {
    setAttachmentInfo(
      extractFileInfoFromFileName(currentDocumentAttachment.name)
    );
  }, [currentDocumentAttachment]);

  const discardAttachmentSection = useCallback(() => {
    dispatch(clearEditedDocumentAttachment());
    dispatch(clearAttachmentInfo(undefined));
    dispatch(setIsDraftValidationOn(false));
    dispatch(setShouldAbortFileUpload(true));
  }, [dispatch]);

  return (
    <DetailsSection
      {...props}
      section={SectionName.Attachment}
      name="Attachment"
      selfHelpType={CurrentSelfHelp.DetailsAttachment}
      isEdited={attamentSection.attachmentIsEdited}
      isValidationError={hasValidationErrors || isUploadRetryError}
      isLoadingDetailsMetaData={isLoadingDetailsMetaData}
      disableInteraction={isFileUploading}
      onDiscardChanges={discardAttachmentSection}
      isEditable={editableState.isEditable}
      notEditableReason={editableState.lockMessage}
    >
      <span id="file-name-upload-container">
        <FileNameControl
          extension={attachmentInfo.extension}
          name={attachmentInfo.name}
          isEditable={editableState.isEditable}
          notEditableReason={editableState.lockMessage}
        />
        <AttachmentUploadControl
          isEditable={editableState.isEditable && !isBreakingDocumentLink}
        />
        {isUploadRetryError && (
          <FormHelperControl
            isWarning={false}
            isError={true}
            texts={[`${uploadErrorMessage} ${retryCounter}s`]}
            marginTop={isAttachmentUploadInProgress ? 6 : 8}
          />
        )}
      </span>
      <AttachmentInfo
        isFileUploading={isAttachmentUploadInProgress || isFileUploading}
        extension={attachmentInfo.extension}
        hasUserPermissionToDownload={hasPermissionToDownloadAttachment}
        sizeInBytes={currentDocumentAttachment.size}
        paddingTop={isUploadRetryError ? 2 : 12}
      />
    </DetailsSection>
  );
}
