import { Button } from "@mui/material";
import styled, { DefaultTheme } from "styled-components";

interface StyledButtonProps {
  height: number;
  colors: "primary" | "secondary";
}

function getColor(colors: "primary" | "secondary", theme: DefaultTheme) {
  if (colors === "primary") {
    return theme.palettes.grayscale.lightest;
  }

  return theme.palettes.grayscale.darkest;
}

function getBackgroundColor(
  colors: "primary" | "secondary",
  theme: DefaultTheme
) {
  if (colors === "primary") {
    return theme.palettes.primary.main;
  }

  return theme.palettes.grayscale.lightest2;
}

export const StyledButton = styled(Button)<StyledButtonProps>`
  &.MuiButton-root {
    height: ${(props) => props.height}px;
    max-height: ${(props) => props.height}px;
    white-space: nowrap;
    color: ${(props) => getColor(props.colors, props.theme)};
    background-color: ${(props) =>
      getBackgroundColor(props.colors, props.theme)};
  }
  &.MuiButton-root:hover {
    background-color: ${(props) =>
      getBackgroundColor(props.colors, props.theme)};
    filter: brightness(90%);
  }
`;
