import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import usePrompt from "../../app/hooks/usePrompt";
import { selectLogoutPrompt } from "../../app/slices/documentDetailsSlice";
import { clearTagValues, selectTagsIsEdited } from "../../app/slices/tagsSlice";
import { Tags } from "../tags/Tags";
import { ContentDiv } from "./SC/ContentDiv";

export function TagsView() {
  const dispatch = useAppDispatch();
  const logoutPrompt = useAppSelector(selectLogoutPrompt);
  const tagsIsEdited = useAppSelector(selectTagsIsEdited);

  usePrompt(
    "All changes will be lost. Are you sure you want to leave?",
    tagsIsEdited && !logoutPrompt
  );

  useEffect(() => {
    return () => {
      dispatch(clearTagValues());
    };
  }, [dispatch]);

  return (
    <ContentDiv id="content-div">
      <Tags />
    </ContentDiv>
  );
}
