import styled from "styled-components";

export const DocumentsFooterDiv = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  height: 40px;
`;
