import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SecurityLevelStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The document list is narrowed down to documents with the selected
        security level combination.
      </Paragraph>
      <Paragraph>By default, all combinations are displayed.</Paragraph>
      <Paragraph>
        Values can be selected or deselected by clicking on them.
      </Paragraph>
      <Paragraph>The results are automatically reloaded.</Paragraph>
    </StepContentDiv>
  );
}
