import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { ContextMenuStepProps } from "./models";
import ShareIcon from "@mui/icons-material/Share";

export function ShareUrlStep(props: ContextMenuStepProps) {
    const theme = useTheme();

    return (
        <StepContentDiv>
            <Paragraph>
                {!props.isVisible && <><ShareIcon style={{
                    fontSize: "16px",
                    verticalAlign: "middle",
                    color: theme.palettes.grayscale.dark
                }} /><b> SHARE URL</b> - </>}
                This action enables the user to copy the document URL. Please note that the URL in draft documents may not be final.
            </Paragraph>
        </StepContentDiv>
    );
}
