import { ReactElement, useCallback } from "react";
import {
  getSectionHeaderColor,
  getValidationStatusText,
} from "../../app/helpers/validationHelper";
import { TooltipTop } from "../Tooltips/ToolipTop";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { StyledWarningIcon } from "./SC/StyledWarningIcon";
import { StyledEditIcon } from "./SC/StyledEditIcon";
import { TooltipMsg } from "../Tooltips/TooltipMessages";

interface PopupIconProps {
  id: string;
  isEditable: boolean;
  isError?: boolean;
  isEdited?: boolean;
  isWarning?: boolean;
  showSelect: boolean;
}

export function PopupIconControl(props: PopupIconProps) {
  if (props.isEditable) {
    return (
      <TooltipTop title={props.showSelect ? TooltipMsg.Close : TooltipMsg.Open}>
        <ArrowDropDownIcon />
      </TooltipTop>
    );
  }

  const getIcon = useCallback((): ReactElement => {
    const editIcon = (
      <StyledEditIcon
        id={props.id + "-edit"}
        $color={getSectionHeaderColor(
          props.isError,
          props.isEdited,
          props.isWarning
        )}
      />
    );

    const warningIcon = (
      <StyledWarningIcon
        id={props.id + "-edit"}
        $color={getSectionHeaderColor(
          props.isError,
          props.isEdited,
          props.isWarning
        )}
      />
    );

    if (props.isEdited && !props.isWarning && !props.isError) return editIcon;
    if (props.isWarning || props.isError) return warningIcon;

    return <></>;
  }, [props.id, props.isEdited, props.isError, props.isWarning]);

  if (props.isEdited || props.isWarning || props.isError) {
    return (
      <TooltipTop
        title={getValidationStatusText(
          props.isError,
          props.isEdited,
          props.isWarning
        )}
      >
        {getIcon()}
      </TooltipTop>
    );
  }

  return null;
}
