import styled from "styled-components";

interface StyledCategoryItemIconDivProps {
  $isExpandable: boolean;
}

export const StyledCategoryItemIconDiv = styled.div<StyledCategoryItemIconDivProps>`
  display: block;
  font-size: ${(props) => props.theme.typography.fontSizes.medium}px;
  visibility: ${(props) => (props.$isExpandable ? "visible" : "hidden")};
  width: 15px;
  height: 12px;

  svg {
    padding-left: 2px;
    cursor: pointer;
    font-size: inherit;
  }
`;
