import { createApi } from "@reduxjs/toolkit/query/react";
import { AccessGroup } from "../models/accessGroup";
import { publishApiBaseQuery } from "./baseQueries";

export const accessGroupsApi = createApi({
  reducerPath: "accessGroupApi",
  baseQuery: publishApiBaseQuery,
  endpoints: (builder) => ({
    getAccessGroups: builder.query<AccessGroup[], string>({
      query: (categoryId) => ({
        url: `AccessGroups/${categoryId}`,
        method: "GET",
      }),
      // The data will be cached for 1 hour even if the query is not active, because its dictionary data
      keepUnusedDataFor: 3600,
    }),
    getAccessGroupsForEdit: builder.query<AccessGroup[], string>({
      query: (categoryId) => ({
        url: `AccessGroups/${categoryId}/Edit`,
        method: "GET",
      }),
    }),
    getAllAccessGroups: builder.query<AccessGroup[], null>({
      query: () => ({
        url: `AccessGroups`,
        method: "GET",
      }),
      // The data will be cached for 1 hour even if the query is not active, because its dictionary data
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const {
  useGetAccessGroupsQuery,
  useLazyGetAccessGroupsQuery,
  useGetAccessGroupsForEditQuery,
  useLazyGetAccessGroupsForEditQuery,
  useGetAllAccessGroupsQuery,
  useLazyGetAllAccessGroupsQuery,
} = accessGroupsApi;
export default accessGroupsApi.reducer;
