import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function DiscardChangesStep4() {
  return (
    <StepContentDiv>
      <Paragraph>
        This action allows discarding all changes made. If any changes are made, the button becomes active.
      </Paragraph>
    </StepContentDiv>
  );
}
