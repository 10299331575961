import styled from "styled-components";

export const ScheduledActionDiv = styled.div`
  margin-bottom: 25px;
  margin-top: 8px;
  & .MuiDatePickerToolbar-root {
    padding: 0px;

    & .MuiDatePickerToolbar-title {
      margin-left: 22px;
    }

    & .MuiTypography-root {
      text-transform: unset;
    }

    & .MuiDialogActions-root {
      display: none;
    }
  }
`;
