import { Step } from "react-joyride";
import { createStep } from "../../helpers/selfHelpBuilder";
import { SearchStep } from "./AddCategories/SearchStep";
import { SelectCategoriesStepOne } from "./AddCategories/SelectCategoriesStepOne";
import { SelectCategoriesStepTwo } from "./AddCategories/SelectCategoriesStepTwo";
import { SaveChangesStep } from "./AddCategories/SaveChangesStep";

export const addCategoriesSteps = () => {
    const steps: Step[] = [];

    steps.push(createStep("#category-search-input-div", <SearchStep />, 520, "auto"));
    steps.push(
      createStep("#category-tree-ul", <SelectCategoriesStepOne />, 410, "right")
    );
    steps.push(
      createStep(
        "#category-tree-dialog-content",
        <SelectCategoriesStepTwo />,
        410,
        "right"
      )
    );
    steps.push(
      createStep(
        "#new-categories-dialog-confirm-dialog-confirm-button",
        <SaveChangesStep />,
        520,
        "top"
      )
    );

    return steps;
};
