import { StyledSkeleton } from "../../../categoryNavigation/SC/StyledSkeleton";
import { TagTypeSkeletonBox } from "./SC/TagTypeSkeletonBox";

interface TagTypeSkeletonsProps {
  numberOfSkeletons: number;
}

export function TagTypeSkeletons(props: TagTypeSkeletonsProps) {
  return (
    <div id="tag-type-skeletons-loading">
      {Array.from({ length: props.numberOfSkeletons }, (_, i) => (
        <TagTypeSkeletonBox key={`skeleton ${i}`}>
          <StyledSkeleton
            variant="circular"
            height={24}
            width={24}
            $marginLeft={0}
            $marginRight={8}
          />
          <StyledSkeleton
            width={"100%"}
            height={24}
            variant="text"
            $marginLeft={0}
            $marginRight={0}
          />
        </TagTypeSkeletonBox>
      ))}
    </div>
  );
}
