import { createStep } from "../../helpers/selfHelpBuilder";
import { BreakLinkStep } from "./Attachment/BreakLinkStep";
import { DownloadStep } from "./Attachment/DownloadStep";
import { FileHashStep } from "./Attachment/FileHashStep";
import { FileNameStep } from "./Attachment/FileNameStep";

export const attachmentSteps = () => {
  return [
    createStep("#file-name-upload-container", <FileNameStep />, 620, "auto"),
    createStep("#attachment-info-file-hash-div", <FileHashStep />, 520, "auto"),
    createStep("#attachment-download-button", <DownloadStep />, 520, "auto"),
    createStep("#break-temporary-document-link-button", <BreakLinkStep />, 520, "auto")
  ];
};
