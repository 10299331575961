import { createApi } from "@reduxjs/toolkit/query/react";
import { publishApiBaseQuery } from "./baseQueries";
import { Identity } from "../models/documentDetails/documentMetadata";

export const revisionsApi = createApi({
  reducerPath: "revisionsApi",
  baseQuery: publishApiBaseQuery,
  endpoints: (builder) => ({
    getAvailableRevisions: builder.query<string[], Identity>({
      query: (identity: Identity) => ({
        url: "AvailableRevisions",
        method: "GET",
        params: {
          documentNumber: identity.documentNumber,
          documentPart:
            identity.documentPart === "" ? "_" : identity.documentPart,
          sortedLanguagesCodes: identity.documentLanguageCodes.join(""),
          revision: identity.revision,
        },
      }),
    }),
    getAvailableRevisionsNewRevision: builder.query<string[], Identity>({
      query: (identity: Identity) => ({
        url: "AvailableRevisionsNewRevision",
        method: "GET",
        params: {
          documentNumber: identity.documentNumber,
          documentPart:
            identity.documentPart === "" ? "_" : identity.documentPart,
          sortedLanguagesCodes: identity.documentLanguageCodes.join(""),
          revision: identity.revision,
        },
      }),
    }),
  }),
});

export const {
  useGetAvailableRevisionsQuery,
  useLazyGetAvailableRevisionsQuery,
  useGetAvailableRevisionsNewRevisionQuery,
  useLazyGetAvailableRevisionsNewRevisionQuery,
} = revisionsApi;
export default revisionsApi.reducer;
