import { Documents } from "../documents/Documents";
import { Footer } from "../footer/Footer";
import { DashboardDiv } from "./SC/DashboardDiv";

export function Dashboard() {
  return (
    <DashboardDiv id="dashboard-div">
      <Documents />
      <Footer />
    </DashboardDiv>
  );
}
