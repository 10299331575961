import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import groupIcon from "../../../../../icons/mutliple_doc_outlined.svg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { StyledImg } from "../../../SC/StyledImg";

export function DocumentGroupStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        Documents on the Document List are grouped by document number, followed
        by language, part, and revision.
      </Paragraph>
      <Paragraph>
        Collapsed document sections can be expanded by clicking on the section
        row.
      </Paragraph>
      <Paragraph>
        When a document has multiple revisions for a specific document number,
        it is indicated by a{" "}
        <StyledImg src={groupIcon} alt="Grouped document icon" /> two-document
        icon and a{" "}
        <KeyboardArrowRightIcon
          style={{
            fontSize: "16px",
            verticalAlign: "middle",
          }}
        />{" "}
        chevron.
      </Paragraph>
      <Paragraph>
        Only the document number and the title of the newest document in the
        group are displayed.
      </Paragraph>
    </StepContentDiv>
  );
}
