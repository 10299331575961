import { useCallback, useEffect, useMemo } from "react";
import { openSnackbar } from "../../app/helpers/snackBarHelper";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { selectIsLoadingDetailsMetaData } from "../../app/slices/documentDetailsSlice";
import {
  setLastFetchedAttachmentId,
  setLastFetchedAttachmentUploadInfo,
  setLastFetchedUploadId,
} from "../../app/slices/fileUploadSlice";
import { setEditedAttachmentId } from "../../app/slices/documentMetadataSlice";
import { SnackbarType } from "../../models/snackbar";
import {
  useAttachmentUploadInfoMutation,
  useCompletedAttachmentInfoMutation,
} from "../fileStatusApi";
import { AttachmentUploadInfo } from "../../models/documentDetails/documentAttachment";
import { UploadStatus } from "../../models/documentDetails/uploadStatus";
import { SnackbarMsg } from "../../controls/Snackbar/SnackbarMessages";

export function useAttachmentUploadStatus() {
  const dispatch = useAppDispatch();
  const isLoadingDetailsMetaData = useAppSelector(
    selectIsLoadingDetailsMetaData
  );
  const [
    fetchAttachmentUploadInfo,
    {
      isError: isUploadInfoError,
      data: uploadInfoData,
      isLoading: uploadInfoIsLoading,
    },
  ] = useAttachmentUploadInfoMutation();
  const [
    fetchCompletedAttachmentInfo,
    {
      isError: isAttachmentInfoError,
      data: attachmentInfoData,
      isLoading: attachmentInfoIsLoading,
    },
  ] = useCompletedAttachmentInfoMutation();

  useEffect(() => {
    if (isUploadInfoError) {
      openSnackbar(
        dispatch,
        SnackbarMsg.AttachmentStatusError,
        SnackbarType.error
      );
    }
  }, [dispatch, isUploadInfoError]);

  useEffect(() => {
    if (isAttachmentInfoError) {
      openSnackbar(
        dispatch,
        SnackbarMsg.AttachmentStatusError,
        SnackbarType.error
      );
    }
  }, [dispatch, isAttachmentInfoError]);

  const fetchUploadInfo = useCallback(
    (uploadId: string) => {
      void fetchAttachmentUploadInfo({ uploadId }).then(() => {
        dispatch(setLastFetchedUploadId(uploadId));
      });
    },
    [dispatch, fetchAttachmentUploadInfo]
  );

  const fetchAttachmentInfo = useCallback(
    (attachmentId: string) => {
      void fetchCompletedAttachmentInfo({ attachmentId }).then(() => {
        dispatch(setLastFetchedAttachmentId(attachmentId));
      });
    },
    [dispatch, fetchCompletedAttachmentInfo]
  );

  useEffect(() => {
    if (uploadInfoData !== undefined) {
      if (uploadInfoData.attachmentId) {
        dispatch(setEditedAttachmentId(uploadInfoData.attachmentId));
        return;
      }

      dispatch(setLastFetchedAttachmentUploadInfo(uploadInfoData));
    }
  }, [uploadInfoData, dispatch]);

  useEffect(() => {
    if (attachmentInfoData !== undefined) {
      const data: AttachmentUploadInfo = {
        ...attachmentInfoData,
        status: UploadStatus.Completed,
        canResumeUpload: false,
        chunksCount: 0,
        chunkSize: 0,
        lastSuccessfullyUploadedChunkNumber: 0,
      };

      dispatch(setLastFetchedAttachmentUploadInfo(data));
    }
  }, [attachmentInfoData, dispatch]);

  const isAttachmentInfoLoaded = useMemo(() => {
    return (
      !isLoadingDetailsMetaData &&
      !attachmentInfoIsLoading &&
      !uploadInfoIsLoading
    );
  }, [isLoadingDetailsMetaData, attachmentInfoIsLoading, uploadInfoIsLoading]);

  return {
    fetchUploadInfo,
    fetchAttachmentInfo,
    isAttachmentInfoLoaded,
  };
}
