import CloseIcon from "@mui/icons-material/Close";
import { DialogActions, DialogContent } from "@mui/material";
import { PreviousRevisionState } from "../../../apis/documentActionsApi";
import { IconButtonControl } from "../../../controls/Buttons/IconButtonControl";
import { DialogButton } from "../../../controls/Dialog/SC/DialogButton";
import { StyledDialog } from "../../../controls/Dialog/SC/StyledDialog";
import { StyledDialogTitle } from "../../../controls/Dialog/SC/StyledDialogTitle";
import { IconButtonControlDiv } from "../contextMenu/SC/IconButtonControlDiv";
import { StyledDialogContentText } from "../selfHelpPopup/SC/StyledDialogContentText";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";

export interface DeletePopupProps {
  isOpen: boolean;
  previousNonDeletedRevision?: PreviousRevisionState;
  onClose: () => void;
  onKeepArchived: () => void;
  onPublishPreviousRevision: () => void;
  onDeleteAllRevisions: () => void;
}

export function DeletePopup(props: DeletePopupProps) {
  return (
    <StyledDialog
      id={"set-deleted-dialog"}
      open={props.isOpen}
      onClose={props.onClose}
    >
      <StyledDialogTitle id={"set-deleted-dialog-title"}>
        {"Deleting document with previous revisions"}
        <IconButtonControlDiv>
          <IconButtonControl
            id={"set-deleted-dialog-close-button"}
            popupText={TooltipMsg.Close}
            onClick={props.onClose}
            icon={<CloseIcon fontSize="inherit" />}
          />
        </IconButtonControlDiv>
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText id="set-deleted-dialog-description">
          This document has at least one archived revision. What would you like
          to do with them?
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogButton color="primary" onClick={props.onKeepArchived} autoFocus>
          {"Keep Archived"}
        </DialogButton>
        {props.previousNonDeletedRevision?.canPreviousRevisionBePublished &&
          props.previousNonDeletedRevision.previousNonDeletedRevision !==
          "" && (
            <DialogButton
              color="primary"
              onClick={props.onPublishPreviousRevision}
              autoFocus
            >
              {"Publish latest revision"}
            </DialogButton>
          )}
        <DialogButton
          color="primary"
          onClick={props.onDeleteAllRevisions}
          autoFocus
        >
          {"Delete all revisions"}
        </DialogButton>
        <DialogButton
          id={"set-deleted-dialog-cancel-button"}
          color="secondary"
          onClick={props.onClose}
        >
          {"Cancel"}
        </DialogButton>
      </DialogActions>
    </StyledDialog>
  );
}
