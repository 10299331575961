import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function ActionBarStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                The available actions depend on the workflow of the opened document and whether it has been modified.
            </Paragraph>
            <Paragraph>
                If the action button is not visible then whole action bar will be highlighted.
            </Paragraph>
        </StepContentDiv>
    );
}
