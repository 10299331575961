import { TooltipTop } from "../../../../../../../controls/Tooltips/ToolipTop";
import { RoleInCategory } from "../../../../../../../models/rolesInCategory";
import { StyledCheckIcon } from "../SC/StyledCheckIcon";

interface NoAccessContentProps {
  role: RoleInCategory;
}

export function DirectAccessContent(props: NoAccessContentProps) {
  return (
    <TooltipTop
      title={props.role + " role assigned directly in the selected category"}
    >
      <StyledCheckIcon />
    </TooltipTop>
  );
}
