import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import styled from "styled-components";
import { defaultTheme } from "../../../../../../app/theme";

interface StyledUnfoldLessIconProps {
  $disabled: boolean;
}

export const StyledUnfoldLessIcon = styled(
  UnfoldLessIcon
)<StyledUnfoldLessIconProps>`
  transform: rotate(90deg);
  color: ${defaultTheme.palettes.grayscale.dark};
  cursor: ${(props) => (props.$disabled ? "arrow" : "pointer")};
`;
