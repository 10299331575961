import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { StyledLink } from "../../../SC/StyledLink";

export function SectionStepOne() {
    return (
        <StepContentDiv>
            <Paragraph>
                The category structure is defined in ABB Products, and can be viewed at <StyledLink href="https://tools.products.abb.com/TreeViewer/" target="_blank">Tree Viewer</StyledLink>.
            </Paragraph>
            <Paragraph>
                It is utilized to classify documents according to their relevant context. Each document must be classified in at least one category.
            </Paragraph>
            <Paragraph>
                It is recommended to classify the document to the lowest level category possible, even if it requires assigning multiple categories.  Classifying the document to a higher-level category should only be done if the intention is to exclude it from being visible in the categories below.
            </Paragraph>
        </StepContentDiv>
    );
}
