import styled from "styled-components";
import InfoIcon from "@mui/icons-material/Info";

export const DocumentKindsInfoIcon = styled(InfoIcon)`
  color: ${(props) => props.theme.palettes.grayscale.dark};

  &.MuiSvgIcon-root {
    font-size: ${(props) => props.theme.typography.fontSizes.small}px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    cursor: pointer;
  }
`;
