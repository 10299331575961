import styled from "styled-components";

interface TreeUlProps {
  isExpanded: boolean;
}

export const TreeUl = styled.ul<TreeUlProps>`
  display: ${(props) => (props.isExpanded ? "flex" : "none")};
  flex-direction: column;
  flex-grow: 1;
  padding-inline: 0px;
  margin: 0px;
`;
