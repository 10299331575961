import { StyledErrorIcon } from "../../../../controls/FormHelper/SC/StyledErrorIcon";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ShowMoreErrorButton } from "./styledComponents/ShowMoreErrorButton";

export interface LoadMoreErrorProps {
  errorMessage: string;
  reloadDataHandler?: () => void;
}

export function LoadMoreError(props: LoadMoreErrorProps) {
  return (
    <ShowMoreErrorButton
      id="load-more-error-button"
      onClick={props.reloadDataHandler}
      startIcon={
        <StyledErrorIcon $paddingRight={4} color="error" fontSize="inherit" />
      }
      endIcon={<RefreshIcon color="error" fontSize="inherit" />}
    >
      {props.errorMessage}
    </ShowMoreErrorButton>
  );
}
