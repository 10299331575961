import styled from 'styled-components';
import { defaultTheme } from "../../theme";

const StyledSpan = styled.span`
  display: flex;
  justify-content: center;
  font-family: ${defaultTheme.typography.fontFamily};
  font-size: ${defaultTheme.typography.fontSizes.medium}px;
  color: ${defaultTheme.palettes.grayscale.darkest};
`;

export default StyledSpan;
