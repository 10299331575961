import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/securityLevel/access_groups.png"

interface AccessGroupsStepProps {
    showScreenshot?: boolean;
}

export function AccessGroupsStep(props: AccessGroupsStepProps) {
    return (
        <StepContentDiv>
            <Paragraph>
                When the Limited level is selected, at least one access group must be provided. An access group represents a predefined group of users.
            </Paragraph>
            <Paragraph>
                The available access groups are determined by the selected Main Category.
            </Paragraph>
            {props.showScreenshot && <Paragraph>
                <Screenshot src={screenshot} />
            </Paragraph>}
        </StepContentDiv>
    );
}
