import { DatePicker } from "@mui/x-date-pickers";
import styled from "styled-components";

interface StyledDatePickerProps {
  $marginBottom?: number;
  $isEditable?: boolean;
}

export const StyledDatePicker = styled(DatePicker)<StyledDatePickerProps>`
  &.MuiFormControl-root .MuiTextField-root {
    flex-grow: 1;
  }
  &.MuiTextField-root {
    margin-bottom: ${(props) =>
      props.$marginBottom !== undefined ? props.$marginBottom + "px" : "8px"};
  }
  & .MuiOutlinedInput-root {
    pointer-events: ${(props) => (props.$isEditable ? "auto" : "none")};
    fieldset {
      border-color: ${(props) =>
        props.$isEditable
          ? props.theme.palettes.grayscale.medium
          : props.theme.palettes.grayscale.white};
    }
  }
`;
