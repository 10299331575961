import { AuthenticatedTemplate } from "@azure/msal-react";
import { Logout } from "@mui/icons-material";
import { Route, useLocation } from "react-router-dom";
import { DetailsView } from "../components/content/DetailsView";
import { MainView } from "../components/content/MainView";
import { NewDocumentRevision } from "../components/content/NewDocumentRevision";
import { NewDocumentView } from "../components/content/NewDocumentView";
import { PublishersView } from "../components/content/PublishersView";
import { SettingsView } from "../components/content/SettingsView";
import { TagsView } from "../components/content/TagsView";
import { DocumentDetailView } from "../models/documentDetailView";
import { routing } from "../models/routingPaths";
import AppWrapper from "./AppWrapper";
import QaWrapper from "./QaWrapper";

const NotFoundView = () => {
  const location = useLocation();

  if (location.pathname === "/qa_auth") {
    return <QaWrapper />;
  }

  //Pecaution for situations when redirect from web server won't work
  window.location.replace("/404.html");
  return null;
};

export function Routers() {
  return (
    <Route>
      <Route element={<AppWrapper />}>
        <Route path="/" element={<MainView />} />
        <Route path={routing.publishers} element={<PublishersView />} />
        <Route path={routing.tags} element={<TagsView />} />
        <Route path={routing.settings} element={<SettingsView />} />
        <Route
          path={routing.newDocument}
          element={<NewDocumentView view={DocumentDetailView.NewDocument} />}
        />
        <Route
          path={routing.newPart}
          element={<NewDocumentView view={DocumentDetailView.NewPart} />}
        />
        <Route
          path={routing.newLanguage}
          element={<NewDocumentView view={DocumentDetailView.NewLanguage} />}
        />
        <Route
          path={routing.newTemplate}
          element={
            <NewDocumentView view={DocumentDetailView.NewFromTemplate} />
          }
        />
        <Route path={routing.newRevision} element={<NewDocumentRevision />} />
        <Route path={routing.details}>
          <Route
            path=":documentNumber/:languageCodes/:part/Revisions/:revision"
            element={<DetailsView />}
          />
          <Route
            path=":documentNumber/:languageCodes/:part/Revisions/:revision/Draft"
            element={<DetailsView />}
          />
        </Route>

        <Route
          path="/logout"
          element={
            <AuthenticatedTemplate>
              <Logout />
            </AuthenticatedTemplate>
          }
        />
      </Route>
      <Route path="*" element={<NotFoundView />} />
    </Route>
  );
}
