import { FormControlLabel } from "@mui/material";
import styled from "styled-components";

export const CategoryTreeFiltersLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root:hover {
    color: ${(props) => props.theme.palettes.primary.main};
  }

  &.MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: ${(props) => props.theme.typography.fontSizes.medium}px;
  }
`;
