import { useTheme } from "styled-components";
import { TextButtonControl } from "../../../../controls/Buttons/TextButtonControl";
import SaveIcon from "@mui/icons-material/Save";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import TagsListItem from "../../../../models/tags/tagsListItem";

interface SaveChangesButtonProps {
  isEdited?: boolean;
  canEdit?: boolean;
  isLoading: boolean;
  hasErrors: boolean;
  selectedTag?: TagsListItem;
  onSaveTagValues: () => void;
}

export function SaveChangesButton(props: SaveChangesButtonProps) {
  const theme = useTheme();

  const disableTooltipText = () => {
    switch (true) {
      case !props.selectedTag:
        return TooltipMsg.ChooseTagFirst;
      case props.hasErrors:
        return TooltipMsg.IsSomeValidationErrors;
      case !props.canEdit:
        return TooltipMsg.NoAccessToTag;
      case !props.isEdited:
        return TooltipMsg.NoChangesMade;
      default:
        return "";
    }
  };

  return (
    <TextButtonControl
      id={"document-settings-save-button"}
      colors={"primary"}
      height={theme.shapes.primaryButtonHeight}
      text={"Save changes"}
      onClick={props.onSaveTagValues}
      isCompactView={false}
      isVertical={false}
      isVisible={true}
      disabled={
        !props.isEdited || !props.canEdit || props.isLoading || props.hasErrors
      }
      disabledTooltipText={disableTooltipText()}
      icon={<SaveIcon fontSize="small" />}
    />
  );
}
