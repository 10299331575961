import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";

export const StyledDataGrid = styled(DataGrid)`
  &.MuiDataGrid-root {
    width: 100%;
    height: 100%;
    border: none;
    overflow-y: auto;
  }

  .MuiDataGrid-cell {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-row--firstVisible {
    --rowBorderColor: transparent !important;
  }
`;
