import { cloneDeep, find } from "lodash";
import { Category } from "../../models/categoryTree";
import {
  CategoryGroup,
  CategoryPath,
  CategoryPathMetadata,
} from "../../models/documentDetails/documentMetadata";
import NavigationCategory from "../../models/navigationCategories/navigationCategory";
import { CategoryToLink } from "../../apis/documentApi";

export function comparePaths(a: CategoryPath, b: CategoryPath) {
  const elementALength = a.categoryPath.length;
  const elementBLength = b.categoryPath.length;

  for (let i = 0; i < Math.max(elementALength, elementBLength); i++) {
    let elementA = null;
    let elementB = null;

    const CategoryPathA = cloneDeep(a.categoryPath).reverse();
    const CategoryPathB = cloneDeep(b.categoryPath).reverse();
    if (elementALength >= i) elementA = CategoryPathA[i];
    if (elementBLength >= i) elementB = CategoryPathB[i];

    if (
      (elementB &&
        elementA &&
        elementA.categoryTranslation < elementB.categoryTranslation) ||
      (elementB && elementA == null)
    ) {
      return -1;
    }
    if (
      (elementB &&
        elementA &&
        elementA.categoryTranslation > elementB.categoryTranslation) ||
      (elementA && elementB == null)
    ) {
      return 1;
    }
  }
  return 0;
}

export function convertCategoryPathToCategoryGroup(paths: CategoryPath[]) {
  const groups: CategoryGroup[] = [];

  paths.forEach((catPath) => {
    const topCategory = catPath.categoryPath.slice(-1)[0];
    const groupIndex = groups.findIndex((g) => {
      return g.topCategoryName == topCategory.categoryTranslation;
    });

    if (groupIndex >= 0) {
      groups[groupIndex].categories.push(catPath);
    } else {
      groups.push({
        topCategoryName: topCategory.categoryTranslation,
        topCategoryId: topCategory.categoryId,
        categories: [catPath],
      });
    }
  });

  return groups.sort((a, b) =>
    a.topCategoryName > b.topCategoryName
      ? 1
      : b.topCategoryName > a.topCategoryName
      ? -1
      : 0
  );
}

export function buildLifeCycleText(category: CategoryPathMetadata | undefined) {
  let text = "";
  if (category) {
    const regex = /\[(\w+)\]/g;
    const matches = generateLabel(
      category.isDeleted,
      category.isInternalVisibleOnly,
      category.lifeCyclePhase
    ).matchAll(regex);
    const categories = Array.from(matches, (match) => match[1]);

    text = categories.join(" ");
  }

  return text;
}

export function buildCategoryText(category: CategoryPathMetadata | undefined) {
  let text = "";
  if (category) {
    text += category.categoryTranslation;
    text += generateLabel(
      category.isDeleted,
      category.isInternalVisibleOnly,
      category.lifeCyclePhase
    );
  }

  return text;
}

export function getLabelWithFacets(node: NavigationCategory): string {
  let categoryName =
    node.categoryName +
    generateLabel(
      node.isDeleted,
      node.isInternalVisibleOnly,
      node.lifeCyclePhase
    );

  if (node.numberOfDocuments !== undefined) {
    categoryName += ` (${node.numberOfDocuments})`;
  }

  return categoryName;
}

export function prepareLabelFromCategory(
  category: Category | NavigationCategory
): string {
  return (
    category.categoryName +
    generateLabel(
      false,
      category.isInternalVisibleOnly,
      category.lifeCyclePhase
    )
  );
}

export function generateLabel(
  isDeleted = false,
  isInternalVisibleOnly = false,
  lifeCyclePhase?: string
): string {
  let text = "";

  if (isDeleted) text += " [Deleted]";
  if (isInternalVisibleOnly) text += " [Hidden]";
  if (!isDeleted && lifeCyclePhase) text += " [" + lifeCyclePhase + "]";

  return text;
}

export function getCategoriesDiff(
  categories1: CategoryToLink[],
  categories2: CategoryToLink[]
): CategoryToLink[] {
  return categories1.filter(
    (cat1) =>
      !find(categories2, (cat2: CategoryToLink) => {
        return (
          cat1.cid === cat2.cid && cat1.isPrioritized === cat2.isPrioritized
        );
      })
  );
}

export function getCategoryIdsDiff(
  categories1: CategoryToLink[],
  categories2: CategoryToLink[]
): CategoryToLink[] {
  return categories1.filter(
    (cat1) =>
      !find(categories2, (cat2: CategoryToLink) => {
        return cat1.cid === cat2.cid;
      })
  );
}
