import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import ClearIcon from "@mui/icons-material/Clear";

export function MultipleFiltersStep() {
  const theme = useTheme();

  return (
    <StepContentDiv>
      <Paragraph>
        The document list is narrowed down to documents with selected values by
        Document kinds, Languages, Publishers, and Access groups.
      </Paragraph>
      <Paragraph>
        Multiple values can be selected, and only one needs to match.
      </Paragraph>
      <Paragraph>The results are automatically reloaded.</Paragraph>
      <Paragraph>
        The input can be cleared using the{" "}
        <ClearIcon
          fontSize="small"
          style={{
            fontSize: "16px",
            color: theme.palettes.grayscale.dark,
            verticalAlign: "middle",
          }}
        />{" "}
        clear icon.
      </Paragraph>
    </StepContentDiv>
  );
}
