import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

export function DocumentNumberAndTitlesStep() {
  const theme = useTheme();

  return (
    <StepContentDiv>
      <Paragraph>
        The document list is narrowed down to documents that contain the
        typed-in text anywhere in the Document number and Document title fields.
      </Paragraph>
      <Paragraph>
        New results can be loaded by pressing Enter or using the{" "}
        <SearchIcon
          fontSize="small"
          style={{
            fontSize: "16px",
            color: theme.palettes.grayscale.dark,
            verticalAlign: "middle",
          }}
        />{" "}
        magnifying glass icon.
      </Paragraph>
      <Paragraph>
        The input can be cleared using the{" "}
        <ClearIcon
          fontSize="small"
          style={{
            fontSize: "16px",
            color: theme.palettes.grayscale.dark,
            verticalAlign: "middle",
          }}
        />{" "}
        clear icon.
      </Paragraph>
    </StepContentDiv>
  );
}
