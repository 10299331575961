import { LinearProgressProps, LinearProgress, Typography } from "@mui/material";
import { ProgressLabelDiv } from "./SC/ProgressLabelDiv";
import { LinearProgressWithLabelDiv } from "./SC/LinearProgressWithLabelDiv";
import { LinearProgressDiv } from "./SC/LinearProgressDiv";

export function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <LinearProgressWithLabelDiv>
      <LinearProgressDiv $isVisible={true}>
        <LinearProgress variant="determinate" {...props} />
      </LinearProgressDiv>
      <ProgressLabelDiv>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </ProgressLabelDiv>
    </LinearProgressWithLabelDiv>
  );
}
