import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CategoryPermissionType } from "../../models/cms/categoryPermissionType";
import { RootState } from "../store";
import DocumentKind from "../../models/documentKind";
import { AccessGroup } from "../../models/accessGroup";
import { ValidationStatus } from "../../models/validationRule";

export interface PublisherCategorySettingsState {
  currentCategorySettings: PublisherCategorySettings;
  categorySettings: PublisherCategorySettings;
  allDocumentKinds: DocumentKind[];
  allAccessGroups: AccessGroup[];
  isLoderVisible: boolean;
  reloadSettings: boolean;
  isEditable: boolean;
  validationState: ValidationState;
  selectedCategoryId: string;
  selectedCategoryName: string;
}

export interface Permissions {
  deleteDocument: CategoryPermissionType;
  changePopularCategory: CategoryPermissionType;
  editDocumentIdentity: CategoryPermissionType;
}

export interface Settings {
  permanentlyDeletedInDays: number;
  approvalWorkflowEnabled: boolean;
  highLevelWarningThreshold: number;
}

interface PublisherCategorySettings {
  categoryId: string;
  documentKindIds: number[];
  accessGroupIds: number[];
  settings: Settings;
  permissions: Permissions;
}

interface ValidationState {
  permanentlyDeletedInDays: ValidationStatus;
  highLevelWarningThreshold: ValidationStatus;
}

const initialState: PublisherCategorySettingsState = {
  currentCategorySettings: {
    categoryId: "",
    documentKindIds: [],
    accessGroupIds: [],
    permissions: {
      deleteDocument: CategoryPermissionType.SuperUser,
      changePopularCategory: CategoryPermissionType.SuperUser,
      editDocumentIdentity: CategoryPermissionType.SuperUser,
    },
    settings: {
      approvalWorkflowEnabled: false,
      permanentlyDeletedInDays: 1,
      highLevelWarningThreshold: 1,
    },
  },
  categorySettings: {
    categoryId: "",
    documentKindIds: [],
    accessGroupIds: [],
    permissions: {
      deleteDocument: CategoryPermissionType.SuperUser,
      changePopularCategory: CategoryPermissionType.SuperUser,
      editDocumentIdentity: CategoryPermissionType.SuperUser,
    },
    settings: {
      approvalWorkflowEnabled: false,
      permanentlyDeletedInDays: 1,
      highLevelWarningThreshold: 1,
    },
  },
  selectedCategoryId: "",
  selectedCategoryName: "",
  allDocumentKinds: [],
  allAccessGroups: [],
  isLoderVisible: false,
  reloadSettings: false,
  isEditable: false,
  validationState: {
    permanentlyDeletedInDays: ValidationStatus.success,
    highLevelWarningThreshold: ValidationStatus.success,
  },
};

export const publisherCategoriesSettingsSlice = createSlice({
  name: "publisherCategoriesSettingsState",
  initialState: initialState,
  reducers: {
    setCategoryId(state, action: PayloadAction<string>) {
      state.currentCategorySettings.categoryId = action.payload;
      state.categorySettings.categoryId = action.payload;
    },
    setSelectedCategoryId(state, action: PayloadAction<string>) {
      state.selectedCategoryId = action.payload;
    },
    setSelectedCategoryName(state, action: PayloadAction<string>) {
      state.selectedCategoryName = action.payload;
    },
    setIsLoaderVisible(state, action: PayloadAction<boolean>) {
      state.isLoderVisible = action.payload;
    },
    setAccessGroups(state, action: PayloadAction<number[]>) {
      state.currentCategorySettings.accessGroupIds = action.payload;
      state.categorySettings.accessGroupIds = action.payload;
    },
    setPermission(state, action: PayloadAction<Permissions>) {
      state.categorySettings.permissions = action.payload;
      state.currentCategorySettings.permissions = action.payload;
    },
    setSettings(state, action: PayloadAction<Settings>) {
      state.categorySettings.settings = action.payload;
      state.currentCategorySettings.settings = action.payload;
    },
    removeCurrentAccessGroup(state, action: PayloadAction<number>) {
      state.currentCategorySettings.accessGroupIds =
        state.currentCategorySettings.accessGroupIds.filter(
          (id) => id !== action.payload
        );
    },
    addCurrentAccessGroup(state, action: PayloadAction<number>) {
      if (
        !state.currentCategorySettings.accessGroupIds.includes(action.payload)
      ) {
        state.currentCategorySettings.accessGroupIds = [
          ...state.currentCategorySettings.accessGroupIds,
          action.payload,
        ];
      }
    },
    setDocumentKindIds(state, action: PayloadAction<number[]>) {
      state.currentCategorySettings.documentKindIds = action.payload;
      state.categorySettings.documentKindIds = action.payload;
    },
    addCurrentDocumentKindId(state, action: PayloadAction<number>) {
      if (
        !state.currentCategorySettings.documentKindIds.includes(action.payload)
      ) {
        state.currentCategorySettings.documentKindIds = [
          ...state.currentCategorySettings.documentKindIds,
          action.payload,
        ];
      }
    },
    removeCurrentDocumentKindId(state, action: PayloadAction<number>) {
      state.currentCategorySettings.documentKindIds =
        state.currentCategorySettings.documentKindIds.filter(
          (id) => id !== action.payload
        );
    },
    setCurrentDeleteDocument(
      state,
      action: PayloadAction<CategoryPermissionType>
    ) {
      state.currentCategorySettings.permissions.deleteDocument = action.payload;
    },
    setCurrentPermanentlyDeletedInDays(state, action: PayloadAction<number>) {
      state.currentCategorySettings.settings.permanentlyDeletedInDays =
        action.payload;
    },
    setCurrentHighLevelWarningThreshold(state, action: PayloadAction<number>) {
      state.currentCategorySettings.settings.highLevelWarningThreshold =
        action.payload;
    },
    setCurrentApprovalWorkflowEnabled(state, action: PayloadAction<boolean>) {
      state.currentCategorySettings.settings.approvalWorkflowEnabled =
        action.payload;
    },
    setCurrentChangePopularCategory(
      state,
      action: PayloadAction<CategoryPermissionType>
    ) {
      state.currentCategorySettings.permissions.changePopularCategory =
        action.payload;
    },
    setCurrentEditDocumentIdentity(
      state,
      action: PayloadAction<CategoryPermissionType>
    ) {
      state.currentCategorySettings.permissions.editDocumentIdentity =
        action.payload;
    },
    setReloadSettings(state, action: PayloadAction<boolean>) {
      state.reloadSettings = action.payload;
    },
    clearCurrentPublisherCategoriesSettings(
      state: PublisherCategorySettingsState
    ) {
      state.currentCategorySettings = state.categorySettings;
      state.validationState = initialState.validationState;
    },
    clearPublisherCategoriesSettings(state: PublisherCategorySettingsState) {
      state.categorySettings = initialState.categorySettings;
      state.currentCategorySettings = initialState.currentCategorySettings;
      state.isEditable = initialState.isEditable;
      state.isLoderVisible = initialState.isLoderVisible;
    },
    setStateAfterSave(state: PublisherCategorySettingsState) {
      state.categorySettings = state.currentCategorySettings;
    },
    setInitialSettingsValues(state: PublisherCategorySettingsState) {
      state.currentCategorySettings.accessGroupIds =
        initialState.currentCategorySettings.accessGroupIds;
      state.currentCategorySettings.documentKindIds =
        initialState.currentCategorySettings.documentKindIds;
      state.currentCategorySettings.permissions =
        initialState.currentCategorySettings.permissions;
      state.currentCategorySettings.settings =
        initialState.currentCategorySettings.settings;

      state.categorySettings.accessGroupIds =
        initialState.categorySettings.accessGroupIds;
      state.categorySettings.documentKindIds =
        initialState.categorySettings.documentKindIds;
      state.categorySettings.permissions =
        initialState.categorySettings.permissions;
      state.categorySettings.settings = initialState.categorySettings.settings;
    },
    setAllDocumentKinds(state, action: PayloadAction<DocumentKind[]>) {
      state.allDocumentKinds = action.payload;
    },
    setAllAccessGroups(state, action: PayloadAction<AccessGroup[]>) {
      state.allAccessGroups = action.payload;
    },
    setIsEditable(state, action: PayloadAction<boolean>) {
      state.isEditable = action.payload;
    },
    setHighLevelWarningThresholdState(
      state,
      action: PayloadAction<ValidationStatus>
    ) {
      state.validationState.highLevelWarningThreshold = action.payload;
    },
    setPermanentlyDeletedInDaysState(
      state,
      action: PayloadAction<ValidationStatus>
    ) {
      state.validationState.permanentlyDeletedInDays = action.payload;
    },
  },
});

export const {
  setAccessGroups,
  addCurrentAccessGroup,
  removeCurrentAccessGroup,
  setDocumentKindIds,
  addCurrentDocumentKindId,
  removeCurrentDocumentKindId,
  setCategoryId,
  setSelectedCategoryId,
  setSelectedCategoryName,
  setPermission,
  setSettings,
  setCurrentApprovalWorkflowEnabled,
  setCurrentChangePopularCategory,
  setCurrentDeleteDocument,
  setCurrentEditDocumentIdentity,
  setCurrentHighLevelWarningThreshold,
  setCurrentPermanentlyDeletedInDays,
  setIsLoaderVisible,
  setReloadSettings,
  clearCurrentPublisherCategoriesSettings,
  setInitialSettingsValues,
  setAllDocumentKinds,
  setAllAccessGroups,
  setIsEditable,
  clearPublisherCategoriesSettings,
  setPermanentlyDeletedInDaysState,
  setHighLevelWarningThresholdState,
  setStateAfterSave,
} = publisherCategoriesSettingsSlice.actions;

export const isSomeValidationErrors = (state: RootState) =>
  state.publisherCategoriesSettingsState.validationState
    .highLevelWarningThreshold === ValidationStatus.error ||
  state.publisherCategoriesSettingsState.validationState
    .permanentlyDeletedInDays === ValidationStatus.error;
export const selectIsEditable = (state: RootState) =>
  state.publisherCategoriesSettingsState.isEditable;
export const selectAllDocumentKinds = (state: RootState) =>
  state.publisherCategoriesSettingsState.allDocumentKinds;
export const selectAllAccessGroups = (state: RootState) =>
  state.publisherCategoriesSettingsState.allAccessGroups;
export const selectCurrentAccessGroups = (state: RootState) =>
  state.publisherCategoriesSettingsState.currentCategorySettings.accessGroupIds;
export const selectAccessGroups = (state: RootState) =>
  state.publisherCategoriesSettingsState.categorySettings.accessGroupIds;
export const selectCurrentDocumentKinds = (state: RootState) =>
  state.publisherCategoriesSettingsState.currentCategorySettings
    .documentKindIds;
export const selectDocumentKinds = (state: RootState) =>
  state.publisherCategoriesSettingsState.categorySettings.documentKindIds;
export const selectCurrentDeleteDocument = (state: RootState) =>
  state.publisherCategoriesSettingsState.currentCategorySettings.permissions
    .deleteDocument;
export const selectCurrentEditDocumentIdentity = (state: RootState) =>
  state.publisherCategoriesSettingsState.currentCategorySettings.permissions
    .editDocumentIdentity;
export const selectCurrentHighLevelWarningThreshold = (state: RootState) =>
  state.publisherCategoriesSettingsState.currentCategorySettings.settings
    .highLevelWarningThreshold;
export const selectCurrentPermanentlyDeletedInDays = (state: RootState) =>
  state.publisherCategoriesSettingsState.currentCategorySettings.settings
    .permanentlyDeletedInDays;
export const selectCurrentChangePopularCategory = (state: RootState) =>
  state.publisherCategoriesSettingsState.currentCategorySettings.permissions
    .changePopularCategory;
export const selectCurrentApprovalWorkflowEnabled = (state: RootState) =>
  state.publisherCategoriesSettingsState.currentCategorySettings.settings
    .approvalWorkflowEnabled;
export const selectCategoryId = (state: RootState) =>
  state.publisherCategoriesSettingsState.currentCategorySettings.categoryId;
export const selectSelectedCategoryName = (state: RootState) =>
  state.publisherCategoriesSettingsState.selectedCategoryName;
export const selectSelectedCategoryId = (state: RootState) =>
  state.publisherCategoriesSettingsState.selectedCategoryId;
export const selectCurrentPermission = (state: RootState) =>
  state.publisherCategoriesSettingsState.currentCategorySettings.permissions;
export const selectCurrentSettings = (state: RootState) =>
  state.publisherCategoriesSettingsState.currentCategorySettings.settings;
export const selectIsLoaderVisible = (state: RootState) =>
  state.publisherCategoriesSettingsState.isLoderVisible;
export const selectReloadSettings = (state: RootState) =>
  state.publisherCategoriesSettingsState.reloadSettings;
export const selectIsAccessGroupsEdited = (state: RootState) => {
  const current =
    state.publisherCategoriesSettingsState.currentCategorySettings
      .accessGroupIds;
  const original =
    state.publisherCategoriesSettingsState.categorySettings.accessGroupIds;

  if (current.length !== original.length) return true;

  const sortedCurrentAccessGroups = current.slice().sort((a, b) => a - b);
  const sortedAccessGroups = original.slice().sort((a, b) => a - b);
  return !sortedAccessGroups.every(
    (value, index) => value === sortedCurrentAccessGroups[index]
  );
};
export const selectIsDocumentKindsEdited = (state: RootState) => {
  const current =
    state.publisherCategoriesSettingsState.currentCategorySettings
      .documentKindIds;
  const original =
    state.publisherCategoriesSettingsState.categorySettings.documentKindIds;

  if (current.length !== original.length) return true;

  const sortedCurrentDocumentKinds = current.slice().sort((a, b) => a - b);
  const sortedDocumentKinds = original.slice().sort((a, b) => a - b);

  return !sortedDocumentKinds.every(
    (value, index) => value === sortedCurrentDocumentKinds[index]
  );
};
export const selectIsPermissionsEdited = (state: RootState) => {
  return (
    selectPermissionChangePopularIsEdited(state) ||
    selectPermissionDeleteDocumentIsEdited(state) ||
    selectPermissionEditIdentityIsEdited(state)
  );
};

export const selectPermissionEditIdentityIsEdited = (state: RootState) => {
  const settings = state.publisherCategoriesSettingsState.categorySettings;
  const currentSettings =
    state.publisherCategoriesSettingsState.currentCategorySettings;

  return (
    settings.permissions.editDocumentIdentity !=
    currentSettings.permissions.editDocumentIdentity
  );
};

export const selectPermissionChangePopularIsEdited = (state: RootState) => {
  const settings = state.publisherCategoriesSettingsState.categorySettings;
  const currentSettings =
    state.publisherCategoriesSettingsState.currentCategorySettings;

  return (
    settings.permissions.changePopularCategory !=
    currentSettings.permissions.changePopularCategory
  );
};

export const selectPermissionDeleteDocumentIsEdited = (state: RootState) => {
  const settings = state.publisherCategoriesSettingsState.categorySettings;
  const currentSettings =
    state.publisherCategoriesSettingsState.currentCategorySettings;

  return (
    settings.permissions.deleteDocument !=
    currentSettings.permissions.deleteDocument
  );
};

export const selectIsSettingsEdited = (state: RootState) => {
  return (
    selectSettingsApprovalWorkflowIsEdited(state) ||
    selectSettingsHighLevelWarningIsEdited(state) ||
    selectSettingsPermanentlyDeletedIsEdited(state)
  );
};

export const selectSettingsPermanentlyDeletedIsEdited = (state: RootState) => {
  const settings = state.publisherCategoriesSettingsState.categorySettings;
  const currentSettings =
    state.publisherCategoriesSettingsState.currentCategorySettings;

  return (
    settings.settings.permanentlyDeletedInDays !=
    currentSettings.settings.permanentlyDeletedInDays
  );
};

export const selectSettingsApprovalWorkflowIsEdited = (state: RootState) => {
  const settings = state.publisherCategoriesSettingsState.categorySettings;
  const currentSettings =
    state.publisherCategoriesSettingsState.currentCategorySettings;

  return (
    settings.settings.approvalWorkflowEnabled !=
    currentSettings.settings.approvalWorkflowEnabled
  );
};

export const selectSettingsHighLevelWarningIsEdited = (state: RootState) => {
  const settings = state.publisherCategoriesSettingsState.categorySettings;
  const currentSettings =
    state.publisherCategoriesSettingsState.currentCategorySettings;

  return (
    settings.settings.highLevelWarningThreshold !=
    currentSettings.settings.highLevelWarningThreshold
  );
};

export const selectIsEdited = (state: RootState) => {
  const settings = state.publisherCategoriesSettingsState.categorySettings;
  const currentSettings =
    state.publisherCategoriesSettingsState.currentCategorySettings;

  return (
    settings.categoryId != currentSettings.categoryId ||
    selectIsPermissionsEdited(state) ||
    selectIsSettingsEdited(state) ||
    selectIsAccessGroupsEdited(state) ||
    selectIsDocumentKindsEdited(state)
  );
};

export default publisherCategoriesSettingsSlice.reducer;
