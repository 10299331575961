import { useCallback, useEffect, useState } from "react";
import { AccessGroup } from "../../../models/accessGroup";
import { useLazyGetAccessGroupsQuery } from "../../../apis/accessGroupsApi";
import { openSnackbar } from "../../helpers/snackBarHelper";
import { SnackbarType } from "../../../models/snackbar";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectCurrentDocumentMainCategory } from "../../slices/documentMetadataSlice";
import { SnackbarMsg } from "../../../controls/Snackbar/SnackbarMessages";

export function useDocumentDetailsAccessGroups() {
  const dispatch = useAppDispatch();
  const [accessGroupsFromCategory, setAccessGroupsFromCategory] = useState<
    AccessGroup[]
  >([]);
  const currentMainCategory = useAppSelector(selectCurrentDocumentMainCategory);
  const [isAccessGroupsLoading, setIsAccessGroupsLoading] = useState(false);

  const [getAccessGroups, { data: response, isError: isAccessGroupsError }] =
    useLazyGetAccessGroupsQuery();

  const fetchAccessGroupsByMainCategory = useCallback(
    (setIsLoading: boolean) => {
      if (currentMainCategory) {
        setIsAccessGroupsLoading(setIsLoading);
        getAccessGroups(currentMainCategory.cid, true)
          .unwrap()
          .catch(() =>
            openSnackbar(
              dispatch,
              SnackbarMsg.AccessGroupsError,
              SnackbarType.error
            )
          )
          .finally(() => setIsAccessGroupsLoading(false));
      }
    },
    [currentMainCategory, dispatch, getAccessGroups]
  );

  useEffect(() => {
    if (response) {
      setAccessGroupsFromCategory(response);
    }
  }, [response]);

  return {
    fetchAccessGroups: fetchAccessGroupsByMainCategory,
    accessGroups: accessGroupsFromCategory,
    isAccessGroupsError,
    isAccessGroupsLoading,
  };
}
