import { createStep } from "../../helpers/selfHelpBuilder";
import { SectionStepOne } from "./Products/SectionStepOne";
import { SectionStepTwo } from "./Products/SectionStepTwo";

export const productsSteps = () => {
  return [
    createStep("#Products", <SectionStepOne />, 620, "auto"),
    createStep("#Products", <SectionStepTwo />, 620, "auto")
  ];
};
