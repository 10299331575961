import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function DocumentIdentityStep4() {
  return (
    <StepContentDiv>
      <Paragraph>
        In this section, permission to edit document identity (change Document number, Languages, Part ID, Revision) in the selected category and its subcategories can be granted to either super users or to both super users and publishers.
      </Paragraph>
      <Paragraph>
        The chosen option is highlighted in red.
      </Paragraph>
    </StepContentDiv>
  );
}
