import {
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarProps,
} from "@mui/x-data-grid";
import { Person } from "../../../../../../models/person";
import { PublisherInfo } from "../../../../publisherCategories/PublisherInfo";
import { StyledGridToolbarContainer } from "./SC/StyledGridToolbar";
import { StyledGridToolbarQuickFilter } from "./SC/StyledGridToolbarQuickFilter";
import { useTheme } from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import PublishersSearch from "../../../../publisherCategories/PublishersSearch";
import { CurrentSelfHelp } from "../../../../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../../../../models/selfHelp/selfHelpSection";
import { SectionName } from "../../../../../../app/slices/models/documentDetailsModels";
import { SelfHelpButtonControl } from "../../../../../../controls/Buttons/SelfHelpButtonControl";
import { CategoryHeaderDiv } from "../../../../SC/CategoryHeaderDiv";
import { useAppSelector } from "../../../../../../app/hooks/hooks";
import { selectCanExport, selectSelectedCategoryId, selectSelectedCategoryName } from "../../../../../../app/slices/publishersListSlice";

interface TableToolbarProps extends GridToolbarProps {
  displayQuickFilter?: boolean;
  displaySearch?: boolean;
  padding?: string;
  marginBottom?: number;
  person?: Person;
  currentSelfHelp?: CurrentSelfHelp;
  selfHelpPage?: SelfHelpPage;
  sectionName?: SectionName;
}

export function TableToolbar(props: TableToolbarProps) {
  const theme = useTheme();
  const categoryId = useAppSelector(selectSelectedCategoryId);
  const categoryName = useAppSelector(selectSelectedCategoryName);
  const canExport = useAppSelector(selectCanExport);

  return (
    <StyledGridToolbarContainer>
      {props.person && (
        <PublisherInfo
          primaryInfo={props.person.name ?? ""}
          secondaryInfo={props.person.email ?? ""}
        />
      )}
      {!props.person && <CategoryHeaderDiv disabled={!canExport}>
        {categoryName && `${categoryName} (CID: ${categoryId})`}
      </CategoryHeaderDiv>}
      <span id="data-grid-buttons">
        <GridToolbarColumnsButton slotProps={{
          button: {
            id: "data-grid-columns-button",
          }
        }} />
        <GridToolbarFilterButton slotProps={{
          button: {
            id: "data-grid-filter-button",
          }
        }} />
        <GridToolbarDensitySelector slotProps={{
          button: {
            id: "data-grid-density-button",
          }
        }} />
      </span>

      {props.displayQuickFilter && (
        <StyledGridToolbarQuickFilter
          id={"data-grid-quick-filter"}
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: (
              <SearchIcon
                fontSize="small"
                htmlColor={theme.palettes.grayscale.medium}
              />
            ),
            startAdornment: null,
          }}
        />
      )}
      {props.displaySearch && <PublishersSearch />}
      <SelfHelpButtonControl
        currentSelfHelp={props.currentSelfHelp}
        page={props.selfHelpPage ?? SelfHelpPage.Publishers}
        section={props.sectionName ?? SectionName.PublishersNavigation}
      />

    </StyledGridToolbarContainer>
  );
}
