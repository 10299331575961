import { TooltipTop } from "../../Tooltips/ToolipTop";
import { TooltipMsg } from "../../Tooltips/TooltipMessages";
import { StyledLeftButtonIcon } from "../SC/StyledLeftButtonIcon";

export function LeftDatePickerIcon() {
  return (
    <TooltipTop title={TooltipMsg.PreviousMonth}>
      <StyledLeftButtonIcon />
    </TooltipTop>
  );
}
