import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { Dictionary, sortBy } from "lodash";
import { Category } from "../../models/categoryTree";
import { RootState } from "../store";

interface OwnerCategoryTreeState {
  categoriesChildrenByParent: Dictionary<string[] | undefined>;
  categories: Dictionary<Category | undefined>;
  navigationViewState: NavigationViewState;
  selectedCategory: string;
}

interface NavigationViewState {
  isMinimized: boolean;
  navigationWidth: number;
  settingssWidth: number;
}

const initialOwnerCategoryTreeState: OwnerCategoryTreeState = {
  categoriesChildrenByParent: {},
  categories: {},
  navigationViewState: {
    isMinimized: false,
    navigationWidth: 0.2,
    settingssWidth: 0.8,
  },
  selectedCategory: "",
};

export const ownerCategoryTreeSlice = createSlice({
  name: "ownerCategoryTreeState",
  initialState: initialOwnerCategoryTreeState,
  reducers: {
    clearOwnerCategories(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.categories = {};
        state.categoriesChildrenByParent = {};
      }
    },
    updateOwnerCategories(state, action: PayloadAction<Category[]>) {
      const categories = sortBy(action.payload, ["categoryName"]);
      categories.map((category) => {
        if (
          state.categoriesChildrenByParent[category.parentCategoryId] ===
          undefined
        ) {
          state.categoriesChildrenByParent[category.parentCategoryId] = [];
        }

        state.categoriesChildrenByParent[category.parentCategoryId]?.push(
          category.categoryId
        );
      });

      categories.forEach((category) => {
        const childrenByParent =
          state.categoriesChildrenByParent[category.categoryId];
        state.categories[category.categoryId] = {
          ...category,
          isExpanded: !!childrenByParent && childrenByParent.length > 0,
        };
      });
    },
    setIsExpanded(
      state,
      action: PayloadAction<{ categoryId: string; isExpanded: boolean }>
    ) {
      const { categoryId, isExpanded } = action.payload;
      if (state.categories[categoryId] !== undefined) {
        (state.categories[categoryId] as Category).isExpanded = isExpanded;
      }
    },
    setIsNavigationMinimized(state, action: PayloadAction<boolean>) {
      state.navigationViewState.isMinimized = action.payload;
    },
    setNavigationWidth(state, action: PayloadAction<number>) {
      state.navigationViewState.navigationWidth = action.payload;
    },
    setSettingsWidth(state, action: PayloadAction<number>) {
      state.navigationViewState.settingssWidth = action.payload;
    },
    setSelectedNode(state, action: PayloadAction<string>) {
      state.selectedCategory = action.payload;
    },
    removeSelectedNode(state) {
      state.selectedCategory = "";
    },
  },
});

export const {
  clearOwnerCategories,
  updateOwnerCategories,
  setIsExpanded,
  setIsNavigationMinimized,
  setNavigationWidth,
  setSettingsWidth,
  setSelectedNode,
  removeSelectedNode,
} = ownerCategoryTreeSlice.actions;

export const selectCategoriesByParentDictionary = (state: RootState) =>
  state.ownerCategoryTreeState.categoriesChildrenByParent;

export const selectCategoriesDictionary = (state: RootState) =>
  state.ownerCategoryTreeState.categories;

export const selectIsNavigationMinimized = (state: RootState) =>
  state.ownerCategoryTreeState.navigationViewState.isMinimized;

export const selectSettingsWidth = (state: RootState) =>
  state.ownerCategoryTreeState.navigationViewState.settingssWidth;

export const selectNavigationWidth = (state: RootState) =>
  state.ownerCategoryTreeState.navigationViewState.navigationWidth;

export const selectSelectedNode = (state: RootState) =>
  state.ownerCategoryTreeState.selectedCategory;

export const selectCategoryChildrenByParentId = createSelector(
  [
    selectCategoriesByParentDictionary,
    (state, parentCategoryId: string) => parentCategoryId,
  ],
  (dictionary, parentCategoryId) => dictionary[parentCategoryId]
);

export const selectCategoryById = createSelector(
  [selectCategoriesDictionary, (state, categoryId: string) => categoryId],
  (dictionary, categoryId): Category | undefined => dictionary[categoryId]
);

export default ownerCategoryTreeSlice.reducer;
