import { createApi } from "@reduxjs/toolkit/query/react";
import { publishApiBaseQuery } from "./baseQueries";
import CategorySettings from "../models/cms/categorySettings";

export const publisherCategoriesSettingsApi = createApi({
  reducerPath: "publisherCategoriesSettingsApi",
  baseQuery: publishApiBaseQuery,
  endpoints: (builder) => ({
    savePublisherCategorySettings: builder.mutation<null, CategorySettings>({
      query: (categorySettings: CategorySettings) => ({
        url: `CategorySettings/${categorySettings.categoryId}`,
        method: "POST",
        body: categorySettings,
        keepUnusedDataFor: 300,
      }),
    }),
    getPublisherCategorySettings: builder.query<CategorySettings, string>({
      query: (categoryId: string) => ({
        url: `CategorySettings/${categoryId}`,
        method: "GET",
        keepUnusedDataFor: 300,
      }),
    }),
  }),
});

export const {
  useSavePublisherCategorySettingsMutation,
  useLazyGetPublisherCategorySettingsQuery,
} = publisherCategoriesSettingsApi;

export default publisherCategoriesSettingsApi.reducer;
