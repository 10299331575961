import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SaveChangesStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                If a document does not have a Main Category assigned, the first selected category will be designated as the Main category.
                The Categories section allows for the modification or removal of categories and their properties, such as Main or Popular.
                When the &quot;Save changes&quot; button is clicked, categories are added to the document.
            </Paragraph>
        </StepContentDiv>
    );
}
