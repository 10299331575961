import SvgIcon from "@mui/material/SvgIcon";

export interface CustomBookmarkSpecialIconProps {
    fontSize: string;
}

export function CustomBookmarkSpecialIcon(props: CustomBookmarkSpecialIconProps) {
    return (
        <SvgIcon style={{ fontSize: props.fontSize, fill: "white" }}>
            <svg
                height="24"
                viewBox="0 0 24 24"
                width="24"
                version="1.1"
                id="svg1"
                xmlns="http://www.w3.org/2000/svg">
                <defs
                    id="defs1" />
                <rect
                    fill="none"
                    height="24"
                    width="24"
                    id="rect1" />
                <g id="g1">
                    <path
                        id="path2"
                        d="M 7,3 C 5.9,3 5,3.9 5,5 v 16 l 7,-3 7,3 V 11 h -2 v 6.970703 L 12,15.830078 7,17.970703 V 5 h 6 V 3 Z M 16.191406,3.5136719 15.408203,5.3613281 13.404297,5.53125 14.925781,6.8515625 14.46875,8.8105469 16.191406,7.7695312 17.914062,8.8105469 17.460938,6.8515625 18.980469,5.53125 16.974609,5.359375 Z m 0,1.1425781 0.476563,1.1269531 1.222656,0.1054688 -0.925781,0.8027343 0.277344,1.1933594 L 16.191406,7.25 15.144531,7.8828125 15.421875,6.6894531 14.498047,5.8867188 15.71875,5.7792969 Z" />
                    <path
                        id="path1"
                        d="M 20.031033,5.6667988 17.10051,5.4140972 15.955201,2.7158963 14.809892,5.4181731 11.879369,5.6667988 14.104773,7.5946674 13.436337,10.459977 15.955201,8.9396921 18.474065,10.459977 17.809705,7.5946674 Z M 15.955201,8.1775114 14.422688,9.1027253 14.830272,7.3582692 13.477095,6.1844295 15.26231,6.0295479 15.955201,4.3869875 16.652169,6.0336237 18.437383,6.1885053 17.084207,7.362345 17.49179,9.1068012 Z M 17,11 v 6.97 L 12,15.83 7,17.97 V 5 h 6 V 3 H 7 C 5.9,3 5,3.9 5,5 v 16 l 7,-3 7,3 V 11 Z" />
                </g>
            </svg>
        </SvgIcon>
    );
}