import { useAppSelector } from "../../../app/hooks/hooks";
import { selectEnvironment } from "../../../app/slices/commonSlice";
import { Environment } from "../../../models/environment";
import { LogoDiv } from "./SC/LogoDiv";
import { LogoImg } from "./SC/LogoImg";
import { LogoSpan } from "./SC/LogoSpan";
import { StyledLink } from "./SC/StyledLink";

export function Logo() {
  const environment = useAppSelector(selectEnvironment);
  let envText = "";
  if (environment === Environment.Test) {
    envText = "Test Environment";
  } else if (environment === Environment.Stage) {
    envText = "Training Environment";
  }

  return (
    <LogoDiv>
      <StyledLink to="/" reloadDocument>
        <LogoImg src="/abbLogo.png" width={76} height={29} alt="ABB logo" />
      </StyledLink>
      <LogoSpan>
        <StyledLink to={"/"} reloadDocument>
          {`Library Publish Interface ${envText}`}
        </StyledLink>
      </LogoSpan>
    </LogoDiv>
  );
}
