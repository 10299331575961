import { useState } from "react";
import { useAppSelector } from "../hooks";
import { selectAttachmentUploadInfo } from "../../slices/fileUploadSlice";
import { useNavigate } from "react-router-dom";
import { useRouteHelper } from "../useRouteHelper";
import { Workflow } from "../../../models/documentList/types/workflow";

export function useDuplicateAttachment() {
  const navigate = useNavigate();
  const { getUrlToDocumentByIdentity } = useRouteHelper();
  const [isDuplicateAttachmentPopupOpen, setIsDuplicateAttachmentPopupOpen] =
    useState(false);
  const attachmentInfo = useAppSelector(selectAttachmentUploadInfo);
  const originalDocumentIdentity = attachmentInfo?.documentOriginalIdentity;
  const attachmentIsDuplicate = attachmentInfo?.isDuplicate;

  const navigateToOriginalDocumentHandler = () => {
    if (attachmentInfo && attachmentInfo.documentOriginalIdentity) {
      const url = getUrlToDocumentByIdentity({
        identity: {
          ...attachmentInfo.documentOriginalIdentity,
          documentLanguageCodes: [
            attachmentInfo.documentOriginalIdentity.documentLanguageCodes,
          ],
        },
        workflow: Workflow.Published,
      });
      navigate(`/document/${url}`);
    }
  };

  const closePopupHandler = () => {
    setIsDuplicateAttachmentPopupOpen(false);
  };

  const openPopupHandler = () => {
    setIsDuplicateAttachmentPopupOpen(true);
  };

  return {
    isDuplicateAttachmentPopupOpen,
    attachmentIsDuplicate,
    originalDocumentIdentity,
    navigateToOriginalDocumentHandler,
    closePopupHandler,
    openPopupHandler,
  };
}
