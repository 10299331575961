import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SplitterStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        Columns can be resized by using drag and drop to adjust their width.
      </Paragraph>
    </StepContentDiv>
  );
}
