import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function FiltersButtonStep4() {
    return (
        <StepContentDiv>
            <Paragraph>
                The list can be filtered out by Category with few operators:
                <ul>
                    <li>
                        Contains – Category name contains filter value
                    </li>
                    <li>
                        Equals – Category name contains exact value
                    </li>
                    <li>
                        Starts with – Category name starts with chosen value
                    </li>
                    <li>
                        Ends with – Category name ends with chosen value
                    </li>
                    <li>
                        Is empty – Hides all results
                    </li>
                    <li>
                        Is not empty – Displays all results
                    </li>
                    <li>
                        Is any of – Category column displays the multiple, selected values
                    </li>
                </ul>
            </Paragraph>
        </StepContentDiv>
    );
}
