import styled from "styled-components";

interface AttachmentInfoRowDivProps {
  $paddingTop?: number;
  $minHeight?: number;
}

export const AttachmentInfoRowDiv = styled.div<AttachmentInfoRowDivProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: ${(props) => `${props.$paddingTop ?? 12}px`};
  width: 100%;

  ${(props) => props.$minHeight && `min-height: ${props.$minHeight}px`}
`;
