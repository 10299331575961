import { DefaultTheme } from "styled-components";
import { Density } from "../../../../app/slices/models/documentsModels";

export function getMinHeight(density: Density, theme: DefaultTheme) {
  switch (density) {
    case Density.Compact:
      return theme.density.compactRowHeight + "px";
    case Density.Comfort:
      return theme.density.comfortRowHeight + "px";
    default:
      //Density.Standard
      return theme.density.standardRowHeight + "px";
  }
}

export function getMaxHeight(density: Density, theme: DefaultTheme) {
  switch (density) {
    case Density.Compact:
      return theme.density.compactRowHeight + "px";
    case Density.Comfort:
      return "none";
    default:
      //Density.Standard
      return theme.density.standardRowHeight + "px";
  }
}

export function getSpacing(
  density: Density,
  theme: DefaultTheme,
  insideGroup?: boolean
) {
  if (insideGroup) {
    return "0px";
  }

  switch (density) {
    case Density.Compact:
      return theme.density.compactRowSpacing + "px";
    case Density.Comfort:
      return theme.density.comfortRowSpacing + "px";
    default:
      //Density.Standard
      return theme.density.standardRowSpacing + "px";
  }
}
