import { DialogContent, DialogActions, Breakpoint } from "@mui/material";
import { StyledDialogTitle } from "./SC/StyledDialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { DialogButton } from "./SC/DialogButton";
import { useEffect, useState } from "react";
import { StyledDialog } from "./SC/StyledDialog";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { selectCurrentSplash } from "../../app/slices/commonSlice";
import { ScreenSplashEnum } from "../../models/screenSplash";
import HelpIcon from "@mui/icons-material/Help";
import { IconButtonControl } from "../Buttons/IconButtonControl";
import { setCurrentSelfHelp } from "../../app/slices/selfHelpSlice";
import { CurrentSelfHelp } from "../../models/CurrentSelfHelpType";
import { IconButtonControlDiv } from "../../components/details/contextMenu/SC/IconButtonControlDiv";
import { TooltipMsg } from "../Tooltips/TooltipMessages";

interface DialogControlProps {
  id: string;
  isOpen: boolean;
  title: string;
  content: React.ReactNode;
  fullWidth?: boolean;
  fullHeight?: boolean;
  maxWidth: Breakpoint | false;
  onCloseClick: (e: React.MouseEvent) => void;
  onYesClick: (e: React.MouseEvent) => void;
  onNoClick: (e: React.MouseEvent) => void;
  useSelfHelp?: boolean;
  useCancelButton?: boolean;
}

export function ConfirmationDialogControl(props: DialogControlProps) {
  const [open, setOpen] = useState(props.isOpen);
  const currentSplashScreen = useAppSelector(selectCurrentSplash);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const handleSelfHelpClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(setCurrentSelfHelp(CurrentSelfHelp.None));
  };

  return (
    <StyledDialog
      id={props.id + "-dialog"}
      fullWidth={props.fullWidth}
      $fullHeight={props.fullHeight}
      maxWidth={props.maxWidth}
      open={open && currentSplashScreen === ScreenSplashEnum.None}
      onClose={props.onCloseClick}
    >
      <StyledDialogTitle id={props.id + "-alert-dialog-title"}>
        {props.title}
        <IconButtonControlDiv>
          {props.useSelfHelp && (
            <IconButtonControl
              id={props.id + "-self-help-dialog-button"}
              onClick={handleSelfHelpClick}
              popupText={TooltipMsg.Help}
              icon={<HelpIcon fontSize="inherit" />}
            />
          )}
          <IconButtonControl
            id={props.id + "-confirm-dialog-close-button"}
            popupText={TooltipMsg.Close}
            onClick={props.onCloseClick}
            icon={<CloseIcon fontSize="inherit" />}
          />
        </IconButtonControlDiv>
      </StyledDialogTitle>
      <DialogContent>{props.content}</DialogContent>
      <DialogActions>
        <DialogButton
          id={props.id + "-confirm-dialog-yes-button"}
          color="primary"
          onClick={props.onYesClick}
        >
          {"Yes"}
        </DialogButton>
        <DialogButton
          id={props.id + "-confirm-dialog-no-button"}
          color="primary"
          onClick={props.onNoClick}
        >
          {"No"}
        </DialogButton>
        {props.useCancelButton && (
          <DialogButton
            id={props.id + "-confirm-dialog-cancel-button"}
            color="secondary"
            onClick={props.onCloseClick}
            autoFocus
          >
            {"Cancel"}
          </DialogButton>
        )}
      </DialogActions>
    </StyledDialog>
  );
}
