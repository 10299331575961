import styled from "styled-components";

export interface SectionFieldDivProps {
  $isHidden?: boolean;
}

export const SectionFieldDiv = styled.div<SectionFieldDivProps>`
  display: flex;
  flex-direction: column;
  padding-bottom: 4px;
  display: ${(props) => (props.$isHidden ? "none" : "inherit")};
`;
