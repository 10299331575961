import { find } from "lodash";
import { useCallback, useMemo } from "react";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import {
  Category,
  CategoryWithPermissions,
} from "../../../models/documentDetails/documentMetadata";
import { isDraft } from "../../helpers/documentsHelper";
import { selectDocumentCategoriesWithPermissions as selectCategoryPermissions } from "../../slices/categoriesSlice";
import { selectIsLoadingDetailsMetaData } from "../../slices/documentDetailsSlice";
import {
  selectCurrentDocumentCategories,
  selectDocumentWorkflow,
  selectIsApprovalMainCategory,
  selectIsRevisionLatest,
} from "../../slices/documentMetadataSlice";
import { useDocumentFlow } from "../document/useDocumentFlow";
import { useAppSelector } from "../hooks";
import { EditableState, initialEditableState } from "./models/state";
import { useUserApplicationRoles } from "./roles/useUserApplicationRoles";
import { useUserPermissionsInMainCategory } from "./roles/useUserPermissionsInMainCategory";
import { useUserPermissionsInDocument } from "./useUserPermissionsInDocument";

export function useUserPermissionsInCategory(categoryId: string | undefined) {
  const arePermissionsLoading = useAppSelector(selectIsLoadingDetailsMetaData);
  const isLatestRevision = useAppSelector(selectIsRevisionLatest);
  const categoryPermissions = useAppSelector(selectCategoryPermissions);
  const documentCategories = useAppSelector(selectCurrentDocumentCategories);
  const isApprovalCategory = useAppSelector(selectIsApprovalMainCategory);
  const { isNewDocumentView, isDeletedDocumentView, isNewDocumentRevision } =
    useDocumentFlow();
  const { isFromCompany, isSuperAdmin } = useUserApplicationRoles();
  const { isEditBlockedForApproval, isEditBlockedForPendingApproval } =
    useUserPermissionsInDocument(isApprovalCategory);
  const { canEditServiceDocument, canEditDocument } =
    useUserPermissionsInMainCategory();
  const workflow = useAppSelector(selectDocumentWorkflow);

  const getCategoryPermissions = useCallback(
    (categoryId: string) => {
      return find(categoryPermissions, (category: CategoryWithPermissions) => {
        return category.cid === categoryId;
      });
    },
    [categoryPermissions]
  );

  const isCategoryApprovalRequired = useMemo(() => {
    if (!categoryId) {
      return false;
    }

    const categoryPermission = getCategoryPermissions(categoryId);
    return categoryPermission?.isApprovalRequired ?? false;
  }, [getCategoryPermissions, categoryId]);

  const canApproveInCategory = useMemo(() => {
    if (!categoryId) {
      return false;
    }

    const categoryPermission = getCategoryPermissions(categoryId);
    return isSuperAdmin || (categoryPermission?.canApproveDocument ?? false);
  }, [getCategoryPermissions, categoryId, isSuperAdmin]);

  const getCategory = useCallback(
    (categoryId: string) => {
      return find(documentCategories, (category: Category) => {
        return category.cid === categoryId;
      });
    },
    [documentCategories]
  );

  const hasAccessToAnyDocumentCategory = useMemo(() => {
    const category = find(categoryPermissions, (category) => {
      return category.canEditDocument;
    });

    return category !== undefined;
  }, [categoryPermissions]);

  const hasAccessToCategory = useMemo((): EditableState => {
    if (!categoryId) {
      return initialEditableState;
    }

    const categoryPermissions = getCategoryPermissions(categoryId);
    const category = getCategory(categoryId);
    if (!categoryPermissions || !category) {
      return initialEditableState;
    }

    if (arePermissionsLoading) {
      return initialEditableState;
    }

    if (isSuperAdmin) {
      return { isEditable: true };
    }

    if (!isFromCompany) {
      return initialEditableState;
    }

    if (category.isFromServiceIntegration && !canEditServiceDocument) {
      return initialEditableState;
    }

    if (!categoryPermissions.canEditDocument) {
      return initialEditableState;
    }

    if (isEditBlockedForApproval || isEditBlockedForPendingApproval) {
      return {
        isEditable: false,
        lockMessage: TooltipMsg.CannotApprovePendingApproval,
      };
    }

    return { isEditable: true };
  }, [
    categoryId,
    getCategoryPermissions,
    getCategory,
    arePermissionsLoading,
    isSuperAdmin,
    isFromCompany,
    canEditServiceDocument,
    isEditBlockedForApproval,
    isEditBlockedForPendingApproval,
  ]);

  const isNotEditableDraft = useMemo(() => {
    return isDraft(workflow) && !canEditDocument;
  }, [workflow, canEditDocument]);

  const canEditCategory = useMemo((): EditableState => {
    if (isNewDocumentView) {
      return { isEditable: true };
    }

    if (!isLatestRevision) {
      return initialEditableState;
    }

    if (isNotEditableDraft) {
      return {
        isEditable: false,
        lockMessage: TooltipMsg.NoAccessToDocument,
      };
    }

    return hasAccessToCategory;
  }, [
    isNewDocumentView,
    isLatestRevision,
    hasAccessToCategory,
    isNotEditableDraft,
  ]);

  const canEditPopularStatus = useMemo((): EditableState => {
    if (!categoryId) {
      return initialEditableState;
    }

    if (isSuperAdmin) {
      return { isEditable: true };
    }

    if (isNotEditableDraft) {
      return {
        isEditable: false,
        lockMessage: TooltipMsg.NoAccessToDocument,
      };
    }

    const category = getCategoryPermissions(categoryId);

    if (!category || !category.canChangePopular) {
      return initialEditableState;
    }

    return hasAccessToCategory;
  }, [
    categoryId,
    isSuperAdmin,
    getCategoryPermissions,
    hasAccessToCategory,
    isNotEditableDraft,
  ]);

  const canAddCategories = useMemo((): EditableState => {
    if (isDeletedDocumentView)
      return { isEditable: false, lockMessage: TooltipMsg.DeletedDocument };
    if (isNewDocumentView) return { isEditable: true };

    if (!isLatestRevision) return initialEditableState;

    if (isNotEditableDraft) {
      return {
        isEditable: false,
        lockMessage: TooltipMsg.NoAccessToDocument,
      };
    }

    if (isNewDocumentRevision) return { isEditable: true };

    if (isEditBlockedForApproval || isEditBlockedForPendingApproval) {
      return {
        isEditable: false,
        lockMessage: TooltipMsg.CannotApprovePendingApproval,
      };
    }

    return { isEditable: true };
  }, [
    isNewDocumentView,
    isDeletedDocumentView,
    isLatestRevision,
    isNewDocumentRevision,
    isEditBlockedForApproval,
    isEditBlockedForPendingApproval,
    isNotEditableDraft,
  ]);

  return {
    canEditCategory,
    canEditPopularStatus,
    canAddCategories,
    hasAccessToCategory,
    hasAccessToAnyDocumentCategory,
    canApproveInCategory,
    isCategoryApprovalRequired,
  };
}
