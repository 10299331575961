import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import ShareIcon from "@mui/icons-material/Share";
import StorageIcon from "@mui/icons-material/Storage";
import { ListItemIcon, ListItemText, Menu } from "@mui/material";
import { useRouteHelper } from "../../../../../app/hooks/useRouteHelper";
import { TooltipTop } from "../../../../../controls/Tooltips/ToolipTop";
import DocumentListItem from "../../../../../models/documentList/documentListItem";
import { Workflow } from "../../../../../models/documentList/types/workflow";
import { StyledLink } from "../cells/styledComponents/StyledLink";
import { StyledMenuItem } from "../cells/styledComponents/StyledMenuItem";
import { StyledDivider } from "./styledComponents/StyledDivider";

interface SingleDocumentOptionsProps {
  workflow: Workflow;
  isMenuOpened: boolean;
  menuAnchorEl: Element | null;
  documentItem: DocumentListItem;
  onMenuClose: (event: React.MouseEvent) => void;
  onShareUrlClick: (event: React.MouseEvent) => void;
  onNewLanguageClick: () => void;
  onNewFromTemplateClick: () => void;
  onNewPartVersionClick: () => void;
  onNewRevisionClick: () => void;
  onSetPublishedClick: (event: React.MouseEvent) => void;
  onSetDeletedClick: (event: React.MouseEvent) => void;
  onSetArchived: (event: React.MouseEvent) => void;
  revisionButtonTooltipMessage: string;
  disableRevisionButton: boolean;
  disableSetArchived: boolean;
  disableSetArchivedTooltip?: string;
  disableSetPublished: boolean;
  disableSetPublishedTooltip?: string;
  disableSetDeleted?: boolean;
  disableSetDeletedTooltip?: string;
  disabledNewVersion?: boolean;
  disabledNewVersionTooltip?: string;
}

export default function SingleDocumentOptions(
  props: SingleDocumentOptionsProps
) {
  const showCreateOptions =
    props.workflow === Workflow.Published ||
    props.workflow === Workflow.Archived;
  const newRevisionButtonState =
    !props.documentItem.hasAccess || props.disableRevisionButton;
  const { getDetailsRoute, getDocumentIdentifier } = useRouteHelper();

  return (
    <Menu
      id="basic-menu"
      anchorEl={props.menuAnchorEl}
      open={props.isMenuOpened}
      onClose={props.onMenuClose}
      PaperProps={{ style: { maxHeight: "320px" } }}
      className="document-options"
    >
      <StyledLink
        $isGray={false}
        isParentElement={true}
        to={getDetailsRoute(getDocumentIdentifier(props.documentItem))}
      >
        <StyledMenuItem>
          <ListItemIcon>
            <ArrowForwardIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Open details</ListItemText>
        </StyledMenuItem>
      </StyledLink>
      {props.workflow !== Workflow.Deleted && [
        <StyledDivider key="share-url-divider" />,
        <StyledMenuItem key="share-url" onClick={props.onShareUrlClick}>
          <ListItemIcon>
            <ShareIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Share URL</ListItemText>
        </StyledMenuItem>,
      ]}
      {showCreateOptions && [
        <StyledDivider key="create-options-divider" />,
        <TooltipTop
          key="new-revision-tooltip"
          title={
            newRevisionButtonState ? props.revisionButtonTooltipMessage : ""
          }
        >
          <span>
            {/* disabled elements cannot be provided with a tooltip */}
            <StyledMenuItem
              disabled={newRevisionButtonState}
              key="new-revision"
              onClick={props.onNewRevisionClick}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText>New Revision</ListItemText>
            </StyledMenuItem>
          </span>
        </TooltipTop>,
        <TooltipTop key="new-part-tooltip" title={props.disabledNewVersionTooltip}>
          <span>
            <StyledMenuItem disabled={props.disabledNewVersion} key="new-part-version" onClick={props.onNewPartVersionClick}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText>New Part version</ListItemText>
            </StyledMenuItem>
          </span>
        </TooltipTop>,
        <TooltipTop key="new-language-tooltip" title={props.disabledNewVersionTooltip}>
          <span>
            <StyledMenuItem disabled={props.disabledNewVersion} key="new-language" onClick={props.onNewLanguageClick}>
              <ListItemIcon></ListItemIcon>
              <ListItemText>New Language version</ListItemText>
            </StyledMenuItem>
          </span>
        </TooltipTop>,
        <TooltipTop key="new-from-template-tooltip" title={props.disabledNewVersionTooltip}>
          <span>
            <StyledMenuItem disabled={props.disabledNewVersion} key="new-from-template" onClick={props.onNewFromTemplateClick}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText>New from template</ListItemText>
            </StyledMenuItem>
          </span>
        </TooltipTop >,
      ]}
      {props.workflow !== Workflow.Draft &&
        props.workflow !== Workflow.Approval && <StyledDivider />}
      {(props.workflow === Workflow.Archived ||
        props.workflow === Workflow.Deleted) && (
          <TooltipTop title={props.disableSetPublishedTooltip ?? ""}>
            <span>
              <StyledMenuItem
                disabled={props.disableSetPublished}
                onClick={props.onSetPublishedClick}
              >
                <ListItemIcon>
                  <PublishedWithChangesIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Publish</ListItemText>
              </StyledMenuItem>
            </span>
          </TooltipTop>
        )}

      {(props.workflow === Workflow.Published ||
        props.workflow === Workflow.Deleted) && (
          <TooltipTop title={props.disableSetArchivedTooltip ?? ""}>
            <span>
              <StyledMenuItem
                disabled={props.disableSetArchived}
                onClick={props.onSetArchived}
              >
                <ListItemIcon>
                  <StorageIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Archive</ListItemText>
              </StyledMenuItem>
            </span>
          </TooltipTop>
        )}
      {(props.workflow === Workflow.Published ||
        props.workflow === Workflow.Archived) && (
          <TooltipTop title={props.disableSetDeletedTooltip ?? ""}>
            <span>
              <StyledMenuItem
                onClick={props.onSetDeletedClick}
                disabled={props.disableSetDeleted}
              >
                <ListItemIcon>
                  <DeleteForeverIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </StyledMenuItem>
            </span>
          </TooltipTop>
        )}
    </Menu>
  );
}
