import { TableCell } from "@mui/material";
import styled from "styled-components";

interface StyledTableCellProps {
  textAlign: string;
  header?: boolean;
  bolded?: boolean;
}

export const StyledTableCell = styled(TableCell)<StyledTableCellProps>`
  &.MuiTableCell-root {
    border-top: ${(props) =>
      props.header
        ? "1px solid " + props.theme.palettes.grayscale.light
        : "unset"};
    font-weight: ${(props) => (props.bolded || props.header ? 700 : 400)};
    font-size: ${(props) =>
      props.header
        ? props.theme.typography.fontSizes.headerT2
        : props.theme.typography.fontSizes.medium}px;
    text-align: ${(props) => props.textAlign};
  }
`;
