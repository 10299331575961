import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function EmailsExportStep4A() {
    return (
        <StepContentDiv>
            <Paragraph>
                This action allows Category Owners exporting list of all publishers within level 0 to which selected category belongs.
            </Paragraph>
            <Paragraph>
                It is presented as single-line text that can be copied. Such text can be pasted to Outlook and after pressing Check Names (or Ctrl+K), it can become a mail delivery list.
            </Paragraph>
            <Paragraph>
                The button becomes available only to Category Owners when the category they own is selected in the left menu.
            </Paragraph>
        </StepContentDiv>
    );
}
