import { BrowserUtils } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import LogoutIcon from "@mui/icons-material/Logout";
import { ListItemIcon, ListItemText, Menu } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import {
  selectUserEmail,
  selectUserName,
  selectUserPhoto,
} from "../../../app/slices/authenticationSlice";
import { TooltipPartDiv } from "../../../controls/Tooltips/SC/TooltipPartDiv";
import { TooltipPartSpan } from "../../../controls/Tooltips/SC/TooltipPartSpan";
import { TooltipTop } from "../../../controls/Tooltips/ToolipTop";
import { StyledMenuItem } from "../../documents/documentsList/documentListItem/cells/styledComponents/StyledMenuItem";
import { StyledAvatar } from "./SC/StyledAvatar";
import { UserDataDiv } from "./SC/UserDataDiv";
import { UserDataSpan } from "./SC/UserDataSpan";
import { UserInfoDiv } from "./SC/UserInfoDiv";
import { UserMenuDiv } from "./SC/UserMenuDiv";
import {
  selectUsePromptForEditedDocument,
  setLogoutPrompt,
} from "../../../app/slices/documentDetailsSlice";
import { useDocumentEditedMode } from "../../../app/hooks/document/useDocumentEditedMode";
import { selectIsEdited } from "../../../app/slices/publisherCategoriesSettingsSlice";

function getInitials(fullName: string | undefined) {
  if (fullName) {
    const names = fullName.split(" ");
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  return "XY";
}

export function UserMenu() {
  const dispatch = useAppDispatch();
  const userEmail = useAppSelector(selectUserEmail);
  const userName = useAppSelector(selectUserName);
  const imageUrl = useAppSelector(selectUserPhoto);
  const { instance: msalInstance } = useMsal();
  const account = msalInstance.getActiveAccount();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const usePromptForEditedDocument = useAppSelector(
    selectUsePromptForEditedDocument
  );
  const { documentIsEdited } = useDocumentEditedMode();
  const settingsIsEdited = useAppSelector(selectIsEdited);

  const useConfirmBeforeLeave = () => {
    const confirm = window.confirm(
      "All changes will be lost. Are you sure you want to leave?"
    );
    if (confirm) {
      dispatch(setLogoutPrompt(true));
      logoutRedirect();
    }
  };

  const logoutRedirect = () => {
    void msalInstance.logoutRedirect({
      account: account,
      onRedirectNavigate: () => !BrowserUtils.isInIframe(),
    });
  };

  const handleLogout = () => {
    if ((documentIsEdited && usePromptForEditedDocument) || settingsIsEdited) {
      useConfirmBeforeLeave();
    } else {
      logoutRedirect();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (account?.idTokenClaims !== undefined) {
    return (
      <UserInfoDiv>
        <UserMenuDiv onClick={handleClick}>
          <UserDataDiv>
            <TooltipTop title={userName}>
              <UserDataSpan $isBolded={true}>{userName}</UserDataSpan>
            </TooltipTop>
            <TooltipTop title={userEmail}>
              <UserDataSpan $isBolded={false}>{userEmail}</UserDataSpan>
            </TooltipTop>
          </UserDataDiv>
          <TooltipTop
            title={
              <>
                <TooltipPartDiv>
                  <TooltipPartSpan>Name: {userName}</TooltipPartSpan>
                  <TooltipPartSpan>Email: {userEmail}</TooltipPartSpan>
                </TooltipPartDiv>
              </>
            }
          >
            <StyledAvatar src={imageUrl}>{getInitials(userName)}</StyledAvatar>
          </TooltipTop>
        </UserMenuDiv>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <StyledMenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </StyledMenuItem>
        </Menu>
      </UserInfoDiv>
    );
  }

  return <></>;
}
