import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ErrorApi } from "../../models/errorApi";

export interface ApiErrorState {
  forbiddenErrorData: ForbiddenErrorData;
  catchedAuthError: boolean;
  errors: ErrorApi[];
}

export interface ForbiddenErrorData {
  Message: string;
  ErrorCode: ForbiddenErrorCode;
  UserEmail: string;
  UserCompany: string | undefined;
}

export enum ForbiddenErrorCode {
  UserHasMultipleCompanies = 100,
  AbbUserNotPublisher,
  ExternalUserNotPublisher,
  RestrictedAccess,
}

const initialState: ApiErrorState = {
  forbiddenErrorData: {} as ForbiddenErrorData,
  catchedAuthError: false,
  errors: [],
};

export const apiErrorSlice = createSlice({
  name: "apiError",
  initialState: initialState,
  reducers: {
    setForbiddenError(
      state: ApiErrorState,
      action: PayloadAction<ForbiddenErrorData>
    ) {
      if (!state.catchedAuthError) {
        state.forbiddenErrorData = action.payload;
        state.catchedAuthError = true;
      }
    },
    setApiErrors(state: ApiErrorState, action: PayloadAction<ErrorApi[]>) {
      state.errors = action.payload;
    },
  },
});

export const { setForbiddenError, setApiErrors } = apiErrorSlice.actions;

export const selectApiError = (state: RootState) => state.apiError;
export const selectApiErrorMessage = (state: RootState) =>
  state.apiError.errors;

export default apiErrorSlice.reducer;
