import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import useNavigationTreeFetch from "../../../app/hooks/useNavigationTreeFetch";
import useWindowDimensions from "../../../app/hooks/useWindowDimensions";
import {
  selectCategoryById,
  setStartedCategoriesFirstLoad,
} from "../../../app/slices/navigationCategoryTreeSlice";
import {
  categorySkeletonRowHeight,
  rootCategoryId,
} from "../../../app/slices/navigationSlice";
import CategoryNavigationTreeItem from "./CategoryNavigationTreeItem";
import CategoryTreeSkeleton from "./CategoryTreeSkeleton";
import {
  ErrorContainerProps,
  ErrorDisplay,
} from "../../../app/errorHandling/ErrorDisplay";

interface CategoryNavigationTreeProps {
  setIsError: (isError: boolean) => void;
}

function CategoryNavigationTree(props: CategoryNavigationTreeProps) {
  const dispatch = useAppDispatch();
  const {
    fetchCategories,
    categoriesTreeIsError,
    categoriesTreeFacetsIsError,
  } = useNavigationTreeFetch({
    categoryId: rootCategoryId,
    includeHighestParent: true,
    categoryDepth: 0,
    hasChildrenWithoutFacets: true,
  });
  const { windowHeight } = useWindowDimensions();
  const rootCategory = useAppSelector((state) =>
    selectCategoryById(state, rootCategoryId)
  );
  const errorContainerStyles: ErrorContainerProps = {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
  };

  useEffect(() => {
    if (rootCategory === undefined) {
      void fetchCategories();
      dispatch(setStartedCategoriesFirstLoad());
    }
  }, [rootCategory, dispatch, fetchCategories]);

  useEffect(() => {
    props.setIsError(categoriesTreeIsError);
  }, [categoriesTreeIsError, props]);

  if (categoriesTreeIsError) {
    props.setIsError(categoriesTreeIsError);
    return (
      <ErrorDisplay
        errorId={"category"}
        errorContainerProps={errorContainerStyles}
        errorMessageTitle={"Something went wrong"}
        errorDescription={"Categories initial loading failed"}
        showDash={false}
        showReloadButton={true}
        refreshFunction={() => {
          void fetchCategories();
        }}
      />
    );
  }

  return rootCategory ? (
    <CategoryNavigationTreeItem
      categoryId={rootCategoryId}
      parentCategoryId={rootCategoryId}
      depth={0}
      isParent={true}
      isCategoryFacetsError={categoriesTreeFacetsIsError}
    />
  ) : (
    <CategoryTreeSkeleton
      numberOfSkeletons={windowHeight / categorySkeletonRowHeight}
      increasingIndent={true}
    />
  );
}

export default CategoryNavigationTree;
