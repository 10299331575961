import styled from "styled-components";
import { StyledDataGrid } from "../../tabs/categoryTab/publishers/TableControl/SC/StyledDataGrid";

export const StyledCategoriesDataGrid = styled(StyledDataGrid)`
  &.MuiDataGrid-root {
    padding: 0px 0px 0px 28px;
  }

  &.MuiDataGrid-root--densityCompact .MuiDataGrid-cell {
    padding: 1px 0px 1px 0px;
  }

  &.MuiDataGrid-root--densityStandard .MuiDataGrid-cell {
    padding: 10px 0px 10px 0px;
  }

  &.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell {
    padding: 15px 0px 15px 0px;
  }
`;
