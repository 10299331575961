import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import useDocumentValidationRules from "../../../../app/hooks/document/useDocumentValidationRules";
import {
  selectCurrentDocumentSummary,
  setEditedSummary,
} from "../../../../app/slices/documentMetadataSlice";
import { setSummaryValidationStatus } from "../../../../app/slices/documentMetadataValidationSlice";
import { ValidationStatus } from "../../../../models/validationRule";
import { MultiText } from "../../inputs/MultiText";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";

interface SummaryControlProps {
  isEdited: boolean;
  isEditable: boolean;
  notEditableReason?: TooltipMsg;
  isLoadingDetailsMetadata: boolean;
}

export function SummaryControl(props: SummaryControlProps) {
  const dispatch = useAppDispatch();
  const summary = useAppSelector(selectCurrentDocumentSummary);
  const { getSummaryValidationRules } = useDocumentValidationRules();

  const onSummaryChangeHandler = (newValue: string) => {
    dispatch(setEditedSummary(newValue));
  };

  const onInputValidation = useCallback(
    (result: ValidationStatus) => {
      dispatch(setSummaryValidationStatus(result));
    },
    [dispatch]
  );

  return (
    <MultiText
      isEditable={props.isEditable}
      id="details-description-summary"
      inputValue={summary}
      notEditableMessage={props.notEditableReason}
      onInputValidation={onInputValidation}
      validationRules={getSummaryValidationRules()}
      wrapText={true}
      isLoading={props.isLoadingDetailsMetadata}
      onChangeHandler={onSummaryChangeHandler}
      isEdited={props.isEdited}
      labelText="Summary"
      placeholder="(optional)"
    />
  );
}
