import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStep1() {
  return (
    <StepContentDiv>
      <Paragraph>
        In this section, access groups allowed to be selected while publishing the document in the selected category or categories below this category (selected in the document as Main category) are displayed.
      </Paragraph>
    </StepContentDiv>
  );
}
