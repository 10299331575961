import { StyledCategoryChip } from "../../../../../components/details/sections/categories/SC/StyledCategoryChip";
import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/categories/main.png";

interface MainCategoryStepProps {
    showScreenshots?: boolean;
}

export function MainCategoryStep(props: MainCategoryStepProps) {
    return (
        <StepContentDiv>
            <Paragraph>
                The Main Category feature is designed to clearly indicate the ownership of a document. It is mandatory for every document to have one main category, which is represented by a filled-in <StyledCategoryChip label="M" type="main" $isDisabled={false} $isSelected={true} selected={true} /> icon.
            </Paragraph>
            <Paragraph>
                The main category selection impacts the available dictionary data, such as access groups, document kinds, and tags, when publishing a document. However, it does not have any impact on how documents are presented in the Download Center or Download Section. The main category information is only visible in the ABB Library Publishing interface.
            </Paragraph>
            {props.showScreenshots &&
                <Paragraph>
                    <Screenshot src={screenshot} />
                </Paragraph>
            }
        </StepContentDiv>
    );
}
