import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function FileHashStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                The file hash is automatically generated when the attachment is uploaded, using the SHA256 algorithm.
            </Paragraph>
            <Paragraph>
                This hash serves as a means to verify the integrity of the file. To confirm the file&apos;s integrity, the hash extracted from the file on the user&apos;s drive must match the hash obtained from the file in the ABB Library.
            </Paragraph>
        </StepContentDiv >
    );
}
