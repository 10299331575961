import { Tooltip, TooltipProps } from "@mui/material";

export function TooltipTop(props: TooltipProps) {
  return (
    <Tooltip
      classes={{ popper: props.className }}
      {...props}
      followCursor
      placement="top"
    />
  );
}
