import { includes } from "lodash";
import { Step } from "react-joyride";
import { ActionButton } from "../../../../app/slices/selfHelpSlice";
import { createStep } from "../../helpers/selfHelpBuilder";
import { ActionBarStep } from "./ContextMenu/ActionBarStep";
import { CreateStep } from "./ContextMenu/CreateStep";
import { DiscardChangesStep } from "./ContextMenu/DiscardChangesStep";
import { DiscardDraftStep } from "./ContextMenu/DiscardDraftStep";
import { GoToLatestStep } from "./ContextMenu/GoToLatestStep";
import { HardDeleteStep } from "./ContextMenu/HardDeleteStep";
import { PublishDoNotChangePublisherStep } from "./ContextMenu/PublishDoNotChangePublisherStep";
import { PublishStep } from "./ContextMenu/PublishStep";
import { SaveAsDraftStep } from "./ContextMenu/SaveAsDraftStep";
import { SetStateStep } from "./ContextMenu/SetStateStep";
import { ShareUrlStep } from "./ContextMenu/ShareUrlStep";
import { ScheduledActionStep } from "./ContextMenu/ScheduledActionStep";

export const contextMenuPublishSteps = (actionButtons: ActionButton[]) => {
  const steps: Step[] = [];

  steps.push(
    createStep("#context-menu-action-buttons", <ActionBarStep />, 520, "auto")
  );

  if (includes(actionButtons, ActionButton.Publish)) {
    steps.push(
      createStep(
        ".document-details-publish-button",
        <PublishStep isVisible />,
        520,
        "auto"
      )
    );
  } else {
    steps.push(
      createStep(
        "#context-menu-header-settings-div",
        <PublishStep />,
        520,
        "auto"
      )
    );
  }

  if (includes(actionButtons, ActionButton.PublishDoNotChangePublisher)) {
    steps.push(
      createStep(
        "#publish-do-not-change-publisher",
        <PublishDoNotChangePublisherStep isVisible />,
        520,
        "auto"
      )
    );
  } else {
    steps.push(
      createStep(
        "#context-menu-header-settings-div",
        <PublishDoNotChangePublisherStep />,
        520,
        "auto"
      )
    );
  }

  if (includes(actionButtons, ActionButton.SaveAsDraft)) {
    steps.push(
      createStep(
        "#document-details-save-as-draft-button",
        <SaveAsDraftStep isVisible />,
        520,
        "auto"
      )
    );
  } else {
    steps.push(
      createStep(
        "#context-menu-header-settings-div",
        <SaveAsDraftStep />,
        520,
        "auto"
      )
    );
  }

  if (includes(actionButtons, ActionButton.DiscardDraft)) {
    steps.push(
      createStep(
        ".document-details-discard-draft-button",
        <DiscardDraftStep isVisible />,
        520,
        "auto"
      )
    );
  } else {
    steps.push(
      createStep(
        "#context-menu-header-settings-div",
        <DiscardDraftStep />,
        520,
        "auto"
      )
    );
  }

  if (includes(actionButtons, ActionButton.DiscardChanges)) {
    steps.push(
      createStep(
        "#document-details-discard-changes-button",
        <DiscardChangesStep isVisible />,
        520,
        "auto"
      )
    );
  } else {
    steps.push(
      createStep(
        "#context-menu-header-settings-div",
        <DiscardChangesStep />,
        520,
        "auto"
      )
    );
  }

  if (includes(actionButtons, ActionButton.ShareUrl)) {
    steps.push(
      createStep(
        "#document-details-share-url-button",
        <ShareUrlStep isVisible />,
        520,
        "auto"
      )
    );
  } else {
    steps.push(
      createStep(
        "#context-menu-header-settings-div",
        <ShareUrlStep />,
        520,
        "auto"
      )
    );
  }

  if (includes(actionButtons, ActionButton.Create)) {
    steps.push(
      createStep(
        "#document-details-create-button",
        <CreateStep isVisible />,
        520,
        "auto"
      )
    );
  } else {
    steps.push(
      createStep(
        "#context-menu-header-settings-div",
        <CreateStep />,
        520,
        "auto"
      )
    );
  }

  if (includes(actionButtons, ActionButton.SetState)) {
    steps.push(
      createStep(
        "#document-details-set-state-button",
        <SetStateStep isVisible />,
        520,
        "auto"
      )
    );
  } else {
    steps.push(
      createStep(
        "#context-menu-header-settings-div",
        <SetStateStep />,
        520,
        "auto"
      )
    );
  }

  if (includes(actionButtons, ActionButton.Add)) {
    steps.push(
      createStep(
        "#document-details-scheduled-actions-button",
        <ScheduledActionStep isVisible />,
        520,
        "auto"
      )
    );
  } else {
    steps.push(
      createStep(
        "#context-menu-header-settings-div",
        <ScheduledActionStep />,
        520,
        "auto"
      )
    );
  }

  if (includes(actionButtons, ActionButton.GoToLatest)) {
    steps.push(
      createStep(
        "#document-details-go-to-latest-revison-button",
        <GoToLatestStep isVisible />,
        520,
        "auto"
      )
    );
  } else {
    steps.push(
      createStep(
        "#context-menu-header-settings-div",
        <GoToLatestStep />,
        520,
        "auto"
      )
    );
  }

  if (includes(actionButtons, ActionButton.HardDelete)) {
    steps.push(
      createStep(
        "#document-details-hard-delete-button",
        <HardDeleteStep isVisible />,
        520,
        "auto"
      )
    );
  } else {
    steps.push(
      createStep(
        "#context-menu-header-settings-div",
        <HardDeleteStep />,
        520,
        "auto"
      )
    );
  }

  return steps;
};
