import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../../../app/hooks/hooks";
import {
  selectAvailableCurrentDocumentTagTypes,
  selectAvailableTagTypes,
  selectCurrentDocumentMainCategory,
} from "../../../../../app/slices/documentMetadataSlice";
import { DialogControl } from "../../../../../controls/Dialog/DialogControl";
import { ComboBoxListItem } from "../../../../../models/autocompleteValue";
import Tag from "../../../../../models/tags/tag";
import { useTheme } from "styled-components";
import { CircularProgressBar } from "../../../../../controls/ProgressIndicators/CircularProgressBar";
import { useDocumentFlow } from "../../../../../app/hooks/document/useDocumentFlow";
import { useFetchTagsList } from "../useFetchTagsList";
import { MultiValueTagControl } from "./MultiValueTagControl";
import { StyledDialogContentText } from "../../../selfHelpPopup/SC/StyledDialogContentText";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";

interface AddNewTagPopupProps {
  isOpen: boolean;
  onConfirmClick: (newTag: Tag) => void;
  onCancelClick: () => void;
}

export function AddNewTagPopup(props: AddNewTagPopupProps) {
  const theme = useTheme();
  const documentTagTypes = useAppSelector(
    selectAvailableCurrentDocumentTagTypes
  );
  const availableTagTypes = useAppSelector(selectAvailableTagTypes);
  const currentDocumentMainCategory = useAppSelector(
    selectCurrentDocumentMainCategory
  );
  const [selectedTag, setSelectedTag] = useState<Tag | undefined>();
  const [availableTags, setAvailableTags] = useState<Tag[]>([]);

  const hideActions = availableTags.length === 0;
  const onClose = props.onCancelClick;
  const onConfirm = props.onConfirmClick;

  const { isNewDocument } = useDocumentFlow();
  const { fetchTagsList, isLoadingTagsList, tagsList } = useFetchTagsList();

  useEffect(() => {
    if (props.isOpen) {
      fetchTagsList(availableTagTypes);
    }
  }, [availableTagTypes, fetchTagsList, props.isOpen]);

  useEffect(() => {
    if (tagsList) {
      const tags = tagsList.filter((t) => !documentTagTypes.includes(t.name));
      setAvailableTags(tags);
    } else {
      setAvailableTags([]);
    }
  }, [documentTagTypes, tagsList]);

  const onChangeHandler = useCallback(
    (items: ComboBoxListItem[]) => {
      if (items.length > 0) {
        const item = items[0];
        const tag = availableTags.find((t) => t.name === item.key);
        setSelectedTag(tag);
      }
    },
    [availableTags]
  );

  const onConfirmClick = useCallback(() => {
    if (selectedTag) {
      onConfirm(selectedTag);
      setSelectedTag(undefined);
    }
  }, [selectedTag, onConfirm]);

  const onCloseClick = useCallback(() => {
    setSelectedTag(undefined);
    onClose();
  }, [onClose]);

  const getDialogContent = () => {
    if (isNewDocument && !currentDocumentMainCategory) {
      return (
        <StyledDialogContentText>
          Main category is not selected. Please select Main category in order to
          load dictionary data.
        </StyledDialogContentText>
      );
    }

    if (isLoadingTagsList) {
      return (
        <CircularProgressBar
          size={theme.circularProgress.medium}
          space={theme.circularProgressWrapper.defaultHeight}
          color="secondary"
        />
      );
    }

    if (availableTagTypes.length === 0) {
      return (
        <StyledDialogContentText>
          There are no Tags available for selected Level 0 category (Level 0 of
          Main Category).
        </StyledDialogContentText>
      );
    }

    if (availableTags.length === 0) {
      return (
        <StyledDialogContentText>
          All available Tags for selected Level 0 category (Level 0 of Main
          Category) are already added.
        </StyledDialogContentText>
      );
    }

    return (
      <MultiValueTagControl
        availableTags={availableTags}
        isLoading={isLoadingTagsList}
        onChangeHandler={onChangeHandler}
        selectedTag={selectedTag}
      />
    );
  };

  return (
    <DialogControl
      id={"tag-types"}
      fullWidth={true}
      isOpen={props.isOpen}
      useActions={!hideActions}
      title={"Add new Tag"}
      content={getDialogContent()}
      maxWidth={"sm"}
      onCloseClick={onCloseClick}
      onConfirmClick={onConfirmClick}
      disabledConfirm={!selectedTag || isLoadingTagsList}
      disabledConfirmTooltipMsg={TooltipMsg.DialogControlNoTags}
    />
  );
}
