import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function WorkflowStep() {
  const theme = useTheme();
  return (
    <StepContentDiv>
      <Paragraph>
        The document list is narrowed down to documents with the selected
        Workflow.
      </Paragraph>
      <Paragraph>
        By default, only documents in the{" "}
        <span style={{ color: theme.palettes.secondary.draft }}>Draft</span>,{" "}
        <span style={{ color: theme.palettes.secondary.approval }}>
          In Approval
        </span>
        , and{" "}
        <span style={{ color: theme.palettes.secondary.published }}>
          Published
        </span>{" "}
        states are displayed.
      </Paragraph>
      <Paragraph>
        Values can be selected or deselected by clicking on them.
      </Paragraph>
      <Paragraph>The results are automatically reloaded.</Paragraph>
    </StepContentDiv>
  );
}
