import { find } from "lodash";
import { PermissionInCategory } from "../../models/permissionsInCategory";

export function hasApplicationRole(
  role: string,
  myRoles: string[] | undefined
) {
  if (!role) {
    return true;
  }

  if (myRoles) {
    const result = find(myRoles, (r: string) => {
      return r === role;
    });

    return !!result;
  }

  return false;
}

export function hasPermission(
  permission: PermissionInCategory,
  myPermissions: PermissionInCategory[] | undefined
) {
  if (myPermissions) {
    const result = find(myPermissions, (p: PermissionInCategory) => {
      return p === permission;
    });

    return !!result;
  }

  return false;
}
