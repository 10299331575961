import { TooltipTop } from "../../../../controls/Tooltips/ToolipTop";
import { StyledToggleButton } from "../../../documentsNavigation/filters/inputs/securityLevels/styledComponents/StyledToggleButton";
import { StyledToggleButtonGroup } from "../../../documentsNavigation/filters/inputs/securityLevels/styledComponents/StyledToggleButtonGroup";
import { StyledDisabledTooltipMsg } from "../../../documentsNavigation/filters/inputs/securityLevels/styledComponents/StyledDisabledTooltipMsg";
import { ToggleButtonSecurityLevel } from "../../sections/security/SecurityLevelControl";

export interface KeyValuePair {
  key: string;
  value: string;
}

export type ToggleButtonsInputHandler = (
  event: React.MouseEvent<HTMLElement>,
  value: string
) => void;

export interface TagsInputProps {
  id: string;
  value: string;
  values: string[] | KeyValuePair[] | ToggleButtonSecurityLevel[];
  isDisabled?: boolean;
  isEdited?: boolean;
  handleInputChange?: ToggleButtonsInputHandler;
  tooltipDisableMessage?: string;
}

export function ToggleButtonsInput(props: TagsInputProps) {
  const handleInputChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    if (props.handleInputChange !== undefined) {
      props.handleInputChange(event, value);
    }
  };

  const isKvp = (item: string | KeyValuePair): item is KeyValuePair =>
    !!(item as KeyValuePair).key;

  const mapToToggleButton = (
    item: string | KeyValuePair | ToggleButtonSecurityLevel,
    index: number
  ) => {
    if (isKvp(item)) {
      const isDisabled =
        (item as ToggleButtonSecurityLevel).isDisabled === true;

      if (isDisabled && props.tooltipDisableMessage) {
        return (
          <StyledToggleButton
            key={item.key + "-" + index}
            value={item.key}
            disabled={isDisabled}
            className="disabled-with-tooltip"
          >
            <TooltipTop title={props.tooltipDisableMessage}>
              <StyledDisabledTooltipMsg>{item.value}</StyledDisabledTooltipMsg>
            </TooltipTop>
          </StyledToggleButton>
        );
      }

      return (
        <StyledToggleButton key={item.key + "-" + index} value={item.key}>
          {item.value}
        </StyledToggleButton>
      );
    }

    return (
      <StyledToggleButton key={item} value={item}>
        {item}
      </StyledToggleButton>
    );
  };

  return (
    <StyledToggleButtonGroup
      value={props.value}
      id={props.id}
      size="small"
      fullWidth
      exclusive={true}
      disabled={props.isDisabled}
      $isEdited={props.isEdited}
      onChange={handleInputChange}
    >
      {props.values.map((item, index) => mapToToggleButton(item, index))}
    </StyledToggleButtonGroup>
  );
}
