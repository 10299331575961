import { GridColumnsPanel } from "@mui/x-data-grid";
import styled from "styled-components";

export const StyledGridColumnsPanel = styled(GridColumnsPanel)`
  &.MuiDataGrid-panelWrapper {
    .MuiButton-root {
      color: ${(props) => props.theme.palettes.primary.dark};
      background-color: ${(props) => props.theme.palettes.grayscale.lightest};

      &:hover {
        color: ${(props) => props.theme.palettes.primary.main};
        background-color: ${(props) => props.theme.palettes.grayscale.lighter};
      }

      &:disabled {
        color: ${(props) => props.theme.palettes.primary.light};
        background-color: ${(props) =>
          props.theme.palettes.grayscale.disabledBackground};
      }
    }

    .MuiCheckbox-root {
      color: ${(props) => props.theme.palettes.grayscale.dark};

      &:hover {
        background-color: ${(props) => props.theme.palettes.grayscale.lightest};
      }

      &.Mui-disabled {
        color: ${(props) => props.theme.palettes.grayscale.disabledBackground};
      }
    }

    .Mui-focused fieldset {
      border-color: ${(props) => props.theme.palettes.grayscale.darkest};
    }
  }
`;
