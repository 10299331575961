import styled, { DefaultTheme } from "styled-components";

interface BreadcrumbListItemProps {
  $isBolded: boolean;
  $isGray: boolean;
  $isEdited: boolean;
}

function getFontWeight(isBolded: boolean) {
  if (isBolded) {
    return 800;
  }

  return 400;
}

function getFontColor(isGray: boolean, isEdited: boolean, theme: DefaultTheme) {
  if (isGray) {
    return theme.palettes.grayscale.medium;
  }

  if (isEdited) {
    return theme.palettes.secondary.info;
  }

  return theme.palettes.grayscale.darkest;
}

export const BreadcrumbListItem = styled.li<BreadcrumbListItemProps>`
  display: list-item;
  text-align: -webkit-match-parent;
  font-weight: ${(props) => getFontWeight(props.$isBolded)};
  color: ${(props) =>
    getFontColor(props.$isGray, props.$isEdited, props.theme)};
`;
