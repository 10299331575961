import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SelectCategoriesStepOne() {
    return (
        <StepContentDiv>
            <Paragraph>
                Categories can be added by directly clicking on them in the category tree. A category is considered selected when a red label appears on its left side. Multiple categories can be selected by clicking on each of them. It should be noted that only categories accessible to the publisher will be displayed.
            </Paragraph>
        </StepContentDiv>
    );
}
