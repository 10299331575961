import { defaultTheme } from "../theme";

export const getValidationStatusText = (
  isValidationError: boolean | undefined,
  isEdited: boolean | undefined,
  isWarning: boolean | undefined
): string | undefined => {
  const conditions = [
    {
      check: isValidationError && isEdited,
      text: " (Modified with Errors)",
    },
    { check: isValidationError, text: " (Errors)" },
    {
      check: isEdited && isWarning,
      text: " (Modified with Warnings)",
    },
    { check: isWarning, text: " (Warnings)" },
    {
      check: isEdited && !isValidationError,
      text: " (Modified)",
    },
  ];

  return conditions.find((c) => c.check)?.text;
};

export const getSectionHeaderColor = (
  isValidationError: boolean | undefined,
  isEdited: boolean | undefined,
  isWarning: boolean | undefined
): string => {
  if (isValidationError) {
    return defaultTheme.palettes.secondary.error;
  } else if (isWarning) {
    return defaultTheme.palettes.secondary.warning;
  } else if (isEdited) return defaultTheme.palettes.secondary.info;

  return "inherit";
};
