import { TooltipTop } from "../../../../../controls/Tooltips/ToolipTop";
import { Pill } from "./Pill";

export interface LanguagePillProps {
  isoCode: string;
  languageName: string;
  isGray: boolean;
}

export function LanguagePill(props: LanguagePillProps) {
  return (
    <TooltipTop title={props.languageName}>
      <Pill
        isGray={props.isGray}
        content={props.isoCode}
        className="security-pill"
      />
    </TooltipTop>
  );
}
