export enum SnackbarMsg {
  PublisherRolesError = "Error while fetching publisher roles",
  PublisherCategoriesError = "Error while fetching publisher categories",
  DocumentKindsError = "Error while fetching document kinds",
  ExportEmailsSuccess = "Emails exported successfully",
  PublishersError = "Error while fetching publishers",
  LanguagesError = "Error while fetching languages",
  AccessGroupsError = "Error while fetching access groups",
  CategoriesError = "Error while fetching categories",
  DocumentListError = "Failed to refresh the document list",
  RevisionsError = "Error while fetching revisions",
  DocumentGroupError = "Error while fetching document group",
  CategoryTagsError = "Error while fetching category tags",
  AvailableRevisionsError = "Error while fetching available revisions for document",
  DocumentNumberError = "Error while generating document number for document",
  CopyFileHashError = "Error while copying file hash",
  FileHashEmpty = "Nothing to copy, file hash is empty",
  CopyUrlError = "Error while copying document url",
  UrlEmpty = "Nothing to copy, document url is empty",
  CategoryChildrenError = "Error while fetching category children",
  CopyCategoryPathSuccess = "Category path copied successfully to clipboard",
  CopyCategoryPathError = "Something went wrong while copying category path to clipboard. If the problem persists, please contact ABB Service Desk via MyIS.",
  AttachmentError = "Error while fetching attachment",
  FileNameError = "File name validation failed",
  PublishDocumentError = "Document publishing failed. Please try again.\nIf the problem persists, please contact Service Desk.",
  HardDeleteError = "Error while hard deleting document",
  ScheduledActionsError = "Error while fetching scheduled actions",
  HardDeleteSuccess = "Document has been deleted permanently",
  DocumentApprovalError = "Error while sending document for approval",
  DocumentApprovalSuccess = "Document has been sent for approval",
  OwnerCategoriesError = "Error while fetching owner categories",
  SaveChangesError = "Error while saving changes",
  WorkflowChangeError = "Workflow change failed. Please try again.\nIf the problem persists, please contact Service Desk.",
  AttachmentStatusError = "Error while fetching attachment status",
  CountriesError = "Error while fetching countries",
  CategoryPermissionsError = "Error while fetching category permissions",
  SaveDocumentDraftError = "Error while saving document draft",
  SaveDocumentDraftSuccess = "Document draft saved successfully",
  SectionMarkSuccess = "Section marked as completed",
  SectionMarkError = "Error while completing section",
  UncompletedSectionsError = "Error while fetching uncompleted sections",
  LinkCategoryError = "Error while linking categories to document",
  PublishDocumentSuccess = "Document has been published successfully",
  PreviousNotDeletedError = "Error while fetching previous non deleted revision status",
  DocumentDraftDeleteError = "Error while deleting document draft",
  DocumentDraftDeleteSuccess = "Document draft removed successfully",
  DocumentApprovalDeleteError = "Error while deleting document in approval",
  DocumentApprovalDeleteSuccess = "Document in approval removed successfully",
  ScheduleActionsError = "Error occurred while scheduling action on a document",
  DeleteScheduledActionsError = "Error while deleting scheduled action",
  ScheduledActionSuccess = "Action scheduled successfully",
  DeleteScheduledActionsSuccess = "Scheduled action deleted successfully",
}