import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { ChangeEvent, useState } from "react";
import { MenuItemDiv } from "./SC/MenuItemDiv";
import { MenuItemSpan } from "./SC/MenuItemSpan";
import { Divider, Menu, Switch } from "@mui/material";
import { DocumentsColumnType } from "../../../../../models/documentList/types/DocumentColumnType";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DropResult,
} from "react-beautiful-dnd";
import { StyledInfoIcon } from "./SC/StyledInfoIcon";
import { ButtonDiv } from "../../SC/ButtonDiv";

import {
  selectFitToScreen,
  setFitToScreen,
} from "../../../../../app/slices/contentSlice";
import {
  selectDocumentColumn,
  setDocumentColumn,
  setColumnVisibility,
} from "../../../../../app/slices/documentListViewSlice";
import { TextButtonControl } from "../../../../../controls/Buttons/TextButtonControl";
import { StyledCheckbox } from "../../../../../controls/StyledComponents/StyledCheckbox";
import { StyledIconButton } from "../../../../../controls/StyledComponents/StyledIconButton";
import { TooltipTop } from "../../../../../controls/Tooltips/ToolipTop";
import { TooltipPartDiv } from "../../../../../controls/Tooltips/SC/TooltipPartDiv";
import { TooltipPartSpan } from "../../../../../controls/Tooltips/SC/TooltipPartSpan";
import { SwitchDiv } from "./SC/SwitchDiv";
import { useTheme } from "styled-components";
import { ColumnVisibilityDiv } from "./SC/ColumnVisibilityDiv";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";

interface ColumnVisibilityProps {
  isCompactView: boolean;
}

export function ColumnVisibility(props: ColumnVisibilityProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const documentColumn = useAppSelector(selectDocumentColumn);
  const fitToScreen = useAppSelector(selectFitToScreen);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showDragDropButton, setShowDragDropButton] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrop = (column: DropResult) => {
    if (!column.destination) {
      return;
    }
    const updatedColumns = [...documentColumn];
    const [item] = updatedColumns.splice(column.source.index, 1);
    updatedColumns.splice(column.destination.index, 0, item);
    dispatch(setDocumentColumn(updatedColumns));
  };

  const handleVisibilityChange = (
    columnType: DocumentsColumnType,
    visible: boolean
  ) => {
    dispatch(setColumnVisibility({ columnType, visible }));
  };

  const changeOverflow = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(setFitToScreen(!checked));
  };

  return (
    <ButtonDiv>
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.primaryButtonHeight}
        icon={<ViewColumnIcon fontSize="small" />}
        id="column-visibility-button"
        tooltipText={TooltipMsg.ColumnsVisibility}
        text="COLUMNS"
        onClick={handleClick}
        isVertical={false}
        isVisible={true}
        isCompactView={props.isCompactView}
      />
      <Menu
        id="column-visibility-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{ style: { maxHeight: "100%" } }}
      >
        <MenuItemDiv
          id={"columns-visibility-menu-option-number"}
          onMouseEnter={() => setShowDragDropButton(true)}
          onMouseLeave={() => setShowDragDropButton(true)}
        >
          <ColumnVisibilityDiv>
            {showDragDropButton && (
              <StyledIconButton
                padding={4}
                disabled={true}
                cursor="grab"
                id="columns-visibility-menu-option-number-icon"
                size="medium"
              >
                <DragIndicatorIcon fontSize="inherit" />
              </StyledIconButton>
            )}
          </ColumnVisibilityDiv>

          <TooltipTop title={TooltipMsg.ColumnCannotBeRemoved}>
            <StyledInfoIcon />
          </TooltipTop>
          <MenuItemSpan>Number</MenuItemSpan>
        </MenuItemDiv>
        <MenuItemDiv
          id={"columns-visibility-menu-option-title"}
          onMouseEnter={() => setShowDragDropButton(true)}
          onMouseLeave={() => setShowDragDropButton(true)}
        >
          <ColumnVisibilityDiv>
            {showDragDropButton && (
              <StyledIconButton
                padding={4}
                disabled={true}
                cursor="grab"
                id="columns-visibility-menu-option-title-icon"
                size="medium"
              >
                <DragIndicatorIcon fontSize="inherit" />
              </StyledIconButton>
            )}
          </ColumnVisibilityDiv>

          <TooltipTop title={TooltipMsg.ColumnCannotBeRemoved}>
            <StyledInfoIcon />
          </TooltipTop>
          <MenuItemSpan>Title</MenuItemSpan>
        </MenuItemDiv>
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="list-container">
            {(provided: DroppableProvided) => (
              <div
                className="list-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {documentColumn.map(
                  (column, index) =>
                    column.isFiltered && (
                      <Draggable
                        key={column.title}
                        draggableId={column.type}
                        index={index}
                      >
                        {(provided: DraggableProvided) => (
                          <div
                            className="column-container"
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <MenuItemDiv
                              id={
                                "columns-visibility-menu-option-" + column.type
                              }
                              onMouseEnter={() => setShowDragDropButton(true)}
                              onMouseLeave={() => setShowDragDropButton(true)}
                            >
                              <ColumnVisibilityDiv>
                                {showDragDropButton && (
                                  <StyledIconButton
                                    padding={4}
                                    id={
                                      "columns-visibility-menu-option-icon=" +
                                      column.type
                                    }
                                    size="medium"
                                    cursor="grab"
                                  >
                                    <DragIndicatorIcon fontSize="inherit" />
                                  </StyledIconButton>
                                )}
                              </ColumnVisibilityDiv>
                              <StyledCheckbox
                                checked={column.isVisible}
                                onChange={(e) => {
                                  handleVisibilityChange(
                                    column.type,
                                    e.currentTarget.checked
                                  );
                                }}
                              />

                              <MenuItemSpan>{column.title}</MenuItemSpan>
                            </MenuItemDiv>
                          </div>
                        )}
                      </Draggable>
                    )
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Divider />
        <MenuItemDiv>
          <SwitchDiv>
            <TooltipTop
              title={
                <>
                  <TooltipPartDiv>
                    <TooltipPartSpan>
                      When enabled, columns can be extended past screen size
                      (showing horizontal scrollbar).
                    </TooltipPartSpan>
                    <TooltipPartSpan>
                      When disabled, columns will take available space within
                      the screen and can be resized within the screen only.
                    </TooltipPartSpan>
                  </TooltipPartDiv>
                </>
              }
            >
              <Switch
                value={fitToScreen}
                checked={!fitToScreen}
                onChange={changeOverflow}
              />
            </TooltipTop>
          </SwitchDiv>
          <MenuItemSpan>Allow overflow</MenuItemSpan>
        </MenuItemDiv>
      </Menu>
    </ButtonDiv>
  );
}
