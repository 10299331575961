import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function AllButtonStep6() {
    return (
        <StepContentDiv>
            <Paragraph>
                User preferences, such as columns and density, are saved for future sessions.
            </Paragraph>
        </StepContentDiv>
    );
}
