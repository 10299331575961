import styled from "styled-components";
import { serviceDocumentIcon } from "../../../../../icons/DocumentInfoIcons";

export const BreadcrumbServiceDocumentIcon = styled(serviceDocumentIcon)`
  display: flex;
  justify-content: center;
  adjust-items: center;
  opacity: 50%;

  &.MuiSvgIcon-root {
    width: ${(props) => props.theme.typography.fontSizes.smallIcon}px;
    height: ${(props) => props.theme.typography.fontSizes.smallIcon}px;
  }
`;
