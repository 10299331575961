import { MuiChipsInput } from "mui-chips-input";
import styled from "styled-components";

interface StyledMuiChipsProps {
  $isEditable: boolean;
  $showDeleteIcons: boolean;
  $idEdited: boolean;
}

export const StyledMuiChips = styled(MuiChipsInput) <StyledMuiChipsProps>`
  &.MuiFormControl-root {
    padding-top: 4px;
    width: 100%;
  }
  .MuiOutlinedInput-root {
    gap: 4px;
  }

  .MuiOutlinedInput-root:hover {
    padding-right: 35px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) =>
    props.$isEditable
      ? props.theme.palettes.grayscale.medium
      : props.theme.palettes.grayscale.white};
    border-width: ${(props) => (props.$isEditable ? "1px" : "0px")};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palettes.grayscale.darkest};
  }

  &.MuiFormControl-root .MuiInputLabel-root {
    padding-top: 4px;
  }

  .MuiChipsInput-Chip {
    max-width: 85%;

    &.chip-error {
      border: 1px solid ${(props) => props.theme.palettes.primary.main};
    }
  }

  .MuiIconButton-root {
    margin-right: -6px;
  }

  input {
    display: ${(props) => (!props.$isEditable ? "none" : "inherit")};
  }
`;
