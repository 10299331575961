import styled from "styled-components";

interface ItemAvatarDivProps {
  $isHidden?: boolean;
}

export const ItemAvatarDiv = styled.div<ItemAvatarDivProps>`
  visibility: ${(props) => (props.$isHidden ? "hidden" : "visible")};
  height: 44px;
  max-height: 44px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
