import {
  createListenerMiddleware,
  isAnyOf,
  TypedStartListening,
} from "@reduxjs/toolkit";
import {
  publisherDirectCategoriesStorageKey,
  setParamsInLocalStorage,
} from "../../../app/hooks/localStorage";
import { PublisherDirectCategoriesParams } from "../../../app/slices/publisherDirectCategoriesSlice";
import {
  setPublishersColumnVisibility,
  setPublishersDensity,
  setPublishersPageSize,
} from "../../../app/slices/publishersListSlice";
import { AppDispatch, RootState } from "../../../app/store";

export const publisherDirectCategoriesLocalStorageMiddleware =
  createListenerMiddleware();

function getpublisherDirectCategoriesState(
  state: PublisherDirectCategoriesParams
) {
  return {
    pageSize: state.pageSize,
    density: state.density,
    columnVisibilityModel: state.columnVisibilityModel,
  };
}

type AppStartListening = TypedStartListening<RootState, AppDispatch>;

const startAppListening =
  publisherDirectCategoriesLocalStorageMiddleware.startListening as AppStartListening;

startAppListening({
  matcher: isAnyOf(
    setPublishersDensity,
    setPublishersPageSize,
    setPublishersColumnVisibility
  ),
  effect: (_, listenerApi) => {
    setParamsInLocalStorage(
      publisherDirectCategoriesStorageKey,
      getpublisherDirectCategoriesState(
        listenerApi.getState().publisherDirectCategories
      )
    );
  },
});
