import { useAppDispatch } from "../../app/hooks/hooks";
import {} from "../../app/helpers/collectionExtensions";
import {
  ScreenSplashEnum,
  SplashScreensContents,
} from "../../models/screenSplash";
import {
  setCurrentSplash,
  setUserAgreedForTooSmallWindow,
} from "../../app/slices/commonSlice";
import { ScreenSplashDiv } from "./SC/ScreenSplashDiv";
import { ScreenSplashImg } from "./SC/ScreenSplashImg";
import { ScreenSplashImgDiv } from "./SC/ScreenSplashImgDiv";
import { ScreenSplashImgSpan } from "./SC/ScreenSplashImgSpan";
import { ScreenSplashMessageSpan } from "./SC/ScreenSplashMessageSpan";
import { ScreenSplashTitleSpan } from "./SC/ScreenSplashTitleSpan";
import { StyledLink } from "../StyledComponents/StyledLink";

export interface ScreenSplashProps {
  currentScreenSplash: ScreenSplashEnum;
}

export function ScreenSplash(props: ScreenSplashProps) {
  const dispatch = useAppDispatch();
  const currentSplashContent = SplashScreensContents.first(
    (splash) => splash.type === props.currentScreenSplash
  );

  return (
    <ScreenSplashDiv splashScreen={props.currentScreenSplash}>
      <ScreenSplashImgDiv>
        <ScreenSplashImg src="/abbLogo.png" />
        <ScreenSplashImgSpan>Library Publish Interface</ScreenSplashImgSpan>
      </ScreenSplashImgDiv>
      <ScreenSplashTitleSpan>
        {currentSplashContent.title}
      </ScreenSplashTitleSpan>
      <ScreenSplashMessageSpan>
        {currentSplashContent.message}
      </ScreenSplashMessageSpan>
      {props.currentScreenSplash === ScreenSplashEnum.WindowTooSmall && (
        <ScreenSplashMessageSpan>
          You can still go to Abb Library Publishing interface but the
          application might not be displayed in a proper way. Click{" "}
          <StyledLink
            to="#"
            onClick={() => {
              dispatch(setCurrentSplash(ScreenSplashEnum.None));
              dispatch(setUserAgreedForTooSmallWindow(true));
            }}
          >
            here
          </StyledLink>{" "}
          if you still wish to proceed to the application.
        </ScreenSplashMessageSpan>
      )}
    </ScreenSplashDiv>
  );
}
