import { Button } from "@mui/material";
import styled from "styled-components";

export const StyledDiscreetButton = styled(Button)`
  &.MuiButton-root {
    align-self: flex-end;
    height: ${(props) => props.theme.shapes.secondaryButtonHeight}px;
    white-space: nowrap;
    color: ${(props) => props.theme.palettes.secondary.info};
    background-color: ${(props) => props.theme.palettes.grayscale.white};
    border: 2px ${(props) => props.theme.palettes.grayscale.white} solid;
  }

  &.MuiButton-root:hover {
    background-color: ${(props) => props.theme.palettes.grayscale.white};
    border: 2px ${(props) => props.theme.palettes.secondary.info} solid;
  }

  &.MuiButton-root:disabled {
    &:hover {
      border: 2px ${(props) => props.theme.palettes.grayscale.white} solid;
    }
  }
`;
