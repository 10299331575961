import { useAppSelector } from "../../../app/hooks/hooks";
import { selectSelectedTag } from "../../../app/slices/tagsViewSlice";
import CategoriesGroupColumn from "../Categories/CategoriesGroupColumn";
import { TagOwnersGroupColumn } from "../TagOwners/TagOwnersGroupColumn";
import { TagValues } from "../TagValues/TagValues";
import { ColumnDiv } from "./SC/ColumnDiv";
import { DashboardDiv } from "./SC/DashboardDiv";
import { DashboardRowDiv } from "./SC/DashboardRowDiv";
import { ValuesRowDiv } from "./SC/ValuesRowDiv";

export function TagsDashboard() {
  const selectedTag = useAppSelector(selectSelectedTag);

  return (
    <DashboardDiv>
      <DashboardRowDiv>
        <ValuesRowDiv>
          <TagValues key={selectedTag ? selectedTag.id : "none"} />
        </ValuesRowDiv>
        <ColumnDiv>
          <CategoriesGroupColumn />
          <TagOwnersGroupColumn />
        </ColumnDiv>
      </DashboardRowDiv>
    </DashboardDiv>
  );
}
