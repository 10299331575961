import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { StyledLink } from "../../../SC/StyledLink";

export function SectionStepOne() {
  return (
    <StepContentDiv>
      <Paragraph>
        The Products feature is utilized to designate documents for specific
        products that are classified under selected categories.
      </Paragraph>
      <Paragraph>
        It is important to note that only Product IDs that exist in the{" "}
        <StyledLink
          href="https://pis.us.abb.com/PisDashboard/Dashboard/Index"
          target="_blank"
        >
          Product Information Service (VPN is required to open)
        </StyledLink>{" "}
        should be used.
      </Paragraph>
      <Paragraph>
        Multiple Product IDs can be entered to associate the document with
        multiple products.
      </Paragraph>
      <Paragraph>
        To easily add multiple values, seperate them with comma or semicolon,
        paste them into the input field and confim with Enter. Multiple values
        will be added instead of one.
      </Paragraph>
    </StepContentDiv>
  );
}
