import styled from "styled-components";

interface StyledScheduledActionSpanProps {
  float: "left" | "none";
  width?: string;
  $margin?: number;
  $paddingTop?: number;
}
export const StyledScheduledActionSpan = styled.span<StyledScheduledActionSpanProps>`
  width: ${(props) => (props.width ? props.width : "26ch")};
  float: ${(props) => props.float};
  padding-top: ${(props) =>
    props.$paddingTop ? props.$paddingTop + "px" : "unset"};
  display: inline-flex;
  & .MuiTextField-root {
    margin-top: ${(props) => (props.$margin ? props.$margin + "px" : "2px")};
    width: ${(props) => (props.width ? props.width : "26ch")};
  }
`;
