import { SectionName } from "../../../../../app/slices/models/documentDetailsModels";
import { TooltipTop } from "../../../../../controls/Tooltips/ToolipTop";
import { SectionIconsDiv } from "../../SC/SectionIconsDiv";
import { ExpandCollapseBreadcrumbsButton } from "../../categories/breadcrumb/ExpandCollapseBreadcrumbsButton";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { selectIsWindowTooSmall } from "../../../../../app/slices/commonSlice";
import {
  expandSection,
  selectIsLoadingDetailsMetaData,
  selectMaximizedSection,
  selectSecurityTabAlternativeView,
  setMaximizedSection,
  swapSecurityTabView,
} from "../../../../../app/slices/documentDetailsSlice";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { ReactElement, useCallback } from "react";
import { setCurrentSelfHelp } from "../../../../../app/slices/selfHelpSlice";
import { CurrentSelfHelp } from "../../../../../models/CurrentSelfHelpType";
import { StyledSwapHoriz } from "./SC/StyledSwapHoriz";
import { StyledCloseFullscreen } from "./SC/StyledCloseFullscreen";
import { StyledOpenInFull } from "./SC/StyledOpenInFull";
import { StyledCancelIcon } from "./SC/StyledCancelIcon";
import { StyledEditIcon } from "./SC/StyledEditIcon";
import { StyledWarningIcon } from "./SC/StyledWarningIcon";
import { SelfHelpPage } from "../../../../../models/selfHelp/selfHelpSection";
import { SelfHelpButtonControl } from "../../../../../controls/Buttons/SelfHelpButtonControl";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";

interface IconsControlProps {
  iconsVisible: boolean;
  disableInteraction?: boolean;
  section: SectionName;
  isCompactView: boolean;
  validationErrorText?: string;
  validationErrorColor?: string;
  isEdited: boolean;
  isWarning?: boolean;
  isError?: boolean;
  isExpanded: boolean;
  selfHelpType?: CurrentSelfHelp;
  onDiscardChanges: () => void;
  setOpenSelfHelp: (open: boolean) => void;
  setIsExpanded: (expanded: boolean) => void;
}

export function IconsControl(props: IconsControlProps) {
  const dispatch = useAppDispatch();
  const isTooSmallWindow = useAppSelector(selectIsWindowTooSmall);
  const maximizedSection = useAppSelector(selectMaximizedSection);
  const showAlternativeTab = useAppSelector(selectSecurityTabAlternativeView);
  const isLoadingDetailsMetaData = useAppSelector(
    selectIsLoadingDetailsMetaData
  );

  const appInsights = useAppInsightsContext();
  const trackSecurityViewModeSwitch = useTrackEvent(
    appInsights,
    "Security section view mode switched",
    { view: "" }
  );

  const isNotEnoughWidth = () => {
    return props.isCompactView && props.validationErrorText !== undefined;
  };

  const handleFullScreenClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    const target =
      maximizedSection?.name === props.section ? undefined : props.section;

    dispatch(setMaximizedSection(target));

    if (target) {
      dispatch(expandSection(props.section));
    }
  };

  const handleDiscardChangesClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    props.onDiscardChanges();
  };

  const handleSecuritySwapClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    dispatch(swapSecurityTabView());
    trackSecurityViewModeSwitch({
      view: !showAlternativeTab ? "Alternative" : "Default",
    });
  };

  const handleSelfHelpClick = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      if (
        props.selfHelpType !== undefined &&
        !props.isExpanded &&
        !isLoadingDetailsMetaData
      ) {
        dispatch(expandSection(props.section));
        props.setIsExpanded(true);
        props.setOpenSelfHelp(true);
      } else if (
        props.selfHelpType !== undefined &&
        props.isExpanded &&
        !isLoadingDetailsMetaData
      ) {
        dispatch(setCurrentSelfHelp(props.selfHelpType));
      }
    },
    [props, isLoadingDetailsMetaData, dispatch]
  );

  const getIcon = useCallback((): ReactElement => {
    const editIcon = (
      <StyledEditIcon
        id={props.section + "-edit"}
        fontSize="small"
        $color={props.validationErrorColor}
      />
    );

    const warningIcon = (
      <StyledWarningIcon
        id={props.section + "-edit"}
        fontSize="small"
        $color={props.validationErrorColor}
      />
    );

    if (props.isEdited && !props.isWarning && !props.isError) return editIcon;
    if (props.isWarning || props.isError) return warningIcon;

    return <></>;
  }, [
    props.isEdited,
    props.isError,
    props.isWarning,
    props.section,
    props.validationErrorColor,
  ]);

  return (
    <SectionIconsDiv>
      {props.iconsVisible && !isTooSmallWindow && !props.disableInteraction && (
        <>
          {maximizedSection ? (
            <TooltipTop title={TooltipMsg.Restore}>
              <StyledCloseFullscreen
                id={props.section + "-restore"}
                fontSize="small"
                onClick={handleFullScreenClick}
              />
            </TooltipTop>
          ) : (
            <TooltipTop title={TooltipMsg.Maximize}>
              <StyledOpenInFull
                id={props.section + "-maximize"}
                fontSize="small"
                onClick={handleFullScreenClick}
              />
            </TooltipTop>
          )}
        </>
      )}
      {isNotEnoughWidth() && (
        <TooltipTop title={props.validationErrorText}>{getIcon()}</TooltipTop>
      )}
      {props.isEdited && (
        <TooltipTop title={TooltipMsg.DiscardChanges}>
          <StyledCancelIcon
            id={props.section + "-discard-changes"}
            fontSize="small"
            onClick={handleDiscardChangesClick}
          />
        </TooltipTop>
      )}
      {props.section === SectionName.Security && (
        <TooltipTop title={TooltipMsg.Swap}>
          <StyledSwapHoriz
            id={props.section + "-swap"}
            fontSize="small"
            onClick={handleSecuritySwapClick}
          />
        </TooltipTop>
      )}
      {props.section === SectionName.Categories && (
        <ExpandCollapseBreadcrumbsButton />
      )}
      <SelfHelpButtonControl
        id={props.section + "-help"}
        onClickHandler={handleSelfHelpClick}
        page={SelfHelpPage.Details}
        section={props.section}
      />
    </SectionIconsDiv>
  );
}
