import styled from "styled-components";

interface TextButtonContentDivProps {
  isVertical: boolean;
  isHighlight?: boolean;
  removeGap?: boolean;
}

function getDirection(isVertical: boolean) {
  if (isVertical) {
    return "column";
  }

  return "row";
}

export const TextButtonContentDiv = styled.div<TextButtonContentDivProps>`
  color: ${(props) =>
    props.isHighlight ? props.theme.palettes.primary.main : "unset"};
  display: flex;
  flex: 1;
  gap: ${(props) => (props.removeGap ? "0px" : "4px")};
  align-items: center;
  justify-content: center;
  flex-direction: ${(props) => getDirection(props.isVertical)};
`;
