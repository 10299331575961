import { Button } from "@mui/material";
import styled from "styled-components";
import { defaultTheme } from "../../theme";

const StyledErrorButton = styled(Button)`
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: ${defaultTheme.palettes.primary.main};
`;

export default StyledErrorButton;
