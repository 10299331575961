import { TooltipProps } from "@mui/material";
import styled from "styled-components";
import { TooltipTop } from "../../../controls/Tooltips/ToolipTop";

export const StyledPopupTooltip = styled(TooltipTop)<TooltipProps>`
  & .MuiTooltip-tooltip {
    font-size: ${(props) => props.theme.typography.fontSizes.small}px;
    padding: 2px 4px 2px 4px;
  }
`;
