import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import InfoIcon from "@mui/icons-material/Info";

export function DocumentKindFirstStep() {
    const theme = useTheme();

    return (
      <StepContentDiv>
        <Paragraph>
          Document Kind is a classification that categorizes documents based on
          the type and purpose of the information contained in the attachment.
        </Paragraph>
        <Paragraph>
          Descriptions of Document Kinds can be accessed by clicking on the{" "}
          <InfoIcon
            style={{
              fontSize: "16px",
              verticalAlign: "middle",
              color: theme.palettes.grayscale.dark,
            }}
          />{" "}
          icon in this section.
        </Paragraph>
        <Paragraph>
          The allowed Document Kinds are determined by the selected Main
          Category. If document kind is selected before Main Category is, then
          it is possible it will not be allowed.
        </Paragraph>
      </StepContentDiv>
    );
}
