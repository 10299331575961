import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { splitViewParamsLocalStorageKey } from "../hooks/localStorage";
import { RootState } from "../store";
import { margins, navigationDefaultWidth } from "./models/contentModels";

const defaultSplitViewParams: SplitViewParams = {
  navigationWidth: navigationDefaultWidth,
  contentWidth: 0,
  fitToScreen: true,
};

export interface SplitViewParams {
  navigationWidth: number;
  contentWidth: number;
  fitToScreen: boolean;
}

export interface SplitViewState {
  navigationWidth: number;
  contentWidth: number;
  fitToScreen: boolean;
  isResizing: boolean;
}

export function getInitialParams() {
  try {
    const value = localStorage.getItem(splitViewParamsLocalStorageKey);
    if (value) {
      return JSON.parse(value) as SplitViewParams;
    }

    return defaultSplitViewParams;
  } catch {
    return defaultSplitViewParams;
  }
}

const initialState: SplitViewState = {
  isResizing: false,
  ...getInitialParams(),
};

export const contentSlice = createSlice({
  name: "splitView",
  initialState,
  reducers: {
    setNavigationWidth(state: SplitViewState, action: PayloadAction<number>) {
      state.navigationWidth = action.payload;
    },
    setContentWidth(state: SplitViewState, action: PayloadAction<number>) {
      state.contentWidth = action.payload;
    },
    setFitToScreen(state: SplitViewState, action: PayloadAction<boolean>) {
      state.fitToScreen = action.payload;
    },
    setIsResizing(state: SplitViewState, action: PayloadAction<boolean>) {
      state.isResizing = action.payload;
    },
  },
});

export const {
  setNavigationWidth,
  setContentWidth,
  setFitToScreen,
  setIsResizing,
} = contentSlice.actions;

export const selectNavigationWidth = (state: RootState) =>
  state.splitView.navigationWidth;
export const selectContentWidth = (state: RootState) =>
  state.splitView.contentWidth - margins;
export const selectFitToScreen = (state: RootState) =>
  state.splitView.fitToScreen;
export const selectIsResizing = (state: RootState) =>
  state.splitView.isResizing;

export default contentSlice.reducer;
