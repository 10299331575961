import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  QueryDefinition,
} from "@reduxjs/toolkit/query";
import { useCallback } from "react";
import {
  useLazyGetCategoriesTreeQuery,
  useLazyGetCategoriesTreeWithFacetsQuery,
} from "../../apis/categoriesListApi";
import CategoryTreeWithFacetsSearchCriteria from "../../models/navigationCategories/categoryTreeWithFacetsSearchCriteria";
import NavigationCategory from "../../models/navigationCategories/navigationCategory";
import { SnackbarType } from "../../models/snackbar";
import {
  addNavigationCategories,
  updateNavigationCategories,
} from "../slices/navigationCategoryTreeSlice";
import { enqueueSnackbar } from "../slices/snackbarSlice";
import { useAppDispatch } from "./hooks";
interface NavigationTreeFetchProps {
  categoryId: string;
  categoryDepth: number;
  includeHighestParent: boolean;
  hasChildrenWithoutFacets: boolean;
}

interface NavigationTreeFetchResult {
  fetchCategories: () => Promise<void>;
  refetchCategoriesTreeFacets: (
    categoryId: string,
    skip: boolean
  ) => Promise<void>;
  refetchCategoriesTree: (categoryId: string, skip: boolean) => Promise<void>;
  getCategoriesTreeWithFacets: LazyQueryTrigger<
    QueryDefinition<
      CategoryTreeWithFacetsSearchCriteria,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
      never,
      NavigationCategory[],
      "categoriesListApi"
    >
  >;
  categoriesTreeFacetsIsError: boolean;
  categoriesTreeFacetsIsLoading: boolean;
  categoriesTreeIsError: boolean;
}

function useNavigationTreeFetch(
  props: NavigationTreeFetchProps
): NavigationTreeFetchResult {
  const dispatch = useAppDispatch();
  const [getCategoriesTree, { isError: categoriesTreeIsError }] =
    useLazyGetCategoriesTreeQuery();
  const [
    getCategoriesTreeWithFacets,
    {
      isError: categoriesTreeFacetsIsError,
      isLoading: categoriesTreeFacetsIsLoading,
    },
  ] = useLazyGetCategoriesTreeWithFacetsQuery();

  const refetchCategoriesTree = async (categoryId: string, skip: boolean) => {
    if (skip) return;

    await getCategoriesTree({
      categoryId: categoryId,
      initialTreeDepth: props.categoryDepth,
      treeDepth: 2,
      includeHighestParent: props.includeHighestParent,
    });
  };

  const refetchCategoriesTreeFacets = async (
    categoryId: string,
    skip: boolean
  ) => {
    if (skip) return;

    const categoriesTreeFacets = getCategoriesTreeWithFacets({
      categoryId: categoryId,
      treeDepth: 1,
    });
    try {
      const categoriesTreeFacetsData = (await categoriesTreeFacets).data;
      if (categoriesTreeFacetsData) {
        dispatch(
          updateNavigationCategories({
            parentCategoryId: categoryId,
            categories: categoriesTreeFacetsData,
          })
        );
      }
    } catch {
      dispatch(
        enqueueSnackbar({
          message: "Error while loading categories tree facets",
          type: SnackbarType.error,
        })
      );
    }
  };

  const fetchCategories = useCallback(async () => {
    const categoriesTreeFacets = getCategoriesTreeWithFacets({
      categoryId: props.categoryId,
      treeDepth: 1,
    });

    try {
      if (props.hasChildrenWithoutFacets) {
        const categoriesTree = getCategoriesTree({
          categoryId: props.categoryId,
          initialTreeDepth: props.categoryDepth,
          treeDepth: 2,
          includeHighestParent: props.includeHighestParent,
        });
        const categoriesTreeData = (await categoriesTree).data;
        dispatch(addNavigationCategories(categoriesTreeData ?? []));
      }

      try {
        const categoriesTreeFacetsData = (await categoriesTreeFacets).data;
        if (categoriesTreeFacetsData) {
          dispatch(
            updateNavigationCategories({
              parentCategoryId: props.categoryId,
              categories: categoriesTreeFacetsData,
            })
          );
        }
      } catch {
        dispatch(
          enqueueSnackbar({
            message: "Error while loading categories tree facets",
            type: SnackbarType.error,
          })
        );
      }
    } catch {
      dispatch(
        enqueueSnackbar({
          message: "Error while loading categories tree",
          type: SnackbarType.error,
        })
      );
    }
  }, [
    dispatch,
    getCategoriesTree,
    getCategoriesTreeWithFacets,
    props.categoryId,
    props.includeHighestParent,
    props.categoryDepth,
    props.hasChildrenWithoutFacets,
  ]);

  return {
    fetchCategories,
    refetchCategoriesTreeFacets,
    refetchCategoriesTree,
    getCategoriesTreeWithFacets,
    categoriesTreeFacetsIsError,
    categoriesTreeFacetsIsLoading,
    categoriesTreeIsError,
  };
}

export default useNavigationTreeFetch;
