import { ListItemIcon, ListItemText, Menu } from "@mui/material";
import { StyledMenuItem } from "../../documents/documentsList/documentListItem/cells/styledComponents/StyledMenuItem";
import { TooltipTop } from "../../../controls/Tooltips/ToolipTop";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import ErrorIcon from '@mui/icons-material/Error';

interface HelpMenuProps {
  anchorEl: null | HTMLElement;
  onClose: () => void;
}

export function HelpMenu(props: HelpMenuProps) {
  return (
    <Menu
      anchorEl={props.anchorEl}
      open={!!props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <TooltipTop title={"Open incident if something does not work as intended"}>
        <StyledMenuItem onClick={() => window.open("https://abb.service-now.com/_myservices?id=cp2_sc_cat_item&sys_id=1f9969e4db99ef40aabe75e98c96196c", "Report an incident in Abb Library Publishing Interface")}>
          <ListItemIcon>
            <ErrorIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Incident</ListItemText>
        </StyledMenuItem>
      </TooltipTop>

      <TooltipTop title={"Additional access can be requested via Service Now"}>
        <StyledMenuItem onClick={() => window.open("https://abb.service-now.com/_myservices?id=cp2_sc_cat_item&sys_id=26d627f5dbcba09498282e84059619e1", "Request access to Abb Library Publishing Interface")}>
          <ListItemIcon>
            <PersonAddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Request access</ListItemText>
        </StyledMenuItem>
      </TooltipTop>

      <TooltipTop title={"Manual explaining all features available to Publishers and Superusers"}>
        <StyledMenuItem onClick={() => window.open("https://search.abb.com/library/Download.aspx?DocumentID=9AKK108469A9793&LanguageCode=en&DocumentPartId=&Action=Launch", "Abb Library Publishing Interface publisher manual")}>
          <ListItemIcon>
            <MenuBookIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Publisher manual</ListItemText>
        </StyledMenuItem>
      </TooltipTop>

      <TooltipTop title={"Manual explaining all features available to Category Owners"}>
        <StyledMenuItem onClick={() => window.open("https://search.abb.com/library/Download.aspx?DocumentID=9AKK108469A9795&LanguageCode=en&DocumentPartId=&Action=Launch", "Abb Library Publishing owner manual")}>
          <ListItemIcon>
            <MenuBookIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Owner manual</ListItemText>
        </StyledMenuItem>
      </TooltipTop>

      <TooltipTop title={"Environment where Publish UI can be tested without impacting end users"}>
        <StyledMenuItem onClick={() => window.open("https://publish.library.stage.abb.com/", "Abb Library Publishing Interface Training Environment")}>
          <ListItemIcon>
            <ModelTrainingIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Training environment</ListItemText>
        </StyledMenuItem>
      </TooltipTop>
    </Menu>
  );
}
