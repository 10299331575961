import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function RequestAccessStep5() {
    return (
        <StepContentDiv>
            <Paragraph>
                This action opens an instruction with the link to request access to Publish UI.
            </Paragraph>
        </StepContentDiv>
    );
}
