import { StepContentDiv } from "../../../SC/StepContentDiv";

export function HorizontalSplitterStep() {
  return (
    <StepContentDiv>
      The Navigation and Filters section can be resized by dragging and dropping
      this splitter.
    </StepContentDiv>
  );
}
