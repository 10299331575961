import { DocumentsPagination } from "./DocumentsPagination";
import { DocumentsFooterDiv } from "./SC/DocumentsFooterDiv";

export function DocumentsFooter() {
  return (
    <DocumentsFooterDiv id="documents-list-footer-div">
      <DocumentsPagination />
    </DocumentsFooterDiv>
  );
}
