import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { Screenshot } from "../../../SC/Screenshot";
import screenshot_ds from "../../../screenshots/description/ds_selected_summary.png";

export function SummaryStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                The summary provides a more detailed description of the content within the attachment.
            </Paragraph>
            <Paragraph>
                While the title can be considered a &quot;short description&quot;, the summary can be seen as a &quot;long description&quot;.
            </Paragraph>
            <Paragraph>
                It is displayed in the Download Section
            </Paragraph>
            <Paragraph>
                <Screenshot src={screenshot_ds} />
            </Paragraph>
        </StepContentDiv>
    );
}
