import { LinearProgressWithLabel } from "../../../../controls/ProgressIndicators/LinearProgressWithLabel";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { AttachmentUploadControlDiv } from "./SC/AttachmentUploadControlDiv";
import { StyledIconButton } from "../../../../controls/StyledComponents/StyledIconButton";
import { TooltipTop } from "../../../../controls/Tooltips/ToolipTop";
import { useAppSelector } from "../../../../app/hooks/hooks";
import {
  selectIsAttachmentUploadInProgress,
  selectIsFileUploadAborted,
  selectIsFileUploading,
  selectIsSingleChunkUploading,
} from "../../../../app/slices/fileUploadSlice";
import { useEffect, useMemo, useState } from "react";
import { initialEditableState } from "../../../../app/hooks/permissions/models/state";
import { useUserCanEditDocument } from "../../../../app/hooks/permissions/useUserCanEditDocument";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";

interface AttachmentUploadingProps {
  onStart: () => void;
  onPause: () => void;
  onStop: () => void;
  progress: number;
  canResumeUpload?: boolean;
}

export function UploadInProgressControl(props: AttachmentUploadingProps) {
  const isAttachmentUploadInProgress = useAppSelector(
    selectIsAttachmentUploadInProgress
  );
  const isFileUploading = useAppSelector(selectIsFileUploading);
  const isSingleChunkUpload = useAppSelector(selectIsSingleChunkUploading);
  const isFileUploadAborted = useAppSelector(selectIsFileUploadAborted);

  const [editableState, setEditableState] = useState(initialEditableState);
  const { canEditDocument } = useUserCanEditDocument();

  useEffect(() => {
    setEditableState(canEditDocument(false));
  }, [canEditDocument]);

  const isPauseButtonDisabled = useMemo(() => {
    return !editableState.isEditable || !isFileUploading || isSingleChunkUpload;
  }, [editableState.isEditable, isFileUploading, isSingleChunkUpload]);

  const isResumeButtonDisabled = useMemo(() => {
    return (
      !editableState.isEditable || isFileUploading || !props.canResumeUpload
    );
  }, [editableState.isEditable, isFileUploading, props.canResumeUpload]);

  if (
    (isAttachmentUploadInProgress || isFileUploading) &&
    !isFileUploadAborted
  ) {
    return (
      <AttachmentUploadControlDiv>
        <TooltipTop title={isResumeButtonDisabled ? "" : TooltipMsg.Resume}>
          <StyledIconButton
            $colorTheme="green"
            size="medium"
            padding={2}
            disabled={isResumeButtonDisabled}
            onClick={props.onStart}
          >
            <PlayCircleIcon fontSize="inherit" />
          </StyledIconButton>
        </TooltipTop>
        <TooltipTop
          title={isPauseButtonDisabled ? "" : TooltipMsg.Pause}
          disableInteractive={isPauseButtonDisabled}
        >
          <StyledIconButton
            $colorTheme="yellow"
            size="medium"
            padding={2}
            disabled={isPauseButtonDisabled}
            onClick={props.onPause}
          >
            <PauseCircleIcon fontSize="inherit" />
          </StyledIconButton>
        </TooltipTop>
        <TooltipTop
          title={!editableState.isEditable ? "" : TooltipMsg.Stop}
          disableInteractive={!editableState.isEditable}
        >
          <StyledIconButton
            $colorTheme="red"
            size="medium"
            padding={2}
            disabled={!editableState.isEditable}
            onClick={props.onStop}
          >
            <StopCircleIcon fontSize="inherit" />
          </StyledIconButton>
        </TooltipTop>
        <LinearProgressWithLabel value={props.progress} />
      </AttachmentUploadControlDiv>
    );
  }

  return <></>;
}
