import styled from "styled-components";

interface DocumentsListHeaderCellsDivProps {
  width?: number;
}

export const DocumentsListHeaderCellsDiv = styled.div<DocumentsListHeaderCellsDivProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 44px;
  min-height: 44px;
  font-size: ${(props) => props.theme.typography.fontSizes.large}px;
  font-weight: 700;
  align-self: baseline;
  width: ${(props) => (props.width ? props.width + "px" : "100%")};
  color: ${(props) => props.theme.palettes.grayscale.dark};
  background-color: ${(props) => props.theme.palettes.grayscale.lightest};
`;
