import { Autocomplete } from "@mui/material";
import styled, { DefaultTheme } from "styled-components";

interface StyledAutoCompleteProps {
  $isEditable: boolean;
  $isDisabled?: boolean;
  $paddingLeft?: number;
  $isEdited?: boolean;
  $isWarning?: boolean;
  $isError?: boolean;
}

function getBorderColor(
  isEditable: boolean,
  theme: DefaultTheme,
  isWarning?: boolean,
  isError?: boolean
) {
  if (isError) {
    return theme.palettes.secondary.error;
  }
  if (isWarning) {
    return theme.palettes.secondary.warning;
  }
  if (isEditable) {
    return theme.palettes.grayscale.medium;
  }

  return theme.palettes.grayscale.white;
}

export const StyledAutoComplete = styled(Autocomplete)<StyledAutoCompleteProps>`
  &.MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 6px 40px 6px 6px;
    padding-left: ${(props) =>
      props.$paddingLeft ? props.$paddingLeft + "px" : "6px"};
  }

  &.MuiAutocomplete-root .MuiFormControl-root .MuiInputLabel-root {
    color: ${(props) => props.theme.palettes.grayscale.dark};
  }

  & .MuiOutlinedInput-root.Mui-disabled {
    color: ${(props) => props.theme.palettes.grayscale.dark};
    fieldset {
      border: none;
    }
  }

  & .MuiOutlinedInput-input.Mui-disabled {
    color: ${(props) => props.theme.palettes.grayscale.darkest};
    -webkit-text-fill-color: unset;
  }

  &.MuiFormControl-root {
    padding-top: 4px;
  }

  & .MuiOutlinedInput-root {
    fieldset {
      border-color: ${(props) =>
        getBorderColor(
          props.$isEditable,
          props.theme,
          props.$isWarning,
          props.$isError
        )};
    }

    &.Mui-focused fieldset {
      border-color: ${(props) => props.theme.palettes.grayscale.darkest};
    }
  }

  &.MuiFormControl-root .MuiInputLabel-root {
    padding-top: 4px;
  }
`;
