import styled from "styled-components";

interface ExtensionDivProps {
  $maxWidth: number;
}

export const ExtensionDiv = styled.div<ExtensionDivProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  max-width: ${(props) => props.$maxWidth}px;
`;
