import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { TooltipTop } from "../../Tooltips/ToolipTop";
import { TooltipMsg } from "../../Tooltips/TooltipMessages";

export function CalendarIcon() {
  return (
    <TooltipTop title={TooltipMsg.OpenCalendar}>
      <CalendarMonthIcon fontSize="inherit" />
    </TooltipTop>
  );
}
