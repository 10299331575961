import styled from "styled-components";
import LockPersonIcon from "@mui/icons-material/LockPerson";

export const StyledLockIcon = styled(LockPersonIcon)`
  &.MuiSvgIcon-root {
    font-size: ${(props) => props.theme.typography.fontSizes.medium}px;
    color: ${(props) => props.theme.palettes.grayscale.medium};
    padding-right: 4px;
  }
`;
