import styled from "styled-components";

export const ScreenSplashImgSpan = styled.span`
  padding-left: 10px;
  font-size: ${(props) => props.theme.typography.fontSizes.headerT1}px;
  font-weight: 800;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;
