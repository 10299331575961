import { useGetAppSettingsQuery } from "../../../../../../apis/configApi";
import { InformationControl } from "../../../../../../controls/InformationControl/InformationControl";
import { getRequestAccessInfo } from "../../../../requestAccess/RequestAccessPopup";

export function NoResults() {
  const { data: appSettings } = useGetAppSettingsQuery(null);

  return (
    <InformationControl
      id={"no-publishers-found"}
      title="No results to display"
      description="This is a category that cannot have publishing access assigned. Please select lower level category to display list of publishers."
      secondDescription={getRequestAccessInfo(
        appSettings?.publishersAccessRequestLink ?? "",
        appSettings?.abbProductsTreeLink ?? "",
        true
      )}
      showDash={false}
      padding="0px 10% 0 10%"
    />
  );
}
