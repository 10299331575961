import { Avatar } from "@mui/material";
import styled from "styled-components";

export interface StyledAvatarProps {
  added?: boolean;
  removed?: boolean;
}

export const StyledAvatar = styled(Avatar)<StyledAvatarProps>`
  &.MuiAvatar-root {
    width: 24px;
    height: 24px;
    background-color: ${(props) =>
      props.added
        ? props.theme.palettes.secondary.info
        : props.removed
          ? props.theme.palettes.primary.main
          : props.theme.palettes.grayscale.medium};
  }
`;
