import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { ContextMenuStepProps } from "./models";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

export function GoToLatestStep(props: ContextMenuStepProps) {
    const theme = useTheme();

    return (
        <StepContentDiv>
            <Paragraph>
                {!props.isVisible && <><ArrowRightIcon style={{
                    fontSize: "16px",
                    verticalAlign: "middle",
                    color: theme.palettes.grayscale.dark
                }} /><b> GO TO LATEST REVISION</b> - </>}
                This action is available on non-latest revision. Non-latest revision does not allow to edit all fields. These fields can be edited on latest revision of document. This action allows to quickly reach latest revision of document.
            </Paragraph>
        </StepContentDiv>
    );
}
