import { StyledSkeleton } from "./SC/StyledSkeleton";

interface CategoryTreeSkeletonProps {
  numberOfSkeletons: number;
  increasingIndent: boolean;
}

function CategoryTreeSkeleton(props: CategoryTreeSkeletonProps) {
  return (
    <div id="category-tree-skeleton-loading">
      {Array.from({ length: props.numberOfSkeletons }, (_, i) => (
        <StyledSkeleton
          key={`skeleton ${i}`}
          width="auto"
          variant="text"
          $marginLeft={
            props.increasingIndent
              ? i % 7 === 0
                ? 17
                : (i - 1) % 7 === 0
                ? 34
                : 51
              : 17
          }
          $marginRight={17}
        />
      ))}
    </div>
  );
}

export default CategoryTreeSkeleton;
