import { SectionAccordionDetails } from "./SC/SectionAccordionDetails";
import { SectionContentDiv } from "./SC/SectionContentDiv";

interface SectionContentProps {
  children?: React.ReactNode;
  onClick?: () => void;
}

export function SectionContent(props: SectionContentProps) {
  return (
    <SectionAccordionDetails>
      <SectionContentDiv>{props.children}</SectionContentDiv>
    </SectionAccordionDetails>
  );
}
