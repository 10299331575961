import styled from "styled-components";

interface WorkflowDivProps {
  $isGray: boolean;
}
function getOpacity(isGray: boolean) {
  if (isGray) {
    return "30%";
  }

  return "100%";
}

export const WorkflowDiv = styled.div<WorkflowDivProps>`
  display: flex;
  width: 20px;
  opacity: ${(props) => getOpacity(props.$isGray)};
`;
