import { Tab } from "@mui/material";
import { useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import { selectCanExport } from "../../../app/slices/publishersListSlice";
import {
  selectPublishersTabType,
  setPublishersTabType,
  setSelectedPublisher,
} from "../../../app/slices/publishersViewSlice";
import { CloseButton } from "../../cms/Config/buttons/CloseButton/CloseButton";
import { ExportEmailsButton } from "../buttons/ExportEmailsButton";
import { PublishersTabType } from "../Publishers";
import { RequestAccessButton } from "../requestAccess/RequestAccessButton";
import { HeaderDiv } from "./SC/HeaderDiv";
import { HeaderEndAdornmentDiv } from "./SC/HeaderEndAdornmentDiv";
import { HeaderTabs } from "./SC/HeaderTabs";
import { SectionName } from "../../../app/slices/models/documentDetailsModels";
import { SelfHelpButtonControl } from "../../../controls/Buttons/SelfHelpButtonControl";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../models/selfHelp/selfHelpSection";
import { SelfHelpButtonControlSpan } from "./SC/SelfHelpButtonControlSpan";

export function Tabs() {
  const dispatch = useAppDispatch();
  const tabsRef = useRef<HTMLDivElement>(null);
  const tabIndex = useAppSelector(selectPublishersTabType);
  const canExport = useAppSelector(selectCanExport);

  const changeTab = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(setPublishersTabType(newValue as PublishersTabType));
  };

  return (
    <HeaderDiv id="publishers-header-tab-div">
      <HeaderTabs
        ref={tabsRef}
        value={tabIndex}
        onChange={changeTab}
        aria-label="wrapped label tabs example"
        TabIndicatorProps={{ style: { background: "secondary.main" } }}
        textColor="secondary"
      >
        <Tab
          id="category-tab-button"
          label="Category"
          value={PublishersTabType.Category}
          onClick={() => dispatch(setSelectedPublisher(undefined))}
        />
        <Tab
          id="publisher-tab-button"
          label="Publisher"
          value={PublishersTabType.Publisher}
        />
      </HeaderTabs>
      <HeaderEndAdornmentDiv id="publishers-header-tab-end-adornment-div">
        {canExport && tabIndex === PublishersTabType.Category && (
          <ExportEmailsButton />
        )}
        <RequestAccessButton />
        <CloseButton />
        <SelfHelpButtonControlSpan>
          <SelfHelpButtonControl
            currentSelfHelp={CurrentSelfHelp.PublishersMenu}
            page={SelfHelpPage.Publishers}
            section={SectionName.PublishersMenu}
          />
        </SelfHelpButtonControlSpan>
      </HeaderEndAdornmentDiv>
    </HeaderDiv>
  );
}
