import { useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../app/hooks/hooks";
import {
  selectVisibleCategorySearch,
  setCategorySearch,
  setSelectedCategoryNodes,
  setVisibleCategorySearch,
} from "../../../../../../app/slices/documentMetadataSlice";
import { clearPublisherCategories } from "../../../../../../app/slices/publisherCategoryTreeSlice";
import { TextFieldInput } from "../../../../../../controls/TextField/TextFieldInput";
import { CategorySearchDiv } from "./SC/CategorySearchDiv";

interface CategorySearchProps {
  minQueryLength: number;
  autoFocus?: boolean;
}

export function CategorySearch({
  minQueryLength,
  autoFocus,
}: CategorySearchProps) {
  const dispatch = useAppDispatch();
  const visibleCategorySearch = useAppSelector(selectVisibleCategorySearch);

  const onCategorySearch = useCallback(
    (search: string) => {
      const trimmedSearch =
        search.length === 0
          ? search
          : search
              .split(" ")
              .filter((x) => x.length !== 0)
              .map((x) => x.trim().toLocaleLowerCase())
              .join(" ");

      if (
        visibleCategorySearch !== search &&
        (trimmedSearch.length >= minQueryLength || search.length === 0)
      ) {
        dispatch(setVisibleCategorySearch(search));
        dispatch(clearPublisherCategories());
        dispatch(setSelectedCategoryNodes([]));
        dispatch(setCategorySearch(trimmedSearch));
      }
    },
    [visibleCategorySearch, minQueryLength, dispatch]
  );

  return (
    <CategorySearchDiv id="category-search-input-div">
      <TextFieldInput
        id="category-search-textfield"
        label=""
        placeholder="Type category name/CID and press enter to search (min. 3 chars)"
        onClick={onCategorySearch}
        initialValue={visibleCategorySearch}
        trimEntries={false}
        autoFocus={autoFocus}
      />
    </CategorySearchDiv>
  );
}
