import React from "react";
import { StyledTextButton } from "./SC/StyledTextButton";
import { TextButtonContentDiv } from "./SC/TextButtonContentDiv";
import { TooltipTop } from "../Tooltips/ToolipTop";
import { DisabledButtonDiv } from "./SC/DisabledButtonDiv";

interface TextButtonControlProps {
  id: string;
  tooltipText?: string;
  disabledTooltipText?: string;
  text: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  colors: "primary" | "secondary";
  icon?: React.ReactNode;
  disabled?: boolean;
  variant?: "text" | "outlined" | "contained";
  isVertical: boolean;
  isVisible: boolean;
  isHighlight?: boolean;
  removeGap?: boolean;
  isCompactView: boolean;
  height: number;
  endIcon?: React.ReactNode;
  isTextVisible?: boolean;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
}

export function TextButtonControl(props: TextButtonControlProps) {
  const getButtonContent = () => {
    const content = (
      <TextButtonContentDiv
        isHighlight={props.isHighlight}
        isVertical={props.isVertical}
        removeGap={props.removeGap}
      >
        {props.icon ? props.icon : null}
        {!props.isCompactView && <div className="text">{props.text}</div>}
      </TextButtonContentDiv>
    );

    if (props.disabledTooltipText && props.disabled) {
      return (
        <TooltipTop disableFocusListener title={props.disabledTooltipText}>
          {content}
        </TooltipTop>
      );
    }

    if (props.tooltipText && !props.disabled) {
      return (
        <TooltipTop disableFocusListener title={props.tooltipText}>
          {content}
        </TooltipTop>
      );
    }

    return <DisabledButtonDiv>{content}</DisabledButtonDiv>;
  };

  return (
    <StyledTextButton
      height={props.height}
      disabled={props.disabled}
      colors={props.colors}
      variant={props.variant ?? "text"}
      size="medium"
      id={props.id}
      onClick={props.disabled ? undefined : props.onClick}
      $isVisible={props.isVisible}
      $isCompactView={props.isCompactView}
      endIcon={props.endIcon}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseLeave}
      $isTextVisible={props.isTextVisible}
    >
      {getButtonContent()}
    </StyledTextButton>
  );
}
