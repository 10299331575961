import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function ActionStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        There are several actions that can be scheduled for a document:
      </Paragraph>
      <Paragraph>
        Archive - This action archives a document and sends a confirmation email
        on action date.
      </Paragraph>

      <Paragraph>
        Delete - This action deletes a document and sends a confirmation email
        on action date.
      </Paragraph>

      <Paragraph>
        Send Email - This action sends an email scheduled on action date. In
        Email Note section action to be performed can be specified.{" "}
      </Paragraph>
    </StepContentDiv>
  );
}
