import { MenuItem } from "@mui/material";
import styled from "styled-components";

interface StyledMenuItemProps {
  $width?: number;
}

export const StyledMenuItem = styled(MenuItem) <StyledMenuItemProps>`
  &:hover {
    color: ${(props) => props.theme.palettes.primary.main};
    cursor: pointer;
    width: ${(props) => (props.$width ? props.$width + "px" : "unset")};
  }
`;
