import styled, { DefaultTheme } from "styled-components";

interface DialogButtonProps {
  color: "primary" | "secondary";
  disabledCursor?: "not-allowed" | "pointer" | "default";
}

function getBackgroundColor(
  color: "primary" | "secondary",
  theme: DefaultTheme
) {
  if (color === "primary") {
    return theme.palettes.primary.main;
  }

  return theme.palettes.grayscale.lightest;
}

function getColor(color: "primary" | "secondary", theme: DefaultTheme) {
  if (color === "primary") {
    return theme.palettes.grayscale.lightest;
  }

  return theme.palettes.grayscale.darkest;
}

export const DialogButton = styled.button<DialogButtonProps>`
  font-family: "ABBLibrary - ABBvoice";
  background-color: ${(props) => getBackgroundColor(props.color, props.theme)};
  border: ${(props) =>
    "2px solid " + getBackgroundColor(props.color, props.theme)};
  color: ${(props) => getColor(props.color, props.theme)};
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;

  &:hover {
    border: 2px solid #ff000f;
  }

  &:disabled {
    cursor: ${(props) =>
      props.disabledCursor ? props.disabledCursor : "not-allowed"};
    background-color: ${(props) =>
      props.theme.palettes.grayscale.disabledBackground};
    color: ${(props) => props.theme.palettes.grayscale.disabledColor};
    border-color: ${(props) =>
      props.theme.palettes.grayscale.disabledBackground};
    background-clip: padding-box;

    div {
      margin: -10px;
      padding: 10px;
    }
  }
`;
