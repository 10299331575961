import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function NumberOfDaysStep2() {
  return (
    <StepContentDiv>
      <Paragraph>
        In this field, the number of days after which the document will be permanently deleted can be defined. This period is counted from the date when the document&apos;s status was changed to “Deleted”.
      </Paragraph>
    </StepContentDiv>
  );
}
