import { Accordion, AccordionProps } from "@mui/material";
import styled from "styled-components";

export const SectionAccordion = styled((props: AccordionProps) => (
  <Accordion {...props} />
))`
  &.MuiAccordion-root {
    padding: 4px 0;
    box-shadow: 0 0 2px 1px
        ${(props) => props.theme.palettes.grayscale.boxShadow},
      2px 2px 2px 1px
        ${(props) => props.theme.palettes.grayscale.boxShadowDarker};
  }
`;
