import { Box } from "@mui/material";
import styled from "styled-components";

export const CategoryTreeBox = styled(Box)`
  display: flex;
  order: 0;
  overflow: auto;
  scrollbar-gutter: stable;
  flex-direction: column;
  min-height: 120px;
  background-color: ${(props) => props.theme.palettes.grayscale.white};
  flex-grow: 1;
  padding-right: 4px;
  margin-right: 4px;
  flex-wrap: nowrap;
  white-space: nowrap;

  &.wide-scrollbar {
    margin-right: 1px;
  }
`;
