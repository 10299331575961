import { useCallback, useEffect, useState } from "react";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { useDocumentFlow } from "../document/useDocumentFlow";
import { useUserPermissionsInDocument } from "./useUserPermissionsInDocument";
import { initialEditableState } from "./models/state";

export function useUserCanEditDocument() {
  const [canEditDocumentNumber, setCanEditDocumentNumber] =
    useState(initialEditableState);
  const [canEditPart, setCanEditPart] = useState(initialEditableState);
  const [canEditLanguage, setCanEditLanguage] = useState(initialEditableState);
  const [canEditIdentitySection, setCanEditIdentitySection] =
    useState(initialEditableState);
  const [canEditRevision, setCanEditRevision] = useState(initialEditableState);

  const { hasPermissionToEditDocument, hasPermissionToEditSpecialFields } =
    useUserPermissionsInDocument(false);
  const {
    isNewLanguageVersion,
    isNewPartVersion,
    isNewDocumentRevision,
    isNewDocumentRevisionDraft,
    isLatestRevision,
    isNewDocumentView,
    isDeletedDocumentView,
  } = useDocumentFlow();

  const canEditDocument = useCallback(
    (shouldBeLatestRevision: boolean) => {
      if (isDeletedDocumentView)
        return { isEditable: false, lockMessage: TooltipMsg.DeletedDocument };
      if (isNewDocumentView) return { isEditable: true };

      if (shouldBeLatestRevision && !isLatestRevision) {
        return {
          isEditable: false,
          lockMessage: TooltipMsg.NotLatestRevision,
        };
      }

      return hasPermissionToEditDocument;
    },
    [
      hasPermissionToEditDocument,
      isLatestRevision,
      isNewDocumentView,
      isDeletedDocumentView,
    ]
  );

  const canEditSpecialFieldsInDocument = useCallback(
    (shouldBeLatestRevision: boolean) => {
      if (isDeletedDocumentView)
        return { isEditable: false, lockMessage: TooltipMsg.DeletedDocument };
      if (isNewDocumentView) return { isEditable: true };

      if (shouldBeLatestRevision && !isLatestRevision) {
        return {
          isEditable: false,
          lockMessage: TooltipMsg.NotLatestRevision,
        };
      }

      return hasPermissionToEditSpecialFields;
    },
    [
      hasPermissionToEditSpecialFields,
      isLatestRevision,
      isNewDocumentView,
      isDeletedDocumentView,
    ]
  );

  useEffect(() => {
    switch (true) {
      case isDeletedDocumentView:
        setCanEditDocumentNumber({
          isEditable: false,
          lockMessage: TooltipMsg.DeletedDocument,
        });
        setCanEditLanguage({
          isEditable: false,
          lockMessage: TooltipMsg.DeletedDocument,
        });
        setCanEditPart({
          isEditable: false,
          lockMessage: TooltipMsg.DeletedDocument,
        });
        setCanEditRevision({
          isEditable: false,
          lockMessage: TooltipMsg.DeletedDocument,
        });
        setCanEditIdentitySection({
          isEditable: false,
          lockMessage: TooltipMsg.DeletedDocument,
        });
        break;
      case isNewPartVersion:
        setCanEditDocumentNumber({
          isEditable: false,
          lockMessage: TooltipMsg.NewPartRevision,
        });
        setCanEditLanguage({
          isEditable: false,
          lockMessage: TooltipMsg.NewPartRevision,
        });
        setCanEditPart({ isEditable: true });
        setCanEditRevision({ isEditable: true });
        setCanEditIdentitySection({ isEditable: true });
        break;
      case isNewLanguageVersion:
        setCanEditDocumentNumber({
          isEditable: false,
          lockMessage: TooltipMsg.NewLanguageRevision,
        });
        setCanEditPart({ isEditable: true });
        setCanEditLanguage({ isEditable: true });
        setCanEditRevision({ isEditable: true });
        setCanEditIdentitySection({ isEditable: true });
        break;
      case isNewDocumentRevision:
      case isNewDocumentRevisionDraft:
        setCanEditDocumentNumber({
          isEditable: false,
          lockMessage: TooltipMsg.NewRevision,
        });
        setCanEditPart({
          isEditable: false,
          lockMessage: TooltipMsg.NewRevision,
        });
        setCanEditLanguage({
          isEditable: false,
          lockMessage: TooltipMsg.NewRevision,
        });
        setCanEditRevision({ isEditable: true });
        setCanEditIdentitySection({ isEditable: true });
        break;
      default:
        setCanEditIdentitySection(canEditSpecialFieldsInDocument(true));
        setCanEditDocumentNumber(canEditSpecialFieldsInDocument(true));
        setCanEditPart(canEditSpecialFieldsInDocument(true));
        setCanEditLanguage(canEditSpecialFieldsInDocument(true));
        setCanEditRevision(canEditSpecialFieldsInDocument(false));
        break;
    }
  }, [
    isNewPartVersion,
    isNewLanguageVersion,
    isNewDocumentRevision,
    isNewDocumentRevisionDraft,
    isDeletedDocumentView,
    canEditSpecialFieldsInDocument,
  ]);

  return {
    canEditDocument,
    canEditDocumentNumber,
    canEditPart,
    canEditLanguage,
    canEditRevision,
    canEditIdentitySection,
  };
}
