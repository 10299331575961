import { map, find, filter } from "lodash";

export interface ComboBoxListItem {
  key: string;
  value: string;
  isWarning?: boolean;
  isDisabled?: boolean;
  isEdited?: boolean;
  hasMissingValueWarning?: boolean;
  hasDataQualityWarning?: boolean;
}

export function filterDictionary(
  keyValuePairs: ComboBoxListItem[] | undefined,
  keys: string[]
): ComboBoxListItem[] {
  if (keyValuePairs) {
    return filter(keyValuePairs, (kvp: ComboBoxListItem) => {
      if (
        find(keys, (key) => {
          return key === kvp.key;
        })
      ) {
        return true;
      }

      return false;
    });
  }

  return [];
}

export function getKeys(keyValuePairs: ComboBoxListItem[]): string[] {
  const result: string[] = map(keyValuePairs, (pair) => {
    return pair.key;
  });

  return result;
}

export function getValues(keyValuePairs: ComboBoxListItem[]): string[] {
  const result: string[] = map(keyValuePairs, (pair) => {
    return pair.value;
  });

  return result;
}

export function isInCollection(
  keyValuePairs: ComboBoxListItem[],
  key: string
): boolean {
  if (find(keyValuePairs, { key: key })) {
    return true;
  }

  return false;
}

export function isItemWithDataQualityWarning(
  keyValuePairs: ComboBoxListItem[],
  key: string
): boolean {
  const keyValuePair = find(keyValuePairs, { key: key });

  if (keyValuePair) {
    return keyValuePair.hasDataQualityWarning
      ? keyValuePair.hasDataQualityWarning
      : false;
  }

  return false;
}
