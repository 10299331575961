import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { ContextMenuStepProps } from "./models";
import SaveAsIcon from "@mui/icons-material/SaveAs";

export function SaveAsDraftStep(props: ContextMenuStepProps) {
    const theme = useTheme();

    return (
        <StepContentDiv>
            <Paragraph>
                {!props.isVisible && <><SaveAsIcon style={{
                    fontSize: "16px",
                    verticalAlign: "middle",
                    color: theme.palettes.grayscale.dark
                }} /><b> SAVE AS DRAFT</b> - </>}
                This action saves the document as a draft. A document in this state is not visible in end user interfaces.
            </Paragraph>
        </StepContentDiv>
    );
}
