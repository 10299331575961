import { ErrorDescriptionDiv } from "../../components/errors/SC/ErrorDescriptionDiv";
import { ErrorDisplayWrapper } from "./wrappers/ErrorDisplayWrapper";

export interface ErrorDisplayProps {
  errorId: string;
  errorMessageTitle?: string;
  errorCode?: number;
  errorContainerProps?: ErrorContainerProps;
  errorDescription?: string;
  errorBoxPadding?: string;
  showDash?: boolean;
  showReloadButton?: boolean;
  doCenterHeader?: boolean;
  children?: React.ReactNode;
  textAlign?: "left" | "center";
  refreshFunction?: () => void;
}

export interface ErrorContainerProps {
  padding?: string;
  position?: string;
  transform?: string;
  top?: string;
  left?: string;
}

export const ErrorDisplay = ({
  errorMessageTitle,
  errorDescription,
  errorContainerProps,
  errorBoxPadding,
  showReloadButton = false,
  showDash = true,
  doCenterHeader = false,
  refreshFunction,
  errorId,
  children,
  textAlign,
}: ErrorDisplayProps) => {
  return (
    <ErrorDisplayWrapper
      errorId={errorId}
      errorBoxPadding={errorBoxPadding}
      errorContainerProps={errorContainerProps}
      showReloadButton={showReloadButton}
      showDash={showDash}
      doCenterHeader={doCenterHeader}
      errorMessageTitle={errorMessageTitle}
      refreshFunction={refreshFunction}
    >
      {children ?? (
        <ErrorDescriptionDiv $textAlign={textAlign}>
          {errorDescription}
        </ErrorDescriptionDiv>
      )}
    </ErrorDisplayWrapper>
  );
};
