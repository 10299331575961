import { useState } from "react";
import { TreeItemLi } from "./SC/TreeItemDiv";
import { TreeItemNamePartSpan } from "./SC/TreeItemNamePartSpan";
import { TreeItemSpan } from "./SC/TreeItemSpan";
import { TreeItemIconDiv } from "./SC/TreeitemIconDiv";
import { SelectedCategory } from "../../../app/slices/documentMetadataSlice";
import { StyledWarningIcon } from "./SC/StyledWarningIcon";
import { TooltipTop } from "../../Tooltips/ToolipTop";
import { StyledRemoveIcon } from "../../../components/cms/Config/navigation/treeItem/SC/StyledRemoveIcon";
import { StyledAddIcon } from "../../../components/cms/Config/navigation/treeItem/SC/StyledAddIcon";
import { TooltipMsg } from "../../Tooltips/TooltipMessages";

export interface NodeNamePart {
  part: string;
  isHighlighted: boolean;
}

interface TreeItemProps {
  nodeId: string;
  isHighLevelNode: boolean;
  nodeNameParts: NodeNamePart[];
  selectable: boolean;
  expanded?: boolean;
  highlighted: boolean;
  treeDepth: number;
  expandable: boolean;
  defaultPadding: number;
  onExpand: (nodeId: string) => void;
  onCollapse: (nodeId: string) => void;
  onSelect: (nodeId: SelectedCategory) => void;
  onDeselect: (nodeId: SelectedCategory) => void;
}

export function TreeItem(props: TreeItemProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(
    props.expanded ? true : false
  );
  const [isSelected, setIsSelected] = useState(false);

  const onExpand = () => {
    setIsExpanded(true);
    props.onExpand(props.nodeId);
  };

  const onCollapse = () => {
    setIsExpanded(false);
    props.onCollapse(props.nodeId);
  };

  const onItemClick = () => {
    if (props.selectable) {
      if (isSelected) {
        setIsSelected(false);
        props.onDeselect({
          categoryId: props.nodeId,
          categoryName: props.nodeNameParts[props.nodeNameParts.length - 1].part,
          isHighLevelCategory: props.isHighLevelNode,
          hasChildren: props.expandable,
        });
      } else {
        setIsSelected(true);
        props.onSelect({
          categoryId: props.nodeId,
          categoryName: props.nodeNameParts[props.nodeNameParts.length - 1].part,
          isHighLevelCategory: props.isHighLevelNode,
          hasChildren: props.expandable,
        });
      }
    } else if (props.expandable) {
      if (isExpanded) {
        onCollapse();
      } else {
        onExpand();
      }
    }
  };

  return (
    <TreeItemLi
      data-category-id={props.nodeId}
      selectable={props.selectable}
      selected={isSelected}
      paddingLeft={props.defaultPadding * props.treeDepth}
    >
      <TreeItemIconDiv>
        {props.expandable &&
          (isExpanded ? (
            <StyledRemoveIcon fontSize="inherit" onClick={onCollapse} />
          ) : (
            <StyledAddIcon fontSize="inherit" onClick={onExpand} />
          ))}
      </TreeItemIconDiv>

      <TreeItemSpan selectable={props.selectable} onClick={onItemClick}>
        {props.nodeNameParts.map((part) => (
          <TreeItemNamePartSpan
            key={part.part}
            highlighted={part.isHighlighted}
          >
            {part.part}
          </TreeItemNamePartSpan>
        ))}
      </TreeItemSpan>
      {isSelected && props.isHighLevelNode && props.expandable && (
        <TooltipTop title={TooltipMsg.CategoryItemHighLevelCategory}>
          <StyledWarningIcon fontSize="inherit" />
        </TooltipTop>
      )}
    </TreeItemLi>
  );
}
