import { TooltipTop } from "../../../../../controls/Tooltips/ToolipTop";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";

interface ExpandCollapseControlProps {
  isExpanded: boolean;
  section: string;
}

export function ExpandCollapseControl(props: ExpandCollapseControlProps) {
  if (props.isExpanded) {
    return (
      <TooltipTop title={TooltipMsg.Collapse}>
        <ExpandMoreIcon id={props.section + "-expand"} fontSize="small" />
      </TooltipTop>
    );
  }

  return (
    <TooltipTop title={TooltipMsg.Expand} >
      <ExpandMoreIcon id={props.section + "-collapse"} fontSize="small" />
    </TooltipTop>
  );
}
