import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/identity/dc_multiple_languages.png";

export function LanguageSecondStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        In the Download Section, users will see English documents by default, as well as documents in their preferred language.
      </Paragraph>
      <Paragraph>
        In the Download Center, all languages of a document are displayed together under a single document number on the document details page. Users have the ability to filter documents based on any language.
      </Paragraph>
      <Paragraph>
        <Screenshot src={screenshot} />
      </Paragraph>
    </StepContentDiv>
  );
}
