import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { Screenshot } from "../../../SC/Screenshot";
import screenshot_dc from "../../../screenshots/description/dc_selected_summary.png";

export function SummarySecondStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                and on the Download Center&apos;s results list and document details pages.
            </Paragraph>
            <Paragraph>
                <Screenshot src={screenshot_dc} />
            </Paragraph>
        </StepContentDiv>
    );
}
