import {
  selectNewDocumentVersionIdentity,
  setDocumentDetailView,
  setNewDocumentVersionIdentity,
} from "../../app/slices/documentDetailsSlice";
import { ContentDiv } from "./SC/ContentDiv";
import { DocumentDetailView } from "../../models/documentDetailView";
import { Details } from "../details/Details";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { setDocumentIsNew } from "../../app/slices/documentMetadataSlice";

export function NewDocumentRevision() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  dispatch(setDocumentDetailView(DocumentDetailView.NewRevision));

  const newDocumentVersionIdentity = useAppSelector(
    selectNewDocumentVersionIdentity
  );

  useEffect(() => {
    return () => {
      dispatch(setDocumentIsNew(false));
      dispatch(setNewDocumentVersionIdentity(undefined));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!newDocumentVersionIdentity) {
      navigate("/404");
    }
  }, [navigate, newDocumentVersionIdentity]);

  return (
    <ContentDiv id="content-div">
      <Details />
    </ContentDiv>
  );
}
