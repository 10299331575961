import styled from "styled-components";

export const PaperContentCenterDiv = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 1;
  height: 100%;
  width: 100%;
`;
