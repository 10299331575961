import { TooltipTop } from "../../Tooltips/ToolipTop";
import { TooltipMsg } from "../../Tooltips/TooltipMessages";
import { StyledRightButtonIcon } from "../SC/StyledRightButtonIcon";

export function RightDatePickerIcon() {
  return (
    <TooltipTop title={TooltipMsg.NextMonth}>
      <StyledRightButtonIcon />
    </TooltipTop>
  );
}
