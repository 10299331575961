import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import {
  selectDetailsColumnsFlex,
  selectIsResize,
  selectMaximizedSection,
  selectPaneSection,
  setDetailsColumnFlex,
  setIsResize,
} from "../../../app/slices/documentDetailsSlice";
import { DetailsColumn } from "../../../app/slices/models/documentDetailsModels";
import {
  HandlerProps,
  ReflexContainer,
  ReflexSplitter,
} from "../../../controls/Reflex";
import PaneElement from "./PaneElement";
import { DetailsTabReflexElement } from "./SC/DetailsTabReflexElement";
import { concat } from "lodash";
import { selectIsWindowTooSmall } from "../../../app/slices/commonSlice";

const allPanesVisible = [
  DetailsColumn.Left,
  DetailsColumn.Middle,
  DetailsColumn.Right,
];

const defaultColumnMinWidth = 440;

export function DetailsTab() {
  const maximizedSection = useAppSelector(selectMaximizedSection);
  const leftPaneSections = useAppSelector((state) =>
    selectPaneSection(state, DetailsColumn.Left)
  );
  const middlePaneSections = useAppSelector((state) =>
    selectPaneSection(state, DetailsColumn.Middle)
  );
  const rightPaneSections = useAppSelector((state) =>
    selectPaneSection(state, DetailsColumn.Right)
  );
  const columnsFlex = useAppSelector(selectDetailsColumnsFlex);
  const [visiblePanes, setVisiblePane] = useState(allPanesVisible);
  const [columnMinWidth, setColumnMinWidth] = useState(defaultColumnMinWidth);
  const dispatch = useAppDispatch();
  const isWindowTooSmall = useAppSelector(selectIsWindowTooSmall);
  const isResizing = useAppSelector(selectIsResize);

  useEffect(() => {
    if (maximizedSection) {
      setColumnMinWidth(0);
      setVisiblePane([maximizedSection.column]);
    } else {
      setColumnMinWidth(defaultColumnMinWidth);
      setVisiblePane([...allPanesVisible]);
    }
  }, [maximizedSection]);

  const getColumnIndexByName = (column: DetailsColumn) => {
    switch (column) {
      case DetailsColumn.Left:
        return 0;
      case DetailsColumn.Middle:
        return 1;
      case DetailsColumn.Right:
        return 2;
      default:
        throw new Error("Provided unsupported column type");
    }
  };

  const startResizeHandler = () => {
    dispatch(setIsResize(true));
  };

  const stopResizeHandler = (args: HandlerProps) => {
    dispatch(setIsResize(false));
    if (
      args.component.props.flex !== undefined &&
      args.component.props.name !== undefined
    ) {
      const index = getColumnIndexByName(
        args.component.props.name as DetailsColumn
      );
      dispatch(
        setDetailsColumnFlex({
          index: index,
          flex: args.component.props.flex,
        })
      );
    }
  };

  const isVisible = (column: DetailsColumn) => visiblePanes.includes(column);

  const getColumnFlex = (column: DetailsColumn) => {
    if (!isVisible(column)) {
      return 0;
    }

    if (isVisible(column) && columnMinWidth === 0) {
      return 1;
    }

    return columnsFlex[getColumnIndexByName(column)];
  };

  if (!isWindowTooSmall) {
    return (
      <ReflexContainer orientation="vertical" windowResizeAware={true}>
        <DetailsTabReflexElement
          className="details-left-pane"
          flex={getColumnFlex(DetailsColumn.Left)}
          isHidden={!isVisible(DetailsColumn.Left)}
          minSize={columnMinWidth}
          onStartResize={startResizeHandler}
          onStopResize={stopResizeHandler}
          name={DetailsColumn.Left}
          isResizing={isResizing}
        >
          <PaneElement
            paneElementClassName="details-pane-content"
            paneElementId="details-left-section-container"
            columnType={DetailsColumn.Left}
            sections={leftPaneSections}
          />
        </DetailsTabReflexElement>
        {!maximizedSection && <ReflexSplitter />}
        <DetailsTabReflexElement
          className="details-middle-pane"
          flex={getColumnFlex(DetailsColumn.Middle)}
          isHidden={!isVisible(DetailsColumn.Middle)}
          minSize={columnMinWidth}
          onStartResize={startResizeHandler}
          onStopResize={stopResizeHandler}
          name={DetailsColumn.Middle}
          isResizing={isResizing}
        >
          <PaneElement
            paneElementClassName="details-pane-content"
            paneElementId="details-middle-section-container"
            columnType={DetailsColumn.Middle}
            sections={middlePaneSections}
          />
        </DetailsTabReflexElement>
        {!maximizedSection && <ReflexSplitter />}
        <DetailsTabReflexElement
          className="details-right-pane"
          flex={getColumnFlex(DetailsColumn.Right)}
          isHidden={!isVisible(DetailsColumn.Right)}
          minSize={columnMinWidth}
          onStartResize={startResizeHandler}
          onStopResize={stopResizeHandler}
          name={DetailsColumn.Right}
          isResizing={isResizing}
        >
          <PaneElement
            paneElementClassName="details-pane-content"
            paneElementId="details-right-section-container"
            columnType={DetailsColumn.Right}
            sections={rightPaneSections}
          />
        </DetailsTabReflexElement>
      </ReflexContainer>
    );
  }

  return (
    <ReflexContainer orientation="vertical" windowResizeAware={true}>
      <DetailsTabReflexElement
        className="details-one-pane"
        flex={getColumnFlex(DetailsColumn.Right)}
        isHidden={!isVisible(DetailsColumn.Right)}
        minSize={columnMinWidth}
        onStartResize={startResizeHandler}
        onStopResize={stopResizeHandler}
        name={DetailsColumn.Right}
        isResizing={isResizing}
      >
        <PaneElement
          paneElementClassName="details-pane-content"
          paneElementId="details-one-pane-section-container"
          columnType={DetailsColumn.Middle}
          sections={concat(
            leftPaneSections,
            middlePaneSections,
            rightPaneSections
          )}
        />
      </DetailsTabReflexElement>
    </ReflexContainer>
  );
}
