import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function BreakLinkStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                The Break Link button modifies the URL that Google uses, compelling it to search for a new functional link.
            </Paragraph>
            <Paragraph>
                This process occurs automatically when documents are altered, but there may be instances where it is necessary to break the link more frequently.
            </Paragraph>
            <Paragraph>
                All document revisions are impacted by this action, and the duration of the process depends on the number of revisions and the size of the file. As the process occurs in the background, there is no confirmation, and it will continue even if the browser is closed.
            </Paragraph>
        </StepContentDiv >
    );
}
