import { NameSpan } from "./SC/NameSpan";
import { TextFieldDiv } from "../SC/TextFieldDiv";
import { NumberTextField } from "../SC/NumberTextField";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import {
  selectCurrentPermanentlyDeletedInDays,
  selectSettingsPermanentlyDeletedIsEdited,
  setCurrentPermanentlyDeletedInDays,
  setPermanentlyDeletedInDaysState,
} from "../../../../../app/slices/publisherCategoriesSettingsSlice";
import { DeleteAttachmentDiv } from "./SC/DeleteAttachmentDiv";
import { useEffect, useState } from "react";
import { ValidationStatus } from "../../../../../models/validationRule";

interface DeleteAttachmentProps {
  disabled?: boolean;
}

export function DeleteAttachment(props: DeleteAttachmentProps) {
  const dispatch = useAppDispatch();
  const currentPermanentlyDeletedInDays = useAppSelector(
    selectCurrentPermanentlyDeletedInDays
  );
  const isEdited = useAppSelector(selectSettingsPermanentlyDeletedIsEdited);
  const [value, setValue] = useState<number>(90);

  const deleteAttachmentChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newDays = event.target.value as unknown as number;
    setValue(newDays);

    if (newDays > 0) {
      dispatch(setCurrentPermanentlyDeletedInDays(newDays));
      dispatch(setPermanentlyDeletedInDaysState(ValidationStatus.success));
    } else dispatch(setPermanentlyDeletedInDaysState(ValidationStatus.error));
  };

  const error = value <= 0;
  const errorMessage = error ? "Error" : "";

  useEffect(() => {
    setValue(currentPermanentlyDeletedInDays);
  }, [currentPermanentlyDeletedInDays]);

  return (
    <DeleteAttachmentDiv id="cms-delete-attachment-div">
      <TextFieldDiv>
        <NumberTextField
          error={error}
          label={errorMessage}
          id="outlined-number"
          type="number"
          size="small"
          onChange={deleteAttachmentChangeHandler}
          value={value}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={props.disabled}
          $isEdited={isEdited}
        />
      </TextFieldDiv>
      <NameSpan>
        Number of days after which documents are permanently deleted
      </NameSpan>
    </DeleteAttachmentDiv>
  );
}
