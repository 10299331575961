import IconButton from "@mui/material/IconButton";
import styled from "styled-components";

const StyledIconButton = styled(IconButton)`
  margin: 0;
  padding: 0;
  font-size: 15;
`;

export default StyledIconButton;
