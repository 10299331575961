import styled from "styled-components";

export const UserInfoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  width: 240px;
  max-width: 240px;
  min-width: 240px;
  margin-left: 12px;
`;
