export {};

declare global {
  interface Array<T> {
    first(predicate: (item: T) => boolean): T;
  }
}

Array.prototype.first = function first<T>(predicate: (item: T) => boolean): T {
  const result = this.find(predicate) as T;
  if (result !== undefined) {
    return result;
  }

  throw new Error("Array does not contain any item matching predicate.");
};
