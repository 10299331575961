import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

export function ExpandCollapseStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The{" "}
        <UnfoldMoreIcon
          style={{
            fontSize: "16px",
            verticalAlign: "middle",
          }}
        />{" "}
        &quot;Expand Groups&quot; button expands or collapses sections of
        documents grouped by document number.
      </Paragraph>
      <Paragraph>By default, the sections are collapsed.</Paragraph>
      <Paragraph>
        It&apos;s important to note that expanding all groups may take a long
        time, depending on the number of documents to be displayed.
      </Paragraph>
    </StepContentDiv>
  );
}
