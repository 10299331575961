import { PopperProps } from "@mui/material/Popper";
import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetAllUsersWithPublisherRoleQuery } from "../../../apis/publishersApi";
import StyledAutoCompleteErrorMessage from "../../../app/errorHandling/autoComplete/StyledAutoCompleteErrorMessage";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import {
  selectUserEmail,
  selectUserName,
} from "../../../app/slices/authenticationSlice";
import {
  selectSelectedPublisher,
  setSelectedPublisher,
} from "../../../app/slices/publishersViewSlice";
import { defaultTheme } from "../../../app/theme";
import { getPopperWidth } from "../../../controls/Autocomplete/AutoCompleteInput";
import { StyledAutoCompletePopper } from "../../../controls/Autocomplete/SC/StyledAutoCompletePopper";
import { TextFieldControl } from "../../../controls/Autocomplete/TextFieldControl";
import { ListboxComponent } from "./ReactWindowAdapter";
import { AutocompleteInputDiv } from "./SC/AutocompleteInputDiv";
import { StyledAutoComplete } from "./SC/StyledAutocomplete";

export interface PublisherValue {
  email: string;
  label: string;
  name: string;
}

export const moveMeToFirstPlace = (
  publishers: PublisherValue[],
  stringToMove?: string
) => {
  if (!stringToMove) {
    return publishers;
  }

  let copy = [...publishers];
  const itemToMove = publishers.find((x) => x.email === stringToMove);

  if (itemToMove) {
    const newItem = {
      ...itemToMove,
      label: `Me (${itemToMove.email})`,
    };
    copy = copy.filter((x) => x.email !== itemToMove.email);
    copy.unshift(newItem);
  }

  return copy;
};

export default function PublishersSearch() {
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLDivElement>(null);
  const userEmail = useAppSelector(selectUserEmail);
  const userName = useAppSelector(selectUserName);
  const { data, refetch, isError, isLoading } =
    useGetAllUsersWithPublisherRoleQuery(null);
  const selectedPerson = useAppSelector(selectSelectedPublisher);
  const [value, setValue] = useState<PublisherValue>({
    email: userEmail,
    label: `Me (${userEmail})`,
    name: userName,
  });

  useEffect(() => {
    if (selectedPerson?.email && selectedPerson.name) {
      setValue({
        email: selectedPerson.email,
        label:
          selectedPerson.email === userEmail
            ? `Me (${userEmail})`
            : selectedPerson.email,
        name: selectedPerson.name,
      });
    }
  }, [selectedPerson?.email, selectedPerson?.name, userEmail]);

  const publishers: PublisherValue[] = useMemo(() => {
    if (data) {
      return moveMeToFirstPlace(
        data.map((x) => ({
          email: x.email,
          label: x.email,
          name: x.name,
        })),
        userEmail
      );
    }

    return [];
  }, [data, userEmail]);

  const customPopper = useCallback(
    (popperProps: PopperProps) => {
      const anchorEl = inputRef.current;

      return isError ? (
        <StyledAutoCompleteErrorMessage
          popperProps={popperProps}
          refreshFunction={() => void refetch()}
          calculatedWidth={getPopperWidth(anchorEl?.clientWidth)}
          anchorEl={anchorEl}
        />
      ) : (
        <StyledAutoCompletePopper
          {...popperProps}
          $fitUl={true}
          anchorEl={anchorEl}
          $calculatedWidth={getPopperWidth(anchorEl?.clientWidth)}
        />
      );
    },
    [isError, refetch]
  );

  return (
    <AutocompleteInputDiv>
      <StyledAutoComplete
        ref={inputRef}
        id="publishers-autocomplete"
        size="small"
        disableListWrap
        multiple={false}
        loading={isLoading}
        $isError={isError}
        PopperComponent={customPopper}
        ListboxComponent={ListboxComponent}
        options={publishers}
        value={value}
        renderInput={(params) => (
          <TextFieldControl
            params={params}
            label={"Publishers"}
            isLoading={isLoading}
          />
        )}
        onChange={(event: React.SyntheticEvent, value: unknown) => {
          const publisher = value as PublisherValue | undefined;
          if (publisher) {
            setValue(publisher);
            dispatch(
              setSelectedPublisher({
                email: publisher.email,
                name: publisher.name,
              })
            );
          } else {
            const foundUser = publishers.find((x) => x.email === userEmail);

            if (foundUser) {
              setValue(foundUser);
              dispatch(
                setSelectedPublisher({
                  email: userEmail,
                  name: userName,
                })
              );
            }
          }
        }}
        ListboxProps={{
          style: {
            maxHeight: "300px",
            backgroundColor: defaultTheme.palettes.grayscale.lightest,
          },
        }}
        renderOption={(props, option, state) =>
          [props, option, state.index] as React.ReactNode
        }
        getOptionLabel={(value) => (value as PublisherValue).label}
        disableClearable
        selectOnFocus={true}
        isOptionEqualToValue={(option, value) => {
          return (
            (option as PublisherValue).label === (value as PublisherValue).label
          );
        }}
      />
    </AutocompleteInputDiv>
  );
}
