import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The Filters section enables the narrowing down of the document list by
        utilizing advanced filtering options.
      </Paragraph>
      <Paragraph>
        It can be resized by dragging and dropping, both in conjunction with the
        Navigation section and the Document List section.
      </Paragraph>
    </StepContentDiv>
  );
}
