export enum ScreenSplashEnum {
  None,
  WindowTooSmall,
}

export interface SplashScreenContent {
  type: ScreenSplashEnum;
  title: string;
  message: string;
}

export const SplashScreensContents = [
  {
    type: ScreenSplashEnum.None,
    title: "",
    message: "",
  },
  {
    type: ScreenSplashEnum.WindowTooSmall,
    title:
      "Your browser window is too small to display the application. Please make it bigger.",
    message: "",
  },
];
