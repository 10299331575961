import { useCallback } from "react";
import { useDocumentFlow } from "../../app/hooks/document/useDocumentFlow";
import { useAppDispatch } from "../../app/hooks/hooks";
import DocumentMetadata from "../../models/documentDetails/documentMetadata";
import { Workflow } from "../../models/documentList/types/workflow";
import { setDocumentStatus } from "../../app/slices/documentMetadataSlice";
import {
  DocumentStatus,
  DraftType,
} from "../../models/documentDetails/documentStatus";

export function useCreateNewDocument() {
  const {
    isNewLanguageVersion,
    isNewPartVersion,
    isNewFromTemplate,
    isNewDocumentVersion,
    isNewDocumentRevision,
  } = useDocumentFlow();
  const dispatch = useAppDispatch();

  const getNewLanguageDetailView = useCallback((metadata: DocumentMetadata) => {
    const engTitle = metadata.titles.first((t) => t.languageIsoCode === "en");

    const newMetadata: DocumentMetadata = {
      ...metadata,
      documentIdentity: {
        documentPart: "",
        revision: "A",
        documentLanguageCodes: [],
        documentNumber: metadata.documentIdentity.documentNumber,
      },
      workflow: Workflow.NewDocument,
      titles: [engTitle],
      attachment: { attachmentId: null, uploadId: null, name: "", size: 0 },
      summary: metadata.summary,
    };

    return newMetadata;
  }, []);

  const getNewFromTemplateDetailView = useCallback(
    (metadata: DocumentMetadata) => {
      const categories = metadata.categories.map((c) => {
        return { ...c, isFromServiceIntegration: false };
      });

      const newMetadata: DocumentMetadata = {
        ...metadata,
        documentIdentity: {
          documentPart: "",
          revision: "A",
          documentLanguageCodes:
            metadata.documentIdentity.documentLanguageCodes,
          documentNumber: "",
        },
        workflow: Workflow.NewDocument,
        attachment: { attachmentId: null, uploadId: null, name: "", size: 0 },
        summary: metadata.summary,
        categories: categories,
      };

      return newMetadata;
    },
    []
  );

  const getNewPartDetailView = useCallback((metadata: DocumentMetadata) => {
    const engTitle = metadata.titles.first((t) => t.languageIsoCode === "en");

    const newMetadata: DocumentMetadata = {
      ...metadata,
      documentIdentity: {
        documentPart: "",
        revision: "A",
        documentLanguageCodes: metadata.documentIdentity.documentLanguageCodes,
        documentNumber: metadata.documentIdentity.documentNumber,
      },
      workflow: Workflow.NewDocument,
      titles: [
        ...metadata.documentIdentity.documentLanguageCodes
          .filter((dl) => dl != "en")
          .map((dl) => ({
            title: "",
            languageIsoCode: dl,
          })),
        { title: engTitle.title, languageIsoCode: "en" },
      ],
      attachment: { attachmentId: null, uploadId: null, name: "", size: 0 },
      summary: metadata.summary,
    };

    return newMetadata;
  }, []);

  const getNewDocumentRevisionDetailView = useCallback(
    (metadata: DocumentMetadata) => {
      const newMetadata = {
        ...metadata,
        workflow: Workflow.NewRevision,
        attachment: { attachmentId: null, uploadId: null, name: "", size: 0 },
      };

      return newMetadata;
    },
    []
  );

  const addMissingTitles = useCallback((metadata: DocumentMetadata) => {
    const newMetadata = { ...metadata };
    const identityIsoCodes = newMetadata.documentIdentity.documentLanguageCodes;
    const missingTitleIsoCodes = identityIsoCodes.filter(
      (isoCode) =>
        newMetadata.titles.find(
          (title) => title.languageIsoCode === isoCode
        ) === undefined
    );

    newMetadata.titles = [
      ...newMetadata.titles,
      ...missingTitleIsoCodes.map((isoCode) => ({
        title: "",
        languageIsoCode: isoCode,
      })),
    ];

    return newMetadata;
  }, []);

  const prepareDocumentMetadata = useCallback(
    (metadata: DocumentMetadata) => {
      if (isNewPartVersion) {
        return getNewPartDetailView(metadata);
      }
      if (isNewLanguageVersion) {
        return getNewLanguageDetailView(metadata);
      }
      if (isNewFromTemplate) {
        return getNewFromTemplateDetailView(metadata);
      }
      if (isNewDocumentRevision) {
        return getNewDocumentRevisionDetailView(metadata);
      }

      return addMissingTitles(metadata);
    },
    [
      getNewLanguageDetailView,
      getNewPartDetailView,
      getNewFromTemplateDetailView,
      getNewDocumentRevisionDetailView,
      addMissingTitles,
      isNewLanguageVersion,
      isNewPartVersion,
      isNewFromTemplate,
      isNewDocumentRevision,
    ]
  );

  const prepareDocumentStatus = useCallback(
    (documentStatus: DocumentStatus) => {
      if (isNewDocumentVersion) {
        dispatch(
          setDocumentStatus({
            isLatest: true,
            documentRevisionId: undefined,
            isFromServiceIntegration: false,
            draftType: DraftType.NewDocumentDraft,
            publisher: documentStatus.publisher,
            hasPendingApproval: false,
            isLaterRevision: false,
            canApprovePendingApproval: false,
            hasOtherRevisionWithPendingApproval: false,
            latestRevision: documentStatus.latestRevision,
            canChangeToPublish: documentStatus.canChangeToPublish,
            isApprovalCategory: documentStatus.isApprovalCategory,
          })
        );
      } else if (isNewDocumentRevision) {
        dispatch(
          setDocumentStatus({
            ...documentStatus,
            draftType: DraftType.NewDocumentRevision,
            documentRevisionId: undefined,
          })
        );
      } else {
        if (documentStatus.isLaterRevision) {
          documentStatus = {
            ...documentStatus,
            draftType: DraftType.NewDocumentRevision,
          };
        }
        dispatch(setDocumentStatus(documentStatus));
      }
    },
    [dispatch, isNewDocumentVersion, isNewDocumentRevision]
  );

  return {
    prepareDocumentMetadata,
    prepareDocumentStatus,
  };
}
