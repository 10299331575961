import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import {
  expandTagCategoryBreadcrumb,
  selectExpandedTagCategoriesBreadcrumbsIds,
} from "../../../../app/slices/tagsViewSlice";
import { TagCategory } from "../../../../models/tags/tagCategory";
import { BreadcrumbEllipsisButton } from "./BreadcrumbEllipsisButton";
import { BreadcrumbSeparator } from "./BreadcrumbSeparator";
import { BreadcrumbList } from "./SC/BreadcrumbList";
import { BreadcrumbListItem } from "./SC/BreadcrumbListItem";

interface BreadcrumbProps {
  tagId: string;
  categoryId: string;
  separator: string;
  categories: TagCategory[];
}

export function Breadcrumb(props: BreadcrumbProps) {
  const dispatch = useAppDispatch();
  const firstItem = useMemo(() => props.categories[0], [props.categories]);
  const lastItem = useMemo(
    () => props.categories[props.categories.length - 1],
    [props.categories]
  );
  const expandedCategories = useAppSelector((state) =>
    selectExpandedTagCategoriesBreadcrumbsIds(state, props.tagId)
  );

  const isExpanded = expandedCategories.includes(props.categoryId);

  const handleExpandClick = () => {
    dispatch(
      expandTagCategoryBreadcrumb({
        tagId: props.tagId,
        categoryId: props.categoryId,
      })
    );
  };

  if (props.categories.length > 3 && !isExpanded) {
    return (
      <BreadcrumbList>
        <BreadcrumbListItem
          data-cid={firstItem.categoryId}
          $isBolded={false}
          $isGray={false}
        >
          {firstItem.categoryName}
        </BreadcrumbListItem>

        <BreadcrumbSeparator $isVisible={true} separator={props.separator} />

        <BreadcrumbListItem $isBolded={false} $isGray={false}>
          <BreadcrumbEllipsisButton onClick={handleExpandClick} />
        </BreadcrumbListItem>

        <BreadcrumbSeparator $isVisible={true} separator={props.separator} />

        <BreadcrumbListItem
          data-cid={lastItem.categoryId}
          $isBolded={true}
          $isGray={false}
        >
          {lastItem.categoryName}
        </BreadcrumbListItem>
      </BreadcrumbList>
    );
  } else {
    return (
      <BreadcrumbList>
        {props.categories.map((category, index) => {
          const lastCategoryIndex = props.categories.length - 1;
          return [
            <BreadcrumbListItem
              key={index}
              data-cid={category.categoryId}
              $isBolded={lastCategoryIndex == index}
              $isGray={false}
            >
              {category.categoryName}
            </BreadcrumbListItem>,
            <BreadcrumbSeparator
              $isVisible={lastCategoryIndex != index}
              key={index + "separator"}
              separator={props.separator}
            />,
          ];
        })}
      </BreadcrumbList>
    );
  }
}
