import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot1 from "../../../screenshots/title/dc_german_title.png";

export function TitleSecondStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                In the Download Center and Download Section, the title is displayed in the user&apos;s language if available.
            </Paragraph>
            <Paragraph>
                If the user&apos;s language is not available, the English title is displayed instead.
            </Paragraph>
            <Paragraph>
                <Screenshot src={screenshot1} />
            </Paragraph>
        </StepContentDiv>
    );
}
