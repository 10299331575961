import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { OpenInFull } from "@mui/icons-material";

export function SectionMaximizeStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        Section can be fully maximized by clicking the maximize icon <OpenInFull fontSize="inherit" /> on the section header.
        It is only displayed when the mouse is hovered over it.
      </Paragraph>
    </StepContentDiv>
  );
}
