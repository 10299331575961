import { Button } from "@mui/material";
import styled from "styled-components";

export const ShowMoreErrorButton = styled(Button)`
  &.MuiButtonBase-root {
    font-size: ${(props) => props.theme.typography.fontSizes.medium}px;
    color: ${(props) => props.theme.palettes.primary.dark};
  }
  &.MuiButtonBase-root:hover {
    color: ${(props) => props.theme.palettes.primary.main};
    background-color: ${(props) => props.theme.palettes.grayscale.lightest};
  }
`;
