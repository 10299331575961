import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { ButtonDiv } from "../SC/ButtonDiv";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { clearEditedDocumentDetails } from "../../../../app/slices/documentMetadataSlice";
import { DialogControl } from "../../../../controls/Dialog/DialogControl";
import CancelIcon from "@mui/icons-material/Cancel";
import { TextButtonControl } from "../../../../controls/Buttons/TextButtonControl";
import {
  clearAttachmentInfo,
  setShouldAbortFileUpload,
} from "../../../../app/slices/fileUploadSlice";
import { selectIsUpdatingDocument } from "../../../../app/slices/documentDetailsSlice";
import {
  addVisibleButton,
  ActionButton,
  removeVisibleButton,
} from "../../../../app/slices/selfHelpSlice";
import { StyledDialogContentText } from "../../selfHelpPopup/SC/StyledDialogContentText";
import { useDocumentFlow } from "../../../../app/hooks/document/useDocumentFlow";
import { setIsDraftValidationOn } from "../../../../app/slices/documentMetadataValidationSlice";

interface DiscardChangesButtonProps {
  disabled?: boolean;
}

export function DiscardChangesButton(props: DiscardChangesButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isUpdatingDocument = useAppSelector(selectIsUpdatingDocument);
  const { isNewDocumentRevisionDraft } = useDocumentFlow();

  const onDiscardAllChanges = () => {
    dispatch(clearAttachmentInfo(undefined));
    dispatch(setShouldAbortFileUpload(true));
    dispatch(setIsDraftValidationOn(false));
    dispatch(clearEditedDocumentDetails(isNewDocumentRevisionDraft));
  };

  useEffect(() => {
    dispatch(addVisibleButton(ActionButton.DiscardChanges));

    return () => {
      dispatch(removeVisibleButton(ActionButton.DiscardChanges));
    };
  }, [dispatch]);

  return (
    <ButtonDiv>
      <DialogControl
        id="discard-changes-button"
        title="Discard all changes"
        onConfirmClick={onDiscardAllChanges}
        onCloseClick={() => setIsOpen(false)}
        maxWidth="sm"
        useActions={true}
        isOpen={isOpen}
        content={
          <StyledDialogContentText id="alert-dialog-description">
            This will discard all changes made. Do you want to proceed?
          </StyledDialogContentText>
        }
      />
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.primaryButtonHeight}
        id={"document-details-discard-changes-button"}
        text={"Discard changes"}
        disabled={props.disabled || isUpdatingDocument}
        onClick={(event) => {
          setIsOpen(true);
          event.stopPropagation();
        }}
        isCompactView={false}
        isVertical={false}
        isVisible={true}
        icon={<CancelIcon fontSize="small" />}
      />
    </ButtonDiv>
  );
}
