import styled from "styled-components";

interface StyledCategoryItemLiProps {
  $isSelected: boolean;
  $isGray: boolean;
  $paddingLeft: number;
}

export const StyledCategoryItemLi = styled.li<StyledCategoryItemLiProps>`
  color: ${(props) =>
    props.$isGray
      ? props.theme.palettes.grayscale.medium
      : props.theme.palettes.grayscale.darkest};
  font-size: ${(props) => props.theme.typography.fontSizes.large}px;
  font-weight: ${(props) => (props.$isGray ? "unset" : "bold")};
  background-color: ${(props) =>
    props.$isSelected ? props.theme.palettes.grayscale.light : "unset"};
  border-left: ${(props) =>
    props.$isSelected
      ? "2px solid " + props.theme.palettes.primary.main
      : "2px solid transparent"};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${(props) => props.$paddingLeft}px;
  list-style: none;

  :hover {
    background-color: ${(props) => props.theme.palettes.grayscale.lighter};
  }
`;
