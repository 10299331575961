import styled, { css } from "styled-components";
import {
  cmaDocumentIcon,
  linkedDocumentIcon,
  noAccessToDocumentIcon,
  serviceDocumentIcon,
} from "../../../../../../icons/DocumentInfoIcons";
import EventNoteIcon from "@mui/icons-material/EventNote";

const iconStyles = css`
  &.MuiSvgIcon-root {
    font-size: ${(props) => props.theme.typography.fontSizes.large}px;
    color: ${(props) => props.theme.palettes.grayscale.medium};
    padding-right: 2px;
  }
`;

export const StyledLinkIcon = styled(linkedDocumentIcon)`
  ${iconStyles}
`;

export const StyledLockIcon = styled(noAccessToDocumentIcon)`
  ${iconStyles}
`;

export const StyledPublicIcon = styled(cmaDocumentIcon)`
  ${iconStyles}
`;

export const StyledElectricalServicesIcon = styled(serviceDocumentIcon)`
  ${iconStyles}
`;

export const StyledEventNoteIcon = styled(EventNoteIcon)`
  ${iconStyles}
`;
