import {
  GridToolbarContainer,
  GridToolbarContainerProps,
} from "@mui/x-data-grid";
import styled from "styled-components";


export const StyledGridToolbarContainer = styled(
  GridToolbarContainer
) <GridToolbarContainerProps>`
  &.MuiDataGrid-toolbarContainer {
    padding-right: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      color: ${(props) => props.theme.palettes.grayscale.darkest};

      &:hover {
        background-color: ${(props) => props.theme.palettes.grayscale.lighter};
      }
    }

    .MuiBadge-badge {
      margin-top: 2px;
    }
  }
`;
