import { useTheme } from "styled-components";
import { useAppSelector } from "../../../../../../app/hooks/hooks";
import { rootCategoryId } from "../../../../../../app/slices/navigationSlice";
import { selectCategoryById } from "../../../../../../app/slices/publisherCategoryTreeSlice";
import { TreeViewSkeleton } from "../../../../../../controls/CategoryTree/skeleton/TreeViewSkeleton";
import { NoResourceFound } from "../../../../../documents/documentsList/noDocumentsFound/NoResourceFound";
import { CategoryTreeDiv } from "./SC/CategoryTreeDiv";
import { CategoryTreeHeaderDiv } from "./SC/CategoryTreeHeaderDiv";
import { CategoryTreeTitleSpan } from "./SC/CategoryTreeTitleSpan";
import { CategoryTreeViewDiv } from "./SC/CategoryTreeViewDiv";
import { CategoryTreeControl } from "./control/CategoryTreeControl";

interface CategoryTreeProps {
  isLoading: boolean;
  isSuccess: boolean;
  isOpen: boolean;
  abortController?: AbortController;
}

export function CategoryTree({
  isLoading,
  isSuccess,
  isOpen,
  abortController,
}: CategoryTreeProps) {
  const theme = useTheme();
  const rootCategory = useAppSelector((state) =>
    selectCategoryById(state, rootCategoryId)
  );

  let content = <></>;

  if (isLoading && isOpen) {
    content = (
      <TreeViewSkeleton
        defaultPadding={theme.shapes.defaultCategoryTreePadding}
        width={320}
        numberOfLevels={6}
      />
    );
  } else if (!rootCategory && isSuccess && isOpen) {
    content = (
      <NoResourceFound
        headerMessage="No Categories Found"
        contentMessage="Change or reset search query to get categories you want to explore..."
      />
    );
  } else if (rootCategory && isSuccess) {
    content = (
      <CategoryTreeControl
        categoryId={rootCategoryId}
        abortController={abortController}
      />
    );
  }

  return (
    <CategoryTreeDiv id="category-tree-div">
      <CategoryTreeHeaderDiv>
        <CategoryTreeTitleSpan>Select categories</CategoryTreeTitleSpan>
      </CategoryTreeHeaderDiv>
      <CategoryTreeViewDiv id="category-tree-box">
        {content}
      </CategoryTreeViewDiv>
    </CategoryTreeDiv>
  );
}
