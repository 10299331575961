
import SearchIcon from "@mui/icons-material/Search";
import { TextFieldInput } from "../../../../controls/TextField/TextFieldInput";
import { SearchTagDiv } from "./SC/SearchTagDiv";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { selectSelectedTag, setTagsValueSearch } from "../../../../app/slices/tagsViewSlice";
import { useEffect } from "react";

export function SearchTagValue() {
  const minQueryLength = 3;
  const dispatch = useAppDispatch();
  const selectedTag = useAppSelector(selectSelectedTag);

  const onTagValuesSearch = (search: string) => {
    if (search === "") {
      dispatch(setTagsValueSearch(""));
      return;
    }

    const trimmedSearch =
      search.length === 0
        ? search
        : search
          .split(" ")
          .filter((x) => x.length !== 0)
          .map((x) => x.trim().toLocaleLowerCase())
          .join(" ");

    if (trimmedSearch.length >= minQueryLength) {
      dispatch(setTagsValueSearch(search));
    }
  }


  useEffect(() => {
    if (selectedTag?.id) {
      dispatch(setTagsValueSearch(""));
    }
  }, [dispatch, selectedTag?.id])


  useEffect(() => {
    return () => {
      dispatch(setTagsValueSearch(""));
    }
  }, [dispatch])

  return (
    <SearchTagDiv id="search-input-div">
      <TextFieldInput
        id={"tag-value-search-textfield"}
        key={selectedTag?.id}
        label=""
        placeholder="Search for a Tag Value (min. 3 chars)..."
        onClick={onTagValuesSearch}
        initialValue={""}
        trimEntries={false}
        icon={<SearchIcon />}
        iconText={"Search"}
      />
    </SearchTagDiv>
  );
}
