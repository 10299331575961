import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function PaginationStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        By default, 20 documents per page are displayed. However, this can be
        changed to 10, 20, or 30 documents per page.
      </Paragraph>
      <Paragraph>
        User preferences for the number of documents per page are saved for
        future sessions.
      </Paragraph>
      <Paragraph>
        The page number is shown and can be modified by either typing in the
        desired number or clicking on the arrows.
      </Paragraph>
      <Paragraph>
        On the right side, the total number of results and the range of
        currently displayed documents are shown.
      </Paragraph>
    </StepContentDiv>
  );
}
