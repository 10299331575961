import { Skeleton } from "@mui/material";
import styled from "styled-components";

interface StyledSkeletonProps {
  $marginLeft: number;
  $marginRight: number;
}

export const StyledSkeleton = styled(Skeleton)<StyledSkeletonProps>`
  &.MuiSkeleton-root {
    margin: 2px ${(props) => props.$marginRight}px 2px
      ${(props) => props.$marginLeft}px;
    background-color: ${(props) => props.theme.palettes.grayscale.lighter};
  }
`;
