import { useAppSelector } from "../../../../../../app/hooks/hooks";
import { selectUserEmail } from "../../../../../../app/slices/authenticationSlice";
import { TooltipMsg } from "../../../../../../controls/Tooltips/TooltipMessages";
import { MultiText } from "../../../../inputs/MultiText";
import { StyledCreatedByField } from "../SC/StyledCreatedByField";

interface CreatedByFieldProps {
  userName: string | undefined;
  isEditable: boolean;
}

export function CreatedByField(props: CreatedByFieldProps) {
  const userEmail = useAppSelector(selectUserEmail);

  return (
    <StyledCreatedByField>
      <MultiText
        id="scheduled-action-created-by-dropdown"
        labelText="Created by"
        inputValue={props.userName ?? `Me (${userEmail})`}
        isEditable={false}
        isEdited={false}
        notEditableMessage={
          !props.isEditable ? TooltipMsg.NoAccessToDocument : undefined
        }
      />
    </StyledCreatedByField>
  );
}
