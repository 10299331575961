import styled from "styled-components";
import WarningIcon from "@mui/icons-material/Warning";

interface StyledWarningIconProps {
  $color: string;
}

export const StyledWarningIcon = styled(WarningIcon)<StyledWarningIconProps>`
  color: ${(props) => props.$color};
  cursor: default;
  padding-right: 13px;
`;
