import RefreshIcon from "@mui/icons-material/Refresh";
import { ReactNode } from "react";
import { ButtonDiv } from "./SC/ButtonDiv";
import { DashDiv } from "./SC/DashDiv";
import { InfoBoxDiv } from "./SC/InfoBoxDiv";
import { InfoContainerDiv } from "./SC/InfoContainerDiv";
import { InfoHeaderDiv } from "./SC/InfoHeaderDiv";
import InfoMessage from "./SC/InfoMessage";
import StyledButton from "./SC/StyledButton";

export interface ErrorDisplayProps {
  id: string;
  title?: string;
  description?: string;
  secondDescription?: ReactNode;
  showDash?: boolean;
  showReloadButton?: boolean;
  doCenterHeader?: boolean;
  refreshFunction?: () => void;
  padding?: string;
}

export function InformationControl(props: ErrorDisplayProps) {
  return (
    <InfoContainerDiv id={props.id + "-error-container"}>
      <InfoBoxDiv
        id={props.id + "-error-box"}
        padding={props.padding ?? "80px 16% 0 16%"}
      >
        <InfoHeaderDiv $centerTextAlign={props.doCenterHeader}>
          {props.title}
        </InfoHeaderDiv>
        <DashDiv showDash={props.showDash} />
        <InfoMessage id={props.id + "-error-message"}>
          {props.description}
        </InfoMessage>
        {props.secondDescription && (
          <InfoMessage id={props.id + "-paragraph"} className="paragraph">
            {props.secondDescription}
          </InfoMessage>
        )}

        {props.showReloadButton && (
          <ButtonDiv>
            <StyledButton
              id={"refresh-button-id"}
              variant="contained"
              startIcon={<RefreshIcon fontSize="small" />}
              color="error"
              onClick={props.refreshFunction}
            >
              Reload
            </StyledButton>
          </ButtonDiv>
        )}
      </InfoBoxDiv>
    </InfoContainerDiv>
  );
}
