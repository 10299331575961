import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { openSnackbar } from "../../../../../app/helpers/snackBarHelper";
import { useAppDispatch } from "../../../../../app/hooks/hooks";
import { ErrorApi } from "../../../../../models/errorApi";
import { ErrorApiType } from "../../../../../models/errorApiType";
import { SnackbarType } from "../../../../../models/snackbar";
import { ErrorApiPopup } from "../popups/error/ErrorApiPopup";
import { useDocumentDetailsDocumentKinds } from "../../../../../app/hooks/document/useDocumentDetailsDocumentKinds";
import { documentKindsApi } from "../../../../../apis/documentKindsApi";
import { useDocumentDetailsAccessGroups } from "../../../../../app/hooks/document/useDocumentDetailsAccessGroups";
import { accessGroupsApi } from "../../../../../apis/accessGroupsApi";
import { SnackbarMsg } from "../../../../../controls/Snackbar/SnackbarMessages";

interface PublishErrorHandlerProps {
  errors: ErrorApi[];
}

function PublishErrorHandler(props: PublishErrorHandlerProps) {
  const dispatch = useAppDispatch();
  const [isErrorApiPopupOpen, setIsErrorApiPopupOpen] = useState(false);
  const [reasons, setReasons] = useState<ErrorApi[]>([]);

  const isInResponse = useCallback(
    (errorType: ErrorApiType) =>
      _.filter(props.errors, ["code", errorType]).length > 0,
    [props.errors]
  );

  const identityDuplicateError = useCallback(
    () => isInResponse(ErrorApiType.IdentityDuplicate),
    [isInResponse]
  );
  const accessGroupError = useCallback(
    () => isInResponse(ErrorApiType.AccessGroupsError),
    [isInResponse]
  );
  const documentKindError = useCallback(
    () => isInResponse(ErrorApiType.DocumentKindError),
    [isInResponse]
  );

  const { fetchDocumentKinds } = useDocumentDetailsDocumentKinds();
  const { fetchAccessGroups } = useDocumentDetailsAccessGroups();

  useEffect(() => {
    let isErrorApiPopupOpen = false;

    if (identityDuplicateError()) {
      isErrorApiPopupOpen = true;
      setReasons(
        props.errors.filter((x) => x.code === ErrorApiType.IdentityDuplicate)
      );
    }

    if (documentKindError()) {
      dispatch(documentKindsApi.util.resetApiState());
      fetchDocumentKinds(false);
    }

    if (accessGroupError()) {
      dispatch(accessGroupsApi.util.resetApiState());
      fetchAccessGroups(false);
    }

    if (
      props.errors.length > 1 ||
      (props.errors.length == 1 && !isErrorApiPopupOpen)
    ) {
      openSnackbar(
        dispatch,
        SnackbarMsg.PublishDocumentError,
        SnackbarType.error
      );
    }

    setIsErrorApiPopupOpen(isErrorApiPopupOpen);
  }, [
    props.errors,
    dispatch,
    documentKindError,
    identityDuplicateError,
    fetchDocumentKinds,
    accessGroupError,
    fetchAccessGroups,
  ]);

  return (
    <ErrorApiPopup
      isOpen={isErrorApiPopupOpen}
      onClose={() => setIsErrorApiPopupOpen(false)}
      reasons={reasons}
      id="document-publish-error-popup"
    />
  );
}

export default PublishErrorHandler;
