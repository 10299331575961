import packageJson from "../../../package.json";
import { CommonParams } from "../slices/commonSlice";
import { SplitViewParams } from "../slices/contentSlice";
import { DocumentListViewParams } from "../slices/documentListViewSlice";
import { DocumentsParams } from "../slices/models/documentsModels";
import { NavigationSplitViewParams } from "../slices/navigationSlice";
import { PublishersViewParams } from "../slices/publishersViewSlice";
import { TagsViewParams } from "../slices/tagsViewSlice";

export const documentParamsLocalStorageKey = "document_list_params";
export const splitViewParamsLocalStorageKey = "split_view_params";
export const documentListViewLocalStorageKey = "document_list_view_params";
export const publishersViewLocalStorageKey = "publishers_view_params";
export const tagsViewLocalStorageKey = "tags_view_params";
export const navigationSplitViewParamsLocalStorageKey =
  "navigation_split_view_params";
export const commonLocalStorageKey = "common_params";
const storageVersionKey = "storageVersion";
export const documentDetailsParamsLocalStorageKey = "document_details_params";
export const publishersListLocalStorageKey = "publishers_list_params";
export const publisherDirectCategoriesStorageKey =
  "publisher_direct_categories_params";

export const storageKeys = [
  documentParamsLocalStorageKey,
  splitViewParamsLocalStorageKey,
  documentListViewLocalStorageKey,
  navigationSplitViewParamsLocalStorageKey,
  commonLocalStorageKey,
  storageVersionKey,
  documentDetailsParamsLocalStorageKey,
  publishersListLocalStorageKey,
  publishersViewLocalStorageKey,
  publisherDirectCategoriesStorageKey,
];

export const storageWhitelist = ["qa_token", "qa_authenticate_as", "qa_roles"];

export function clearStorageIfUsingWrongVersion() {
  const version = packageJson.storageVersion;

  if (
    typeof localStorage.storageVersion === "undefined" ||
    localStorage.storageVersion === null
  ) {
    localStorage.setItem(storageVersionKey, version);
  }

  if (localStorage.storageVersion != version) {
    localStorage.clear();
  }
}

export function setDocumentParamsInLocalStorage(value: DocumentsParams) {
  try {
    localStorage.setItem(documentParamsLocalStorageKey, JSON.stringify(value));
  } catch (error) {
    console.log(error);
  }
}

export function setSplitViewInLocalStorage(value: SplitViewParams) {
  try {
    localStorage.setItem(splitViewParamsLocalStorageKey, JSON.stringify(value));
  } catch (error) {
    console.log(error);
  }
}

export function setDocumentListViewInLocalStorage(
  value: DocumentListViewParams
) {
  try {
    localStorage.setItem(
      documentListViewLocalStorageKey,
      JSON.stringify(value)
    );
  } catch (error) {
    console.log(error);
  }
}

export function setNavigationSplitViewInLocalStorage(
  value: NavigationSplitViewParams
) {
  try {
    localStorage.setItem(
      navigationSplitViewParamsLocalStorageKey,
      JSON.stringify(value)
    );
  } catch (error) {
    console.log(error);
  }
}

export function setPublishersViewInLocalStorage(value: PublishersViewParams) {
  try {
    localStorage.setItem(publishersViewLocalStorageKey, JSON.stringify(value));
  } catch (error) {
    console.log(error);
  }
}

export function setTagsViewInLocalStorage(value: TagsViewParams) {
  try {
    localStorage.setItem(tagsViewLocalStorageKey, JSON.stringify(value));
  } catch (error) {
    console.log(error);
  }
}

export function setCommonParamsInLocalStorage(value: CommonParams) {
  try {
    localStorage.setItem(commonLocalStorageKey, JSON.stringify(value));
  } catch (error) {
    console.log(error);
  }
}

export function setParamsInLocalStorage<T>(key: string, value: T) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.log(error);
  }
}

export function readParamsFromLocalStorage<T>(key: string) {
  try {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value) as T;
    }
  } catch {
    // ignored
  }

  return undefined;
}
