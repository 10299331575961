import styled from "styled-components";
import { Density } from "../../../../../../app/slices/models/documentsModels";

export interface LanguagePillsDivProps {
  density: Density;
}

function setHeight(density: Density) {
  if (density === Density.Compact) {
    return "16px";
  }

  if (density === Density.Standard) {
    return "32px";
  }

  return "100%";
}

export const LanguagePillsDiv = styled.div<LanguagePillsDivProps>`
  display: flex;
  width: 100%;
  height: ${(props) => setHeight(props.density)};
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  row-gap: 3px;
`;
