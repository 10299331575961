import { ListItemText } from "@mui/material";
import styled from "styled-components";
export const StyledListItemText = styled(ListItemText)`
  .MuiListItemText-primary {
    font-size: ${(props) => props.theme.typography.fontSizes.headerT2}px;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .MuiListItemText-secondary {
    font-size: ${(props) => props.theme.typography.fontSizes.headerT3}px;
  }
`;
