import { useState, useEffect, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { ComboBoxListItem } from "../../../../models/autocompleteValue";
import { CountryAvailabilityType } from "../../../../models/documentDetails/countryMarketAvailability";
import { CurrentSelfHelp } from "../../../../models/CurrentSelfHelpType";
import { DetailsAutoComplete } from "../../inputs/DetailsAutoComplete";
import { CountryMarketAvailabilityDiv } from "../../SC/CountryMarketAvailabilityDiv";
import {
  selectCountriesDictionary,
  selectCurrentDocumentCma,
  setEditedAndRealDocumentCma,
  clearEditedDocumentCma,
  setEditedDocumentCountries,
  setEditedDocumentCountryAvailabilityType,
  selectCurrentDocumentCategories,
} from "../../../../app/slices/documentMetadataSlice";
import { SectionName } from "../../../../app/slices/models/documentDetailsModels";
import { DetailsSection, DetailsSectionProps } from "../DetailsSection";
import CountryAvailabilityTypeSection from "./CountryAvailabilityTypeSection";
import { CircularProgressBar } from "../../../../controls/ProgressIndicators/CircularProgressBar";
import { includes, sortBy } from "lodash";
import { useTheme } from "styled-components";
import { getCountriesKvpFromIsoCodes } from "../../../../app/helpers/getCountriesKvp";
import {
  selectIsLoadingDetailsMetaData,
  selectShouldRefreshDocumentCma,
  setShouldRefreshDocumentCma,
} from "../../../../app/slices/documentDetailsSlice";
import { useCountries } from "../../../../apis/hooks/useCountries";
import { CmaSyncSwitch } from "./CmaSyncSwitch";
import { initialEditableState } from "../../../../app/hooks/permissions/models/state";
import { useUserCanEditDocument } from "../../../../app/hooks/permissions/useUserCanEditDocument";
import { useDocumentEditedMode } from "../../../../app/hooks/document/useDocumentEditedMode";
import CountryMarketAvailabilityValidation from "./validation/CountryMarketAvailabilityValidation";
import { selectIsCmaValidationError } from "../../../../app/slices/documentMetadataValidationSlice";

export function CMASection(props: DetailsSectionProps) {
  const dispatch = useAppDispatch();

  const [editableState, setEditableState] = useState(initialEditableState);
  const { canEditDocument } = useUserCanEditDocument();

  useEffect(() => {
    setEditableState(canEditDocument(true));
  }, [canEditDocument]);

  const isCmaValidationError = useAppSelector(selectIsCmaValidationError);
  const currentDocumentCma = useAppSelector(selectCurrentDocumentCma);
  const currentDocumentCategories = useAppSelector(
    selectCurrentDocumentCategories
  );
  const countriesDictionary = useAppSelector(selectCountriesDictionary);
  const { cmaSection } = useDocumentEditedMode();
  const shouldRefreshDocumentCma = useAppSelector(
    selectShouldRefreshDocumentCma
  );
  const isLoadingDetailsMetaData = useAppSelector(
    selectIsLoadingDetailsMetaData
  );
  const theme = useTheme();
  const [loadedSortedCountries, setLoadedSortedCountries] = useState(false);
  const [documentCountries, setDocumentCountries] = useState<
    ComboBoxListItem[]
  >([]);

  const {
    fetchAllCountries,
    fetchSyncCma,
    cmaData,
    isLoadingCountries,
    isFetchCountriesError,
  } = useCountries();

  const handleRefreshView = useCallback(() => {
    void fetchAllCountries();
  }, [fetchAllCountries]);

  useEffect(() => {
    if (
      currentDocumentCma.isSynchronizedWithCma &&
      shouldRefreshDocumentCma &&
      currentDocumentCategories.length > 0
    ) {
      void fetchSyncCma(currentDocumentCategories);
      dispatch(setShouldRefreshDocumentCma(false));
    }
  }, [
    currentDocumentCategories,
    currentDocumentCma.isSynchronizedWithCma,
    fetchSyncCma,
    shouldRefreshDocumentCma,
    dispatch,
  ]);

  useEffect(() => {
    if (cmaData && currentDocumentCma.isSynchronizedWithCma) {
      dispatch(
        setEditedDocumentCountryAvailabilityType(
          cmaData.countryAvailabilityType
        )
      );
      dispatch(
        setEditedDocumentCountries(
          cmaData.countries.map((country) => {
            return country.isoCode;
          })
        )
      );
    }
  }, [cmaData, currentDocumentCma.isSynchronizedWithCma, dispatch]);

  useEffect(() => {
    const documentCountries = currentDocumentCma.countryIsoCodes.map(
      (isoCode) => {
        const matchingCountries = countriesDictionary.find(
          (country) => country.isoCode === isoCode
        );

        return {
          key: isoCode,
          value: matchingCountries?.name ?? isoCode,
          isEdited: includes(cmaSection.editedCountries, isoCode),
        };
      }
    );

    const result = sortBy(documentCountries, ["isEdited", "value"]);

    setDocumentCountries(result);
  }, [
    countriesDictionary,
    currentDocumentCma.countryIsoCodes,
    cmaSection.editedCountries,
  ]);

  const onSelectedCountriesChange = (values: ComboBoxListItem[]) => {
    dispatch(setEditedDocumentCountries(values.map((country) => country.key)));
  };

  const onAvailabilityTypeChange = (value: CountryAvailabilityType) => {
    dispatch(setEditedDocumentCountryAvailabilityType(value));

    if (value === CountryAvailabilityType.AllCountries) {
      dispatch(setEditedDocumentCountries([]));
    }
  };

  useEffect(() => {
    const sortCountryIsoCodesByName = (): string[] => {
      if (countriesDictionary.length > 0) {
        const countries = getCountriesKvpFromIsoCodes(
          currentDocumentCma.countryIsoCodes,
          countriesDictionary
        );

        countries.sort((a, b) => (a.value > b.value ? 1 : -1));
        return countries.map((country) => country.key);
      }

      return [];
    };

    if (countriesDictionary.length > 0 && !loadedSortedCountries) {
      const sortedIsoCodes = sortCountryIsoCodesByName();
      dispatch(
        setEditedAndRealDocumentCma({
          ...currentDocumentCma,
          countryIsoCodes: sortedIsoCodes,
        })
      );
      setLoadedSortedCountries(true);
    }
  }, [
    dispatch,
    countriesDictionary,
    currentDocumentCma,
    loadedSortedCountries,
  ]);

  const discardCMASection = useCallback(() => {
    dispatch(clearEditedDocumentCma());
  }, [dispatch]);

  return (
    <DetailsSection
      {...props}
      section={SectionName.CountryMarketAvailability}
      name="Country market availability"
      selfHelpType={CurrentSelfHelp.DetailsCountryMarketAvailability}
      isEdited={cmaSection.cmaSectionIsEdited}
      isLoadingDetailsMetaData={isLoadingDetailsMetaData || isLoadingCountries}
      onDiscardChanges={discardCMASection}
      isEditable={editableState.isEditable}
      notEditableReason={editableState.lockMessage}
      isValidationError={isCmaValidationError}
    >
      <CountryMarketAvailabilityDiv>
        <CmaSyncSwitch
          isEditable={editableState.isEditable}
          notEditableMessage={editableState.lockMessage}
        />
        {isLoadingDetailsMetaData || isLoadingCountries ? (
          <CircularProgressBar
            size={theme.circularProgress.medium}
            space={theme.circularProgressWrapper.defaultHeight}
            color="secondary"
          />
        ) : (
          <>
            <CountryAvailabilityTypeSection
              countryAvailabilityType={
                currentDocumentCma.countryAvailabilityType
              }
              onCountryAvailabilityTypeChange={onAvailabilityTypeChange}
              isDisabled={currentDocumentCma.isSynchronizedWithCma}
              isEditable={editableState.isEditable}
              notEditableMessage={editableState.lockMessage}
              isEdited={cmaSection.cmaIsEdited}
            />

            <DetailsAutoComplete
              id="countries"
              pillsClassName="countries-pill"
              labelText="Countries"
              onRefreshHandler={handleRefreshView}
              isDictionaryDataFetchingError={isFetchCountriesError}
              isError={isCmaValidationError}
              selected={documentCountries}
              isHidden={
                currentDocumentCma.countryAvailabilityType ===
                CountryAvailabilityType.AllCountries
              }
              items={countriesDictionary.map((country) => {
                return {
                  key: country.isoCode,
                  value: country.name,
                };
              })}
              isEditable={
                editableState.isEditable &&
                (!currentDocumentCma.isSynchronizedWithCma ||
                  isFetchCountriesError)
              }
              isDisabled={
                !editableState.isEditable ||
                (currentDocumentCma.isSynchronizedWithCma &&
                  !isFetchCountriesError)
              }
              onChangeHandler={onSelectedCountriesChange}
              isEdited={cmaSection.countriesIsEdited}
              multiple={true}
              isLoading={isLoadingCountries}
              notEditableMessage={editableState.lockMessage}
            />
            <CountryMarketAvailabilityValidation />
          </>
        )}
      </CountryMarketAvailabilityDiv>
    </DetailsSection>
  );
}
