import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import CheckIcon from "@mui/icons-material/Check";
import { SelfHelpIcon } from "../SelfHelpIcon";

export function PublisherListStep9() {
    return (
        <StepContentDiv>
            <Paragraph>
                User needs to have at least Publisher role to be displayed on the list.
            </Paragraph>
            <Paragraph>
                Roles that user has assigned directly in selected category are marked by <SelfHelpIcon isBlack icon={<CheckIcon />} /> icon.
            </Paragraph>
        </StepContentDiv>
    );
}
