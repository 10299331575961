import { useCallback, useEffect, useMemo } from "react";
import { openSnackbar } from "../../app/helpers/snackBarHelper";
import { SnackbarType } from "../../models/snackbar";
import {
  useLazyGetAllCountriesQuery,
  useLazyGetCmaQuery,
} from "../countriesApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { setCountriesDictionary } from "../../app/slices/documentMetadataSlice";
import { selectIsLoadingDetailsMetaData } from "../../app/slices/documentDetailsSlice";
import { Category } from "../../models/documentDetails/documentMetadata";
import { SnackbarMsg } from "../../controls/Snackbar/SnackbarMessages";

export function useCountries() {
  const dispatch = useAppDispatch();
  const isLoadingDetailsMetaData = useAppSelector(
    selectIsLoadingDetailsMetaData
  );

  const [
    getLazyAllCountries,
    {
      data: responseCountriesDictionary,
      isError: isFetchAllCountriesError,
      isLoading: isLoadingAllCountries,
      isUninitialized: isAllCountriesUninitialized,
    },
  ] = useLazyGetAllCountriesQuery();

  const [
    getLazyCma,
    { data: cmaData, isError: isFetchCmaError, isFetching: isFetchingCma },
  ] = useLazyGetCmaQuery();

  const fetchAllCountries = useCallback(async () => {
    await getLazyAllCountries(null, true);
  }, [getLazyAllCountries]);

  const fetchSyncCma = useCallback(
    async (documentCategories: Category[]) => {
      await getLazyCma(documentCategories, true);
    },
    [getLazyCma]
  );

  useEffect(() => {
    if (responseCountriesDictionary) {
      dispatch(setCountriesDictionary(responseCountriesDictionary));
    }
  }, [dispatch, responseCountriesDictionary]);

  useEffect(() => {
    if (isFetchAllCountriesError || isFetchCmaError) {
      openSnackbar(dispatch, SnackbarMsg.CountriesError, SnackbarType.error);
    }
  }, [dispatch, isFetchAllCountriesError, isFetchCmaError]);

  //initial load
  useEffect(() => {
    if (!isLoadingDetailsMetaData && isAllCountriesUninitialized) {
      void fetchAllCountries();
    }
  }, [
    fetchAllCountries,
    isAllCountriesUninitialized,
    isLoadingDetailsMetaData,
  ]);

  const isFetchCountriesError = useMemo(() => {
    return isFetchAllCountriesError || isFetchCmaError;
  }, [isFetchAllCountriesError, isFetchCmaError]);

  const isLoadingCountries = useMemo(() => {
    return isLoadingAllCountries || isFetchingCma;
  }, [isFetchingCma, isLoadingAllCountries]);

  return {
    fetchAllCountries,
    fetchSyncCma,
    isLoadingCountries,
    isFetchCountriesError,
    cmaData,
  };
}
