import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";

export interface EditableState {
  isEditable: boolean;
  lockMessage?: TooltipMsg;
}

export const initialEditableState: EditableState = {
  isEditable: false,
};
