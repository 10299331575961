import { useAppSelector } from "../../app/hooks/hooks";
import { selectEnvironment } from "../../app/slices/commonSlice";
import { ActionButtons } from "./ActionButtons/ActionButtons";
import { Logo } from "./Logo/Logo";
import { HeaderDiv } from "./SC/HeaderDiv";
import { UserMenu } from "./UserMenu/UserMenu";

export function Header() {
  const environment = useAppSelector(selectEnvironment);

  return (
    <HeaderDiv id="header-div" environment={environment} >
      <Logo />
      <ActionButtons />
      <UserMenu />
    </HeaderDiv>
  );
}
