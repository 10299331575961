import { ListItemText } from "@mui/material";
import styled from "styled-components";

export const StyledListItemText = styled(ListItemText)`
  &.MuiListItemText-root {
    align-items: center;
    justify-content: center;
    cursor: default;
  }
`;
