import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccordionState } from "../../models/documentList/types/accordionState";
import { SortBy } from "../../models/documentList/types/sortBy";
import { RootState } from "../store";
import {
  defaultDensity,
  Density,
  getInitialDocumentsParams,
  initialPageNumber,
} from "./models/documentsModels";
import DocumentListItem from "../../models/documentList/documentListItem";

export interface ExpandablePart {
  isExpanded: boolean;
  documentsToLoad: number;
  shouldExpandOnExpandAll: boolean;
}

export interface ExpandableLanguage {
  isExpanded: boolean;
  shouldExpandOnExpandAll: boolean;
}

export interface DocumentListState {
  groupItemsToLoadCount: number;
  density: Density;
  pageNumber: number;
  pageSize: number;
  documentsCount: number;
  sortBy: SortBy;
  sortDescending: boolean;
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
  groupedListView: boolean;
  allGroupAccordionState: AccordionState;
  startedDocumentsFirstLoad: boolean;
  expandableDocumentGroups: Record<string, boolean | undefined>;
  expandableLanguageGroups: Record<string, ExpandableLanguage | undefined>;
  expandablePartGroups: Record<string, ExpandablePart | undefined>;
  areAllGroupsExpanded: boolean;
  changingWorkflowCounter: number;
  groupsCount: number;
  expandedGroupsCount: number;
}

const initialState: DocumentListState = {
  groupItemsToLoadCount: 0,
  documentsCount: 0,
  pageNumber: initialPageNumber,
  sortBy: SortBy.PublishDate,
  isFetching: false,
  isLoading: true,
  isError: false,
  sortDescending: true,
  groupedListView: true,
  allGroupAccordionState: AccordionState.Collapsed,
  startedDocumentsFirstLoad: false,
  expandableDocumentGroups: {},
  expandableLanguageGroups: {},
  expandablePartGroups: {},
  areAllGroupsExpanded: false,
  changingWorkflowCounter: 0,
  groupsCount: 0,
  expandedGroupsCount: 0,
  ...getInitialDocumentsParams(),
};

export const documentListSlice = createSlice({
  name: "documentList",
  initialState,
  reducers: {
    setDensity(state, action: PayloadAction<Density>) {
      state.density = action.payload;
    },
    setPageNumber(state, action: PayloadAction<number>) {
      if (action.payload <= 0) {
        state.pageNumber = initialPageNumber;
        return;
      }

      const upperBoundary = Math.ceil(state.documentsCount / state.pageSize);
      if (action.payload > upperBoundary) {
        state.pageNumber =
          upperBoundary === 0 ? initialPageNumber : upperBoundary;
        return;
      }

      state.pageNumber = action.payload;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
      state.pageNumber = initialPageNumber;
    },
    setSortBy(state, action: PayloadAction<SortBy>) {
      state.sortBy = action.payload;
      state.pageNumber = initialPageNumber;
    },
    setSortDescending(state, action: PayloadAction<SortBy>) {
      if (state.sortBy === action.payload) {
        state.sortDescending = !state.sortDescending;
      } else {
        if (action.payload === SortBy.PublishDate) {
          state.sortDescending = true;
        } else {
          state.sortDescending = false;
        }
      }
    },
    setExpandableGroupsState(state, action: PayloadAction<DocumentListItem[]>) {
      const documentNumbers = action.payload.map((x) => x.documentNumber);
      const numbersSet = new Set(documentNumbers);
      const filteredGroups: Record<string, boolean | undefined> = {};
      state.groupsCount = documentNumbers.length;
      let expandedCount = 0;

      for (const key in state.expandableDocumentGroups) {
        if (numbersSet.has(key)) {
          filteredGroups[key] = state.expandableDocumentGroups[key];

          if (state.expandableDocumentGroups[key]) {
            expandedCount++;
          }
        }
      }

      state.expandableDocumentGroups = filteredGroups;
      state.expandedGroupsCount = expandedCount;
      state.areAllGroupsExpanded = expandedCount === documentNumbers.length;
      state.allGroupAccordionState =
        expandedCount === 0
          ? AccordionState.Collapsed
          : AccordionState.Expanded;
    },
    setDocumentsCount(state, action: PayloadAction<number>) {
      state.documentsCount = action.payload;
    },
    setIsDocumentsListFetching(state, action: PayloadAction<boolean>) {
      state.isFetching = action.payload;
    },
    setIsDocumentListLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    resetPageNumber(state) {
      state.pageNumber = initialPageNumber;
    },
    resetDensity(state) {
      state.density = defaultDensity;
    },
    resetExpandedGroups(state) {
      state.allGroupAccordionState = AccordionState.Unset;
      state.expandableDocumentGroups = {};
      state.expandableLanguageGroups = {};
      state.expandablePartGroups = {};
      state.areAllGroupsExpanded = false;
      state.expandedGroupsCount = 0;
    },
    setAllGroupsToExpand(state) {
      state.areAllGroupsExpanded = true;
      state.expandedGroupsCount = state.groupsCount;
      state.allGroupAccordionState = AccordionState.Expanded;

      for (const key in state.expandableLanguageGroups) {
        state.expandableLanguageGroups[key] = {
          ...state.expandableLanguageGroups[key],
          shouldExpandOnExpandAll: true,
        } as ExpandableLanguage;
      }

      for (const key in state.expandablePartGroups) {
        state.expandablePartGroups[key] = {
          ...state.expandablePartGroups[key],
          shouldExpandOnExpandAll: true,
        } as ExpandablePart;
      }
    },
    setAllGroupsToCollapse(state) {
      state.expandableDocumentGroups = {};
      state.expandedGroupsCount = 0;
      state.areAllGroupsExpanded = false;
      state.allGroupAccordionState = AccordionState.Collapsed;
    },
    setGroupItemsToLoadCount(state, action: PayloadAction<number>) {
      state.groupItemsToLoadCount = action.payload;
    },
    incrementGroupItemsToLoadCount(state, action: PayloadAction<number>) {
      state.groupItemsToLoadCount += action.payload;
    },
    decrementGroupItemsToLoadCount(state, action: PayloadAction<number>) {
      if (state.groupItemsToLoadCount > 0) {
        state.groupItemsToLoadCount -= action.payload;
      } else {
        state.groupItemsToLoadCount = 0;
      }
    },
    setChangeListView(state) {
      state.groupedListView = !state.groupedListView;
    },
    setStartedDocumentsFirstLoad(state) {
      state.startedDocumentsFirstLoad = true;
    },
    setIsDocumentListError(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
    setDocumentGroup(
      state,
      action: PayloadAction<{ documentNumber: string; isExpanded: boolean }>
    ) {
      const { documentNumber, isExpanded } = action.payload;
      state.expandableDocumentGroups[documentNumber] = isExpanded;

      if (!isExpanded) {
        state.areAllGroupsExpanded = false;
        state.expandedGroupsCount = Math.max(state.expandedGroupsCount - 1, 0);
      } else {
        state.expandedGroupsCount = Math.min(
          state.expandedGroupsCount + 1,
          state.groupsCount
        );
      }

      state.allGroupAccordionState =
        state.expandedGroupsCount > 0
          ? AccordionState.Expanded
          : AccordionState.Collapsed;
    },
    setLanguageGroup(
      state,
      action: PayloadAction<{
        documentNumber: string;
        languageGroupId: string;
        expandedLanguage: ExpandableLanguage;
      }>
    ) {
      const { documentNumber, languageGroupId, expandedLanguage } =
        action.payload;
      if (state.expandableDocumentGroups[documentNumber]) {
        state.expandableLanguageGroups[`${documentNumber}${languageGroupId}`] =
          expandedLanguage;
      }
    },
    setPartsGroup(
      state,
      action: PayloadAction<{
        documentNumber: string;
        languageGroupId: string;
        partGroupId: string;
        expandedPart: ExpandablePart;
      }>
    ) {
      const { documentNumber, languageGroupId, partGroupId, expandedPart } =
        action.payload;
      if (
        state.expandableLanguageGroups[`${documentNumber}${languageGroupId}`]
      ) {
        state.expandablePartGroups[
          `${documentNumber}${languageGroupId}${partGroupId}`
        ] = expandedPart;
      }
    },
    setDocumentsToLoadForExpandedPart(
      state,
      action: PayloadAction<{
        documentNumber: string;
        languageGroupId: string;
        partGroupId: string;
        documentsToLoad: number;
      }>
    ) {
      const { documentNumber, languageGroupId, partGroupId, documentsToLoad } =
        action.payload;
      const id = `${documentNumber}${languageGroupId}${partGroupId}`;
      if (state.expandablePartGroups[id]?.isExpanded) {
        state.expandablePartGroups[id] = {
          isExpanded: state.expandablePartGroups[id]?.isExpanded ?? false,
          documentsToLoad: documentsToLoad,
          shouldExpandOnExpandAll: documentsToLoad > 0,
        };
      }
    },
    increaseChangingWorkflowCounter(state) {
      state.changingWorkflowCounter++;
    },
    decreaseChangingWorkflowCounter(state) {
      if (state.changingWorkflowCounter > 0) {
        state.changingWorkflowCounter--;
      }
    },
  },
});

export const {
  setDensity,
  setPageNumber,
  setPageSize,
  setDocumentsCount,
  setIsDocumentsListFetching,
  setIsDocumentListLoading,
  resetDensity,
  resetExpandedGroups,
  resetPageNumber,
  setChangeListView,
  setSortBy,
  setSortDescending,
  incrementGroupItemsToLoadCount,
  decrementGroupItemsToLoadCount,
  setGroupItemsToLoadCount,
  setStartedDocumentsFirstLoad,
  setIsDocumentListError,
  setDocumentGroup,
  setLanguageGroup,
  setPartsGroup,
  setDocumentsToLoadForExpandedPart,
  setAllGroupsToExpand,
  setAllGroupsToCollapse,
  increaseChangingWorkflowCounter,
  decreaseChangingWorkflowCounter,
  setExpandableGroupsState,
} = documentListSlice.actions;

export const selectDensity = (state: RootState) => state.documentList.density;
export const selectPageSize = (state: RootState) => state.documentList.pageSize;
export const selectIsGroupingEnabled = (state: RootState) =>
  state.documentList.groupedListView;
export const selectPageNumber = (state: RootState) =>
  state.documentList.pageNumber;
export const selectDocumentsCount = (state: RootState) =>
  state.documentList.documentsCount;
export const selectIsDocumentsListFetching = (state: RootState) =>
  state.documentList.isFetching;
export const selectIsDocumentsListLoading = (state: RootState) =>
  state.documentList.isLoading;
export const selectSortBy = (state: RootState) => state.documentList.sortBy;
export const selectSortDescending = (state: RootState) =>
  state.documentList.sortDescending;
export const selectAllGroupAccordionState = (state: RootState) =>
  state.documentList.allGroupAccordionState;
export const selectExpandedGroupsCount = (state: RootState) =>
  state.documentList.expandedGroupsCount;
export const selectGroupItemsToLoadCount = (state: RootState) =>
  state.documentList.groupItemsToLoadCount;
export const selectGroupsCount = (state: RootState) =>
  state.documentList.groupsCount;
export const selectStartedDocumentsFirstLoad = (state: RootState) =>
  state.documentList.startedDocumentsFirstLoad;
export const selectIsDocumentListError = (state: RootState) =>
  state.documentList.isError;
export const selectDocumentGroup = createSelector(
  [
    (state: RootState) => state.documentList.expandableDocumentGroups,
    (state, documentNumber: string) => documentNumber,
  ],
  (groups, documentNumber) => groups[documentNumber]
);
export const selectLanguageGroup = createSelector(
  [
    (state: RootState) => state.documentList.expandableLanguageGroups,
    (state, documentNumber: string, languageGroupId: string) => ({
      documentNumber,
      languageGroupId,
    }),
  ],
  (groups, { documentNumber, languageGroupId }) =>
    groups[`${documentNumber}${languageGroupId}`]
);
export const selectPartsGroup = createSelector(
  [
    (state: RootState) => state.documentList.expandablePartGroups,
    (
      state,
      documentNumber: string,
      languageGroupId: string,
      partGroupId: string
    ) => ({
      documentNumber,
      languageGroupId,
      partGroupId,
    }),
  ],
  (groups, { documentNumber, languageGroupId, partGroupId }) =>
    groups[`${documentNumber}${languageGroupId}${partGroupId}`]
);
export const selectAreAllGroupsExpanded = (state: RootState) =>
  state.documentList.areAllGroupsExpanded;

export const selectChangingWorkflowCounter = (state: RootState) =>
  state.documentList.changingWorkflowCounter;

export default documentListSlice.reducer;
