import styled from "styled-components";

export interface ILinearProgressDiv {
  $isVisible: boolean;
}

function getVisibility(isVisible: boolean) {
  if (isVisible) return "visible";

  return "hidden";
}

export const LinearProgressDiv = styled.div<ILinearProgressDiv>`
  z-index: 100;
  width: 100%;
  display: block;
  visibility: ${(props) => getVisibility(props.$isVisible)};
`;
