import { useCallback, useEffect } from "react";
import { useTheme } from "styled-components";
import { useLazyGetOwnerCategoriesQuery } from "../../../../../apis/publisherCategoriesApi";
import StyledCategoryTreeItemErrorMessage from "../../../../../app/errorHandling/categoryTreeItem/StyledCategoryTreeItemErrorMessage";
import { openSnackbar } from "../../../../../app/helpers/snackBarHelper";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { SelectedCategory } from "../../../../../app/slices/documentMetadataSlice";
import {
  selectCategoryChildrenByParentId,
  selectCategoryById,
  setIsExpanded,
  setSelectedNode,
  removeSelectedNode,
} from "../../../../../app/slices/ownerCategoryTreeSlice";
import { Category } from "../../../../../models/categoryTree";
import { SnackbarType } from "../../../../../models/snackbar";
import { updateOwnerCategories } from "../../../../../app/slices/ownerCategoryTreeSlice";
import { rootCategoryId } from "../../../../../app/slices/navigationSlice";
import { NodeNamePart, TreeItem } from "../treeItem/TreeItem";
import { TreeUl } from "../../../../../controls/CategoryTree/SC/TreeUl";
import { TreeItemOwnerSkeleton } from "../../../../../controls/CategoryTree/skeleton/TreeItemOwnerSkeleton";
import { SnackbarMsg } from "../../../../../controls/Snackbar/SnackbarMessages";

interface CategoryTreeControlProps {
  categoryId: string;
  maxCategoryLevelShow: number | null;
  colorCategoriesByLevel: number;
  onCategorySelect: (categoryId: string, categoryName: string) => void;
}

const getCategoryNameParts = (categoryName: string): NodeNamePart[] => {
  return [
    {
      part: categoryName,
      isHighlighted: false,
    },
  ];
};

export function CategoryTreeControl({
  categoryId,
  maxCategoryLevelShow,
  colorCategoriesByLevel,
  onCategorySelect,
}: CategoryTreeControlProps) {
  const theme = useTheme();
  const addRemoveIconWidth = 32;
  const dispatch = useAppDispatch();
  const category = useAppSelector((state) =>
    selectCategoryById(state, categoryId)
  );
  const categoryChildren = useAppSelector((state) =>
    selectCategoryChildrenByParentId(state, categoryId)
  );

  const [
    getOwnerCategories,
    { data: categoryChildrenResponse, isError: isCategoryChildrenError },
  ] = useLazyGetOwnerCategoriesQuery();

  useEffect(() => {
    if (isCategoryChildrenError) {
      openSnackbar(
        dispatch,
        SnackbarMsg.CategoryChildrenError,
        SnackbarType.error
      );
    }
  }, [isCategoryChildrenError, dispatch]);

  useEffect(() => {
    if (categoryChildrenResponse) {
      dispatch(updateOwnerCategories(categoryChildrenResponse));
    }
  }, [categoryChildrenResponse, dispatch]);

  const getNodeChildren = useCallback(() => {
    if (category) {
      void getOwnerCategories(
        {
          includeHighestParent: false,
          maxDepth: 1,
          parentCategoryId: category.categoryId,
          maxCategoryLevelShow: maxCategoryLevelShow ?? undefined,
        },
        true
      );
    }
  }, [category, maxCategoryLevelShow, getOwnerCategories]);

  const onExpand = (nodeId: string) => {
    if (nodeId && categoryChildren === undefined && !isCategoryChildrenError) {
      getNodeChildren();
    }

    dispatch(setIsExpanded({ categoryId: categoryId, isExpanded: true }));
  };

  const onCollapse = () => {
    dispatch(setIsExpanded({ categoryId: categoryId, isExpanded: false }));
  };

  const onSelect = (node: SelectedCategory) => {
    dispatch(setSelectedNode(node.categoryId));
    onCategorySelect(node.categoryId, node.categoryName);
  };

  const onDeselect = () => {
    dispatch(removeSelectedNode());
    onCategorySelect("", "");
  };

  const getCategoryDepth = (level: number) => {
    const rootCategoryLevel = -2;
    const indentation = level - rootCategoryLevel;

    return indentation;
  };

  const paddingLeft = (level: number) =>
    (theme.shapes.defaultCategoryTreePadding + addRemoveIconWidth) *
    (getCategoryDepth(level) + 0.5);

  const renderChildren = () => {
    if (categoryChildren && categoryChildren.length > 0) {
      return categoryChildren.map((childCategoryId) => (
        <CategoryTreeControl
          colorCategoriesByLevel={colorCategoriesByLevel}
          key={childCategoryId}
          categoryId={childCategoryId}
          maxCategoryLevelShow={maxCategoryLevelShow}
          onCategorySelect={onCategorySelect}
        />
      ));
    }

    if (category && isCategoryChildrenError) {
      return (
        <StyledCategoryTreeItemErrorMessage
          treeItemMessageStyles={{
            paddingLeft: `${paddingLeft(category.level)}px`,
            marginLeft: "0px",
          }}
          errorMessage="Categories loading failed"
          refreshFunction={getNodeChildren}
        />
      );
    }

    if (category?.hasChildren) {
      return (
        <TreeItemOwnerSkeleton
          parentCategoryId={category.parentCategoryId}
          numbersOfSkeleton={4}
          padding={
            getCategoryDepth(category.level) *
            theme.shapes.defaultCMSCategoryTreePadding +
            addRemoveIconWidth
          }
          width={240}
        />
      );
    }

    return <></>;
  };

  const hasAccessInside = (hasAccessInside: boolean, categoryLevel: number) =>
    categoryLevel >= colorCategoriesByLevel && hasAccessInside;

  const isSelectable = (category: Category): boolean => {
    const { categoryId, parentCategoryId } = category;

    return !(
      categoryId === rootCategoryId || parentCategoryId === rootCategoryId
    );
  };

  if (category) {
    return (
      <TreeUl isExpanded={true}>
        <TreeItem
          hasAccessInside={hasAccessInside(
            category.hasAccessInside,
            category.level
          )}
          hasAccess={category.hasAccess}
          nodeId={category.categoryId}
          isHighLevelNode={category.isHighLevelCategory}
          nodeNameParts={getCategoryNameParts(category.categoryName)}
          selectable={isSelectable(category)}
          expanded={category.isExpanded}
          expandable={category.hasChildren}
          level={category.level}
          defaultPadding={theme.shapes.defaultCMSCategoryTreePadding}
          highlighted={false}
          onExpand={onExpand}
          onCollapse={onCollapse}
          onSelect={onSelect}
          onDeselect={onDeselect}
        />
        <TreeUl isExpanded={category.isExpanded}>{renderChildren()}</TreeUl>
      </TreeUl>
    );
  }

  return <></>;
}
