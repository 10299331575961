import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogContent } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { PreviousRevisionState } from "../../../../../apis/documentActionsApi";
import usePreviousNonDeletedRevision from "../../../../../app/hooks/document/usePreviousNonDeletedRevision";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { useUserPermissionsInDocument } from "../../../../../app/hooks/permissions/useUserPermissionsInDocument";
import {
  selectDocumentIdentity,
  selectDocumentWorkflow,
  selectIsRevisionLatest,
} from "../../../../../app/slices/documentMetadataSlice";
import { IconButtonControl } from "../../../../../controls/Buttons/IconButtonControl";
import { StyledDialog } from "../../../../../controls/Dialog/SC/StyledDialog";
import { StyledDialogTitle } from "../../../../../controls/Dialog/SC/StyledDialogTitle";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";
import ScheduledActionMessage, {
  Action,
} from "../../../../../models/scheduledActionMessage";
import DeleteAllPopup from "../../../../scheduledActions/DeleteAllPopup";
import { IconButtonControlDiv } from "../../SC/IconButtonControlDiv";
import { ScheduledActionDiv } from "./SC/ScheduledActionDiv";
import { StyledScheduledActionSpan } from "./SC/StyledScheduledActionSpan";
import { ActionDropDown } from "./components/ActionDropDown";
import { Calendar } from "./components/Calendar";
import { CreatedByField } from "./components/CreatedByField";
import { EmailSection } from "./components/EmailSection";
import { useScheduledActions } from "./useScheduledActions";
import { ActionConfirmationDialog } from "./components/ActionConfirmationDialog";
import { ActionButtons } from "./buttons/ActionButtons";
import {
  clearScheduledActionState,
  selectScheduledAction,
  selectScheduledActionEmailNoteError,
  selectScheduledActionIsError,
  selectScheduledActionIsErrorVisible,
  selectScheduledActionIsFilled,
  selectScheduledActionIsLoading,
  setScheduledActionIsError,
  setScheduledActionIsErrorVisible,
} from "../../../../../app/slices/scheduledActionSlice";
import { Workflow } from "../../../../../models/documentList/types/workflow";
import { SelfHelpButtonControl } from "../../../../../controls/Buttons/SelfHelpButtonControl";
import { CurrentSelfHelp } from "../../../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../../../models/selfHelp/selfHelpSection";
import { SectionName } from "../../../../../app/slices/models/documentDetailsModels";

interface ScheduledActionDialogProps {
  isOpen: boolean;
  onCloseClick: () => void;
  title: string;
  content: ScheduledActionMessage | undefined;
}

export function ScheduledActionDialog(props: ScheduledActionDialogProps) {
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isDeleteAllPopupOpen, setIsDeleteAllPopupOpen] = useState(false);
  const dispatch = useAppDispatch();
  const isLatest = useAppSelector(selectIsRevisionLatest);
  const identity = useAppSelector(selectDocumentIdentity);
  const workflow = useAppSelector(selectDocumentWorkflow);
  const selectedAction = useAppSelector(selectScheduledAction);
  const isError = useAppSelector(selectScheduledActionIsError);
  const isErrorVisible = useAppSelector(selectScheduledActionIsErrorVisible);
  const isFilled = useAppSelector(selectScheduledActionIsFilled);
  const isEmailNoteError = useAppSelector(selectScheduledActionEmailNoteError);
  const isLoading = useAppSelector(selectScheduledActionIsLoading);

  const { hasPermissionToEditDocument, hasPermissionToDeleteDocument } =
    useUserPermissionsInDocument(false);
  const {
    scheduleActionHandler,
    deletedScheduledActionHandler,
    isSuccess,
    isDeleteSuccess,
  } = useScheduledActions();
  const isEditable = hasPermissionToEditDocument.isEditable;

  const handleScheduleAction = useCallback(() => {
    scheduleActionHandler();
  }, [scheduleActionHandler]);

  const onFetchPreviousNonDeletedRevisionSuccess = useCallback(
    (revisionState: PreviousRevisionState) => {
      if (revisionState.canPreviousRevisionBePublished) {
        setIsDeleteAllPopupOpen(true);
      } else {
        handleScheduleAction();
      }
    },
    [handleScheduleAction]
  );

  const { fetchPreviousNonDeletedRevision } = usePreviousNonDeletedRevision({
    identity: identity,
    onSuccess: onFetchPreviousNonDeletedRevisionSuccess,
    omitScheduledForDelete: true,
  });

  const scheduleAction = useCallback(() => {
    if (isError) {
      dispatch(setScheduledActionIsErrorVisible(true));
      return;
    }

    if (!isFilled || isEmailNoteError) {
      dispatch(setScheduledActionIsError(true));
      dispatch(setScheduledActionIsErrorVisible(true));
      return;
    }

    if (isLatest && selectedAction.action === Action.Delete) {
      fetchPreviousNonDeletedRevision();
      return;
    }

    handleScheduleAction();
  }, [
    selectedAction,
    isLatest,
    handleScheduleAction,
    fetchPreviousNonDeletedRevision,
    dispatch,
    isFilled,
    isEmailNoteError,
    isError,
  ]);

  const closeDeleteAllPopup = useCallback(() => {
    setIsDeleteAllPopupOpen(false);
  }, []);

  const onDeleteButtonHandeler = () => {
    setIsOpenDeleteDialog(true);
  };

  const onConfirmDelete = () => {
    deletedScheduledActionHandler();
    setIsOpenDeleteDialog(false);
  };

  const onCloseDeleteDialog = () => {
    setIsOpenDeleteDialog(false);
  };

  const onDialogClose = (
    event: object,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason !== "backdropClick") {
      props.onCloseClick();
      dispatch(clearScheduledActionState(true));
    }
  };

  useEffect(() => {
    if (isFilled && !isEmailNoteError) {
      dispatch(setScheduledActionIsError(false));
      dispatch(setScheduledActionIsErrorVisible(false));
    }
  }, [isFilled, isEmailNoteError, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      props.onCloseClick();
    }
  }, [props, isSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      props.onCloseClick();
    }
  }, [props, isDeleteSuccess]);

  return (
    <StyledDialog
      id={"scheduled-action-dialog"}
      open={props.isOpen}
      onClose={onDialogClose}
    >
      <ActionConfirmationDialog
        id={"document-details-delete-scheduled-action-button-dialog"}
        onCloseClick={onCloseDeleteDialog}
        onYesClick={onConfirmDelete}
        onNoClick={onCloseDeleteDialog}
        isOpen={isOpenDeleteDialog}
      />
      <StyledDialogTitle id={"scheduled-action-alert-dialog-title"}>
        {props.title}
        <IconButtonControlDiv>
          <SelfHelpButtonControl
            id={"scheduled-action-self-help-dialog-button"}
            currentSelfHelp={CurrentSelfHelp.DetailsScheduledAction}
            page={SelfHelpPage.Details}
            section={SectionName.ScheduledAction}
          />
          <IconButtonControl
            id={"scheduled-action-confirm-dialog-close-button"}
            popupText={TooltipMsg.Close}
            onClick={props.onCloseClick}
            icon={<CloseIcon fontSize="inherit" />}
          />
        </IconButtonControlDiv>
      </StyledDialogTitle>
      <DialogContent id="scheduled-action-dialog-content">
        <Box component="form" noValidate autoComplete="off">
          <ScheduledActionDiv>
            <StyledScheduledActionSpan float="left">
              <ActionDropDown
                isEditable={isEditable}
                action={props.content?.action}
                canDelete={hasPermissionToDeleteDocument.isEditable}
                canArchive={workflow != Workflow.Archived}
              />
            </StyledScheduledActionSpan>
            <StyledScheduledActionSpan width={"30ch"} float="none">
              <CreatedByField
                isEditable={isEditable}
                userName={props.content?.userEmail}
              />
            </StyledScheduledActionSpan>
          </ScheduledActionDiv>
          <EmailSection
            emailBody={props.content?.emailContent}
            emailRecipients={props.content?.emailRecipients}
            isEditable={isEditable}
          />
          <Calendar
            expirationDate={props.content?.expirationDate}
            isEditable={isEditable}
          />
        </Box>
      </DialogContent>
      <ActionButtons
        isError={isErrorVisible && isError}
        isEditable={isEditable}
        isLoading={isLoading}
        isScheduled={!!props.content}
        onCloseClick={() => {
          props.onCloseClick();
          dispatch(clearScheduledActionState(true));
        }}
        onConfirmButtonHandler={scheduleAction}
        onDeleteButtonHandeler={onDeleteButtonHandeler}
      />
      <DeleteAllPopup
        isOpen={isDeleteAllPopupOpen}
        message={{
          ...selectedAction,
        }}
        onCloseClick={closeDeleteAllPopup}
        onNoClick={handleScheduleAction}
        handleScheduleDialogClose={props.onCloseClick}
      />
    </StyledDialog>
  );
}
