import { useNavigate } from "react-router-dom";
import { TextButtonControl } from "../../../../controls/Buttons/TextButtonControl";
import { ButtonDiv } from "../SC/ButtonDiv";
import { useTheme } from "styled-components";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { selectDocumentMetadata } from "../../../../app/slices/documentMetadataSlice";
import { useRouteHelper } from "../../../../app/hooks/useRouteHelper";
import { Workflow } from "../../../../models/documentList/types/workflow";
import { useEffect } from "react";
import { addVisibleButton, ActionButton, removeVisibleButton } from "../../../../app/slices/selfHelpSlice";

interface GoToLatestRevisionButtonProps {
  isCompactView?: boolean;
  latestRevision: string;
}

export function GoToLatestRevisionButton(props: GoToLatestRevisionButtonProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { getUrlToDocumentByIdentity } = useRouteHelper();
  const navigate = useNavigate();
  const documentMetada = useAppSelector(selectDocumentMetadata);

  const navigateToLatestDocumentHandler = () => {
    const url = getUrlToDocumentByIdentity({
      identity: {
        ...documentMetada.documentIdentity,
        revision: props.latestRevision,
      },
      workflow: Workflow.Published,
    });
    navigate(`/document/${url}`);
  };

  useEffect(() => {
    dispatch(addVisibleButton(ActionButton.GoToLatest))

    return () => {
      dispatch(removeVisibleButton(ActionButton.GoToLatest))
    }
  }, [dispatch])

  return (
    <ButtonDiv>
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.primaryButtonHeight}
        id={"document-details-go-to-latest-revison-button"}
        text={"Go to latest revision"}
        onClick={navigateToLatestDocumentHandler}
        isCompactView={props.isCompactView == true}
        isVertical={false}
        isVisible={true}
        icon={<ArrowRightIcon fontSize="small" />}
      />
    </ButtonDiv>
  );
}
