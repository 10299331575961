import styled, { DefaultTheme } from "styled-components";
import { ToggleButton } from "@mui/material";
import { Workflow } from "../../../../../../models/documentList/types/workflow";

interface StyledToggleButtonProps {
  workflow: Workflow;
}

function getColor(workflow: Workflow, theme: DefaultTheme): string {
  switch (workflow) {
    case Workflow.Approval:
      return theme.palettes.secondary.approval;
    case Workflow.Archived:
      return theme.palettes.secondary.archived;
    case Workflow.Deleted:
      return theme.palettes.secondary.deleted;
    case Workflow.Draft:
      return theme.palettes.secondary.draft;
    case Workflow.Published:
      return theme.palettes.secondary.published;
    default:
      return "";
  }
}

export const StyledToggleButton = styled(ToggleButton)<StyledToggleButtonProps>`
  &.MuiButtonBase-root {
    display: flex;
    flex-direction: column;
    padding-right: 4px;
    padding-left: 4px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: normal;
  }
  &.MuiButtonBase-root.Mui-selected {
    color: ${(props) => props.theme.palettes.grayscale.darkest};
    font-weight: 800;
    border-bottom: 4px solid ${(props) => getColor(props.workflow, props.theme)};
  }
`;
