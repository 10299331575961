import styled from "styled-components";

function getHeight(height: number | undefined) {
  if (height) {
    return height + "px";
  }

  return "100%";
}

interface CircularProgressDivProps {
  height?: number;
}

export const CircularProgressDiv = styled.div<CircularProgressDivProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${(props) => getHeight(props.height)};
`;
