import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function ListStep7() {
    const theme = useTheme();
    return (
        <StepContentDiv>
            <Paragraph>
                Categories can exist in two states:
                <ul>
                    <li>
                        <span style={{ color: theme.palettes.grayscale.medium }}>
                            Gray, with a normal font
                        </span>{" "} - Categories to which current user does not have Owner rights.
                    </li>
                    <li>
                        <span style={{ fontWeight: "bold" }}>
                            Black, with a bolded font
                        </span>{" "} - Categories to which current user has Owner rights.
                    </li>
                </ul>
            </Paragraph>
        </StepContentDiv>
    );
}
