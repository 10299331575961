import IconButton from "@mui/material/IconButton";
import { StyledErrorIcon } from "../../../../controls/FormHelper/SC/StyledErrorIcon";
import RefreshIcon from "@mui/icons-material/Refresh";
import { PartsErrorSpan } from "./styledComponents/PartsErrorSpan";

export interface PartsAccordionErrorProps {
  errorMessage: string;
  reloadDataHandler?: (useCache: boolean) => void;
}

export function PartsAccordionError(props: PartsAccordionErrorProps) {
  const onMouseDownHandler = () =>
    props.reloadDataHandler ? props.reloadDataHandler(true) : undefined;

  return (
    <>
      <PartsErrorSpan id="parts-error-span">
        <StyledErrorIcon $paddingRight={4} color="error" fontSize="inherit" />
        {props.errorMessage}
      </PartsErrorSpan>
      <IconButton onMouseDown={onMouseDownHandler} size="small">
        <RefreshIcon color="error" fontSize="inherit" />
      </IconButton>
    </>
  );
}
