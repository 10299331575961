import { useTheme } from "styled-components";
import { PaperControl } from "../../../../controls/Paper/PaperControl";
import { CircularProgressBar } from "../../../../controls/ProgressIndicators/CircularProgressBar";
import { ConfigurationsPaperDiv } from "./SC/ConfigurationsPaperDiv";
import { ApprovalWorkflow } from "./approvalWorkflow/ApprovalWorkflow";
import { DeleteAttachment } from "./deleteAttachment/DeleteAttachment";
import { HighLevelWarningThreshold } from "./highLevelWarningThreshold/HighLevelWarningThreshold";
import { SectionName } from "../../../../app/slices/models/documentDetailsModels";
import { CurrentSelfHelp } from "../../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../../models/selfHelp/selfHelpSection";
import { useAppSelector } from "../../../../app/hooks/hooks";
import {
  selectCategoryId,
  selectIsSettingsEdited,
} from "../../../../app/slices/publisherCategoriesSettingsSlice";
import { useEffect, useState } from "react";

interface ConfigurationPaperProps {
  disabled: boolean;
  isLoading: boolean;
}

export function ConfigurationPaper(props: ConfigurationPaperProps) {
  const theme = useTheme();
  const isEdited = useAppSelector(selectIsSettingsEdited);
  const categoryId = useAppSelector(selectCategoryId);
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    setIsReady(false);
  }, [categoryId]);

  useEffect(() => {
    if (!props.isLoading) {
      setIsReady(true);
    }
  }, [props.isLoading]);

  return (
    <PaperControl
      useHeader={true}
      title={"Configurations"}
      disabled={props.disabled}
      currentSelfHelp={CurrentSelfHelp.CmsConfigurations}
      selfHelpPage={SelfHelpPage.Cms}
      selfHelpSectionName={SectionName.CmsConfigurations}
      isEdited={isEdited}
    >
      <ConfigurationsPaperDiv id="cms-configurations-paper">
        {props.isLoading || !isReady ? (
          <CircularProgressBar
            size={theme.circularProgress.medium}
            space={theme.circularProgressWrapper.defaultHeight}
            color="secondary"
          />
        ) : (
          <>
            <ApprovalWorkflow disabled={props.disabled} />
            <DeleteAttachment disabled={props.disabled} />
            <HighLevelWarningThreshold disabled={props.disabled} />
          </>
        )}
      </ConfigurationsPaperDiv>
    </PaperControl>
  );
}
