import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStep1() {
  return (
    <StepContentDiv>
      <Paragraph>
        This tool allows Level 0 Category Owners to manage category settings.
      </Paragraph>
    </StepContentDiv>
  );
}
