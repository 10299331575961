import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStepOne() {
    return (
        <StepContentDiv>
            <Paragraph>
                The Country Market Availability feature allows you to specify the countries in which a document should be visible by default, as well as the countries where it should not be visible.
            </Paragraph>
            <Paragraph>
                It is important to note that this feature is not used to limit access to the document. Access to document is based on its security level.
            </Paragraph>
        </StepContentDiv>
    );
}
