import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import {
  selectHasApiErrors,
  setDocumentDetailView,
} from "../../app/slices/documentDetailsSlice";
import {
  setDocumentDraftType,
  setDocumentWorkflow,
} from "../../app/slices/documentMetadataSlice";
import { DocumentDetailView } from "../../models/documentDetailView";
import { DraftType } from "../../models/documentDetails/documentStatus";
import { Workflow } from "../../models/documentList/types/workflow";
import { Details } from "../details/Details";
import { ContentDiv } from "./SC/ContentDiv";

export function DetailsView() {
  const dispatch = useAppDispatch();
  const hasApiErrors = useAppSelector(selectHasApiErrors);

  if (!hasApiErrors) {
    dispatch(setDocumentDetailView(DocumentDetailView.EditDocument));
    dispatch(setDocumentWorkflow(Workflow.Draft));
    dispatch(setDocumentDraftType(DraftType.NonDraft));
  }

  return (
    <ContentDiv id="content-div">
      <Details />
    </ContentDiv>
  );
}
