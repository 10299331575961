import { StepContentDiv } from "../../../SC/StepContentDiv";

export function FirstCheckboxStep() {
  return (
    <StepContentDiv>
      When the &quot;Include documents from subcategories&quot; option is
      disabled, it will only show documents that are directly classified under
      the selected category, excluding documents classified under categories
      nested below it.
    </StepContentDiv>
  );
}
