import styled from "styled-components";

export interface ErrorHeaderDivProps {
  $doCenterHeader: boolean;
  $showDash: boolean;
}

export const ErrorHeaderDiv = styled.div<ErrorHeaderDivProps>`
  font-size: ${(props) => props.theme.typography.fontSizes.splashScreenTitle}px;
  font-weight: bold;
  text-align: ${(props) =>
    props.$doCenterHeader ? "center" : props.$showDash ? "left" : ""};
  margin-bottom: 12px;
`;
