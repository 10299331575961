import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function PagingStep12() {
    return (
        <StepContentDiv>
            <Paragraph>
                By default, 50 results per page are displayed. However, this can be changed to 10, 25, 50 or 100 results per page.
            </Paragraph>
            <Paragraph>
                User preferences for the number of results per page are saved for future sessions.
            </Paragraph>
            <Paragraph>
                On the right side, the total number of results and the range of currently displayed documents are shown.
            </Paragraph>
            <Paragraph>
                The next or previous page can be opened by clicking on the arrows.
            </Paragraph>
        </StepContentDiv>
    );
}
