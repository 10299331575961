import {
  PublicClientApplication,
  EventMessage,
  AuthenticationResult,
  EventType,
  Configuration,
} from "@azure/msal-browser";

let msalInstance: PublicClientApplication | null = null;

export function clearMsalInstance() {
  msalInstance = null;
}

export function getMsalInstance(
  configuration: Configuration
): PublicClientApplication {
  if (!msalInstance) {
    msalInstance = new PublicClientApplication(configuration);

    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance?.setActiveAccount(account);
      }
    });
  }

  return msalInstance;
}
