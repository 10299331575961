import { PaperContentCenterDiv } from "./SC/PaperContentCenterDiv";
import { CircularProgressBar } from "../../../../../../controls/ProgressIndicators/CircularProgressBar";

export function Loader() {
  return (
    <PaperContentCenterDiv>
      <CircularProgressBar
        message="Loading..."
        size={48}
        messageFontSize={18}
        color={"primary"}
      />
    </PaperContentCenterDiv>
  );
}
