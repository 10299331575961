import { Skeleton } from "@mui/material";
import styled, { DefaultTheme } from "styled-components";
import { Density } from "../../../../../app/slices/models/documentsModels";
import { getMaxHeight, getMinHeight } from "../../helpers/densityHelper";

interface StyledRowSkeletonProps {
  density: Density;
  zebraStripe: boolean;
}

function getColor(zebraStripe: boolean, theme: DefaultTheme) {
  if (zebraStripe) {
    return theme.palettes.grayscale.white;
  }

  return theme.palettes.grayscale.lightest;
}

export const StyledRowSkeleton = styled(Skeleton)<StyledRowSkeletonProps>`
  display: flex;
  width: 100%;
  min-height: ${(props) => getMinHeight(props.density, props.theme)};
  max-height: ${(props) => getMaxHeight(props.density, props.theme)};
  border-bottom: ${(props) =>
    "1px solid " + props.theme.palettes.grayscale.dark};
  &.MuiSkeleton-root {
    background-color: ${(props) => getColor(props.zebraStripe, props.theme)};
  }
`;
