import { useCallback, useMemo } from "react";
import {
  isSecurityLevelLimited,
  isSecurityLevelNonLimited,
} from "../../../../../app/helpers/securityLevelHelper";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { selectCurrentDocumentSecurityLevel } from "../../../../../app/slices/documentMetadataSlice";
import { setSecurityLevelValidationStatus } from "../../../../../app/slices/documentMetadataValidationSlice";
import { ComboBoxListItem } from "../../../../../models/autocompleteValue";
import { ValidationStatus } from "../../../../../models/validationRule";
import {
  ConditionValidationResult,
  ConditionValidationRules,
} from "../../common/ConditionValidationRules";
import SectionValidation from "../../common/SectionValidation";

interface SecurityLevelValidationProps {
  blockLimitedLevels: boolean;
  selectedAccessGroups: ComboBoxListItem[];
}

function SecurityLevelValidation(props: SecurityLevelValidationProps) {
  const dispatch = useAppDispatch();
  const securityLevelDocumentMetadata = useAppSelector(
    selectCurrentDocumentSecurityLevel
  );

  const validationRules: ConditionValidationRules = useMemo(() => {
    return {
      rules: [
        () => ({
          isError:
            isSecurityLevelNonLimited(securityLevelDocumentMetadata) &&
            props.selectedAccessGroups.length > 0,
          errorMessage:
            "This security level does not allow access groups. They will be removed when document is saved/published.",
          status: ValidationStatus.warning,
        }),
        () => ({
          isError:
            isSecurityLevelLimited(securityLevelDocumentMetadata) &&
            props.blockLimitedLevels,
          errorMessage:
            "This security level is not available for selected Main Category.",
          status: ValidationStatus.error,
        }),
      ],
    };
  }, [
    props.blockLimitedLevels,
    securityLevelDocumentMetadata,
    props.selectedAccessGroups,
  ]);

  const onValidationHandle = useCallback(
    (validationResult?: ConditionValidationResult) => {
      dispatch(
        setSecurityLevelValidationStatus(
          validationResult ? validationResult.status : ValidationStatus.success
        )
      );
    },
    [dispatch]
  );

  return (
    <SectionValidation
      rules={validationRules}
      onValidationHandle={onValidationHandle}
      marginBottom={2}
    />
  );
}

export default SecurityLevelValidation;
