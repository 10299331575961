import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

export function ResetStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The{" "}
        <RestartAltIcon
          style={{
            fontSize: "16px",
            verticalAlign: "middle",
          }}
        />{" "}
        &quot;Reset View&quot; button restores the view to its default values.
      </Paragraph>
    </StepContentDiv>
  );
}
