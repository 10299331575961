import styled, { DefaultTheme } from "styled-components";

const getColor = (props: ContainerProps, theme: DefaultTheme) => {
  if (props.isDragAccept) {
    return theme.palettes.secondary.published;
  }
  if (props.isDragReject) {
    return theme.palettes.secondary.deleted;
  }
  if (props.isFocused) {
    return theme.palettes.secondary.info;
  }
  return theme.palettes.grayscale.medium;
};

interface ContainerProps {
  isDragReject: boolean;
  isDragAccept: boolean;
  isFocused: boolean;
}

export const StyledUploadContainer = styled.div<ContainerProps>`
  flex: 1;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props, props.theme)};
  border-style: dashed;
  background-color: ${(props) => props.theme.palettes.grayscale.lightest};
  color: ${(props) => props.theme.palettes.grayscale.medium};
  outline: none;
  transition: border 0.24s ease-in-out;
`;
