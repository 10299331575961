import { createApi } from "@reduxjs/toolkit/query/react";
import DocumentKind from "../models/documentKind";
import { publishApiBaseQuery } from "./baseQueries";

export const documentKindsApi = createApi({
  reducerPath: "documentKindsApi",
  baseQuery: publishApiBaseQuery,
  endpoints: (builder) => ({
    getDocumentKindsList: builder.query<DocumentKind[], string>({
      query: (categoryId) => ({
        url: `DocumentKinds/${categoryId}`,
        method: "GET",
      }),
    }),
    getDocumentKindsForEdit: builder.query<DocumentKind[], string>({
      query: (categoryId) => ({
        url: `DocumentKinds/${categoryId}/Edit`,
        method: "GET",
      }),
    }),
    getAllDocumentKinds: builder.query<DocumentKind[], null>({
      query: () => ({
        url: `DocumentKinds`,
        method: "GET",
      }),

      // The data will be cached for 1 hour even if the query is not active, because its dictionary data
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const {
  useGetDocumentKindsListQuery,
  useLazyGetDocumentKindsListQuery,
  useGetDocumentKindsForEditQuery,
  useLazyGetDocumentKindsForEditQuery,
  useGetAllDocumentKindsQuery,
  useLazyGetAllDocumentKindsQuery,
} = documentKindsApi;
export default documentKindsApi.reducer;
