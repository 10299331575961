import styled from "styled-components";

export const MultipleFilterDiv = styled.div`
display: flex;
flex-direction: column;
overflow: auto;
scrollbar-gutter: stable;
margin-right: 4px;
padding: 4px 4px 4px 4px;

&.wide-scrollbar {
  margin-right: 1px;
}
`;
