import { ConfirmationDialogControl } from "../../../../../controls/Dialog/ConfirmationDialogControl";
import { StyledDialogContentText } from "../../../selfHelpPopup/SC/StyledDialogContentText";

interface IdentityChangeConfirmationDialogProps {
  id: string;
  onCloseClick: (e: React.MouseEvent) => void;
  onYesClick: (e: React.MouseEvent) => void;
  onNoClick: (e: React.MouseEvent) => void;
  isOpen: boolean;
}

export function IdentityChangeConfirmationDialog(
  props: IdentityChangeConfirmationDialogProps
) {
  return (
    <ConfirmationDialogControl
      id={props.id}
      title="Document identity has changed"
      onYesClick={props.onYesClick}
      onNoClick={props.onNoClick}
      onCloseClick={props.onCloseClick}
      useCancelButton
      maxWidth="sm"
      isOpen={props.isOpen}
      content={
        <StyledDialogContentText id="alert-dialog-description">
          Document Identity was changed which affects the link to the document.
          Old link can still work but it will not be possible to use previous
          identity again. Should old link still work?
        </StyledDialogContentText>
      }
    />
  );
}
