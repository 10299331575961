import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function ZoomButtonStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        This action explains how to make the application have bigger/smaller
        fonts, according to user preferences.
      </Paragraph>
    </StepContentDiv>
  );
}
