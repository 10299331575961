import { createStep } from "../../../helpers/selfHelpBuilder";
import { ButtonsStep2 } from "./ButtonsStep2";
import { CloseStep5 } from "./CloseStep5";
import { DiscardChangesStep4 } from "./DiscardChangesStep4";
import { SaveChangesStep3 } from "./SaveChangesStep3";
import { SectionStep1 } from "./SectionStep1";


export const cmsMenuSteps = () => {
  return [
    createStep("#dashboard-div", <SectionStep1 />, 280, "left"),
    createStep("#cms-header-buttons-div", <ButtonsStep2 />, 520, "left"),
    createStep("#document-settings-save-button", <SaveChangesStep3 />, 520, "left"),
    createStep("#document-settings-discard-changes-button", <DiscardChangesStep4 />, 520, "left"),
    createStep("#document-settings-close-button", <CloseStep5 />, 520, "left"),
  ];
};
