import { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import {
  selectCategoryId,
  selectCurrentChangePopularCategory,
  selectCurrentDeleteDocument,
  selectCurrentEditDocumentIdentity,
  selectIsPermissionsEdited,
  selectPermissionChangePopularIsEdited,
  selectPermissionDeleteDocumentIsEdited,
  selectPermissionEditIdentityIsEdited,
  setCurrentChangePopularCategory,
  setCurrentDeleteDocument,
  setCurrentEditDocumentIdentity,
} from "../../../../app/slices/publisherCategoriesSettingsSlice";
import { PaperControl } from "../../../../controls/Paper/PaperControl";
import { CircularProgressBar } from "../../../../controls/ProgressIndicators/CircularProgressBar";
import { CategoryPermissionType } from "../../../../models/cms/categoryPermissionType";
import { RoleInCategory } from "../../../../models/rolesInCategory";
import { RolesPaperDiv } from "./SC/RolesPaperDiv";
import { PermissionInfo } from "./permission/PermissionInfo";
import { SectionName } from "../../../../app/slices/models/documentDetailsModels";
import { CurrentSelfHelp } from "../../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../../models/selfHelp/selfHelpSection";
import { useEffect, useState } from "react";

interface PermissionsPaperProps {
  disabled: boolean;
  isLoading: boolean;
}

export function PermissionsPaper(props: PermissionsPaperProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const editDocumentIdentityValue = useAppSelector(
    selectCurrentEditDocumentIdentity
  );
  const [isReady, setIsReady] = useState<boolean>(false);

  const deleteDocument = useAppSelector(selectCurrentDeleteDocument);
  const changePopularCatetgory = useAppSelector(
    selectCurrentChangePopularCategory
  );

  const deleteDocumentIsEdited = useAppSelector(
    selectPermissionDeleteDocumentIsEdited
  );
  const changePopularCategoryIsEdited = useAppSelector(
    selectPermissionChangePopularIsEdited
  );
  const editIdentityIsEdited = useAppSelector(
    selectPermissionEditIdentityIsEdited
  );
  const categoryId = useAppSelector(selectCategoryId);

  const getPermissionType = (role: RoleInCategory) =>
    role == RoleInCategory.Publisher
      ? CategoryPermissionType.SuperUserAndPublisher
      : CategoryPermissionType.SuperUser;

  const getRoleInCategory = (permission: CategoryPermissionType) => {
    const role =
      permission == CategoryPermissionType.SuperUser
        ? RoleInCategory.SuperUser
        : RoleInCategory.Publisher;

    return role;
  };

  const onDeleteDocument = (role: RoleInCategory) => {
    const categoryPermission = getPermissionType(role);
    dispatch(setCurrentDeleteDocument(categoryPermission));
  };

  const onChangePopularCategory = (role: RoleInCategory) => {
    const categoryPermission = getPermissionType(role);
    dispatch(setCurrentChangePopularCategory(categoryPermission));
  };

  const onEditDocumentIdentity = (role: RoleInCategory) => {
    const categoryPermission = getPermissionType(role);
    dispatch(setCurrentEditDocumentIdentity(categoryPermission));
  };

  const isEdited = useAppSelector(selectIsPermissionsEdited);

  useEffect(() => {
    setIsReady(false);
  }, [categoryId]);

  useEffect(() => {
    if (!props.isLoading) {
      setIsReady(true);
    }
  }, [props.isLoading]);

  return (
    <PaperControl
      useHeader={true}
      title={"Permissions"}
      disabled={props.disabled}
      currentSelfHelp={CurrentSelfHelp.CmsPermissions}
      selfHelpPage={SelfHelpPage.Cms}
      selfHelpSectionName={SectionName.CmsPermissions}
      isEdited={isEdited}
    >
      <RolesPaperDiv id="cms-permissions-paper">
        {props.isLoading || !isReady ? (
          <CircularProgressBar
            size={theme.circularProgress.medium}
            space={theme.circularProgressWrapper.defaultHeight}
            color="secondary"
          />
        ) : (
          <>
            <PermissionInfo
              id="cms-permissions-delete-document"
              value={getRoleInCategory(deleteDocument)}
              onChangeHandler={onDeleteDocument}
              permission="Delete document"
              disabled={props.disabled}
              isEdited={deleteDocumentIsEdited}
            />
            <PermissionInfo
              id="cms-permissions-change-popular-category"
              value={getRoleInCategory(changePopularCatetgory)}
              onChangeHandler={onChangePopularCategory}
              permission="Set document as popular in category"
              disabled={props.disabled}
              isEdited={changePopularCategoryIsEdited}
            />
            <PermissionInfo
              id="cms-permissions-document-identity"
              value={getRoleInCategory(editDocumentIdentityValue)}
              onChangeHandler={onEditDocumentIdentity}
              permission="Edit document identity"
              disabled={props.disabled}
              isEdited={editIdentityIsEdited}
            />
          </>
        )}
      </RolesPaperDiv>
    </PaperControl>
  );
}
