import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SynchronizeStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                By default, the country settings are synchronized with ABB Products. However, when the &quot;Synchronize with ABB Products&quot; feature is disabled, the country settings can be manually overwritten.
            </Paragraph>
        </StepContentDiv>
    );
}
