import {
  createListenerMiddleware,
  isAnyOf,
  TypedStartListening,
} from "@reduxjs/toolkit";
import {
  documentDetailsParamsLocalStorageKey,
  setParamsInLocalStorage,
} from "../../../app/hooks/localStorage";
import { AppDispatch, RootState } from "../../../app/store";

import {
  collapseSection,
  DetailsState,
  expandSection,
  moveSectionToColumn,
  PersistedDetailsState,
  resetDetailsView,
  setDetailsColumnFlex,
  swapSections,
  swapSecurityTabView,
} from "../../../app/slices/documentDetailsSlice";

export const detailsLocalStorageMiddleware = createListenerMiddleware();

function buildPersistedDetailsState(
  detailsState: DetailsState
): PersistedDetailsState {
  return {
    collapsedSections: detailsState.collapsedSections,
    columns: detailsState.columns,
    securityTabAlternativeView: detailsState.securityTabAlternativeView,
    columnsFlex: detailsState.columnsFlex,
  };
}

type AppStartListening = TypedStartListening<RootState, AppDispatch>;

const startAppListening =
  detailsLocalStorageMiddleware.startListening as AppStartListening;

startAppListening({
  matcher: isAnyOf(
    swapSections,
    swapSecurityTabView,
    moveSectionToColumn,
    collapseSection,
    expandSection,
    resetDetailsView,
    setDetailsColumnFlex
  ),
  effect: (_, listenerApi) => {
    setParamsInLocalStorage(
      documentDetailsParamsLocalStorageKey,
      buildPersistedDetailsState(listenerApi.getState().documentDetails)
    );
  },
});
