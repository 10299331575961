export enum ActionDate {
  days30 = "30 days",
  days90 = "90 days",
  days180 = "180 days",
  year = "1 year",
  years2 = "2 years",
  years3 = "3 years",
  years5 = "5 years",
  custom = "Custom",
}
