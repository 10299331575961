import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";

export interface DeleteIconProps {
  $isVisible: boolean;
  disabled?: boolean;
}

export const CategoryBreadCrumbDeleteIcon = styled(DeleteIcon)<DeleteIconProps>`
  color: ${(props) =>
    props.disabled
      ? props.theme.palettes.grayscale.medium
      : props.theme.palettes.grayscale.dark};
  :hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
  &.MuiSvgIcon-root {
    display: flex;
    visibility: ${(props) => (props.$isVisible ? "visible" : "hidden")};
    align-items: center;
    align-content: center;
    justify-content: center;
    width: ${(props) => props.theme.typography.fontSizes.smallIcon}px;
    height: ${(props) => props.theme.typography.fontSizes.smallIcon}px;
  }
`;
