export default function getLanguageNameFromISO(languageISO: string) {
  const languageNames = new Intl.DisplayNames(["en"], { type: "language" });

  return languageNames.of(languageISO.slice(0, 2));
}

export function getSortedLanguageNameFromISOArray(languageISOArray: string[]) {
  const languageNames = new Intl.DisplayNames(["en"], { type: "language" });
  const results: string[] = [];

  languageISOArray.forEach((e) => {
    results.push(languageNames.of(e) ?? "");
  });

  return results.sort((languagePrevious, languageNext) =>
    languagePrevious.toLocaleLowerCase() > languageNext.toLocaleLowerCase()
      ? 1
      : -1
  );
}
