import { useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useLazyGetPublisherCategoriesQuery } from "../../../../../../../apis/publisherCategoriesApi";
import StyledCategoryTreeItemErrorMessage from "../../../../../../../app/errorHandling/categoryTreeItem/StyledCategoryTreeItemErrorMessage";
import { generateLabel } from "../../../../../../../app/helpers/categoriesHelper";
import { openSnackbar } from "../../../../../../../app/helpers/snackBarHelper";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../app/hooks/hooks";
import {
  SelectedCategory,
  addSelectedCategoryNode,
  removeSelectedCategoryNode,
  selectCategorySearch,
} from "../../../../../../../app/slices/documentMetadataSlice";
import { rootCategoryId } from "../../../../../../../app/slices/navigationSlice";
import {
  selectCategoryById,
  selectCategoryChildrenByParentId,
  setIsExpanded,
  updatePublisherCategories,
} from "../../../../../../../app/slices/publisherCategoryTreeSlice";
import { TreeUl } from "../../../../../../../controls/CategoryTree/SC/TreeUl";
import { TreeItemSkeleton } from "../../../../../../../controls/CategoryTree/skeleton/TreeItemSkeleton";
import {
  NodeNamePart,
  TreeItem,
} from "../../../../../../../controls/CategoryTree/treeItem/TreeItem";
import { SnackbarMsg } from "../../../../../../../controls/Snackbar/SnackbarMessages";
import { Category } from "../../../../../../../models/categoryTree";
import { SnackbarType } from "../../../../../../../models/snackbar";
import { publishersAbortReason } from "../../../../../../../app/hooks/useInitialPublisherCategoriesFetch";

interface CategoryTreeControlProps {
  categoryId: string;
  maxDepthShow?: number;
  abortController?: AbortController;
}

const getCategoryNameParts = (
  categoryName: string,
  categoryId: string,
  categorySearch: string,
  hasAccess: boolean
): NodeNamePart[] => {
  const categoryNameLower = categoryName.toLocaleLowerCase();
  if (
    categorySearch.length > 0 &&
    hasAccess &&
    categoryNameLower.includes(categorySearch)
  ) {
    const regex = new RegExp(`(${categorySearch})`, "i");
    return categoryName
      .split(regex)
      .filter((x) => x.length > 0)
      .map((x) => ({
        part: x,
        isHighlighted: x.toLocaleLowerCase() === categorySearch,
      }));
  }

  return [
    {
      part: categoryName,
      isHighlighted:
        categoryId.toLocaleLowerCase() === categorySearch && hasAccess,
    },
  ];
};

const getCategoryLabel = (
  isInternalVisibleOnly: boolean,
  lifecyclePhase: string
): NodeNamePart[] => {
  const label = generateLabel(false, isInternalVisibleOnly, lifecyclePhase);

  if (label.length > 0) {
    return [
      {
        part: label,
        isHighlighted: false,
      },
    ];
  }

  return [];
};

export function CategoryTreeControl({
  categoryId,
  maxDepthShow,
  abortController,
}: CategoryTreeControlProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const category = useAppSelector((state) =>
    selectCategoryById(state, categoryId)
  );
  const categoryChildren = useAppSelector((state) =>
    selectCategoryChildrenByParentId(state, categoryId)
  );
  const categorySearch = useAppSelector(selectCategorySearch);

  const [
    getPublisherCategories,
    { data: categoryChildrenResponse, isError, error },
  ] = useLazyGetPublisherCategoriesQuery();
  const [isCategoryChildrenError, setIsCategoryChildrenError] = useState(false);

  useEffect(() => {
    const isNotAborted =
      (error as { error: string } | undefined)?.error !== publishersAbortReason;
    setIsCategoryChildrenError(isError && isNotAborted);
    if (isError && isNotAborted) {
      openSnackbar(
        dispatch,
        SnackbarMsg.CategoryChildrenError,
        SnackbarType.error
      );
    }
  }, [isCategoryChildrenError, dispatch, error, isError]);

  useEffect(() => {
    if (categoryChildrenResponse) {
      dispatch(updatePublisherCategories(categoryChildrenResponse));
    }
  }, [categoryChildrenResponse, dispatch]);

  const getNodeChildren = useCallback(() => {
    if (category) {
      void getPublisherCategories(
        {
          includeHighestParent: false,
          maxDepth: category.depth + 1,
          parentCategoryId: category.categoryId,
          parentDepth: category.depth,
          signal: abortController?.signal,
        },
        true
      );
    }
  }, [category, getPublisherCategories, abortController?.signal]);

  const onExpand = (nodeId: string) => {
    if (nodeId && categoryChildren === undefined && !isCategoryChildrenError) {
      getNodeChildren();
    }

    dispatch(setIsExpanded({ categoryId: categoryId, isExpanded: true }));
  };

  const onCollapse = () => {
    dispatch(setIsExpanded({ categoryId: categoryId, isExpanded: false }));
  };

  const onSelect = (node: SelectedCategory) => {
    dispatch(addSelectedCategoryNode(node));
  };

  const onDeselect = (node: SelectedCategory) => {
    dispatch(removeSelectedCategoryNode(node));
  };

  const renderChildren = () => {
    if (categoryChildren && categoryChildren.length > 0) {
      return categoryChildren.map((childCategoryId) => (
        <CategoryTreeControl
          key={childCategoryId}
          categoryId={childCategoryId}
          abortController={abortController}
        />
      ));
    }

    if (category && isCategoryChildrenError) {
      return (
        <StyledCategoryTreeItemErrorMessage
          treeItemMessageStyles={{
            paddingLeft: `${
              theme.shapes.defaultCategoryTreePadding * (category.depth + 0.5)
            }px`,
            marginLeft: "0px",
          }}
          errorMessage="Categories loading failed"
          refreshFunction={getNodeChildren}
        />
      );
    }

    if (category?.hasChildren) {
      return (
        <TreeItemSkeleton
          parentCategoryId={category.parentCategoryId}
          numbersOfSkeleton={4}
          treeDepth={category.depth + 2}
          padding={theme.shapes.defaultCategoryTreePadding}
          width={240}
        />
      );
    }

    return <></>;
  };

  const isSelectable = (category: Category): boolean => {
    const { categoryId, parentCategoryId, hasEditorAccess } = category;

    if (categoryId === rootCategoryId || parentCategoryId === rootCategoryId)
      return false;

    return hasEditorAccess;
  };

  if (category) {
    return (
      <TreeUl id="category-tree-ul" isExpanded={true}>
        <TreeItem
          nodeId={category.categoryId}
          isHighLevelNode={category.isHighLevelCategory}
          nodeNameParts={getCategoryNameParts(
            category.categoryName,
            category.categoryId,
            categorySearch,
            category.hasEditorAccess
          ).concat(
            getCategoryLabel(
              category.isInternalVisibleOnly,
              category.lifeCyclePhase
            )
          )}
          selectable={isSelectable(category)}
          expanded={category.isExpanded}
          expandable={
            category.hasChildren &&
            (!maxDepthShow || category.depth <= maxDepthShow)
          }
          treeDepth={category.depth}
          defaultPadding={theme.shapes.defaultCategoryTreePadding}
          highlighted={false}
          onExpand={onExpand}
          onCollapse={onCollapse}
          onSelect={onSelect}
          onDeselect={onDeselect}
        />
        <TreeUl isExpanded={category.isExpanded}>{renderChildren()}</TreeUl>
      </TreeUl>
    );
  }

  return <></>;
}
