import { Chip } from "@mui/material";
import styled from "styled-components";
import { defaultTheme } from "../../../../../app/theme";

interface StyledCategoryChip {
  type: "popular" | "main";
  $isSelected: boolean;
  $isDisabled: boolean;
  selected: boolean;
}

interface ChipStyle {
  selectedNotDisabled: string;
  selectedDisabled: string;
  notSelectedNotDisabled: string;
  notSelectedDisabled: string;
}

const popularChipStyle: ChipStyle = {
  selectedNotDisabled:
    "background-color:" +
    defaultTheme.palettes.secondary.info +
    ";" +
    "color:" +
    defaultTheme.palettes.grayscale.white,
  selectedDisabled:
    "background-color:" +
    defaultTheme.palettes.grayscale.medium +
    ";" +
    "color:" +
    defaultTheme.palettes.grayscale.white,
  notSelectedNotDisabled:
    "background-color: transparent;" +
    "border:" +
    defaultTheme.palettes.secondary.info +
    " 2px solid" +
    ";" +
    "color:" +
    defaultTheme.palettes.secondary.info,
  notSelectedDisabled:
    "background-color: transparent;" +
    "border:" +
    defaultTheme.palettes.grayscale.medium +
    " 2px solid" +
    ";" +
    "color:" +
    defaultTheme.palettes.grayscale.medium,
};

const mainChipStyle: ChipStyle = {
  selectedNotDisabled:
    "background-color:" +
    defaultTheme.palettes.primary.main +
    ";" +
    "color:" +
    defaultTheme.palettes.grayscale.white,
  selectedDisabled:
    "background-color:" +
    defaultTheme.palettes.grayscale.medium +
    ";" +
    "color:" +
    defaultTheme.palettes.grayscale.white,
  notSelectedNotDisabled:
    "background-color: transparent;" +
    "border:" +
    defaultTheme.palettes.primary.main +
    " 2px solid" +
    ";" +
    "color:" +
    defaultTheme.palettes.primary.main,
  notSelectedDisabled:
    "background-color: transparent;" +
    "border:" +
    defaultTheme.palettes.grayscale.medium +
    " 2px solid" +
    ";" +
    "color:" +
    defaultTheme.palettes.grayscale.medium,
};

function getCategoryChipStyle(props: StyledCategoryChip) {
  if (props.type === "popular") {
    if (!props.$isSelected) {
      if (props.$isDisabled) {
        return popularChipStyle.notSelectedDisabled;
      }
      return popularChipStyle.notSelectedNotDisabled;
    }

    if (props.$isDisabled) {
      return popularChipStyle.selectedDisabled;
    }

    return popularChipStyle.selectedNotDisabled;
  }

  if (!props.$isSelected) {
    if (props.$isDisabled) {
      return mainChipStyle.notSelectedDisabled;
    }
    return mainChipStyle.notSelectedNotDisabled;
  }

  if (props.$isDisabled) {
    return mainChipStyle.selectedDisabled;
  }

  return mainChipStyle.selectedNotDisabled;
}

export const StyledCategoryChip = styled(Chip)<StyledCategoryChip>`
  & .MuiChip-label {
    font-size: ${(props) => props.theme.typography.fontSizes.medium}px;
    padding: 0;
  }

  &.MuiChip-root {
    cursor: ${(props) => {
      if (props.$isSelected) {
        return "arrow";
      }

      return props.$isDisabled ? "not-allowed" : "pointer";
    }};
    border-radius: 4px;
    ${(props) => getCategoryChipStyle(props)};
    width: ${(props) => props.theme.shapes.smallChipHeight}px;
    height: ${(props) => props.theme.shapes.smallChipHeight}px;
    min-height: ${(props) => props.theme.shapes.smallChipHeight}px;

    &:hover {
      ${(props) => getCategoryChipStyle(props)};
      cursor: ${(props) => {
        if (props.$isDisabled) {
          return "not-allowed";
        }

        if (props.$isSelected) {
          return "arrow";
        }

        return "hand";
      }};
    }
  }
`;
