import { Autocomplete, AutocompleteProps } from "@mui/material";
import styled from "styled-components";
import { PublisherValue } from "../PublishersSearch";

interface StyledAutoCompleteProps
  extends AutocompleteProps<
    PublisherValue,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  > {
  $isError?: boolean;
}

export const StyledAutoComplete = styled(Autocomplete)<StyledAutoCompleteProps>`
  &.MuiAutocomplete-root {
    width: 100%;
  }

  &.MuiAutocomplete-root .MuiFormControl-root .MuiInputLabel-root {
    color: ${(props) => props.theme.palettes.grayscale.dark};
  }

  & .MuiOutlinedInput-root.Mui-disabled {
    color: ${(props) => props.theme.palettes.grayscale.dark};
    fieldset {
      border: none;
    }
  }

  & .MuiOutlinedInput-input.Mui-disabled {
    color: ${(props) => props.theme.palettes.grayscale.darkest};
    -webkit-text-fill-color: unset;
  }

  &.MuiFormControl-root {
    padding-top: 4px;
  }

  & .MuiOutlinedInput-root {
    fieldset {
      border-color: ${(props) =>
        props.$isError
          ? props.theme.palettes.secondary.error
          : props.theme.palettes.grayscale.medium};
    }

    &.Mui-focused fieldset {
      border-color: ${(props) => props.theme.palettes.grayscale.darkest};
    }
  }

  &.MuiFormControl-root .MuiInputLabel-root {
    padding-top: 4px;
  }

  & .MuiAutocomplete-listbox > ul {
    width: fit-content !important;
  }
`;
