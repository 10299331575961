import { PublishersSectionDiv } from "./SC/PublishersPaperDiv";
import PublishersTable from "./TableControl/PublishersTable";

interface PublishersSectionProps {
  selectedCategoryId: string
}

export function PublishersSection(props: PublishersSectionProps) {
  return (
    <PublishersSectionDiv>
      <PublishersTable selectedCategoryId={props.selectedCategoryId} />
    </PublishersSectionDiv>
  );
}
