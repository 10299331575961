import { useAppDispatch } from "../../../../../app/hooks/hooks";
import { setEditedPopularCategory } from "../../../../../app/slices/documentMetadataSlice";
import { TooltipTop } from "../../../../../controls/Tooltips/ToolipTop";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";
import { StyledCategoryChip } from "../SC/StyledCategoryChip";

interface CategoryPopularChipProps {
  isPopular: boolean;
  canEditCategory: boolean;
  isDeleted: boolean;
  categoryId: string;
  isLatestRevision: boolean;
  disabledTooltip?: string;
}

export function CategoryPopularChip(props: CategoryPopularChipProps) {
  const dispatch = useAppDispatch();

  const tooltipText = () => {
    if (props.disabledTooltip) {
      return props.disabledTooltip;
    }

    if (!props.isLatestRevision) return TooltipMsg.NotLatestRevision;
    if (props.isDeleted) return TooltipMsg.CannotSetPopularForDeletedCategory;
    if (!props.canEditCategory) return TooltipMsg.NoPermissionForPopular;

    if (props.isPopular) {
      return TooltipMsg.UnsetPopular;
    }

    return TooltipMsg.SetPopular;
  };

  const handleClick = () => {
    if (props.canEditCategory) {
      dispatch(
        setEditedPopularCategory({
          categoryId: props.categoryId,
          isPopular: !props.isPopular,
        })
      );
    }
  };

  const className = props.isPopular ? "popular-category-chip-enabled" : "popular-category-chip"

  return (
    <TooltipTop title={tooltipText()}>
      <StyledCategoryChip
        clickable={props.canEditCategory}
        onClick={handleClick}
        data-selected={props.isPopular}
        $isSelected={props.isPopular}
        $isDisabled={
          !props.canEditCategory || props.isDeleted || !props.isLatestRevision
        }
        size="small"
        type="popular"
        label="P"
        selected={props.isPopular}
        className={className}
      />
    </TooltipTop>
  );
}
