import { ActionDate } from "../models/ActionDate";
import { useMemo, useState } from "react";
import { ComboBoxListItem } from "../../../../../../models/autocompleteValue";
import { useScheduledActions } from "../useScheduledActions";
import { DetailsAutoComplete } from "../../../../inputs/DetailsAutoComplete";
import { StyledScheduledActionSpan } from "../SC/StyledScheduledActionSpan";
import { ScheduledActionDiv } from "../SC/ScheduledActionDiv";
import { useGetAppSettingsQuery } from "../../../../../../apis/configApi";
import moment, { Moment } from "moment";
import { ActionDatePicker } from "./ActionDatePicker";
import { useAppDispatch } from "../../../../../../app/hooks/hooks";
import { setScheduledExpirationDate } from "../../../../../../app/slices/scheduledActionSlice";
import { TooltipMsg } from "../../../../../../controls/Tooltips/TooltipMessages";

interface CalendarProps {
  isEditable: boolean;
  expirationDate?: string;
}

export function Calendar(props: CalendarProps) {
  const dispatch = useAppDispatch();
  const { calculateNewDate, getDateRangePicker } = useScheduledActions();
  const { data: config } = useGetAppSettingsQuery(null);
  const dateFormat = config ? config.localization.dateFormat : "";
  const [dateValue, setDateValue] = useState<Moment | undefined>(
    props.expirationDate ? moment(props.expirationDate) : undefined
  );
  const [selectedRangeDate, setSelectedRangeDate] = useState<
    ActionDate | undefined
  >(ActionDate.custom);
  const [changeTrigger, setChangeTrigger] = useState(false);

  const minDate = useMemo(
    () => moment().add(1, "day").format(dateFormat),
    [dateFormat]
  );

  const onDateRangeChange = (values: ComboBoxListItem[]) => {
    const selectedDate = values[0].key as ActionDate;
    setSelectedRangeDate(selectedDate);

    if (selectedDate !== ActionDate.custom) {
      const calculatedDate = calculateNewDate(selectedDate);
      setDateValue(moment(calculatedDate));
      setChangeTrigger((trigger) => !trigger);
      dispatch(
        setScheduledExpirationDate(moment(calculatedDate).format(dateFormat))
      );
    }
  };

  return (
    <ScheduledActionDiv>
      <StyledScheduledActionSpan $paddingTop={2.9} float="left">
        <DetailsAutoComplete
          id="scheduled-action-date-dropdown"
          labelText="Action date range"
          multiple={false}
          selected={
            selectedRangeDate
              ? [
                  {
                    key: selectedRangeDate,
                    value: selectedRangeDate,
                  },
                ]
              : []
          }
          items={Object.values(getDateRangePicker).map((r) => {
            return {
              key: r,
              value: r,
            };
          })}
          alwaysShowBorder={props.isEditable}
          isEditable={props.isEditable}
          isDisabled={!props.isEditable}
          onChangeHandler={onDateRangeChange}
          isEdited={false}
          notEditableMessage={TooltipMsg.NoAccessToDocument}
          $paddingTop={8}
          $paddingBottom={8}
        />
      </StyledScheduledActionSpan>
      <StyledScheduledActionSpan $margin={8} float="none">
        <ActionDatePicker
          id={"scheduled-action-date-picker"}
          label={"Action specific date"}
          minDate={moment(minDate).format(dateFormat)}
          value={dateValue ? dateValue.format(dateFormat) : null}
          trigger={changeTrigger}
          dateFormat={dateFormat}
          disabled={!props.isEditable}
          onClick={(newValue) => {
            if (newValue) {
              setSelectedRangeDate(ActionDate.custom);
              setDateValue(moment(newValue));
              dispatch(setScheduledExpirationDate(newValue));
            }
          }}
        />
      </StyledScheduledActionSpan>
    </ScheduledActionDiv>
  );
}
