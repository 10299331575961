import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import { resetPageNumber } from "../../../app/slices/documentListSlice";
import {
  selectCategoryId,
  setCategoryId,
} from "../../../app/slices/navigationSlice";
import { TooltipTop } from "../../../controls/Tooltips/ToolipTop";
import NavigationCategoryFacetsProgress from "./NavigationCategoryFacetsProgress";
import { StyledCategoryItemIconDiv } from "./SC/StyledCategoryItemIconDiv";
import { StyledCategoryItemLi } from "./SC/StyledCategoryItemLi";
import { StyledCategoryItemSpan } from "./SC/StyledCategoryItemSpan";
import { IconButton } from "@mui/material";
import StyledRefreshIcon from "../../../app/errorHandling/SC/StyledRefreshIcon";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";

interface CategoryItemProps {
  categoryId: string;
  onExpandCategoryId: string;
  isExpanded: boolean;
  isExpandable: boolean;
  isHoveringRefreshIcon: boolean;
  isHoveringErrorIcon: boolean;
  isLoading: boolean;
  isError: boolean;
  isCategoryTreeError: boolean;
  isCategoryGray: boolean;
  hasUserPermissionsInside: boolean;
  label: React.ReactNode;
  treeDepth: number;
  numberOfRevisions?: number;
  onCollapse: () => void;
  onExpand: () => void;
  handleIsHoveringRefresh: (isHovering: boolean) => void;
  refetchCategoriesTreeFacets: (
    categoryId: string,
    skip: boolean
  ) => Promise<void>;
}

function getTooltipTitle(
  isCategoryGray: boolean,
  hasUserPermissionInside: boolean,
  isHoveringRefresh: boolean,
  isHoveringErrorIcon: boolean
): string {
  if (isHoveringErrorIcon) return TooltipMsg.CategoryItemErrorIcon;
  if (isHoveringRefresh) return TooltipMsg.CategoryItemRefreshIcon;
  if (isCategoryGray) {
    return TooltipMsg.CategoryItemHasNoPublishRight;
  }

  if (hasUserPermissionInside) {
    return TooltipMsg.CategoryItemHasPermissionsInside;
  }

  return TooltipMsg.CategoryItemHasPublishRights;
}

const noAccess = "NoAccess";
const accessInside = "AccessInside";
const fullAccess = "Full";

function getUserAccessType(
  isCategoryGray: boolean,
  hasUserPermissionInside: boolean
): string {
  if (isCategoryGray) {
    return noAccess;
  }

  if (hasUserPermissionInside) {
    return accessInside;
  }

  return fullAccess;
}

export function CategoryItem(props: CategoryItemProps) {
  const dispatch = useAppDispatch();
  const selectedCategoryId = useAppSelector(selectCategoryId);
  const isParentCategory =
    props.isError && props.categoryId === props.onExpandCategoryId;

  const onSelect = useCallback(() => {
    dispatch(setCategoryId(props.categoryId));
    dispatch(resetPageNumber());
  }, [dispatch, props.categoryId]);

  const getLabel = () => {
    if (isParentCategory && !props.isCategoryTreeError) {
      return (
        <>
          {props.label}
          <IconButton
            sx={{
              margin: 0,
              padding: 0,
              fontSize: 15,
            }}
            onMouseDown={() => {
              void props.refetchCategoriesTreeFacets(
                props.onExpandCategoryId,
                false
              );
            }}
          >
            <StyledRefreshIcon
              onMouseEnter={() => props.handleIsHoveringRefresh(true)}
              onMouseLeave={() => props.handleIsHoveringRefresh(false)}
              color="action"
            />
          </IconButton>
        </>
      );
    }
    return <> {props.label}</>;
  };

  return (
    <StyledCategoryItemLi
      key={props.categoryId}
      data-cid={props.categoryId}
      data-user-access={getUserAccessType(
        props.isCategoryGray,
        props.hasUserPermissionsInside
      )}
      data-loaded={props.numberOfRevisions !== undefined}
      $isGray={props.isCategoryGray}
      $isSelected={selectedCategoryId === props.categoryId}
      $paddingLeft={8 * (props.treeDepth + 1)}
    >
      <StyledCategoryItemIconDiv $isExpandable={props.isExpandable}>
        {props.isExpanded ? (
          <RemoveIcon onClick={props.onCollapse} />
        ) : (
          <AddIcon onClick={props.onExpand} />
        )}
      </StyledCategoryItemIconDiv>
      <TooltipTop
        title={getTooltipTitle(
          props.isCategoryGray,
          props.hasUserPermissionsInside,
          props.isHoveringRefreshIcon && props.isError,
          props.isHoveringErrorIcon && props.isCategoryTreeError
        )}
      >
        <StyledCategoryItemSpan onClick={onSelect}>
          {getLabel()}
          {props.isLoading || props.numberOfRevisions === undefined ? (
            <NavigationCategoryFacetsProgress categoryId={props.categoryId} />
          ) : (
            <></>
          )}
        </StyledCategoryItemSpan>
      </TooltipTop>
    </StyledCategoryItemLi>
  );
}
