import styled, { DefaultTheme } from "styled-components";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";

interface StyledServiceIntegrationLabelIconProps {
    $marginBottom?: number;
    $width?: number;
    $height?: number;
    $marginLeft?: number;
    size?: "medium" | "large";
}

  function getFontSize(theme: DefaultTheme, size?: "medium" | "large") {
    switch (size) {
      case "large": {
        return theme.typography.fontSizes.large;
      }
      case "medium": {
        return theme.typography.fontSizes.medium;
      }  
      default: {
        return theme.typography.fontSizes.extraSmallIcon;
      }
    }
  }

export const StyledServiceIntegrationLabelIcon = styled(
ElectricalServicesIcon
)<StyledServiceIntegrationLabelIconProps>`
&.MuiSvgIcon-root {
    font-size: ${(props) => getFontSize(props.theme, props.size)}px;
    color: ${(props) => props.theme.palettes.grayscale.medium};
    width: ${(props) => props.theme.typography.fontSizes.smallIcon}px;
    height: ${(props) => props.theme.typography.fontSizes.smallIcon}px;
    margin-bottom: ${(props) => props.$marginBottom ?? 0}px;
    margin-left: ${(props) => props.$marginLeft ?? 0}px;
  }
`;
