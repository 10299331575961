import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function TitleFirstStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                The title serves to describe the content and purpose of the document.
            </Paragraph>
            <Paragraph>
                It is essential that the document title is provided in English and in all other selected languages.
            </Paragraph>
            <Paragraph>
                The title should be descriptive and accurately reflect the content of the attachment, as it is the first thing end users encounter when they come across the document.
            </Paragraph>
            <Paragraph>
                Ideally, the title should match the title within the attachment itself, if available.
            </Paragraph>
        </StepContentDiv>
    );
}
