import { DialogActions, DialogContent } from "@mui/material";
import { StyledDialog } from "../../../controls/Dialog/SC/StyledDialog";
import { DialogButton } from "../../../controls/Dialog/SC/DialogButton";
import { StyledDialogTitle } from "../../../controls/Dialog/SC/StyledDialogTitle";
import { IconButtonControl } from "../../../controls/Buttons/IconButtonControl";
import CloseIcon from "@mui/icons-material/Close";
import { StyledDialogContentText } from "./SC/StyledDialogContentText";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { IconButtonControlDiv } from "../../details/contextMenu/SC/IconButtonControlDiv";
import { useEffect, useState } from "react";
import { useUserApplicationRoles } from "../../../app/hooks/permissions/roles/useUserApplicationRoles";

export function ReadOnlyPopup() {
  const [isOpen, setIsOpen] = useState(false);
  const { canPublish } = useUserApplicationRoles();

  useEffect(() => {
    if (!canPublish) {
      setIsOpen(true);
    }
  }, [canPublish]);

  return (
    <StyledDialog
      id={"readonly-dialog"}
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <StyledDialogTitle id={"set-selfhelp-dialog-title"}>
        {"No publisher access"}
        <IconButtonControlDiv>
          <IconButtonControl
            id={"set-readonly-dialog-close-button"}
            popupText={TooltipMsg.Close}
            onClick={() => setIsOpen(false)}
            icon={<CloseIcon fontSize="inherit" />}
          />
        </IconButtonControlDiv>
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText id="set-readonly-dialog-description">
          Please be informed that you do not have access to publish documents
          and ABB Library Publishing Interface is in read-only mode. To get
          publishing access, please raise a ticket via{" "}
          <a href="https://abb.service-now.com/myservices?id=sc_cat_item&sys_id=26d627f5dbcba09498282e84059619e1">
            {" "}
            MyIS tool
          </a>
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogButton
          id={"set-readonly-dialog-close-button"}
          color="primary"
          onClick={() => setIsOpen(false)}
        >
          {"Ok"}
        </DialogButton>
      </DialogActions>
    </StyledDialog>
  );
}
