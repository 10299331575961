import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { selectIsLoadingDetailsMetaData } from "../../../../app/slices/documentDetailsSlice";
import {
  selectCurrentDocumentAttachment,
  setEditedAttachmentName,
} from "../../../../app/slices/documentMetadataSlice";
import {
  setFileNameValidationStatus,
  setIsDraftValidationOn,
} from "../../../../app/slices/documentMetadataValidationSlice";
import { ValidationStatus } from "../../../../models/validationRule";
import { MultiText } from "../../inputs/MultiText";
import { selectIsFileUploadAborted } from "../../../../app/slices/fileUploadSlice";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import useDocumentValidationRules from "../../../../app/hooks/document/useDocumentValidationRules";

interface FileNameControlProps {
  name: string;
  extension: string;
  isEditable: boolean;
  notEditableReason?: TooltipMsg;
}

export function FileNameControl(props: FileNameControlProps) {
  const dispatch = useAppDispatch();
  const isLoadingDetailsMetaData = useAppSelector(
    selectIsLoadingDetailsMetaData
  );
  const currentDocumentAttachment = useAppSelector(
    selectCurrentDocumentAttachment
  );

  const isFileUploadAborted = useAppSelector(selectIsFileUploadAborted);
  const { getFileNameValidationRules } = useDocumentValidationRules();

  const onFileNameChange = useCallback(
    (newValue: string) => {
      const newFileName = newValue.trimStart().trimEnd();

      dispatch(setEditedAttachmentName(newFileName + props.extension));
      dispatch(setIsDraftValidationOn(true));
    },
    [props.extension, dispatch]
  );

  const onInputValidation = useCallback(
    (result: ValidationStatus) => {
      dispatch(setFileNameValidationStatus(result));
    },
    [dispatch]
  );

  return (
    <MultiText
      disabled={currentDocumentAttachment.attachmentId === null}
      isLoading={isLoadingDetailsMetaData}
      isEditable={props.isEditable && !isFileUploadAborted}
      notEditableMessage={props.notEditableReason}
      id="document-details-attachment-file-name"
      labelText="File name"
      inputValue={props.name}
      isEdited={false}
      onChangeHandler={onFileNameChange}
      onInputValidation={onInputValidation}
      validationRules={getFileNameValidationRules(props.extension)}
    />
  );
}
