import { DialogActions, DialogContent } from "@mui/material";
import { StyledDialog } from "../../../controls/Dialog/SC/StyledDialog";
import { DialogButton } from "../../../controls/Dialog/SC/DialogButton";
import { StyledDialogTitle } from "../../../controls/Dialog/SC/StyledDialogTitle";
import { IconButtonControl } from "../../../controls/Buttons/IconButtonControl";
import { IconButtonControlDiv } from "../contextMenu/SC/IconButtonControlDiv";
import CloseIcon from "@mui/icons-material/Close";
import { StyledDialogContentText } from "./SC/StyledDialogContentText";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { StyledHelpIcon } from "../sections/SectionHeader/controls/SC/StyledHelpIcon";
import { StyledSpan } from "./SC/StyledSpan";

export interface SelfHelpPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export function SelfHelpPopup(props: SelfHelpPopupProps) {
  return (
    <StyledDialog
      id={"set-selfhelp-dialog"}
      open={props.isOpen}
      onClose={props.onClose}
    >
      <StyledDialogTitle id={"set-selfhelp-dialog-title"}>
        {"Mandatory training"}
        <IconButtonControlDiv>
          <IconButtonControl
            id={"set-selfhelp-dialog-close-button"}
            popupText={TooltipMsg.Close}
            onClick={props.onClose}
            icon={<CloseIcon fontSize="inherit" />}
          />
        </IconButtonControlDiv>
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText id="set-selfhelp-dialog-description">
          Before you can start publishing, you have to pass mandatory training.
          The training is in the form of self-help materials on document detail
          page. Please complete all sections and publishing will become
          unlocked. Mandatory sections are marked{" "}
          <StyledSpan color="red">red</StyledSpan>{" "}
          <StyledHelpIcon $marginBottom={-4} $colorNew="red" $cursor="auto" />.
          Optional sections are <StyledSpan color="blue">blue</StyledSpan>{" "}
          <StyledHelpIcon $marginBottom={-4} $colorNew="blue" $cursor="auto" />.
          Completed sections are marked{" "}
          <StyledSpan color="grey">grey</StyledSpan>{" "}
          <StyledHelpIcon $marginBottom={-4} $colorNew="grey" $cursor="auto" />.
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogButton
          id={"set-selfhelp-dialog-close-button"}
          color="primary"
          onClick={props.onClose}
        >
          {"Ok"}
        </DialogButton>
      </DialogActions>
    </StyledDialog>
  );
}
