import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import { HeaderDiv } from "../SC/HeaderDiv";
import { HeaderEndAdornmentDiv } from "../../publishers/tabs/SC/HeaderEndAdornmentDiv";
import { SaveChangesButton } from "./buttons/SaveButton/SaveChangesButton";
import { LinearProgressDiv } from "../../../controls/ProgressIndicators/SC/LinearProgressDiv";
import { LinearProgressBar } from "../../../controls/ProgressIndicators/LinearProgressBar";
import {
  isSomeValidationErrors,
  selectIsEditable,
  selectIsEdited,
  selectIsLoaderVisible,
  selectSelectedCategoryId,
  selectSelectedCategoryName,
  setSelectedCategoryId,
  setSelectedCategoryName,
} from "../../../app/slices/publisherCategoriesSettingsSlice";
import { CloseButton } from "./buttons/CloseButton/CloseButton";
import { DiscardChangesButton } from "./buttons/DiscardChanges/DiscardChangesButton";
import { useSaveSettingsHandler } from "../../../app/hooks/settings/useSaveSettingsHandler";
import { SelfHelpButtonControl } from "../../../controls/Buttons/SelfHelpButtonControl";
import { SectionName } from "../../../app/slices/models/documentDetailsModels";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../models/selfHelp/selfHelpSection";
import { CategoryHeaderDiv } from "./SC/CategoryHeaderDiv";
import { useEffect } from "react";
import { SelfHelpButtonControlSpan } from "./SC/SelfHelpButtonControlSpan";

export enum SettingsTagType {
  Tags = "Tags",
  Settings = "Settings",
}

interface HeaderProps {
  isLoading: boolean;
}

export function Header(props: HeaderProps) {
  const isLoaderVisible = useAppSelector(selectIsLoaderVisible);
  const isEdited = useAppSelector(selectIsEdited);
  const isEditable = useAppSelector(selectIsEditable);
  const isSomeValidationError = useAppSelector(isSomeValidationErrors);
  const { saveSettingsHandler, isSaving } = useSaveSettingsHandler();
  const categoryName = useAppSelector(selectSelectedCategoryName);
  const categoryId = useAppSelector(selectSelectedCategoryId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(setSelectedCategoryId(""));
      dispatch(setSelectedCategoryName(""));
    };
  }, [dispatch]);

  return (
    <>
      <HeaderDiv>
        <CategoryHeaderDiv disabled={!isEditable}>
          {categoryName && `${categoryName} (CID: ${categoryId})`}
        </CategoryHeaderDiv>
        <HeaderEndAdornmentDiv id="cms-header-buttons-div">
          <SaveChangesButton
            saveSettingsHandler={saveSettingsHandler}
            isLoading={isSaving || props.isLoading}
            isEdited={isEdited}
            permissionMissing={!isEditable}
            isErrors={isSomeValidationError}
          />
          <DiscardChangesButton
            isLoading={isSaving || props.isLoading}
            isEdited={isEdited}
            permissionMissing={!isEditable}
            isErrors={isSomeValidationError}
          />
          <CloseButton />
          <SelfHelpButtonControlSpan>
            <SelfHelpButtonControl
              currentSelfHelp={CurrentSelfHelp.CmsMenu}
              page={SelfHelpPage.Cms}
              section={SectionName.CmsMenu}
            />
          </SelfHelpButtonControlSpan>
        </HeaderEndAdornmentDiv>
      </HeaderDiv>
      <LinearProgressDiv
        data-active={isLoaderVisible}
        $isVisible={isLoaderVisible}
        id="context-menu-settings-linear-progress-div"
      >
        <LinearProgressBar />
      </LinearProgressDiv>
    </>
  );
}
