import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";

export const noEditorRightsText =
  "You do not have Publisher rights in the document's Main Category.";

export const getButtonDisabledTooltipText = (
  isEditor: boolean,
  approvalRequired: boolean,
  isEditBlockedForPendingApproval: boolean,
  hasPendingApproval = false,
  hasCategoriesWithoutApproval = false
) => {
  if (!isEditor) {
    return noEditorRightsText;
  }

  if (hasPendingApproval) {
    return TooltipMsg.PendingApproval;
  }

  if (approvalRequired) {
    return TooltipMsg.NoApproverRights;
  }

  if (isEditBlockedForPendingApproval) {
    return TooltipMsg.CannotApprovePendingApproval;
  }

  if (hasCategoriesWithoutApproval) {
    return TooltipMsg.CategoriesWithoutApproval;
  }
};
