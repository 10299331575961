import { SecurityLevelPill } from "../../../../../components/documents/documentsList/documentListItem/pills/SecurityLevelPill";
import { DocumentSecurityLevel } from "../../../../../models/documentSecurityLevel";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { StyledLi } from "../../../SC/StyledLi";

export function SecurityLevelColumnStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The &quot;Security&quot; column displays combinations of security level
        icons that describe the access levels to documents.
      </Paragraph>
      <Paragraph>
        These icons represent different access levels:
        <ul>
          <StyledLi>
            <SecurityLevelPill
              securityLevel={
                DocumentSecurityLevel.ExternalPublicAndInternalPublic
              }
              isInternal={false}
              isGray={false}
            />
            <span>(External Public)</span>
          </StyledLi>
          <StyledLi>
            <SecurityLevelPill
              securityLevel={
                DocumentSecurityLevel.HiddenFromExternalAndInternalPublic
              }
              isInternal={false}
              isGray={false}
            />
            <span>(Hidden from External Interfaces)</span>
          </StyledLi>
          <StyledLi>
            <SecurityLevelPill
              securityLevel={
                DocumentSecurityLevel.ExternalLimitedAndInternalPublic
              }
              isInternal={false}
              isGray={false}
            />
            <span>(External Limited)</span>
          </StyledLi>
          <StyledLi>
            <SecurityLevelPill
              securityLevel={
                DocumentSecurityLevel.ExternalPublicAndInternalPublic
              }
              isInternal={true}
              isGray={false}
            />
            <span>(Internal Public)</span>
          </StyledLi>
          <StyledLi>
            <SecurityLevelPill
              securityLevel={DocumentSecurityLevel.InternalLimited}
              isInternal={true}
              isGray={false}
            />
            <span>(Internal Limited)</span>
          </StyledLi>
        </ul>
      </Paragraph>
    </StepContentDiv>
  );
}
