import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PublishersTabType } from "../../components/publishers/Publishers";
import { Person } from "../../models/person";
import { publishersViewLocalStorageKey } from "../hooks/localStorage";
import { RootState } from "../store";

export interface PublishersViewParams {
  navigationFlex: number;
  publishersFlex: number;
}

export interface PublishersViewState {
  viewState: PublishersViewParams;
  tab: PublishersTabType;
  selectedPublisher?: Person;
}

const defaultSplitViewParams: PublishersViewParams = {
  navigationFlex: 0.2,
  publishersFlex: 0.8,
};

export function getInitialParams() {
  try {
    const value = localStorage.getItem(publishersViewLocalStorageKey);
    if (value) {
      return JSON.parse(value) as PublishersViewParams;
    }

    return defaultSplitViewParams;
  } catch {
    return defaultSplitViewParams;
  }
}

const initialState: PublishersViewState = {
  viewState: getInitialParams(),
  tab: PublishersTabType.Category,
};

export const publishersViewSlice = createSlice({
  name: "publishersView",
  initialState: initialState,
  reducers: {
    setPublishersTabType(state, action: PayloadAction<PublishersTabType>) {
      state.tab = action.payload;
    },
    setNavigationFlex(state, action: PayloadAction<number>) {
      state.viewState.navigationFlex = action.payload;
    },
    setPublishersFlex(state, action: PayloadAction<number>) {
      state.viewState.publishersFlex = action.payload;
    },
    setSelectedPublisher(state, action: PayloadAction<Person | undefined>) {
      state.selectedPublisher = action.payload;
    },
  },
});

export const {
  setNavigationFlex,
  setPublishersFlex,
  setPublishersTabType,
  setSelectedPublisher,
} = publishersViewSlice.actions;

export const selectPublishersFlex = (state: RootState) =>
  state.publishersView.viewState.publishersFlex;

export const selectNavigationFlex = (state: RootState) =>
  state.publishersView.viewState.navigationFlex;

export const selectPublishersTabType = (state: RootState) =>
  state.publishersView.tab;

export const selectSelectedPublisher = (state: RootState) =>
  state.publishersView.selectedPublisher;

export default publishersViewSlice.reducer;
