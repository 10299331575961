import { useTheme } from "styled-components";
import { TextButtonControl } from "../../../controls/Buttons/TextButtonControl";
import { ButtonDiv } from "../../documents/documentsHeader/SC/ButtonDiv";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { DialogControl } from "../../../controls/Dialog/DialogControl";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { useLazyGetPublishersEmailsQuery } from "../../../apis/publishersApi";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import { selectSelectedCategoryId } from "../../../app/slices/publishersListSlice";
import { CircularProgressBar } from "../../../controls/ProgressIndicators/CircularProgressBar";
import StyledErrorSpan from "../../../app/errorHandling/SC/StyledErrorSpan";
import StyledSpan from "../../../app/errorHandling/SC/StyledSpan";
import RefreshIcon from "@mui/icons-material/Refresh";
import { StyledErrorIcon } from "../../categoryNavigation/SC/StyledErrorIcon";
import { ErrorDiv } from "../SC/ErrorDix";
import { LoadingDiv } from "../SC/LoadingDiv";
import { EmailsDiv } from "../SC/EmailsDiv";
import { openSnackbar } from "../../../app/helpers/snackBarHelper";
import { SnackbarType } from "../../../models/snackbar";
import { StyledDialogContentText } from "../../details/selfHelpPopup/SC/StyledDialogContentText";
import { SnackbarMsg } from "../../../controls/Snackbar/SnackbarMessages";

export function ExportEmailsButton() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const categoryId = useAppSelector(selectSelectedCategoryId);

  const [getPublisherEmails, { data, isLoading, isError, isFetching }] =
    useLazyGetPublishersEmailsQuery();

  const onClickHandler = () => {
    setEmails([]);
    openDialog();
    getEmails();
  };

  const openDialog = () => {
    setIsOpen(true);
  };

  const getEmails = () => {
    if (categoryId) {
      void getPublisherEmails(categoryId);
    }
  };

  const confirmHandler = () => {
    void navigator.clipboard.writeText(emails.join("; "));
    openSnackbar(dispatch, SnackbarMsg.ExportEmailsSuccess, SnackbarType.success);
    setIsOpen(false);
  };

  let content = <></>;

  if (isError) {
    content = (
      <ErrorDiv>
        <StyledErrorSpan>
          <StyledErrorIcon color="error" fontSize="inherit" />
        </StyledErrorSpan>
        <StyledSpan>
          Something go wrong, try fresh the page or contact to Administrator
        </StyledSpan>
        <IconButton onMouseDown={getEmails} size="small">
          <RefreshIcon color="error" fontSize="inherit" />
        </IconButton>
      </ErrorDiv>
    );
  }

  if (isLoading || isFetching) {
    content = (
      <LoadingDiv>
        <CircularProgressBar
          size={theme.circularProgress.medium}
          space={theme.circularProgressWrapper.defaultHeight}
          color="secondary"
        />
      </LoadingDiv>
    );
  }

  if (data && !isLoading && !isFetching && !isError) {
    content = (
      <StyledDialogContentText id="alert-dialog-description">
        <>
          This is a list of email addresses of all publishers in selected
          category, including publishers with inherited access and publishers
          from subcategories:
        </>
        <EmailsDiv>{emails.map((e) => e + "; ")}</EmailsDiv>
      </StyledDialogContentText>
    );
  }

  useEffect(() => {
    if (data && data.length > 0) {
      setEmails(data);
    }
  }, [data, setEmails, isFetching]);

  return (
    <ButtonDiv>
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.primaryButtonHeight}
        text={"Emails export"}
        icon={<AssignmentIcon fontSize="small" />}
        id="header-action-emails-data-export"
        tooltipText="Export all emails related to this category"
        onClick={onClickHandler}
        isCompactView={false}
        isVertical={false}
        isVisible={true}
      />
      <DialogControl
        id="emails-data-export"
        title="Emails export"
        onConfirmClick={confirmHandler}
        onCloseClick={() => {
          setIsOpen(false);
        }}
        maxWidth="md"
        useActions={true}
        isOpen={isOpen}
        confirmText="Copy"
        content={content}
        fullHeight={false}
        fullWidth={true}
        padding="0"
        margin="20px"
      />
    </ButtonDiv>
  );
}
