import { ValidationStatus } from "../../models/validationRule";

export default function useValidationRules() {
  const getTagValidationRule = {
    regExp: new RegExp(
      `^[a-zA-Z0-9\\-_()\\./\\*,\\s]*[a-zA-Z0-9 \\-_()\\./\\*,\\s]*[a-zA-Z0-9\\-_()\\./\\*,\\s]*$`
    ),
    message: "Tag value contains illegal characters",
    validationType: ValidationStatus.error,
  };
  return {
    getTagValidationRule,
  };
}
