import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function DocumentListHeaderStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        Documents can be sorted by Publish date, Number, and Title by clicking
        the column name.
      </Paragraph>
      <Paragraph>
        By default, documents are sorted by Publish date, from newest to oldest.
      </Paragraph>
    </StepContentDiv>
  );
}
