import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { ContextMenuStepProps } from "./models";
import PostAddIcon from "@mui/icons-material/PostAdd";

export function CreateStep(props: ContextMenuStepProps) {
    const theme = useTheme();

    return (
        <StepContentDiv>
            <Paragraph>
                {!props.isVisible && <><PostAddIcon style={{
                    fontSize: "16px",
                    verticalAlign: "middle",
                    color: theme.palettes.grayscale.dark
                }} /><b> CREATE</b> - </>}
                The &quot;Create&quot; button is utilized to generate new documents based on the currently opened one. The following actions are associated with the &quot;Create&quot; button:
                <ul>
                    <li>New revision - This action opens a pre-filled template for creating a new document revision.</li>
                    <li>New part version - This action opens a pre-filled template for creating a new document part version.</li>
                    <li>New language version - This action opens a pre-filled template for creating a new document language version.</li>
                    <li>New from template - This action opens a pre-filled template with most fields copied from the original document.</li>
                </ul>
            </Paragraph>
        </StepContentDiv>
    );
}
