import { useCallback, useEffect, useRef } from "react";
import { useLazyGetMatchingPublisherCategoriesQuery } from "../../apis/publisherCategoriesApi";
import { rootCategoryId } from "../slices/navigationSlice";
import {
  clearPublisherCategories,
  selectCategoryById,
  updatePublisherCategories,
} from "../slices/publisherCategoryTreeSlice";
import { useAppDispatch, useAppSelector } from "./hooks";
import {
  FetchPublisherCategoriesProps,
  PublisherCategoriesFetchResponse,
  publishersAbortReason,
} from "./useInitialPublisherCategoriesFetch";

export function useMatchingPublisherCategoriesFetch({
  query,
  visibleQuery,
  minQueryLength,
  isOpen,
}: FetchPublisherCategoriesProps): PublisherCategoriesFetchResponse {
  const dispatch = useAppDispatch();
  const [
    getPublisherCategories,
    { isError, isFetching, isUninitialized, isSuccess, error },
  ] = useLazyGetMatchingPublisherCategoriesQuery();
  const rootCategory = useAppSelector((state) =>
    selectCategoryById(state, rootCategoryId)
  );
  const abortControllerRef = useRef<AbortController>(new AbortController());

  const cancel = useCallback(() => {
    abortControllerRef.current.abort(publishersAbortReason);
    abortControllerRef.current = new AbortController();
  }, []);

  const fetchCategories = useCallback(() => {
    if (
      isOpen &&
      visibleQuery &&
      minQueryLength &&
      query.length >= minQueryLength &&
      rootCategory === undefined
    ) {
      getPublisherCategories(
        {
          query,
          signal: abortControllerRef.current.signal,
        },
        true
      )
        .unwrap()
        .then((response) => {
          dispatch(updatePublisherCategories(response));
        })
        .catch(() => {
          dispatch(clearPublisherCategories());
        });
    }
  }, [
    getPublisherCategories,
    query,
    visibleQuery,
    rootCategory,
    minQueryLength,
    isOpen,
    dispatch,
  ]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return {
    isLoading: isFetching || isUninitialized,
    isError,
    isSuccess,
    fetch: fetchCategories,
    cancel,
    error,
  };
}
