import { createStep } from "../../helpers/selfHelpBuilder";
import { CategoryTreeBoxStep1 } from "./Navigation/CategoryTreeBoxStep1";
import { CategoryTreeBoxStep2 } from "./Navigation/CategoryTreeBoxStep2";
import { CollapseStep } from "./Navigation/CollapseStep";
import { FirstCheckboxStep } from "./Navigation/FirstCheckboxStep";
import { HorizontalSplitterStep } from "./Navigation/HorizontalSplitterStep";
import { SecondCheckboxStep } from "./Navigation/SecondCheckboxStep";
import { SectionStep } from "./Navigation/SectionStep";
import { ThirdCheckboxStep } from "./Navigation/ThirdCheckboxStep";
import { VerticalSplitterStep } from "./Navigation/VerticalSplitterStep";

export const navigationSteps = () => {
  return [
    createStep("#CategoryTreeNavigation", <SectionStep />, 420, "right"),
    createStep("#navigation-collapse-button", <CollapseStep />, 320, "right"),
    createStep(
      "#dashboard-vertical-splitter",
      <VerticalSplitterStep />,
      320,
      "right"
    ),
    createStep(
      "#dashboard-horizontal-splitter",
      <HorizontalSplitterStep />,
      320,
      "right"
    ),
    createStep("#CategoryTreeBox", <CategoryTreeBoxStep1 />, 520, "right"),
    createStep("#CategoryTreeBox", <CategoryTreeBoxStep2 />, 520, "right"),
    createStep(
      "#category-tree-include-documents-from-subcategories-label",
      <FirstCheckboxStep />,
      420,
      "right"
    ),

    createStep(
      "#category-tree-include-documents-i-cannot-manage-label",
      <SecondCheckboxStep />,
      420,
      "right"
    ),
    createStep(
      "#category-tree-show-only-uncategorized-documents-label",
      <ThirdCheckboxStep />,
      420,
      "right"
    ),
  ];
};
