import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { filter } from "lodash";
import { DocumentsColumnType } from "../../models/documentList/types/DocumentColumnType";
import { documentListViewLocalStorageKey } from "../hooks/localStorage";
import {
  getMinColumnWidth,
  getDefaultColumnWidth,
  getMaxWidthColumn,
  resizeColumnsInternal,
  getNextLeftColumn,
  selectFullRowWidth,
  selectTitleRowWidthForGroup,
} from "../services/documentsService";
import { RootState } from "../store";
import {
  defaultDocumentColumns,
  DocumentsColumn,
  ColumnWidthChange,
} from "./models/documentsModels";

const defaultSplitViewParams: DocumentListViewParams = {
  columns: defaultDocumentColumns,
};

export interface DocumentListViewParams {
  columns: DocumentsColumn[];
}

export interface DocumentListViewState {
  columns: DocumentsColumn[];
  hasAnyDocumentOnView: boolean;
  hasAnyGroupOnView: boolean;
  hasAnySingleDocumentOnView: boolean;
}

export function getInitialParams() {
  try {
    const value = localStorage.getItem(documentListViewLocalStorageKey);
    if (value) {
      return JSON.parse(value) as DocumentListViewParams;
    }

    return defaultSplitViewParams;
  } catch {
    return defaultSplitViewParams;
  }
}

const initialState: DocumentListViewState = {
  ...getInitialParams(),
  hasAnyDocumentOnView: false,
  hasAnyGroupOnView: false,
  hasAnySingleDocumentOnView: false,
};

export const documentListViewSlice = createSlice({
  name: "documentListView",
  initialState,
  reducers: {
    setHasAnyDocumentOnView(
      state: DocumentListViewState,
      action: PayloadAction<boolean>
    ) {
      state.hasAnyDocumentOnView = action.payload;
    },
    setHasAnyDocumentGroupOnView(
      state: DocumentListViewState,
      action: PayloadAction<boolean>
    ) {
      state.hasAnyGroupOnView = action.payload;
    },
    setHasAnySingleDocumentOnView(
      state: DocumentListViewState,
      action: PayloadAction<boolean>
    ) {
      state.hasAnySingleDocumentOnView = action.payload;
    },
    resetColumns(state: DocumentListViewState) {
      state.columns = defaultDocumentColumns;
    },
    setColumnWidth(
      state: DocumentListViewState,
      action: PayloadAction<ColumnWidthChange>
    ) {
      const minColumnWidth = getMinColumnWidth(action.payload.type);
      const column = state.columns.first((x) => x.type === action.payload.type);
      const newColumnWidth = column.size + action.payload.resizeValue;
      column.size =
        newColumnWidth < minColumnWidth ? minColumnWidth : newColumnWidth;
    },
    setDefaultColumnWidth(
      state: DocumentListViewState,
      action: PayloadAction<{
        type: DocumentsColumnType;
        fitToScreen: boolean;
      }>
    ) {
      const column = state.columns.first((x) => x.type === action.payload.type);
      const defaultWidth = getDefaultColumnWidth(action.payload.type);

      if (!action.payload.fitToScreen) {
        column.size = defaultWidth;
        return;
      }

      //state: fit to screen
      let widthChange = defaultWidth - column.size;

      //make it smaller
      if (widthChange < 0) {
        if (column.type == DocumentsColumnType.Title) {
          return;
        } else {
          column.size += widthChange;
          const titleColumn = state.columns.first(
            (x) => x.type === DocumentsColumnType.Title
          );
          titleColumn.size -= widthChange;
        }
        return;
      }

      //make it bigger
      let otherColumns = filter(
        state.columns,
        (col) => col.type != column.type
      );
      let maxSizeColumn = getMaxWidthColumn(otherColumns);

      //enough space in max size column
      if (widthChange < maxSizeColumn.size - maxSizeColumn.minSize) {
        column.size += widthChange;
        maxSizeColumn.size = maxSizeColumn.size - widthChange;
        widthChange = 0;
      } //not enough space in max size column
      else {
        //stop when resize is done or all other columns are on min width
        while (widthChange > 0 || otherColumns.length == 0) {
          const newMaxColumnSize = maxSizeColumn.size - widthChange;
          if (newMaxColumnSize < maxSizeColumn.minSize) {
            const currentWidthChange =
              maxSizeColumn.size - maxSizeColumn.minSize;
            maxSizeColumn.size = maxSizeColumn.minSize;
            column.size += currentWidthChange;
            widthChange = widthChange - currentWidthChange;
          } else {
            maxSizeColumn.size -= widthChange;
            column.size += widthChange;
            widthChange = 0;
          }

          //update values for next iteration
          otherColumns = filter(
            otherColumns,
            (col) => col.type != maxSizeColumn.type
          );
          maxSizeColumn = getMaxWidthColumn(otherColumns);
        }
      }
    },
    setColumnVisibility(
      state: DocumentListViewState,
      action: PayloadAction<{
        columnType: DocumentsColumnType;
        visible: boolean;
      }>
    ) {
      let columnToChange = state.columns.first(
        (x) => x.type === action.payload.columnType
      );
      const titleColumn = state.columns.first(
        (x) => x.type === DocumentsColumnType.Title
      );

      columnToChange.isVisible = action.payload.visible;
      if (!action.payload.visible) {
        // when column is hidden use spare space to upsize title
        titleColumn.size += columnToChange.size;
        return;
      }
      // when column is shown, upsize it to the right and then to the left if needed
      columnToChange.size = 0;
      resizeColumnsInternal(
        state.columns,
        columnToChange.type,
        columnToChange.minSize
      );
      const columnToTheLeft = getNextLeftColumn(
        state.columns,
        columnToChange.type
      );
      if (columnToTheLeft === null) {
        return;
      }
      columnToChange = state.columns.first(
        (x) => x.type === action.payload.columnType
      );

      resizeColumnsInternal(
        state.columns,
        columnToTheLeft.type,
        columnToChange.size - columnToChange.minSize
      );
    },
    setDocumentColumn(
      state: DocumentListViewState,
      action: PayloadAction<DocumentsColumn[]>
    ) {
      state.columns = action.payload;
    },
    fitColumnsToScreen(
      state: DocumentListViewState,
      action: PayloadAction<number>
    ) {
      const availableWidth: number = action.payload;
      let index = state.columns.length - 1;
      let currentColumnWidth: number = selectFullRowWidth(state.columns);
      let currentColumn = getMaxWidthColumn(state.columns);
      while (currentColumnWidth !== availableWidth) {
        //increase width
        if (currentColumnWidth < availableWidth) {
          const missingWidth = availableWidth - currentColumnWidth;

          //title column gets all (new) width
          const titleColumn = state.columns.first(
            (x) => x.type === DocumentsColumnType.Title
          );

          titleColumn.size += missingWidth;
        } //decrease width
        else {
          if (currentColumn.isResizable) {
            const excessWidth = currentColumnWidth - availableWidth;

            if (currentColumn.size - excessWidth < currentColumn.minSize) {
              currentColumn.size = currentColumn.minSize;
            } else {
              currentColumn.size -= excessWidth;
            }
          }
        }

        //update vars
        index -= 1;

        //nothing more to resize
        if (index < 0) {
          return;
        }
        currentColumnWidth = selectFullRowWidth(state.columns);
        currentColumn = state.columns[index];
      }
    },
    resizeColumns(state, action: PayloadAction<ColumnWidthChange>) {
      resizeColumnsInternal(
        state.columns,
        action.payload.type,
        action.payload.resizeValue
      );
    },
  },
});

export const {
  setColumnWidth,
  setDefaultColumnWidth,
  setColumnVisibility,
  setDocumentColumn,
  resizeColumns,
  resetColumns,
  fitColumnsToScreen,
  setHasAnyDocumentOnView,
  setHasAnyDocumentGroupOnView,
  setHasAnySingleDocumentOnView,
} = documentListViewSlice.actions;

export const selectHasAnyDocumentOnView = (state: RootState) => {
  return state.documentListView.hasAnyDocumentOnView;
};

export const selectHasAnyDocumentGroupOnView = (state: RootState) => {
  return state.documentListView.hasAnyGroupOnView;
};

export const selectHasAnySingleDocumentOnView = (state: RootState) => {
  return state.documentListView.hasAnySingleDocumentOnView;
};

export const selectColumnWidth = (state: RootState) => {
  return selectFullRowWidth(state.documentListView.columns);
};
export const selectDocumentColumn = (state: RootState) =>
  state.documentListView.columns;

export const selectTitleWidthForGroup = (state: RootState) => {
  return selectTitleRowWidthForGroup(state.documentListView.columns);
};
export const selectVisibleColumnTypes = (state: RootState) => {
  return filter(state.documentListView.columns, (column: DocumentsColumn) => {
    return column.isVisible;
  }).map((column) => {
    return column.type;
  });
};

export default documentListViewSlice.reducer;
