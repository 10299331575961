import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { navigationSplitViewParamsLocalStorageKey } from "../hooks/localStorage";
import { RootState } from "../store";
import { DocumentSecurityLevel } from "../../models/documentSecurityLevel";
import { Workflow } from "../../models/documentList/types/workflow";
import { ComboBoxListItem } from "../../models/autocompleteValue";

const defaultNavigationParams = {
  filtersFlex: 0.55,
  isMinimized: false,
};

export interface NavigationFiltersState {
  categoryId: string;
  searchDocumentNumber: string;
  searchDocumentTitle: string;
  securityLevels: DocumentSecurityLevel[];
  documentKindIds?: number[];
  publisherIds?: string[];
  accessGroupIds?: number[];
  documentLanguageCodes?: string[];
  workflows: Workflow[];
  publishedAfter?: string;
  publishedBefore?: string;
}

interface CurrentNavigationFiltersState {
  currentDocumentNumber: string;
  currentDocumentTitle: string;
  currentDocumentKinds: ComboBoxListItem[];
  currentLanguages: ComboBoxListItem[];
  currentPublishers: ComboBoxListItem[];
  currentAccessGroups: ComboBoxListItem[];
}

export interface NavigationSplitViewState {
  filtersFlex: number;
  isMinimized: boolean;
}

export interface NavigationSplitViewParams {
  filtersFlex: number;
  isMinimized: boolean;
}

export function getInitialSplitViewParams() {
  try {
    const value = localStorage.getItem(
      navigationSplitViewParamsLocalStorageKey
    );
    if (value) {
      return JSON.parse(value) as NavigationSplitViewParams;
    }

    return defaultNavigationParams;
  } catch {
    return defaultNavigationParams;
  }
}

export interface NavigationIncludesDocumentsState {
  includeDocumentsFromSubcategories: boolean;
  includeDocumentsICannotManage: boolean;
  showOnlyUnCategorizedDocuments: boolean;
}

export interface NavigationState {
  filters: NavigationFiltersState;
  splitView: NavigationSplitViewState;
  includeDocuments: NavigationIncludesDocumentsState;
  currentFilters: CurrentNavigationFiltersState;
}

export const categoriesMinHeight = 240;
export const filtersMinHeight = 120;
export const categorySkeletonRowHeight = 24;
export const rootCategoryId = "Root";

export const filtersInitialState: NavigationFiltersState = {
  categoryId: rootCategoryId,
  accessGroupIds: undefined,
  documentKindIds: undefined,
  documentLanguageCodes: undefined,
  searchDocumentNumber: "",
  searchDocumentTitle: "",
  publishedAfter: undefined,
  publishedBefore: undefined,
  publisherIds: undefined,
  securityLevels: [
    DocumentSecurityLevel.ExternalLimitedAndInternalLimited,
    DocumentSecurityLevel.ExternalLimitedAndInternalPublic,
    DocumentSecurityLevel.ExternalPublicAndInternalPublic,
    DocumentSecurityLevel.InternalLimited,
    DocumentSecurityLevel.InternalPublic,
    DocumentSecurityLevel.HiddenFromExternalAndInternalPublic,
  ],
  workflows: [Workflow.Approval, Workflow.Draft, Workflow.Published],
};

export const currentFiltersInitialState: CurrentNavigationFiltersState = {
  currentDocumentNumber: "",
  currentDocumentTitle: "",
  currentDocumentKinds: [],
  currentLanguages: [],
  currentPublishers: [],
  currentAccessGroups: [],
};
const includeDocuments: NavigationIncludesDocumentsState = {
  includeDocumentsFromSubcategories: true,
  includeDocumentsICannotManage: true,
  showOnlyUnCategorizedDocuments: false,
};

const initialState = {
  filters: filtersInitialState,
  includeDocuments: includeDocuments,
  splitView: getInitialSplitViewParams(),
  currentFilters: currentFiltersInitialState,
} as NavigationState;

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setIsNavigationMinimized(
      state: NavigationState,
      action: PayloadAction<boolean>
    ) {
      state.splitView.isMinimized = action.payload;
    },
    setFiltersFlex(state: NavigationState, action: PayloadAction<number>) {
      state.splitView.filtersFlex = action.payload;
    },
    resetNavigationSplitView(state: NavigationState) {
      state.splitView.filtersFlex = defaultNavigationParams.filtersFlex;
    },
    setIncludeDocumentsFromSubcategories(
      state: NavigationState,
      action: PayloadAction<boolean>
    ) {
      state.includeDocuments.includeDocumentsFromSubcategories = action.payload;
    },
    setIncludeDocumentsICannotManage(
      state: NavigationState,
      action: PayloadAction<boolean>
    ) {
      state.includeDocuments.includeDocumentsICannotManage = action.payload;
    },
    setShowOnlyUnCategorizedDocuments(
      state: NavigationState,
      action: PayloadAction<boolean>
    ) {
      state.includeDocuments.showOnlyUnCategorizedDocuments = action.payload;
    },
    setCategoryId(state: NavigationState, action: PayloadAction<string>) {
      state.filters.categoryId = action.payload;
    },
    setSearchDocumentTitleFilter(
      state: NavigationState,
      action: PayloadAction<string>
    ) {
      state.filters.searchDocumentTitle = action.payload;
    },
    setCurrentDocumentTitleFilter(
      state: NavigationState,
      action: PayloadAction<string>
    ) {
      state.currentFilters.currentDocumentTitle = action.payload;
    },
    setSearchDocumentNumberFilter(
      state: NavigationState,
      action: PayloadAction<string>
    ) {
      state.filters.searchDocumentNumber = action.payload;
    },
    setCurrentDocumentNumberFilter(
      state: NavigationState,
      action: PayloadAction<string>
    ) {
      state.currentFilters.currentDocumentNumber = action.payload;
    },
    setSecurityLevelsFilter(
      state: NavigationState,
      action: PayloadAction<DocumentSecurityLevel[]>
    ) {
      state.filters.securityLevels = action.payload;
    },
    setDocumentKindsFilter(
      state: NavigationState,
      action: PayloadAction<number[]>
    ) {
      state.filters.documentKindIds = action.payload;
    },
    setPublishersFilter(
      state: NavigationState,
      action: PayloadAction<string[]>
    ) {
      state.filters.publisherIds = action.payload;
    },
    setAccessGroupsFilter(
      state: NavigationState,
      action: PayloadAction<number[]>
    ) {
      state.filters.accessGroupIds = action.payload;
    },
    setDocumentLanguageCodesFilter(
      state: NavigationState,
      action: PayloadAction<string[]>
    ) {
      state.filters.documentLanguageCodes = action.payload;
    },
    setPublishedFromFilter(
      state: NavigationState,
      action: PayloadAction<string | undefined>
    ) {
      state.filters.publishedAfter = action.payload;
    },
    setPublishedToFilter(
      state: NavigationState,
      action: PayloadAction<string | undefined>
    ) {
      state.filters.publishedBefore = action.payload;
    },
    setWorkflowsFilter(
      state: NavigationState,
      action: PayloadAction<Workflow[]>
    ) {
      state.filters.workflows = action.payload;
    },
    setCurrentDocumentKindsFilter(
      state: NavigationState,
      action: PayloadAction<ComboBoxListItem[]>
    ) {
      state.currentFilters.currentDocumentKinds = action.payload;
    },
    setCurrentLanguagesFilter(
      state: NavigationState,
      action: PayloadAction<ComboBoxListItem[]>
    ) {
      state.currentFilters.currentLanguages = action.payload;
    },
    setCurrentPublishersFilter(
      state: NavigationState,
      action: PayloadAction<ComboBoxListItem[]>
    ) {
      state.currentFilters.currentPublishers = action.payload;
    },
    setCurrentAccessGroupsFilter(
      state: NavigationState,
      action: PayloadAction<ComboBoxListItem[]>
    ) {
      state.currentFilters.currentAccessGroups = action.payload;
    },
    resetFilters: (state: NavigationState) => {
      const currentCategory = state.filters.categoryId;
      state.filters = {
        ...filtersInitialState,
        categoryId: currentCategory,
      };
      state.currentFilters = currentFiltersInitialState;
    },
  },
});

export const {
  setFiltersFlex,
  resetNavigationSplitView,
  setAccessGroupsFilter,
  setDocumentKindsFilter,
  setCurrentDocumentNumberFilter,
  resetFilters,
  setCurrentDocumentTitleFilter,
  setSearchDocumentNumberFilter,
  setSearchDocumentTitleFilter,
  setDocumentLanguageCodesFilter,
  setPublishedFromFilter,
  setPublishedToFilter,
  setPublishersFilter,
  setSecurityLevelsFilter,
  setWorkflowsFilter,
  setCategoryId,
  setIncludeDocumentsFromSubcategories,
  setIncludeDocumentsICannotManage,
  setShowOnlyUnCategorizedDocuments,
  setIsNavigationMinimized,
  setCurrentDocumentKindsFilter,
  setCurrentLanguagesFilter,
  setCurrentPublishersFilter,
  setCurrentAccessGroupsFilter,
} = navigationSlice.actions;

export const selectFiltersFlex = (state: RootState) =>
  state.navigation.splitView.filtersFlex;
export const selectIsNavigationMinimized = (state: RootState) =>
  state.navigation.splitView.isMinimized;
export const selectIncludeDocumentsFromSubcategories = (state: RootState) =>
  state.navigation.includeDocuments.includeDocumentsFromSubcategories;
export const selectIncludeDocumentsICannotManage = (state: RootState) =>
  state.navigation.includeDocuments.includeDocumentsICannotManage;
export const selectCategoryId = (state: RootState) =>
  state.navigation.filters.categoryId;
export const selectNavigationFilters = (state: RootState) =>
  state.navigation.filters;
export const selectWorkflowsFilter = (state: RootState) =>
  state.navigation.filters.workflows;
export const selectPublishedFromFilter = (state: RootState) =>
  state.navigation.filters.publishedAfter;
export const selectPublishedToFilter = (state: RootState) =>
  state.navigation.filters.publishedBefore;
export const selectCurrentDocumentNumberFilter = (state: RootState) =>
  state.navigation.currentFilters.currentDocumentNumber;
export const selectSearchDocumentNumberFilter = (state: RootState) =>
  state.navigation.filters.searchDocumentNumber;
export const selectCurrentDocumentTitleFilter = (state: RootState) =>
  state.navigation.currentFilters.currentDocumentTitle;
export const selectSearchDocumentTitleFilter = (state: RootState) =>
  state.navigation.filters.searchDocumentTitle;
export const selectSecurityLevelFilter = (state: RootState) =>
  state.navigation.filters.securityLevels;
export const selectDocumentKindsFilter = (state: RootState) =>
  state.navigation.filters.documentKindIds;
export const selectPublishersFilter = (state: RootState) =>
  state.navigation.filters.publisherIds;
export const selectLanguagesFilter = (state: RootState) =>
  state.navigation.filters.documentLanguageCodes;
export const selectAccessGroupsFilter = (state: RootState) =>
  state.navigation.filters.accessGroupIds;
export const selectShowOnlyUnCategorizedDocuments = (state: RootState) =>
  state.navigation.includeDocuments.showOnlyUnCategorizedDocuments;
export const selectCurrentDocumentKindsFilter = (state: RootState) =>
  state.navigation.currentFilters.currentDocumentKinds;
export const selectCurrentLanguagesFilter = (state: RootState) =>
  state.navigation.currentFilters.currentLanguages;
export const selectCurrentPublishersFilter = (state: RootState) =>
  state.navigation.currentFilters.currentPublishers;
export const selectCurrentAccessGroupsFilter = (state: RootState) =>
  state.navigation.currentFilters.currentAccessGroups;

export default navigationSlice.reducer;
