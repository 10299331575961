import ClearIcon from "@mui/icons-material/Clear";
import { TooltipTop } from "../Tooltips/ToolipTop";
import { TooltipMsg } from "../Tooltips/TooltipMessages";

interface ClearIconProps {
  multiple: boolean;
}

export function ClearIconControl(props: ClearIconProps) {
  if (props.multiple) {
    return (
      <TooltipTop title={TooltipMsg.Clear}>
        <ClearIcon fontSize="inherit" />
      </TooltipTop>
    );
  }

  return null;
}
