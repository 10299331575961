import { useAppSelector } from "../../../app/hooks/hooks";
import { selectUserEmail } from "../../../app/slices/authenticationSlice";
import { selectIsWindowTooSmall } from "../../../app/slices/commonSlice";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { CloseButton } from "./buttons/CloseButton";
import { PublishButton } from "./buttons/publish/PublishButton";
import { SaveAsDraftButton } from "./buttons/SaveAsDraftButton";
import { SendForApprovalButton } from "./buttons/SendForApprovalButton";
import { ShareUrlButton } from "./buttons/ShareURLButton";
import UnpublishedIcon from "@mui/icons-material/Unpublished";

interface NewDocumentButtonsProps {
    approvalRequired: boolean;
    hasCategoriesWithoutApproval: boolean;
    isNewDocumentRevision: boolean;
}

export function NewDocumentButtons(props: NewDocumentButtonsProps) {
    const isWindowToSmall = useAppSelector(selectIsWindowTooSmall);
    const userEmail = useAppSelector(selectUserEmail);

    return <>
        {props.approvalRequired && <SendForApprovalButton />}
        <PublishButton
            id="document-details-publish-button"
            color="primary"
            userEmail={userEmail}
            text="Publish"
            isCompactView={isWindowToSmall}
            disabled={props.approvalRequired || props.hasCategoriesWithoutApproval}
            disabledTooltipText={
                props.approvalRequired
                    ? TooltipMsg.NoApproverRights
                    : props.hasCategoriesWithoutApproval
                        ? TooltipMsg.CategoriesWithoutApproval
                        : undefined
            }
        />
        {props.isNewDocumentRevision && <PublishButton
            id="publish-do-not-change-publisher"
            color="secondary"
            userEmail=""
            text="Publish do not change publisher"
            disabled={props.approvalRequired || props.hasCategoriesWithoutApproval}
            disabledTooltipText={
                props.approvalRequired
                    ? TooltipMsg.NoApproverRights
                    : props.hasCategoriesWithoutApproval
                        ? TooltipMsg.CategoriesWithoutApproval
                        : undefined
            }
        />}
        <SaveAsDraftButton
            disabled={props.approvalRequired}
            disabledTooltipText={TooltipMsg.NoApproverRights}
        />
        <CloseButton
            text="Discard Draft"
            icon={<UnpublishedIcon fontSize="small" />}
            isCompactView={isWindowToSmall}
            id="document-details-discard-draft-button"
        />
        <ShareUrlButton isCompactView={isWindowToSmall} />
    </>
}