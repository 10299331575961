import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";


export function RevisionStepTwo() {
  return (
    <StepContentDiv>
      <Paragraph>
        In the Download Center, Download Section, and Document Download URL, the latest revision is displayed as the default.
      </Paragraph>
      <Paragraph>
        The revision can also be utilized in the document download URL to retrieve a specific revision.
      </Paragraph>
    </StepContentDiv>
  );
}
