import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function FiltersButtonStep5() {
    return (
        <StepContentDiv>
            <Paragraph>
                The list can be also filtered out by columns: Publishers, Super User, Approver, Owner.
            </Paragraph>
            <Paragraph>
                The filter role can be chosen as:
                <ul>
                    <li>
                        Assigned (role assigned to user in the category)
                    </li>
                    <li>
                        Not assigned (role not assigned to user in the category)
                    </li>
                </ul>
            </Paragraph>
            <Paragraph>
                By default, Assigned and Not Assigned are selected.
            </Paragraph>
        </StepContentDiv>
    );
}
