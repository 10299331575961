import { Typography } from "@mui/material";
import styled, { DefaultTheme } from "styled-components";
import {
  SecurityTypePremium,
  SecurityTypePublic,
} from "../../../../models/securityLevels";

interface StyledSecurityTypeProps {
  $documentSecurityLevel: string;
  $isHidden: boolean;
  $useFlex: boolean;
}

const getColor = (securityLevel: string, theme: DefaultTheme) => {
  if (securityLevel === SecurityTypePublic)
    return theme.palettes.secondary.public;
  if (securityLevel === SecurityTypePremium)
    return theme.palettes.secondary.premium;

  return theme.palettes.secondary.internal;
};

export const StyledSecurityType = styled(Typography) <StyledSecurityTypeProps>`
  color: ${(props) => getColor(props.$documentSecurityLevel, props.theme)};
  visibility: ${(props) => (props.$isHidden ? "hidden" : "visible")};
  padding-left: 10px;
  flex: ${(props) => (props.$useFlex ? "1 1 auto" : "unset")};
  text-align: right;
`;
