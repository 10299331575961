import ShareIcon from "@mui/icons-material/Share";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components";
import { useGetAppSettingsQuery } from "../../../../apis/configApi";
import { isDraft } from "../../../../app/helpers/documentsHelper";
import { useDocumentFlow } from "../../../../app/hooks/document/useDocumentFlow";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import {
  selectCurrentDocumentMetadata,
  selectDocumentMetadata,
  selectDocumentStatus,
  selectDocumentWorkflow,
} from "../../../../app/slices/documentMetadataSlice";
import {
  ActionButton,
  addVisibleButton,
  removeVisibleButton,
} from "../../../../app/slices/selfHelpSlice";
import { TextButtonControl } from "../../../../controls/Buttons/TextButtonControl";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import { DocumentUrlPopup } from "../../../popup/DocumentUrlPopup";
import { ButtonDiv } from "../SC/ButtonDiv";

interface ShareUrlButtonProps {
  disabled?: boolean;
  isCompactView?: boolean;
}

export function ShareUrlButton(props: ShareUrlButtonProps) {
  const dispatch = useAppDispatch();
  const { data: appSettings } = useGetAppSettingsQuery(null);
  const [isOpen, setIsOpen] = useState(false);
  const [documentUrlResponse, setDocumentUrlResponse] = useState("");
  const theme = useTheme();
  const workflow = useAppSelector(selectDocumentWorkflow);
  const identity = useAppSelector(selectDocumentMetadata).documentIdentity;
  const currentIdentity = useAppSelector(
    selectCurrentDocumentMetadata
  ).documentIdentity;
  const documentStatus = useAppSelector(selectDocumentStatus);

  const { isNewDocument } = useDocumentFlow();

  const generateNewDocumentUrl = useCallback(() => {
    if (appSettings?.singleDocumentBaseUrl) {
      let url = appSettings.singleDocumentBaseUrl;
      url += `?DocumentID=${encode(currentIdentity.documentNumber)}`;
      url += `&LanguageCode=${currentIdentity.documentLanguageCodes.join("&LanguageCode=")}`;
      url += `&DocumentPartId=${encode(currentIdentity.documentPart)}`;
      url += `&Action=Launch`;

      setDocumentUrlResponse(url);
    }
  }, [appSettings, currentIdentity]);

  const generateDocumentShareUrl = useCallback(() => {
    let url = appSettings?.singleDocumentBaseUrl ?? "";
    url += `?DocumentID=${encode(identity.documentNumber)}`;
    url += `&LanguageCode=${identity.documentLanguageCodes.join("&LanguageCode=")}`;
    url += `&DocumentPartId=${encode(identity.documentPart)}`;
    url += `&Action=Launch`;

    if (!documentStatus.isLatest)
      url += `&DocumentRevisionId=${identity.revision}`;

    setDocumentUrlResponse(url);
  }, [appSettings, identity, documentStatus]);

  const generateDocumentUrl = useCallback(() => {
    if (isNewDocument) {
      generateNewDocumentUrl();
    } else {
      generateDocumentShareUrl();
    }
  }, [isNewDocument, generateNewDocumentUrl, generateDocumentShareUrl]);

  const isEmptyDocumentNumber = useMemo(() => {
    return isNewDocument
      ? !currentIdentity.documentNumber
      : !identity.documentNumber;
  }, [isNewDocument, identity, currentIdentity]);

  const encode = (text: string) => encodeURIComponent(text);

  const shareUrlHandler = (event: React.MouseEvent) => {
    generateDocumentUrl();
    setIsOpen(true);
    event.stopPropagation();
  };

  useEffect(() => {
    dispatch(addVisibleButton(ActionButton.ShareUrl));

    return () => {
      dispatch(removeVisibleButton(ActionButton.ShareUrl));
    };
  }, [dispatch]);

  return (
    <ButtonDiv>
      <DocumentUrlPopup
        open={isOpen}
        handleRefresh={generateDocumentUrl}
        onClose={() => setIsOpen(false)}
        isDraft={isDraft(workflow) || isNewDocument}
        url={documentUrlResponse}
      />
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.primaryButtonHeight}
        id={"document-details-share-url-button"}
        text={"Share URL"}
        disabled={props.disabled || isEmptyDocumentNumber}
        onClick={shareUrlHandler}
        isCompactView={props.isCompactView == true}
        isVertical={false}
        isVisible={true}
        disabledTooltipText={
          isEmptyDocumentNumber ? TooltipMsg.EmptyDocumentNumber : ""
        }
        icon={<ShareIcon fontSize="small" />}
      />
    </ButtonDiv>
  );
}
