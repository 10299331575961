import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { CurrentSelfHelp } from "../../../../models/CurrentSelfHelpType";
import { SectionName } from "../../../../app/slices/models/documentDetailsModels";
import { SectionAccordionSummary } from "../SC/SectionAccordionSummary";
import { SectionDiv } from "../SC/SectionDiv";
import { SectionDivider } from "../SC/SectionDivider";
import { SectionDragIndicator } from "../SC/SectionDragIndicator";
import { SectionHeaderDiv } from "../SC/SectionHeaderDiv";

import { setCurrentSelfHelp } from "../../../../app/slices/selfHelpSlice";
import {
  selectMaximizedSection,
  setMaximizedSection,
  setDraggingSection,
} from "../../../../app/slices/documentDetailsSlice";
import { TooltipTop } from "../../../../controls/Tooltips/ToolipTop";
import { selectIsWindowTooSmall } from "../../../../app/slices/commonSlice";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import { ExpandCollapseControl } from "./controls/ExpandCollapseControl";
import { TextControl } from "./controls/TextControl";
import { IconsControl } from "./controls/IconsControl";
import { useTheme } from "styled-components";
import { useRefDimensions } from "../../../../app/hooks/useRefDimensions";

export interface SectionHeaderProps {
  name: string;
  section: SectionName;
  iconsVisible: boolean;
  isCollapsed: boolean;
  isExpanded: boolean;
  isValidationError?: boolean;
  isWarning?: boolean;
  isEdited: boolean;
  disableInteraction?: boolean;
  isEditable: boolean;
  notEditableReason?: TooltipMsg;
  customIcon?: "link" | "serviceIntegration";
  selfHelpType?: CurrentSelfHelp;
  onDiscardChanges: () => void;
}

//isCollapsed detects the end of the extend animation,
//but sometimes when section is very close to the end of the page,
//self-help does not render properly, timeout helps with that
const selfHelpTimeout = 200;

export function SectionHeader(props: SectionHeaderProps) {
  const headerDiv = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isTooSmallWindow = useAppSelector(selectIsWindowTooSmall);
  const dispatch = useAppDispatch();

  const maximizedSection = useAppSelector(selectMaximizedSection);
  const [open, setOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(props.isExpanded);
  const [isCompactView, setIsCompactView] = useState(false);

  const [validationErrorText, setValidationErrorText] = useState<
    string | undefined
  >();
  const [validationErrorColor, setValidationErrorColor] = useState<
    string | undefined
  >();
  const [openSelfHelp, setOpenSelfHelp] = useState(false);

  const dimensions = useRefDimensions(headerDiv);

  const closeMoveTooltip = () => {
    setOpen(false);
  };

  const openMoveTooltip = () => {
    setOpen(true);
  };

  const setDragStartingSection = () => {
    closeMoveTooltip();
    dispatch(setDraggingSection(props.section));
  };

  useEffect(() => {
    if (openSelfHelp && !props.isCollapsed) {
      setTimeout(() => {
        dispatch(
          setCurrentSelfHelp(props.selfHelpType ?? CurrentSelfHelp.None)
        );
      }, selfHelpTimeout);
      setOpenSelfHelp(false);
    }
  }, [dispatch, openSelfHelp, props.isCollapsed, props.selfHelpType]);

  useEffect(() => {
    const handleTabClose = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      dispatch(setMaximizedSection(undefined));

      window.removeEventListener("popstate", handleTabClose);
    };

    window.addEventListener("popstate", handleTabClose);
  }, [dispatch]);

  useEffect(() => {
    if (dimensions.width < theme.sectionHeader.minWidth) {
      setIsCompactView(true);
    } else {
      setIsCompactView(false);
    }
  }, [dimensions, theme.sectionHeader.minWidth]);

  return (
    <div>
      <SectionAccordionSummary
        onClick={() => {
          setIsExpanded((prev) => !prev);
        }}
        expandIcon={
          <ExpandCollapseControl
            isExpanded={isExpanded}
            section={props.section}
          />
        }
      >
        <SectionDiv>
          <SectionHeaderDiv id="Section-Header-Div" ref={headerDiv}>
            {!maximizedSection && (
              <TooltipTop
                title={
                  isTooSmallWindow || props.disableInteraction
                    ? TooltipMsg.MovingDisabled
                    : TooltipMsg.Move
                }
                open={open}
              >
                <SectionDragIndicator
                  id={props.section + "-move"}
                  onMouseEnter={openMoveTooltip}
                  onMouseLeave={closeMoveTooltip}
                  onMouseDown={setDragStartingSection}
                  fontSize="small"
                  disabled={isTooSmallWindow || props.disableInteraction}
                />
              </TooltipTop>
            )}
            <TextControl
              name={props.name}
              isEditable={props.isEditable}
              isEdited={props.isEdited}
              isWarning={props.isWarning}
              setValidationErrorText={setValidationErrorText}
              setValidationErrorColor={setValidationErrorColor}
              customIcon={props.customIcon}
              isCompactView={isCompactView}
              isValidationError={props.isValidationError}
              notEditableReason={props.notEditableReason}
            />
            <IconsControl
              iconsVisible={props.iconsVisible}
              section={props.section}
              isCompactView={isCompactView}
              isEdited={props.isEdited}
              isError={props.isValidationError}
              isWarning={props.isWarning}
              isExpanded={isExpanded}
              selfHelpType={props.selfHelpType}
              validationErrorColor={validationErrorColor}
              validationErrorText={validationErrorText}
              onDiscardChanges={props.onDiscardChanges}
              setOpenSelfHelp={setOpenSelfHelp}
              setIsExpanded={setIsExpanded}
            />
          </SectionHeaderDiv>
        </SectionDiv>
      </SectionAccordionSummary>
      <SectionDivider />
    </div>
  );
}
