import { createApi } from "@reduxjs/toolkit/query/react";
import { Identity } from "../models/documentDetails/documentMetadata";
import ScheduledActionMessage from "../models/scheduledActionMessage";
import ScheduledDeleteAllActionMessage from "../models/scheduledDeleteAllActionMessage";
import { publishApiBaseQuery } from "./baseQueries";

export const scheduledActionsApi = createApi({
  reducerPath: "scheduledActionsApi",
  baseQuery: publishApiBaseQuery,
  tagTypes: ["Actions"],
  endpoints: (builder) => ({
    getDocumentActions: builder.query<ScheduledActionMessage, Identity>({
      query: (data) => ({
        url: `Documents/${encodeURIComponent(
          data.documentNumber
        )}/${data.documentLanguageCodes.join("")}/${encodeURIComponent(
          data.documentPart
        )}/Revisions/${data.revision}/ScheduledActions`,
        method: "GET",
      }),
      providesTags: ["Actions"],
    }),
    setDocumentActions: builder.mutation<
      boolean,
      {
        documentIdentity: Identity;
        action: ScheduledActionMessage;
      }
    >({
      query: (data) => ({
        url: `Documents/${encodeURIComponent(
          data.documentIdentity.documentNumber
        )}/${data.documentIdentity.documentLanguageCodes.join("")}/${encodeURIComponent(
          data.documentIdentity.documentPart
        )}/Revisions/${data.documentIdentity.revision}/ScheduledActions`,
        method: "PUT",
        body: data.action,
      }),
      invalidatesTags: ["Actions"],
    }),
    deleteDocumentActions: builder.mutation<
      boolean,
      {
        documentIdentity: Identity;
      }
    >({
      query: (data) => ({
        url: `Documents/${encodeURIComponent(
          data.documentIdentity.documentNumber
        )}/${data.documentIdentity.documentLanguageCodes.join("")}/${encodeURIComponent(
          data.documentIdentity.documentPart
        )}/Revisions/${data.documentIdentity.revision}/ScheduledActions`,
        method: "DELETE",
      }),
      invalidatesTags: ["Actions"],
    }),
    deleteAllDocumentActions: builder.mutation<
      string[],
      {
        documentIdentity: Identity;
        message: ScheduledDeleteAllActionMessage;
      }
    >({
      query: (data) => ({
        url: `Documents/${encodeURIComponent(
          data.documentIdentity.documentNumber
        )}/${data.documentIdentity.documentLanguageCodes.join("")}/${encodeURIComponent(
          data.documentIdentity.documentPart
        )}/Revisions/${data.documentIdentity.revision}/ScheduledDeleteAllActions`,
        method: "POST",
        body: data.message,
      }),
      invalidatesTags: ["Actions"],
    }),
  }),
});

export const {
  useLazyGetDocumentActionsQuery,
  useSetDocumentActionsMutation,
  useDeleteDocumentActionsMutation,
  useDeleteAllDocumentActionsMutation,
} = scheduledActionsApi;
export default scheduledActionsApi.reducer;
