import { Skeleton } from "@mui/material";
import { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { CurrentSelfHelp } from "../../../../models/CurrentSelfHelpType";
import { Products } from "../../inputs/Products";
import {
  selectCurrentDocumentProducts,
  clearEditedDocumentProducts,
} from "../../../../app/slices/documentMetadataSlice";
import { SectionName } from "../../../../app/slices/models/documentDetailsModels";
import { DetailsSection, DetailsSectionProps } from "../DetailsSection";
import { useCallback, useEffect, useState } from "react";
import { selectIsLoadingDetailsMetaData } from "../../../../app/slices/documentDetailsSlice";
import { initialEditableState } from "../../../../app/hooks/permissions/models/state";
import { useUserCanEditDocument } from "../../../../app/hooks/permissions/useUserCanEditDocument";
import { useDocumentEditedMode } from "../../../../app/hooks/document/useDocumentEditedMode";

export function ProductsSection(props: DetailsSectionProps) {
  const dispatch = useAppDispatch();
  const products = useAppSelector(selectCurrentDocumentProducts);
  const theme = useTheme();

  const isLoadingDetailsMetaData = useAppSelector(
    selectIsLoadingDetailsMetaData
  );

  const { productsSection } = useDocumentEditedMode();
  const [editableState, setEditableState] = useState(initialEditableState);
  const { canEditDocument } = useUserCanEditDocument();

  useEffect(() => {
    setEditableState(canEditDocument(true));
  }, [canEditDocument]);

  const discardProductsSection = useCallback(() => {
    dispatch(clearEditedDocumentProducts());
  }, [dispatch]);

  return (
    <DetailsSection
      {...props}
      section={SectionName.Products}
      name="Products"
      selfHelpType={CurrentSelfHelp.DetailsProducts}
      isEdited={productsSection.productsSectionIsEdited}
      isLoadingDetailsMetaData={isLoadingDetailsMetaData}
      onDiscardChanges={discardProductsSection}
      isEditable={editableState.isEditable}
      notEditableReason={editableState.lockMessage}
    >
      {isLoadingDetailsMetaData ? (
        <Skeleton height={theme.skeletons.medium} width={"auto"} />
      ) : (
        <Products
          isEditable={editableState.isEditable}
          notEditableMessage={editableState.lockMessage}
          id="Products"
          products={products}
          isEdited={productsSection.productsIsEdited}
          editedItems={productsSection.editedProducts}
        />
      )}
    </DetailsSection>
  );
}
