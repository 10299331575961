import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import FilterListIcon from "@mui/icons-material/FilterList";
import { SelfHelpIcon } from "../SelfHelpIcon";

export function FiltersButtonStep2() {
    return (
        <StepContentDiv>
            <Paragraph>
                The <SelfHelpIcon isBlack icon={<FilterListIcon />} /> Filters button is used to narrow down the publishers list to users meeting filtering criteria.
            </Paragraph>
        </StepContentDiv>
    );
}
