import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import {
  selectNewDocumentVersionIdentity,
  setDocumentDetailView,
  setNewDocumentVersionIdentity,
} from "../../app/slices/documentDetailsSlice";
import { setDocumentIsNew } from "../../app/slices/documentMetadataSlice";
import { DocumentDetailView } from "../../models/documentDetailView";
import { Details } from "../details/Details";
import { ContentDiv } from "./SC/ContentDiv";

interface NewDocumentViewProps {
  view: DocumentDetailView;
}

export function NewDocumentView(props: NewDocumentViewProps) {
  const dispatch = useAppDispatch();
  const newDocumentVersionIdentity = useAppSelector(
    selectNewDocumentVersionIdentity
  );

  useEffect(() => {
    dispatch(setDocumentIsNew(true));
    dispatch(setDocumentDetailView(props.view));
  }, [dispatch, props.view]);

  useEffect(() => {
    return () => {
      dispatch(setDocumentIsNew(false));
      dispatch(setNewDocumentVersionIdentity(undefined));
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      props.view != DocumentDetailView.NewDocument &&
      newDocumentVersionIdentity === undefined
    ) {
      window.location.replace("/404.html");
    }
  }, [newDocumentVersionIdentity, props.view]);

  return (
    <ContentDiv id="content-div">
      <Details />
    </ContentDiv>
  );
}
