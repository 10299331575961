import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { setPageNumber } from "../../../app/slices/documentListSlice";
import {
  setAccessGroupsFilter,
  setCategoryId,
  setDocumentKindsFilter,
  setDocumentLanguageCodesFilter,
  setIncludeDocumentsFromSubcategories,
  setIncludeDocumentsICannotManage,
  setPublishedFromFilter,
  setPublishedToFilter,
  setPublishersFilter,
  setSearchDocumentNumberFilter,
  setSearchDocumentTitleFilter,
  setSecurityLevelsFilter,
  setShowOnlyUnCategorizedDocuments,
  setWorkflowsFilter,
} from "../../../app/slices/navigationSlice";
import { RootState } from "../../../app/store";

export const listFiltersMiddleware = createListenerMiddleware();

listFiltersMiddleware.startListening({
  matcher: isAnyOf(
    setIncludeDocumentsFromSubcategories,
    setIncludeDocumentsICannotManage,
    setShowOnlyUnCategorizedDocuments
  ),
  effect: (_, listenerApi) => {
    listenerApi.dispatch(setPageNumber(1));
  },
});

listFiltersMiddleware.startListening({
  matcher: isAnyOf(
    setWorkflowsFilter,
    setPublishedFromFilter,
    setPublishedToFilter,
    setAccessGroupsFilter,
    setPublishersFilter,
    setDocumentLanguageCodesFilter,
    setSecurityLevelsFilter,
    setDocumentKindsFilter,
    setIncludeDocumentsFromSubcategories,
    setIncludeDocumentsICannotManage,
    setShowOnlyUnCategorizedDocuments,
    setCategoryId
  ),
  effect: (_, listenerApi) => {
    const state = listenerApi.getState() as RootState;
    listenerApi.dispatch(
      setSearchDocumentNumberFilter(
        state.navigation.currentFilters.currentDocumentNumber.trim()
      )
    );
    listenerApi.dispatch(
      setSearchDocumentTitleFilter(
        state.navigation.currentFilters.currentDocumentTitle.trim()
      )
    );
  },
});
