import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function TableSettingsStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        These buttons are used to apply changes to the document list view.
      </Paragraph>
      <Paragraph>
        User preferences, such as density and columns, are saved for future
        sessions.
      </Paragraph>
    </StepContentDiv>
  );
}
