import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SnackbarData } from "../../models/snackbar";
import { RootState } from "../store";

export interface SnackbarState {
  data: SnackbarData[];
  cleared: boolean;
}

const initialSnackbarState: SnackbarState = {
  data: [],
  cleared: false,
};

export const snackbarSlice = createSlice({
  name: "snackbarState",
  initialState: initialSnackbarState,
  reducers: {
    enqueueSnackbar(state, action: PayloadAction<SnackbarData>) {
      state.cleared = false;
      state.data = [...state.data, action.payload];
    },
    dequeueSnackbar(state) {
      state.data = state.data.length > 1 ? state.data.slice(1) : [];
    },
    clearSnackbar(state) {
      state.data = [];
      state.cleared = true;
    },
  },
});

export const { enqueueSnackbar, dequeueSnackbar, clearSnackbar } =
  snackbarSlice.actions;

export const selectSnackbarData = (state: RootState) =>
  state.snackbarState.data;
export const selectSnackbarCleared = (state: RootState) =>
  state.snackbarState.cleared;

export default snackbarSlice.reducer;
