import { useTheme } from "styled-components";
import { TextButtonControl } from "../../../../../controls/Buttons/TextButtonControl";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSaveSettingsHandler } from "../../../../../app/hooks/settings/useSaveSettingsHandler";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import {
  clearCurrentPublisherCategoriesSettings,
  selectCategoryId,
} from "../../../../../app/slices/publisherCategoriesSettingsSlice";
import { ButtonDiv } from "../CloseButton/SC/ButtonDiv";
import { DialogControl } from "../../../../../controls/Dialog/DialogControl";
import { useState } from "react";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";
import { StyledDialogContentText } from "../../../../details/selfHelpPopup/SC/StyledDialogContentText";

interface DiscardChangesButtonProps {
  isEdited?: boolean;
  permissionMissing?: boolean;
  isLoading: boolean;
  isErrors: boolean;
}

export function DiscardChangesButton(props: DiscardChangesButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const categoryId = useAppSelector(selectCategoryId);

  const { isSaving } = useSaveSettingsHandler();
  const onDiscardAllChanges = () => {
    dispatch(clearCurrentPublisherCategoriesSettings());
    setIsOpen(false);
  };

  const disableTooltipText = () => {
    switch (true) {
      case !categoryId:
        return TooltipMsg.ChooseCategoryFirst;
      case props.permissionMissing:
        return TooltipMsg.NoAccessToCategory;
      case !props.isEdited:
        return TooltipMsg.NoChangesMade;
      default:
        return "";
    }
  };

  return (
    <ButtonDiv>
      <DialogControl
        id="discard-changes-button"
        title="Discard all changes"
        onConfirmClick={onDiscardAllChanges}
        onCloseClick={() => setIsOpen(false)}
        maxWidth="sm"
        useActions={true}
        isOpen={isOpen}
        content={
          <StyledDialogContentText id="alert-dialog-description">
            This will discard all changes made. Do you want to proceed?
          </StyledDialogContentText>
        }
      />
      <TextButtonControl
        id={"document-settings-discard-changes-button"}
        colors={"secondary"}
        height={theme.shapes.primaryButtonHeight}
        text={"Discard changes"}
        onClick={(event) => {
          setIsOpen(true);
          event.stopPropagation();
        }}
        isCompactView={false}
        isVertical={false}
        isVisible={true}
        disabled={
          !props.isErrors &&
          (!props.isEdited ||
            props.permissionMissing ||
            isSaving ||
            props.isLoading)
        }
        icon={<CancelIcon fontSize="small" />}
        disabledTooltipText={disableTooltipText()}
      />
    </ButtonDiv>
  );
}
