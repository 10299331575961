import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function PublishedDateStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                This indicates the document&apos;s published date or draft creation date.
            </Paragraph>
        </StepContentDiv>
    );
}
