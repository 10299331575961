import styled from "styled-components";

interface TagTypesDivProps {
  $isMinimized: boolean;
}

export const TagTypesDiv = styled.div<TagTypesDivProps>`
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  margin-left: 10px;
  display: ${(props) => (props.$isMinimized ? "none" : "flex")};
`;
