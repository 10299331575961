import { FormGroup } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import {
  selectIncludeDocumentsFromSubcategories,
  selectIncludeDocumentsICannotManage,
  selectShowOnlyUnCategorizedDocuments,
  setIncludeDocumentsFromSubcategories,
  setIncludeDocumentsICannotManage,
  setShowOnlyUnCategorizedDocuments,
} from "../../../app/slices/navigationSlice";
import { CategoryTreeFiltersCheckbox } from "./SC/CategoryTreeFiltersCheckbox";
import { CategoryTreeFiltersLabel } from "./SC/CategoryTreeFiltersLabel";

interface CategoryTreeViewNavigationProps {
  isError: boolean;
}
export default function CategoryTreeViewNavigation(
  props: CategoryTreeViewNavigationProps
) {
  const includeDocsFromSubcats = useAppSelector(
    selectIncludeDocumentsFromSubcategories
  );
  const includeDocsIcannotManage = useAppSelector(
    selectIncludeDocumentsICannotManage
  );
  const showOnlyUnCategorizedDocs = useAppSelector(
    selectShowOnlyUnCategorizedDocuments
  );
  const dispatch = useAppDispatch();

  if (props.isError) {
    return null;
  }

  return (
    <FormGroup>
      <CategoryTreeFiltersLabel
        id="category-tree-include-documents-from-subcategories-label"
        value="end"
        control={
          <CategoryTreeFiltersCheckbox
            id="category-tree-include-documents-from-subcategories-checkbox"
            className="category-tree-checkbox"
            size="small"
            checked={includeDocsFromSubcats}
            onChange={(e) =>
              dispatch(
                setIncludeDocumentsFromSubcategories(e.currentTarget.checked)
              )
            }
          />
        }
        label="Include documents from subcategories"
        labelPlacement="end"
      />
      <CategoryTreeFiltersLabel
        id="category-tree-include-documents-i-cannot-manage-label"
        value="end"
        control={
          <CategoryTreeFiltersCheckbox
            id="category-tree-include-documents-i-cannot-manage-checkbox"
            className="category-tree-checkbox"
            size="small"
            checked={includeDocsIcannotManage}
            onChange={(e) =>
              dispatch(
                setIncludeDocumentsICannotManage(e.currentTarget.checked)
              )
            }
          />
        }
        label="Include documents I cannot manage"
        labelPlacement="end"
      />
      <CategoryTreeFiltersLabel
        id="category-tree-show-only-uncategorized-documents-label"
        value="end"
        control={
          <CategoryTreeFiltersCheckbox
            id="category-tree-show-only-uncategorized-documents-checkbox"
            className="category-tree-checkbox"
            size="small"
            checked={showOnlyUnCategorizedDocs}
            onChange={(e) =>
              dispatch(
                setShowOnlyUnCategorizedDocuments(e.currentTarget.checked)
              )
            }
          />
        }
        label="Show uncategorized documents only"
        labelPlacement="end"
      />
    </FormGroup>
  );
}
