import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SplitterStep3() {
    return (
        <StepContentDiv>
            <Paragraph>
                The categories and publishers list section can be resized by dragging and dropping this splitter.
            </Paragraph>
        </StepContentDiv>
    );
}
