import { useCallback, useEffect, useMemo } from "react";
import { useLazyGetTagsByCategoryIdQuery } from "../../../../apis/tagsApi";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { selectIsLoadingDetailsMetaData } from "../../../../app/slices/documentDetailsSlice";
import { openSnackbar } from "../../../../app/helpers/snackBarHelper";
import { SnackbarType } from "../../../../models/snackbar";
import {
  selectCurrentDocumentMainCategory,
  setTagsDictionary,
} from "../../../../app/slices/documentMetadataSlice";
import DocumentTag from "../../../../models/documentDetails/documentTag";
import { SnackbarMsg } from "../../../../controls/Snackbar/SnackbarMessages";

export function useFetchTagsByCategory() {
  const dispatch = useAppDispatch();
  const isLoadingDetailsMetaData = useAppSelector(
    selectIsLoadingDetailsMetaData
  );
  const currentMainCategory = useAppSelector(selectCurrentDocumentMainCategory);

  const [
    getTagsByCategoryId,
    {
      data: tagsInCategoryResponse,
      isError: isCategoryTagsError,
      isLoading,
      isFetching,
    },
  ] = useLazyGetTagsByCategoryIdQuery();

  const isLoadingCategoryTags = useMemo(() => {
    return isLoadingDetailsMetaData || isLoading || isFetching;
  }, [isLoadingDetailsMetaData, isLoading, isFetching]);

  const fetchTagsByCategoryId = useCallback(() => {
    if (currentMainCategory) {
      void getTagsByCategoryId(currentMainCategory.cid, true);
    }
  }, [currentMainCategory, getTagsByCategoryId]);

  useEffect(() => {
    if (currentMainCategory) {
      void getTagsByCategoryId(currentMainCategory.cid, true);
    }
  }, [currentMainCategory, getTagsByCategoryId]);

  useEffect(() => {
    if (tagsInCategoryResponse) {
      const documentTags = tagsInCategoryResponse
        .map((categoryTag) => {
          return categoryTag.value.map<DocumentTag>((ctv) => {
            return { key: categoryTag.key, value: ctv };
          });
        })
        .flat();
      dispatch(setTagsDictionary(documentTags));
    }
  }, [dispatch, tagsInCategoryResponse]);

  useEffect(() => {
    if (isCategoryTagsError) {
      openSnackbar(
        dispatch,
        SnackbarMsg.CategoryTagsError,
        SnackbarType.error
      );
    }
  }, [isCategoryTagsError, dispatch]);

  return {
    fetchTagsByCategoryId,
    isCategoryTagsError,
    isLoadingCategoryTags,
  };
}
