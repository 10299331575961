import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

export function PublicationDateStep() {
  const theme = useTheme();

  return (
    <StepContentDiv>
      <Paragraph>
        The document list is narrowed down to documents that have been published
        since the &quot;From&quot; date or until the &quot;To&quot; date.
      </Paragraph>
      <Paragraph>
        The date can be selected from the{" "}
        <CalendarMonthIcon
          fontSize="small"
          style={{
            fontSize: "16px",
            color: theme.palettes.grayscale.dark,
            verticalAlign: "middle",
          }}
        />{" "}
        calendar or typed in using the yyyy-mm-dd date format.
      </Paragraph>
      <Paragraph>
        Values can be selected or deselected by clicking on them.
      </Paragraph>
      <Paragraph>The results are automatically reloaded.</Paragraph>
    </StepContentDiv>
  );
}
