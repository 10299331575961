import styled, { DefaultTheme } from "styled-components";

interface TreeItemDivProps {
  selectable: boolean;
  selected: boolean;
  paddingLeft: number;
}

function getColor(selectable: boolean, theme: DefaultTheme) {
  if (!selectable) {
    return theme.palettes.grayscale.medium;
  }

  return theme.palettes.grayscale.darkest;
}

function getBold(selectable: boolean) {
  if (selectable) {
    return "bold";
  }

  return "unset";
}

export const TreeItemLi = styled.li<TreeItemDivProps>`
  color: ${(props) => getColor(props.selectable, props.theme)};
  font-size: ${(props) => props.theme.typography.fontSizes.large}px;
  font-weight: ${(props) => getBold(props.selectable)};
  background-color: ${(props) =>
    props.selected ? props.theme.palettes.grayscale.light : "unset"};
  border-left: ${(props) =>
    props.selected
      ? "2px solid " + props.theme.palettes.primary.main
      : "2px solid transparent"};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  line-height: 20px;
  padding-left: ${(props) => props.paddingLeft}px;

  :hover {
    background-color: ${(props) => props.theme.palettes.grayscale.lighter};
  }
`;
