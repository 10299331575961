import styled, { DefaultTheme } from "styled-components";
import LockPersonIcon from "@mui/icons-material/LockPerson";

interface StyledLabelLockIconProps {
  $marginBottom?: number;
  $marginLeft?: number;
  size?: "medium" | "large";
}

function getFontSize(theme: DefaultTheme, size?: "medium" | "large") {
  switch (size) {
    case "large": {
      return theme.typography.fontSizes.large;
    }
    case "medium": {
      return theme.typography.fontSizes.medium;
    }
    default: {
      return theme.typography.fontSizes.extraSmallIcon;
    }
  }
}

export const StyledLockLabelIcon = styled(
  LockPersonIcon
)<StyledLabelLockIconProps>`
  &.MuiSvgIcon-root {
    font-size: ${(props) => getFontSize(props.theme, props.size)}px;
    color: ${(props) => props.theme.palettes.grayscale.medium};
    margin-bottom: ${(props) => props.$marginBottom ?? 0}px;
    margin-left: ${(props) => props.$marginLeft ?? 0}px;
  }
`;
