import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import ScheduledActionMessage, {
  Action,
} from "../../models/scheduledActionMessage";

export interface ScheduledActionState {
  scheduledAction: ScheduledActionMessage;
  error: ErrorState;
  isLoading: boolean;
}

interface ErrorState {
  isError: boolean;
  isErrorVisible: boolean;
  isEmailNoteError: boolean;
}

const initialState: ScheduledActionState = {
  scheduledAction: {
    action: undefined,
    expirationDate: undefined,
    emailContent: "",
    emailRecipients: [],
  },
  error: {
    isError: false,
    isErrorVisible: false,
    isEmailNoteError: false,
  },
  isLoading: false,
};

export const scheduledActionSlice = createSlice({
  name: "scheduledAction",
  initialState: initialState,
  reducers: {
    setScheduledAction(state, action: PayloadAction<Action>) {
      state.scheduledAction.action = action.payload;
    },
    setScheduledEmailContent(state, action: PayloadAction<string>) {
      state.scheduledAction.emailContent = action.payload;
    },
    setScheduledEmailRecipient(state, action: PayloadAction<string[]>) {
      state.scheduledAction.emailRecipients = [...action.payload];
    },
    setScheduledExpirationDate(state, action: PayloadAction<string>) {
      state.scheduledAction.expirationDate = action.payload;
    },
    setScheduledActionIsError(state, action: PayloadAction<boolean>) {
      state.error.isError = action.payload;
    },
    setScheduledActionIsErrorVisible(state, action: PayloadAction<boolean>) {
      state.error.isErrorVisible = action.payload;
    },
    setScheduledActionIsEmailNoteError(state, action: PayloadAction<boolean>) {
      state.error.isEmailNoteError = action.payload;
    },
    setScheduledActionContent(
      state,
      action: PayloadAction<ScheduledActionMessage | null>
    ) {
      if (action.payload) {
        state.scheduledAction = action.payload;
      } else {
        state.scheduledAction = initialState.scheduledAction;
      }
    },
    setScheduledActionIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    clearScheduledActionState(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.scheduledAction = initialState.scheduledAction;
        state.error = initialState.error;
      }
    },
  },
});

export const {
  setScheduledAction,
  setScheduledEmailContent,
  setScheduledEmailRecipient,
  setScheduledExpirationDate,
  setScheduledActionIsError,
  setScheduledActionContent,
  setScheduledActionIsErrorVisible,
  setScheduledActionIsEmailNoteError,
  setScheduledActionIsLoading,
  clearScheduledActionState,
} = scheduledActionSlice.actions;

export const selectScheduledAction = (state: RootState) =>
  state.scheduledAction.scheduledAction;
export const selectScheduledActionIsError = (state: RootState) =>
  state.scheduledAction.error.isError;
export const selectScheduledActionIsErrorVisible = (state: RootState) =>
  state.scheduledAction.error.isErrorVisible;
export const selectScheduledActionIsFilled = (state: RootState) =>
  state.scheduledAction.scheduledAction.action !== undefined &&
  state.scheduledAction.scheduledAction.expirationDate !== undefined;
export const selectScheduledActionEmailNoteError = (state: RootState) =>
  state.scheduledAction.error.isEmailNoteError;
export const selectScheduledActionIsLoading = (state: RootState) =>
  state.scheduledAction.isLoading;

export default scheduledActionSlice.reducer;
