import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SecondCheckboxStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        When the &quot;Include documents I cannot manage&quot; option is
        disabled, it will only show documents that you can edit, which are
        displayed in black and bold font.
      </Paragraph>
      <Paragraph>
        However, please note that generating this list requires more processing
        power, so it may take longer than usual to load the document list.
      </Paragraph>
    </StepContentDiv>
  );
}
