import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query/react";
import { accessGroupsApi } from "../apis/accessGroupsApi";
import { categoriesListApi } from "../apis/categoriesListApi";
import { configApi } from "../apis/configApi";
import { countriesApi } from "../apis/countriesApi";
import { documentActionsApi } from "../apis/documentActionsApi";
import { documentApi } from "../apis/documentApi";
import { documentAttachmentApi } from "../apis/documentAttachmentApi";
import { documentDetailsApi } from "../apis/documentDetailsApi";
import { documentDraftApi } from "../apis/documentDraftApi";
import { documentKindsApi } from "../apis/documentKindsApi";
import { documentsListApi } from "../apis/documentsListApi";
import { fileStatusApi } from "../apis/fileStatusApi";
import { fileUploadApi } from "../apis/fileUploadApi";
import { graphApi } from "../apis/graphApi";
import { languagesApi } from "../apis/languagesApi";
import { metadataExportApi } from "../apis/metadataExportApi";
import { publisherCategoriesApi } from "../apis/publisherCategoriesApi";
import { publisherCategoriesSettingsApi } from "../apis/publisherCategoriesSettingsApi";
import { publishersApi } from "../apis/publishersApi";
import { publishersRolesApi } from "../apis/publishersRolesApi";
import { revisionsApi } from "../apis/revisionsApi";
import { tagsApi } from "../apis/tagsApi";
import { detailsLocalStorageMiddleware } from "../components/details/middleware/DetailsLocalStorageMiddleware";
import { listFiltersMiddleware } from "../components/details/middleware/ListFiltersMiddleware";
import { publisherDirectCategoriesLocalStorageMiddleware } from "../components/details/middleware/PublisherDirectCategoriesLocalStorageMiddleware";
import { publishersListocalStorageMiddleware } from "../components/details/middleware/PublishersListLocalStorageMiddleware";
import { apiErrorSlice } from "./slices/apiErrorSlice";
import { authenticationSlice } from "./slices/authenticationSlice";
import { categoriesSlice } from "./slices/categoriesSlice";
import { commonSlice } from "./slices/commonSlice";
import { contentSlice } from "./slices/contentSlice";
import { detailsSlice } from "./slices/documentDetailsSlice";
import { documentListSlice } from "./slices/documentListSlice";
import { documentListViewSlice } from "./slices/documentListViewSlice";
import { documentMetadataSlice } from "./slices/documentMetadataSlice";
import { documentMetadataValidationSlice } from "./slices/documentMetadataValidationSlice";
import { fileUploadSlice } from "./slices/fileUploadSlice";
import { navigationCategoryTreeSlice } from "./slices/navigationCategoryTreeSlice";
import { navigationSlice } from "./slices/navigationSlice";
import { ownerCategoryTreeSlice } from "./slices/ownerCategoryTreeSlice";
import { publisherCategoriesSettingsSlice } from "./slices/publisherCategoriesSettingsSlice";
import { publisherCategoryTreeSlice } from "./slices/publisherCategoryTreeSlice";
import { publisherDirectCategoriesSlice } from "./slices/publisherDirectCategoriesSlice";
import { publishersListSlice } from "./slices/publishersListSlice";
import { publishersViewSlice } from "./slices/publishersViewSlice";
import { selfHelpSlice } from "./slices/selfHelpSlice";
import { snackbarSlice } from "./slices/snackbarSlice";
import { selfHelpApi } from "../apis/selfHelpApi";
import { tagsViewSlice } from "./slices/tagsViewSlice";
import { tagsSlice } from "./slices/tagsSlice";
import { scheduledActionsApi } from "../apis/scheduledActionsApi";
import { scheduledActionSlice } from "./slices/scheduledActionSlice";

const defaultMiddlewareConfig = {
  serializableCheck: {
    ignoredPaths: [
      publisherCategoriesApi.reducerPath,
      documentsListApi.reducerPath,
    ],
  },
};

export const store = configureStore({
  reducer: {
    [authenticationSlice.name]: authenticationSlice.reducer,
    [contentSlice.name]: contentSlice.reducer,
    [commonSlice.name]: commonSlice.reducer,
    [documentListSlice.name]: documentListSlice.reducer,
    [documentListViewSlice.name]: documentListViewSlice.reducer,
    [navigationSlice.name]: navigationSlice.reducer,
    [navigationCategoryTreeSlice.name]: navigationCategoryTreeSlice.reducer,
    [publisherCategoryTreeSlice.name]: publisherCategoryTreeSlice.reducer,
    [ownerCategoryTreeSlice.name]: ownerCategoryTreeSlice.reducer,
    [documentKindsApi.reducerPath]: documentKindsApi.reducer,
    [selfHelpSlice.name]: selfHelpSlice.reducer,
    [apiErrorSlice.name]: apiErrorSlice.reducer,
    [snackbarSlice.name]: snackbarSlice.reducer,
    [detailsSlice.name]: detailsSlice.reducer,
    [documentMetadataSlice.name]: documentMetadataSlice.reducer,
    [documentMetadataValidationSlice.name]:
      documentMetadataValidationSlice.reducer,
    [documentAttachmentApi.reducerPath]: documentAttachmentApi.reducer,
    [fileUploadSlice.name]: fileUploadSlice.reducer,
    [categoriesSlice.name]: categoriesSlice.reducer,
    [publishersViewSlice.name]: publishersViewSlice.reducer,
    [publisherCategoriesSettingsSlice.name]:
      publisherCategoriesSettingsSlice.reducer,
    [publishersListSlice.name]: publishersListSlice.reducer,
    [publisherDirectCategoriesSlice.name]:
      publisherDirectCategoriesSlice.reducer,
    [tagsViewSlice.name]: tagsViewSlice.reducer,
    [tagsSlice.name]: tagsSlice.reducer,
    [scheduledActionSlice.name]: scheduledActionSlice.reducer,

    //APIs
    [documentsListApi.reducerPath]: documentsListApi.reducer,
    [metadataExportApi.reducerPath]: metadataExportApi.reducer,
    [categoriesListApi.reducerPath]: categoriesListApi.reducer,
    [documentDetailsApi.reducerPath]: documentDetailsApi.reducer,
    [documentDraftApi.reducerPath]: documentDraftApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    [scheduledActionsApi.reducerPath]: scheduledActionsApi.reducer,
    [publisherCategoriesApi.reducerPath]: publisherCategoriesApi.reducer,
    [revisionsApi.reducerPath]: revisionsApi.reducer,
    [countriesApi.reducerPath]: countriesApi.reducer,
    [accessGroupsApi.reducerPath]: accessGroupsApi.reducer,
    [publishersApi.reducerPath]: publishersApi.reducer,
    [languagesApi.reducerPath]: languagesApi.reducer,
    [tagsApi.reducerPath]: tagsApi.reducer,
    [fileUploadApi.reducerPath]: fileUploadApi.reducer,
    [fileStatusApi.reducerPath]: fileStatusApi.reducer,
    [graphApi.reducerPath]: graphApi.reducer,
    [configApi.reducerPath]: configApi.reducer,
    [documentActionsApi.reducerPath]: documentActionsApi.reducer,
    [publisherCategoriesSettingsApi.reducerPath]:
      publisherCategoriesSettingsApi.reducer,
    [publishersRolesApi.reducerPath]: publishersRolesApi.reducer,
    [selfHelpApi.reducerPath]: selfHelpApi.reducer,
    //END APIs
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(defaultMiddlewareConfig)
      .prepend(detailsLocalStorageMiddleware.middleware)
      .prepend(listFiltersMiddleware.middleware)
      .concat(metadataExportApi.middleware)
      .concat(categoriesListApi.middleware)
      .concat(documentDetailsApi.middleware)
      .concat(documentsListApi.middleware)
      .concat(documentApi.middleware)
      .concat(scheduledActionsApi.middleware)
      .concat(publishersApi.middleware)
      .concat(graphApi.middleware)
      .concat(configApi.middleware)
      .concat(languagesApi.middleware)
      .concat(accessGroupsApi.middleware)
      .concat(documentKindsApi.middleware)
      .concat(tagsApi.middleware)
      .concat(countriesApi.middleware)
      .concat(documentAttachmentApi.middleware)
      .concat(documentDraftApi.middleware)
      .concat(revisionsApi.middleware)
      .concat(publisherCategoriesApi.middleware)
      .concat(publisherCategoriesSettingsApi.middleware)
      .concat(fileUploadApi.middleware)
      .concat(fileStatusApi.middleware)
      .concat(documentActionsApi.middleware)
      .concat(publishersListocalStorageMiddleware.middleware)
      .concat(publisherDirectCategoriesLocalStorageMiddleware.middleware)
      .concat(publishersRolesApi.middleware)
      .concat(selfHelpApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
