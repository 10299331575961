import { GridColumnVisibilityModel, GridDensity } from "@mui/x-data-grid";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PublisherRoles } from "../../models/publisherRoles";
import { publishersListLocalStorageKey } from "../hooks/localStorage";
import { RootState } from "../store";

export interface PublishersListParams {
  density: GridDensity;
  pageSize: number;
  columnVisibilityModel?: GridColumnVisibilityModel;
}

export interface PublishersListState extends PublishersListParams {
  publishersRoles?: PublisherRoles[];
  categoryId: string;
  categoryName: string;
  canExport: boolean;
  selectedBreadcrumbCategory: string;
}

const defaultListParams: PublishersListParams = {
  density: "standard",
  pageSize: 50,
  columnVisibilityModel: undefined,
};

export function getInitialParams() {
  try {
    const value = localStorage.getItem(publishersListLocalStorageKey);
    if (value) {
      return JSON.parse(value) as PublishersListParams;
    }

    return defaultListParams;
  } catch {
    return defaultListParams;
  }
}

const initialState: PublishersListState = {
  ...getInitialParams(),
  publishersRoles: undefined,
  categoryId: "",
  categoryName: "",
  canExport: false,
  selectedBreadcrumbCategory: "",
};

export const publishersListSlice = createSlice({
  name: "publishersList",
  initialState: initialState,
  reducers: {
    setPublishersRoles(
      state,
      action: PayloadAction<PublisherRoles[] | undefined>
    ) {
      state.publishersRoles = action.payload;
    },
    setPublishersDensity(state, action: PayloadAction<GridDensity>) {
      state.density = action.payload;
    },
    setPublishersPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setPublishersColumnVisibility(
      state,
      action: PayloadAction<GridColumnVisibilityModel>
    ) {
      state.columnVisibilityModel = action.payload;
    },
    setSelectedCategoryId(state, action: PayloadAction<string>) {
      state.categoryId = action.payload;
    },
    setSelectedCategoryName(state, action: PayloadAction<string>) {
      state.categoryName = action.payload;
    },
    setCanExport(state, action: PayloadAction<boolean>) {
      state.canExport = action.payload;
    },
    setSelectedBreadcrumbCategory(state, action: PayloadAction<string>) {
      state.selectedBreadcrumbCategory = action.payload;
    },
  },
});

export const {
  setPublishersRoles,
  setPublishersDensity,
  setPublishersPageSize,
  setPublishersColumnVisibility,
  setSelectedCategoryId,
  setSelectedCategoryName,
  setCanExport,
  setSelectedBreadcrumbCategory,
} = publishersListSlice.actions;

export const selectPublishersRoles = (state: RootState) =>
  state.publishersList.publishersRoles;

export const selectPublishersDensity = (state: RootState) =>
  state.publishersList.density;

export const selectPublishersPageSize = (state: RootState) =>
  state.publishersList.pageSize;

export const selectPublishersColumnVisibility = (state: RootState) =>
  state.publishersList.columnVisibilityModel;

export const selectSelectedCategoryId = (state: RootState) =>
  state.publishersList.categoryId;

export const selectSelectedCategoryName = (state: RootState) =>
  state.publishersList.categoryName;

export const selectCanExport = (state: RootState) =>
  state.publishersList.canExport;

export const selectSelectedBreadcrumbCategory = (state: RootState) =>
  state.publishersList.selectedBreadcrumbCategory;

export default publishersListSlice.reducer;
