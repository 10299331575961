import styled from "styled-components";

interface ListSettingsDivProps {
  $justifyContent?: string;
  $loaderPadding?: boolean;
}

export const ContextMenuListSettingsDiv = styled.div<ListSettingsDivProps>`
  display: flex;
  justify-content: space-between;
  height: inherit;
  padding-top: ${(props) => (props.$loaderPadding ? "4px" : "0px")};
  justify-content: ${(props) =>
    props.$justifyContent ? props.$justifyContent : "space-between"};
`;
