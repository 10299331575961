import { cloneDeep } from "lodash";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSendDocumentForApprovalMutation } from "../../../apis/documentApi";
import { SnackbarMsg } from "../../../controls/Snackbar/SnackbarMessages";
import { DraftType } from "../../../models/documentDetails/documentStatus";
import { Workflow } from "../../../models/documentList/types/workflow";
import { ErrorApiResponse } from "../../../models/errorApi";
import { ErrorApiType } from "../../../models/errorApiType";
import { SnackbarType } from "../../../models/snackbar";
import { getNonEmptyDocumentIdentity } from "../../helpers/documentIdentityHelper";
import { isSecurityLevelNonLimited } from "../../helpers/securityLevelHelper";
import { openSnackbar } from "../../helpers/snackBarHelper";
import { selectUserEmail } from "../../slices/authenticationSlice";
import {
  setIsUpdatingDocument,
  setUsePrompt,
} from "../../slices/documentDetailsSlice";
import {
  selectCurrentDocumentIdentity,
  selectCurrentDocumentMetadata,
  selectDocumentDraftType,
  selectDocumentIdentity,
  selectDocumentWorkflow,
} from "../../slices/documentMetadataSlice";
import {
  selectHasAnySectionNotVisiblePublishError,
  selectIsPublishValidationOn,
} from "../../slices/documentMetadataValidationSlice";
import { useAppDispatch, useAppSelector } from "../hooks";

export function useSendDocumentForApprovalAction() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const draftType = useAppSelector(selectDocumentDraftType);
  const identity = useAppSelector(selectDocumentIdentity);
  const currentIdentity = useAppSelector(selectCurrentDocumentIdentity);
  const currentDocumentMetadata = useAppSelector(selectCurrentDocumentMetadata);
  const userEmail = useAppSelector(selectUserEmail);
  const hasAnyNotVisibleErrors = useAppSelector(
    selectHasAnySectionNotVisiblePublishError
  );
  const isPublishValidationOn = useAppSelector(selectIsPublishValidationOn);
  const [sendDocumentForApproval, { isLoading, isError, error, isSuccess }] =
    useSendDocumentForApprovalMutation();
  const workflow = useAppSelector(selectDocumentWorkflow);

  useEffect(() => {
    if (isLoading) {
      dispatch(setUsePrompt(false));
      dispatch(setIsUpdatingDocument(true));
    }
  }, [dispatch, isLoading]);

  const sendForApproval = () => {
    if (!hasAnyNotVisibleErrors || isPublishValidationOn) {
      const isNewDocument = workflow === Workflow.NewDocument;
      const isNewRevision = workflow === Workflow.NewRevision;
      const nonEmptyIdentity = getNonEmptyDocumentIdentity(
        isNewDocument ? currentIdentity : identity
      );
      const documentMetadata = cloneDeep(currentDocumentMetadata);
      if (isNewDocument || isNewRevision) {
        documentMetadata.workflow = Workflow.Approval;
      }

      documentMetadata.documentIdentity.documentNumber =
        documentMetadata.documentIdentity.documentNumber.trim();
      documentMetadata.documentIdentity.documentPart =
        documentMetadata.documentIdentity.documentPart.trim();
      documentMetadata.publisherEmail = userEmail;
      documentMetadata.accessGroups = isSecurityLevelNonLimited(
        documentMetadata.securityLevel
      )
        ? []
        : documentMetadata.accessGroups;

      void sendDocumentForApproval({
        documentMetadata: documentMetadata,
        revisionIdentity: nonEmptyIdentity,
        isNewDocument:
          isNewDocument || draftType === DraftType.NewDocumentDraft,
        isNewRevision: isNewRevision,
      });
    }
  };

  const sendForApprovalErrors = useMemo(() => {
    if (isError) {
      return !!error &&
        "status" in error &&
        !!(error.data as ErrorApiResponse | undefined)?.errors
        ? (error.data as ErrorApiResponse).errors
        : [{ message: "", code: ErrorApiType.Exception }];
    }

    return [];
  }, [isError, error]);

  useEffect(() => {
    if (isError) {
      dispatch(setUsePrompt(true));
      dispatch(setIsUpdatingDocument(false));
    }
  }, [dispatch, isError]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setIsUpdatingDocument(false));
      navigate("/");
      openSnackbar(
        dispatch,
        SnackbarMsg.DocumentApprovalSuccess,
        SnackbarType.success
      );
    }
  }, [dispatch, isSuccess, navigate]);

  return {
    sendForApproval,
    sendForApprovalErrors,
  };
}
