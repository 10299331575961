import styled from "styled-components";
import InfoIcon from "@mui/icons-material/Info";

export const StyledInfoIcon = styled(InfoIcon)`
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 9px;
  padding-right: 9px;
  color: ${(props) => props.theme.palettes.secondary.info};
`;
