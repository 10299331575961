import { AccessGroupPaperDiv } from "../SC/AccessGroupPaperDiv";
import { ContentPaperDiv } from "../SC/ContentPaperDiv";
import { DocumentKindsPaperDiv } from "../SC/DocumentKindsPaperDiv";
import { PermissionsPaperDiv } from "../SC/PermissionsPaperDiv";
import { TabDiv } from "../SC/TabDiv";
import { AccessGroupsPaper } from "./accessGroups/AccessGroupsPaper";
import { DocumentKindsPaper } from "./documentKinds/DocumentKindsPaper";
import { ConfigurationPaper } from "./configurations/ConfigurationsPaper";
import { PermissionsPaper } from "./permissions/PermissionsPaper";

interface ConfigTabProps {
  isLoading: boolean;
  isEditable: boolean;
}

export function ConfigTab(props: ConfigTabProps) {
  return (
    <TabDiv id="content-tab-div">
      <ContentPaperDiv id="content-paper-div">
        <AccessGroupPaperDiv>
          <AccessGroupsPaper
            isLoading={props.isLoading}
            disabled={!props.isEditable}
          />
        </AccessGroupPaperDiv>
        <DocumentKindsPaperDiv>
          <DocumentKindsPaper
            isLoading={props.isLoading}
            disabled={!props.isEditable}
          />
        </DocumentKindsPaperDiv>
        <PermissionsPaperDiv>
          <PermissionsPaper
            isLoading={props.isLoading}
            disabled={!props.isEditable}
          />
          <ConfigurationPaper
            isLoading={props.isLoading}
            disabled={!props.isEditable}
          />
        </PermissionsPaperDiv>
      </ContentPaperDiv>
    </TabDiv>
  );
}
