import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot2 from "../../../screenshots/title/ds_german_title_in_english.png";

export function TitleThirdStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                <Screenshot src={screenshot2} />
            </Paragraph>
        </StepContentDiv>
    );
}
