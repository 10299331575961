import { createStep } from "../../helpers/selfHelpBuilder";
import { SectionStepOne } from "./CMA/SectionStepOne";
import { SectionStepTwo } from "./CMA/SectionStepTwo";
import { SynchronizeStep } from "./CMA/SynchronizeStep";

export const cmaSteps = () => {
  return [
    createStep("#CountryMarketAvailability", <SectionStepOne />, 520, "auto"),
    createStep("#is-synchronized-with-cma-switch-div", <SynchronizeStep />, 520, "auto"),
    createStep("#CountryMarketAvailability", <SectionStepTwo />, 520, "auto"),
  ];
};
