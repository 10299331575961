import { Skeleton } from "@mui/material";
import styled from "styled-components";

interface StyledCellSkeletonProps {
  width: number;
}

export const StyledCellSkeleton = styled(Skeleton)<StyledCellSkeletonProps>`
  display: flex;
  width: ${(props) => props.width}px;
`;
