import { DocumentAttachment } from "../../models/documentDetails/documentAttachment";

export interface AttachmentChunks {
  size: number;
  count: number;
}

export interface FileInfo {
  name: string;
  extension: string;
}

export const maxAttachmentNameLength = 260;

export const calculateProgress = (
  allChunksCount: number,
  lastUploadChunk: number
) => {
  //+1 for complete attachment request
  const singleChunkProgress = 100 / (allChunksCount + 1);
  const currentProgress = singleChunkProgress * lastUploadChunk;
  return currentProgress < 99 ? Math.ceil(currentProgress) : currentProgress;
};

export const calculateChunkSizes = (file: File, chunkSize: number): number => {
  return Math.ceil(file.size / chunkSize);
};

export const createAttachmentData = (file: File, uploadId: string) => {
  const attachment: DocumentAttachment = {
    attachmentId: null,
    uploadId: uploadId,
    name: file.name,
    size: file.size,
  };

  return attachment;
};

export const convertBytesToMB = (bytes?: number) => {
  if (!bytes) {
    return "0";
  }

  const MBytes = bytes / 1024 / 1024;

  let result = "0";

  if (MBytes < 100) {
    result = MBytes.toFixed(2);
  } else if (MBytes < 999) {
    result = MBytes.toFixed(1);
  } else {
    result = MBytes.toFixed(0);
  }

  return result.replace(".", ",");
};

export const extractFileInfoFromFileName = (fullFileName: string): FileInfo => {
  const fileNameSubstrings = fullFileName.split(".");
  const isNameWithoutExtension = fileNameSubstrings.length === 1;

  const extension = isNameWithoutExtension
    ? ""
    : `.${fileNameSubstrings[fileNameSubstrings.length - 1]}`;
  const attachmentName = isNameWithoutExtension
    ? fullFileName
    : fullFileName.substring(0, fullFileName.lastIndexOf(extension));

  return {
    name: attachmentName,
    extension: extension,
  };
};

// url returns surrounded with quotation marks like this "url"
export function extractUrl(url: string | undefined): string {
  if (url) {
    return url.slice(1, url.length - 1);
  }

  return "";
}
