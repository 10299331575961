import styled from "styled-components";

export const StyledLinkDiv = styled.div`
  font-size: ${(props) => props.theme.typography.fontSizes.medium}px;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
  line-height: 1.25;
`;
