import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function CreatedByStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        This field indicates who created the scheduled action. Once the changes
        are applied, this field is updated with the name of the current creator.
      </Paragraph>
    </StepContentDiv>
  );
}
