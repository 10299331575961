import { DashDiv } from "../../../components/errors/SC/DashDiv";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ErrorHeaderDiv } from "../../../components/errors/SC/ErrorHeaderDiv";
import { ErrorBoxDiv } from "../../../components/errors/SC/ErrorBoxDiv";
import { ErrorContainerDiv } from "../../../components/errors/SC/ErrorContainerDiv";
import { ErrorContainerProps } from "../ErrorDisplay";
import ErrorMessage from "../../../components/errors/SC/ErrorMessage";
import StyledErrorMessageDiv from "../SC/StyledErrorMessageDiv";
import StyledErrorButton from "../SC/StyledErrorButton";

interface ErrorDisplayWrapperProps {
  errorMessageTitle?: string;
  children: React.ReactNode;
  showReloadButton?: boolean;
  doCenterHeader?: boolean;
  errorContainerProps?: ErrorContainerProps;
  errorBoxPadding?: string;
  errorBoxAlignItems?: string;
  showDash?: boolean;
  refreshFunction?: () => void;
  errorId: string;
}

export const ErrorDisplayWrapper = ({
  errorMessageTitle,
  children,
  errorContainerProps,
  errorBoxPadding,
  showReloadButton = false,
  showDash = true,
  errorId,
  refreshFunction = () => window.location.reload(),
  doCenterHeader = false,
}: ErrorDisplayWrapperProps) => {
  return (
    <ErrorContainerDiv
      padding={errorContainerProps?.padding}
      position={errorContainerProps?.position}
      transform={errorContainerProps?.transform}
      top={errorContainerProps?.top}
      left={errorContainerProps?.left}
      id={errorId + "-error-container"}
    >
      <ErrorBoxDiv padding={errorBoxPadding} id={errorId + "-error-box"}>
        {showDash && <DashDiv />}
        <ErrorHeaderDiv $doCenterHeader={doCenterHeader} $showDash={showDash}>
          {errorMessageTitle}
        </ErrorHeaderDiv>
        <ErrorMessage id={errorId + "-error-message"}>{children}</ErrorMessage>

        {showReloadButton && (
          <StyledErrorMessageDiv>
            <StyledErrorButton
              id={"refresh-button-id"}
              variant="contained"
              startIcon={<RefreshIcon fontSize="small" />}
              color="error"
              onClick={refreshFunction}
            >
              Reload
            </StyledErrorButton>
          </StyledErrorMessageDiv>
        )}
      </ErrorBoxDiv>
    </ErrorContainerDiv>
  );
};
