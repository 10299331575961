import { createApi } from "@reduxjs/toolkit/query/react";
import DocumentMetadata from "../models/documentDetails/documentMetadata";
import { DocumentStatus } from "../models/documentDetails/documentStatus";
import { publishApiBaseQuery } from "./baseQueries";

export const documentDetailsApi = createApi({
  reducerPath: "documentDetailsApi",
  baseQuery: publishApiBaseQuery,
  endpoints: (builder) => ({
    getDocumentMetadata: builder.query<DocumentMetadata, string>({
      query: (detailsPath) => ({
        url: `Documents/${detailsPath}`,
        method: "GET",
      }),
      //do not keep cache
      keepUnusedDataFor: 0.1,
      extraOptions: {
        setDetailsSplashScreen: true,
      },
    }),
    getDocumentStatus: builder.query<DocumentStatus, string>({
      query: (detailsPath) => ({
        url: `Documents/Status/${detailsPath}`,
        method: "GET",
      }),
      //do not keep cache
      keepUnusedDataFor: 0.1,
      extraOptions: {
        setDetailsSplashScreen: true,
      },
    }),
    isAvailableNewRevision: builder.query<boolean, string>({
      query: (detailsPath) => ({
        url: `Documents/NewRevisionAvailable/${detailsPath}`,
        method: "GET",
      }),
      //do not keep cache
      keepUnusedDataFor: 0.1,
      extraOptions: {
        setDetailsSplashScreen: true,
      },
    }),
  }),
});

export const {
  useGetDocumentMetadataQuery,
  useLazyGetDocumentMetadataQuery,
  useGetDocumentStatusQuery,
  useLazyGetDocumentStatusQuery,
  useIsAvailableNewRevisionQuery,
  useLazyIsAvailableNewRevisionQuery,
} = documentDetailsApi;

export default documentDetailsApi.reducer;
