import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SearchUserStep7() {
    return (
        <StepContentDiv>
            <Paragraph>
                Users can be searched by entering text. All users that meet the search criteria will be displayed.
            </Paragraph>
        </StepContentDiv>
    );
}
