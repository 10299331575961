import styled, { DefaultTheme } from "styled-components";
import { Workflow } from "../../../../models/documentList/types/workflow";

interface WorkflowFieldDivProps {
  workflow?: Workflow;
}

function getColor(theme: DefaultTheme, workflow?: Workflow) {
  switch (workflow) {
    case Workflow.Approval:
      return theme.palettes.secondary.approval;
    case Workflow.Archived:
      return theme.palettes.secondary.archived;
    case Workflow.Deleted:
      return theme.palettes.secondary.deleted;
    case Workflow.Draft:
    case Workflow.NewDocument:
    case Workflow.NewRevision:
      return theme.palettes.secondary.draft;
    case Workflow.Published:
      return theme.palettes.secondary.published;
    default:
      return "";
  }
}

export const WorkflowFieldDiv = styled.div<WorkflowFieldDivProps>`
  color: ${(props) => getColor(props.theme, props.workflow)};
  margin-bottom: 6px;
  font-weight: 700;
`;
