import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function CategoryTreeBoxStep1() {
  return (
    <StepContentDiv>
      <Paragraph>
        When a category is selected, the document list will show documents that
        are classified under that category, as well as categories that are
        nested below it.
      </Paragraph>
      <Paragraph>
        The number in brackets indicates the total number of document revisions
        classified under the specific category and its subcategories.
      </Paragraph>
      <Paragraph>All categories available for ABB are shown.</Paragraph>
    </StepContentDiv>
  );
}
