import styled from "styled-components";

export interface TitleSpanProps {
  $cursorPointer?: boolean;
  width: number;
}

export const TitleSpan = styled.span<TitleSpanProps>`
  width: ${(props) => props.width}px;
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  cursor: ${(props) => (props.$cursorPointer ? "pointer" : "unset")};
`;
