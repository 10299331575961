import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { Dictionary, sortBy } from "lodash";
import { Category } from "../../models/categoryTree";
import { RootState } from "../store";

interface PublisherCategoryTreeState {
  categoriesChildrenByParent: Dictionary<string[] | undefined>;
  categories: Dictionary<Category | undefined>;
}

const initialPublisherCategoryTreeState: PublisherCategoryTreeState = {
  categoriesChildrenByParent: {},
  categories: {},
};

export const publisherCategoryTreeSlice = createSlice({
  name: "publisherCategoryTreeState",
  initialState: initialPublisherCategoryTreeState,
  reducers: {
    clearPublisherCategories(state) {
      state.categories = {};
      state.categoriesChildrenByParent = {};
    },
    updatePublisherCategories(state, action: PayloadAction<Category[]>) {
      const categories = sortBy(action.payload, ["categoryName"]);
      categories.map((category) => {
        if (
          state.categoriesChildrenByParent[category.parentCategoryId] ===
          undefined
        ) {
          state.categoriesChildrenByParent[category.parentCategoryId] = [];
        }

        state.categoriesChildrenByParent[category.parentCategoryId]?.push(
          category.categoryId
        );
      });

      categories.forEach((category) => {
        const childrenByParent =
          state.categoriesChildrenByParent[category.categoryId];
        state.categories[category.categoryId] = {
          ...category,
          isExpanded: !!childrenByParent && childrenByParent.length > 0,
        };
      });
    },
    setIsExpanded(
      state,
      action: PayloadAction<{ categoryId: string; isExpanded: boolean }>
    ) {
      const { categoryId, isExpanded } = action.payload;
      if (state.categories[categoryId] !== undefined) {
        (state.categories[categoryId] as Category).isExpanded = isExpanded;
      }
    },
  },
});

export const {
  clearPublisherCategories,
  updatePublisherCategories,
  setIsExpanded,
} = publisherCategoryTreeSlice.actions;

const selectCategoriesByParentDictionary = (state: RootState) =>
  state.publisherCategoryTreeState.categoriesChildrenByParent;

const selectCategoriesDictionary = (state: RootState) =>
  state.publisherCategoryTreeState.categories;

export const selectCategoryChildrenByParentId = createSelector(
  [
    selectCategoriesByParentDictionary,
    (state, parentCategoryId: string) => parentCategoryId,
  ],
  (dictionary, parentCategoryId) => dictionary[parentCategoryId]
);

export const selectCategoryById = createSelector(
  [selectCategoriesDictionary, (state, categoryId: string) => categoryId],
  (dictionary, categoryId): Category | undefined => dictionary[categoryId]
);

export default publisherCategoryTreeSlice.reducer;
