import { TooltipTop } from "../../../controls/Tooltips/ToolipTop";
import { StyledLinkLabelIcon } from "./SC/StyledLinkLabelIcon";
import { StyledLockLabelIcon } from "./SC/StyledLockLabelIcon";
import { StyledServiceIntegrationLabelIcon } from "./SC/StyledServiceIntegrationLabelIcon";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";

interface LabelLockProps {
  message: TooltipMsg;
  marginBottom?: number;
  marginLeft?: number;
  size?: "medium" | "large";
  icon?: "link" | "lock" | "serviceIntegration";
}

function LabelIcon(props: LabelLockProps) {
  const getIcon = (icon: string | undefined) => {
    if (icon === undefined || icon === "lock") {
      return (
        <StyledLockLabelIcon
          $marginBottom={props.marginBottom}
          $marginLeft={props.marginLeft}
          size={props.size}
        />)
    }

    if (icon === "serviceIntegration") {
      return (<StyledServiceIntegrationLabelIcon
        $marginBottom={props.marginBottom}
        $marginLeft={props.marginLeft}
        size={props.size}
      />)
    }

    return (
      <StyledLinkLabelIcon
        $marginBottom={props.marginBottom}
        $marginLeft={props.marginLeft}
        size={props.size}
      />
    );
  }

  return (
    <TooltipTop title={props.message}>
      {getIcon(props.icon)}
    </TooltipTop>
  );
}

export default LabelIcon;
