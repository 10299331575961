import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { selectCurrentDocumentCma } from "../../../../../app/slices/documentMetadataSlice";
import {
  selectIsPublishValidationOn,
  setCmaValidationStatus,
} from "../../../../../app/slices/documentMetadataValidationSlice";
import { ValidationStatus } from "../../../../../models/validationRule";
import {
  ConditionValidationResult,
  ConditionValidationRules,
} from "../../common/ConditionValidationRules";
import SectionValidation from "../../common/SectionValidation";
import { CountryAvailabilityType } from "../../../../../models/documentDetails/countryMarketAvailability";

function CountryMarketAvailabilityValidation() {
  const dispatch = useAppDispatch();
  const cmaDocumentMetadata = useAppSelector(selectCurrentDocumentCma);
  const isPublishValidationOn = useAppSelector(selectIsPublishValidationOn);

  const validationRules: ConditionValidationRules = useMemo(() => {
    return {
      rules: [
        () => ({
          isError:
            !cmaDocumentMetadata.isSynchronizedWithCma &&
            cmaDocumentMetadata.countryAvailabilityType ===
              CountryAvailabilityType.AvailableInCountries &&
            cmaDocumentMetadata.countryIsoCodes.length === 0,
          errorMessage: "No country selected.",
          status: isPublishValidationOn
            ? ValidationStatus.error
            : ValidationStatus.notVisibleError,
        }),
        () => ({
          isError:
            !cmaDocumentMetadata.isSynchronizedWithCma &&
            cmaDocumentMetadata.countryAvailabilityType ===
              CountryAvailabilityType.NotAvailableInCountries &&
            cmaDocumentMetadata.countryIsoCodes.length === 0,
          errorMessage: "No country selected.",
          status: isPublishValidationOn
            ? ValidationStatus.error
            : ValidationStatus.notVisibleError,
        }),
      ],
    };
  }, [cmaDocumentMetadata, isPublishValidationOn]);

  const onValidationHandle = useCallback(
    (validationResult?: ConditionValidationResult) => {
      dispatch(
        setCmaValidationStatus(
          validationResult ? validationResult.status : ValidationStatus.success
        )
      );
    },
    [dispatch]
  );

  return (
    <SectionValidation
      rules={validationRules}
      onValidationHandle={onValidationHandle}
      marginBottom={2}
      marginTop={-10}
    />
  );
}

export default CountryMarketAvailabilityValidation;
