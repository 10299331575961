import { createStep } from "../../helpers/selfHelpBuilder";

export const publicationDateSteps = () => {
  return [
    createStep(
      "#navigation-filters-published-dates-div",
      "placeholder",
      320,
      "right"
    ),
  ];
};
