import { List } from "@mui/material";
import styled from "styled-components";

export const GroupsList = styled(List)`
  overflow-x: hidden;
  max-width: 100%;
  overflow-wrap: break-word;

  .MuiListItem-root:hover {
    background-color: ${(props) => props.theme.palettes.grayscale.lighter};
  }
`;
