import { useAppSelector } from "../../../../../app/hooks/hooks";
import { selectDensity } from "../../../../../app/slices/documentListSlice";
import { DocumentsColumnType } from "../../../../../models/documentList/types/DocumentColumnType";
import { DocumentsCellDiv } from "./styledComponents/DocumentsCellDiv";

export interface DocumentsHeaderCellProps {
  type: DocumentsColumnType;
  width: number;
  minWidth: number;
  maxWidth?: number;
  children?: JSX.Element;
  isVisible: boolean;
  className?: string;
  fitToScreen: boolean;
}

export function DocumentsCell(props: DocumentsHeaderCellProps) {
  const density = useAppSelector(selectDensity);
  return (
    <DocumentsCellDiv
      className={props.className}
      density={density}
      width={props.width}
      $minWidth={props.minWidth}
      $maxWidth={props.maxWidth}
      $isVisible={props.isVisible}
      type={props.type}
      $fitToScreen={props.fitToScreen}
    >
      {props.children}
    </DocumentsCellDiv>
  );
}
