import { ErrorDisplay } from "../../app/errorHandling/ErrorDisplay";
import { useAppSelector } from "../../app/hooks/hooks";
import { selectApiError } from "../../app/slices/apiErrorSlice";
import { UnuathorizedMessageSelector } from "./UnuathorizedMessageSelector";

export const UnauthorizedPage = () => {
  const apiError = useAppSelector(selectApiError);
  const userEmail = apiError.forbiddenErrorData.UserEmail;
  const errorCode = apiError.forbiddenErrorData.ErrorCode;

  return (
    <ErrorDisplay
      errorId={"screen-for-forbidden"}
      errorMessageTitle={`Authorization error ${errorCode}`}
    >
      <UnuathorizedMessageSelector
        userEmail={userEmail}
        errorCode={errorCode}
      />
    </ErrorDisplay>
  );
};
