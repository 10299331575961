import { useEffect, useState } from "react";
import { DocumentDetailView } from "../../../models/documentDetailView";
import { selectDocumentDetailView } from "../../slices/documentDetailsSlice";
import {
  selectDocumentMetadata,
  selectIsRevisionLater,
  selectIsRevisionLatest,
} from "../../slices/documentMetadataSlice";
import { useAppSelector } from "../hooks";
import { useDocumentState } from "./useDocumentState";
import { DraftType } from "../../../models/documentDetails/documentStatus";
import { Workflow } from "../../../models/documentList/types/workflow";

export function useDocumentFlow() {
  const isLatestRevision = useAppSelector(selectIsRevisionLatest);
  const isLaterRevision = useAppSelector(selectIsRevisionLater);
  const documentMetadata = useAppSelector(selectDocumentMetadata);
  const documentDetailView = useAppSelector(selectDocumentDetailView);
  const [isNewDocumentView, setIsNewDocumentView] = useState(false);
  const [isNewDocument, setIsNewDocument] = useState(false);
  const [isNewLanguageVersion, setIsNewLanguageVersion] = useState(false);
  const [isNewPartVersion, setIsNewPartVersion] = useState(false);
  const [isNewFromTemplate, setIsNewFromTemplate] = useState(false);
  const [isNewDocumentRevision, setIsNewDocumentRevision] = useState(false);
  const [isNewDocumentVersion, setIsNewDocumentVersion] = useState(false);
  const [isDeletedDocumentView, setIsDeletedDocumentView] = useState(false);
  const [isNewDocumentRevisionDraft, setIsNewDocumentRevisionDraft] =
    useState(false);
  const { isDocumentFromServiceIntegration, documentDraftType } =
    useDocumentState();
  useEffect(() => {
    setIsNewDocumentView(
      documentDetailView !== DocumentDetailView.EditDocument &&
        documentDetailView !== DocumentDetailView.NewRevision
    );
  }, [documentDetailView]);

  useEffect(() => {
    setIsDeletedDocumentView(documentMetadata.workflow === Workflow.Deleted);
  }, [documentMetadata.workflow]);

  useEffect(() => {
    setIsNewDocument(
      (documentDetailView !== DocumentDetailView.EditDocument &&
        documentDetailView !== DocumentDetailView.NewRevision) ||
        documentDraftType === DraftType.NewDocumentDraft
    );
  }, [documentDetailView, documentDraftType]);

  useEffect(() => {
    setIsNewLanguageVersion(
      documentDetailView === DocumentDetailView.NewLanguage
    );
  }, [documentDetailView]);

  useEffect(() => {
    setIsNewPartVersion(documentDetailView === DocumentDetailView.NewPart);
  }, [documentDetailView]);

  useEffect(() => {
    setIsNewFromTemplate(
      documentDetailView === DocumentDetailView.NewFromTemplate
    );
  }, [documentDetailView]);

  useEffect(() => {
    setIsNewDocumentRevision(
      documentDetailView === DocumentDetailView.NewRevision
    );
  }, [documentDetailView]);

  useEffect(() => {
    setIsNewDocumentRevisionDraft(
      documentDraftType === DraftType.NewDocumentRevision
    );
  }, [documentDraftType]);

  useEffect(() => {
    setIsNewDocumentVersion(
      documentDetailView === DocumentDetailView.NewFromTemplate ||
        documentDetailView === DocumentDetailView.NewPart ||
        documentDetailView === DocumentDetailView.NewLanguage
    );
  }, [documentDetailView]);

  return {
    isDocumentFromServiceIntegration,
    isLatestRevision,
    isLaterRevision,
    isNewLanguageVersion,
    isNewPartVersion,
    isNewFromTemplate,
    isNewDocument,
    isNewDocumentRevision,
    isNewDocumentVersion,
    isNewDocumentRevisionDraft,
    documentDetailView,
    documentDraftType,
    isNewDocumentView,
    isDeletedDocumentView,
  };
}
