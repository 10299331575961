import { Skeleton } from "@mui/material";
import { useCallback, useState } from "react";
import {
  ValidationRule,
  ValidationStatus,
} from "../../../models/validationRule";
import { LabelSpan } from "../SC/LabelSpan";
import LabelIcon from "./LabelIcon";
import { LabelContainerDiv } from "./SC/LabelContainerDiv";
import { SectionFieldDiv } from "./SC/SectionFieldDiv";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { TextFieldInput } from "./controls/TextFieldInput";

interface MultiTextProps {
  id: string;
  labelText?: string;
  inputValue: string;
  isEditable: boolean;
  isEdited?: boolean;
  isWarning?: boolean;
  wrapText?: boolean;
  onChangeHandler?: (newValue: string) => void;
  isLoading?: boolean;
  validationRules?: ValidationRule[];
  onInputValidation?: (result: ValidationStatus) => void;
  inputAdornment?: React.ReactNode;
  notEditableMessage?: TooltipMsg;
  placeholder?: string;
  disabled?: boolean;
}

export function MultiText(props: MultiTextProps) {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const isLoading = props.isLoading === true;

  const startEditing = useCallback(() => {
    setIsInEditMode(props.isEditable);
  }, [props.isEditable]);

  const stopEditing = useCallback(() => {
    setIsInEditMode(false);
  }, []);

  return (
    <SectionFieldDiv
      id={props.id}
      onMouseEnter={startEditing}
      onMouseLeave={stopEditing}
    >
      <LabelContainerDiv>
        {props.labelText && <LabelSpan>{props.labelText}:</LabelSpan>}
        {props.notEditableMessage && !props.isEditable && (
          <LabelIcon message={props.notEditableMessage} />
        )}
      </LabelContainerDiv>
      {isLoading ? (
        <Skeleton width={"auto"}>
          <TextFieldInput
            id={props.id + "-text-field"}
            isEditable={false}
            isInEditMode={false}
          />
        </Skeleton>
      ) : (
        <TextFieldInput
          id={props.id + "-text-field"}
          disabled={props.disabled}
          initialValue={props.inputValue}
          isEditable={props.isEditable}
          wrapText={props.wrapText}
          isInEditMode={isInEditMode}
          placeholder={props.placeholder}
          multiline
          onChange={props.onChangeHandler}
          isWarning={props.isWarning}
          isEdited={props.isEdited}
          validationRules={props.validationRules}
          inputAdornment={props.inputAdornment}
          onInputValidation={props.onInputValidation}
        />
      )}
    </SectionFieldDiv>
  );
}
