import styled from "styled-components";
import { ReflexSplitter } from "../../../controls/Reflex";

interface StyledReflexSplitterProps {
  $isMinimized: boolean;
}

export const StyledReflexSplitter = styled(
  ReflexSplitter
)<StyledReflexSplitterProps>`
  visibility: ${(props) => (props.$isMinimized ? "hidden" : "visible")};
`;
