import { trim } from "lodash";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { resetPageNumber } from "../../../../../app/slices/documentListSlice";
import {
  selectCurrentDocumentTitleFilter,
  selectSearchDocumentTitleFilter,
  setCurrentDocumentTitleFilter,
  setSearchDocumentTitleFilter,
} from "../../../../../app/slices/navigationSlice";
import { UncontrolledTextFieldInput } from "../../../../../controls/TextField/UncontrolledTextFieldInput";
import { InputDiv } from "../../../../documentsNavigation/filters/SC/InputDiv";

export function DocumentTitleFilter() {
  const dispatch = useAppDispatch();
  const searchDocumentTitle: string = useAppSelector(
    selectSearchDocumentTitleFilter
  );
  const currentDocumentTitle: string = useAppSelector(
    selectCurrentDocumentTitleFilter
  );

  const onChange = useCallback(
    (text: string) => {
      dispatch(setCurrentDocumentTitleFilter(text));
    },
    [dispatch]
  );

  const dispatchInputChange = useCallback(
    (text: string) => {
      dispatch(setSearchDocumentTitleFilter(trim(text)));
      dispatch(resetPageNumber());
    },
    [dispatch]
  );

  const onClick = useCallback(() => {
    if (searchDocumentTitle !== currentDocumentTitle) {
      dispatchInputChange(currentDocumentTitle);
    }
  }, [searchDocumentTitle, currentDocumentTitle, dispatchInputChange]);

  const onClear = useCallback(() => {
    dispatch(setCurrentDocumentTitleFilter(""));

    if (searchDocumentTitle !== "") {
      dispatchInputChange("");
    }
  }, [searchDocumentTitle, dispatch, dispatchInputChange]);

  return (
    <InputDiv
      id="navigation-filters-document-title"
      className="document-filter"
    >
      <UncontrolledTextFieldInput
        id="document-title-textfield"
        label="Document title"
        onClick={onClick}
        onChange={onChange}
        onClear={onClear}
        value={currentDocumentTitle}
      />
    </InputDiv>
  );
}
