import styled from "styled-components";
import { Button } from "@mui/material";

interface ShowMoreButtonProps {
  width?: number;
}

export const ShowMoreButton = styled(Button)<ShowMoreButtonProps>`
  &.MuiButtonBase-root {
    font-size: ${(props) => props.theme.typography.fontSizes.medium}px;
    color: ${(props) => props.theme.palettes.primary.dark};
  }

  &.MuiButtonBase-root:hover {
    color: ${(props) => props.theme.palettes.primary.main};
    background-color: ${(props) => props.theme.palettes.grayscale.lightest};
  }
`;
