import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function CloseButtonStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        This action does not apply any changes on a scheduled action and closes
        the window.
      </Paragraph>
    </StepContentDiv>
  );
}
