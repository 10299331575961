import { useState, useCallback, useEffect } from "react";
import { useAppSelector } from "../../../../../../app/hooks/hooks";
import {
  selectHasTagsSectionAnyValidationWarnings,
  selectSecuritySectionStatus,
} from "../../../../../../app/slices/documentMetadataValidationSlice";
import { ValidationStatus } from "../../../../../../models/validationRule";
import WarningPopup from "./WarningPopup";

interface WarningPopupItem {
  title: string;
  content: string;
  priority: number;
  accepted: boolean;
  id: string;
}

interface WarningPopupQueueProps {
  isOpen: boolean;
  onSave: () => void;
  onClose: () => void;
}

const securitySectionItem: WarningPopupItem = {
  title: "Save Document - Access Groups",
  content: `There are some incorrect access groups in document, highlighted by
  orange border. Document can be processed, but these values will be
  removed. Do you want to proceed?`,
  priority: 1,
  accepted: false,
  id: "security-warning-popup",
};

const tagsSectionItem: WarningPopupItem = {
  title: "Save Document - Tags",
  content: `There are some incorrect tags in document, highlighted by
  orange border. Document can be processed, but these values will be
  removed. Do you want to proceed?`,
  priority: 2,
  accepted: false,
  id: "tags-warning-popup",
};

const dialogItems = [securitySectionItem, tagsSectionItem];

function WarningPopupQueue(props: WarningPopupQueueProps) {
  const hasTagsSectionAnyValidationWarnings = useAppSelector(
    selectHasTagsSectionAnyValidationWarnings
  );
  const securitySectionStatus = useAppSelector(selectSecuritySectionStatus);
  const [queue, setQueue] = useState<WarningPopupItem[]>([]);

  const onSave = props.onSave;
  const onClose = props.onClose;

  const enqueue = useCallback(
    (item: WarningPopupItem) => {
      if (queue.find((elem) => elem.id === item.id)) {
        return;
      }

      setQueue(
        queue.concat(item).sort((a, b) => (a.priority > b.priority ? 1 : -1))
      );
    },
    [queue]
  );

  const setFirstAsAccepted = useCallback(() => {
    if (queue.length === 0) {
      return true;
    }

    const tmpQueue = [...queue];
    for (let i = 0; i < tmpQueue.length; i++) {
      if (!tmpQueue[i].accepted) {
        tmpQueue[i] = { ...tmpQueue[i], accepted: true };
        setQueue(tmpQueue);
        return i === tmpQueue.length - 1;
      }
    }

    return false;
  }, [queue]);

  const dequeue = useCallback(
    (item: WarningPopupItem) => {
      if (!queue.find((elem) => elem.id === item.id)) {
        return;
      }

      setQueue((prev) =>
        prev
          .filter((elem) => elem.id !== item.id)
          .sort((a, b) => (a.priority > b.priority ? 1 : -1))
      );
    },
    [queue]
  );

  const peek = useCallback(() => {
    return queue.find((elem) => !elem.accepted);
  }, [queue]);

  const onCloseDialog = useCallback(() => {
    setQueue([]);
    onClose();
  }, [onClose]);

  const onSaveDialog = useCallback(() => {
    const canSave = setFirstAsAccepted();

    if (canSave) {
      setQueue([]);
      onSave();
    }
  }, [onSave, setFirstAsAccepted]);

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }

    if (securitySectionStatus === ValidationStatus.warning) {
      enqueue(securitySectionItem);
    } else {
      dequeue(securitySectionItem);
    }

    if (hasTagsSectionAnyValidationWarnings) {
      enqueue(tagsSectionItem);
    } else {
      dequeue(tagsSectionItem);
    }
  }, [
    securitySectionStatus,
    hasTagsSectionAnyValidationWarnings,
    enqueue,
    dequeue,
    props.isOpen,
  ]);

  return (
    <>
      {dialogItems.map((item) => (
        <WarningPopup
          key={item.id}
          title={item.title}
          id={item.id}
          content={item.content}
          isOpen={props.isOpen && item.id === peek()?.id}
          onCloseClick={onCloseDialog}
          onConfirmClick={onSaveDialog}
        />
      ))}
    </>
  );
}

export default WarningPopupQueue;
