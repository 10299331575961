import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function DownloadStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                The Download button enables users to download the file to their local drive. It does not utilize the permanent link for this purpose.
            </Paragraph>
        </StepContentDiv >
    );
}
