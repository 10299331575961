import SaveAsIcon from "@mui/icons-material/SaveAs";
import { useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";

import { useSaveAsDraft } from "../../../../apis/hooks/useSaveAsDraft";
import { getNonEmptyDocumentIdentity } from "../../../../app/helpers/documentIdentityHelper";
import { isSecurityLevelNonLimited } from "../../../../app/helpers/securityLevelHelper";
import { useDocumentFlow } from "../../../../app/hooks/document/useDocumentFlow";
import { initialEditableState } from "../../../../app/hooks/permissions/models/state";
import { useUserCanEditDocument } from "../../../../app/hooks/permissions/useUserCanEditDocument";
import {
  selectApiErrorMessage,
  setApiErrors,
} from "../../../../app/slices/apiErrorSlice";
import { selectUserEmail } from "../../../../app/slices/authenticationSlice";
import {
  selectIsBreakingDocumentLink,
  selectIsUpdatingDocument,
  setUsePrompt,
} from "../../../../app/slices/documentDetailsSlice";
import {
  selectCategoriesAreEdited,
  selectCurrentDocumentMainCategory,
  selectCurrentDocumentMetadata,
  selectDocumentDraftType,
  selectDocumentIdentity,
  selectIsTrimmedIdentitySectionEdited,
  selectMainCategoryIsEdited,
} from "../../../../app/slices/documentMetadataSlice";
import {
  selectHasCurrentDocumentAnyValidationErrors,
  selectHasTagsSectionAnyValidationWarnings,
  selectSecuritySectionStatus,
} from "../../../../app/slices/documentMetadataValidationSlice";
import { selectIsFileUploadInitializing } from "../../../../app/slices/fileUploadSlice";
import {
  ActionButton,
  addVisibleButton,
  removeVisibleButton,
  selectDetailsHasRequiredUncompletedSections,
} from "../../../../app/slices/selfHelpSlice";
import { TextButtonControl } from "../../../../controls/Buttons/TextButtonControl";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import { Identity } from "../../../../models/documentDetails/documentMetadata";
import { DraftType } from "../../../../models/documentDetails/documentStatus";
import { Workflow } from "../../../../models/documentList/types/workflow";
import { ValidationStatus } from "../../../../models/validationRule";
import { ButtonDiv } from "../SC/ButtonDiv";
import { ErrorApiPopup } from "./popups/error/ErrorApiPopup";
import { ErrorPopup, SaveAsDraftErrorType } from "./popups/error/ErrorPopup";
import WarningPopupQueue from "./popups/warning/WarningPopupQueue";

interface SaveAsDraftButtonProps {
  disabled?: boolean;
  disabledTooltipText?: string;
}

export function SaveAsDraftButton(props: SaveAsDraftButtonProps) {
  const [errorType, setErrorType] = useState<SaveAsDraftErrorType | null>(null);
  const [warningPopupIsOpen, setWarningPopupIsOpen] = useState(false);
  const theme = useTheme();
  const identityIsEdited = useAppSelector(selectIsTrimmedIdentitySectionEdited);
  const categoriesIsEdited = useAppSelector(selectCategoriesAreEdited);
  const mainCategoryIsEdited = useAppSelector(selectMainCategoryIsEdited);
  const apiError = useAppSelector(selectApiErrorMessage);
  const selectedMainCategory = useAppSelector(
    selectCurrentDocumentMainCategory
  );
  const draftType = useAppSelector(selectDocumentDraftType);
  const { isNewDocument, isNewDocumentRevision, isNewDocumentView } =
    useDocumentFlow();
  const lastDocumentIdentity = useAppSelector(selectDocumentIdentity);
  const documentMetadata = useAppSelector(selectCurrentDocumentMetadata);
  const hasCurrentDocumentAnyValidationErrors = useAppSelector(
    selectHasCurrentDocumentAnyValidationErrors
  );
  const hasTagsSectionAnyValidationWarnings = useAppSelector(
    selectHasTagsSectionAnyValidationWarnings
  );
  const securitySectionStatus = useAppSelector(selectSecuritySectionStatus);
  const isFileUploadInitializing = useAppSelector(
    selectIsFileUploadInitializing
  );
  const showWarningPopup =
    securitySectionStatus === ValidationStatus.warning ||
    hasTagsSectionAnyValidationWarnings;
  const isSavingDocumentDraft = useAppSelector(selectIsUpdatingDocument);
  const [documentIdentity, setDocumentIdentity] = useState<Identity | null>(
    null
  );
  const isBreakingDocumentLink = useAppSelector(selectIsBreakingDocumentLink);
  const userEmail = useAppSelector(selectUserEmail);
  const hasUncompletedSections = useAppSelector(
    selectDetailsHasRequiredUncompletedSections
  );

  const {
    saveDraftForExistingDocument,
    updateDraftForNewDocument,
    createDraftForNewDocument,
    saveDraftForNewRevision,
  } = useSaveAsDraft();

  const dispatch = useAppDispatch();
  const [editableState, setEditableState] = useState(initialEditableState);
  const { canEditDocument } = useUserCanEditDocument();

  useEffect(() => {
    setEditableState(canEditDocument(false));
  }, [canEditDocument]);

  useEffect(() => {
    if (isNewDocumentView || isNewDocumentRevision) {
      setDocumentIdentity(documentMetadata.documentIdentity);
    } else {
      setDocumentIdentity(lastDocumentIdentity);
    }
  }, [
    lastDocumentIdentity,
    isNewDocumentView,
    isNewDocumentRevision,
    documentMetadata.documentIdentity,
  ]);

  const areNecessaryFieldsMissing = useCallback(() => {
    if (isNewDocument || isNewDocumentRevision) {
      return (
        selectedMainCategory === undefined ||
        !documentMetadata.documentIdentity.documentNumber ||
        (!documentMetadata.attachment.attachmentId &&
          !documentMetadata.attachment.uploadId)
      );
    }

    return false;
  }, [
    isNewDocument,
    isNewDocumentRevision,
    selectedMainCategory,
    documentMetadata,
  ]);

  const isButtonDisabled =
    areNecessaryFieldsMissing() ||
    props.disabled ||
    hasCurrentDocumentAnyValidationErrors ||
    isFileUploadInitializing ||
    isSavingDocumentDraft ||
    isBreakingDocumentLink ||
    hasUncompletedSections;

  const getDisabledTooltip = useCallback(() => {
    if (hasUncompletedSections) {
      return TooltipMsg.UncompletedSelfHelpSections;
    }

    return props.disabled
      ? props.disabledTooltipText
      : areNecessaryFieldsMissing()
        ? "Document number, attachment or Main Category are missing. Draft cannot be saved"
        : isFileUploadInitializing
          ? "You cannot save document as draft because document upload is currently initializing"
          : hasCurrentDocumentAnyValidationErrors
            ? "You cannot save document as draft because it has some validation errors"
            : undefined;
  }, [
    areNecessaryFieldsMissing,
    isFileUploadInitializing,
    hasCurrentDocumentAnyValidationErrors,
    props.disabledTooltipText,
    props.disabled,
    hasUncompletedSections,
  ]);

  const saveDocumentAsDraft = useCallback(() => {
    if (documentIdentity) {
      const identity = getNonEmptyDocumentIdentity(documentIdentity);
      const securityLevel = documentMetadata.securityLevel;
      const documentData = {
        identity: identity,
        documentMetadata: {
          ...documentMetadata,
          accessGroups: isSecurityLevelNonLimited(securityLevel)
            ? []
            : documentMetadata.accessGroups,
          publisherEmail: userEmail,
          documentIdentity: {
            ...documentMetadata.documentIdentity,
            documentNumber:
              documentMetadata.documentIdentity.documentNumber.trim(),
            documentPart: documentMetadata.documentIdentity.documentPart.trim(),
          },
        },
      };

      switch (true) {
        case isNewDocumentView:
          dispatch(setUsePrompt(false));
          documentData.documentMetadata.workflow = Workflow.Draft;
          void createDraftForNewDocument(documentData);
          break;
        case draftType === DraftType.NewDocumentDraft:
          dispatch(setUsePrompt(false));
          void updateDraftForNewDocument(documentData);
          break;
        case draftType === DraftType.NewDocumentRevision:
          dispatch(setUsePrompt(false));
          documentData.documentMetadata.workflow = Workflow.Draft;
          void saveDraftForNewRevision(documentData);
          break;
        default:
          void saveDraftForExistingDocument(documentData);
          break;
      }
    }
  }, [
    documentIdentity,
    documentMetadata,
    userEmail,
    createDraftForNewDocument,
    draftType,
    updateDraftForNewDocument,
    saveDraftForNewRevision,
    saveDraftForExistingDocument,
    isNewDocumentView,
    dispatch,
  ]);

  const buttonClickHandler = useCallback(() => {
    if (!isNewDocument && !isNewDocumentRevision && identityIsEdited) {
      setErrorType(SaveAsDraftErrorType.IdentityChanged);
      return;
    }

    if (!isNewDocument && mainCategoryIsEdited) {
      setErrorType(SaveAsDraftErrorType.MainCategoryChanged);
      return;
    }

    if (categoriesIsEdited && !editableState.isEditable) {
      setErrorType(SaveAsDraftErrorType.CategoriesChanged);
      return;
    }

    if (showWarningPopup) {
      setWarningPopupIsOpen(true);
      return;
    }

    saveDocumentAsDraft();
  }, [
    identityIsEdited,
    isNewDocument,
    mainCategoryIsEdited,
    categoriesIsEdited,
    editableState.isEditable,
    showWarningPopup,
    isNewDocumentRevision,
    saveDocumentAsDraft,
  ]);

  const onWarningPopupSave = useCallback(() => {
    setWarningPopupIsOpen(false);
    saveDocumentAsDraft();
  }, [saveDocumentAsDraft]);

  const onWarningPopupClose = useCallback(() => {
    setWarningPopupIsOpen(false);
  }, []);

  useEffect(() => {
    dispatch(addVisibleButton(ActionButton.SaveAsDraft));

    return () => {
      dispatch(removeVisibleButton(ActionButton.SaveAsDraft));
    };
  }, [dispatch]);

  return (
    <ButtonDiv>
      <ErrorPopup
        isOpen={errorType !== null}
        onClose={() => setErrorType(null)}
        reason={errorType}
      />
      {apiError.length > 0 && (
        <ErrorApiPopup
          isOpen={!!apiError}
          onClose={() => dispatch(setApiErrors([]))}
          reasons={apiError}
          id="disabled-save-draft-popup"
        />
      )}
      <WarningPopupQueue
        isOpen={warningPopupIsOpen}
        onSave={onWarningPopupSave}
        onClose={onWarningPopupClose}
      />
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.primaryButtonHeight}
        id={"document-details-save-as-draft-button"}
        text={"Save as draft"}
        disabled={isButtonDisabled}
        disabledTooltipText={getDisabledTooltip()}
        onClick={buttonClickHandler}
        isCompactView={false}
        isVertical={false}
        isVisible={true}
        icon={<SaveAsIcon fontSize="small" />}
      />
    </ButtonDiv>
  );
}
