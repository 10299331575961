import styled from "styled-components";

interface LabelSpanParams {
  float?: string;
  marginBottom?: number;
  clickable?: boolean;
}

export const LabelSpan = styled.span<LabelSpanParams>`
  color: ${(props) => props.theme.palettes.grayscale.dark};
  float: ${(props) => (props.float ? props.float : "none")};
  margin-right: 8px;
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
  margin-bottom: ${(props) =>
    props.marginBottom ? `${props.marginBottom}px` : "0px"};
`;
