import { useCallback, useEffect, useMemo, useState } from "react";
import { ErrorDisplay } from "../../../../../app/errorHandling/ErrorDisplay";
import { openSnackbar } from "../../../../../app/helpers/snackBarHelper";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import {
  publishersAbortReason,
  useInitialPublisherCategoriesFetch,
} from "../../../../../app/hooks/useInitialPublisherCategoriesFetch";
import { useMatchingPublisherCategoriesFetch } from "../../../../../app/hooks/useMatchingPublisherCategoriesFetch";
import { setShouldRefreshDocumentCma } from "../../../../../app/slices/documentDetailsSlice";
import {
  selectCategorySearch,
  selectCurrentDocumentMainCategory,
  selectSelectedCategoryNodes,
  selectVisibleCategorySearch,
  setEditedCategories,
  setEditedMainCategory,
} from "../../../../../app/slices/documentMetadataSlice";
import { SectionName } from "../../../../../app/slices/models/documentDetailsModels";
import { DialogControl } from "../../../../../controls/Dialog/DialogControl";
import { SnackbarMsg } from "../../../../../controls/Snackbar/SnackbarMessages";
import { CurrentSelfHelp } from "../../../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../../../models/selfHelp/selfHelpSection";
import { SnackbarType } from "../../../../../models/snackbar";
import { CategorySearch } from "./CategorySearch/CategorySearch";
import { CategoryTree } from "./categoryTree/CategoryTree";
import { DialogContentDiv } from "./SC/DialogContentDiv";
import { ValidationMessage } from "./ValidationMessage";

interface AddCategoriesDialogProps {
  isOpen: boolean;
  onCloseClick: () => void;
}

const minQueryLength = 3;

export function AddCategoriesDialog(props: AddCategoriesDialogProps) {
  const dispatch = useAppDispatch();
  const selectedCategoryNodes = useAppSelector(selectSelectedCategoryNodes);
  const searchQuery = useAppSelector(selectCategorySearch);
  const visibleSearchQuery = useAppSelector(selectVisibleCategorySearch);
  const currentMainCategory = useAppSelector(selectCurrentDocumentMainCategory);
  const isPublishersQuery = searchQuery.length === 0;
  const [abortController, setAbortController] = useState<AbortController>(
    new AbortController()
  );

  const {
    isLoading: isLoadingPublishers,
    isError: isPublishersError,
    isSuccess: isPublishersSuccess,
    fetch: publishersRefetch,
    cancel: cancelInitialPublisherCategoriesFetch,
    error: errorInitialPublishers,
  } = useInitialPublisherCategoriesFetch({
    query: searchQuery,
    isOpen: props.isOpen,
  });

  const {
    isLoading: isLoadingMatchingPublishers,
    isError: isMatchingPublishersError,
    isSuccess: isMatchingPublishersSuccess,
    fetch: matchingPublishersRefetch,
    cancel: cancelMatchingPublisherCategoriesFetch,
    error: errorMatchingPublishers,
  } = useMatchingPublisherCategoriesFetch({
    query: searchQuery,
    visibleQuery: visibleSearchQuery,
    minQueryLength,
    isOpen: props.isOpen,
  });
  const onCloseClick = props.onCloseClick;

  const isInitialError = useMemo(
    () =>
      isPublishersError &&
      (errorInitialPublishers as { error: string } | undefined)?.error !==
      publishersAbortReason,
    [errorInitialPublishers, isPublishersError]
  );

  const isMatchingError = useMemo(
    () =>
      isMatchingPublishersError &&
      (errorMatchingPublishers as { error: string } | undefined)?.error !==
      publishersAbortReason,
    [errorMatchingPublishers, isMatchingPublishersError]
  );

  const isError = useMemo(
    () => isInitialError || isMatchingError,
    [isInitialError, isMatchingError]
  );

  useEffect(() => {
    if (isError) {
      openSnackbar(
        dispatch,
        SnackbarMsg.PublisherCategoriesError,
        SnackbarType.error
      );
    }
  }, [dispatch, isError]);

  const onClose = useCallback(() => {
    abortController.abort(publishersAbortReason);
    setAbortController(new AbortController());
    cancelInitialPublisherCategoriesFetch();
    cancelMatchingPublisherCategoriesFetch();
    onCloseClick();
  }, [
    abortController,
    onCloseClick,
    cancelInitialPublisherCategoriesFetch,
    cancelMatchingPublisherCategoriesFetch,
  ]);

  const onSaveNewCategories = () => {
    dispatch(
      setEditedCategories(
        selectedCategoryNodes.map((cat) => {
          return cat.categoryId;
        })
      )
    );
    dispatch(setShouldRefreshDocumentCma(true));
    if (selectedCategoryNodes.length > 0 && !currentMainCategory) {
      dispatch(setEditedMainCategory(selectedCategoryNodes[0].categoryId));
    }
    onCloseClick();
  };

  return (
    <DialogControl
      disableButtonAutoFocus
      id={"new-categories-dialog"}
      isOpen={props.isOpen}
      onCloseClick={onClose}
      onCancelClick={onClose}
      onConfirmClick={onSaveNewCategories}
      useActions={true}
      fullWidth={true}
      fullHeight={!isError}
      useSelfHelp={true}
      selfHelpPage={SelfHelpPage.Details}
      selfHelpSection={SectionName.AddCategories}
      selfHelp={CurrentSelfHelp.DetailsAddCategories}
      confirmText="Save changes"
      title={"Add new categories to this document"}
      padding={isError ? "0px" : "0px 24px 0px 24px;"}
      content={
        isError ? (
          <ErrorDisplay
            errorId={"category"}
            errorMessageTitle={"Something went wrong"}
            errorDescription={"Categories add loading failed"}
            errorContainerProps={{
              padding: "40px 0px 40px 0px",
            }}
            refreshFunction={
              isMatchingError ? matchingPublishersRefetch : publishersRefetch
            }
            showDash={false}
            doCenterHeader={true}
            showReloadButton={true}
          />
        ) : (
          <DialogContentDiv id="category-tree-dialog-content">
            <CategorySearch minQueryLength={minQueryLength} autoFocus />
            <CategoryTree
              isLoading={
                isPublishersQuery
                  ? isLoadingPublishers
                  : isLoadingMatchingPublishers
              }
              isSuccess={
                isPublishersQuery
                  ? isPublishersSuccess
                  : isMatchingPublishersSuccess
              }
              isOpen={props.isOpen}
              abortController={abortController}
            />
            <ValidationMessage />
          </DialogContentDiv>
        )
      }
      maxWidth={"sm"}
    />
  );
}
