import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  CategoryGroup,
  CategoryWithPermissions,
} from "../../models/documentDetails/documentMetadata";

interface CategoriesState {
  categoryGroups: CategoryGroup[];
  documentCategoriesWithPermissions: CategoryWithPermissions[];
}

const initialState = {
  categoryGroups: [],
  documentCategoriesWithPermissions: [],
} as CategoriesState;

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategoryGroups(
      state: CategoriesState,
      action: PayloadAction<CategoryGroup[]>
    ) {
      state.categoryGroups = action.payload;
    },
    setCategoryPermissions(
      state: CategoriesState,
      action: PayloadAction<CategoryWithPermissions[]>
    ) {
      state.documentCategoriesWithPermissions = action.payload;
    },
  },
});

export const { setCategoryGroups, setCategoryPermissions } =
  categoriesSlice.actions;

export const selectCategoryGroups = (state: RootState) =>
  state.categories.categoryGroups;

export const selectDocumentCategoriesWithPermissions = (state: RootState) =>
  state.categories.documentCategoriesWithPermissions;

export default categoriesSlice.reducer;
