import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTheme } from "styled-components";
import { useAttachmentUploadStatus } from "../../../../apis/hooks/useAttachmentUploadStatus";
import { calculateProgress } from "../../../../app/helpers/attachmentHelper";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { selectIsWindowTooSmall } from "../../../../app/slices/commonSlice";
import {
  clearEditedDocumentAttachment,
  selectCurrentDocumentAttachment,
  setEditedAttachment,
} from "../../../../app/slices/documentMetadataSlice";
import {
  clearFileUploadError,
  selectAttachmentUploadInfo,
  selectIsFileUploadAborted,
  selectIsFileUploading,
  selectLastFetchedAttachmentId,
  selectLastFetchedUploadId,
  selectShouldAbortFileUpload,
  selectUploadProgress,
  setIsAttachmentUploadInProgress,
  setIsFileUploadAborted,
  setIsFileUploadInitializing,
  setIsFileUploading,
  setIsSingleChunkUploading,
  setShouldAbortFileUpload,
  setUploadProgress,
} from "../../../../app/slices/fileUploadSlice";
import { CircularProgressBar } from "../../../../controls/ProgressIndicators/CircularProgressBar";
import {
  AttachmentUploadInfo,
  DocumentAttachment,
} from "../../../../models/documentDetails/documentAttachment";
import { UploadStatus } from "../../../../models/documentDetails/uploadStatus";
import FileUpload, {
  FileUploadHandler,
} from "../../inputs/controls/FileUpload";
import { UploadInProgressControl } from "./UploadInProgressControl";

interface AttachmentUploadControl {
  isEditable: boolean;
}

export const pauseUploadReason = "File upload was paused";

export function AttachmentUploadControl(props: AttachmentUploadControl) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const uploadProgress = useAppSelector(selectUploadProgress);
  const isFileUploading = useAppSelector(selectIsFileUploading);
  const attachmentUploadInfo = useAppSelector(selectAttachmentUploadInfo);
  const lastFetchedAttachmentId = useAppSelector(selectLastFetchedAttachmentId);
  const lastFetchedUploadId = useAppSelector(selectLastFetchedUploadId);
  const fileUploadRef = useRef<FileUploadHandler>(null);
  const isWindowTooSmall = useAppSelector(selectIsWindowTooSmall);
  const currentDocumentAttachment = useAppSelector(
    selectCurrentDocumentAttachment
  );
  const shouldAbortFileUpload = useAppSelector(selectShouldAbortFileUpload);
  const isFileUploadAborted = useAppSelector(selectIsFileUploadAborted);
  const abortControllers = useMemo(() => new Array<AbortController>(), []);
  const runOnce = useRef(false);
  const { fetchUploadInfo, fetchAttachmentInfo, isAttachmentInfoLoaded } =
    useAttachmentUploadStatus();

  const updateUploadMode = useCallback(
    (attachmentStatus: AttachmentUploadInfo) => {
      const isUploadInProgress =
        attachmentStatus.status == UploadStatus.UploadInProgress ||
        attachmentStatus.status == UploadStatus.Created;
      dispatch(setIsAttachmentUploadInProgress(isUploadInProgress));

      if (isUploadInProgress) {
        dispatch(
          setUploadProgress(
            calculateProgress(
              attachmentStatus.chunksCount,
              attachmentStatus.lastSuccessfullyUploadedChunkNumber
            )
          )
        );
      }
    },
    [dispatch]
  );

  const clearAbortControllers = useCallback(
    (abortReason?: string) => {
      abortControllers.forEach((controller) => controller.abort(abortReason));
      abortControllers.length = 0;
    },
    [abortControllers]
  );

  useEffect(() => {
    if (
      currentDocumentAttachment.attachmentId &&
      currentDocumentAttachment.attachmentId !== lastFetchedAttachmentId &&
      currentDocumentAttachment.attachmentId !== ""
    ) {
      fetchAttachmentInfo(currentDocumentAttachment.attachmentId);
    } else if (
      currentDocumentAttachment.uploadId &&
      currentDocumentAttachment.uploadId !== lastFetchedUploadId &&
      currentDocumentAttachment.uploadId !== ""
    ) {
      fetchUploadInfo(currentDocumentAttachment.uploadId);
    }
  }, [
    currentDocumentAttachment.attachmentId,
    currentDocumentAttachment.uploadId,
    fetchUploadInfo,
    fetchAttachmentInfo,
    lastFetchedAttachmentId,
    lastFetchedUploadId,
  ]);

  useEffect(() => {
    if (attachmentUploadInfo) {
      updateUploadMode(attachmentUploadInfo);
    }
  }, [attachmentUploadInfo, dispatch, updateUploadMode]);

  const onUploadInitialize = useCallback(() => {
    dispatch(setIsFileUploadInitializing(true));
  }, [dispatch]);

  const onChunkSizeCalculation = useCallback(
    (isSingleFileUpload: boolean) => {
      dispatch(setIsSingleChunkUploading(isSingleFileUpload));
    },
    [dispatch]
  );

  const onUploadInitError = useCallback(() => {
    dispatch(setIsFileUploadInitializing(false));
  }, [dispatch]);

  const onUploadStart = useCallback(
    (data: DocumentAttachment) => {
      dispatch(setIsFileUploadInitializing(false));
      dispatch(setIsFileUploading(true));
      dispatch(setEditedAttachment(data));
      dispatch(setIsFileUploadAborted(false));
    },
    [dispatch]
  );

  const onReportProgress = useCallback(
    (progress: number) => {
      dispatch(setUploadProgress(progress));
    },
    [dispatch]
  );

  const onUploadSuccess = useCallback(() => {
    dispatch(setIsFileUploading(false));
    dispatch(clearFileUploadError());
  }, [dispatch]);

  const abortUpload = useCallback(
    (clearAttachment: boolean) => {
      clearAbortControllers();
      fileUploadRef.current?.clearFile();

      dispatch(setIsFileUploadInitializing(false));
      dispatch(setIsFileUploading(false));
      dispatch(clearFileUploadError());

      if (clearAttachment) {
        dispatch(
          setEditedAttachment({
            attachmentId: null,
            uploadId: null,
            name: "",
            size: 0,
          })
        );
        dispatch(setIsFileUploadAborted(true));
      } else {
        dispatch(setIsFileUploadAborted(false));
      }
    },
    [clearAbortControllers, dispatch]
  );

  const onUploadFailed = useCallback(() => {
    if (
      currentDocumentAttachment.attachmentId &&
      currentDocumentAttachment.attachmentId !== ""
    ) {
      dispatch(clearEditedDocumentAttachment());
      dispatch(setShouldAbortFileUpload(true));
      fetchAttachmentInfo(currentDocumentAttachment.attachmentId);
    } else {
      abortUpload(true);
    }
  }, [
    dispatch,
    currentDocumentAttachment.attachmentId,
    fetchAttachmentInfo,
    abortUpload,
  ]);

  const resumeUpload = useCallback(() => {
    if (attachmentUploadInfo === undefined) return;

    if (
      attachmentUploadInfo.chunksCount ==
        attachmentUploadInfo.lastSuccessfullyUploadedChunkNumber &&
      currentDocumentAttachment.uploadId
    ) {
      fileUploadRef.current?.completeFileUpload(
        currentDocumentAttachment.uploadId
      );
      return;
    }

    fileUploadRef.current?.resumeFileUpload();
  }, [attachmentUploadInfo, currentDocumentAttachment.uploadId]);

  const pauseUpload = useCallback(() => {
    clearAbortControllers(pauseUploadReason);
    dispatch(clearFileUploadError());

    if (currentDocumentAttachment.uploadId) {
      fetchUploadInfo(currentDocumentAttachment.uploadId);
      dispatch(setIsFileUploading(false));
    }
  }, [
    clearAbortControllers,
    currentDocumentAttachment.uploadId,
    dispatch,
    fetchUploadInfo,
  ]);

  useEffect(() => {
    if (shouldAbortFileUpload) {
      abortUpload(false);

      if (attachmentUploadInfo) {
        updateUploadMode(attachmentUploadInfo);
      }

      dispatch(setShouldAbortFileUpload(false));
    }
  }, [
    abortUpload,
    attachmentUploadInfo,
    dispatch,
    isFileUploading,
    shouldAbortFileUpload,
    updateUploadMode,
  ]);

  useEffect(() => {
    return clearAbortControllers;
  }, [clearAbortControllers]);

  useEffect(() => {
    if (!runOnce.current) {
      if (!isWindowTooSmall) {
        clearAbortControllers();
        dispatch(setIsFileUploading(false));
      } else if (currentDocumentAttachment.uploadId) {
        fetchUploadInfo(currentDocumentAttachment.uploadId);
      } else if (currentDocumentAttachment.attachmentId) {
        fetchAttachmentInfo(currentDocumentAttachment.attachmentId);
      }
    }
    return () => {
      runOnce.current = true;
    };
  }, [
    clearAbortControllers,
    currentDocumentAttachment.attachmentId,
    currentDocumentAttachment.uploadId,
    dispatch,
    fetchUploadInfo,
    fetchAttachmentInfo,
    isWindowTooSmall,
  ]);

  if (isAttachmentInfoLoaded) {
    return (
      <>
        <UploadInProgressControl
          onStart={resumeUpload}
          onPause={pauseUpload}
          onStop={() => {
            abortUpload(true);
          }}
          progress={uploadProgress}
          canResumeUpload={attachmentUploadInfo?.canResumeUpload}
        />
        <FileUpload
          ref={fileUploadRef}
          disabled={!props.isEditable}
          attachmentUploadInfo={attachmentUploadInfo}
          onUploadSuccess={onUploadSuccess}
          onUploadFailed={onUploadFailed}
          onUploadStart={onUploadStart}
          onReportProgress={onReportProgress}
          onUploadInitialize={onUploadInitialize}
          onChunkSizeCalculation={onChunkSizeCalculation}
          onUploadInitError={onUploadInitError}
          abortControllers={abortControllers}
          buttonText={
            attachmentUploadInfo?.status == UploadStatus.Completed &&
            !isFileUploadAborted
              ? "Replace file"
              : "Upload a file"
          }
          dragAndDropText={
            attachmentUploadInfo?.status == UploadStatus.Completed &&
            !isFileUploadAborted
              ? "...or drag and drop a file to change it."
              : "...or drag and drop a file."
          }
        />
      </>
    );
  } else {
    return (
      <CircularProgressBar
        size={theme.circularProgress.medium}
        space={isFileUploading ? 36 : 110}
        color="secondary"
      />
    );
  }
}
