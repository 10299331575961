import { IconButton } from "@mui/material";
import styled from "styled-components";

interface StyledCopyButtonProps {
  $fontSize?: number;
  $isVisible?: boolean;
  $isLoading?: boolean;
}

export const StyledCopyButton = styled(IconButton)<StyledCopyButtonProps>`
  &.MuiIconButton-root {
    border-radius: 15%;
    padding: 4px;
    margin-left: auto;
    visibility: ${(props) =>
      props.$isVisible === false ? "hidden" : "visible"};
    display: ${(props) => (props.$isLoading ? "none" : "flex")};
  }

  &.MuiIconButton-root:active {
    background-color: ${(props) =>
      props.theme.palettes.grayscale.darkerHighlight};
  }

  &.MuiIconButton-root:disabled {
    background-color: ${(props) => props.theme.palettes.primary.light};
  }

  .MuiTouchRipple-root {
    width: 0px;
    height: 0px;
  }

  ${(props) =>
    props.$fontSize &&
    `
      svg {
        font-size: ${props.$fontSize}px;
      }
  `}
`;
