import styled from "styled-components";
import { MenuItem } from "@mui/material";

export const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root.Mui-selected {
    background-color: ${(props) => props.theme.palettes.grayscale.lighter};
  }

  &.MuiMenuItem-root.Mui-selected:hover {
    background-color: ${(props) => props.theme.palettes.grayscale.lightest};
  }

  &.MuiMenuItem-root:hover {
    background-color: ${(props) => props.theme.palettes.grayscale.lightest};
  }
`;
