import { StyledSkeleton } from "./SC/StyledSkeleton";

interface TreeItemOwnerSkeletonProps {
  parentCategoryId: string;
  numbersOfSkeleton: number;
  padding: number;
  width: number;
}

export function TreeItemOwnerSkeleton(props: TreeItemOwnerSkeletonProps) {
  return (
    <>
      {Array.from({ length: props.numbersOfSkeleton }, (_, i) => (
        <StyledSkeleton
          key={props.parentCategoryId + "-skeleton" + i}
          $marginLeft={props.padding}
          width={props.width}
          variant="text"
        />
      ))}
    </>
  );
}
