import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { ButtonDiv } from "../../SC/ButtonDiv";
import { useTheme } from "styled-components";
import { TextButtonControl } from "../../../../../controls/Buttons/TextButtonControl";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";

interface ResetViewProps {
  isCompactView: boolean;
  disabled?: boolean;
  onClickHandler: () => void;
}

export function ResetView(props: ResetViewProps) {
  const theme = useTheme();

  return (
    <ButtonDiv>
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.primaryButtonHeight}
        icon={<RestartAltIcon fontSize="small" />}
        id="reset-view-button"
        tooltipText={TooltipMsg.ResetView}
        text="RESET VIEW"
        onClick={props.onClickHandler}
        isVertical={false}
        isVisible={true}
        isCompactView={props.isCompactView}
        disabled={props.disabled}
      />
    </ButtonDiv>
  );
}
