import ErrorIcon from "@mui/icons-material/Error";
import styled from "styled-components";

const StyledErrorIcon = styled(ErrorIcon)`
  padding-top: 9;
  padding: 0;
  margin-right: 3px;
  font-size: 16;
`;

export default StyledErrorIcon;
