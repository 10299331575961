import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { SpeedButtonControl } from "../../../../controls/Buttons/SpeedButtonControl";
import { Option } from "../../../../models/option";

interface BulkUpdateButtonProps {
  isCompactView: boolean;
  isSuperAdmin: boolean;
}

export function BulkUpdateButton(props: BulkUpdateButtonProps) {
  const options: Option[] = [
    {
      text: "Set workflow",
      hasAccess: true,
      description: "Sets workflow for all visible documents",
      onClick: () => {
        console.log("set workflow");
      },
    },
    {
      text: "Set security level",
      hasAccess: true,
      description: "Sets security level for all visible documents",
      onClick: () => {
        console.log("set security level");
      },
    },
    {
      text: "Set publisher",
      hasAccess: true,
      description: "Sets publisher for all visible documents",
      onClick: () => {
        console.log("set publisher");
      },
    },
    {
      text: "Set document kind",
      hasAccess: true,
      description: "Sets document kind for all visible documents",
      onClick: () => {
        console.log("set document kind");
      },
    },
  ];

  return (
    <SpeedButtonControl
      colors="secondary"
      id="header-action-bulk-update-button"
      text="Bulk update"
      options={options}
      icon={<AutoFixHighIcon />}
      isVisible={props.isSuperAdmin}
      isCompactView={props.isCompactView}
    />
  );
}
