import styled from "styled-components";

interface CategoryHeaderDivProps {
    disabled: boolean
}

export const CategoryHeaderDiv = styled.div<CategoryHeaderDivProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  height: 48px;
  line-height: 48px;
  margin-right: 12px;
  font-weight: bold;
  vertical-align: middle;
    color: ${(props) =>
        props.disabled
            ? props.theme.palettes.grayscale.medium
            : props.theme.palettes.grayscale.darkest};
  font-size: ${(props) => props.theme.typography.fontSizes.headerT1}px;
`;
