export interface DocumentStatus {
  documentRevisionId?: bigint;
  isFromServiceIntegration: boolean;
  isLatest: boolean;
  draftType: DraftType;
  publishedDate?: Date;
  publisher: string;
  hasPendingApproval: boolean;
  canApprovePendingApproval: boolean;
  isLaterRevision: boolean;
  hasOtherRevisionWithPendingApproval: boolean;
  latestRevision: string;
  canChangeToPublish: boolean;
  isApprovalCategory: boolean;
}

export enum DraftType {
  NonDraft = "NonDraft",
  NewDocumentDraft = "NewDocumentDraft",
  NewDocumentRevision = "NewDocumentRevisionDraft",
  ExistingDocumentDraft = "ExistingDocumentDraft",
  NewDocumentApproval = "NewDocumentApproval",
}
