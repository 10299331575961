import { DialogControl } from "../../../../../../controls/Dialog/DialogControl";
import { ErrorApi } from "../../../../../../models/errorApi";
import { ErrorApiType } from "../../../../../../models/errorApiType";
import { useEffect, useState } from "react";
import _ from "lodash";
import { StyledDialogContentText } from "../../../../selfHelpPopup/SC/StyledDialogContentText";

interface ErrorPopupProps {
  isOpen: boolean;
  onClose: () => void;
  reasons: ErrorApi[];
  id: string;
}

export function ErrorApiPopup(props: ErrorPopupProps) {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const getTitle = (code: ErrorApiType | undefined) => {
    switch (code) {
      case ErrorApiType.ApprovalDuplicateError:
      case ErrorApiType.IdentityDuplicate:
        setTitle("Identity duplicate");
        setMessage("Document with the provided identity already exists");
        break;
      default:
        setTitle("");
        break;
    }
  };

  useEffect(() => {
    if (props.reasons.length > 1) {
      setTitle("Some errors occurred");
      setMessage(props.reasons.map((reason) => reason.message).join("\n"));
    } else {
      getTitle(_.first(props.reasons)?.code);
    }
  }, [props.reasons]);

  return (
    <>
      {title !== "" ? (
        <DialogControl
          id={props.id}
          title={title}
          onCloseClick={props.onClose}
          maxWidth="sm"
          useActions={false}
          isOpen={props.isOpen}
          content={
            <StyledDialogContentText id="alert-dialog-description">
              {message}
            </StyledDialogContentText>
          }
        />
      ) : (
        <></>
      )}
    </>
  );
}
