import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SearchStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                The category tree can be narrowed down to display only categories containing the specified phrase in their name using the search function. A minimum of 3 characters is required for the search. The search can be confirmed by clicking on the magnifying glass icon or pressing Enter.
            </Paragraph>
        </StepContentDiv>
    );
}
