import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { openSnackbar } from "../../app/helpers/snackBarHelper";
import { useDocumentFlow } from "../../app/hooks/document/useDocumentFlow";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { useRouteHelper } from "../../app/hooks/useRouteHelper";
import { setApiErrors } from "../../app/slices/apiErrorSlice";
import {
  setHasApiErrors,
  setIsUpdatingDocument,
  setShouldRefreshDocumentMetadata,
} from "../../app/slices/documentDetailsSlice";
import {
  selectCurrentDocumentMetadata,
  selectDocumentDraftType,
  setDocumentIsNew,
} from "../../app/slices/documentMetadataSlice";
import { DraftType } from "../../models/documentDetails/documentStatus";
import { Workflow } from "../../models/documentList/types/workflow";
import { ErrorApiResponse } from "../../models/errorApi";
import { ErrorApiType } from "../../models/errorApiType";
import { SnackbarType } from "../../models/snackbar";
import {
  useCreateDraftForNewDocumentMutation,
  useCreateOrUpdateDraftForExistingDocumentMutation,
  useCreateOrUpdateDraftForNewRevisionMutation,
  useUpdateDraftForNewDocumentMutation,
} from "../documentDraftApi";
import { SnackbarMsg } from "../../controls/Snackbar/SnackbarMessages";

export function useSaveAsDraft() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const documentDraftType = useAppSelector(selectDocumentDraftType);
  const { isNewDocument, isNewDocumentVersion, isNewDocumentRevision } =
    useDocumentFlow();
  const currentDocumentMetadata = useAppSelector(selectCurrentDocumentMetadata);
  const { getDetailsRoute } = useRouteHelper();
  const routeToDetails = getDetailsRoute({
    identity: currentDocumentMetadata.documentIdentity,
    workflow: Workflow.Draft,
  });
  const displaySaveAsDraftSuccess = useRef(false);

  const [
    saveDraftForExistingDocument,
    {
      isError: isExistingDocSaveDraftError,
      isSuccess: isExistingDocSaveDraftSuccess,
      isLoading: isExistingDocSaveDraftLoading,
    },
  ] = useCreateOrUpdateDraftForExistingDocumentMutation();

  const [
    saveDraftForNewRevision,
    {
      isError: isNewRevisionSaveDraftError,
      isSuccess: isNewRevisionSaveDraftSuccess,
      isLoading: isNewRevisionSaveDraftLoading,
    },
  ] = useCreateOrUpdateDraftForNewRevisionMutation();

  const [
    updateDraftForNewDocument,
    {
      error: updateNewDraftError,
      isError: isUpdateNewDraftError,
      isSuccess: isUpdateNewDraftSuccess,
      isLoading: isUpdateNewDraftLoading,
    },
  ] = useUpdateDraftForNewDocumentMutation();

  const [
    createDraftForNewDocument,
    {
      error: createNewDraftError,
      isError: isCreateNewDraftError,
      isSuccess: isCreateNewDraftSuccess,
      isLoading: isCreateNewDraftLoading,
    },
  ] = useCreateDraftForNewDocumentMutation();

  useEffect(() => {
    displaySaveAsDraftSuccess.current = true;
    dispatch(
      setIsUpdatingDocument(
        isExistingDocSaveDraftLoading ||
          isUpdateNewDraftLoading ||
          isCreateNewDraftLoading ||
          isNewRevisionSaveDraftLoading
      )
    );
  }, [
    dispatch,
    isCreateNewDraftLoading,
    isExistingDocSaveDraftLoading,
    isUpdateNewDraftLoading,
    isNewRevisionSaveDraftLoading,
  ]);

  useEffect(() => {
    for (const error of [createNewDraftError, updateNewDraftError]) {
      if (error) {
        const errorResponse =
          "status" in error ? (error.data as ErrorApiResponse) : undefined;
        if (errorResponse && errorResponse.errors.length > 0) {
          const apiErrors = errorResponse.errors.filter(
            (error) => error.code === ErrorApiType.IdentityDuplicate
          );

          dispatch(setHasApiErrors(true));
          dispatch(setApiErrors(apiErrors));
          break;
        }
      }
    }

    if (
      isCreateNewDraftError ||
      isExistingDocSaveDraftError ||
      isUpdateNewDraftError ||
      isNewRevisionSaveDraftError
    ) {
      openSnackbar(
        dispatch,
        SnackbarMsg.SaveDocumentDraftError,
        SnackbarType.error
      );
    }
  }, [
    dispatch,
    isCreateNewDraftError,
    isExistingDocSaveDraftError,
    isNewRevisionSaveDraftError,
    isUpdateNewDraftError,
    createNewDraftError,
    updateNewDraftError,
  ]);

  useEffect(() => {
    if (
      (isUpdateNewDraftSuccess ||
        isExistingDocSaveDraftSuccess ||
        isCreateNewDraftSuccess ||
        isNewRevisionSaveDraftSuccess) &&
      displaySaveAsDraftSuccess.current
    ) {
      dispatch(setHasApiErrors(false));
      openSnackbar(
        dispatch,
        SnackbarMsg.SaveDocumentDraftSuccess,
        SnackbarType.success
      );
      if (isCreateNewDraftSuccess) {
        dispatch(setDocumentIsNew(false));
      }

      if (
        documentDraftType !== DraftType.NonDraft &&
        documentDraftType !== DraftType.NewDocumentApproval
      ) {
        dispatch(setShouldRefreshDocumentMetadata(true));
      }

      if (isNewDocument || isNewDocumentVersion || isNewDocumentRevision) {
        navigate(routeToDetails);
      }

      return () => {
        displaySaveAsDraftSuccess.current = false;
      };
    }
  }, [
    isUpdateNewDraftSuccess,
    isExistingDocSaveDraftSuccess,
    isNewRevisionSaveDraftSuccess,
    documentDraftType,
    dispatch,
    isCreateNewDraftSuccess,
    isNewDocumentVersion,
    isNewDocumentRevision,
    navigate,
    isNewDocument,
    routeToDetails,
  ]);

  return {
    updateDraftForNewDocument,
    saveDraftForExistingDocument,
    createDraftForNewDocument,
    saveDraftForNewRevision,
  };
}
