import { StyledSkeleton } from "./styledComponents/StyledSkeleton";

export function GroupSkeleton() {
  return (
    <div>
      <StyledSkeleton padding={48} width={120} variant="text" />
      <StyledSkeleton padding={96} width={140} variant="text" />
      <StyledSkeleton padding={96} width={140} variant="text" />
    </div>
  );
}
