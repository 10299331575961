import { Step } from "react-joyride";
import { createStep } from "../../helpers/selfHelpBuilder";
import { AddCategoriesStep } from "./Categories/AddCategoriesStep";
import { MainCategoryStep } from "./Categories/MainCategoryStep";
import { PopularCategoryStep } from "./Categories/PopularCategoryStep";
import { SectionStepOne } from "./Categories/SectionStepOne";
import { SectionStepTwo } from "./Categories/SectionStepTwo";
import { SelectedCategoriesStep } from "./Categories/SelectedCategoriesStep";

export const categoriesSteps = (isAnyCategoryVisible: boolean, isAnyPopularCategoryVisible: boolean) => {
  const steps: Step[] = [];

  steps.push(createStep("#Categories", <SectionStepOne />, 720, "auto"));
  steps.push(createStep("#Categories", <SectionStepTwo />, 720, "auto"));
  steps.push(createStep("#details-section-categories-add-categories-button", <AddCategoriesStep />, 620, "auto"));

  if (isAnyCategoryVisible) {
    steps.push(createStep("#details-section-categories-breadcrumbs-container", <SelectedCategoriesStep />, 620, "auto"));
  } else {
    steps.push(createStep("#Categories", <SelectedCategoriesStep showScreenshots />, 620, "auto"));
  }

  if (isAnyCategoryVisible) {
    steps.push(createStep(".main-category-chip-enabled", <MainCategoryStep />, 620, "auto"));
  } else {
    steps.push(createStep("#Categories", <MainCategoryStep showScreenshots />, 620, "auto"));
  }

  if (isAnyCategoryVisible) {
    if (isAnyPopularCategoryVisible) {
      steps.push(createStep(".popular-category-chip-enabled", <PopularCategoryStep isPopular={true} />, 620, "auto"));
    } else {
      steps.push(createStep(".popular-category-chip", <PopularCategoryStep isPopular={false} />, 620, "auto"));
    }
  } else {
    steps.push(createStep("#Categories", <PopularCategoryStep isPopular={false} showScreenshots />, 620, "auto"));
  }

  return steps;
};
