import { AutocompleteRenderOptionState, ListItemIcon } from "@mui/material";
import { HTMLAttributes, useEffect, useState } from "react";
import { DocumentSecurityLevel } from "../../../models/documentSecurityLevel";
import { SecurityLevelPill } from "../../documents/documentsList/documentListItem/pills/SecurityLevelPill";
import {
  getDocumentSecurityLevelName,
  getSecurityType,
} from "../../../app/helpers/securityLevelHelper";
import { StyledSecurityType } from "./SC/StyledSecurityType";
import { StyledSecurityItem } from "./SC/StyledSecurityItem";
import { useAppDispatch } from "../../../app/hooks/hooks";
import { StyledSecurityListItemText } from "./SC/StyledSecurityListItemText";
import { setEditedSecurityLevel } from "../../../app/slices/documentMetadataSlice";
import { DetailsAutoComplete } from "./DetailsAutoComplete";
import { ComboBoxListItem } from "../../../models/autocompleteValue";
import { Editable } from "../sections/common/Editable";
import { SecurityLevelPillDiv } from "./SC/SecurityLevelPillDiv";

interface SecurityDropDownProps extends Editable {
  id: string;
  labelText: string;
  securityLevel: DocumentSecurityLevel;
  items: SecurityLevelItem[];
  isEdited: boolean;
  isValidationError?: boolean;
}

interface SecurityLevelItem {
  key: string;
  value: DocumentSecurityLevel;
}

export function SecurityAutoComplete(props: SecurityDropDownProps) {
  const [selectedValue, setSelectedValue] = useState<ComboBoxListItem>({
    key: props.securityLevel,
    value: getDocumentSecurityLevelName(props.securityLevel),
  });
  const dispatch = useAppDispatch();

  const changeItem = (newValue: ComboBoxListItem[] | null) => {
    if (newValue) {
      const securityLevel = newValue[0].key as DocumentSecurityLevel;
      dispatch(setEditedSecurityLevel(securityLevel));
      setSelectedValue(newValue[0]);
    }
  };

  useEffect(() => {
    setSelectedValue({
      key: props.securityLevel,
      value: getDocumentSecurityLevelName(props.securityLevel),
    });
  }, [props.securityLevel]);

  const getPills = (securityLevel: DocumentSecurityLevel) => {
    return (
      <>
        <SecurityLevelPillDiv>
          <SecurityLevelPill
            isGray={false}
            isInternal={false}
            securityLevel={securityLevel}
          />
        </SecurityLevelPillDiv>
        <SecurityLevelPillDiv>
          <SecurityLevelPill
            isGray={false}
            isInternal={true}
            securityLevel={securityLevel}
          />
        </SecurityLevelPillDiv>
      </>
    );
  };

  const getStyledSecurityLevel = (
    securityLevel: DocumentSecurityLevel,
    useFlex: boolean
  ) => {
    return (
      <StyledSecurityType
        $documentSecurityLevel={getSecurityType(securityLevel)}
        variant="body2"
        $useFlex={useFlex}
        paragraph={false}
        $isHidden={false}
      >
        {getSecurityType(securityLevel)}
      </StyledSecurityType>
    );
  };

  const getMenuItem = (
    props: HTMLAttributes<HTMLLIElement>,
    option: unknown,
    state: AutocompleteRenderOptionState
  ) => {
    const securityOption = option as ComboBoxListItem;
    const securityType = securityOption.key as DocumentSecurityLevel;
    return (
      <StyledSecurityItem
        {...props}
        $removeHoverColor={state.selected}
        key={securityOption.key}
        value={securityOption.value}
        $paddingLeft={16}
        $paddingRight={16}
        $paddingTop={6}
        $paddingBottom={6}
      >
        <ListItemIcon>{getPills(securityType)}</ListItemIcon>
        <StyledSecurityListItemText>
          {getDocumentSecurityLevelName(securityType)}
        </StyledSecurityListItemText>
        {getStyledSecurityLevel(securityType, true)}
      </StyledSecurityItem>
    );
  };

  return (
    <DetailsAutoComplete
      id={props.id + "security-select-input"}
      labelText={props.labelText}
      selected={[selectedValue]}
      inputStartAdornment={getPills(selectedValue.key as DocumentSecurityLevel)}
      inputEndAdornment={getStyledSecurityLevel(
        selectedValue.key as DocumentSecurityLevel,
        false
      )}
      items={props.items.map((item) => {
        return {
          key: item.key,
          value: getDocumentSecurityLevelName(
            item.key as DocumentSecurityLevel
          ),
        };
      })}
      isEditable={props.isEditable}
      isEdited={props.isEdited}
      multiple={false}
      onChangeHandler={changeItem}
      renderOption={getMenuItem}
      notEditableMessage={props.notEditableMessage}
      isDisabled={!props.isEditable}
      isError={props.isValidationError}
      errorMessages={[]}
    />
  );
}
