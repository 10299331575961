import { useMemo } from "react";
import {
  selectAttachmentIsEdited,
  selectCategoriesAreEdited,
  selectCmaCountriesIsEdited,
  selectCmaIsEdited,
  selectCmaSectionIsEdited,
  selectDescriptionSectionIsEdited,
  selectDocumentKindIsEdited,
  selectDocumentNumberIsEdited,
  selectEditedAccessGroups,
  selectEditedCmaCountries,
  selectEditedLanguages,
  selectEditedProducts,
  selectEditedTags,
  selectEditedTitles,
  selectExternalSecurityLevelIsEdited,
  selectIdentitySectionIsEdited,
  selectInternalSecurityLevelIsEdited,
  selectLanguagesIsEdited,
  selectPartIsEdited,
  selectProductsIsEdited,
  selectProductsSectionIsEdited,
  selectRevisionIsEdited,
  selectSecurityIsEdited,
  selectSecuritySectionIsEdited,
  selectSummaryIsEdited,
  selectTagsSectionIsEdited,
  selectTitleSectionIsEdited,
  selectedAccessGroupIsEdited,
} from "../../slices/documentMetadataSlice";
import { useAppSelector } from "../hooks";
import { useDocumentFlow } from "./useDocumentFlow";

export function useDocumentEditedMode() {
  const { isNewDocumentView } = useDocumentFlow();

  /* Identity Section */
  const sectionIdentityIsEdited = useAppSelector(selectIdentitySectionIsEdited);
  const documentNumberState = useAppSelector(selectDocumentNumberIsEdited);
  const languagesState = useAppSelector(selectLanguagesIsEdited);
  const partState = useAppSelector(selectPartIsEdited);
  const revisionState = useAppSelector(selectRevisionIsEdited);
  const languagesEdited = useAppSelector(selectEditedLanguages);

  /* Product Section */
  const sectionProductsIsEdited = useAppSelector(selectProductsSectionIsEdited);
  const productsState = useAppSelector(selectProductsIsEdited);
  const productsEdited = useAppSelector(selectEditedProducts);

  /* Description Section */
  const sectionDescriptionIsEdited = useAppSelector(
    selectDescriptionSectionIsEdited
  );
  const documentKindState = useAppSelector(selectDocumentKindIsEdited);
  const summaryState = useAppSelector(selectSummaryIsEdited);

  /* Security Section */
  const sectionSecurityIsEdited = useAppSelector(selectSecuritySectionIsEdited);
  const accessGroupsEdited = useAppSelector(selectEditedAccessGroups);
  const accessGroupState = useAppSelector(selectedAccessGroupIsEdited);
  const securityState = useAppSelector(selectSecurityIsEdited);
  const internalSecurityLevelState = useAppSelector(
    selectInternalSecurityLevelIsEdited
  );
  const externalSecurityLevelState = useAppSelector(
    selectExternalSecurityLevelIsEdited
  );

  /* Tag Section */
  const sectionTagIsEdited = useAppSelector(selectTagsSectionIsEdited);
  const tagsEdited = useAppSelector(selectEditedTags);

  /* Title Section */
  const sectionTitlesIsEdited = useAppSelector(selectTitleSectionIsEdited);
  const titlesEdited = useAppSelector(selectEditedTitles);

  /* CMA Section */
  const sectionCmaIsEdited = useAppSelector(selectCmaSectionIsEdited);
  const countriesEdited = useAppSelector(selectEditedCmaCountries);
  const cmaState = useAppSelector(selectCmaIsEdited);
  const countriesState = useAppSelector(selectCmaCountriesIsEdited);

  /* Categories Section */
  const categoriesState = useAppSelector(selectCategoriesAreEdited);

  /* Attachment Section */
  const sectionAttachmentIsEdited = useAppSelector(selectAttachmentIsEdited);

  /* Identity Section */
  const identitySectionIsEdited = useMemo(
    () => (isNewDocumentView ? false : sectionIdentityIsEdited),
    [isNewDocumentView, sectionIdentityIsEdited]
  );
  const partIsEdited = useMemo(
    () => (isNewDocumentView ? false : partState),
    [isNewDocumentView, partState]
  );
  const documentNumberIsEdited = useMemo(
    () => (isNewDocumentView ? false : documentNumberState),
    [isNewDocumentView, documentNumberState]
  );
  const languagesIsEdited = useMemo(
    () => (isNewDocumentView ? false : languagesState),
    [isNewDocumentView, languagesState]
  );
  const revisionIsEdited = useMemo(
    () => (isNewDocumentView ? false : revisionState),
    [isNewDocumentView, revisionState]
  );
  const editedLanguages = useMemo(
    () => (isNewDocumentView ? [] : languagesEdited),
    [isNewDocumentView, languagesEdited]
  );

  /* Product Section */
  const productsSectionIsEdited = useMemo(
    () => (isNewDocumentView ? false : sectionProductsIsEdited),
    [isNewDocumentView, sectionProductsIsEdited]
  );
  const productsIsEdited = useMemo(
    () => (isNewDocumentView ? false : productsState),
    [isNewDocumentView, productsState]
  );
  const editedProducts = useMemo(
    () => (isNewDocumentView ? [] : productsEdited),
    [isNewDocumentView, productsEdited]
  );

  /* Description Section */
  const descriptionSectionIsEdited = useMemo(
    () => (isNewDocumentView ? false : sectionDescriptionIsEdited),
    [isNewDocumentView, sectionDescriptionIsEdited]
  );
  const documentKindIsEdited = useMemo(
    () => (isNewDocumentView ? false : documentKindState),
    [isNewDocumentView, documentKindState]
  );
  const summaryIsEdited = useMemo(
    () => (isNewDocumentView ? false : summaryState),
    [isNewDocumentView, summaryState]
  );

  /* Security Section */
  const accessGroupIsEdited = useMemo(
    () => (isNewDocumentView ? false : accessGroupState),
    [isNewDocumentView, accessGroupState]
  );
  const securitySectionIsEdited = useMemo(
    () => (isNewDocumentView ? false : sectionSecurityIsEdited),
    [isNewDocumentView, sectionSecurityIsEdited]
  );
  const editedAccessGroups = useMemo(
    () => (isNewDocumentView ? [] : accessGroupsEdited),
    [isNewDocumentView, accessGroupsEdited]
  );
  const securityIsEdited = useMemo(
    () => (isNewDocumentView ? false : securityState),
    [isNewDocumentView, securityState]
  );
  const externalSecurityLevelIsEdited = useMemo(
    () => (isNewDocumentView ? false : externalSecurityLevelState),
    [isNewDocumentView, externalSecurityLevelState]
  );
  const internalSecurityLevelIsEdited = useMemo(
    () => (isNewDocumentView ? false : internalSecurityLevelState),
    [isNewDocumentView, internalSecurityLevelState]
  );

  /* Tag Section */
  const editedTags = useMemo(
    () => (isNewDocumentView ? [] : tagsEdited),
    [isNewDocumentView, tagsEdited]
  );
  const tagSectionIsEdited = useMemo(
    () => (isNewDocumentView ? false : sectionTagIsEdited),
    [isNewDocumentView, sectionTagIsEdited]
  );

  /* Title Section */
  const titlesSectionIsEdited = useMemo(
    () => (isNewDocumentView ? false : sectionTitlesIsEdited),
    [isNewDocumentView, sectionTitlesIsEdited]
  );
  const editedTitles = useMemo(
    () => (isNewDocumentView ? [] : titlesEdited),
    [isNewDocumentView, titlesEdited]
  );

  /* CMA Section */
  const cmaSectionIsEdited = useMemo(
    () => (isNewDocumentView ? false : sectionCmaIsEdited),
    [isNewDocumentView, sectionCmaIsEdited]
  );
  const cmaIsEdited = useMemo(
    () => (isNewDocumentView ? false : cmaState),
    [isNewDocumentView, cmaState]
  );
  const countriesIsEdited = useMemo(
    () => (isNewDocumentView ? false : countriesState),
    [isNewDocumentView, countriesState]
  );
  const editedCountries = useMemo(
    () => (isNewDocumentView ? [] : countriesEdited),
    [isNewDocumentView, countriesEdited]
  );

  /* Categories Section */
  const categoriesAreEdited = useMemo(
    () => (isNewDocumentView ? false : categoriesState),
    [isNewDocumentView, categoriesState]
  );

  /* Attachment Section */
  const attachmentIsEdited = useMemo(
    () => (isNewDocumentView ? false : sectionAttachmentIsEdited),
    [isNewDocumentView, sectionAttachmentIsEdited]
  );

  /* Document */
  const documentIsEdited = useMemo(() => {
    return (
      sectionDescriptionIsEdited ||
      sectionProductsIsEdited ||
      sectionIdentityIsEdited ||
      sectionSecurityIsEdited ||
      sectionTagIsEdited ||
      sectionTitlesIsEdited ||
      sectionCmaIsEdited ||
      categoriesState ||
      sectionAttachmentIsEdited
    );
  }, [
    sectionDescriptionIsEdited,
    sectionProductsIsEdited,
    sectionIdentityIsEdited,
    sectionSecurityIsEdited,
    sectionTagIsEdited,
    sectionTitlesIsEdited,
    sectionCmaIsEdited,
    categoriesState,
    sectionAttachmentIsEdited,
  ]);

  const isInEditMode = useMemo(() => {
    if (isNewDocumentView) return false;
    return documentIsEdited;
  }, [documentIsEdited, isNewDocumentView]);

  const descriptionSection = {
    descriptionSectionIsEdited,
    documentKindIsEdited,
    summaryIsEdited,
  };

  const productsSection = {
    productsSectionIsEdited,
    productsIsEdited,
    editedProducts,
  };

  const identitySection = {
    identitySectionIsEdited,
    partIsEdited,
    documentNumberIsEdited,
    languagesIsEdited,
    revisionIsEdited,
    editedLanguages,
  };

  const securitySection = {
    securitySectionIsEdited,
    accessGroupIsEdited,
    editedAccessGroups,
    securityIsEdited,
    externalSecurityLevelIsEdited,
    internalSecurityLevelIsEdited,
  };

  const tagSection = {
    tagSectionIsEdited,
    editedTags,
  };

  const titleSection = {
    titlesSectionIsEdited,
    editedTitles,
  };

  const cmaSection = {
    cmaSectionIsEdited,
    cmaIsEdited,
    countriesIsEdited,
    editedCountries,
  };

  const categoriesSection = {
    categoriesAreEdited,
  };

  const attamentSection = {
    attachmentIsEdited,
  };

  return {
    isInEditMode,
    documentIsEdited,
    identitySection,
    productsSection,
    descriptionSection,
    securitySection,
    tagSection,
    titleSection,
    cmaSection,
    categoriesSection,
    attamentSection,
  };
}
