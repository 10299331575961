import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from "@mui/icons-material/Search";
import { SelfHelpIcon } from "../SelfHelpIcon";


export function SearchBoxStep4() {
    return (
        <StepContentDiv>
            <Paragraph>
                The category can be searched by its name or CID using at least 3 characters.
            </Paragraph>
            <Paragraph>
                The categories tree is narrowed down to categories that contain the typed-in text.
            </Paragraph>
            <Paragraph>
                New result can be loaded by pressing Enter or using the <SelfHelpIcon icon={<SearchIcon />} /> icon.
            </Paragraph>
            <Paragraph>
                The input can be cleared using the <SelfHelpIcon icon={<ClearIcon />} /> icon.
            </Paragraph>
        </StepContentDiv>
    );
}
