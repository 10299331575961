/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { useEffect, useState } from "react";
import { useLazyGetMyRolesQuery } from "../apis/publishersApi";
import { UnauthorizedPage } from "../components/errors/UnauthorizedPage";
import { selectApiError } from "./slices/apiErrorSlice";
import { PageLayout } from "../components/pageLayout/PageLayout";
import { AppContainerDiv } from "../components/pageLayout/SC/AppContainerDiv";
import { EmptyPageDiv } from "../components/pageLayout/SC/PageDiv";
import SnackbarQueue from "../controls/Snackbar/SnackbarQueue";
import {
  selectIsWindowTooSmall,
  selectCurrentSplash,
  selectUserAgreedForTooSmallWindow,
  setIsWindowTooSmall,
  setCurrentSplash,
  setEnvironment,
} from "./slices/commonSlice";
import {
  elementsWithCustomScrollbar,
  windowMinWidth,
  windowMinHeight,
} from "./slices/models/contentModels";
import { selectUserRoles, setUserRoles } from "./slices/authenticationSlice";
import { useAppDispatch, useAppSelector } from "./hooks/hooks";
import { clearStorageIfUsingWrongVersion } from "./hooks/localStorage";
import useWindowDimensions, {
  useScreenInfo,
} from "./hooks/useWindowDimensions";
import { CircularProgressBar } from "../controls/ProgressIndicators/CircularProgressBar";
import { ScreenSplash } from "../controls/ScreenSplash/ScreenSplash";
import { ScreenSplashEnum } from "../models/screenSplash";
import { clearSnackbar } from "./slices/snackbarSlice";
import { ErrorBoundaryWrapper } from "./errorHandling/wrappers/ErrorBoundaryWrapper";
import { ErrorDisplay } from "./errorHandling/ErrorDisplay";
import { useLoggedInUser } from "./hooks/permissions/useLoggedInUser";
import { SelfHelp } from "../controls/SelfHelp/SelfHelp";
import { useGetAppSettingsQuery } from "../apis/configApi";
import { Environment } from "../models/environment";
import { ReadOnlyPopup } from "../components/dashboard/readOnlyPopup/ReadOnlyPopup";

function App() {
  const dispatch = useAppDispatch();
  const [getMyRoles, { data, isLoading, isError }] = useLazyGetMyRolesQuery();
  const { isFetchUserError } = useLoggedInUser();
  const isWindowTooSmall = useAppSelector(selectIsWindowTooSmall);
  const currentSplashScreen = useAppSelector(selectCurrentSplash);
  const userAgreedForTooSmallWindow = useAppSelector(
    selectUserAgreedForTooSmallWindow
  );
  const { windowWidth, windowHeight } = useWindowDimensions();
  const screenInfo = useScreenInfo();
  const apiError = useAppSelector(selectApiError);
  const appInsights = useAppInsightsContext();
  const myRoles = useAppSelector(selectUserRoles);

  const trackScreenSizeWithPixelRatio = useTrackEvent(
    appInsights,
    "Application loaded",
    screenInfo
  );
  const { data: config } = useGetAppSettingsQuery(null);

  useEffect(() => {
    if (config) {
      const env: string = config.environment;
      dispatch(setEnvironment(Environment[env as keyof typeof Environment]));
    }
  }, [config, dispatch])


  const refreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    clearStorageIfUsingWrongVersion();
  }, []);

  useEffect(() => {
    trackScreenSizeWithPixelRatio(screenInfo);
  }, [screenInfo, trackScreenSizeWithPixelRatio]);

  useEffect(() => {
    const mouseMoveListener = (e: MouseEvent) => {
      elementsWithCustomScrollbar.forEach((elementId) => {
        const ele = document.getElementById(elementId);
        if (ele) {
          if (ele.scrollHeight <= ele.clientHeight) return;

          const distance =
            ele.getBoundingClientRect().left + ele.offsetWidth - e.pageX;
          const distanceH =
            ele.getBoundingClientRect().top + ele.offsetHeight - e.pageY;
          distance < 10 &&
            distance > -10 &&
            distanceH > 0 &&
            distanceH < ele.offsetHeight
            ? ele.classList.add("wide-scrollbar")
            : ele.classList.remove("wide-scrollbar");
          distanceH < 10 &&
            distanceH > -10 &&
            distance > 0 &&
            distance < ele.offsetWidth
            ? ele.classList.add("wide-scrollbar-horizontal")
            : ele.classList.remove("wide-scrollbar-horizontal");
        }
      });
    };

    document.addEventListener("mousemove", mouseMoveListener);
    return () => document.removeEventListener("mousemove", mouseMoveListener);
  }, []);

  useEffect(() => {
    if (
      (windowWidth < windowMinWidth || windowHeight < windowMinHeight) &&
      !isWindowTooSmall
    ) {
      dispatch(setIsWindowTooSmall(true));
      dispatch(clearSnackbar());
    } else if (
      windowWidth >= windowMinWidth &&
      windowHeight >= windowMinHeight &&
      isWindowTooSmall
    ) {
      dispatch(setIsWindowTooSmall(false));
    }
  }, [dispatch, windowWidth, windowHeight, isWindowTooSmall]);

  useEffect(() => {
    if (
      isWindowTooSmall &&
      !userAgreedForTooSmallWindow &&
      currentSplashScreen != ScreenSplashEnum.WindowTooSmall
    ) {
      dispatch(setCurrentSplash(ScreenSplashEnum.WindowTooSmall));
    } else if (
      !isWindowTooSmall &&
      currentSplashScreen != ScreenSplashEnum.None
    ) {
      dispatch(setCurrentSplash(ScreenSplashEnum.None));
    }
  }, [
    isWindowTooSmall,
    currentSplashScreen,
    userAgreedForTooSmallWindow,
    dispatch,
  ]);

  useEffect(() => {
    if (!myRoles) {
      void getMyRoles(null);
    }
  }, [dispatch, getMyRoles, myRoles]);

  useEffect(() => {
    if (data) {
      dispatch(setUserRoles(data));
    }
  }, [data, dispatch]);

  if (apiError.catchedAuthError) {
    return <UnauthorizedPage />;
  }

  if (isError) {
    return (
      <ErrorDisplay
        errorContainerProps={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        errorId="global-myroles"
        doCenterHeader={true}
        showReloadButton={true}
        refreshFunction={() => void getMyRoles(null)}
        errorMessageTitle={"Authorization error"}
        errorDescription={`You are not authorized to access ABB
        Library Publish UI.`}
      />
    );
  }

  if (isFetchUserError) {
    return (
      <ErrorDisplay
        errorContainerProps={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        errorId="global-myroles"
        doCenterHeader={true}
        showReloadButton={true}
        refreshFunction={refreshPage}
        errorMessageTitle={"Authorization error"}
        errorDescription={`You are not authorized to access ABB
        Library Publish UI.`}
      />
    );
  }

  if (isLoading || !myRoles) {
    return (
      <EmptyPageDiv>
        <CircularProgressBar
          color="primary"
          size={80}
          message="Loading..."
          messageFontSize={28}
        />
      </EmptyPageDiv>
    );
  }



  return (
    <ErrorBoundaryWrapper>
      <AppContainerDiv>
        <SnackbarQueue />
        <PageLayout />
        <SelfHelp />
        <ReadOnlyPopup />
        <ScreenSplash currentScreenSplash={currentSplashScreen} />
      </AppContainerDiv>
    </ErrorBoundaryWrapper>
  );
}

export default App;
