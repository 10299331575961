import styled from "styled-components";

export const TagValuesDiv = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0px;
    overflow-y: auto;
    max-width: 100%;
    margin-top: 10px;
    flex:1;
`;
