import styled from "styled-components";

export const ContentPaperDiv = styled.div`
  display: flex;
  flex-direction: row;
  order: 1;
  flex: 1;
  width: calc(100% - 48px);
  height: 100%;
  margin: 24px;
`;
