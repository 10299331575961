import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { ReactNode, useState } from "react";
import { StyledButton } from "./SC/StyledButton";
import { TooltipTop } from "../Tooltips/ToolipTop";

interface ButtonControlProps {
  sx?: SxProps<Theme> | undefined;
  id: string;
  text: string;
  tooltipText?: string;
  colors: "primary" | "secondary";
  height: number;
  startIcon?: ReactNode;
  isCompactView: boolean;
  hasAccess: boolean;
  disabled?: boolean;
  disabledTooltipText?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export function ButtonControl(props: ButtonControlProps) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (!props.hasAccess) {
    return null;
  }

  const button = <StyledButton
    sx={props.sx}
    id={props.id + "-styled-button"}
    size="medium"
    height={props.height}
    color={props.colors}
    colors={props.colors}
    disabled={props.disabled}
    onClick={(event: React.MouseEvent<HTMLElement>) => {
      setOpen(false);
      if (props.onClick !== undefined) props.onClick(event);
    }}
  >
    {props.startIcon}
    {!props.isCompactView && props.text}
  </StyledButton>

  if (props.disabled && props.disabledTooltipText) {
    return (
      <TooltipTop
        title={props.disabledTooltipText}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
      >
        <span>
          {button}
        </span>
      </TooltipTop>
    );
  }

  if (props.tooltipText) {
    return (
      <TooltipTop
        title={props.tooltipText}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
      >
        {button}
      </TooltipTop>
    );
  }

  return button;
}
