import { Dialog } from "@mui/material";
import styled from "styled-components";

interface StyledDialogProps {
  $fullHeight?: boolean;
  $padding?: string;
  $margin?: string;
}

export const StyledDialog = styled(Dialog)<StyledDialogProps>`
  .MuiDialog-paper {
    height: ${(props) => (props.$fullHeight ? "calc(100% - 64px)" : "unset")};
    border-radius: 0px;
    border-top: 3px solid ${(props) => props.theme.palettes.grayscale.darkest};
    box-shadow: 0px 6px 12px
      ${(props) => props.theme.palettes.grayscale.darkHighlight};
  }

  .MuiDialogContent-root {
    padding: ${(props) => props.$padding ?? "20px 24px"};
    margin: ${(props) => props.$margin ?? ""};
  }

  .MuiDialog-paperWidthSm {
    max-width: 610px;
  }
`;
