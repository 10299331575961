import { useTheme } from "styled-components";
import { ButtonDiv } from "../SC/ButtonDiv";
import { Skeleton } from "@mui/material";

export function ButtonsSkeleton() {
  const theme = useTheme();

  return (
    <>
      <ButtonDiv>
        <Skeleton height={theme.shapes.primaryButtonHeight} width={92} />
      </ButtonDiv>
      <ButtonDiv>
        <Skeleton height={theme.shapes.primaryButtonHeight} width={92} />
      </ButtonDiv>
      <ButtonDiv>
        <Skeleton height={theme.shapes.primaryButtonHeight} width={92} />
      </ButtonDiv>
    </>
  );
}
