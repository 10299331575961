import { Step } from "react-joyride";
import { createStep } from "../../../helpers/selfHelpBuilder";
import { CategoryTabStep2 } from "./CategoryTabStep2";
import { CloseStep6 } from "./CloseStep6";
import { EmailsExportStep4A } from "./EmailsExportStep4A";
import { EmailsExportStep4B } from "./EmailsExportStep4B";
import { PublisherTabStep3 } from "./PublisherTabStep3";
import { RequestAccessStep5 } from "./RequestAccessStep5";
import { SectionStep1 } from "./SectionStep1";

export const publishersMenuSteps = (isEmailExportsButton: boolean) => {
  const steps: Step[] = [];
  steps.push(createStep("#publishers-header-tab-div", <SectionStep1 />, 520, "bottom"));
  steps.push(createStep("#category-tab-button", <CategoryTabStep2 />, 520, "right"));
  steps.push(createStep("#publisher-tab-button", <PublisherTabStep3 />, 520, "right"));

  if (isEmailExportsButton) {
    steps.push(createStep("#header-action-emails-data-export", <EmailsExportStep4A />, 520, "left"));
  } else {
    steps.push(createStep("#publishers-header-tab-end-adornment-div", <EmailsExportStep4B />, 520, "left"));
  }

  steps.push(createStep("#request-access-button", <RequestAccessStep5 />, 520, "left"));
  steps.push(createStep("#document-settings-close-button", <CloseStep6 />, 520, "left"));
  return steps;

};
