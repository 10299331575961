import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/store";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { defaultTheme, muiTheme } from "./app/theme";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Routers } from "./app/Routers";

const container = document.getElementById("root");
const rootContainer = createRoot(container as Element);
const router = createBrowserRouter(createRoutesFromElements(Routers()));

rootContainer.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme}>
        <MuiThemeProvider theme={muiTheme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <RouterProvider router={router} />
          </LocalizationProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
