import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AttachmentUploadInfo } from "../../models/documentDetails/documentAttachment";
import { RootState } from "../store";

export interface FileUploadState {
  attachmentUploadInfo?: AttachmentUploadInfo;
  lastFetchedAttachmentId: string;
  lastFetchedUploadId: string;
  isFileUploading: boolean;
  isFileUploadAborted: boolean;
  isFileUploadInitializing: boolean;
  isSingleChunkUploading: boolean;
  isAttachmentUploadInProgress: boolean;
  uploadProgress: number;
  shouldAbortFileUpload: boolean;
  isUploadControlLoaded: boolean;
  isUploadError: boolean;
  errorMessage: string;
  retryCounter: number;
}

const defaultState: FileUploadState = {
  attachmentUploadInfo: undefined,
  lastFetchedAttachmentId: "",
  lastFetchedUploadId: "",
  isFileUploadAborted: false,
  isFileUploading: false,
  isFileUploadInitializing: false,
  isSingleChunkUploading: false,
  uploadProgress: 0,
  shouldAbortFileUpload: false,
  isUploadControlLoaded: false,
  isUploadError: false,
  isAttachmentUploadInProgress: false,
  retryCounter: 0,
  errorMessage: "",
};

const initialState: FileUploadState = defaultState;

export const fileUploadSlice = createSlice({
  name: "fileUpload",
  initialState,
  reducers: {
    setLastFetchedAttachmentId: (state, action: PayloadAction<string>) => {
      state.lastFetchedAttachmentId = action.payload;
    },
    setLastFetchedUploadId: (state, action: PayloadAction<string>) => {
      state.lastFetchedUploadId = action.payload;
    },
    setLastFetchedAttachmentUploadInfo: (
      state,
      action: PayloadAction<AttachmentUploadInfo | undefined>
    ) => {
      state.attachmentUploadInfo = action.payload;
    },
    setIsUploadControlLoaded: (state, action: PayloadAction<boolean>) => {
      state.isUploadControlLoaded = action.payload;
    },
    setShouldAbortFileUpload: (state, action: PayloadAction<boolean>) => {
      state.shouldAbortFileUpload = action.payload;
    },
    setIsFileUploading: (state, action: PayloadAction<boolean>) => {
      state.isFileUploading = action.payload;
    },
    setIsFileUploadAborted: (state, action: PayloadAction<boolean>) => {
      state.isFileUploadAborted = action.payload;
    },
    setIsFileUploadInitializing: (state, action: PayloadAction<boolean>) => {
      state.isFileUploadInitializing = action.payload;
    },
    setIsAttachmentUploadInProgress: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isAttachmentUploadInProgress = action.payload;
    },
    setUploadProgress: (state, action: PayloadAction<number>) => {
      state.uploadProgress = action.payload;
    },
    setIsSingleChunkUploading: (state, action: PayloadAction<boolean>) => {
      state.isSingleChunkUploading = action.payload;
    },
    setIsFileUploadError(state, action: PayloadAction<boolean>) {
      state.isUploadError = action.payload;
    },
    setFileUploadRetryCounter(state, action: PayloadAction<number>) {
      state.retryCounter = action.payload;
    },
    setFileUploadErrorMessage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    clearFileUploadError(state) {
      state.errorMessage = "";
      state.isUploadError = false;
      state.retryCounter = 0;
    },
    clearAttachmentInfo(state) {
      state.attachmentUploadInfo = undefined;
    },
  },
});

export const {
  setShouldAbortFileUpload,
  setIsFileUploading,
  setIsFileUploadAborted,
  setIsFileUploadInitializing,
  setUploadProgress,
  setIsSingleChunkUploading,
  setIsFileUploadError,
  setFileUploadRetryCounter,
  setFileUploadErrorMessage,
  clearFileUploadError,
  setIsUploadControlLoaded,
  setIsAttachmentUploadInProgress,
  setLastFetchedAttachmentUploadInfo,
  setLastFetchedAttachmentId,
  setLastFetchedUploadId,
  clearAttachmentInfo,
} = fileUploadSlice.actions;

const fileUploadSelector = (state: RootState) => state.fileUpload;

export const selectLastFetchedAttachmentId = createSelector(
  [fileUploadSelector],
  (state) => state.lastFetchedAttachmentId
);

export const selectLastFetchedUploadId = createSelector(
  [fileUploadSelector],
  (state) => state.lastFetchedUploadId
);

export const selectAttachmentUploadInfo = createSelector(
  [fileUploadSelector],
  (state) => state.attachmentUploadInfo
);

export const selectIsUploadControlLoaded = createSelector(
  [fileUploadSelector],
  (state) => state.isUploadControlLoaded
);

export const selectUploadProgress = createSelector(
  [fileUploadSelector],
  (state) => state.uploadProgress
);

export const selectIsSingleChunkUploading = createSelector(
  [fileUploadSelector],
  (state) => state.isSingleChunkUploading
);

export const selectIsFileUploading = createSelector(
  [fileUploadSelector],
  (state) => state.isFileUploading
);

export const selectIsAttachmentUploadInProgress = createSelector(
  [fileUploadSelector],
  (state) => state.isAttachmentUploadInProgress
);

export const selectIsFileUploadAborted = createSelector(
  [fileUploadSelector],
  (state) => state.isFileUploadAborted
);

export const selectIsFileUploadInitializing = createSelector(
  [fileUploadSelector],
  (state) => state.isFileUploadInitializing
);

export const selectShouldAbortFileUpload = createSelector(
  [fileUploadSelector],
  (state) => state.shouldAbortFileUpload
);

export const selectIsFileUploadError = createSelector(
  [fileUploadSelector],
  (state) => state.isUploadError
);

export const selectFileUploadRetryCounter = createSelector(
  [fileUploadSelector],
  (state) => state.retryCounter
);

export const selectFileUploadRetryErrorMessage = createSelector(
  [fileUploadSelector],
  (state) => state.errorMessage
);

export const selectFileHash = createSelector(
  [fileUploadSelector],
  (state) => state.attachmentUploadInfo?.fileHash
);

export default fileUploadSlice.reducer;
