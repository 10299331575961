import _ from "lodash";
import { DragEvent, useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import {
  moveSectionToColumn,
  selectDragEnterSection,
  selectDragStartSection,
  swapSections,
} from "../../../app/slices/documentDetailsSlice";
import {
  DetailsColumn,
  SectionName,
} from "../../../app/slices/models/documentDetailsModels";
import { PaneElementDiv } from "../SC/PaneElementDiv";
import { AttachmentSection } from "../sections/Attachment/AttachmentSection";
import { CategoriesSection } from "../sections/categories/CategoriesSection";
import { CMASection } from "../sections/countryMarketAvailability/CMASection";
import { DescriptionSection } from "../sections/description/DescriptionSection";
import { IdentitySection } from "../sections/identity/IdentitySection";
import { ProductsSection } from "../sections/products/ProductsSection";
import { SecuritySection } from "../sections/security/SecuritySection";
import { TagsSection } from "../sections/tags/TagsSection";
import { TitlesSection } from "../sections/titles/TitlesSection";
import { ColumnDragAndDropPlaceholder } from "./SC/ColumnDragAndDropPlaceholder";

export interface PaneElementProps {
  paneElementClassName: string;
  paneElementId: string;
  columnType: DetailsColumn;
  dimensions?: { width: number; height: number };
  sections: SectionName[];
}

const createSection = (
  sectionName: SectionName,
  column: DetailsColumn,
  index: number
) => {
  switch (sectionName) {
    case SectionName.Attachment:
      return (
        <AttachmentSection
          key={"AttachmentSection"}
          column={column}
          index={index}
        />
      );
    case SectionName.Categories:
      return (
        <CategoriesSection
          key={"CategoriesSection"}
          column={column}
          index={index}
        />
      );
    case SectionName.CountryMarketAvailability:
      return <CMASection key={"CMASection"} column={column} index={index} />;
    case SectionName.Identity:
      return (
        <IdentitySection
          key={"IdentitySection"}
          column={column}
          index={index}
        />
      );
    case SectionName.Products:
      return (
        <ProductsSection
          key={"ProductsSection"}
          column={column}
          index={index}
        />
      );
    case SectionName.Security:
      return (
        <SecuritySection
          key={"SecuritySection"}
          column={column}
          index={index}
        />
      );
    case SectionName.Description:
      return (
        <DescriptionSection
          key={"DescriptionSection"}
          column={column}
          index={index}
        />
      );
    case SectionName.Tags:
      return <TagsSection key={"TagsSection"} column={column} index={index} />;
    case SectionName.Titles:
      return (
        <TitlesSection key={"TitlesSection"} column={column} index={index} />
      );
    default:
      return null;
  }
};

export function PaneElement(props: PaneElementProps) {
  const dragStartSection = useAppSelector(selectDragStartSection);
  const dragEnterSection = useAppSelector(selectDragEnterSection);
  const dispatch = useAppDispatch();
  const swapSectionsCallback = useCallback(
    (dragStartSection: SectionName, dragEnterSection: SectionName) => {
      dispatch(
        swapSections({
          source: dragStartSection,
          target: dragEnterSection,
        })
      );
    },
    [dispatch]
  );

  // To prevent section swaping flickering
  const throttledSwapSections = useMemo(
    () => _.throttle(swapSectionsCallback, 10),
    [swapSectionsCallback]
  );

  useEffect(() => {
    if (
      dragStartSection &&
      dragEnterSection &&
      dragStartSection !== dragEnterSection
    ) {
      throttledSwapSections(dragStartSection, dragEnterSection);
    }
    return () => {
      throttledSwapSections.cancel();
    };
  }, [throttledSwapSections, dragStartSection, dragEnterSection]);

  const dragEnter = useCallback(
    (e: DragEvent<HTMLElement>) => {
      const targetColumn = e.currentTarget.getAttribute(
        "id"
      ) as DetailsColumn | null;
      if (dragStartSection && targetColumn) {
        dispatch(
          moveSectionToColumn({
            source: dragStartSection,
            target: targetColumn,
          })
        );
      }
    },
    [dragStartSection, dispatch]
  );

  return (
    <PaneElementDiv
      id={props.paneElementId}
      className={props.paneElementClassName}
    >
      {props.sections.map((x, index) =>
        createSection(x, props.columnType, index)
      )}
      <ColumnDragAndDropPlaceholder
        id={props.columnType}
        onDragEnter={dragEnter}
      />
    </PaneElementDiv>
  );
}

export default PaneElement;
