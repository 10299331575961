import {
  externalLimited,
  internalPublic,
} from "../../../../../app/helpers/securityLevelHelper";
import { TooltipTop } from "../../../../../controls/Tooltips/ToolipTop";
import { DocumentSecurityLevel } from "../../../../../models/documentSecurityLevel";
import {
  ExternalLimitedPill,
  ExternalPublicPill,
  HiddenFromExternalPill,
  InternalLimitedPill,
  InternalPublicPill,
} from "../../../../../models/securityLevels";
import { Pill } from "./Pill";

export interface SecurityLevelPillProps {
  isGray: boolean;
  securityLevel: DocumentSecurityLevel;
  isInternal: boolean;
}

export function SecurityLevelPill(props: SecurityLevelPillProps) {
  if (props.isInternal) {
    if (internalPublic.includes(props.securityLevel)) {
      return (
        <TooltipTop title={InternalPublicPill}>
          <Pill isGray={props.isGray} content="IP" className="security-pill" />
        </TooltipTop>
      );
    } else {
      return (
        <TooltipTop title={InternalLimitedPill}>
          <Pill isGray={props.isGray} content="IL" className="security-pill" />
        </TooltipTop>
      );
    }
  }

  if (externalLimited.includes(props.securityLevel)) {
    return (
      <TooltipTop title={ExternalLimitedPill}>
        <Pill isGray={props.isGray} content="EL" className="security-pill" />
      </TooltipTop>
    );
  }
  if (
    props.securityLevel ===
    DocumentSecurityLevel.ExternalPublicAndInternalPublic
  ) {
    return (
      <TooltipTop title={ExternalPublicPill}>
        <Pill isGray={props.isGray} content="EP" className="security-pill" />
      </TooltipTop>
    );
  }
  if (
    props.securityLevel ===
    DocumentSecurityLevel.HiddenFromExternalAndInternalPublic
  ) {
    return (
      <TooltipTop title={HiddenFromExternalPill}>
        <Pill isGray={props.isGray} content="H" className="security-pill" />
      </TooltipTop>
    );
  }

  return <></>;
}
