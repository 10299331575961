import { buildCategoryText } from "../../../../../app/helpers/categoriesHelper";
import { openSnackbar } from "../../../../../app/helpers/snackBarHelper";
import { useAppDispatch } from "../../../../../app/hooks/hooks";
import { SnackbarMsg } from "../../../../../controls/Snackbar/SnackbarMessages";
import { TooltipTop } from "../../../../../controls/Tooltips/ToolipTop";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";
import { CategoryPathMetadata } from "../../../../../models/documentDetails/documentMetadata";
import { SnackbarType } from "../../../../../models/snackbar";
import { CategoryBreadCrumbCopyIcon } from "../SC/CategoryBreadCrumbCopyIcon";

export interface CopyPathButtonProps {
  isVisible: boolean;
  categories: CategoryPathMetadata[];
}

export function CopyPathButton(props: CopyPathButtonProps) {
  const dispatch = useAppDispatch();

  const copyWholePath = () => {
    let pathString = "";
    const categories = [...props.categories].reverse();
    categories.forEach((path, index, array) => {
      pathString += buildCategoryText(path);

      if (++index != array.length) {
        pathString += "  >>  ";
      }
    });

    return pathString;
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(copyWholePath())
      .then(() =>
        openSnackbar(
          dispatch,
          SnackbarMsg.CopyCategoryPathSuccess,
          SnackbarType.success
        )
      )
      .catch(() => {
        openSnackbar(
          dispatch,
          SnackbarMsg.CopyCategoryPathError,
          SnackbarType.error
        );
      });
  };

  return (
    <TooltipTop title={TooltipMsg.CopyWholePath}>
      <CategoryBreadCrumbCopyIcon
        onClick={copyToClipboard}
        $isVisible={props.isVisible}
      />
    </TooltipTop>
  );
}
