import { useAppSelector } from "../../../app/hooks/hooks";
import { GoToLatestRevisionButton } from "./buttons/GoToLatestRevisionButton";
import { ShareUrlButton } from "./buttons/ShareURLButton";
import {
  getButtonDisabledTooltipText,
  noEditorRightsText,
} from "./helpers/buttonsHelper";
import { selectDocumentStatus } from "../../../app/slices/documentMetadataSlice";
import { PermissionsEditableState } from "../../../app/hooks/permissions/useUserPermissionsInDocument";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { DiscardDraftButton } from "./buttons/DiscardDraftButton";
import { PublishButton } from "./buttons/publish/PublishButton";
import { SendForApprovalButton } from "./buttons/SendForApprovalButton";
import { selectIsWindowTooSmall } from "../../../app/slices/commonSlice";
import { selectUserEmail } from "../../../app/slices/authenticationSlice";

interface DraftDocumentButtonsProps {
  hasPermissionToEditDocument: PermissionsEditableState;
  hasCategoriesWithoutApproval: boolean;
  isEditBlockedForPendingApproval: boolean;
  approvalRequired: boolean;
  isNewDocument: boolean;
}

export function DraftDocumentButtons(props: DraftDocumentButtonsProps) {
  const isWindowToSmall = useAppSelector(selectIsWindowTooSmall);
  const documentStatus = useAppSelector(selectDocumentStatus);
  const userEmail = useAppSelector(selectUserEmail);

  return (
    <>
      {props.approvalRequired &&
        props.hasPermissionToEditDocument.isEditable && (
          <SendForApprovalButton
            disabled={props.isEditBlockedForPendingApproval}
            disabledTooltipText={TooltipMsg.CannotApprovePendingApproval}
          />
        )}
      {!documentStatus.isFromServiceIntegration && <PublishButton
        id="document-details-publish-button"
        color="primary"
        userEmail={userEmail}
        text="Publish"
        disabled={
          !props.hasPermissionToEditDocument.isEditable ||
          props.approvalRequired ||
          props.isEditBlockedForPendingApproval ||
          props.hasCategoriesWithoutApproval
        }
        disabledTooltipText={getButtonDisabledTooltipText(
          props.hasPermissionToEditDocument.isEditable,
          props.approvalRequired,
          props.isEditBlockedForPendingApproval,
          false,
          props.hasCategoriesWithoutApproval
        )}
        isCompactView={isWindowToSmall}
      />}
      {!props.isNewDocument && (
        <PublishButton
          id="publish-do-not-change-publisher"
          color="secondary"
          userEmail={""}
          text="Publish do not change publisher"
          disabled={
            !props.hasPermissionToEditDocument.isEditable ||
            props.approvalRequired ||
            props.isEditBlockedForPendingApproval ||
            props.hasCategoriesWithoutApproval
          }
          disabledTooltipText={getButtonDisabledTooltipText(
            props.hasPermissionToEditDocument.isEditable,
            props.approvalRequired,
            props.isEditBlockedForPendingApproval,
            false,
            props.hasCategoriesWithoutApproval
          )}
          isCompactView={isWindowToSmall}
        />
      )}
      <DiscardDraftButton
        isCompactView={isWindowToSmall}
        disabled={!props.hasPermissionToEditDocument.isEditable}
        disabledTooltipText={noEditorRightsText}
      />
      <ShareUrlButton isCompactView={isWindowToSmall} />
      {!documentStatus.isLatest && (
        <GoToLatestRevisionButton
          latestRevision={documentStatus.latestRevision}
          isCompactView={isWindowToSmall}
        />
      )}
    </>
  );
}
