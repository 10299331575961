import { InformationControl } from "../../../controls/InformationControl/InformationControl";

interface NoTagsFoundProps {
  title: string;
  description: string;
}

export function NoTagsFound(props: NoTagsFoundProps) {
  return (
    <InformationControl
      id={"no-tag-values-found"}
      title={props.title}
      description={props.description}
    />
  );
}
