import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { openSnackbar } from "../../app/helpers/snackBarHelper";
import { useAppDispatch } from "../../app/hooks/hooks";
import {
  setIsUpdatingDocument,
  setUsePrompt,
} from "../../app/slices/documentDetailsSlice";
import { SnackbarType } from "../../models/snackbar";
import { useLazyDiscardDocumentDraftQuery } from "../documentDraftApi";

interface DiscardDraftProps {
  successMessage: string;
  errorMessage: string;
}

export function useDiscardDraft(props: DiscardDraftProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [
    discardDocumentDraft,
    {
      isError: isDiscardDocumentDraftError,
      isSuccess: isDiscardDocumentDraftSuccess,
      isLoading: isDiscardDocumentDraftLoading,
    },
  ] = useLazyDiscardDocumentDraftQuery();

  useEffect(() => {
    if (isDiscardDocumentDraftSuccess) {
      navigate("/");
      openSnackbar(dispatch, props.successMessage, SnackbarType.success);
      dispatch(setUsePrompt(true));
    }
  }, [dispatch, navigate, isDiscardDocumentDraftSuccess, props.successMessage]);

  useEffect(() => {
    if (isDiscardDocumentDraftError) {
      openSnackbar(dispatch, props.errorMessage, SnackbarType.error);
    }
  }, [dispatch, isDiscardDocumentDraftError, props.errorMessage]);

  useEffect(() => {
    dispatch(setIsUpdatingDocument(isDiscardDocumentDraftLoading));
  }, [dispatch, isDiscardDocumentDraftLoading]);

  return {
    discardDocumentDraft,
  };
}
