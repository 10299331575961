import { useCallback, useEffect, useState } from "react";
import {
  useLazyGetAvailableRevisionsNewRevisionQuery,
  useLazyGetAvailableRevisionsQuery,
} from "../../../../apis/revisionsApi";
import { openSnackbar } from "../../../../app/helpers/snackBarHelper";
import { useDocumentEditedMode } from "../../../../app/hooks/document/useDocumentEditedMode";
import { useDocumentFlow } from "../../../../app/hooks/document/useDocumentFlow";
import { useDocumentState } from "../../../../app/hooks/document/useDocumentState";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import {
  selectDocumentIdentity,
  setEditedRevision,
  setFetchedNewDocumentRevision,
} from "../../../../app/slices/documentMetadataSlice";
import { ComboBoxListItem } from "../../../../models/autocompleteValue";
import { DraftType } from "../../../../models/documentDetails/documentStatus";
import { SnackbarType } from "../../../../models/snackbar";
import { DetailsAutoComplete } from "../../inputs/DetailsAutoComplete";
import { Editable } from "../common/Editable";
import { SnackbarMsg } from "../../../../controls/Snackbar/SnackbarMessages";

interface PartProps extends Editable {
  revision: string;
  isLoadingDetailsMetaData: boolean;
}

export function Revision(props: PartProps) {
  const deprecatedValue = "-";
  const { isNewDocument, isNewDocumentRevision } = useDocumentFlow();
  const dispatch = useAppDispatch();
  const { documentDraftType } = useDocumentState();
  const { identitySection } = useDocumentEditedMode();
  const documentIdentity = useAppSelector(selectDocumentIdentity);
  const [availableRevisions, setAvailableRevisions] = useState<
    ComboBoxListItem[]
  >([]);
  const [selectedRevision, setSelectedRevision] = useState(props.revision);
  const [allRevisionsResponse, setAllRevisionsResponse] = useState<string[]>(
    []
  );

  const getAllAvailableRevisions = useCallback(() => {
    const alphabet: string[] = [];

    for (let l = "A".charCodeAt(0); l <= "Z".charCodeAt(0); l++) {
      alphabet.push(String.fromCharCode(l));
    }
    const twoLetterCombinations = alphabet.flatMap((firstLetter) =>
      alphabet.map((secondLetter) => firstLetter + secondLetter)
    );

    setAllRevisionsResponse([...alphabet, ...twoLetterCombinations]);
  }, []);

  const [
    getAvailableRevisions,
    { data: revisionsResponse, isError: isRevisionsError },
  ] = useLazyGetAvailableRevisionsQuery();

  const [
    getAvailableRevisionsNewRevision,
    {
      data: revisionsResponseNewRevision,
      isError: isRevisionsErrorNewRevision,
    },
  ] = useLazyGetAvailableRevisionsNewRevisionQuery();

  useEffect(() => {
    if (!props.isLoadingDetailsMetaData && props.isEditable) {
      switch (true) {
        case isNewDocument && !documentIdentity.documentNumber:
          getAllAvailableRevisions();
          break;
        case isNewDocumentRevision && documentIdentity.documentNumber !== "":
          void getAvailableRevisionsNewRevision(documentIdentity, false);
          break;
        case documentIdentity.documentNumber !== "":
          void getAvailableRevisions(documentIdentity, false);
          break;
      }
    }
    if (!props.isEditable) {
      setAvailableRevisions([
        {
          key: documentIdentity.revision,
          value: documentIdentity.revision,
          isDisabled: true,
        },
      ]);
    }
  }, [
    documentIdentity,
    setAvailableRevisions,
    getAllAvailableRevisions,
    getAvailableRevisionsNewRevision,
    getAvailableRevisions,
    isNewDocument,
    isNewDocumentRevision,
    props.isEditable,
    props.isLoadingDetailsMetaData,
  ]);

  useEffect(() => {
    if (
      availableRevisions.length > 0 &&
      availableRevisions.find((r) => r.key === props.revision) === undefined
    ) {
      setSelectedRevision(availableRevisions[0].key);
    } else {
      setSelectedRevision(props.revision);
    }
  }, [props.revision, availableRevisions]);

  useEffect(() => {
    if (isRevisionsError || isRevisionsErrorNewRevision) {
      openSnackbar(
        dispatch,
        SnackbarMsg.AvailableRevisionsError,
        SnackbarType.error
      );
    }
  }, [dispatch, isRevisionsError, isRevisionsErrorNewRevision]);

  useEffect(() => {
    let clone: string[] = [];

    if (revisionsResponseNewRevision) {
      clone = [...revisionsResponseNewRevision];
      const firstAvailableRevision = revisionsResponseNewRevision[0];
      setSelectedRevision(firstAvailableRevision);
      dispatch(setEditedRevision(firstAvailableRevision));
      dispatch(setFetchedNewDocumentRevision(firstAvailableRevision));
    }

    if (revisionsResponse) {
      clone = [...revisionsResponse];
    }

    if (allRevisionsResponse.length > 0) {
      clone = [...allRevisionsResponse];
      setSelectedRevision(allRevisionsResponse[0]);
      dispatch(setEditedRevision(allRevisionsResponse[0]));
      dispatch(setFetchedNewDocumentRevision(allRevisionsResponse[0]));
    }

    const revisions = clone.map((x) => {
      return {
        key: x,
        value: x,
        isDisabled:
          x === deprecatedValue &&
          documentDraftType == DraftType.NewDocumentDraft,
      };
    });

    setAvailableRevisions(revisions);
  }, [
    documentDraftType,
    isNewDocumentRevision,
    revisionsResponse,
    revisionsResponseNewRevision,
    allRevisionsResponse,
    dispatch,
  ]);

  const onRevisionChangeHandler = (newValues: ComboBoxListItem[] | null) => {
    if (newValues) {
      dispatch(setEditedRevision(newValues[0].value));
    }
  };

  return (
    <DetailsAutoComplete
      id="revision-dropdown"
      labelText="Revision"
      multiple={false}
      selected={[
        {
          key: selectedRevision,
          value: selectedRevision,
        },
      ]}
      items={availableRevisions}
      isEditable={props.isEditable}
      isDisabled={!props.isEditable}
      isLoading={props.isLoadingDetailsMetaData}
      onChangeHandler={onRevisionChangeHandler}
      isEdited={identitySection.revisionIsEdited}
      notEditableMessage={props.notEditableMessage}
    />
  );
}
