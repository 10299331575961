import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useState } from "react";
import { useTheme } from "styled-components";
import { useGetAppSettingsQuery } from "../../../../../apis/configApi";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import {
  selectAllGroupAccordionState,
  selectExpandedGroupsCount,
  selectGroupItemsToLoadCount,
  selectGroupsCount,
  selectIsDocumentsListLoading,
  setAllGroupsToCollapse,
  setAllGroupsToExpand,
} from "../../../../../app/slices/documentListSlice";
import { TextButtonControl } from "../../../../../controls/Buttons/TextButtonControl";
import { DialogControl } from "../../../../../controls/Dialog/DialogControl";
import { AccordionState } from "../../../../../models/documentList/types/accordionState";
import { ButtonDiv } from "../../SC/ButtonDiv";
import { StyledDialogContentText } from "../../../../details/selfHelpPopup/SC/StyledDialogContentText";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";

interface ExpandCollapseGroupProps {
  isCompactView: boolean;
}

export function ExpandCollapseGroup(props: ExpandCollapseGroupProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const groupItemsCount = useAppSelector(selectGroupItemsToLoadCount);
  const groupsCount = useAppSelector(selectGroupsCount);
  const allGroupAccordionState = useAppSelector(selectAllGroupAccordionState);
  const expandedGroupsCount = useAppSelector(selectExpandedGroupsCount);
  const isDocumentListLoading = useAppSelector(selectIsDocumentsListLoading);
  const { data: appSettings } = useGetAppSettingsQuery(null);

  const handleClose = () => {
    setOpenDialog(false);
  };

  function handleExpand(expand: boolean) {
    setOpenDialog(false);

    if (expand) {
      dispatch(setAllGroupsToExpand());
    }
  }

  function showDialog(): boolean {
    if (
      appSettings?.documentList.expandCollapseDialog.show &&
      allGroupAccordionState !== AccordionState.Expanded
    ) {
      return (
        appSettings.documentList.expandCollapseDialog.revisionMinLimit <=
        groupItemsCount
      );
    }

    return false;
  }

  const handleExpandCollapseGroup = () => {
    if (allGroupAccordionState === AccordionState.Expanded) {
      dispatch(setAllGroupsToCollapse());
    } else if (showDialog()) {
      setOpenDialog(true);
    } else {
      dispatch(setAllGroupsToExpand());
    }
  };

  return (
    <ButtonDiv>
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.primaryButtonHeight}
        icon={
          expandedGroupsCount === 0 ? (
            <UnfoldMoreIcon fontSize="small" />
          ) : (
            <UnfoldLessIcon fontSize="small" />
          )
        }
        disabled={groupsCount === 0 || isDocumentListLoading}
        id="expand-collapse-group-button"
        tooltipText={TooltipMsg.ExpandCollapseGroups}
        text={expandedGroupsCount === 0 ? "EXPAND GROUPS" : "COLLAPSE GROUPS"}
        onClick={handleExpandCollapseGroup}
        isVertical={false}
        isVisible={true}
        isCompactView={props.isCompactView}
      />

      <DialogControl
        id="expand-group"
        isOpen={openDialog}
        useActions={true}
        maxWidth="sm"
        title={"Expand groups"}
        content={
          <StyledDialogContentText id="alert-dialog-description">
            This operation might take a while as there are {groupItemsCount}{" "}
            revisions to be loaded. Are you sure you want to expand all groups
            on this page?
          </StyledDialogContentText>
        }
        onCloseClick={handleClose}
        onConfirmClick={() => {
          handleExpand(true);
        }}
        onCancelClick={() => {
          handleExpand(false);
        }}
      />
    </ButtonDiv>
  );
}
