import _, { sortBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  useLazyGetAllDocumentKindsQuery,
  useLazyGetDocumentKindsListQuery,
} from "../../../apis/documentKindsApi";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectCurrentDocumentMainCategory } from "../../slices/documentMetadataSlice";
import { openSnackbar } from "../../helpers/snackBarHelper";
import { SnackbarType } from "../../../models/snackbar";
import DocumentKind from "../../../models/documentKind";
import { SnackbarMsg } from "../../../controls/Snackbar/SnackbarMessages";

export function useDocumentDetailsDocumentKinds() {
  const dispatch = useAppDispatch();
  const currentDocumentMainCategory = useAppSelector(
    selectCurrentDocumentMainCategory
  );
  const [sortedDocumentKinds, setSortedDocumentKinds] = useState<
    DocumentKind[]
  >([]);
  const [sortedAllDocumentKinds, setSortedAllDocumentKinds] = useState<
    DocumentKind[]
  >([]);

  const [
    getDocumentKinds,
    {
      data: docKinds,
      isError: isDocumentKindsError,
      isFetching: isDocumentKindsFetching,
      isLoading: isDocumentKindsLoading,
    },
  ] = useLazyGetDocumentKindsListQuery();

  const [
    getAllDocumentKinds,
    { data: allDocumentKinds, isError: isErrorAllDocumentKinds },
  ] = useLazyGetAllDocumentKindsQuery();

  const fetchAllDocumentKinds = useCallback(
    (isLoadingDetailsMetadata: boolean) => {
      if (!isLoadingDetailsMetadata && !currentDocumentMainCategory) {
        void getAllDocumentKinds(null);
      }
    },
    [currentDocumentMainCategory, getAllDocumentKinds]
  );

  const fetchDocumentKinds = useCallback(
    (isLoadingDetailsMetadata: boolean) => {
      if (!isLoadingDetailsMetadata && currentDocumentMainCategory) {
        void getDocumentKinds(currentDocumentMainCategory.cid, true);
      }
    },
    [currentDocumentMainCategory, getDocumentKinds]
  );

  useEffect(() => {
    if (allDocumentKinds) {
      setSortedAllDocumentKinds(sortBy(allDocumentKinds, ["name"]));
    }
  }, [allDocumentKinds]);

  useEffect(() => {
    const docKindsFromMainCategory = _.filter(docKinds, [
      "isOnlyInDocument",
      false,
    ]);
    if (!currentDocumentMainCategory) {
      setSortedDocumentKinds([]);
    } else {
      setSortedDocumentKinds(sortBy(docKindsFromMainCategory, ["name"]));
    }
  }, [docKinds, currentDocumentMainCategory]);

  useEffect(() => {
    if (isDocumentKindsError || isErrorAllDocumentKinds) {
      openSnackbar(
        dispatch,
        SnackbarMsg.DocumentKindsError,
        SnackbarType.error
      );
    }
  }, [
    dispatch,
    isDocumentKindsError,
    isErrorAllDocumentKinds,
    fetchDocumentKinds,
    fetchAllDocumentKinds,
  ]);

  return {
    fetchAllDocumentKinds,
    fetchDocumentKinds,
    sortedAllDocumentKinds,
    sortedDocumentKinds,
    isDocumentKindsError,
    isErrorAllDocumentKinds,
    isLoading: isDocumentKindsLoading || isDocumentKindsFetching,
  };
}
