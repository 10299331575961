import { useEffect, useState } from "react";
import { TreeItemLi } from "./SC/TreeItemDiv";
import { TreeItemNamePartSpan } from "./SC/TreeItemNamePartSpan";
import { TreeItemSpan } from "./SC/TreeItemSpan";
import { TreeItemIconDiv } from "./SC/TreeitemIconDiv";
import { SelectedCategory } from "../../../../../app/slices/documentMetadataSlice";
import { useAppSelector } from "../../../../../app/hooks/hooks";
import { selectSelectedNode } from "../../../../../app/slices/ownerCategoryTreeSlice";
import { TooltipTop } from "../../../../../controls/Tooltips/ToolipTop";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";
import { StyledRemoveIcon } from "./SC/StyledRemoveIcon";
import { StyledAddIcon } from "./SC/StyledAddIcon";

export interface NodeNamePart {
  part: string;
  isHighlighted: boolean;
}

interface TreeItemProps {
  nodeId: string;
  isHighLevelNode: boolean;
  nodeNameParts: NodeNamePart[];
  selectable: boolean;
  hasAccess: boolean;
  hasAccessInside: boolean;
  expanded?: boolean;
  highlighted: boolean;
  level: number;
  expandable: boolean;
  defaultPadding: number;
  onExpand: (nodeId: string) => void;
  onCollapse: (nodeId: string) => void;
  onSelect: (nodeId: SelectedCategory) => void;
  onDeselect: (nodeId: SelectedCategory) => void;
}

export function TreeItem(props: TreeItemProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(!!props.expanded);
  const isSelected = useAppSelector(selectSelectedNode) === props.nodeId;

  const onExpand = () => {
    setIsExpanded(true);
    props.onExpand(props.nodeId);
  };

  const onCollapse = () => {
    setIsExpanded(false);
    props.onCollapse(props.nodeId);
  };

  const onToggle = () => {
    if (isExpanded) {
      onCollapse();
    } else {
      onExpand();
    }
  };

  const onToggleSelect = () => {
    if (isSelected) {
      props.onDeselect({
        categoryId: props.nodeId,
        categoryName: props.nodeNameParts[props.nodeNameParts.length - 1].part,
        isHighLevelCategory: props.isHighLevelNode,
        hasChildren: props.expandable,
      });
    } else {
      props.onSelect({
        categoryId: props.nodeId,
        categoryName: props.nodeNameParts[props.nodeNameParts.length - 1].part,
        isHighLevelCategory: props.isHighLevelNode,
        hasChildren: props.expandable,
      });
    }
  };

  const onItemClick = () => {
    if (props.selectable) {
      onToggleSelect();
    } else if (props.expandable) {
      onToggle();
    }
  };

  const getPaddingLeft = (level: number) => {
    const rootCategoryLevel = -2;
    const indentation = level - rootCategoryLevel;

    return indentation;
  };

  const getTooltipTitle = (
    hasUserAccessInside: boolean,
    hasUserAccess: boolean
  ) => {
    if (hasUserAccess) return TooltipMsg.UserIsCategoryOwner;
    if (hasUserAccessInside) return TooltipMsg.UserIsCategoryOwnerInside;

    return TooltipMsg.UserIsNotCategoryOwner;
  };

  useEffect(() => {
    setIsExpanded(!!props.expanded);
  }, [setIsExpanded, props.expanded]);

  return (
    <TreeItemLi
      data-category-id={props.nodeId}
      hasAccess={props.hasAccess || props.hasAccessInside}
      selected={isSelected}
      paddingLeft={props.defaultPadding * getPaddingLeft(props.level)}
    >
      <TreeItemIconDiv>
        {props.expandable &&
          (isExpanded ? (
            <StyledRemoveIcon fontSize="inherit" onClick={onCollapse} />
          ) : (
            <StyledAddIcon fontSize="inherit" onClick={onExpand} />
          ))}
      </TreeItemIconDiv>
      <TooltipTop
        title={getTooltipTitle(props.hasAccessInside, props.hasAccess)}
      >
        <TreeItemSpan
          hasAccess={props.hasAccess || props.hasAccessInside}
          onClick={onItemClick}
        >
          {props.nodeNameParts.map((part) => (
            <TreeItemNamePartSpan
              key={part.part}
              highlighted={part.isHighlighted}
            >
              {part.part}
            </TreeItemNamePartSpan>
          ))}
        </TreeItemSpan>
      </TooltipTop>
    </TreeItemLi>
  );
}
