import { useAppSelector } from "../../../app/hooks/hooks";
import { PermissionsEditableState } from "../../../app/hooks/permissions/useUserPermissionsInDocument";
import { selectUserEmail } from "../../../app/slices/authenticationSlice";
import { selectIsWindowTooSmall } from "../../../app/slices/commonSlice";
import {
  selectDocumentStatus,
  selectPublisherEmail,
} from "../../../app/slices/documentMetadataSlice";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { DiscardChangesButton } from "./buttons/DiscardChangesButton";
import { DiscardDraftButton } from "./buttons/DiscardDraftButton";
import { PublishButton } from "./buttons/publish/PublishButton";
import { ShareUrlButton } from "./buttons/ShareURLButton";
import {
  getButtonDisabledTooltipText,
  noEditorRightsText,
} from "./helpers/buttonsHelper";

export interface ApprovalDocumentButtonsProps {
  hasPermissionToApproveDocument: boolean;
  hasCategoriesWithoutApproval: boolean;
  approvalRequired: boolean;
  isApprovalCategory: boolean;
  hasAccessToAnyDocumentCategory: boolean;
  hasPermissionToEditDocument: PermissionsEditableState;
  isEdited: boolean;
}

export function ApprovalDocumentButtons(props: ApprovalDocumentButtonsProps) {
  const isWindowToSmall = useAppSelector(selectIsWindowTooSmall);
  const userEmail = useAppSelector(selectUserEmail);
  const publisherEmail = useAppSelector(selectPublisherEmail);
  const documentStatus = useAppSelector(selectDocumentStatus);
  const canRejectDocument =
    props.hasPermissionToApproveDocument || userEmail === publisherEmail;

  return (
    <>
      {props.isApprovalCategory && !props.isEdited ? (
        <PublishButton
          id="document-details-approve-button"
          isCompactView={isWindowToSmall}
          isApproveButton={true}
          userEmail=""
          text={"Approve"}
          color={"primary"}
          disabled={
            !props.hasPermissionToApproveDocument ||
            !props.hasPermissionToEditDocument.isEditable ||
            props.hasCategoriesWithoutApproval
          }
          disabledTooltipText={getButtonDisabledTooltipText(
            props.hasPermissionToEditDocument.isEditable,
            props.approvalRequired,
            false,
            false,
            props.hasCategoriesWithoutApproval
          )}
        />
      ) : (
        <>
          {!documentStatus.isFromServiceIntegration && (
            <PublishButton
              id="publish"
              text="Publish"
              color="primary"
              userEmail={userEmail}
              disabled={
                !props.hasPermissionToEditDocument.isEditable ||
                props.hasCategoriesWithoutApproval
              }
              disabledTooltipText={
                !props.hasPermissionToEditDocument.isEditable
                  ? noEditorRightsText
                  : props.hasCategoriesWithoutApproval
                    ? TooltipMsg.CategoriesWithoutApproval
                    : undefined
              }
              isCompactView={isWindowToSmall}
            />
          )}
          <PublishButton
            id="publish-do-not-change-publisher"
            color="secondary"
            isApproveButton={true}
            userEmail=""
            text="Publish do not change publisher"
            disabled={
              !props.hasPermissionToEditDocument.isEditable ||
              props.hasCategoriesWithoutApproval
            }
            disabledTooltipText={
              !props.hasPermissionToEditDocument.isEditable
                ? noEditorRightsText
                : props.hasCategoriesWithoutApproval
                  ? TooltipMsg.CategoriesWithoutApproval
                  : undefined
            }
            isCompactView={isWindowToSmall}
          />
        </>
      )}
      {props.isApprovalCategory ? (
        <DiscardDraftButton
          disabled={
            !canRejectDocument ||
            (props.hasCategoriesWithoutApproval && userEmail !== publisherEmail)
          }
          disabledTooltipText={getButtonDisabledTooltipText(
            true,
            !canRejectDocument,
            false,
            false,
            props.hasCategoriesWithoutApproval
          )}
          isCompactView={isWindowToSmall}
          isRejectApprovalButton={true}
        />
      ) : (
        <DiscardDraftButton
          disabled={
            !props.hasPermissionToEditDocument.isEditable ||
            (props.hasCategoriesWithoutApproval && userEmail !== publisherEmail)
          }
          disabledTooltipText={
            !props.hasPermissionToEditDocument.isEditable
              ? noEditorRightsText
              : props.hasCategoriesWithoutApproval
                ? TooltipMsg.CategoriesWithoutApproval
                : undefined
          }
          isCompactView={isWindowToSmall}
        />
      )}
      {props.isEdited && <DiscardChangesButton />}
      <ShareUrlButton isCompactView={isWindowToSmall} />
    </>
  );
}
