import { ListItem } from "@mui/material";
import styled from "styled-components";
export const StyledListItem = styled(ListItem)`
  &.MuiListItem-root {
    padding: 0px;
    padding-left: 0px;
    cursor: default;
    height: 44px;
  }
`;
