import EventNoteIcon from "@mui/icons-material/EventNote";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import {
  selectDocumentIdentity,
  setScheduledAction,
  setScheduledActionType,
} from "../../../../../app/slices/documentMetadataSlice";
import { getNonEmptyDocumentIdentity } from "../../../../../app/helpers/documentIdentityHelper";
import {
  ActionButton,
  addVisibleButton,
  removeVisibleButton,
  selectDetailsHasRequiredUncompletedSections,
} from "../../../../../app/slices/selfHelpSlice";
import { TextButtonControl } from "../../../../../controls/Buttons/TextButtonControl";
import { SnackbarMsg } from "../../../../../controls/Snackbar/SnackbarMessages";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";
import { SnackbarType } from "../../../../../models/snackbar";
import { openSnackbar } from "../../../../../app/helpers/snackBarHelper";
import { useLazyGetDocumentActionsQuery } from "../../../../../apis/scheduledActionsApi";
import {
  setScheduledActionContent,
  setScheduledActionIsErrorVisible,
  setScheduledActionIsLoading,
} from "../../../../../app/slices/scheduledActionSlice";
import { ButtonDiv } from "../../SC/ButtonDiv";
import { ScheduledActionDialog } from "./ScheduledActionDialog";
import { debounce } from "lodash";
import { Badge } from "@mui/material";

interface ScheduledActionButtonProps {
  disabled?: boolean;
  disabledTooltipText?: string;
  isCompactView?: boolean;
}

export function ScheduledActionButton(props: ScheduledActionButtonProps) {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [scheduledExists, setScheduledExists] = useState(false);
  const theme = useTheme();
  const documentIdentity = useAppSelector(selectDocumentIdentity);
  const hasUncompletedSections = useAppSelector(
    selectDetailsHasRequiredUncompletedSections
  );

  const notEmptyIdentity = useMemo(
    () => getNonEmptyDocumentIdentity(documentIdentity),
    [documentIdentity]
  );

  const [getScheduledActions, { data, isLoading, isError }] =
    useLazyGetDocumentActionsQuery();

  const debounceSetLoadingToFalse = debounce(function () {
    dispatch(setScheduledActionIsLoading(false));
    dispatch(setScheduledActionIsErrorVisible(false));
  }, 1000);

  const onClose = useCallback(() => {
    setIsOpen(false);
    debounceSetLoadingToFalse();
  }, [debounceSetLoadingToFalse]);

  const title = useMemo(() => {
    if (data) {
      return "Scheduled action";
    } else {
      return "Schedule new action";
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      openSnackbar(
        dispatch,
        SnackbarMsg.ScheduledActionsError,
        SnackbarType.error
      );
    }
  }, [dispatch, isError]);

  useEffect(() => {
    if (notEmptyIdentity.documentNumber) {
      void getScheduledActions(notEmptyIdentity);
    }
  }, [getScheduledActions, notEmptyIdentity]);

  useEffect(() => {
    if (data) {
      dispatch(setScheduledActionContent(data));
      dispatch(setScheduledAction(true));
      dispatch(setScheduledActionType(data.action));
      dispatch(removeVisibleButton(ActionButton.Add));
      setScheduledExists(true);
    } else {
      dispatch(setScheduledAction(false));
      dispatch(setScheduledActionType(undefined));
      dispatch(addVisibleButton(ActionButton.Add));
      setScheduledExists(false);
    }
    return () => {
      dispatch(setScheduledAction(false));
      dispatch(setScheduledActionContent(null));
      dispatch(removeVisibleButton(ActionButton.Add));
      setScheduledExists(false);
    };
  }, [dispatch, data, isLoading, isOpen]);

  return (
    <ButtonDiv>
      <ScheduledActionDialog
        isOpen={isOpen}
        onCloseClick={onClose}
        title={title}
        content={data}
      />
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.primaryButtonHeight}
        id={"document-details-scheduled-actions-button"}
        text={"Scheduled action"}
        tooltipText={
          isLoading
            ? TooltipMsg.IsLoadingScheduledActions
            : TooltipMsg.ShowScheduledActions
        }
        disabledTooltipText={
          hasUncompletedSections
            ? TooltipMsg.UncompletedSelfHelpSections
            : props.disabledTooltipText
        }
        disabled={props.disabled || isLoading || hasUncompletedSections}
        onClick={(event) => {
          setIsOpen(true);
          event.stopPropagation();
        }}
        isCompactView={props.isCompactView == true}
        isVertical={false}
        isVisible={true}
        icon={
          <Badge color={"primary"} variant="dot" invisible={!scheduledExists}>
            <EventNoteIcon fontSize="small" />
          </Badge>
        }
      />
    </ButtonDiv>
  );
}
