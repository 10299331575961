import { SectionHeaderSpan } from "../../../header/SC/SectionHeaderSpan";
import LabelIcon from "../../../inputs/LabelIcon";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";
import SectionHeaderStatus from "../SectionHeaderStatus";

interface TextControlProps {
  name: string;
  isEditable: boolean;
  isEdited: boolean;
  isValidationError?: boolean;
  isWarning?: boolean;
  customIcon?: "link" | "serviceIntegration";
  notEditableReason?: TooltipMsg;
  isCompactView: boolean;
  setValidationErrorText: (state: string | undefined) => void;
  setValidationErrorColor: (state: string | undefined) => void;
}

export function TextControl(props: TextControlProps) {
  return (
    <SectionHeaderSpan disabled={!props.isEditable}>
      {props.name}
      <SectionHeaderStatus
        isValidationError={props.isValidationError}
        isEdited={props.isEdited}
        setValidationErrorColor={props.setValidationErrorColor}
        setValidationErrorText={props.setValidationErrorText}
        isWarning={props.isWarning}
        isCompactView={props.isCompactView}
      />
      {(!props.isEditable || props.customIcon) && (
        <LabelIcon
          icon={props.customIcon}
          marginLeft={6}
          size="large"
          message={
            props.notEditableReason
              ? props.notEditableReason
              : TooltipMsg.SectionDisabled
          }
        />
      )}
    </SectionHeaderSpan>
  );
}
