import  {
  Action,
} from "../../../../../models/scheduledActionMessage";
import { ActionDate } from "./models/ActionDate";
import {
  useDeleteDocumentActionsMutation,
  useSetDocumentActionsMutation,
} from "../../../../../apis/scheduledActionsApi";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { selectDocumentIdentity } from "../../../../../app/slices/documentMetadataSlice";
import { getNonEmptyDocumentIdentity } from "../../../../../app/helpers/documentIdentityHelper";
import { useEffect, useMemo } from "react";
import { openSnackbar } from "../../../../../app/helpers/snackBarHelper";
import { SnackbarType } from "../../../../../models/snackbar";
import { ErrorApiResponse } from "../../../../../models/errorApi";
import { ErrorApiType } from "../../../../../models/errorApiType";
import { SnackbarMsg } from "../../../../../controls/Snackbar/SnackbarMessages";
import {
  selectScheduledAction,
  setScheduledActionIsLoading,
} from "../../../../../app/slices/scheduledActionSlice";
import { useGetAppSettingsQuery } from "../../../../../apis/configApi";
import moment from "moment";

export function useScheduledActions() {
  const { data: config } = useGetAppSettingsQuery(null);
  const dateFormat = config ? config.localization.dateFormat : "";
  const [scheduleAction, { isSuccess, isError, isLoading, error, reset }] =
    useSetDocumentActionsMutation();
  const [
    deleteScheduledAction,
    {
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
      isLoading: deleteLoading,
      reset: resetDelete,
    },
  ] = useDeleteDocumentActionsMutation();
  const identity = useAppSelector(selectDocumentIdentity);
  const scheduledAction = useAppSelector(selectScheduledAction);
  const dispatch = useAppDispatch();

  const errors = useMemo(() => {
    if (isError) {
      return !!error &&
        "status" in error &&
        !!(error.data as ErrorApiResponse | undefined)?.errors
        ? (error.data as ErrorApiResponse).errors
        : [{ message: "", code: ErrorApiType.Exception }];
    }
    return [];
  }, [isError, error]);

  const deleteErrors = useMemo(() => {
    if (isDeleteError) {
      return !!deleteError &&
        "status" in deleteError &&
        !!(deleteError.data as ErrorApiResponse | undefined)?.errors
        ? (deleteError.data as ErrorApiResponse).errors
        : [{ message: "", code: ErrorApiType.Exception }];
    }
    return [];
  }, [isDeleteError, deleteError]);

  const getDateRangePicker = {
    days30: ActionDate.days30,
    days90: ActionDate.days90,
    days180: ActionDate.days180,
    year: ActionDate.year,
    years2: ActionDate.years2,
    years3: ActionDate.years3,
    years5: ActionDate.years5,
    custom: ActionDate.custom,
  };

  const getActionsPicker = (canDelete: boolean, canArchive: boolean) => {
    const result = [];
    if (canArchive) {
      result.push({ value: "Archive document", key: Action.Archive });
    }
    if (canDelete) {
      result.push({ value: "Delete document", key: Action.Delete });
    }
    result.push({ value: "Send Email", key: Action.SendEmail });
    return result;
  };

  const calculateNewDate = (selectedDate: ActionDate) => {
    const currentDate = new Date();
    switch (selectedDate) {
      case ActionDate.days30:
        currentDate.setDate(currentDate.getDate() + 30);
        break;
      case ActionDate.days90:
        currentDate.setDate(currentDate.getDate() + 90);
        break;
      case ActionDate.days180:
        currentDate.setDate(currentDate.getDate() + 180);
        break;
      case ActionDate.year:
        currentDate.setFullYear(currentDate.getFullYear() + 1);
        break;
      case ActionDate.years2:
        currentDate.setFullYear(currentDate.getFullYear() + 2);
        break;
      case ActionDate.years3:
        currentDate.setFullYear(currentDate.getFullYear() + 3);
        break;
      case ActionDate.years5:
        currentDate.setFullYear(currentDate.getFullYear() + 5);
        break;
    }
    return currentDate;
  };

  const findAction = (initialAction: Action | undefined) => {
    if (initialAction === undefined) {
      return undefined;
    }
    const action = getActionsPicker(true, true).find(
      (a) => a.key === initialAction
    );
    if (action) {
      return action.value;
    }

    return undefined;
  };

  const scheduleActionHandler = () => {
    if (scheduledAction.expirationDate) {
      dispatch(setScheduledActionIsLoading(true));

      const formattedDate = moment(scheduledAction.expirationDate).format(
        dateFormat
      );
      const documentIdentity = getNonEmptyDocumentIdentity(identity);
      void scheduleAction({
        documentIdentity,
        action: {
          ...scheduledAction,
          expirationDate: formattedDate,
        },
      });
    }
  };

  const deletedScheduledActionHandler = () => {
    dispatch(setScheduledActionIsLoading(true));

    const documentIdentity = getNonEmptyDocumentIdentity(identity);
    void deleteScheduledAction({
      documentIdentity,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      openSnackbar(
        dispatch,
        SnackbarMsg.ScheduledActionSuccess,
        SnackbarType.success
      );
      reset();
    }
  }, [dispatch, reset, isSuccess]);

  useEffect(() => {
    if (isError) {
      let errorMessage: string = SnackbarMsg.ScheduleActionsError;

      if (errors.length > 0 && errors[0].message) {
        errorMessage = errors[0].message;
      }
      dispatch(setScheduledActionIsLoading(false));
      openSnackbar(dispatch, errorMessage, SnackbarType.error);
      reset();
    }
  }, [dispatch, reset, isError, errors]);

  useEffect(() => {
    if (isDeleteSuccess) {
      openSnackbar(
        dispatch,
        SnackbarMsg.DeleteScheduledActionsSuccess,
        SnackbarType.success
      );
      resetDelete();
    }
  }, [dispatch, resetDelete, isDeleteSuccess]);

  useEffect(() => {
    if (isDeleteError) {
      let errorMessage: string = SnackbarMsg.DeleteScheduledActionsError;

      if (deleteErrors.length > 0 && deleteErrors[0].message) {
        errorMessage = deleteErrors[0].message;
      }
      
      dispatch(setScheduledActionIsLoading(false));
      openSnackbar(dispatch, errorMessage, SnackbarType.error);
      resetDelete();
    }
  }, [dispatch, resetDelete, isDeleteError, deleteErrors]);

  return {
    getDateRangePicker,
    getActionsPicker,
    calculateNewDate,
    findAction,
    scheduleActionHandler,
    deletedScheduledActionHandler,
    isSuccess,
    isDeleteSuccess,
    isLoading: isLoading || deleteLoading,
  };
}
