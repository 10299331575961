import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";


export function SectionStepTwo() {
    return (
        <StepContentDiv>
            <Paragraph>
                The purpose of this feature is to showcase documentation within the context of a particular product, such as on Product Pages and in Download Sections that are specifically configured for a designated Product ID and category.
            </Paragraph>
            <Paragraph>
                In these widgets, following documents will be displayed:
                <ul>
                    <li>documents that are assigned to the matching Product ID and category </li>
                    <li>documents that are not assigned to the any Product ID but have a matching category</li>
                </ul>
            </Paragraph>
            <Paragraph>
                Additionally, in the Download Center, users have the ability to search for documents using the Product ID as a search criterion.
            </Paragraph>
        </StepContentDiv>
    );
}
