import { useMemo } from "react";
import { useAppSelector } from "../hooks";
import {
  selectCurrentDocumentCategories,
  selectDocumentCategories,
} from "../../slices/documentMetadataSlice";
import { CategoryToLink } from "../../../apis/documentApi";
import {
  getCategoriesDiff,
  getCategoryIdsDiff,
} from "../../helpers/categoriesHelper";

export function useDocumentCategoriesState() {
  const documentCategories = useAppSelector(selectDocumentCategories);
  const currentDocumentCategories = useAppSelector(
    selectCurrentDocumentCategories
  );

  const oldCategories: CategoryToLink[] = useMemo(
    () =>
      documentCategories.map((cat) => {
        return {
          cid: cat.cid,
          isPrioritized: cat.isPrioritized,
        };
      }),
    [documentCategories]
  );

  const newCategories: CategoryToLink[] = useMemo(
    () =>
      currentDocumentCategories.map((cat) => {
        return {
          cid: cat.cid,
          isPrioritized: cat.isPrioritized,
        };
      }),
    [currentDocumentCategories]
  );

  const categoriesToChange = useMemo(
    () => getCategoriesDiff(newCategories, oldCategories),
    [newCategories, oldCategories]
  );

  const categoriesToDelete = useMemo(
    () => getCategoryIdsDiff(oldCategories, newCategories),
    [newCategories, oldCategories]
  );

  const categoriesWereModified = useMemo(
    () => categoriesToChange.length > 0 || categoriesToDelete.length > 0,
    [categoriesToChange, categoriesToDelete]
  );

  return {
    categoriesToChange,
    categoriesToDelete,
    categoriesWereModified,
  };
}
