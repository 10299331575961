import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function CategoryTabStep2() {
    return (
        <StepContentDiv>
            <Paragraph>
                The Category section is used to display selected category publishers with their roles and category levels on which access has been granted.
            </Paragraph>
        </StepContentDiv>
    );
}
