import { createApi } from "@reduxjs/toolkit/query/react";
import { CategoryTag } from "../models/categoryTag";
import { Person } from "../models/person";
import Tag from "../models/tags/tag";
import TagsListItem from "../models/tags/tagsListItem";
import { TagValue } from "../models/tags/TagValue";
import { publishApiBaseQuery } from "./baseQueries";
import { TagCategoryPath } from "../models/tags/tagCategoryPath";

interface TagValuesRequest {
  tagId: string;
  searchQuery: string;
}

interface TagValuesChangeRequest {
  addedValues: string[];
  removedValues: string[];
  tagId: string;
}

export interface TagValueResponse {
  count: number;
  values: TagValue[];
}

export const tagsApi = createApi({
  reducerPath: "tagsApi",
  baseQuery: publishApiBaseQuery,
  endpoints: (builder) => ({
    getTagsList: builder.query<Tag[], string[]>({
      query: (tagNames: string[]) => ({
        url: `Tags`,
        method: "POST",
        body: tagNames,
      }),
      keepUnusedDataFor: 3600,
    }),
    getTagsByCategoryId: builder.query<CategoryTag[], string>({
      query: (categoryId: string) => ({
        url: `Tags/${categoryId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 3600,
    }),
    getUserTagsList: builder.query<TagsListItem[], null>({
      query: () => ({
        url: `Tags`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    getTagOwners: builder.query<Person[], string>({
      query: (tagId: string) => ({
        url: `Tags/${tagId}/Owners`,
        method: "GET",
      }),
      keepUnusedDataFor: 3600,
    }),
    getTagValues: builder.query<TagValueResponse, TagValuesRequest>({
      query: (request: TagValuesRequest) => ({
        url: `Tags/${request.tagId}/Values?searchQuery=${encodeURIComponent(request.searchQuery)}`,
        method: "GET",
      }),
      keepUnusedDataFor: 3600,
    }),
    setTagValues: builder.mutation<null, TagValuesChangeRequest>({
      query: (request: TagValuesChangeRequest) => ({
        url: `Tags/${request.tagId}/Values`,
        method: "POST",
        body: request,
      }),
    }),
    getTagCategories: builder.query<TagCategoryPath[], string>({
      query: (tagId: string) => ({
        url: `Tags/${tagId}/Categories`,
        method: "GET",
      }),
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const {
  useGetTagsListQuery,
  useLazyGetTagsListQuery,
  useGetTagsByCategoryIdQuery,
  useLazyGetTagsByCategoryIdQuery,
  useGetTagOwnersQuery,
  useLazyGetTagOwnersQuery,
  useGetTagValuesQuery,
  useLazyGetTagValuesQuery,
  useGetUserTagsListQuery,
  useLazyGetUserTagsListQuery,
  useSetTagValuesMutation,
  useLazyGetTagCategoriesQuery,
} = tagsApi;

export default tagsApi.reducer;
