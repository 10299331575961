import { Skeleton, Switch } from "@mui/material";
import { LabelSpan } from "../../SC/LabelSpan";
import { IsSynchronizedDiv } from "./SC/IsSynchronizedDiv";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import {
  selectCurrentDocumentCma,
  setEditedDocumentCmaSynchronization,
} from "../../../../app/slices/documentMetadataSlice";
import {
  selectIsLoadingDetailsMetaData,
  setShouldRefreshDocumentCma,
} from "../../../../app/slices/documentDetailsSlice";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import LabelIcon from "../../inputs/LabelIcon";

interface CmaSyncSwitchProps {
  isEditable: boolean;
  notEditableMessage?: TooltipMsg;
}

export function CmaSyncSwitch(props: CmaSyncSwitchProps) {
  const dispatch = useAppDispatch();
  const currentDocumentCma = useAppSelector(selectCurrentDocumentCma);
  const isLoadingDetailsMetaData = useAppSelector(
    selectIsLoadingDetailsMetaData
  );

  const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEditedDocumentCmaSynchronization(event.target.checked));
    if (event.target.checked) dispatch(setShouldRefreshDocumentCma(true));
  };

  if (isLoadingDetailsMetaData) {
    return <Skeleton variant="text" height={38} />;
  }
  return (
    <IsSynchronizedDiv id="is-synchronized-with-cma-switch-div">
      <Switch
        id={"is-synchronized-with-cma-switch"}
        checked={currentDocumentCma.isSynchronizedWithCma}
        onChange={onSwitchChange}
        disabled={!props.isEditable}
      />
      <LabelSpan>Synchronize with ABB Products</LabelSpan>
      {props.notEditableMessage && !props.isEditable && (
        <LabelIcon message={props.notEditableMessage} />
      )}
    </IsSynchronizedDiv>
  );
}
