import { useCallback, useEffect, useMemo } from "react";
import { useLazyGetTagsListQuery } from "../../../../apis/tagsApi";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { selectIsLoadingDetailsMetaData } from "../../../../app/slices/documentDetailsSlice";
import { openSnackbar } from "../../../../app/helpers/snackBarHelper";
import { SnackbarType } from "../../../../models/snackbar";
import { SnackbarMsg } from "../../../../controls/Snackbar/SnackbarMessages";

export function useFetchTagsList() {
  const dispatch = useAppDispatch();
  const isLoadingDetailsMetaData = useAppSelector(
    selectIsLoadingDetailsMetaData
  );

  const [getTagsList, { data: tagsList, isError, isFetching, isLoading }] =
    useLazyGetTagsListQuery();

  const isLoadingTagsList = useMemo(() => {
    return isLoadingDetailsMetaData || isLoading || isFetching;
  }, [isLoadingDetailsMetaData, isLoading, isFetching]);

  const fetchTagsList = useCallback(
    (tags: string[] | undefined) => {
      if (tags) {
        void getTagsList(tags, false);
      }
    },
    [getTagsList]
  );

  useEffect(() => {
    if (isError) {
      openSnackbar(
        dispatch,
        SnackbarMsg.CategoryTagsError,
        SnackbarType.error
      );
    }
  }, [isError, dispatch]);

  return {
    fetchTagsList,
    tagsList,
    isLoadingTagsList,
  };
}
