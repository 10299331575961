import styled, { DefaultTheme } from "styled-components";
import { linkedDocumentIcon } from "../../../../icons/DocumentInfoIcons";

interface StyledLinkLabelIconProps {
  $marginBottom?: number;
  $marginLeft?: number;
  size?: "medium" | "large";
}

function getFontSize(theme: DefaultTheme, size?: "medium" | "large") {
  switch (size) {
    case "large": {
      return theme.typography.fontSizes.large;
    }
    case "medium": {
      return theme.typography.fontSizes.medium;
    }
    default: {
      return theme.typography.fontSizes.extraSmallIcon;
    }
  }
}

export const StyledLinkLabelIcon = styled(
  linkedDocumentIcon
)<StyledLinkLabelIconProps>`
  &.MuiSvgIcon-root {
    font-size: ${(props) => getFontSize(props.theme, props.size)}px;
    color: ${(props) => props.theme.palettes.grayscale.darkest};
    margin-bottom: ${(props) => props.$marginBottom ?? 0}px;
    margin-left: ${(props) => props.$marginLeft ?? 0}px;
  }
`;
