import { createStep } from "../../helpers/selfHelpBuilder";
import { DocumentNumberAndTitlesStep } from "./Filters/DocumentNumberAndTitlesStep";
import { MultipleFiltersStep } from "./Filters/MultipleFiltersStep";
import { PublicationDateStep } from "./Filters/PublicationDateStep";
import { ResetFiltersStep } from "./Filters/ResetFiltersStep";
import { SectionStep } from "./Filters/SectionStep";
import { SecurityLevelStep } from "./Filters/SecurityLevelStep";
import { WorkflowStep } from "./Filters/WorkflowStep";

export const filtersSteps = () => {
  return [
    createStep("#filters-navigation-section", <SectionStep />, 420, "right"),
    createStep(
      "#doc-number-title-filters",
      <DocumentNumberAndTitlesStep />,
      420,
      "right"
    ),
    createStep("#multiple-filters", <MultipleFiltersStep />, 420, "right"),
    createStep("#workflow-filter", <WorkflowStep />, 420, "right"),
    createStep("#publication-date-filter", <PublicationDateStep />, 420, "top"),
    createStep("#security-level-filter", <SecurityLevelStep />, 420, "top"),
    createStep("#reset-filters-button", <ResetFiltersStep />, 380, "right"),
  ];
};
