import { useLazyGetDocumentsListItemsQuery } from "../../../../apis/documentsListApi";
import { openSnackbar } from "../../../../app/helpers/snackBarHelper";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import {
  selectChangingWorkflowCounter,
  setIsDocumentsListFetching,
} from "../../../../app/slices/documentListSlice";
import { SnackbarType } from "../../../../models/snackbar";
import DocumentListItem from "../../../../models/documentList/documentListItem";
import { DocumentIdentity } from "../../../../models/documentList/documentListSearchCriteria";
import { Workflow } from "../../../../models/documentList/types/workflow";
import { SnackbarMsg } from "../../../../controls/Snackbar/SnackbarMessages";

export function useChangeState() {
  const dispatch = useAppDispatch();
  const [getDocumentListItems, { isFetching: isRefreshDocumentListFetching }] =
    useLazyGetDocumentsListItemsQuery();
  const changingWorkflowCounter = useAppSelector(selectChangingWorkflowCounter);

  const refreshList = (
    documentIdentity: DocumentIdentity,
    documents: DocumentListItem[],
    setState: (newItems: DocumentListItem[]) => void,
    isGroup: boolean,
    setDocumentCount?: React.Dispatch<React.SetStateAction<number>>
  ) => {
    getDocumentListItems({
      documentLanguageCodes: documentIdentity.documentLanguageCodes,
      documentPart: documentIdentity.documentPart,
      documentNumber: documentIdentity.documentNumber,
    })
      .unwrap()
      .then((reponseItems) => {
        const newDocumentList: DocumentListItem[] = [];
        const documentCount = documents.length;

        documents.forEach((documentItem) => {
          const filtered = existsInResponse(reponseItems, documentItem);

          if (isDraft(documentItem) && isGroup) {
            addDraftIfExistsInReponse(filtered, documentItem, newDocumentList);
          } else {
            addNewDocumentItem(filtered, documentItem, newDocumentList);
          }
        });

        setState(newDocumentList);

        const difference = documentCount - newDocumentList.length;
        if (setDocumentCount)
          setDocumentCount((prevValue) => prevValue - difference);

        if (changingWorkflowCounter === 0 || changingWorkflowCounter === 1) {
          dispatch(setIsDocumentsListFetching(false));
        }
      })
      .catch(() => {
        openSnackbar(
          dispatch,
          SnackbarMsg.DocumentListError,
          SnackbarType.error
        );
      });
  };

  const existsInResponse = (
    reponse: DocumentListItem[],
    documentItem: DocumentListItem
  ) =>
    reponse.filter(
      (responseDocument) =>
        responseDocument.documentNumber == documentItem.documentNumber &&
        responseDocument.part === documentItem.part &&
        responseDocument.languageCodes.toString() ===
          documentItem.languageCodes.toString() &&
        responseDocument.revision === documentItem.revision
    );

  const isDraft = (documentItem: DocumentListItem) =>
    documentItem.workflow === Workflow.Draft ||
    documentItem.workflow === Workflow.Approval;

  const addDraftIfExistsInReponse = (
    filtered: DocumentListItem[],
    documentItem: DocumentListItem,
    returnedItems: DocumentListItem[]
  ) => {
    if (filtered.length > 0) {
      const draft = filtered.filter(
        (response) => documentItem.workflow === response.workflow
      );
      if (draft.length > 0) {
        returnedItems.push(
          draft.first((response) => documentItem.workflow === response.workflow)
        );
      }
    }
  };

  const addNewDocumentItem = (
    filtered: DocumentListItem[],
    documentItem: DocumentListItem,
    returnedItems: DocumentListItem[]
  ) => {
    if (filtered.length > 0) {
      returnedItems.push(filtered[0]);
    } else {
      returnedItems.push(documentItem);
    }
  };

  return { refreshList, isRefreshDocumentListFetching };
}
