import { useCallback } from "react";
import useDocumentValidationRules from "../../../../app/hooks/document/useDocumentValidationRules";
import { ValidationStatus } from "../../../../models/validationRule";
import { MultiText } from "../../inputs/MultiText";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";

interface TitleNameControlProps {
  labelText: string;
  inputValue: string;
  id: string;
  isEdited: boolean;
  onChangeHandler: (newValue: string) => void;
  isEditable: boolean;
  notEditableMessage?: TooltipMsg;
  onInputValidation: (status: ValidationStatus, isoCode: string) => void;
}

export function TitleNameControl(props: TitleNameControlProps) {
  const { getTitleNameValidationRules } = useDocumentValidationRules();
  const onValidateInput = props.onInputValidation;

  const onInputValidation = useCallback(
    (result: ValidationStatus) => {
      onValidateInput(result, props.id);
    },
    [props.id, onValidateInput]
  );

  return (
    <MultiText
      key={props.id}
      notEditableMessage={props.notEditableMessage}
      isEditable={props.isEditable}
      onInputValidation={onInputValidation}
      validationRules={getTitleNameValidationRules()}
      id={props.id}
      labelText={props.labelText}
      inputValue={props.inputValue}
      onChangeHandler={props.onChangeHandler}
      isEdited={props.isEdited}
    />
  );
}
