import { useCallback, useEffect } from "react";
import {
  useLazyGetMatchingOwnerCategoriesQuery,
  useLazyGetOwnerCategoriesByCategoryIdQuery,
  useLazyGetOwnerCategoriesQuery,
} from "../../../../../apis/publisherCategoriesApi";
import { openSnackbar } from "../../../../../app/helpers/snackBarHelper";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import {
  clearOwnerCategories,
  selectSelectedNode,
  updateOwnerCategories,
} from "../../../../../app/slices/ownerCategoryTreeSlice";
import {
  selectSelectedBreadcrumbCategory,
  setSelectedBreadcrumbCategory,
} from "../../../../../app/slices/publishersListSlice";
import { TextFieldInput } from "../../../../../controls/TextField/TextFieldInput";
import { SnackbarType } from "../../../../../models/snackbar";
import { CategorySearchDiv } from "./SC/CategorySearchDiv";
import { SnackbarMsg } from "../../../../../controls/Snackbar/SnackbarMessages";

interface CategorySearchProps {
  minQueryLength: number;
  isMinimized: boolean;
}

export function CategorySearch({
  minQueryLength,
  isMinimized,
}: CategorySearchProps) {
  const dispatch = useAppDispatch();
  const categoryId = useAppSelector(selectSelectedNode);
  const breadcrumbCategoryId = useAppSelector(selectSelectedBreadcrumbCategory);
  const [
    getSearchOwnerCategories,
    {
      data: searchedData,
      isSuccess: isSearchedSuccess,
      isFetching: searchedDataFeting,
    },
  ] = useLazyGetMatchingOwnerCategoriesQuery();

  const [getOwnerCategories, { data, isSuccess, isFetching }] =
    useLazyGetOwnerCategoriesQuery();

  const [
    getOwnerCategoriesById,
    {
      data: ownerCategoriesByIdData,
      isSuccess: isSuccessOwnerCategoriesById,
      isFetching: isFetchingOwnerCategoriesById,
      isError: isErrorOwnerCategoriesById,
    },
  ] = useLazyGetOwnerCategoriesByCategoryIdQuery();

  const onCategorySearch = useCallback(
    (search: string) => {
      const trimmedSearch =
        search.length === 0
          ? search
          : search
            .split(" ")
            .filter((x) => x.length !== 0)
            .map((x) => x.trim().toLocaleLowerCase())
            .join(" ");

      if (trimmedSearch.length >= minQueryLength || search.length !== 0) {
        void getSearchOwnerCategories({ query: trimmedSearch });
      } else {
        if (categoryId) {
          void getOwnerCategoriesById(categoryId);
        } else {
          void getOwnerCategories({
            maxDepth: 1,
            parentCategoryId: "Root",
            includeHighestParent: true,
            maxCategoryLevelShow: 0,
          });
        }
      }
    },
    [
      getOwnerCategories,
      getSearchOwnerCategories,
      getOwnerCategoriesById,
      minQueryLength,
      categoryId,
    ]
  );

  useEffect(() => {
    if (breadcrumbCategoryId !== "") {
      void getOwnerCategoriesById(breadcrumbCategoryId);
    }
  }, [breadcrumbCategoryId, getOwnerCategoriesById]);

  useEffect(() => {
    if (data && isSuccess) {
      dispatch(clearOwnerCategories(true));
      dispatch(updateOwnerCategories(data));
    }
  }, [dispatch, data, isSuccess, isFetching]);

  useEffect(() => {
    if (searchedData && isSearchedSuccess) {
      dispatch(clearOwnerCategories(true));
      dispatch(updateOwnerCategories(searchedData));
    }
  }, [dispatch, searchedData, searchedDataFeting, isSearchedSuccess]);

  useEffect(() => {
    if (ownerCategoriesByIdData && isSuccessOwnerCategoriesById) {
      dispatch(clearOwnerCategories(true));
      dispatch(updateOwnerCategories(ownerCategoriesByIdData));
      dispatch(setSelectedBreadcrumbCategory(""));
    }
  }, [
    dispatch,
    ownerCategoriesByIdData,
    isFetchingOwnerCategoriesById,
    isSuccessOwnerCategoriesById,
  ]);

  useEffect(() => {
    if (isErrorOwnerCategoriesById) {
      openSnackbar(
        dispatch,
        SnackbarMsg.OwnerCategoriesError,
        SnackbarType.error
      );
      dispatch(setSelectedBreadcrumbCategory(""));
    }
  });

  if (isMinimized) {
    return <></>;
  }

  return (
    <CategorySearchDiv id="category-search-input-div">
      <TextFieldInput
        id="category-search-textfield"
        label=""
        placeholder="Type category name/CID and press enter to search (min. 3 chars)"
        onClick={onCategorySearch}
        initialValue={""}
        trimEntries={false}
      />
    </CategorySearchDiv>
  );
}
