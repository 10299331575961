import { AccordionDetails, AccordionDetailsProps } from "@mui/material";
import styled from "styled-components";

export const SectionAccordionDetails = styled(
  (props: AccordionDetailsProps) => <AccordionDetails {...props} />
)`
  &.MuiAccordionDetails-root {
    padding-bottom: 8px;
  }
`;
