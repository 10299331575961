import { useEffect, useState } from "react";
import { FormHelperControl } from "../../../../controls/FormHelper/FormHelperControl";
import { InputDiv } from "../../../../controls/StyledComponents/InputDiv";
import { StyledTextField } from "../../../../controls/TextField/SC/StyledTextField";
import {
  ValidationRule,
  ValidationStatus,
} from "../../../../models/validationRule";
import { TextFieldInputAdornment } from "./TextFieldInputAdornment";
import { debounce } from "lodash";

interface TextFieldInputProps {
  id: string;
  initialValue?: string;
  isEditable: boolean;
  isInEditMode: boolean;
  isWarning?: boolean;
  wrapText?: boolean;
  validationRules?: ValidationRule[];
  multiline?: boolean;
  isEdited?: boolean;
  onChange?: (newValue: string) => void;
  onInputValidation?: (result: ValidationStatus) => void;
  inputAdornment?: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
}

export function TextFieldInput(props: TextFieldInputProps) {
  const debounceTime = 1000;
  const [inputValue, setInputValue] = useState(props.initialValue);
  const [isValidationError, setIsValidationError] = useState(false);
  const [isValidationWarning, setIsValidationWarning] = useState(false);
  const [isNotVisibleError, setIsNotVisibleError] = useState(false);
  const [helperText, setHelperText] = useState<string[] | React.ReactNode[]>(
    []
  );
  const [warningText, setWarningText] = useState<React.ReactNode[]>([]);
  const [isWarningValidateEnable, setIsWarningValidateEnable] = useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (props.onChange) props.onChange(event.target.value);
    debounceValidateEnableFunc();
  };

  const onInputValidation = props.onInputValidation;

  const debounceValidateEnableFunc = debounce(function () {
    setIsWarningValidateEnable(true);
  }, debounceTime);

  const testResult = (inputValue: string, rule: ValidationRule) =>
    rule.successIsInvalid
      ? rule.regExp.test(inputValue)
      : !rule.regExp.test(inputValue);

  useEffect(() => {
    if (
      isWarningValidateEnable &&
      props.isEditable &&
      props.validationRules &&
      props.validationRules.length > 0
    ) {
      const texts: React.ReactNode[] = [];

      props.validationRules
        .filter((v) => v.validationType === ValidationStatus.warning)
        .forEach((rule) => {
          if (inputValue !== undefined && !rule.regExp.test(inputValue)) {
            texts.push(rule.message);
          }
        });
      setIsValidationWarning(texts.length > 0);
      setWarningText(texts);
    } else {
      setIsValidationWarning(false);
      setWarningText([]);
    }
  }, [
    inputValue,
    props.isEditable,
    props.validationRules,
    isWarningValidateEnable,
  ]);

  useEffect(() => {
    if (onInputValidation) {
      if (isValidationError) {
        onInputValidation(ValidationStatus.error);
      } else if (isNotVisibleError) {
        onInputValidation(ValidationStatus.notVisibleError);
      } else {
        onInputValidation(ValidationStatus.success);
      }
    }
  }, [onInputValidation, isValidationError, isNotVisibleError]);

  useEffect(() => {
    setInputValue(props.initialValue);
  }, [props.initialValue]);

  useEffect(() => {
    let isError = false;
    let isNotVisibleError = false;

    if (
      props.isEditable &&
      props.validationRules &&
      props.validationRules.length > 0
    ) {
      const texts: React.ReactNode[] = [];

      props.validationRules
        .filter((v) => v.validationType === ValidationStatus.error)
        .forEach((rule) => {
          if (inputValue !== undefined && testResult(inputValue, rule)) {
            isNotVisibleError ||= rule.isVisible === false;
            isError = !!rule.isVisible || rule.isVisible === undefined;

            if (isError && !(rule.omitText && texts.includes(rule.omitText))) {
              texts.push(rule.message);
            }
          }
        });
      setIsValidationError(texts.length > 0);
      setIsNotVisibleError(isNotVisibleError);
      setHelperText(texts);
    }
  }, [inputValue, props.isEditable, props.validationRules]);

  return (
    <InputDiv>
      <StyledTextField
        id={props.id}
        disabled={props.disabled}
        size="small"
        error={isValidationError}
        variant="outlined"
        value={inputValue}
        $wrapText={props.wrapText}
        multiline={props.multiline}
        onChange={handleChange}
        $isEditable={props.isInEditMode}
        $isEdited={props.isEdited}
        placeholder={props.placeholder}
        InputProps={{
          endAdornment: (
            <TextFieldInputAdornment
              id={props.id}
              inputAdornment={props.inputAdornment}
              isEdited={props.isEdited}
              isError={isValidationError}
              isWarning={props.isWarning}
            />
          ),
        }}
      />
      <FormHelperControl
        isWarning={false}
        isError={isValidationError}
        texts={[...helperText]}
      />
      <FormHelperControl
        isWarning={isValidationWarning}
        isError={false}
        texts={[...warningText]}
      />
    </InputDiv>
  );
}
