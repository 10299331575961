import styled from "styled-components";
import { ReflexElement } from "../../../../controls/Reflex";

export interface DetailsTabReflexElement {
  isHidden?: boolean;
  isResizing: boolean;
}

export const DetailsTabReflexElement = styled(
  ReflexElement
)<DetailsTabReflexElement>`
  margin-left: ${(props) => (props.isHidden ? "0" : "6px")};
  overflow: ${(props) => (props.isResizing ? "hidden" : "auto")} !important;
`;
