import UnpublishedIcon from "@mui/icons-material/Unpublished";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components";
import { useDiscardDraft } from "../../../../apis/hooks/useDiscardDraft";
import { getNonEmptyDocumentIdentity } from "../../../../app/helpers/documentIdentityHelper";
import { useDocumentFlow } from "../../../../app/hooks/document/useDocumentFlow";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { selectIsUpdatingDocument } from "../../../../app/slices/documentDetailsSlice";
import {
  selectCurrentDocumentMetadata,
  selectDocumentIdentity,
  selectDocumentWorkflow,
} from "../../../../app/slices/documentMetadataSlice";
import {
  selectIsAttachmentUploadInProgress,
  selectIsFileUploading,
} from "../../../../app/slices/fileUploadSlice";
import {
  ActionButton,
  addVisibleButton,
  removeVisibleButton,
  selectDetailsHasRequiredUncompletedSections,
} from "../../../../app/slices/selfHelpSlice";
import { TextButtonControl } from "../../../../controls/Buttons/TextButtonControl";
import { SnackbarMsg } from "../../../../controls/Snackbar/SnackbarMessages";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import { Identity } from "../../../../models/documentDetails/documentMetadata";
import { ButtonDiv } from "../SC/ButtonDiv";
import { Workflow } from "../../../../models/documentList/types/workflow";

interface DiscardDraftButtonProps {
  disabled?: boolean;
  disabledTooltipText?: string;
  isCompactView?: boolean;
  isRejectApprovalButton?: boolean;
}

export function DiscardDraftButton(props: DiscardDraftButtonProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { discardDocumentDraft } = useDiscardDraft(
    props.isRejectApprovalButton
      ? {
          successMessage: SnackbarMsg.DocumentApprovalDeleteSuccess,
          errorMessage: SnackbarMsg.DocumentApprovalDeleteError,
        }
      : {
          successMessage: SnackbarMsg.DocumentDraftDeleteSuccess,
          errorMessage: SnackbarMsg.DocumentDraftDeleteError,
        }
  );

  const [documentIdentity, setDocumentIdentity] = useState<Identity | null>(
    null
  );
  const { isNewDocument } = useDocumentFlow();
  const lastDocumentIdentity = useAppSelector(selectDocumentIdentity);
  const documentMetadata = useAppSelector(selectCurrentDocumentMetadata);
  const isFileUploading = useAppSelector(selectIsFileUploading);
  const isAttachmentUploadInProgress = useAppSelector(
    selectIsAttachmentUploadInProgress
  );
  const isUpdatingDocument = useAppSelector(selectIsUpdatingDocument);
  const workflow = useAppSelector(selectDocumentWorkflow);
  const isUploadInProgress = useMemo(() => {
    return (
      isFileUploading ||
      (isAttachmentUploadInProgress && workflow !== Workflow.Draft)
    );
  }, [isAttachmentUploadInProgress, isFileUploading, workflow]);
  const hasUncompletedSections = useAppSelector(
    selectDetailsHasRequiredUncompletedSections
  );

  const discardDraft = useCallback(() => {
    if (documentIdentity) {
      const identity = getNonEmptyDocumentIdentity(documentIdentity);
      void discardDocumentDraft(identity);
    }
  }, [discardDocumentDraft, documentIdentity]);

  useEffect(() => {
    if (isNewDocument) {
      setDocumentIdentity(documentMetadata.documentIdentity);
    } else {
      setDocumentIdentity(lastDocumentIdentity);
    }
  }, [lastDocumentIdentity, isNewDocument, documentMetadata.documentIdentity]);

  useEffect(() => {
    dispatch(addVisibleButton(ActionButton.DiscardDraft));

    return () => {
      dispatch(removeVisibleButton(ActionButton.DiscardDraft));
    };
  }, [dispatch]);

  return (
    <ButtonDiv className="document-details-discard-draft-button">
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.primaryButtonHeight}
        id={
          props.isRejectApprovalButton
            ? "document-details-reject-button"
            : "document-details-discard-draft-button"
        }
        text={props.isRejectApprovalButton ? "Reject" : "Discard Draft"}
        disabled={
          props.disabled ||
          isUploadInProgress ||
          isUpdatingDocument ||
          hasUncompletedSections
        }
        disabledTooltipText={
          hasUncompletedSections
            ? TooltipMsg.UncompletedSelfHelpSections
            : props.disabled
              ? props.disabledTooltipText
              : undefined
        }
        onClick={(event) => {
          discardDraft();
          event.stopPropagation();
        }}
        isCompactView={props.isCompactView == true}
        isVertical={false}
        isVisible={true}
        icon={<UnpublishedIcon fontSize="small" />}
      />
    </ButtonDiv>
  );
}
