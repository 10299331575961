import React from "react";
import { useTheme } from "styled-components";

interface SelfHelpIconProps {
    icon: React.ReactNode;
    isBlack?: boolean;
}

export function SelfHelpIcon(props: SelfHelpIconProps) {
    const theme = useTheme();
    return (
        <div style={{
            display: "inline-block",
            fontSize: "16px",
            verticalAlign: "middle",
            color: props.isBlack ? theme.palettes.grayscale.darkest : theme.palettes.grayscale.dark
        }}>{props.icon}</div>

    );
}
