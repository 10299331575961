import styled, { DefaultTheme } from "styled-components";
import { Density } from "../../../../../app/slices/models/documentsModels";
import { getMaxHeight, getMinHeight } from "../../helpers/densityHelper";

interface DocumentsListDivProps {
  density: Density;
  $isGray: boolean;
  $zebraStripe: boolean;
  insideGroup?: boolean;
}

function getColor(isGray: boolean, theme: DefaultTheme) {
  if (isGray) {
    return theme.palettes.grayscale.medium;
  }
  return theme.palettes.grayscale.darkest;
}

function getBackgroundColor(theme: DefaultTheme, zebraStripe: boolean) {
  if (zebraStripe) {
    return theme.palettes.grayscale.white;
  }

  return theme.palettes.grayscale.lightest;
}

function getFontSize(density: Density, theme: DefaultTheme) {
  if (density === Density.Comfort) {
    return theme.typography.fontSizes.large;
  } else {
    return theme.typography.fontSizes.medium;
  }
}

function getBorder(insideGroup: boolean | undefined, theme: DefaultTheme) {
  if (insideGroup) {
    return "none";
  }
  return "1px solid " + theme.palettes.grayscale.dark;
}

export const DocumentsListItemDiv = styled.div<DocumentsListDivProps>`
  z-index: 1;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  min-height: ${(props) => getMinHeight(props.density, props.theme)};
  max-height: ${(props) => getMaxHeight(props.density, props.theme)};
  font-size: ${(props) => getFontSize(props.density, props.theme)}px;
  color: ${(props) => getColor(props.$isGray, props.theme)};
  background-color: ${(props) =>
    getBackgroundColor(props.theme, props.$zebraStripe)};
  border-color: ${(props) => props.theme.palettes.grayscale.white};
  border-bottom: ${(props) => getBorder(props.insideGroup, props.theme)};
  &: hover {
    background-color: ${(props) => props.theme.palettes.grayscale.lighter};
  }
`;
