import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { TextButtonControl } from "../../../../../controls/Buttons/TextButtonControl";
import { ButtonDiv } from "../../SC/ButtonDiv";
import { HardDeleteConfirmationDialog } from "./HardDeleteConfirmationDialog";
import { useHardDelete } from "./useHardDelete";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { selectDocumentIdentity } from "../../../../../app/slices/documentMetadataSlice";
import { getNonEmptyDocumentIdentity } from "../../../../../app/helpers/documentIdentityHelper";
import {
  addVisibleButton,
  ActionButton,
  removeVisibleButton,
  selectDetailsHasRequiredUncompletedSections,
} from "../../../../../app/slices/selfHelpSlice";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";

interface HardDeleteButtonProps {
  disabled?: boolean;
  disabledTooltipText?: string;
  isCompactView?: boolean;
}

export function HardDeleteButton(props: HardDeleteButtonProps) {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const { hardDeleteDocument, isHardDeleting } = useHardDelete();
  const documentIdentity = useAppSelector(selectDocumentIdentity);
  const hasUncompletedSections = useAppSelector(
    selectDetailsHasRequiredUncompletedSections
  );

  const onClose = () => {
    setIsOpen(false);
  };

  const onConfirm = () => {
    const notEmptyIdentity = getNonEmptyDocumentIdentity(documentIdentity);
    void hardDeleteDocument(notEmptyIdentity);
    setIsOpen(false);
  };

  useEffect(() => {
    dispatch(addVisibleButton(ActionButton.HardDelete));

    return () => {
      dispatch(removeVisibleButton(ActionButton.HardDelete));
    };
  }, [dispatch]);

  return (
    <ButtonDiv>
      <HardDeleteConfirmationDialog
        id={"document-details-hard-delete-button-dialog"}
        onCloseClick={onClose}
        onYesClick={onConfirm}
        onNoClick={onClose}
        isOpen={isOpen}
      />
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.primaryButtonHeight}
        id={"document-details-hard-delete-button"}
        text={"Hard Delete"}
        tooltipText={TooltipMsg.DeletePermanently}
        disabledTooltipText={
          hasUncompletedSections
            ? TooltipMsg.UncompletedSelfHelpSections
            : props.disabledTooltipText
        }
        disabled={props.disabled || isHardDeleting || hasUncompletedSections}
        onClick={(event) => {
          setIsOpen(true);
          event.stopPropagation();
        }}
        isCompactView={props.isCompactView == true}
        isVertical={false}
        isVisible={true}
        icon={<DeleteForeverIcon fontSize="small" />}
      />
    </ButtonDiv>
  );
}
