import styled, { DefaultTheme } from "styled-components";

interface TitleSpanParams {
  $isGray: boolean;
  width?: string;
}

function getColor(isGray: boolean, theme: DefaultTheme) {
  if (isGray) {
    return theme.palettes.grayscale.medium;
  }
  return theme.palettes.grayscale.darkest;
}

function getWeight(isGray: boolean) {
  if (isGray) {
    return "unset";
  }

  return "bold";
}

export const TitleSpan = styled.span<TitleSpanParams>`
  font-weight: ${(param) => getWeight(param.$isGray)};
  font-size: ${(props) => props.theme.typography.fontSizes.headerT1}px;
  display: flex;
  align-items: center;
  color: ${(param) => getColor(param.$isGray, param.theme)};
  width: ${(param) => (param.width ? param.width : "100%")};
`;
