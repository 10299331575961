import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The Navigation section enables the filtering of documents using
        category-related filters.
      </Paragraph>
      <Paragraph>
        The categories originate from ABB Products and are utilized not only in
        ABB Library but also in other applications.
      </Paragraph>
    </StepContentDiv>
  );
}
