import { DialogTitle } from "@mui/material";
import styled from "styled-components";

export const StyledDialogTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    font-weight: bold;
    font-size: ${(props) => props.theme.typography.fontSizes.headerT2}px;
    padding-bottom: 10px;
  }
`;
