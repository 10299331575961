import CancelIcon from "@mui/icons-material/Cancel";
import { SyntheticEvent, useRef } from "react";
import { EllipsisText } from "../../components/documents/documentsList/documentListItem/cells/EllipsisText";
import { ComboBoxListItem } from "../../models/autocompleteValue";
import { WordBreakType } from "../../models/documentList/types/WordBreakType";
import { StyledChip } from "../StyledComponents/StyledChip";

interface AutocompleteChipProps {
  label?: string;
  pillsClassName: string;
  isEditable: boolean;
  isDisabled?: boolean;
  item: ComboBoxListItem;
  isError?: boolean;
  handleDelete: (e: React.MouseEvent, keyValue: ComboBoxListItem) => void;
  handleChange: (event: SyntheticEvent, value: unknown) => void;
}

export function AutocompleteChip(props: AutocompleteChipProps) {
  const itemMissingWarningText = "This value is not present on the list";
  const itemDataQualityWarningText = "This value has data quality issue";
  const chipRef = useRef<HTMLDivElement>(null);
  const additionalTextLines = [];

  if (props.item.hasDataQualityWarning) {
    additionalTextLines.push(itemDataQualityWarningText);
  }

  if (props.item.hasMissingValueWarning) {
    additionalTextLines.push(itemMissingWarningText);
  }

  return (
    <StyledChip
      id={props.label + "-" + props.item.key + "-" + props.item.value}
      ref={chipRef}
      className={props.pillsClassName}
      size="small"
      $isEdited={props.item.isEdited}
      $isWarning={
        props.item.hasMissingValueWarning ||
        props.item.hasDataQualityWarning ||
        props.item.isWarning
      }
      $isError={props.isError}
      $isDisabled={props.isDisabled}
      label={
        <EllipsisText
          width={chipRef.current ? chipRef.current.clientWidth : 0}
          useTooltip={false}
          fillIfEmpty={false}
          content={props.item.value}
          wordBreak={WordBreakType.BreakAll}
          additionalTextLines={additionalTextLines}
        />
      }
      deleteIcon={<CancelIcon fontSize="inherit" />}
      onDelete={
        props.isEditable
          ? (e: React.MouseEvent) => props.handleDelete(e, props.item)
          : undefined
      }
      onChange={(event: SyntheticEvent) =>
        props.handleChange(event, props.item)
      }
      onMouseDown={(event) => event.stopPropagation()}
      onFocus={(event) => event.stopPropagation()}
    />
  );
}
