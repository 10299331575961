import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function AddCategoriesStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                The &quot;Add categories&quot; button presents a tree view that allows users to select categories to be added to document. This can be done by clicking on the desired categories and saving the changes. More information about categories can be found in the Help section after clicking the &quot;Add Categories&quot; button.
            </Paragraph>
            <Paragraph>
                It is important to note that this button is always active for published documents, even if the user does not have editing access to the document. This is because publishers should always add their own category to an existing document instead of creating a duplicate.
            </Paragraph>
        </StepContentDiv>
    );
}
