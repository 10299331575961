import { GridFilterPanel } from "@mui/x-data-grid";
import styled from "styled-components";

export const StyledGridFilterPanel = styled(GridFilterPanel)`
  .MuiDataGrid-panelContent {
    label {
      color: ${(props) => props.theme.palettes.grayscale.dark};

      &.Mui-focused {
        color: ${(props) => props.theme.palettes.primary.dark};
      }
    }
  }
`;
