import { Step } from "react-joyride";
import { ActionButton } from "../../../../app/slices/selfHelpSlice";
import { createStep } from "../../helpers/selfHelpBuilder";
import { includes } from "lodash";
import { AboutStep } from "./ScheduledAction/AboutStep";
import { ActionStep } from "./ScheduledAction/ActionStep";
import { CreatedByStep } from "./ScheduledAction/CreatedByStep";
import { EmailNoteStep } from "./ScheduledAction/EmailNoteStep";
import { AdditionalEmailsStep } from "./ScheduledAction/AdditionalEmailsStep";
import { DateOfActionStep } from "./ScheduledAction/DateOfActionStep";
import { ActionDateStep } from "./ScheduledAction/ActionDateStep";
import { AddButtonStep } from "./ScheduledAction/AddButtonStep";
import { OverwriteStep } from "./ScheduledAction/OverwriteStep";
import { DeleteButtonStep } from "./ScheduledAction/DeleteButtonStep";
import { CloseButtonStep } from "./ScheduledAction/CloseButtonStep";

export const scheduledActionSteps = (actionButtons: ActionButton[]) => {
  const steps: Step[] = [];

  steps.push(
    createStep("#scheduled-action-dialog-content", <AboutStep />, 300, "right")
  );

  steps.push(
    createStep("#scheduled-action-type-dropdown", <ActionStep />, 520, "auto")
  );

  steps.push(
    createStep(
      "#scheduled-action-created-by-dropdown",
      <CreatedByStep />,
      520,
      "auto"
    )
  );

  steps.push(
    createStep("#scheduled-action-email-body", <EmailNoteStep />, 520, "auto")
  );

  steps.push(
    createStep(
      "#scheduled-action-email-recipients-body",
      <AdditionalEmailsStep />,
      520,
      "auto"
    )
  );

  steps.push(
    createStep(
      "#scheduled-action-date-dropdown",
      <DateOfActionStep />,
      520,
      "auto"
    )
  );

  steps.push(
    createStep("#scheduled-action-date-picker", <ActionDateStep />, 520, "auto")
  );

  if (includes(actionButtons, ActionButton.Add)) {
    steps.push(
      createStep("#dialog-add-update-button", <AddButtonStep />, 520, "auto")
    );
  } else {
    steps.push(
      createStep("#dialog-add-update-button", <OverwriteStep />, 520, "auto")
    );
    steps.push(
      createStep("#dialog-delete-button", <DeleteButtonStep />, 520, "auto")
    );
  }

  steps.push(
    createStep("#dialog-cancel-button", <CloseButtonStep />, 520, "auto")
  );

  return steps;
};
