import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import {
  selectCurrentHighLevelWarningThreshold,
  selectSettingsHighLevelWarningIsEdited,
  setCurrentHighLevelWarningThreshold,
  setHighLevelWarningThresholdState,
} from "../../../../../app/slices/publisherCategoriesSettingsSlice";
import { NameSpan } from "../SC/NameSpan";
import { NumberTextField } from "../SC/NumberTextField";
import { TextFieldDiv } from "../SC/TextFieldDiv";
import { HighLevelWarningThresholdDiv } from "./SC/HighLevelWarningThresholdDiv";
import { ValidationStatus } from "../../../../../models/validationRule";

interface HighLevelWarningThresholdProps {
  disabled?: boolean;
}

export function HighLevelWarningThreshold(
  props: HighLevelWarningThresholdProps
) {
  const currentHighLevelWarning = useAppSelector(
    selectCurrentHighLevelWarningThreshold
  );
  const isEdited = useAppSelector(selectSettingsHighLevelWarningIsEdited);
  const [value, setValue] = useState<number>(2);
  const dispatch = useAppDispatch();

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const level = event.target.value as unknown as number;
    setValue(level);

    if (level > 0) {
      dispatch(setCurrentHighLevelWarningThreshold(level));
      dispatch(setHighLevelWarningThresholdState(ValidationStatus.success));
    } else dispatch(setHighLevelWarningThresholdState(ValidationStatus.error));
  };

  const error = value <= 0;
  const errorMessage = error ? "Error" : "";

  useEffect(() => {
    setValue(currentHighLevelWarning);
  }, [currentHighLevelWarning]);

  return (
    <HighLevelWarningThresholdDiv id="cms-category-warning-div">
      <TextFieldDiv>
        <NumberTextField
          label={errorMessage}
          error={error}
          id="outlined-number"
          type="number"
          size="small"
          onChange={onChangeHandler}
          value={value}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={props.disabled}
          $isEdited={isEdited}
        />
      </TextFieldDiv>
      <NameSpan>
        Tree level until which warning about publishing in high level category
        is displayed
      </NameSpan>
    </HighLevelWarningThresholdDiv>
  );
}
