import React from "react";
import { StyledDiscreetButton } from "./SC/StyledDiscreetButton";
import { TooltipTop } from "../Tooltips/ToolipTop";
import { DisabledButtonDiv } from "./SC/DisabledButtonDiv";

interface DiscreetButtonProps {
  id: string;
  text: string;
  disabled?: boolean;
  disabledTooltipText?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function DiscreetButtonControl(props: DiscreetButtonProps) {
  const getButtonContent = (text: string) => {
    if (props.disabledTooltipText && props.disabled) {
      return (
        <TooltipTop disableFocusListener title={props.disabledTooltipText}>
          <DisabledButtonDiv>{text}</DisabledButtonDiv>
        </TooltipTop>
      );
    }

    return <DisabledButtonDiv>{text}</DisabledButtonDiv>;
  };

  return (
    <StyledDiscreetButton
      id={props.id}
      disabled={props.disabled}
      onClick={props.disabled ? undefined : props.onClick}
    >
      {getButtonContent(props.text)}
    </StyledDiscreetButton>
  );
}
