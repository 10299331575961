import { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorDisplay } from "../ErrorDisplay";

const errorMessageTitle = `Something went wrong`;
const errorDescription = `Try refresh the page. 
If this problem persists, please raise an incident with screenshot of this error and ask to assign it to ABB Library`;

interface ErrorFallbackProps {
  children: ReactNode;
}

export const ErrorBoundaryWrapper = ({ children }: ErrorFallbackProps) => {
  return (
    <ErrorBoundary
      fallbackRender={() => (
        <ErrorDisplay
          doCenterHeader={true}
          errorId="global"
          showReloadButton={true}
          errorMessageTitle={errorMessageTitle}
          errorDescription={errorDescription}
        />
      )}>
      {children}
    </ErrorBoundary>
  );
};
