export const routing = {
  details: "/document",
  newDocument: "/document/new",
  newPart: "/document/new/part",
  newLanguage: "/document/new/language",
  newTemplate: "/document/new/template",
  newRevision: "/document/new/revision",
  publishers: "/publishers",
  settings: "/settings",
  tags: "tags",
};
