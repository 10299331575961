import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function ApprovalWorkflowStep1() {
  return (
    <StepContentDiv>
      <Paragraph>
        Enabling this function adds an additional step during the document publishing process. This means that documents will wait for additional review by users with the Approver role before being published.
      </Paragraph>
      <Paragraph>
        If the option is marked in red, it indicates that the approval workflow for the selected category and its subcategories is enabled.
      </Paragraph>
    </StepContentDiv>
  );
}
