import { RoleInCategory } from "../../../../../models/rolesInCategory";
import { StyledToggleButton } from "../../../../publishers/tabs/categoryTab/publishers/SC/StyledToggleButton";
import { StyledToggleButtonGroup } from "../../../../publishers/tabs/categoryTab/publishers/SC/StyledToggleButtonGroup";

interface RolesButtonsProps {
  disabled: boolean;
  value: RoleInCategory;
  isEdited: boolean;
  onChangeHandler: (role: RoleInCategory) => void;
}

export function RolesButtons(props: RolesButtonsProps) {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newRole: RoleInCategory | null
  ) => {
    if (newRole != null) {
      props.onChangeHandler(newRole);
    }
  };

  const isEdited = (role: RoleInCategory) =>
    props.isEdited && props.value === role;

  return (
    <StyledToggleButtonGroup
      id="roles-toggle-button-group"
      value={props.value}
      onChange={handleChange}
      disabled={props.disabled}
      size="small"
      exclusive
      fullWidth
    >
      <StyledToggleButton
        $isEdited={isEdited(RoleInCategory.SuperUser)}
        size="small"
        value={RoleInCategory.SuperUser}
      >
        SUPER USER
      </StyledToggleButton>
      <StyledToggleButton
        $isEdited={isEdited(RoleInCategory.Publisher)}
        size="small"
        value={RoleInCategory.Publisher}
      >
        SUPER USER AND PUBLISHER
      </StyledToggleButton>
    </StyledToggleButtonGroup>
  );
}
