import styled from "styled-components";

interface ShowMoreButtonDivProps {
  isVisible: boolean;
}

function setVisibility(isVisible: boolean) {
  if (isVisible) {
    return "flex";
  }

  return "none";
}

export const ShowMoreButtonDiv = styled.div<ShowMoreButtonDivProps>`
  display: ${(props) => setVisibility(props.isVisible)};
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
`;
