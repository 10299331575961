import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export function NewDocumentStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The{" "}
        <UploadFileIcon
          style={{
            fontSize: "16px",
            verticalAlign: "middle",
          }}
        />{" "}
        &quot;New Document&quot; button opens a template for creating a new
        document.
      </Paragraph>
    </StepContentDiv>
  );
}
