import styled from "styled-components";

interface AttachmentInfoDivProps {
  $paddingTop?: number;
}

export const AttachmentInfoDiv = styled.div<AttachmentInfoDivProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-top: ${(props) => `${props.$paddingTop ?? 12}px`};
`;
