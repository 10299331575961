import _ from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useLazyGetMyPermissionsInCategoryQuery } from "../../../../../../apis/publishersApi";
import { openSnackbar } from "../../../../../../app/helpers/snackBarHelper";
import { useAppDispatch } from "../../../../../../app/hooks/hooks";
import {
  setCanExport,
  setPublishersRoles,
} from "../../../../../../app/slices/publishersListSlice";
import { PermissionInCategory } from "../../../../../../models/permissionsInCategory";
import { SnackbarType } from "../../../../../../models/snackbar";
import { useLazyGetPublishersRolesListQuery } from "../../../../../../apis/publishersRolesApi";
import { SnackbarMsg } from "../../../../../../controls/Snackbar/SnackbarMessages";

export function usePublishersRoles(categoryId: string) {
  const dispatch = useAppDispatch();
  const [
    getPublishersRoles,
    {
      isUninitialized,
      data: publisherRoles,
      isError: isPublisherRolesError,
      isFetching,
      isLoading,
    },
  ] = useLazyGetPublishersRolesListQuery();

  const [
    getMyPermissions,
    {
      data: permissions,
      isError: isErrorMyPermission,
      isLoading: isLoadingMyPermission,
    },
  ] = useLazyGetMyPermissionsInCategoryQuery();

  const fetchPublisherRoles = useCallback(() => {
    if (categoryId !== "") {
      void getPublishersRoles(categoryId);
      void getMyPermissions(categoryId);
    }
  }, [categoryId, getPublishersRoles, getMyPermissions]);

  const isLoadingPublishers = useMemo(
    () =>
      (isUninitialized && categoryId !== "") ||
      isLoading ||
      isFetching ||
      isLoadingMyPermission,
    [isUninitialized, categoryId, isFetching, isLoading, isLoadingMyPermission]
  );

  const isPublishersError = useMemo(
    () => isPublisherRolesError || isErrorMyPermission,
    [isPublisherRolesError, isErrorMyPermission]
  );

  useEffect(() => {
    if (permissions) {
      const canUserExport = _.includes(
        permissions,
        PermissionInCategory.CanExportPublishersEmails
      );
      dispatch(setCanExport(canUserExport));
    }
  }, [permissions, dispatch]);

  useEffect(() => {
    fetchPublisherRoles();
  }, [fetchPublisherRoles]);

  useEffect(() => {
    if (categoryId !== "") {
      dispatch(setPublishersRoles(publisherRoles));
    }
  }, [categoryId, publisherRoles, dispatch]);

  useEffect(() => {
    if (isPublisherRolesError) {
      openSnackbar(
        dispatch,
        SnackbarMsg.PublisherRolesError,
        SnackbarType.error
      );
    }
  }, [dispatch, isPublisherRolesError]);

  return {
    fetchPublisherRoles,
    publisherRoles,
    isLoadingPublishers,
    isPublishersError,
  };
}
