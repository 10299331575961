import {
  PublisherRole,
  PublisherRoleType,
} from "../../../../../../../models/publisherRoles";
import { DirectAccessContent } from "./DirectAccessContent";
import { InheritedAccessContent } from "./InheritedAccessContent";

export function RoleCell(props: PublisherRole) {
  if (props.role && props.roleType === PublisherRoleType.Assigned) {
    return <DirectAccessContent role={props.role} />;
  }

  if (props.role && props.roleType === PublisherRoleType.Inherited) {
    return (
      <InheritedAccessContent
        role={props.role}
        categoryPath={props.assignedInCategoryPath}
        inheritedCategoryName={props.assignedInCategoryName}
        categoryId={props.assignedInCategoryId}
      />
    );
  }

  return <></>;
}
