import { ListFiltersBox } from "./SC/ListFiltersSC";
import { ListFiltersNavigation } from "./SC/ListFiltersNavigationSC";
import { Filters } from "../../filters/Filters";

export function ListFiltersContainer() {
  return (
    <ListFiltersNavigation id="filters-navigation-section">
      <ListFiltersBox>
        <Filters />
      </ListFiltersBox>
    </ListFiltersNavigation>
  );
}
