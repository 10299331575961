import styled from "styled-components";

interface DocumentsListDivProps {
  $fitToScreen: boolean;
}

export const DocumentsListDiv = styled.div<DocumentsListDivProps>`
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-x: ${(props) => (props.$fitToScreen ? "hidden" : "auto")};
`;
