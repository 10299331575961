import { useCallback } from "react";
import {
  PreviousRevisionState,
  useLazyGetPreviousNonDeletedRevisionStateQuery,
} from "../../../apis/documentActionsApi";
import { Identity } from "../../../models/documentDetails/documentMetadata";
import { SnackbarType } from "../../../models/snackbar";
import { getNonEmptyDocumentIdentity } from "../../helpers/documentIdentityHelper";
import { openSnackbar } from "../../helpers/snackBarHelper";
import { setUsePrompt } from "../../slices/documentDetailsSlice";
import { useAppDispatch } from "../hooks";
import { SnackbarMsg } from "../../../controls/Snackbar/SnackbarMessages";

interface PreviousNonDeletedRevisionProps {
  onSuccess: (previousRevision: PreviousRevisionState) => void;
  identity?: Identity;
  omitScheduledForDelete?: boolean;
}

function usePreviousNonDeletedRevision(props: PreviousNonDeletedRevisionProps) {
  const dispatch = useAppDispatch();
  const [getPreviousNonDeletedRevisionState] =
    useLazyGetPreviousNonDeletedRevisionStateQuery();
  const onSuccess = props.onSuccess;

  const fetchPreviousNonDeletedRevision = useCallback(() => {
    if (props.identity) {
      const nonEmptyIdentity = getNonEmptyDocumentIdentity(props.identity);
      getPreviousNonDeletedRevisionState({
        identity: nonEmptyIdentity,
        omitScheduledForDelete: props.omitScheduledForDelete ?? false,
      })
        .unwrap()
        .then((response) => {
          onSuccess(response);
        })
        .catch(() => {
          dispatch(setUsePrompt(true));
          openSnackbar(
            dispatch,
            SnackbarMsg.PreviousNotDeletedError,
            SnackbarType.error
          );
        });
    }
  }, [
    onSuccess,
    props.identity,
    props.omitScheduledForDelete,
    dispatch,
    getPreviousNonDeletedRevisionState,
  ]);

  return {
    fetchPreviousNonDeletedRevision,
  };
}

export default usePreviousNonDeletedRevision;
