import { FormHelperText } from "@mui/material";
import styled, { DefaultTheme } from "styled-components";

interface StyledFormHelperTextProps {
  $isWarning: boolean;
  $isError: boolean;
  $isVisible?: boolean;
  $marginTop?: number;
  $marginBottom?: number;
  $isInfo?: boolean;
}

function getDisplay(
  isWarning: boolean,
  isError: boolean,
  isVisible?: boolean,
  isInfo?: boolean
): string {
  if (!isVisible) {
    return "none";
  }

  if (isWarning || isError || isInfo) {
    return "flex";
  }

  return "none";
}

function getColor(
  isWarning: boolean,
  isError: boolean,
  theme: DefaultTheme
): string {
  if (isError) {
    return theme.palettes.secondary.deleted;
  }

  if (isWarning) {
    return theme.palettes.secondary.warning;
  }

  return theme.palettes.grayscale.dark;
}

export const StyledFormHelperText = styled(
  FormHelperText
)<StyledFormHelperTextProps>`
  &.MuiFormHelperText-root {
    color: ${(props) =>
      getColor(props.$isWarning, props.$isError, props.theme)};
    display: ${(props) =>
      getDisplay(
        props.$isWarning,
        props.$isError,
        props.$isVisible,
        props.$isInfo
      )};
    spacing: 10px;
    line-height: 1.5;
    align-items: center;
    margin-top: ${(props) => `${props.$marginTop ?? 2}`}px;
    margin-left: 4px;
    margin-bottom: ${(props) => `${props.$marginBottom ?? 0}px`};
  }
`;
