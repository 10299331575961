import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStepTwo() {
    return (
        <StepContentDiv>
            <Paragraph>
                A document that is categorized under a specific category is visible in the Download Section for that category and also in the Download Center for both that category and its ancestor categories.
            </Paragraph>
        </StepContentDiv>
    );
}
