import { ListItemText } from "@mui/material";
import styled from "styled-components";

interface StyledListItemTextProps {
  $hasAccess: boolean;
}

export const StyledListItemText = styled(ListItemText) <StyledListItemTextProps>`
  .MuiListItemText-primary {
    font-size: ${(props) => props.theme.typography.fontSizes.large}px;
    font-weight: ${(props) => (props.$hasAccess ? "bold" : "normal")};
    color: ${(props) =>
    !props.$hasAccess
      ? props.theme.palettes.grayscale.medium
      : props.theme.palettes.primary.dark};
  }

  .MuiListItemText-secondary {
    font-size: ${(props) => props.theme.typography.fontSizes.medium}px;
  }
`;
