import styled from "styled-components";

const StyledErrorMessageDiv = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default StyledErrorMessageDiv;
