import styled from "styled-components";

interface UserDataSpanProps {
  $isBolded: boolean;
}

export const UserDataSpan = styled.span<UserDataSpanProps>`
  width: 176px;
  text-overflow: ellipsis;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  font-size: ${(props) => props.theme.typography.fontSizes.large}px;
  font-weight: ${(props) => (props.$isBolded ? "800" : "400")};
`;
