import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { useEffect, useState } from "react";
import {
  getDocumentSecurityLevel,
  getExternalSecurityLevel,
  getInternalSecurityLevel,
  isSecurityLevelNonLimited,
} from "../../../../app/helpers/securityLevelHelper";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { selectSecurityTabAlternativeView } from "../../../../app/slices/documentDetailsSlice";
import {
  selectCurrentDocumentSecurityLevel,
  setEditedSecurityLevel,
} from "../../../../app/slices/documentMetadataSlice";
import { selectIsSecurityLevelValidationError } from "../../../../app/slices/documentMetadataValidationSlice";
import ExternalSecurityLevel from "../../../../models/documentDetails/SecurityLevels/externalSecurityLevel";
import InternalSecurityLevel from "../../../../models/documentDetails/SecurityLevels/internalSecurityLevel";
import { DocumentSecurityLevel } from "../../../../models/documentSecurityLevel";
import { SecurityAutoComplete } from "../../inputs/SecurityAutoComplete";
import { ToggleButtons } from "../../inputs/ToggleButtons";
import { KeyValuePair } from "../../inputs/controls/ToggleButtonsInput";
import SecurityLevelValidation from "./validation/SecurityLevelValidation";
import { ComboBoxListItem } from "../../../../models/autocompleteValue";
import { initialEditableState } from "../../../../app/hooks/permissions/models/state";
import { useUserCanEditDocument } from "../../../../app/hooks/permissions/useUserCanEditDocument";
import { useDocumentEditedMode } from "../../../../app/hooks/document/useDocumentEditedMode";

export interface ToggleButtonSecurityLevel extends KeyValuePair {
  isDisabled: boolean;
}

function mapToToggleButtonSecurityLevel(
  value: InternalSecurityLevel | ExternalSecurityLevel,
  disableLimitedLevels: boolean
): ToggleButtonSecurityLevel {
  if (
    (value === InternalSecurityLevel.Limited ||
      value === ExternalSecurityLevel.Limited) &&
    disableLimitedLevels
  ) {
    return {
      value,
      isDisabled: true,
      key: value,
    };
  }

  return { value, isDisabled: false, key: value };
}

interface SecurityLevelControlProps {
  disableLimitedSecurityLevels: boolean;
  selectedAccessGroups: ComboBoxListItem[];
}

const tooltipDisabledMessage = "No access groups are available";

function SecurityLevelControl(props: SecurityLevelControlProps) {
  const dispatch = useAppDispatch();
  const securityLevelDocumentMetadata = useAppSelector(
    selectCurrentDocumentSecurityLevel
  );

  const internalSecurityLevelValues = Object.values(InternalSecurityLevel).map(
    (value) =>
      mapToToggleButtonSecurityLevel(value, props.disableLimitedSecurityLevels)
  );
  const externalSecurityLevelValues = Object.values(ExternalSecurityLevel).map(
    (value) =>
      mapToToggleButtonSecurityLevel(value, props.disableLimitedSecurityLevels)
  );
  const { securitySection } = useDocumentEditedMode();

  const showAlternativeTab = useAppSelector(selectSecurityTabAlternativeView);
  const appInsights = useAppInsightsContext();
  const trackSecurityViewMode = useTrackEvent(
    appInsights,
    "Security section view mode",
    { view: "" }
  );
  const isSecurityLevelValidationError = useAppSelector(
    selectIsSecurityLevelValidationError
  );

  const [editableState, setEditableState] = useState(initialEditableState);
  const { canEditDocument } = useUserCanEditDocument();

  useEffect(() => {
    setEditableState(canEditDocument(true));
  }, [canEditDocument]);

  useEffect(() => {
    trackSecurityViewMode({
      view: showAlternativeTab ? "Alternative" : "Default",
    });
  }, [showAlternativeTab, trackSecurityViewMode]);

  const mapSecurityLevels = (securityLevels: DocumentSecurityLevel[]) => {
    return securityLevels
      .filter(
        (value) =>
          !props.disableLimitedSecurityLevels ||
          isSecurityLevelNonLimited(value)
      )
      .map((value) => {
        return {
          key: value,
          value: value,
        };
      });
  };

  const securityLevelValues = mapSecurityLevels(
    Object.values(DocumentSecurityLevel)
  );

  const handleInternalSecurityTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    selected: string | null
  ) => {
    if (selected) {
      const internalSecurityLevel = selected as InternalSecurityLevel;
      const externalSecurityLevel = getExternalSecurityLevel(
        securityLevelDocumentMetadata
      );
      dispatch(
        setEditedSecurityLevel(
          getDocumentSecurityLevel(internalSecurityLevel, externalSecurityLevel)
        )
      );
    }
  };

  const handleExternalSecurityTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    selected: string | null
  ) => {
    if (selected) {
      const externalSecurityLevel = selected as ExternalSecurityLevel;
      const internalSecurityLevel = getInternalSecurityLevel(
        securityLevelDocumentMetadata
      );
      dispatch(
        setEditedSecurityLevel(
          getDocumentSecurityLevel(internalSecurityLevel, externalSecurityLevel)
        )
      );
    }
  };

  return (
    <>
      {!showAlternativeTab && (
        <SecurityAutoComplete
          id="security-level-dropdown"
          labelText="Security Level"
          securityLevel={securityLevelDocumentMetadata}
          items={securityLevelValues}
          isEditable={editableState.isEditable}
          isEdited={securitySection.securityIsEdited}
          notEditableMessage={editableState.lockMessage}
          isValidationError={isSecurityLevelValidationError}
        />
      )}
      {showAlternativeTab && (
        <div id="internal-external-security-level-control">
          <ToggleButtons
            id="internal-security-level"
            labelText="Internal security level"
            value={getInternalSecurityLevel(securityLevelDocumentMetadata)}
            values={internalSecurityLevelValues}
            isEdited={securitySection.internalSecurityLevelIsEdited}
            isEditable={editableState.isEditable}
            handleInputChange={handleInternalSecurityTypeChange}
            notEditableMessage={editableState.lockMessage}
            tooltipDisableMessage={tooltipDisabledMessage}
          />
          <ToggleButtons
            id="external-security-level"
            labelText="External security level"
            value={getExternalSecurityLevel(securityLevelDocumentMetadata)}
            values={externalSecurityLevelValues}
            isEdited={securitySection.externalSecurityLevelIsEdited}
            isEditable={editableState.isEditable}
            handleInputChange={handleExternalSecurityTypeChange}
            notEditableMessage={editableState.lockMessage}
            tooltipDisableMessage={tooltipDisabledMessage}
          />
        </div>
      )}
      <SecurityLevelValidation
        blockLimitedLevels={props.disableLimitedSecurityLevels}
        selectedAccessGroups={props.selectedAccessGroups}
      />
    </>
  );
}

export default SecurityLevelControl;
