import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { resetPageNumber } from "../../../../../app/slices/documentListSlice";
import {
  selectCurrentDocumentNumberFilter,
  selectSearchDocumentNumberFilter,
  setCurrentDocumentNumberFilter,
  setSearchDocumentNumberFilter,
} from "../../../../../app/slices/navigationSlice";
import { UncontrolledTextFieldInput } from "../../../../../controls/TextField/UncontrolledTextFieldInput";
import { InputDiv } from "../../SC/InputDiv";
import { trim } from "lodash";

export function DocumentNumberFilter() {
  const dispatch = useAppDispatch();
  const searchDocumentNumber: string = useAppSelector(
    selectSearchDocumentNumberFilter
  );
  const currentDocumentNumber: string = useAppSelector(
    selectCurrentDocumentNumberFilter
  );

  const onChange = useCallback(
    (text: string) => {
      dispatch(setCurrentDocumentNumberFilter(text));
    },
    [dispatch]
  );

  const dispatchInputChange = useCallback(
    (text: string) => {
      dispatch(setSearchDocumentNumberFilter(trim(text)));
      dispatch(resetPageNumber());
    },
    [dispatch]
  );

  const onClick = useCallback(() => {
    if (searchDocumentNumber !== currentDocumentNumber) {
      dispatchInputChange(currentDocumentNumber);
    }
  }, [searchDocumentNumber, currentDocumentNumber, dispatchInputChange]);

  const onClear = useCallback(() => {
    dispatch(setCurrentDocumentNumberFilter(""));

    if (searchDocumentNumber !== "") {
      dispatchInputChange("");
    }
  }, [searchDocumentNumber, dispatch, dispatchInputChange]);

  return (
    <InputDiv
      id="navigation-filters-document-number"
      className="document-filter"
    >
      <UncontrolledTextFieldInput
        id="document-number-textfield"
        label="Document number"
        onClick={onClick}
        onChange={onChange}
        onClear={onClear}
        value={currentDocumentNumber}
      />
    </InputDiv>
  );
}
