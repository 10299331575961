export const ExternalLimitedAndInternalLimited =
  "External Limited + Internal Limited";
export const ExternalPublicAndInternalPublic =
  "External Public + Internal Public";
export const HiddenFromExternalAndInternalPublic =
  "Hidden from External Interfaces + Internal Public";
export const ExternalLimitedAndInternalPublic =
  "External Limited + Internal Public";
export const InternalPublic = "External None + Internal Public";
export const InternalLimited = "External None + Internal Limited";

export const SecurityTypeInternal = "INTERNAL";
export const SecurityTypePremium = "PREMIUM";
export const SecurityTypePublic = "PUBLIC";

export const InternalPublicPill = "Internal Public";
export const InternalLimitedPill = "Internal Limited";
export const ExternalPublicPill = "External Public";
export const ExternalLimitedPill = "External Limited";
export const HiddenFromExternalPill = "Hidden From External Interfaces";
