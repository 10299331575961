import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStep5() {
  return (
    <StepContentDiv>
      <Paragraph>
        Once any changes are made in this section, the changed field is marked in blue.
      </Paragraph>
    </StepContentDiv>
  );
}
