import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/identity/dc_multiple_parts.png";

export function PartStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        A &quot;Part&quot; is a suffix utilized to extend the document identity, ensuring the unique identification of a standalone document.
      </Paragraph>
      <Paragraph>
        It should be employed when the document content is presented in different formats (such as PDF, video, image, or Word) or when the document is divided into smaller segments (e.g., splitting a large file into several smaller ones) while maintaining the same Document number.
      </Paragraph>
      <Paragraph>
        In the Download Center, all document parts are consolidated under a shared document number.
      </Paragraph>
      <Paragraph>
        <Screenshot src={screenshot} />
      </Paragraph>
    </StepContentDiv>
  );
}
