import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import { SectionName } from "../../../app/slices/models/documentDetailsModels";
import {
  selectIsNavigationMinimized,
  setIsNavigationMinimized,
} from "../../../app/slices/tagsViewSlice";
import { IconButtonControl } from "../../../controls/Buttons/IconButtonControl";
import { SelfHelpButtonControl } from "../../../controls/Buttons/SelfHelpButtonControl";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../models/selfHelp/selfHelpSection";
import { CategoryTreeHeaderDiv } from "../../categoryNavigation/SC/CategoryTreeHeaderDiv";
import { CategoryTreeTitleSpan } from "../../cms/Config/navigation/SC/CategoryTreeTitleSpan";
import { selectIsWindowTooSmall } from "../../../app/slices/commonSlice";

function TagTypesHeader() {
  const dispatch = useAppDispatch();
  const isMinimized = useAppSelector(selectIsNavigationMinimized);
  const isWindowTooSmall = useAppSelector(selectIsWindowTooSmall);

  const onToggleClick = useCallback(() => {
    if (!isWindowTooSmall) {
      dispatch(setIsNavigationMinimized(!isMinimized));
    }
  }, [isWindowTooSmall, isMinimized, dispatch]);

  return (
    <CategoryTreeHeaderDiv>
      <CategoryTreeTitleSpan>Select Tag</CategoryTreeTitleSpan>
      <SelfHelpButtonControl
        currentSelfHelp={CurrentSelfHelp.TagsNavigation}
        page={SelfHelpPage.Tags}
        section={SectionName.Tags}
      />
      <IconButtonControl
        id="navigation-collapse-button"
        popupText={TooltipMsg.Hide}
        icon={<KeyboardDoubleArrowLeftIcon fontSize="inherit" />}
        onClick={onToggleClick}
      />
    </CategoryTreeHeaderDiv>
  );
}

export default TagTypesHeader;
