import styled from "styled-components";
import { GroupNameTypography } from "./GroupNameTypography";

export const GroupCountSC = styled(GroupNameTypography)`
  display: flex;
  font-style: italic;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
  font-size: ${(props) => props.theme.typography.fontSizes.medium}px;
`;
