import { useCallback, useEffect, useState } from "react";
import { useDeleteAllDocumentActionsMutation } from "../../../apis/scheduledActionsApi";
import { getNonEmptyDocumentIdentity } from "../../../app/helpers/documentIdentityHelper";
import { openSnackbar } from "../../../app/helpers/snackBarHelper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import { selectDocumentIdentity } from "../../../app/slices/documentMetadataSlice";
import scheduledActionMessage from "../../../models/scheduledActionMessage";
import { SnackbarType } from "../../../models/snackbar";
import moment from "moment";
import { useGetAppSettingsQuery } from "../../../apis/configApi";

export function useScheduleDeleteAll() {
  const dispatch = useAppDispatch();
  const [
    scheduleDeleteAllAction,
    { data, isLoading, isError, isSuccess, reset },
  ] = useDeleteAllDocumentActionsMutation();
  const identity = useAppSelector(selectDocumentIdentity);
  const [deleteOnlyFor, setDeleteOnlyFor] = useState<string[] | undefined>();

  const { data: config } = useGetAppSettingsQuery(null);
  const dateFormat = config ? config.localization.dateFormat : "";

  useEffect(() => {
    if (data) {
      setDeleteOnlyFor(data);
    }
  }, [data]);

  useEffect(() => {
    if (isError || (deleteOnlyFor && deleteOnlyFor.length > 0)) {
      openSnackbar(
        dispatch,
        "Error while scheduling delete all action",
        SnackbarType.error
      );
    }
  }, [dispatch, isError, deleteOnlyFor]);

  const runCleanup = useCallback(() => {
    setDeleteOnlyFor(undefined);
    reset();
  }, [reset]);

  const scheduleDeleteAll = useCallback(
    (message: scheduledActionMessage) => {
      const nonEmptyIdentity = getNonEmptyDocumentIdentity(identity);
      const formattedDate = moment(message.expirationDate).format(dateFormat);

      void scheduleDeleteAllAction({
        documentIdentity: nonEmptyIdentity,
        message: {
          ...message,
          deleteOnlyFor: deleteOnlyFor ?? [],
          expirationDate: formattedDate,
        },
      });
    },
    [identity, deleteOnlyFor, scheduleDeleteAllAction, dateFormat]
  );

  return {
    isSuccess: isSuccess && deleteOnlyFor?.length == 0,
    hasUnsuccessfulRequests: deleteOnlyFor && deleteOnlyFor.length > 0,
    isLoading: isLoading,
    isError: isError,
    deleteOnlyFor: deleteOnlyFor ?? [],
    scheduleDeleteAll,
    runCleanup,
  };
}

export default useScheduleDeleteAll;
