import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { useUserCanEditDocument } from "../../../../app/hooks/permissions/useUserCanEditDocument";
import { selectIsLoadingDetailsMetaData } from "../../../../app/slices/documentDetailsSlice";
import {
  clearEditedDocumentIdentity,
  selectCurrentDocumentMetadata,
} from "../../../../app/slices/documentMetadataSlice";
import { selectHasIdentitySectionAnyValidationErrors } from "../../../../app/slices/documentMetadataValidationSlice";
import { SectionName } from "../../../../app/slices/models/documentDetailsModels";
import { CurrentSelfHelp } from "../../../../models/CurrentSelfHelpType";
import { DetailsSection, DetailsSectionProps } from "../DetailsSection";
import { DocumentNumber } from "./DocumentNumber";
import { Language } from "./Language";
import { Part } from "./Part";
import { Revision } from "./Revision";
import { useDocumentEditedMode } from "../../../../app/hooks/document/useDocumentEditedMode";
import { useDocumentFlow } from "../../../../app/hooks/document/useDocumentFlow";

export function IdentitySection(props: DetailsSectionProps) {
  const dispatch = useAppDispatch();
  const currentDocumentMetadata = useAppSelector(selectCurrentDocumentMetadata);
  const { identitySection } = useDocumentEditedMode();
  const isLoadingDetailsMetaData = useAppSelector(
    selectIsLoadingDetailsMetaData
  );
  const hasValidationErrors = useAppSelector(
    selectHasIdentitySectionAnyValidationErrors
  );
  const { isNewDocumentRevisionDraft } = useDocumentFlow();

  const {
    canEditIdentitySection,
    canEditDocumentNumber,
    canEditPart,
    canEditLanguage,
    canEditRevision,
  } = useUserCanEditDocument();

  const discardIdentitySection = useCallback(() => {
    dispatch(clearEditedDocumentIdentity(isNewDocumentRevisionDraft));
  }, [dispatch, isNewDocumentRevisionDraft]);

  return (
    <DetailsSection
      {...props}
      section={SectionName.Identity}
      selfHelpType={CurrentSelfHelp.DetailsIdentity}
      name="Identity"
      isEdited={identitySection.identitySectionIsEdited}
      isValidationError={hasValidationErrors}
      isLoadingDetailsMetaData={isLoadingDetailsMetaData}
      onDiscardChanges={discardIdentitySection}
      isEditable={canEditIdentitySection.isEditable}
      notEditableReason={canEditIdentitySection.lockMessage}
    >
      <DocumentNumber
        isEditable={canEditDocumentNumber.isEditable}
        documentNumber={currentDocumentMetadata.documentIdentity.documentNumber}
        isLoadingDetailsMetaData={isLoadingDetailsMetaData}
        notEditableMessage={canEditDocumentNumber.lockMessage}
      />
      <Language
        isEditable={canEditLanguage.isEditable}
        isLoadingDetailsMetaData={isLoadingDetailsMetaData}
        languageCodes={
          currentDocumentMetadata.documentIdentity.documentLanguageCodes
        }
        notEditableMessage={canEditLanguage.lockMessage}
      />
      <Part
        isEditable={canEditPart.isEditable}
        part={currentDocumentMetadata.documentIdentity.documentPart}
        isLoadingDetailsMetaData={isLoadingDetailsMetaData}
        notEditableMessage={canEditPart.lockMessage}
      />
      <Revision
        isEditable={canEditRevision.isEditable}
        revision={currentDocumentMetadata.documentIdentity.revision}
        isLoadingDetailsMetaData={isLoadingDetailsMetaData}
        notEditableMessage={canEditRevision.lockMessage}
      />
    </DetailsSection>
  );
}
