import { useState } from "react";
import { useTheme } from "styled-components";
import { TextButtonControl } from "../../../../controls/Buttons/TextButtonControl";
import { DialogControl } from "../../../../controls/Dialog/DialogControl";
import { StyledDialogContentText } from "../../../details/selfHelpPopup/SC/StyledDialogContentText";
import { ButtonDiv } from "../Close/SC/ButtonDiv";
import CancelIcon from "@mui/icons-material/Cancel";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import { useAppDispatch } from "../../../../app/hooks/hooks";
import { clearTagValues } from "../../../../app/slices/tagsSlice";
import TagsListItem from "../../../../models/tags/tagsListItem";

interface DiscardChangesButtonProps {
  isEdited?: boolean;
  canEdit?: boolean;
  isLoading: boolean;
  hasErrors: boolean;
  selectedTag?: TagsListItem;
}

export function DiscardChangesButton(props: DiscardChangesButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const onDiscardAllChanges = () => {
    dispatch(clearTagValues());
    setIsOpen(false);
  };

  const disableTooltipText = () => {
    switch (true) {
      case !props.selectedTag:
        return TooltipMsg.ChooseTagFirst;
      case !props.canEdit:
        return TooltipMsg.NoAccessToTag;
      case !props.isEdited:
        return TooltipMsg.NoChangesMade;
      default:
        return "";
    }
  };

  return (
    <ButtonDiv>
      <DialogControl
        id="discard-changes-button"
        title="Discard all changes"
        onConfirmClick={onDiscardAllChanges}
        onCloseClick={() => setIsOpen(false)}
        maxWidth="sm"
        useActions={true}
        isOpen={isOpen}
        content={
          <StyledDialogContentText id="alert-dialog-description">
            This will discard all changes made. Do you want to proceed?
          </StyledDialogContentText>
        }
      />
      <TextButtonControl
        id={"document-settings-discard-changes-button"}
        colors={"secondary"}
        height={theme.shapes.primaryButtonHeight}
        text={"Discard changes"}
        onClick={(event) => {
          setIsOpen(true);
          event.stopPropagation();
        }}
        isCompactView={false}
        isVertical={false}
        isVisible={true}
        disabled={
          !props.hasErrors &&
          (!props.isEdited || !props.canEdit || props.isLoading)
        }
        icon={<CancelIcon fontSize="small" />}
        disabledTooltipText={disableTooltipText()}
      />
    </ButtonDiv>
  );
}
