import styled from "styled-components";

interface CellSkeletonDivProps {
  width: number;
}

export const CellSkeletonDiv = styled.div<CellSkeletonDivProps>`
  width: ${(props) => props.width}px;
  display: flex;
  justify-content: flex-start;
`;
