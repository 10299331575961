import { MenuItem } from "@mui/material";
import styled from "styled-components";

interface StyledMenuItemProps {
  padding?: number;
  height?: number;
  $isDisabled?: boolean;
}

export const StyledMenuItem = styled(MenuItem)<StyledMenuItemProps>`
  &.MuiMenuItem-root {
    height: ${(props) => (props.height ? props.height + "px" : "unset")};
    padding: ${(props) => (props.padding ? props.padding + "px" : "6px 16px")};
    color: ${(props) =>
      props.$isDisabled
        ? props.theme.palettes.grayscale.disabledColor
        : props.theme.palettes.grayscale.darkest};

    &:hover {
      cursor: ${(props) => (props.$isDisabled ? "not-allowed" : "pointer")};
      background-color: ${(props) =>
        props.$isDisabled
          ? props.theme.palettes.grayscale.white
          : props.theme.palettes.grayscale.lighter};
      color: ${(props) =>
        props.$isDisabled
          ? props.theme.palettes.grayscale.disabledColor
          : props.theme.palettes.primary.main};
    }
  }
`;
