export enum DocumentsColumnType {
  Checkbox = "Checkbox",
  Chevron = "Chevron",
  Title = "Title",
  DocumentNumber = "Number",
  DocumentKind = "DocumentKind",
  Published = "PublishDate",
  Part = "Part",
  Language = "Language",
  Revision = "Revision",
  SecurityLevel = "SecurityLevel",
  Publisher = "Publisher",
  Info = "Info",
  Options = "Options",
}
