import { ForbiddenErrorCode } from "../../app/slices/apiErrorSlice";
import { ErrorDescriptionDiv } from "./SC/ErrorDescriptionDiv";
import { ErrorList } from "./SC/ErrorList";

export interface UnuathorizedMessageProps {
  userEmail: string;
  errorCode: ForbiddenErrorCode;
}

export const UnuathorizedMessageSelector = (
  props: UnuathorizedMessageProps
) => {
  if (props.errorCode === ForbiddenErrorCode.UserHasMultipleCompanies) {
    return (
      <ErrorDescriptionDiv>
        We are unable to recognize company to which you belong. If this problem
        persists, please raise an incident with screenshot of this error and ask
        to assign it to ABB Library.
      </ErrorDescriptionDiv>
    );
  }

  if (props.errorCode === ForbiddenErrorCode.AbbUserNotPublisher) {
    return (
      <ErrorDescriptionDiv>
        <div>
          User with email {props.userEmail} is not authorized to access ABB
          Library Publish UI.
        </div>
        <div>
          ABB Library content is split into categories defined in ABB Products.
          Every category in ABB Library has its owner, who is responsible for
          documents published within their category and for managing access
          requests to it.
        </div>
        <div>How to start publishing documents to ABB Library?</div>
        <ErrorList>
          <li>
            Submit a request in{" "}
            <a href="https://abb.service-now.com/myservices?id=sc_cat_item&sys_id=26d627f5dbcba09498282e84059619e1">
              MyIS tool
            </a>{" "}
            and inform what category you need to have access to. If you do not
            know the category name, please check it in ABB Products Tree (VPN
            required).
          </li>
          <li>
            After getting an approval from the category owner you will be asked
            to pass the training (if you have not done it already).
          </li>
          <li>
            Once the training is passed, the access will be granted and you will
            be informed via email.
          </li>
        </ErrorList>
      </ErrorDescriptionDiv>
    );
  }

  return (
    <ErrorDescriptionDiv>
      User with email {props.userEmail} is not authorized to access this
      application
    </ErrorDescriptionDiv>
  );
};
