import { useAppDispatch } from "../../../app/hooks/hooks";
import { IconButtonControl } from "../../../controls/Buttons/IconButtonControl";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import { FiltersDiv } from "./SC/FiltersDiv";
import { SectionHeaderDiv } from "./SC/SectionHeaderDiv";
import { TitleSpan } from "./SC/TitleSpan";
import { AccessGroupsFilter } from "./inputs/accessGroups/AccessGroupsFilter";
import { DocumentNumberFilter } from "./inputs/documentNumber/DocumentNumberFilter";
import { PublishedDateFilter } from "./inputs/publishedDate/PublishedDateFilter";
import { PublishersFilter } from "./inputs/publishers/PublishersFilter";
import { SecurityLevelsFilter } from "./inputs/securityLevels/SecurityLevelsFilter";
import { WorkflowFilter } from "./inputs/workflows/WorkflowFilter";
import { DocumentKindsFilter } from "./inputs/documentKinds/DocumentKindsFilter";
import { MultipleFilterDiv } from "./SC/MultipleFilterDiv";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { resetFilters } from "../../../app/slices/navigationSlice";
import { resetPageNumber } from "../../../app/slices/documentListSlice";
import { DocumentTitleFilter } from "./inputs/documentTitle/DocumentTitleFilter";
import { LanguagesFilter } from "./inputs/languages/LanguagesFilter";
import { SelfHelpButtonControl } from "../../../controls/Buttons/SelfHelpButtonControl";
import { SelfHelpPage } from "../../../models/selfHelp/selfHelpSection";
import { SectionName } from "../../../app/slices/models/documentDetailsModels";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";

export function Filters() {
  const dispatch = useAppDispatch();

  const handleResetFiltersClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(resetFilters());
    dispatch(resetPageNumber());
  };

  return (
    <FiltersDiv id="navigation-filters-div">
      <SectionHeaderDiv>
        <TitleSpan>Filters</TitleSpan>
        <IconButtonControl
          id="reset-filters-button"
          onClick={handleResetFiltersClick}
          popupText={TooltipMsg.ResetFilters}
          icon={<RestartAltIcon fontSize="inherit" />}
        />
        <SelfHelpButtonControl
          currentSelfHelp={CurrentSelfHelp.Filters}
          page={SelfHelpPage.List}
          section={SectionName.Filters}
        />
      </SectionHeaderDiv>
      <MultipleFilterDiv id="navigation-multiple-filters-div">
        <span id="doc-number-title-filters">
          <DocumentNumberFilter />
          <DocumentTitleFilter />
        </span>
        <span id="multiple-filters">
          <DocumentKindsFilter />
          <LanguagesFilter />
          <PublishersFilter />
          <AccessGroupsFilter />
        </span>
        <WorkflowFilter />
        <PublishedDateFilter />
        <SecurityLevelsFilter />
      </MultipleFilterDiv>
    </FiltersDiv>
  );
}
