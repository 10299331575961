import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStep2() {
  return (
    <StepContentDiv>
      <Paragraph>
        Access groups enabled for the selected category appear at the top of the list with a selected checkbox.
      </Paragraph>
    </StepContentDiv>
  );
}
