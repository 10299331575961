import { Identity } from "../../models/documentDetails/documentMetadata";

export function getNonEmptyDocumentIdentity(identity: Identity): Identity {
  const newIdentity = { ...identity };
  newIdentity.documentNumber = newIdentity.documentNumber.trim();
  newIdentity.documentPart = newIdentity.documentPart.trim();

  if (newIdentity.documentPart === "") {
    newIdentity.documentPart = "_";
  }

  if (newIdentity.documentLanguageCodes.length === 0) {
    newIdentity.documentLanguageCodes = ["_"];
  }

  return newIdentity;
}
