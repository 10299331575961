import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";

export function ColumnsStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The{" "}
        <ViewColumnIcon
          style={{
            fontSize: "16px",
            verticalAlign: "middle",
          }}
        />{" "}
        &quot;Columns&quot; button provides options to show/hide columns, change
        their order using drag and drop, and adjust the overflow setting.
      </Paragraph>
      <Paragraph>
        When the &quot;Allow overflow&quot; feature is enabled, columns can
        extend beyond the screen size, displaying a horizontal scrollbar.
      </Paragraph>
      <Paragraph>
        On the other hand, when the &quot;Allow overflow&quot; feature is
        disabled, columns will adjust to the available space within the screen
        and can only be resized within the screen boundaries.
      </Paragraph>
    </StepContentDiv>
  );
}
