import { debounce, DebouncedFunc, find } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  documentsListApi,
  useLazyGetDocumentsListQuery,
} from "../../../apis/documentsListApi";
import { ErrorDisplay } from "../../../app/errorHandling/ErrorDisplay";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import {
  setDocumentListViewInLocalStorage,
  setDocumentParamsInLocalStorage,
} from "../../../app/hooks/localStorage";
import {
  selectContentWidth,
  selectFitToScreen,
  selectIsResizing,
} from "../../../app/slices/contentSlice";
import {
  resetExpandedGroups,
  selectDensity,
  selectIsGroupingEnabled,
  selectPageNumber,
  selectPageSize,
  selectSortBy,
  selectSortDescending,
  setDocumentsCount,
  setExpandableGroupsState,
  setGroupItemsToLoadCount,
  setIsDocumentListError,
  setIsDocumentListLoading,
  setIsDocumentsListFetching,
  setStartedDocumentsFirstLoad,
} from "../../../app/slices/documentListSlice";
import {
  fitColumnsToScreen,
  selectColumnWidth,
  selectDocumentColumn,
  setHasAnyDocumentGroupOnView,
  setHasAnyDocumentOnView,
  setHasAnySingleDocumentOnView,
} from "../../../app/slices/documentListViewSlice";
import {
  selectIncludeDocumentsFromSubcategories,
  selectIncludeDocumentsICannotManage,
  selectNavigationFilters,
  selectShowOnlyUnCategorizedDocuments,
} from "../../../app/slices/navigationSlice";
import DocumentListItem from "../../../models/documentList/documentListItem";
import { DocumentListResult } from "../../../models/documentList/documentListResult";
import DocumentListSearchCriteria, {
  DocumentIdentity,
} from "../../../models/documentList/documentListSearchCriteria";
import { DocumentListGroup } from "./documentListGroup/DocumentListGroup";
import { DocumentsListHeader } from "./documentListHeader/DocumentsListHeader";
import { DocumentListItemSkeleton } from "./documentListItem/DocumentListItemSkeleton";
import { DocumentsListItem } from "./documentListItem/DocumentsListItem";
import { useChangeState } from "./hooks/useChangeState";
import { NoResourceFound } from "./noDocumentsFound/NoResourceFound";
import { DocumentItemsDiv } from "./styledComponents/DocumentItemsDiv/DocumentItemsDiv";
import { DocumentsListDiv } from "./styledComponents/DocumentsListDiv";

const abortFiltersReason = "Filters changed";

export function DocumentsList() {
  const firstLoad = useRef(true);
  const documentListRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const fitToScreen = useAppSelector(selectFitToScreen);
  const pageSize = useAppSelector(selectPageSize);
  const density = useAppSelector(selectDensity);
  const groupedResult = useAppSelector(selectIsGroupingEnabled);
  const pageNumber = useAppSelector(selectPageNumber);
  const sortBy = useAppSelector(selectSortBy);
  const sortDescending = useAppSelector(selectSortDescending);
  const filters = useAppSelector(selectNavigationFilters);
  const contentWidth = useAppSelector(selectContentWidth);
  const allColumnsWidth = useAppSelector(selectColumnWidth);
  const isResizing = useAppSelector(selectIsResizing);
  const documentColumns = useAppSelector(selectDocumentColumn);
  const includeDocsFromSubcats = useAppSelector(
    selectIncludeDocumentsFromSubcategories
  );
  const includeDocsIcannotManage = useAppSelector(
    selectIncludeDocumentsICannotManage
  );
  const showOnlyUnCategorizedDocuments = useAppSelector(
    selectShowOnlyUnCategorizedDocuments
  );
  const [isPreservedError, setIsPreservedError] = useState(false);
  const canResetExpandedGroups = useRef(false);
  const [documentsList, setDocumentsList] = useState<
    DocumentListResult | undefined
  >();
  const { refreshList, isRefreshDocumentListFetching } = useChangeState();
  const debouncedFunc = useRef<DebouncedFunc<() => void>>();
  const abortController = useRef<AbortController>();
  const [isFirstLoadingSuccess, setIsFirstLoadingSuccess] = useState(false);

  const reloadAfterChangeWorkflow = (document: DocumentIdentity) => {
    if (documentsList) {
      refreshList(
        document,
        documentsList.documents,
        refreshDocumentListItems,
        false
      );
    }
  };

  const refreshDocumentListItems = (newDocuments: DocumentListItem[]) => {
    if (documentsList)
      setDocumentsList({ ...documentsList, documents: newDocuments });
  };

  const [
    getDocumentsList,
    {
      isFetching: isDocumentsListFetching,
      isError: isDocumentListError,
      error: documentListError,
    },
  ] = useLazyGetDocumentsListQuery();

  const debouncedGetDocumentsList = useMemo(
    () =>
      (
        args: DocumentListSearchCriteria & { controller?: AbortController },
        time: number
      ) =>
        debounce(() => {
          if (canResetExpandedGroups.current) {
            dispatch(resetExpandedGroups());
          } else {
            canResetExpandedGroups.current = true;
          }

          setIsPreservedError(false);
          void getDocumentsList({
            ...args,
            signal: args.controller?.signal,
          }).then((response) => {
            if (response.data) {
              const groups = response.data.documents.filter(
                (doc) => doc.groupItemsCount > 1
              );

              dispatch(setDocumentsCount(response.data.allGroupsCount));
              dispatch(setExpandableGroupsState(groups));
              dispatch(
                setGroupItemsToLoadCount(
                  groups.reduce(
                    (sum, current) => sum + current.groupItemsCount,
                    0
                  )
                )
              );
              setDocumentsList(response.data);
              documentListRef.current?.scrollTo(0, 0);
              setIsFirstLoadingSuccess(true);
            }
          });
        }, time),
    [getDocumentsList, dispatch]
  );

  const fetchDocumentList = useCallback(
    (time: number, controller?: AbortController) => {
      if (pageSize && pageNumber) {
        const debounced = debouncedGetDocumentsList(
          {
            ...filters,
            documentNumber: filters.searchDocumentNumber,
            documentTitle: filters.searchDocumentTitle,
            pageNumber: pageNumber,
            pageSize: pageSize,
            includeDocumentsFromSubcategories: includeDocsFromSubcats,
            includeDocumentsICannotManage: includeDocsIcannotManage,
            showOnlyUnCategorizedDocuments: showOnlyUnCategorizedDocuments,
            sortBy: sortBy,
            sortDescending: sortDescending,
            returnGroupedResults: groupedResult,
            controller: controller,
          },
          time
        );
        debouncedFunc.current = debounced;
        debounced();
      }
    },
    [
      pageNumber,
      pageSize,
      groupedResult,
      sortBy,
      sortDescending,
      filters,
      includeDocsFromSubcats,
      includeDocsIcannotManage,
      showOnlyUnCategorizedDocuments,
      debouncedGetDocumentsList,
    ]
  );

  useEffect(() => {
    if (documentsList === undefined || documentsList.documents.length === 0) {
      dispatch(setHasAnyDocumentOnView(false));
      dispatch(setHasAnySingleDocumentOnView(false));
      dispatch(setHasAnyDocumentGroupOnView(false));
      return;
    }

    const anyGroup = find(documentsList.documents, (d: DocumentListItem) => {
      return d.groupItemsCount > 1;
    });

    const anySingleDocument = find(
      documentsList.documents,
      (d: DocumentListItem) => {
        return d.groupItemsCount == 1;
      }
    );

    if (anyGroup || anySingleDocument) {
      dispatch(setHasAnyDocumentOnView(true));
    } else {
      dispatch(setHasAnyDocumentOnView(false));
    }

    if (anyGroup) {
      dispatch(setHasAnyDocumentGroupOnView(true));
    } else {
      dispatch(setHasAnyDocumentGroupOnView(false));
    }

    if (anySingleDocument) {
      dispatch(setHasAnySingleDocumentOnView(true));
    } else {
      dispatch(setHasAnySingleDocumentOnView(false));
    }
  }, [dispatch, documentsList]);

  useEffect(() => {
    if (pageSize && pageNumber) {
      const debounceTime = firstLoad.current ? 0 : 500;
      debouncedFunc.current?.cancel();
      abortController.current?.abort(abortFiltersReason);

      if (firstLoad.current) {
        dispatch(setStartedDocumentsFirstLoad());
        firstLoad.current = false;
      }

      abortController.current = new AbortController();
      fetchDocumentList(debounceTime, abortController.current);
    }
  }, [pageNumber, pageSize, fetchDocumentList, dispatch]);

  useEffect(() => {
    setDocumentParamsInLocalStorage({
      pageSize: pageSize,
      density: density,
    });
  }, [density, pageSize]);

  useEffect(() => {
    if (fitToScreen) {
      dispatch(fitColumnsToScreen(contentWidth));
    }
  }, [contentWidth, fitToScreen, dispatch]);

  useEffect(() => {
    setDocumentListViewInLocalStorage({
      columns: documentColumns,
    });
  }, [documentColumns]);

  useEffect(() => {
    dispatch(setIsDocumentListLoading(!isFirstLoadingSuccess));
  }, [isFirstLoadingSuccess, dispatch]);

  useEffect(() => {
    dispatch(
      setIsDocumentsListFetching(
        isDocumentsListFetching && isFirstLoadingSuccess
      )
    );
  }, [isFirstLoadingSuccess, isDocumentsListFetching, dispatch]);

  useEffect(() => {
    if (isDocumentListError) {
      dispatch(setIsDocumentsListFetching(false));
      dispatch(documentsListApi.util.resetApiState());
      const isError =
        (documentListError as { error: string } | undefined)?.error !==
        abortFiltersReason;
      setIsPreservedError(isError);

      if (isError) {
        setIsFirstLoadingSuccess(false);
      }
    }
  }, [isDocumentListError, documentListError, dispatch]);

  useEffect(() => {
    dispatch(setIsDocumentListError(isPreservedError));
  }, [isPreservedError, dispatch]);

  if (isPreservedError) {
    return (
      <DocumentsListDiv
        $fitToScreen={fitToScreen}
        id="documents-list-div"
        className="documents-list-items"
      >
        <DocumentsListHeader />
        <ErrorDisplay
          errorId={"document-list-error"}
          errorMessageTitle={"Something went wrong"}
          errorDescription={"Document list request failed"}
          refreshFunction={() => {
            fetchDocumentList(0);
          }}
          showDash={false}
          showReloadButton={true}
        />
      </DocumentsListDiv>
    );
  }

  if (!isFirstLoadingSuccess || (isResizing && fitToScreen)) {
    return (
      <DocumentsListDiv
        $fitToScreen={fitToScreen}
        id="Documents-list-loading-progress-div"
        className="documents-list-items"
      >
        <DocumentsListHeader />
        <DocumentItemsDiv id="Documents-list-items-progress-div">
          {Array(pageSize)
            .fill(0)
            .map((value, index) => {
              return (
                <DocumentListItemSkeleton
                  key={index}
                  width={contentWidth}
                  zebraStripe={index % 2 === 0}
                  isResizing={isResizing && fitToScreen}
                />
              );
            })}
        </DocumentItemsDiv>
      </DocumentsListDiv>
    );
  }

  if (documentsList && documentsList.documents.length > 0) {
    return (
      <DocumentsListDiv
        $fitToScreen={fitToScreen}
        id="documents-list-div"
        className="documents-list-items"
      >
        <DocumentsListHeader />
        <DocumentItemsDiv
          id="Documents-list-items-div"
          ref={documentListRef}
          width={allColumnsWidth}
        >
          {documentsList.documents.map((doc, index) => {
            const zebraStripe = index % 2 === 0 ? true : false;
            return doc.groupItemsCount > 1 ? (
              <DocumentListGroup
                zebraStripe={zebraStripe}
                key={index}
                documentListItem={doc}
              />
            ) : (
              <DocumentsListItem
                zebraStripe={zebraStripe}
                index={index}
                key={index}
                documentListItem={doc}
                reloadAfterWorkflowChange={reloadAfterChangeWorkflow}
                isLoading={isRefreshDocumentListFetching}
              />
            );
          })}
        </DocumentItemsDiv>
      </DocumentsListDiv>
    );
  }

  return (
    <NoResourceFound
      headerMessage="No Documents Found"
      contentMessage="Change or reset filters to get documents you want to explore..."
      headerId="no-documents-found-header"
    />
  );
}
