import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import docPublished from "../../../../../icons/single_doc_published.svg";
import docApproval from "../../../../../icons/single_doc_approval.svg";
import docArchived from "../../../../../icons/single_doc_archived.svg";
import docDeleted from "../../../../../icons/single_doc_deleted.svg";
import docDraft from "../../../../../icons/single_doc_draft.svg";

import { StyledLi } from "../../../SC/StyledLi";
import { StyledImg } from "../../../SC/StyledImg";
import { useTheme } from "styled-components";

export function DocumentListItemStep() {
  const theme = useTheme();

  return (
    <StepContentDiv>
      <Paragraph>
        Documents can have two states:
        <ul>
          <StyledLi>
            <span style={{ color: theme.palettes.grayscale.medium }}>
              Gray, normal font:
            </span>
            These are documents that you do not have access rights to edit.
          </StyledLi>
          <StyledLi>
            <span style={{ fontWeight: "bold" }}>Black, bolded font:</span>
            These are documents that you have access to edit.
          </StyledLi>
        </ul>
      </Paragraph>
      <Paragraph>
        The document icon color is consistent with the workflow color:
        <ul>
          <StyledLi>
            <StyledImg src={docDraft} />
            <span> - Draft</span>
          </StyledLi>
          <StyledLi>
            <StyledImg src={docPublished} />
            <span> - Published</span>
          </StyledLi>
          <StyledLi>
            <StyledImg src={docApproval} />
            <span> - In Approval</span>
          </StyledLi>
          <StyledLi>
            <StyledImg src={docArchived} />
            <span> - Archived</span>
          </StyledLi>
          <StyledLi>
            <StyledImg src={docDeleted} />
            <span> - Deleted</span>
          </StyledLi>
        </ul>
      </Paragraph>
    </StepContentDiv>
  );
}
