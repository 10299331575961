import {
  CategoryGroup,
  CategoryPath,
} from "../../../../models/documentDetails/documentMetadata";
import { CategoryGroupNameDiv as CategoryGroupNameDiv } from "./SC/BreadcrumbCategoryGroup";
import { CategoryGroupDiv as CategoryGroupDiv } from "./SC/CategoryGroupDiv";
import { CategoryControl } from "./CategoryControl";
import { useState, useEffect } from "react";
import { comparePaths } from "../../../../app/helpers/categoriesHelper";
import { cloneDeep } from "lodash";

interface CategoryGroupControlProps {
  categoryGroup: CategoryGroup;
}

export function CategoryGroupControl(props: CategoryGroupControlProps) {
  const [sortedCategories, setSortedCategories] = useState<CategoryPath[]>([]);

  useEffect(() => {
    if (props.categoryGroup.categories.length > 0) {
      const categories = cloneDeep(props.categoryGroup.categories);
      setSortedCategories(categories.sort(comparePaths));
    }
  }, [props.categoryGroup]);

  return (
    <CategoryGroupDiv id="details-categories-group-container">
      <CategoryGroupNameDiv>
        <span>{props.categoryGroup.topCategoryName}:</span>
      </CategoryGroupNameDiv>
      {sortedCategories.map((category, index) => {
        return (
          <CategoryControl
            key={index}
            categories={category.categoryPath}
            categoryId={category.categoryId}
          />
        );
      })}
    </CategoryGroupDiv>
  );
}
