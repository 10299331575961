import { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components";
import { useDocumentEditedMode } from "../../../../app/hooks/document/useDocumentEditedMode";
import { useDocumentFlow } from "../../../../app/hooks/document/useDocumentFlow";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { initialEditableState } from "../../../../app/hooks/permissions/models/state";
import { useUserCanEditDocument } from "../../../../app/hooks/permissions/useUserCanEditDocument";
import { selectIsLoadingDetailsMetaData } from "../../../../app/slices/documentDetailsSlice";
import {
  addEmptyTitle,
  clearEditedDocumentTitles,
  removeTitle,
  selectCurrentDocumentLanguages,
  selectCurrentDocumentTitles,
  selectLanguages,
  setEditedTitle,
} from "../../../../app/slices/documentMetadataSlice";
import {
  selectHasTitlesSectionAnyValidationErrors,
  selectIsPublishValidationOn,
  setTitlesValidationStatus,
} from "../../../../app/slices/documentMetadataValidationSlice";
import { SectionName } from "../../../../app/slices/models/documentDetailsModels";
import { CircularProgressBar } from "../../../../controls/ProgressIndicators/CircularProgressBar";
import { CurrentSelfHelp } from "../../../../models/CurrentSelfHelpType";
import { ValidationStatus } from "../../../../models/validationRule";
import { DetailsSection, DetailsSectionProps } from "../DetailsSection";
import { TitleNameControl } from "./TitleNameControl";

export function TitlesSection(props: DetailsSectionProps) {
  const titles = useAppSelector(selectCurrentDocumentTitles);
  const languages = useAppSelector(selectLanguages);
  const currentDocumentLanguages = useAppSelector(
    selectCurrentDocumentLanguages
  );
  const dispatch = useAppDispatch();
  const isLoadingDetailsMetaData = useAppSelector(
    selectIsLoadingDetailsMetaData
  );
  const theme = useTheme();
  const { titleSection } = useDocumentEditedMode();
  const hasValidationErrors = useAppSelector(
    selectHasTitlesSectionAnyValidationErrors
  );
  const [editableState, setEditableState] = useState(initialEditableState);
  const { canEditDocument } = useUserCanEditDocument();
  const [languagesWithErrors, setLanguagesWithErrors] = useState<string[]>([]);
  const [languagesWithNotVisibleErrors, setLanguagesWithNotVisibleErrors] =
    useState<string[]>([]);
  const [englishtTitleHasError, setEnglishTitleHasError] = useState(false);
  const [englishtTitleHasNotVisibleError, setEnglishTitleHasNotVisibleError] =
    useState(false);
  const { isNewDocument } = useDocumentFlow();
  const isPublishValidationOn = useAppSelector(selectIsPublishValidationOn);

  useEffect(() => {
    setEditableState(canEditDocument(false));
  }, [canEditDocument]);

  useEffect(() => {
    //do not change english title
    const nonEnglishTitlesIsoCodes = titles
      .filter((title) => title.languageIsoCode !== "en")
      .map((title) => title.languageIsoCode);

    const nonEnglishCurrentIsoCodes = currentDocumentLanguages.filter(
      (x) => x !== "en"
    );

    if (nonEnglishTitlesIsoCodes.length > nonEnglishCurrentIsoCodes.length) {
      const titlesToRemove = nonEnglishTitlesIsoCodes.filter(
        (title) => !currentDocumentLanguages.includes(title)
      );

      titlesToRemove.forEach((isoCode) => {
        dispatch(removeTitle(isoCode));
      });
    } else if (
      nonEnglishTitlesIsoCodes.length < nonEnglishCurrentIsoCodes.length
    ) {
      const titlesToAdd = currentDocumentLanguages.filter(
        (title) => !nonEnglishTitlesIsoCodes.includes(title)
      );

      titlesToAdd.forEach((isoCode) => {
        dispatch(addEmptyTitle(isoCode));
      });
    }
  }, [titles, currentDocumentLanguages, dispatch]);

  const joined = useMemo(() => {
    const joinedTitles = titles.map((title) => {
      const foundLanguage = languages.find(
        (language) => language.isoCode === title.languageIsoCode
      ) ?? { name: title.languageIsoCode };

      return { ...title, ...foundLanguage };
    });

    const sorted = joinedTitles.sort((a, b) => (a.name > b.name ? 1 : -1));
    const englishTitleIndex = sorted.findIndex(
      (title) => title.languageIsoCode.toLocaleLowerCase() === "en"
    );

    if (englishTitleIndex !== -1) {
      const title = sorted[englishTitleIndex];
      sorted.splice(englishTitleIndex, 1);
      sorted.splice(0, 0, title);
    }

    return sorted;
  }, [titles, languages]);

  const discardTitleChanges = useCallback(() => {
    dispatch(clearEditedDocumentTitles());
  }, [dispatch]);

  const onInputValidation = useCallback(
    (status: ValidationStatus, isoCode: string) => {
      const canValidateErrors = !isNewDocument || isPublishValidationOn;

      if (isoCode !== "en") {
        if (status === ValidationStatus.error && canValidateErrors) {
          setLanguagesWithErrors((prev) => prev.concat(isoCode));
        }

        if (status === ValidationStatus.notVisibleError && !canValidateErrors) {
          setLanguagesWithNotVisibleErrors((prev) => prev.concat(isoCode));
        }

        if (status === ValidationStatus.success) {
          setLanguagesWithErrors((prev) => prev.filter((x) => x !== isoCode));

          if (!canValidateErrors) {
            setLanguagesWithNotVisibleErrors((prev) =>
              prev.filter((x) => x !== isoCode)
            );
          }
        }
      } else {
        setEnglishTitleHasError(
          status === ValidationStatus.error && canValidateErrors
        );
        setEnglishTitleHasNotVisibleError(
          status === ValidationStatus.notVisibleError && !canValidateErrors
        );
      }

      if (canValidateErrors) {
        setLanguagesWithNotVisibleErrors([]);
        setEnglishTitleHasNotVisibleError(false);
      }
    },
    [isPublishValidationOn, isNewDocument]
  );

  useEffect(() => {
    const hasErrors = languagesWithErrors.length > 0 || englishtTitleHasError;
    const hasNotVisibleErrors =
      (languagesWithNotVisibleErrors.length > 0 ||
        englishtTitleHasNotVisibleError) &&
      !hasErrors;

    dispatch(
      setTitlesValidationStatus(
        hasErrors
          ? ValidationStatus.error
          : hasNotVisibleErrors
          ? ValidationStatus.notVisibleError
          : ValidationStatus.success
      )
    );
  }, [
    languagesWithErrors.length,
    englishtTitleHasError,
    dispatch,
    englishtTitleHasNotVisibleError,
    languagesWithNotVisibleErrors.length,
  ]);

  useEffect(() => {
    if (languagesWithErrors.length > 0 && !englishtTitleHasError) {
      const hasDeletedCodes = languagesWithErrors.every(
        (x) => !currentDocumentLanguages.includes(x)
      );

      if (hasDeletedCodes) {
        dispatch(setTitlesValidationStatus(ValidationStatus.success));
        setLanguagesWithErrors([]);
      }
    }
  }, [
    languagesWithErrors,
    currentDocumentLanguages,
    dispatch,
    englishtTitleHasError,
  ]);

  return (
    <DetailsSection
      {...props}
      section={SectionName.Titles}
      name="Titles"
      selfHelpType={CurrentSelfHelp.DetailsTitles}
      isValidationError={hasValidationErrors}
      isEdited={titleSection.titlesSectionIsEdited}
      isLoadingDetailsMetaData={isLoadingDetailsMetaData}
      onDiscardChanges={discardTitleChanges}
      isEditable={editableState.isEditable}
      notEditableReason={editableState.lockMessage}
    >
      {isLoadingDetailsMetaData ? (
        <CircularProgressBar
          size={theme.circularProgress.medium}
          space={theme.circularProgressWrapper.defaultHeight}
          color="secondary"
        />
      ) : (
        joined.map((title) => {
          return (
            <TitleNameControl
              key={title.languageIsoCode}
              id={title.languageIsoCode}
              labelText={title.name}
              inputValue={title.title}
              isEditable={editableState.isEditable}
              notEditableMessage={editableState.lockMessage}
              onChangeHandler={(newValue: string) => {
                dispatch(
                  setEditedTitle({
                    newValue: newValue,
                    isoCode: title.languageIsoCode,
                  })
                );
              }}
              isEdited={titleSection.editedTitles.includes(
                title.languageIsoCode + title.title
              )}
              onInputValidation={onInputValidation}
            />
          );
        })
      )}
    </DetailsSection>
  );
}
