import styled from "styled-components";

interface InputDivProps {
  paddingTop?: number;
}

export const InputDiv = styled.div<InputDivProps>`
  display: flex;
  flex-direction: column;
  padding-top: ${(props) =>
    props.paddingTop !== undefined ? props.paddingTop + "px" : "8px"};
`;
