import { DocumentsDiv } from "./SC/DocumentsDiv";
import { DocumentsList } from "./documentsList/DocumentsList";
import { DocumentsFooter } from "./documentsFooter/DocumentsFooter";
import { ContextMenuHeader } from "../header/ContextMenu/ContextMenuHeader";
import { useAppSelector } from "../../app/hooks/hooks";
import { selectIsDocumentListError } from "../../app/slices/documentListSlice";

export function Documents() {
  const isDocumentsListError = useAppSelector(selectIsDocumentListError);

  return (
    <DocumentsDiv id="documents-div">
      <ContextMenuHeader useLoader />
      <DocumentsList />
      {!isDocumentsListError && <DocumentsFooter />}
    </DocumentsDiv>
  );
}
