import ExternalSecurityLevel from "../../models/documentDetails/SecurityLevels/externalSecurityLevel";
import InternalSecurityLevel from "../../models/documentDetails/SecurityLevels/internalSecurityLevel";
import { DocumentSecurityLevel } from "../../models/documentSecurityLevel";
import {
  ExternalLimitedAndInternalLimited,
  ExternalPublicAndInternalPublic,
  HiddenFromExternalAndInternalPublic,
  ExternalLimitedAndInternalPublic,
  InternalPublic,
  InternalLimited,
  SecurityTypeInternal,
  SecurityTypePublic,
  SecurityTypePremium,
} from "../../models/securityLevels";

export const internalPublic = [
  DocumentSecurityLevel.HiddenFromExternalAndInternalPublic,
  DocumentSecurityLevel.ExternalLimitedAndInternalPublic,
  DocumentSecurityLevel.ExternalPublicAndInternalPublic,
  DocumentSecurityLevel.InternalPublic,
];

export const externalLimited = [
  DocumentSecurityLevel.ExternalLimitedAndInternalLimited,
  DocumentSecurityLevel.ExternalLimitedAndInternalPublic,
];

const nonLimitedSecurityLevels = [
  DocumentSecurityLevel.InternalPublic,
  DocumentSecurityLevel.ExternalPublicAndInternalPublic,
  DocumentSecurityLevel.HiddenFromExternalAndInternalPublic,
];

export const getDocumentSecurityLevelName = (
  securityLevelValue: DocumentSecurityLevel
) => {
  switch (securityLevelValue) {
    case DocumentSecurityLevel.ExternalLimitedAndInternalLimited:
      return ExternalLimitedAndInternalLimited;
    case DocumentSecurityLevel.ExternalPublicAndInternalPublic:
      return ExternalPublicAndInternalPublic;
    case DocumentSecurityLevel.HiddenFromExternalAndInternalPublic:
      return HiddenFromExternalAndInternalPublic;
    case DocumentSecurityLevel.ExternalLimitedAndInternalPublic:
      return ExternalLimitedAndInternalPublic;
    case DocumentSecurityLevel.InternalPublic:
      return InternalPublic;
    case DocumentSecurityLevel.InternalLimited:
      return InternalLimited;
  }
};

export const getSecurityType = (securityLevel: DocumentSecurityLevel) => {
  if (
    securityLevel === DocumentSecurityLevel.ExternalPublicAndInternalPublic ||
    securityLevel === DocumentSecurityLevel.HiddenFromExternalAndInternalPublic
  ) {
    return SecurityTypePublic;
  } else if (
    securityLevel === DocumentSecurityLevel.ExternalLimitedAndInternalLimited ||
    securityLevel === DocumentSecurityLevel.ExternalLimitedAndInternalPublic
  ) {
    return SecurityTypePremium;
  }

  return SecurityTypeInternal;
};

export const getInternalSecurityLevel = (value: DocumentSecurityLevel) => {
  if (internalPublic.includes(value)) return InternalSecurityLevel.All;

  return InternalSecurityLevel.Limited;
};

export const getExternalSecurityLevel = (value: DocumentSecurityLevel) => {
  if (externalLimited.includes(value)) return ExternalSecurityLevel.Limited;

  if (value === DocumentSecurityLevel.HiddenFromExternalAndInternalPublic)
    return ExternalSecurityLevel.Hidden;

  if (value === DocumentSecurityLevel.ExternalPublicAndInternalPublic)
    return ExternalSecurityLevel.All;

  return ExternalSecurityLevel.None;
};

export const getDocumentSecurityLevel = (
  internal: InternalSecurityLevel,
  external: ExternalSecurityLevel
) => {
  if (internal === InternalSecurityLevel.All) {
    switch (external) {
      case ExternalSecurityLevel.All:
        return DocumentSecurityLevel.ExternalPublicAndInternalPublic;
      case ExternalSecurityLevel.Hidden:
        return DocumentSecurityLevel.HiddenFromExternalAndInternalPublic;
      case ExternalSecurityLevel.Limited:
        return DocumentSecurityLevel.ExternalLimitedAndInternalPublic;
      case ExternalSecurityLevel.None:
        return DocumentSecurityLevel.InternalPublic;
    }
  } else {
    switch (external) {
      case ExternalSecurityLevel.Limited:
        return DocumentSecurityLevel.ExternalLimitedAndInternalLimited;
      case ExternalSecurityLevel.None:
        return DocumentSecurityLevel.InternalLimited;
    }
  }
};

export const isSecurityLevelNonLimited = (
  level: DocumentSecurityLevel
): boolean => nonLimitedSecurityLevels.includes(level);

export const isSecurityLevelLimited = (level: DocumentSecurityLevel): boolean =>
  !nonLimitedSecurityLevels.includes(level);
