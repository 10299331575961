import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function EmailNoteStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        In this section, the scheduled action creator can add optional note that
        will be included in the automatic email that will be sent to recipients
        on the action date.{" "}
      </Paragraph>
    </StepContentDiv>
  );
}
