import { WordBreakType } from "../../../../../models/documentList/types/WordBreakType";
import { Workflow } from "../../../../../models/documentList/types/workflow";
import { EllipsisText } from "./EllipsisText";

export interface DateCellProps {
  date: Date;
  width: number;
  workflow: Workflow;
}

function dateToYMD(date: Date) {
  const d = date.getDate();
  const m = date.getMonth() + 1; //Month from 0 to 11
  const y = date.getFullYear();
  return y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
}

export function DateCell(props: DateCellProps) {
  let dateString = "";
  const d = new Date(props.date);

  if (
    props.workflow === Workflow.Draft ||
    props.workflow === Workflow.Approval
  ) {
    dateString = "[" + dateToYMD(d) + "]";
  } else {
    dateString = dateToYMD(d);
  }

  return (
    <EllipsisText
      useTooltip={true}
      width={props.width}
      fillIfEmpty={true}
      wordBreak={WordBreakType.BreakAll}
      content={dateString}
      $paddingLeft={5}
      $paddingRight={8}
      flexGrow={1}
      enableEllipsis={true}
    />
  );
}
