import { LabelSpan } from "../SC/LabelSpan";
import { Editable } from "../sections/common/Editable";
import { ToggleButtonSecurityLevel } from "../sections/security/SecurityLevelControl";
import {
  KeyValuePair,
  ToggleButtonsInput,
  ToggleButtonsInputHandler,
} from "./controls/ToggleButtonsInput";
import LabelIcon from "./LabelIcon";
import { LabelContainerDiv } from "./SC/LabelContainerDiv";
import { SectionFieldDiv } from "./SC/SectionFieldDiv";

interface ToggleButtonsProps extends Editable {
  id: string;
  labelText?: string;
  value: string;
  values: string[] | KeyValuePair[] | ToggleButtonSecurityLevel[];
  isEdited?: boolean;
  handleInputChange?: ToggleButtonsInputHandler;
  tooltipDisableMessage?: string;
}

export function ToggleButtons(props: ToggleButtonsProps) {
  return (
    <SectionFieldDiv id={props.id}>
      <LabelContainerDiv>
        {props.labelText && (
          <LabelSpan marginBottom={2}>{props.labelText}:</LabelSpan>
        )}
        {props.notEditableMessage && !props.isEditable && (
          <LabelIcon marginBottom={2} message={props.notEditableMessage} />
        )}
      </LabelContainerDiv>
      <ToggleButtonsInput
        id={props.id}
        value={props.value}
        values={props.values}
        handleInputChange={props.handleInputChange}
        isDisabled={!props.isEditable}
        isEdited={props.isEdited}
        tooltipDisableMessage={props.tooltipDisableMessage}
      />
    </SectionFieldDiv>
  );
}
