import styled from "styled-components";

export const CategoryTreeNavigation = styled.div`
  display: flex;
  flex-direction: column;
  order: 1;
  order: 0;
  margin: 0px 0px 0px 10px;
  height: calc(100% - 5px);
`;
