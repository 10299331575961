import styled from "styled-components";

export const TreeItemIconDiv = styled.div`
  display: flex;
  min-width: 32px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 12px;
`;
