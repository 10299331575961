import styled, { DefaultTheme } from "styled-components";
import { Typography } from "@mui/material";
import { Density } from "../../../../../app/slices/models/documentsModels";

function getHeight(density: Density, theme: DefaultTheme) {
  return theme.density.compactRowHeight;
}

export const GroupNameTypography = styled(Typography)`
  display: flex;
  font-size: ${(props) => props.theme.typography.fontSizes.medium}px !important;
  justify-content: center;
  align-items: center;
  & .MuiTypography-root {
    font-size: ${(props) => props.theme.typography.fontSizes.small}px;
    height: ${(props) => getHeight(Density.Compact, props.theme)}px;
  }
  font-style: normal;
`;
