import CloseIcon from "@mui/icons-material/Close";
import { Breakpoint, DialogActions, DialogContent } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks/hooks";
import { selectCurrentSplash } from "../../app/slices/commonSlice";
import { CurrentSelfHelp } from "../../models/CurrentSelfHelpType";
import { ScreenSplashEnum } from "../../models/screenSplash";
import { IconButtonControl } from "../Buttons/IconButtonControl";
import { DialogButton } from "./SC/DialogButton";
import { StyledDialog } from "./SC/StyledDialog";
import { StyledDialogTitle } from "./SC/StyledDialogTitle";
import { TooltipTop } from "../Tooltips/ToolipTop";
import { StyledDisabledTooltipMsg } from "../../components/documentsNavigation/filters/inputs/securityLevels/styledComponents/StyledDisabledTooltipMsg";
import { IconButtonControlDiv } from "../../components/details/contextMenu/SC/IconButtonControlDiv";
import { SelfHelpButtonControl } from "../Buttons/SelfHelpButtonControl";
import { SectionName } from "../../app/slices/models/documentDetailsModels";
import { SelfHelpPage } from "../../models/selfHelp/selfHelpSection";
import { TooltipMsg } from "../Tooltips/TooltipMessages";

interface DialogControlProps {
  id: string;
  isOpen: boolean;
  useActions: boolean;
  title: string;
  content: React.ReactNode;
  fullWidth?: boolean;
  fullHeight?: boolean;
  maxWidth: Breakpoint | false;
  onCloseClick: (e: React.MouseEvent) => void;
  onConfirmClick?: (e: React.MouseEvent) => void;
  onCancelClick?: (e: React.MouseEvent) => void;
  onExtraButtonClick?: (e: React.MouseEvent) => void;
  confirmText?: string;
  cancelText?: string;
  extraButtonText?: string;
  selfHelp?: CurrentSelfHelp;
  useSelfHelp?: boolean;
  padding?: string;
  showExtraButton?: boolean;
  hideCancelButton?: boolean;
  disabledConfirm?: boolean;
  disabledConfirmTooltipMsg?: string;
  margin?: string;
  selfHelpSection?: SectionName;
  selfHelpPage?: SelfHelpPage;
  hideConfirmButton?: boolean;
  disableButtonAutoFocus?: boolean;
}

export function DialogControl(props: DialogControlProps) {
  const [open, setOpen] = useState(props.isOpen);
  const currentSplashScreen = useAppSelector(selectCurrentSplash);
  const confirmText = props.confirmText ? props.confirmText : "Confirm";

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <StyledDialog
      id={props.id + "-dialog"}
      fullWidth={props.fullWidth}
      $fullHeight={props.fullHeight}
      maxWidth={props.maxWidth}
      open={open && currentSplashScreen === ScreenSplashEnum.None}
      onClose={props.onCloseClick}
      $padding={props.padding}
      $margin={props.margin}
      disableRestoreFocus={true}
    >
      <StyledDialogTitle id={props.id + "-alert-dialog-title"}>
        {props.title}
        <IconButtonControlDiv>
          {props.useSelfHelp && props.selfHelpPage && props.selfHelpSection && (
            <SelfHelpButtonControl
              id={props.id + "-self-help-dialog-button"}
              currentSelfHelp={
                props.selfHelp ? props.selfHelp : CurrentSelfHelp.None
              }
              page={props.selfHelpPage}
              section={props.selfHelpSection}
            />
          )}
          <IconButtonControl
            id={props.id + "-confirm-dialog-close-button"}
            popupText={TooltipMsg.Close}
            onClick={props.onCloseClick}
            icon={<CloseIcon fontSize="inherit" />}
          />
        </IconButtonControlDiv>
      </StyledDialogTitle>
      <DialogContent>{props.content}</DialogContent>
      {props.useActions && (
        <DialogActions id="dialog-actions">
          {!props.hideConfirmButton && (
            <DialogButton
              id={props.id + "-confirm-dialog-confirm-button"}
              color="primary"
              onClick={props.onConfirmClick}
              autoFocus={!props.disableButtonAutoFocus}
              disabled={props.disabledConfirm ?? false}
            >
              {props.disabledConfirm ? (
                <TooltipTop title={props.disabledConfirmTooltipMsg ?? ""}>
                  <StyledDisabledTooltipMsg>
                    {confirmText}
                  </StyledDisabledTooltipMsg>
                </TooltipTop>
              ) : (
                <>{confirmText}</>
              )}
            </DialogButton>
          )}
          {props.showExtraButton && (
            <DialogButton
              id={props.id + "-confirm-dialog-extra-button"}
              color="secondary"
              onClick={props.onExtraButtonClick}
            >
              {props.extraButtonText ? props.extraButtonText : "Publish Anyway"}
            </DialogButton>
          )}
          {!props.hideCancelButton && (
            <DialogButton
              id={props.id + "-confirm-dialog-cancel-button"}
              color="secondary"
              onClick={
                props.onCancelClick ? props.onCancelClick : props.onCloseClick
              }
            >
              {props.cancelText ? props.cancelText : "Cancel"}
            </DialogButton>
          )}
        </DialogActions>
      )}
    </StyledDialog>
  );
}
