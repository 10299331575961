import { IconButton } from "@mui/material";
import { useAppDispatch } from "../../../../app/hooks/hooks";
import { TooltipTop } from "../../../../controls/Tooltips/ToolipTop";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import { TagValue } from "../../../../models/tags/TagValue";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { removeTagValue, unRemoveTagValue } from "../../../../app/slices/tagsSlice";

export interface IconButtonProps {
    canEdit?: boolean;
    tagValue: TagValue;
}

export function TagValueIconButton(props: IconButtonProps) {
    const dispatch = useAppDispatch();

    const icon = props.tagValue.removed ? <RestoreFromTrashIcon fontSize="small" style={{ fontSize: "18px" }} /> : <DeleteIcon fontSize="small" style={{ fontSize: "18px" }} />;
    const tooltipTitle = props.tagValue.removed ? TooltipMsg.Restore : props.canEdit ? TooltipMsg.RemoveTag : TooltipMsg.CannotRemoveTag

    const removeButtonHandler = () => {
        dispatch(
            removeTagValue({
                tagValueId: props.tagValue.tagValueId,
                value: props.tagValue.value,
                countOfDocuments: props.tagValue.countOfDocuments,
            })
        );
    };

    const unRemoveButtonHandler = () => {
        dispatch(unRemoveTagValue(props.tagValue.value));
    };

    return <IconButton
        edge="end"
        aria-label="delete"
        disabled={!props.canEdit}
        onClick={props.tagValue.removed ? unRemoveButtonHandler : removeButtonHandler}
    >
        <TooltipTop title={tooltipTitle}>
            {icon}
        </TooltipTop>
    </IconButton>
}