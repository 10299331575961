import styled from "styled-components";

interface GroupsColumnDivProps {
  width: number;
  height: number;
}

export const GroupsColumnDiv = styled.div<GroupsColumnDivProps>`
  display: flex;
  flex-grow: 1;
  width: ${(props) => props.width}%;
  box-sizing: border-box;
  height: ${(props) => props.height}%;
`;
