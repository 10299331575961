import { useAppDispatch } from "../../../../../app/hooks/hooks";
import { setShouldRefreshDocumentCma } from "../../../../../app/slices/documentDetailsSlice";
import { removeEditedCategory } from "../../../../../app/slices/documentMetadataSlice";
import { TooltipTop } from "../../../../../controls/Tooltips/ToolipTop";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";
import { CategoryBreadCrumbDeleteIcon } from "../SC/CategoryBreadCrumbDeleteIcon";

interface DeleteCategoryButtonProps {
  isVisible: boolean;
  hasAccess: boolean;
  isMainCategory: boolean;
  isLatestRevision: boolean;
  categoryId: string;
  disabledTooltip?: string;
}

export function DeleteCategoryButton(props: DeleteCategoryButtonProps) {
  const dispatch = useAppDispatch();

  const handleRemoveCategory = () => {
    dispatch(removeEditedCategory(props.categoryId));
    dispatch(setShouldRefreshDocumentCma(true));
  };

  const tooltipText = () => {
    if (props.disabledTooltip) {
      return props.disabledTooltip;
    }

    if (!props.isLatestRevision) return TooltipMsg.NotLatestRevision;
    if (!props.hasAccess) return TooltipMsg.NoAccessToCategory;

    if (props.isMainCategory) {
      return TooltipMsg.CantDeleteMainCategory;
    }

    return TooltipMsg.DeleteCategory;
  };

  return (
    <TooltipTop title={tooltipText()}>
      <CategoryBreadCrumbDeleteIcon
        $isVisible={props.isVisible}
        disabled={!props.hasAccess || props.isMainCategory}
        onClick={
          props.hasAccess && !props.isMainCategory
            ? handleRemoveCategory
            : undefined
        }
      />
    </TooltipTop>
  );
}
