import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function DocumentListStep() {
  const theme = useTheme();

  return (
    <StepContentDiv>
      <Paragraph>
        The Document List displays documents based on the applied navigation and
        filters. By default, it shows all documents in the{" "}
        <span style={{ color: theme.palettes.secondary.published }}>
          Published
        </span>
        ,{" "}
        <span style={{ color: theme.palettes.secondary.approval }}>
          In Approval
        </span>
        , or{" "}
        <span style={{ color: theme.palettes.secondary.draft }}>Draft</span>{" "}
        workflow statuses.
      </Paragraph>
    </StepContentDiv>
  );
}
