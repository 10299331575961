import { filter, forEach, map, max } from "lodash";
import { DocumentsColumnType } from "../../models/documentList/types/DocumentColumnType";
import {
  DocumentsColumn,
  defaultDocumentColumns,
} from "../slices/models/documentsModels";

export const resizeColumnsInternal = (
  documentColumns: DocumentsColumn[],
  columnType: DocumentsColumnType,
  resizeValue: number
) => {
  const resizedColumn = documentColumns.first((x) => x.type === columnType);
  let leftOverResizeValue = Math.abs(resizeValue);
  let currentColumnType = resizedColumn.type;
  let columnToDownSize: DocumentsColumn | null = resizedColumn;
  let columnToUpSize: DocumentsColumn | null = resizedColumn;
  if (resizeValue < 0) {
    // resizing column down, extra space should be taken from resized column and then columns to the left
    columnToUpSize = getNextRightColumn(documentColumns, currentColumnType);
    if (columnToUpSize === null) {
      return;
    }
  } else if (resizeValue > 0) {
    // resizing column up, extra space should be taken from columns to the right
    columnToDownSize = getNextRightColumn(documentColumns, currentColumnType);
    if (columnToDownSize === null) {
      // no more column available to take space from
      return;
    }
  }
  while (leftOverResizeValue > 0) {
    const availableSpaceToDownsize =
      columnToDownSize.size - columnToDownSize.minSize;
    const resizeValueToDownsize = Math.min(
      availableSpaceToDownsize,
      leftOverResizeValue
    );

    columnToDownSize.size -= resizeValueToDownsize;
    columnToUpSize.size += resizeValueToDownsize;

    let nextColumnToDownSize: DocumentsColumn | null = null;
    if (resizeValue < 0) {
      nextColumnToDownSize = getNextLeftColumn(
        documentColumns,
        currentColumnType
      );
      if (nextColumnToDownSize === null) {
        break;
      }
    } else {
      nextColumnToDownSize = getNextRightColumn(
        documentColumns,
        currentColumnType
      );
      if (nextColumnToDownSize === null) {
        break;
      }
    }

    columnToDownSize = nextColumnToDownSize;
    currentColumnType = nextColumnToDownSize.type;
    leftOverResizeValue -= resizeValueToDownsize;
  }
};

export const selectFullRowWidth = (documentColumns: DocumentsColumn[]) => {
  const visibleColumns = filter(documentColumns, (column) => {
    return column.isVisible;
  });

  let width = 0;
  forEach(visibleColumns, (column) => {
    width += column.size;
  });

  return width;
};

export const selectTitleRowWidthForGroup = (
  documentColumns: DocumentsColumn[]
) => {
  const fullRowWidth = selectFullRowWidth(documentColumns);
  return (
    fullRowWidth -
    documentColumns.first((x) => x.type === DocumentsColumnType.DocumentNumber)
      .size -
    documentColumns.first((x) => x.type === DocumentsColumnType.Options).size -
    documentColumns.first((x) => x.type === DocumentsColumnType.Chevron).size
  );
};

export const getNextRightColumn = (
  documentColumns: DocumentsColumn[],
  type: DocumentsColumnType
) => {
  const resultIndex = documentColumns.findIndex((value) => value.type === type);

  if (resultIndex > -1) {
    for (let idx = resultIndex + 1; idx < documentColumns.length; idx++) {
      if (documentColumns[idx].isVisible) {
        return documentColumns[idx];
      }
    }
  }
  return null;
};

export const getNextLeftColumn = (
  documentColumns: DocumentsColumn[],
  type: DocumentsColumnType
) => {
  const resultIndex = documentColumns.findIndex((value) => value.type === type);

  if (resultIndex > 0) {
    for (let idx = resultIndex - 1; idx >= 0; idx--) {
      if (documentColumns[idx].isVisible) {
        return documentColumns[idx];
      }
    }
  }
  return null;
};

export const getMinColumnWidth = (type: DocumentsColumnType) => {
  return defaultDocumentColumns.first((x) => x.type === type).minSize;
};

export const getDefaultColumnWidth = (type: DocumentsColumnType) => {
  return defaultDocumentColumns.first((x) => x.type === type).defaultSize;
};

export const getMaxWidthColumn = (columns: DocumentsColumn[]) => {
  const columnSizes = map(columns, (column) => {
    return column.size;
  });
  const maxColumnSizeWidth = max(columnSizes);
  return columns.first((x) => x.size === maxColumnSizeWidth);
};
