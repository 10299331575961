import styled from "styled-components";

interface AutocompleteInputDivProps {
  $paddingTop: number;
}

export const AutocompleteInputDiv = styled.div<AutocompleteInputDivProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: ${(props) => props.$paddingTop}px;
`;
