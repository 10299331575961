import styled from "styled-components";

interface AutoCompleteLabelSpanParams {
  float?: string;
  marginBottom?: number;
  clickable?: boolean;
}

export const AutoCompleteLabelSpan = styled.span<AutoCompleteLabelSpanParams>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  color: ${(props) => props.theme.palettes.grayscale.dark};
  float: ${(props) => (props.float ? props.float : "none")};
  margin-right: 8px;
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
  margin-bottom: ${(props) =>
    props.marginBottom ? `${props.marginBottom}px` : "0px"};
  font-size: ${(props) => props.theme.typography.fontSizes.medium + "px"};
`;
