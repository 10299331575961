import { createStep } from "../../helpers/selfHelpBuilder";

export const securityLevelSteps = () => {
  return [
    createStep(
      "#security-levels-toggle-button-group",
      "placeholder",
      320,
      "right-end"
    ),
  ];
};
