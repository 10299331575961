import Accordion from "@mui/material/Accordion";
import styled, { DefaultTheme } from "styled-components";

interface StyledAccordionProps {
  $isGray: boolean;
  $zebraStripe: boolean;
  $useBorder: boolean;
}

function getColor(isGray: boolean, theme: DefaultTheme) {
  if (isGray) {
    return theme.palettes.grayscale.medium;
  }
  return theme.palettes.grayscale.darkest;
}

function getBackgroundColor(zebraStripe: boolean, theme: DefaultTheme) {
  if (zebraStripe) {
    return theme.palettes.grayscale.white;
  }
  return theme.palettes.grayscale.lightest;
}

export const StyledAccordion = styled(Accordion)<StyledAccordionProps>`
  position: unset !important;
  border-bottom: ${(props) =>
    props.$useBorder ? "1px solid " + props.theme.palettes.grayscale.dark : 0};
  color: ${(props) => getColor(props.$isGray, props.theme)} !important;
  background-color: ${(props) =>
    getBackgroundColor(props.$zebraStripe, props.theme)} !important;
  box-shadow: unset !important;
`;
