import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { commonLocalStorageKey } from "../hooks/localStorage";
import { RootState } from "../store";
import { ScreenSplashEnum } from "../../models/screenSplash";
import { Environment } from "../../models/environment";

const defaultCommonParams: CommonParams = {
  currentSplash: ScreenSplashEnum.None,
  isWindowTooSmall: false,
  userAgreedForTooSmallWindow: false,
  environment: Environment.Development,
};

export interface CommonParams {
  currentSplash: ScreenSplashEnum;
  isWindowTooSmall: boolean;
  userAgreedForTooSmallWindow: boolean;
  environment: Environment;
}
export interface CommonState {
  currentSplash: ScreenSplashEnum;
  isWindowTooSmall: boolean;
  userAgreedForTooSmallWindow: boolean;
  environment: Environment;
}

export function getInitialCommonParams() {
  try {
    const value = localStorage.getItem(commonLocalStorageKey as string);
    if (value) {
      return JSON.parse(value) as CommonParams;
    }
    return defaultCommonParams;
  } catch {
    return defaultCommonParams;
  }
}

const initialState: CommonState = {
  ...getInitialCommonParams(),
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setCurrentSplash(
      state: CommonState,
      action: PayloadAction<ScreenSplashEnum>
    ) {
      state.currentSplash = action.payload;
    },
    setUserAgreedForTooSmallWindow(
      state: CommonState,
      action: PayloadAction<boolean>
    ) {
      state.userAgreedForTooSmallWindow = action.payload;
    },
    setIsWindowTooSmall(state: CommonState, action: PayloadAction<boolean>) {
      state.isWindowTooSmall = action.payload;
    },
    setEnvironment(state: CommonState, action: PayloadAction<Environment>) {
      state.environment = action.payload;
    },
  },
});

export const {
  setCurrentSplash,
  setIsWindowTooSmall,
  setUserAgreedForTooSmallWindow,
  setEnvironment
} = commonSlice.actions;

export const selectCurrentSplash = (state: RootState) =>
  state.common.currentSplash;

export const selectIsWindowTooSmall = (state: RootState) =>
  state.common.isWindowTooSmall;

export const selectUserAgreedForTooSmallWindow = (state: RootState) =>
  state.common.userAgreedForTooSmallWindow;

export const selectEnvironment = (state: RootState) =>
  state.common.environment;

export default commonSlice.reducer;
