import { createStep } from "../../helpers/selfHelpBuilder";
import { DocumentKindFirstStep } from "./Description/DocumentKindFirstStep";
import { DocumentKindSecondStep } from "./Description/DocumentKindSecondStep";
import { SummarySecondStep } from "./Description/SummarySecondStep";
import { SummaryStep } from "./Description/SummaryStep";

export const descriptionSteps = () => {
  return [
    createStep("#document-kind", <DocumentKindFirstStep />, 520, "auto"),
    createStep("#document-kind", <DocumentKindSecondStep />, 720, "auto"),
    createStep("#details-description-summary", <SummaryStep />, 720, "auto"),
    createStep("#details-description-summary", <SummarySecondStep />, 720, "auto"),
  ];
};
