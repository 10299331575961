import { Tabs } from "@mui/material";
import styled from "styled-components";

export const HeaderTabs = styled(Tabs)`
  background-color: "tertiary.main";
  margin-left: "auto";

  .MuiTab-root {
    color: ${(props) => props.theme.palettes.grayscale.medium};
  }

  .Mui-selected {
    color: ${(props) => props.theme.palettes.primary.dark} !important;
  }
`;
