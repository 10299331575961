import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionMoveStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        Section can be moved by using drag and drop to adjust their position.
      </Paragraph>
    </StepContentDiv>
  );
}
