import {
  useCompleteAttachmentUploadMutation,
  useCreateAttachmentMutation,
  useUploadChunkMutation,
} from "../fileUploadApi";

export function useAttachmentUpload() {
  const [createAttachment] = useCreateAttachmentMutation();
  const [uploadChunk] = useUploadChunkMutation();
  const [completeAttachmentUpload] = useCompleteAttachmentUploadMutation();

  return {
    createAttachment,
    uploadChunk,
    completeAttachmentUpload,
  };
}
