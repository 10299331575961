import styled from "styled-components";

interface ItemAvatarDivProps {
  $isSelected: boolean;
}

export const ItemAvatarDiv = styled.div<ItemAvatarDivProps>`
  display: flex;
  background-color: ${(props) =>
    props.$isSelected ? props.theme.palettes.grayscale.light : "unset"};
  border-left: ${(props) =>
    props.$isSelected
      ? "2px solid " + props.theme.palettes.primary.main
      : "2px solid transparent"};

  :hover {
    background-color: ${(props) => props.theme.palettes.grayscale.lighter};
  }
`;
