import styled from "styled-components";

export const TagsDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: calc(100% - 24px);
  max-height: calc(100% - 24px);
`;
