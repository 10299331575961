import { Avatar } from "@mui/material";
import styled from "styled-components";

export const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    margin-right: 12px;
    margin-left: 12px;
    background-color: ${(props) => props.theme.palettes.secondary.info};
  }
`;
