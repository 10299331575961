import { useMemo } from "react";
import { FormHelperControl } from "../FormHelper/FormHelperControl";

interface ValidationControlProps {
  isError?: boolean;
  isWarning?: boolean;
  isMissingValueWarning?: boolean;
  isDataQualityWarning?: boolean;
  isDictionaryDataFetchingError?: boolean;
  errorMessages?: string[];
  warningMessage?: string;
  missingValueWarningText?: string;
  dataQualityWarningText?: string;
}

export function ValidationControl(props: ValidationControlProps) {
  const warningTexts = useMemo(() => {
    const warningTexts: string[] = [];

    if (props.isWarning && props.warningMessage) {
      warningTexts.push(props.warningMessage);
      //This warning is most important
      return warningTexts;
    }

    if (props.isMissingValueWarning && props.missingValueWarningText) {
      warningTexts.push(props.missingValueWarningText);
    }

    if (props.isDataQualityWarning && props.dataQualityWarningText) {
      warningTexts.push(props.dataQualityWarningText);
    }

    return warningTexts;
  }, [
    props.dataQualityWarningText,
    props.isDataQualityWarning,
    props.isMissingValueWarning,
    props.isWarning,
    props.missingValueWarningText,
    props.warningMessage,
  ]);

  if (!props.isDictionaryDataFetchingError) {
    if (props.isError) {
      return (
        <FormHelperControl
          isWarning={false}
          isError={true}
          texts={props.errorMessages ? props.errorMessages : []}
        />
      );
    }

    if (
      props.isMissingValueWarning ||
      props.isDataQualityWarning ||
      props.isWarning
    ) {
      return (
        <FormHelperControl
          isWarning={true}
          isError={false}
          texts={warningTexts}
        />
      );
    }
  }

  return <></>;
}
