import styled from "styled-components";

interface ToggleDivProps {
  $isMinimized: boolean;
}

export const ToggleDiv = styled.div<ToggleDivProps>`
  display: ${(props) => (props.$isMinimized ? "flex" : "none")};
  height: 100%;
  border-right: 1px solid ${(props) => props.theme.palettes.grayscale.medium};
  background-color: ${(props) => props.theme.palettes.grayscale.lighter};
  color: ${(props) => props.theme.palettes.grayscale.dark};
  align-items: start;
  justify-content: center;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.palettes.grayscale.light};
  }
`;
