import { createApi } from "@reduxjs/toolkit/query/react";
import {
  AttachmentUploadInfo,
  CompletedAttachmentInfo,
} from "../models/documentDetails/documentAttachment";
import { publishApiBaseQuery } from "./baseQueries";
import { AttachmentRequest, UploadRequest } from "./fileUploadApi";

export const fileStatusApi = createApi({
  reducerPath: "fileStatusApi",
  baseQuery: publishApiBaseQuery,
  endpoints: (builder) => ({
    attachmentUploadInfo: builder.mutation<AttachmentUploadInfo, UploadRequest>(
      {
        query: ({ uploadId }) => ({
          url: `Attachments/Upload/${uploadId}`,
          method: "GET",
        }),
      }
    ),
    completedAttachmentInfo: builder.mutation<
      CompletedAttachmentInfo,
      AttachmentRequest
    >({
      query: ({ attachmentId }) => ({
        url: `Attachments/${attachmentId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAttachmentUploadInfoMutation,
  useCompletedAttachmentInfoMutation,
} = fileStatusApi;

export default fileStatusApi;
