import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                Tags are optional values that can be utilized to enhance document metadata with business-specific attributes.
            </Paragraph>
            <Paragraph>
                These tags have predefined types and values, which are accessible depending on the selected Main Category.
            </Paragraph>
            <Paragraph>
                Other applications can make use of these tags through the ABB Library API.
            </Paragraph>
            <Paragraph>
                In the future, there is a possibility that these tags will be displayed in the Download Center or utilized as filters in the Download Center or Download Section widget.
            </Paragraph>
        </StepContentDiv>
    );
}
