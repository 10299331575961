import styled from "styled-components";

export const AutocompleteInputDiv = styled.div`
  margin: 0 0 0 auto;
  padding: 0;
  width: 25%;
  max-width: 420px;
  min-width: 200px;

  @media only screen and (max-width: 800px) {
    display: none;
  }
`;
