import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStep3() {
  const theme = useTheme();

  return (
    <StepContentDiv>
      <Paragraph>
        Categories can have two states:
        <ul>
          <li>
            <span style={{ color: theme.palettes.grayscale.medium }}>
              Gray, with a normal font
            </span>{" "} - Categories for which current user do not have Owner access to edit.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>
              Black, with a bolded font
            </span>{" "} - Categories that current user can edit.
          </li>
        </ul>
      </Paragraph>
    </StepContentDiv>
  );
}
