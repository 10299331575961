import { Moment } from "moment";
import { useState } from "react";
import { StyledDatePicker } from "./SC/StyledDatePicker";
import { StyledTextField } from "../TextField/SC/StyledTextField";
import ClearIcon from "@mui/icons-material/Clear";
import { CalendarIcon } from "./Icons/CalendarIcon";
import moment from "moment";
import { FormHelperControl } from "../FormHelper/FormHelperControl";
import { InputDiv } from "../StyledComponents/InputDiv";
import { LeftDatePickerIcon } from "./Icons/LeftDatePickerIcon";
import { RightDatePickerIcon } from "./Icons/RightDatePickerIcon";
import { TooltipTop } from "../Tooltips/ToolipTop";
import { StyledIconButton } from "./SC/StyledIconButton";
import { TooltipMsg } from "../Tooltips/TooltipMessages";

interface TextFieldInputProps {
  id: string;
  label: string;
  minDate?: Moment | null;
  maxDate?: Moment | null;
  dateFormat: string;
  value: Moment | null;
  onClick: (date: Moment | null) => void;
}

export function DatePickerInput(props: TextFieldInputProps) {
  const [isError, setIsError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>("");

  const handleChange = (newValue: Moment | null) => {
    if (newValue && !newValue.isValid()) {
      setIsError(true);
      setHelperText("Date is invalid");
      return;
    }

    if (newValue?.isAfter(moment())) {
      setIsError(true);
      setHelperText("Date is in the future");
      return;
    }

    if (props.minDate) {
      if (newValue?.isBefore(props.minDate)) {
        setIsError(true);
        setHelperText("Date is later than 'After date'");
        return;
      }
    }

    if (props.maxDate) {
      if (newValue?.isAfter(props.maxDate)) {
        setIsError(true);
        setHelperText("Date is earlier than 'Before date'");
        return;
      }
    }

    setIsError(false);
    setHelperText("");
    props.onClick(newValue);
  };

  const handleClearPress = () => {
    props.onClick(null);
  };

  return (
    <StyledDatePicker
      $isEditable={true}
      label={props.label}
      value={props.value}
      inputFormat={props.dateFormat}
      minDate={props.minDate}
      maxDate={props.maxDate}
      onChange={(newValue) => {
        handleChange(newValue as Moment);
      }}
      components={{
        OpenPickerIcon: CalendarIcon,
        LeftArrowIcon: LeftDatePickerIcon,
        RightArrowIcon: RightDatePickerIcon,
      }}
      InputProps={{
        startAdornment: (
          <TooltipTop title={TooltipMsg.Clear}>
            <StyledIconButton
              onClick={handleClearPress}
              padding={1}
              $marginLeft={0}
              $marginRight={0}
              $visibility={props.value}
            >
              <ClearIcon fontSize="inherit" />
            </StyledIconButton>
          </TooltipTop>
        ),
      }}
      InputAdornmentProps={{
        position: "end",
        style: { order: 2, marginLeft: 0 },
      }}
      renderInput={(params) => {
        return (
          <InputDiv>
            <StyledTextField
              $isEditable={true}
              id={props.id + "-input"}
              error={isError}
              padding={12}
              size="small"
              {...params}
            />
            <FormHelperControl
              isWarning={false}
              isError={isError}
              texts={[helperText]}
            />
          </InputDiv>
        );
      }}
    />
  );
}
