import { useCallback, useEffect, useState } from "react";
import {
  setSplitViewInLocalStorage,
  setNavigationSplitViewInLocalStorage,
} from "../../app/hooks/localStorage";
import {
  setNavigationWidth,
  setContentWidth,
  setIsResizing,
  selectContentWidth,
  selectFitToScreen,
  selectNavigationWidth,
} from "../../app/slices/contentSlice";
import {
  windowMinWidth,
  navigationDefaultWidth,
  navigationMinWidth,
} from "../../app/slices/models/contentModels";
import { contentMinWidth } from "../../app/slices/models/documentsModels";
import { HandlerProps } from "../../controls/Reflex";
import { ScreenSplashEnum } from "../../models/screenSplash";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import useWindowDimensions from "../../app/hooks/useWindowDimensions";
import {
  selectCurrentSplash,
  selectIsWindowTooSmall,
} from "../../app/slices/commonSlice";
import {
  selectIsNavigationMinimized,
  selectFiltersFlex,
} from "../../app/slices/navigationSlice";

export const minimizedSize = 20;

export function useNavigationResize() {
  const dispatch = useAppDispatch();
  const { windowWidth } = useWindowDimensions();
  const navigationWidth = useAppSelector(selectNavigationWidth);
  const contentWidth = useAppSelector(selectContentWidth);
  const fitToScreen = useAppSelector(selectFitToScreen);
  const currentSplashScreen = useAppSelector(selectCurrentSplash);
  const isMinimizedByUser = useAppSelector(selectIsNavigationMinimized);
  const isWindowTooSmall = useAppSelector(selectIsWindowTooSmall);
  const [maxNavigationWidth, setMaxNavigationWidth] = useState(
    windowWidth - contentMinWidth
  );
  const filtersFlex = useAppSelector(selectFiltersFlex);
  const [isMinimized, setIsMinimized] = useState(false);

  const setNavigationDefaultSize = useCallback(() => {
    if (windowWidth >= windowMinWidth) {
      dispatch(setNavigationWidth(navigationDefaultWidth));
    } else {
      dispatch(setNavigationWidth(windowWidth - contentMinWidth));
    }

    setMaxNavigationWidth(windowWidth - contentMinWidth);
  }, [windowWidth, dispatch]);

  const setNavigationMinimizedSize = useCallback(() => {
    dispatch(setNavigationWidth(minimizedSize));
  }, [dispatch]);

  useEffect(() => {
    setIsMinimized(isMinimizedByUser || isWindowTooSmall);
  }, [isMinimizedByUser, isWindowTooSmall]);

  useEffect(() => {
    if (currentSplashScreen === ScreenSplashEnum.None) {
      if (!isMinimized) {
        setNavigationDefaultSize();
      } else {
        setNavigationMinimizedSize();
      }
    }
  }, [
    currentSplashScreen,
    isMinimized,
    setNavigationDefaultSize,
    setNavigationMinimizedSize,
  ]);

  useEffect(() => {
    dispatch(setContentWidth(windowWidth - navigationWidth));
  }, [navigationWidth, windowWidth, dispatch]);

  useEffect(() => {
    setSplitViewInLocalStorage({
      navigationWidth: navigationWidth,
      contentWidth: contentWidth,
      fitToScreen: fitToScreen,
    });
  }, [navigationWidth, contentWidth, fitToScreen]);

  useEffect(() => {
    setNavigationSplitViewInLocalStorage({
      filtersFlex: filtersFlex,
      isMinimized: isMinimizedByUser,
    });
  }, [filtersFlex, isMinimizedByUser]);

  const onNavigationStopResize = (args: HandlerProps) => {
    const el = args.domElement as Element;
    if (windowWidth < windowMinWidth) {
      return;
    }

    if (el.clientWidth > navigationMinWidth) {
      dispatch(setNavigationWidth(el.clientWidth));
    } else {
      dispatch(setNavigationWidth(navigationMinWidth));
    }
  };

  const onStartResize = () => {
    dispatch(setIsResizing(true));
  };

  const onStopResize = () => {
    dispatch(setIsResizing(false));
  };

  return {
    isMinimized,
    maxNavigationWidth,
    onStartResize,
    onStopResize,
    onNavigationStopResize,
    navigationWidth,
    contentWidth,
  };
}
