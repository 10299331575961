import { Divider } from "@mui/material";
import { useAppSelector } from "../../../../app/hooks/hooks";
import { DocumentsListHeaderCell } from "./DocumentsListHeaderCell";
import { DocumentsListHeaderDiv } from "./SC/DocumentsListHeaderDiv";
import { DocumentsListHeaderCellsDiv } from "./SC/DocumentsListHeaderCellsDiv";
import {
  selectIsResizing,
  selectFitToScreen,
} from "../../../../app/slices/contentSlice";
import {
  selectDocumentColumn,
  selectColumnWidth,
} from "../../../../app/slices/documentListViewSlice";

export function DocumentsListHeader() {
  const documentColumn = useAppSelector(selectDocumentColumn);
  const isResizing = useAppSelector(selectIsResizing);
  const fitToScreen = useAppSelector(selectFitToScreen);
  const allColumnsWidth = useAppSelector(selectColumnWidth);
  if (isResizing && fitToScreen) {
    return (
      <DocumentsListHeaderDiv id="documents-list-header-div">
        <DocumentsListHeaderCellsDiv />
        <Divider variant="fullWidth" />
      </DocumentsListHeaderDiv>
    );
  } else {
    return (
      <DocumentsListHeaderDiv id="documents-list-header-div">
        <DocumentsListHeaderCellsDiv width={allColumnsWidth}>
          {documentColumn.map((column, index) => (
            <DocumentsListHeaderCell
              key={"documents-list-header-" + index}
              isResizable={column.isResizable}
              isSortable={column.isSortable}
              isVisible={column.isVisible}
              width={column.size}
              minWidth={column.minSize}
              type={column.type}
              name={column.title}
              fullName={column.fullTitle}
            />
          ))}
        </DocumentsListHeaderCellsDiv>
        <Divider variant="fullWidth" />
      </DocumentsListHeaderDiv>
    );
  }
}
