import styled, { DefaultTheme } from "styled-components";

interface TreeItemSpanProps {
  hasAccess: boolean;
}

function getColor(hasAccess: boolean, theme: DefaultTheme) {
  if (!hasAccess) {
    return theme.palettes.grayscale.medium;
  }

  return theme.palettes.grayscale.darkest;
}

export const TreeItemSpan = styled.span<TreeItemSpanProps>`
  display: flex;
  flex-grow: 1;
  color: ${(props) => getColor(props.hasAccess, props.theme)};
  cursor: pointer;
`;
