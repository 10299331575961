import { useAppSelector } from "../../../app/hooks/hooks";
import { PermissionsEditableState } from "../../../app/hooks/permissions/useUserPermissionsInDocument";
import { selectIsWindowTooSmall } from "../../../app/slices/commonSlice";
import { selectIsChangingWorkflow } from "../../../app/slices/documentDetailsSlice";
import { selectDocumentStatus, selectDocumentWorkflow, selectHasOtherRevisionWithPendingApproval } from "../../../app/slices/documentMetadataSlice";
import { ScheduledActionButton } from "./buttons/actions/ScheduledActionButton";
import { CreateButton } from "./buttons/CreateButton";
import { GoToLatestRevisionButton } from "./buttons/GoToLatestRevisionButton";
import { SetStateButton } from "./buttons/SetStateButton";
import { ShareUrlButton } from "./buttons/ShareURLButton";

export interface ArchivedDocumentButtonsProps {
  hasPermissionToEditDocument: PermissionsEditableState;
}

export function ArchivedDocumentButtons(props: ArchivedDocumentButtonsProps) {
  const isWindowToSmall = useAppSelector(selectIsWindowTooSmall);
  const documentStatus = useAppSelector(selectDocumentStatus);
  const workflow = useAppSelector(selectDocumentWorkflow);
  const isChangingWorkflow = useAppSelector(selectIsChangingWorkflow);
  const hasOtherRevisionWithPendingApproval = useAppSelector(
    selectHasOtherRevisionWithPendingApproval
  );
  return (
    <>
      <CreateButton
        disabled={!props.hasPermissionToEditDocument.isEditable}
        isCompactView={isWindowToSmall}
        hasPendingApprovals={hasOtherRevisionWithPendingApproval}
      />
      <ShareUrlButton isCompactView={isWindowToSmall} />
      <SetStateButton
        disabled={isChangingWorkflow}
        workflow={workflow}
        isCompactView={isWindowToSmall}
      />
      <ScheduledActionButton />
      {!documentStatus.isLatest && (
        <GoToLatestRevisionButton
          latestRevision={documentStatus.latestRevision}
          isCompactView={isWindowToSmall}
        />
      )}
    </>
  );
}