import styled from "styled-components";

interface ErrorDescriptionDivProps {
  $textAlign?: "left" | "center";
}

export const ErrorDescriptionDiv = styled.div<ErrorDescriptionDivProps>`
  font-size: ${(props) =>
    props.theme.typography.fontSizes.splashScreenMessage}px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: ${(props) => (props.$textAlign ? props.$textAlign : "left")};
`;
