import PersonIcon from "@mui/icons-material/Person";
import { Avatar } from "@mui/material";
import { useAppDispatch } from "../../../../../../app/hooks/hooks";
import {
  setPublishersTabType,
  setSelectedPublisher,
} from "../../../../../../app/slices/publishersViewSlice";
import { StyledListItem } from "./SC/StyledListItem";
import { StyledListItemAvatar } from "./SC/StyledListItemAvatar";
import { StyledListItemText } from "./SC/StyledListItemText";
import { PublishersTabType } from "../../../../Publishers";

interface ItemAvatarProps {
  email: string;
  name: string;
}

export function PublisherInfo(props: ItemAvatarProps) {
  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(
      setSelectedPublisher({
        name: props.name,
        email: props.email,
      })
    );
    dispatch(setPublishersTabType(PublishersTabType.Publisher));
  };

  return (
    <StyledListItem dense onClick={onClick} $clickable={true}>
      <StyledListItemAvatar>
        <Avatar sx={{ width: 28, height: 28 }}>
          <PersonIcon fontSize="small" />
        </Avatar>
      </StyledListItemAvatar>
      <StyledListItemText primary={props.email} secondary={props.name} />
    </StyledListItem>
  );
}
