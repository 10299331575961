import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { ContextMenuStepProps } from "./models";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export function SetStateStep(props: ContextMenuStepProps) {
    const theme = useTheme();

    return (
        <StepContentDiv>
            <Paragraph>
                {!props.isVisible && <><ArrowDropDownIcon style={{
                    fontSize: "16px",
                    verticalAlign: "middle",
                    color: theme.palettes.grayscale.dark
                }} /><b> SET STATE</b> - </>}
                The &quot;Set State&quot; button is utilized to modify the workflow of a document. However, not all changes are permitted.
                Here are the actions associated with the &quot;Set State&quot; button:

                <ul>
                    <li>Publish - This action changes the document status to &quot;Published&quot; and makes it accessible in search interfaces such as Download Center and Download Section. Users can download the document from these interfaces.</li>
                    <li>Archive - This action changes the document status to &quot;Archived&quot;. In the Download Section, the document is hidden, but in the Download Center, it is visible in the document details if there is any published version. Users can still download the document.</li>
                    <li>Delete - This action changes the document status to &quot;Deleted&quot; and removes it from ABB Library search interfaces. The document is no longer available for download. By default, after 90 days, the document is permanently deleted from the library.</li>
                </ul>

                Please note that the availability of these actions may vary depending on the document&apos;s current state and user permissions.
            </Paragraph>
        </StepContentDiv>
    );
}
