import HelpIcon from "@mui/icons-material/Help";
import styled, { DefaultTheme } from "styled-components";

interface HelpIconProps {
  $colorNew: "red" | "blue" | "grey";
  $cursor?: string;
  $marginBottom?: number;
}

function getColor(theme: DefaultTheme, color: string) {
  if (color === "red") return theme.palettes.primary.main;
  if (color === "blue") return theme.palettes.secondary.info;
  return theme.palettes.grayscale.dark;
}

export const StyledHelpIcon = styled(HelpIcon)<HelpIconProps>`
  color: ${(props) => getColor(props.theme, props.$colorNew)};
  cursor: ${(props) => (props.$cursor ? props.$cursor : "pointer")};
  font-size: 1.15rem !important;
  margin-bottom: ${(props) =>
    props.$marginBottom ? props.$marginBottom + "px" : "unset"};
`;
