import { CircularProgress } from "@mui/material";
import { sortBy } from "lodash";
import { useTheme } from "styled-components";
import { PaperControl } from "../../../controls/Paper/PaperControl";
import { PaperContentDiv } from "../../../controls/Paper/SC/PaperContentDiv";
import { GroupsColumnDiv } from "./SC/GroupsColumnDiv";
import { GroupsList } from "./SC/GroupsList";
import { NoOwnersFound } from "./NoOwnersFound";
import { DenseItemAvatar } from "./DenseItemAvatar";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import { SectionName } from "../../../app/slices/models/documentDetailsModels";
import { SelfHelpPage } from "../../../models/selfHelp/selfHelpSection";
import { ErrorDisplay } from "../../../app/errorHandling/ErrorDisplay";
import { PaperContentCenterDiv } from "./SC/PaperContentCenterDiv";
import { useTagOwners } from "./hooks/useTagOwners";
import { useEffect } from "react";

export function TagOwnersGroupColumn() {
  const { fetchTagOwners, isOwnersError, isOwnersFetching, tagOwners } =
    useTagOwners();
  const theme = useTheme();

  useEffect(() => {
    fetchTagOwners();
  }, [fetchTagOwners]);

  const content = () => {
    if (isOwnersError) {
      return (
        <PaperContentCenterDiv>
          <ErrorDisplay
            errorId={"tag-owners"}
            errorMessageTitle={"Something went wrong"}
            errorDescription={"Error while fetching tag owners."}
            refreshFunction={fetchTagOwners}
            showReloadButton={true}
          />
        </PaperContentCenterDiv>
      );
    }

    if (isOwnersFetching) {
      return (
        <PaperContentCenterDiv>
          <CircularProgress
            sx={{ color: theme.palettes.primary.main }}
            size="30px"
          />
        </PaperContentCenterDiv>
      );
    }

    if (tagOwners && tagOwners.length > 0) {
      return (
        <PaperContentDiv id="tag-owners-content-div">
          <GroupsList id={"list"} dense={true}>
            {sortBy(tagOwners, ["email"]).map((p) => {
              return (
                <DenseItemAvatar
                  origin="tag-owners"
                  id={"tag-owners-li-item-" + p.email}
                  key={p.email}
                  primaryInfo={p.name ? p.name : ""}
                  secondaryInfo={p.email ? p.email : ""}
                  type={"person"}
                  isBold={false}
                />
              );
            })}
          </GroupsList>
        </PaperContentDiv>
      );
    }

    return (
      <PaperContentCenterDiv id="tag-owners-no-content-div">
        <NoOwnersFound
          title="Nothing has been found"
          description="This Tag does not have any owners assigned"
        />
      </PaperContentCenterDiv>
    );
  };

  return (
    <GroupsColumnDiv id="tag-owners-column" width={100} height={50}>
      <PaperControl
        title={"Owners"}
        currentSelfHelp={CurrentSelfHelp.TagsOwners}
        selfHelpPage={SelfHelpPage.Tags}
        selfHelpSectionName={SectionName.TagsOwners}
        useHeader={true}
      >
        {content()}
      </PaperControl>
    </GroupsColumnDiv>
  );
}
