import styled, { DefaultTheme } from "styled-components";

interface TreeItemSpanProps {
  selectable: boolean;
}

function getColor(selectable: boolean, theme: DefaultTheme) {
  if (!selectable) {
    return theme.palettes.grayscale.medium;
  }

  return theme.palettes.grayscale.darkest;
}

export const TreeItemSpan = styled.span<TreeItemSpanProps>`
  display: flex;
  flex-grow: 1;
  color: ${(props) => getColor(props.selectable, props.theme)};
  cursor: pointer;
`;
