import { find } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetPublishersListQuery } from "../../../../../apis/publishersApi";
import { openSnackbar } from "../../../../../app/helpers/snackBarHelper";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { selectUserEmail } from "../../../../../app/slices/authenticationSlice";
import { resetPageNumber } from "../../../../../app/slices/documentListSlice";
import {
  selectCategoryId,
  selectCurrentPublishersFilter,
  setCurrentPublishersFilter,
  setPublishersFilter,
} from "../../../../../app/slices/navigationSlice";
import { SnackbarMsg } from "../../../../../controls/Snackbar/SnackbarMessages";
import {
  ComboBoxListItem,
  getKeys,
} from "../../../../../models/autocompleteValue";
import { SnackbarType } from "../../../../../models/snackbar";
import { FiltersAutoComplete } from "../FiltersAutoComplete";

const moveMeToFirstPlace = (
  stringToMove: string | undefined,
  arrayIn: ComboBoxListItem[]
) => {
  if (stringToMove === undefined) {
    return arrayIn;
  }

  const itemToMove = find(arrayIn, (item) => item.value === stringToMove);
  if (itemToMove !== undefined) {
    const currentIndex = arrayIn.indexOf(itemToMove);
    itemToMove.value = `Me (${itemToMove.value})`;
    arrayIn.splice(currentIndex, 1);
    arrayIn.unshift(itemToMove);
  }

  return arrayIn;
};

export function PublishersFilter() {
  const dispatch = useAppDispatch();
  const categoryId = useAppSelector(selectCategoryId);

  const {
    data: response,
    refetch,
    isError,
    isLoading,
  } = useGetPublishersListQuery(categoryId);
  const [isGetQueryFailed, setIsGetQueryFailed] = useState(false);

  const currentPublishersFilter = useAppSelector(selectCurrentPublishersFilter);
  const userEmail = useAppSelector(selectUserEmail);
  const onPublishersFilterChange = useCallback(
    (publishers: ComboBoxListItem[]) => {
      dispatch(setCurrentPublishersFilter(publishers));
      dispatch(setPublishersFilter(getKeys(publishers)));
      dispatch(resetPageNumber());
    },
    [dispatch]
  );

  useEffect(() => {
    setIsGetQueryFailed(isError);
  }, [isError]);

  useEffect(() => {
    setIsGetQueryFailed(false);
  }, [categoryId]);

  useEffect(() => {
    if (isGetQueryFailed) {
      openSnackbar(dispatch, SnackbarMsg.PublishersError, SnackbarType.error);
    }
  }, [dispatch, isGetQueryFailed]);

  const publishersDictionary = useMemo(() => {
    if (response && userEmail) {
      const publishers = moveMeToFirstPlace(
        userEmail.toLocaleLowerCase(),
        response.map<ComboBoxListItem>((item) => {
          return {
            key: item.id,
            value: item.email,
            hasDataQualityWarning: item.isOnlyInDocument,
          };
        })
      );

      return publishers;
    }
  }, [userEmail, response]);

  return (
    <div id="navigation-filters-publishers">
      <FiltersAutoComplete
        id="navigation-publishers-combobox"
        pillsClassName="publisher-pill"
        label="Publishers"
        items={publishersDictionary ?? []}
        missingValueWarningText={
          "Selected publishers are no longer available in the filters list"
        }
        dataQualityWarningText={
          "Highlighted publishers do not have rights for selected category anymore but have documents published by them"
        }
        onChangeHandler={onPublishersFilterChange}
        isLoading={isLoading}
        selected={currentPublishersFilter}
        isDictionaryDataFetchingError={isError}
        onRefreshHandler={() => void refetch}
      />
    </div>
  );
}
