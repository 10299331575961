import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { clearEditedDocumentDescription } from "../../../../app/slices/documentMetadataSlice";
import { SectionName } from "../../../../app/slices/models/documentDetailsModels";
import { DetailsSection, DetailsSectionProps } from "../DetailsSection";
import { CurrentSelfHelp } from "../../../../models/CurrentSelfHelpType";
import { selectIsLoadingDetailsMetaData } from "../../../../app/slices/documentDetailsSlice";
import { selectHasDescriptionSectionAnyValidationErrors } from "../../../../app/slices/documentMetadataValidationSlice";
import { useUserCanEditDocument } from "../../../../app/hooks/permissions/useUserCanEditDocument";
import { initialEditableState } from "../../../../app/hooks/permissions/models/state";
import { useDocumentEditedMode } from "../../../../app/hooks/document/useDocumentEditedMode";
import { DocumentKindControl } from "./DocumentKindControl";
import { SummaryControl } from "./SummaryControl";

export function DescriptionSection(props: DetailsSectionProps) {
  const dispatch = useAppDispatch();
  const { descriptionSection } = useDocumentEditedMode();

  const hasValidationErrors = useAppSelector(
    selectHasDescriptionSectionAnyValidationErrors
  );
  const isLoadingDetailsMetaData = useAppSelector(
    selectIsLoadingDetailsMetaData
  );

  const [editableState, setEditableState] = useState(initialEditableState);
  const [editableDocumentKind, setEditableDocumentKind] =
    useState(initialEditableState);

  const { canEditDocument } = useUserCanEditDocument();

  useEffect(() => {
    setEditableState(canEditDocument(false));
  }, [canEditDocument]);

  useEffect(() => {
    setEditableDocumentKind(canEditDocument(true));
  }, [canEditDocument]);

  const discardDescriptionSection = useCallback(() => {
    dispatch(clearEditedDocumentDescription());
  }, [dispatch]);

  return (
    <DetailsSection
      {...props}
      section={SectionName.Description}
      name="Description"
      selfHelpType={CurrentSelfHelp.DetailsDescription}
      isValidationError={hasValidationErrors}
      isEdited={descriptionSection.descriptionSectionIsEdited}
      isLoadingDetailsMetaData={isLoadingDetailsMetaData}
      onDiscardChanges={discardDescriptionSection}
      isEditable={editableState.isEditable}
      notEditableReason={editableState.lockMessage}
    >
      <>
        <DocumentKindControl
          isEdited={descriptionSection.documentKindIsEdited}
          isEditable={editableDocumentKind.isEditable}
          notEditableReason={editableDocumentKind.lockMessage}
          isLoadingDetailsMetadata={isLoadingDetailsMetaData}
        />
        <SummaryControl
          isEdited={descriptionSection.summaryIsEdited}
          isEditable={editableState.isEditable}
          notEditableReason={editableState.lockMessage}
          isLoadingDetailsMetadata={isLoadingDetailsMetaData}
        />
      </>
    </DetailsSection>
  );
}
