import { ContentDiv } from "./SC/ContentDiv";
import { HeaderDiv } from "./SC/HeaderDiv";
import { NoResourceFoundDiv } from "./SC/NoResourceFoundDiv";

interface NoTagSelectedProps {
  headerMessage: string;
  contentMessage: string;
  headerId?: string;
}

export function NoTagSelected({
  headerMessage,
  contentMessage,
  headerId,
}: NoTagSelectedProps) {
  return (
    <NoResourceFoundDiv>
      <HeaderDiv id={headerId ?? ""}>{headerMessage}</HeaderDiv>
      <ContentDiv>{contentMessage}</ContentDiv>
    </NoResourceFoundDiv>
  );
}
