import { ConfirmationDialogControl } from "../../../../../controls/Dialog/ConfirmationDialogControl";
import { StyledDialogContentText } from "../../../selfHelpPopup/SC/StyledDialogContentText";

interface HardDeleteConfirmationDialogProps {
  id: string;
  onCloseClick: (e: React.MouseEvent) => void;
  onYesClick: (e: React.MouseEvent) => void;
  onNoClick: (e: React.MouseEvent) => void;
  isOpen: boolean;
}

export function HardDeleteConfirmationDialog(
  props: HardDeleteConfirmationDialogProps
) {
  return (
    <ConfirmationDialogControl
      id={props.id}
      title="Do you wish to hard delete this document revision?"
      onYesClick={props.onYesClick}
      onNoClick={props.onNoClick}
      onCloseClick={props.onCloseClick}
      maxWidth="sm"
      isOpen={props.isOpen}
      content={
        <StyledDialogContentText id="alert-dialog-description">
          This action is irreversible, document revision will disappear
          completely from ABB Library. This should be done if you do not wish to
          wait 90 days for document revision to be deleted automatically, so you
          can reuse its Identity earlier.
        </StyledDialogContentText>
      }
    />
  );
}
