import { FixedSizeList } from "react-window";
import styled from "styled-components";

export const StyledFixedSizeList = styled(FixedSizeList)`
  .MuiListItem-root:hover {
    background-color: ${(props) => props.theme.palettes.grayscale.lighter};
  }
  overflow-x: hidden;
  max-width: 100%;
  overflow-wrap: break-word;
`;
