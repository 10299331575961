import SendIcon from "@mui/icons-material/Send";
import { useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { openSnackbar } from "../../../../app/helpers/snackBarHelper";
import { useDocumentFlow } from "../../../../app/hooks/document/useDocumentFlow";
import { useDuplicateAttachment } from "../../../../app/hooks/document/useDuplicateAttachment";
import { useSendDocumentForApprovalAction } from "../../../../app/hooks/document/useSendDocumentForApprovalAction";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import {
  selectIsBreakingDocumentLink,
  selectIsUpdatingDocument,
} from "../../../../app/slices/documentDetailsSlice";
import {
  selectDocumentDraftType,
  selectIsTrimmedIdentitySectionEdited,
} from "../../../../app/slices/documentMetadataSlice";
import {
  selectHasCurrentDocumentAnyValidationErrors,
  selectHasTagsSectionAnyValidationWarnings,
  selectSecuritySectionStatus,
  setIsPublishDocumentValidationOn,
} from "../../../../app/slices/documentMetadataValidationSlice";
import {
  selectIsAttachmentUploadInProgress,
  selectIsFileUploading,
} from "../../../../app/slices/fileUploadSlice";
import { selectDetailsHasRequiredUncompletedSections } from "../../../../app/slices/selfHelpSlice";
import { TextButtonControl } from "../../../../controls/Buttons/TextButtonControl";
import { SnackbarMsg } from "../../../../controls/Snackbar/SnackbarMessages";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import { DraftType } from "../../../../models/documentDetails/documentStatus";
import { ErrorApi } from "../../../../models/errorApi";
import { ErrorApiType } from "../../../../models/errorApiType";
import { SnackbarType } from "../../../../models/snackbar";
import { ValidationStatus } from "../../../../models/validationRule";
import { ButtonDiv } from "../SC/ButtonDiv";
import { ErrorApiPopup } from "./popups/error/ErrorApiPopup";
import { ErrorPopup, SaveAsDraftErrorType } from "./popups/error/ErrorPopup";
import WarningPopupQueue from "./popups/warning/WarningPopupQueue";
import DuplicateAttachmentPopup from "./publish/DuplicateAttachmentPopup";

interface SendForApprovalButtonProps {
  disabled?: boolean;
  disabledTooltipText?: string;
}

export function SendForApprovalButton(props: SendForApprovalButtonProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const hasCurrentDocumentAnyValidationErrors = useAppSelector(
    selectHasCurrentDocumentAnyValidationErrors
  );
  const identityIsEdited = useAppSelector(selectIsTrimmedIdentitySectionEdited);
  const draftType = useAppSelector(selectDocumentDraftType);
  const isFileUploading = useAppSelector(selectIsFileUploading);
  const isAttachmentUploadInProgress = useAppSelector(
    selectIsAttachmentUploadInProgress
  );
  const isUpdatingDocument = useAppSelector(selectIsUpdatingDocument);
  const isBreakingDocumentLink = useAppSelector(selectIsBreakingDocumentLink);
  const hasTagsSectionAnyValidationWarnings = useAppSelector(
    selectHasTagsSectionAnyValidationWarnings
  );
  const securitySectionStatus = useAppSelector(selectSecuritySectionStatus);
  const { isNewDocument, isNewDocumentRevision } = useDocumentFlow();
  const { sendForApproval, sendForApprovalErrors } =
    useSendDocumentForApprovalAction();
  const [errorType, setErrorType] = useState<SaveAsDraftErrorType | null>(null);
  const [apiErrors, setApiErrors] = useState<ErrorApi[]>([]);
  const [warningPopupIsOpen, setWarningPopupIsOpen] = useState(false);
  const showWarningPopup =
    securitySectionStatus === ValidationStatus.warning ||
    hasTagsSectionAnyValidationWarnings;
  const hasUncompletedSections = useAppSelector(
    selectDetailsHasRequiredUncompletedSections
  );

  const {
    isDuplicateAttachmentPopupOpen,
    attachmentIsDuplicate,
    originalDocumentIdentity,
    navigateToOriginalDocumentHandler,
    closePopupHandler,
    openPopupHandler,
  } = useDuplicateAttachment();

  useEffect(() => {
    if (Array.isArray(sendForApprovalErrors)) {
      const errors = sendForApprovalErrors.filter(
        (x) =>
          x.code === ErrorApiType.IdentityDuplicate ||
          x.code === ErrorApiType.ApprovalDuplicateError
      );

      if (errors.length === 0 && sendForApprovalErrors.length > 0) {
        openSnackbar(
          dispatch,
          SnackbarMsg.DocumentApprovalError,
          SnackbarType.error
        );
      }

      setApiErrors([errors[0]]);
    }
  }, [sendForApprovalErrors, dispatch]);

  const sendForApprovalHandler = () => {
    if (
      !isNewDocument &&
      !isNewDocumentRevision &&
      draftType !== DraftType.NewDocumentDraft &&
      identityIsEdited
    ) {
      setErrorType(SaveAsDraftErrorType.ApprovalIdentityChanged);
      return;
    }

    if (showWarningPopup) {
      setWarningPopupIsOpen(true);
      return;
    }

    sendForApproval();
  };

  const onWarningPopupSave = useCallback(() => {
    setWarningPopupIsOpen(false);
    sendForApproval();
  }, [sendForApproval]);

  const onWarningPopupClose = useCallback(() => {
    setWarningPopupIsOpen(false);
  }, []);

  return (
    <ButtonDiv>
      <ErrorPopup
        isOpen={errorType !== null}
        onClose={() => setErrorType(null)}
        reason={errorType}
      />
      {apiErrors.length > 0 && (
        <ErrorApiPopup
          isOpen={apiErrors.length > 0}
          onClose={() => setApiErrors([])}
          reasons={apiErrors}
          id="disabled-send-for-approval-popup"
        />
      )}
      <DuplicateAttachmentPopup
        id="duplicate-attachment-popup"
        onCloseClick={closePopupHandler}
        onConfirmClick={navigateToOriginalDocumentHandler}
        onPublishAnywayClick={() => {
          closePopupHandler();
          sendForApprovalHandler();
        }}
        publishAnywayButtonText="Send for approval"
        isOpen={isDuplicateAttachmentPopupOpen}
        originalIdentity={originalDocumentIdentity}
      />
      <WarningPopupQueue
        isOpen={warningPopupIsOpen}
        onSave={onWarningPopupSave}
        onClose={onWarningPopupClose}
      />
      <TextButtonControl
        colors="primary"
        height={theme.shapes.primaryButtonHeight}
        id={"document-details-send-for-approval-button"}
        text={"Send for approval"}
        disabled={
          props.disabled ||
          hasCurrentDocumentAnyValidationErrors ||
          isFileUploading ||
          isAttachmentUploadInProgress ||
          isUpdatingDocument ||
          isBreakingDocumentLink ||
          hasUncompletedSections
        }
        disabledTooltipText={
          hasUncompletedSections
            ? TooltipMsg.UncompletedSelfHelpSections
            : props.disabled
              ? props.disabledTooltipText
              : hasCurrentDocumentAnyValidationErrors
                ? "You cannot send document for approval because it has some validation errors"
                : undefined
        }
        onClick={() => {
          dispatch(setIsPublishDocumentValidationOn(true));
          if (attachmentIsDuplicate && originalDocumentIdentity) {
            openPopupHandler();
          } else {
            sendForApprovalHandler();
          }
        }}
        isCompactView={false}
        isVertical={false}
        isVisible={true}
        icon={<SendIcon fontSize="small" />}
      />
    </ButtonDiv>
  );
}
