import { MenuItem } from "@mui/material";
import styled from "styled-components";

const StyledMenuItem = styled(MenuItem)`
  padding: 6px;
  border: 1px solid;
  border-radius: 4;
`;

export default StyledMenuItem;
