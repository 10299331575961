import { useEffect, useCallback, useState } from "react";
import { useGetAccessGroupsQuery } from "../../../../../apis/accessGroupsApi";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { ComboBoxListItem } from "../../../../../models/autocompleteValue";
import { resetPageNumber } from "../../../../../app/slices/documentListSlice";
import {
  selectCategoryId,
  selectCurrentAccessGroupsFilter,
  setAccessGroupsFilter,
  setCurrentAccessGroupsFilter,
} from "../../../../../app/slices/navigationSlice";
import { openSnackbar } from "../../../../../app/helpers/snackBarHelper";
import { SnackbarType } from "../../../../../models/snackbar";
import { FiltersAutoComplete } from "../FiltersAutoComplete";
import { SnackbarMsg } from "../../../../../controls/Snackbar/SnackbarMessages";

export function AccessGroupsFilter() {
  const dispatch = useAppDispatch();
  const categoryId = useAppSelector(selectCategoryId);
  const [isGetQueryFailed, setIsGetQueryFailed] = useState(false);

  const {
    data: response,
    refetch,
    isLoading,
    isError,
  } = useGetAccessGroupsQuery(categoryId);
  const currentAccessGroupsFilter = useAppSelector(
    selectCurrentAccessGroupsFilter
  );
  const onAccessGroupsFilterChange = useCallback(
    (search: ComboBoxListItem[]) => {
      const accessGroupsIds: number[] = [];
      search.forEach((element) => {
        accessGroupsIds.push(parseInt(element.key));
      });

      dispatch(setCurrentAccessGroupsFilter(search));
      dispatch(setAccessGroupsFilter(accessGroupsIds));
      dispatch(resetPageNumber());
    },
    [dispatch]
  );

  useEffect(() => {
    setIsGetQueryFailed(isError);
  }, [isError]);

  useEffect(() => {
    setIsGetQueryFailed(false);
  }, [categoryId]);

  useEffect(() => {
    if (isGetQueryFailed) {
      openSnackbar(
        dispatch,
        SnackbarMsg.AccessGroupsError,
        SnackbarType.error
      );
    }
  }, [dispatch, isGetQueryFailed]);

  return (
    <div id="navigation-filters-access-groups">
      <FiltersAutoComplete
        id="navigation-access-groups-combobox"
        pillsClassName="access-group-pill"
        label="Access groups"
        items={
          response?.map<ComboBoxListItem>((item) => {
            return {
              key: item.groupId.toString(),
              value: item.name,
              hasDataQualityWarning: item.isOnlyInDocument,
            };
          }) ?? []
        }
        missingValueWarningText={
          "Selected access groups are no longer available in the filters list"
        }
        dataQualityWarningText={
          "Highlighted access groups are not allowed for selected category but have documents published with them"
        }
        onChangeHandler={onAccessGroupsFilterChange}
        isLoading={isLoading}
        selected={currentAccessGroupsFilter}
        isDictionaryDataFetchingError={isError}
        onRefreshHandler={() => void refetch()}
      />
    </div>
  );
}
