export interface SelfHelpSection {
  sectionName: string;
  page: SelfHelpPage;
  optional: boolean;
  version: string;
  userVersion: string;
}

export interface SelfHelpSectionIdentifier {
  sectionName: string;
  page: SelfHelpPage;
}

export enum SelfHelpPage {
  List = "List",
  Details = "Details",
  Cms = "Cms",
  Tags = "Tags",
  Publishers = "Publishers",
}

export enum SectionState {
  Completed = "grey",
  Mandatory = "red",
  Optional = "blue",
}
