import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function ActionDateStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The action date can be specified by typing it directly into the field or
        selecting it from the calendar. Note that the action date cannot be
        earlier than the next day (UTC time).
      </Paragraph>
    </StepContentDiv>
  );
}
