import styled from "styled-components";
import { ToggleButtonGroup } from "@mui/material";

interface StyledToggleButtonGroupProps {
  $isEdited?: boolean;
}

export const StyledToggleButtonGroup = styled(
  ToggleButtonGroup
)<StyledToggleButtonGroupProps>`
  &.MuiToggleButtonGroup-root {
    background-color: ${(props) => props.theme.palettes.grayscale.white};
    outline: 1px solid ${(props) => props.theme.palettes.grayscale.light};
    height: 34px;
    max-height: 34px;
  }

  .Mui-selected {
    outline: ${(props) =>
      props.$isEdited
        ? "1px solid " + props.theme.palettes.secondary.info
        : "unset"};
  }

  .MuiToggleButtonGroup-grouped {
    margin: 3px;
    border: 0px;

    &.Mui-disabled {
      border: 0px;

      &.Mui-selected {
        color: ${(props) => props.theme.palettes.grayscale.medium};
      }
    }

    &:not(:first-of-type) {
      border-radius: 2px;
    }

    &:first-of-type {
      border-radius: 2px;
    }
  }
`;
