import styled from "styled-components";
import { chevronMinWidth } from "../../../../../app/slices/models/documentsModels";

export const GroupErrorSpan = styled.span`
  fontfamily: ${(props) => props.theme.typography.fontFamily};
  color: ${(props) => props.theme.palettes.grayscale.darkest};
  padding-left: ${chevronMinWidth}px;
  maxheight: ${(props) => props.theme.density.compactRowHeight}px;
  minheight: ${(props) => props.theme.density.compactRowHeight}px;
  fontsize: ${(props) => props.theme.typography.fontSizes.medium}px;
`;
