import { RoleInCategory } from "./rolesInCategory";

export interface PublisherRoles {
  email: string;
  name: string;
  roles: PublisherRole[];
}

export interface PublisherRole {
  role?: RoleInCategory;
  assignedInCategoryId: string;
  assignedInCategoryName: string;
  assignedInCategoryPath: string;
  roleType: PublisherRoleType;
}

export enum PublisherRoleType {
  Assigned = "Assigned",
  Inherited = "Inherited",
  NotAssigned = "Not Assigned",
}
