import { ItemAvatarDiv } from "./SC/ItemAvatarDiv";
import { StyledAvatar } from "./SC/StyledAvatar";
import { StyledListItem } from "./SC/StyledListItem";
import { StyledListItemAvatar } from "./SC/StyledListItemAvatar";
import { StyledListItemText } from "./SC/StyledListItemText";
import { StyledPersonIcon } from "./SC/StyledPersonIcon";

export interface PublisherInfoProps {
  primaryInfo: string | null;
  secondaryInfo: string | null;
}

export function PublisherInfo(props: PublisherInfoProps) {
  return (
    <ItemAvatarDiv id={props.primaryInfo + "-publisher-info-div"}>
      <StyledListItem>
        <StyledListItemAvatar>
          <StyledAvatar>
            <StyledPersonIcon />
          </StyledAvatar>
        </StyledListItemAvatar>
        <StyledListItemText
          primary={props.primaryInfo}
          secondary={props.secondaryInfo}
        />
      </StyledListItem>
    </ItemAvatarDiv>
  );
}
