import { useLayoutEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../../app/hooks/hooks";
import { selectDensity } from "../../../../../app/slices/documentListSlice";
import { Density } from "../../../../../app/slices/models/documentsModels";
import { DocumentTextSpan } from "./styledComponents/DocumentTextSpan";
import { WordBreakType } from "../../../../../models/documentList/types/WordBreakType";
import { TooltipTop } from "../../../../../controls/Tooltips/ToolipTop";
import { TooltipPartDiv } from "../../../../../controls/Tooltips/SC/TooltipPartDiv";
import { TooltipPartSpan } from "../../../../../controls/Tooltips/SC/TooltipPartSpan";

export interface EllipsisTextProps {
  isBolded?: boolean;
  wordBreak: WordBreakType;
  content: string;
  emptyContentPlaceholder?: string;
  fillIfEmpty: boolean;
  italicFont?: boolean;
  className?: string;
  useTooltip: boolean;
  width: number;
  id?: string;
  $paddingLeft?: number;
  $paddingRight?: number;
  enableEllipsis?: boolean;
  flexGrow?: number;
  additionalTextLines?: string[];
}

export function EllipsisText(props: EllipsisTextProps) {
  const spanRef = useRef<HTMLSpanElement>(null);
  const density: Density = useAppSelector(selectDensity);
  const [isOverflow, setIsOverflow] = useState(false);

  useLayoutEffect(() => {
    if (spanRef.current) {
      setIsOverflow(spanRef.current.scrollWidth > props.width);
    }
  }, [density, props.width]);

  let content = props.content;

  if (!props.content && props.fillIfEmpty) {
    content = `(${props.emptyContentPlaceholder ?? "empty"})`;
  }

  const documentTextSpan = (
    <DocumentTextSpan
      className={props.className}
      ref={spanRef}
      id={props.id}
      italic={props.italicFont}
      $wordBreak={props.wordBreak}
      $enableEllipsis={props.enableEllipsis}
      $hiddenOverflow={true}
      bold={props.isBolded}
      $paddingLeft={props.$paddingLeft}
      $paddingRight={props.$paddingRight}
      $flexGrow={props.flexGrow}
    >
      {content}
    </DocumentTextSpan>
  );

  if (
    (isOverflow ||
      (props.additionalTextLines && props.additionalTextLines.length > 0)) &&
    props.useTooltip
  ) {
    return (
      <TooltipTop
        title={
          <TooltipPartDiv>
            {isOverflow && <TooltipPartSpan>{content}</TooltipPartSpan>}
            {props.additionalTextLines?.map((text, index) => {
              return <TooltipPartSpan key={index}>{text}</TooltipPartSpan>;
            })}
          </TooltipPartDiv>
        }
      >
        {documentTextSpan}
      </TooltipTop>
    );
  } else {
    return documentTextSpan;
  }
}
