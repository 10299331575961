import { useEffect, useMemo } from "react";
import { ButtonDiv } from "../SC/ButtonDiv";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { SpeedButtonControl } from "../../../../controls/Buttons/SpeedButtonControl";
import { Option } from "../../../../models/option";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { useNavigate } from "react-router-dom";
import { setShouldRefreshDocumentMetadata } from "../../../../app/slices/documentDetailsSlice";
import { setNewDocumentVersionIdentity } from "../../../../app/slices/documentDetailsSlice";
import { selectDocumentMetadata } from "../../../../app/slices/documentMetadataSlice";
import { routing } from "../../../pageLayout/PageLayout";
import { useUserCanEditDocument } from "../../../../app/hooks/permissions/useUserCanEditDocument";
import { useUserPermissionsInCategory } from "../../../../app/hooks/permissions/useUserPermissionsInCategory";
import { Workflow } from "../../../../models/documentList/types/workflow";
import { useIsNewRevisionAvailable } from "../../../../app/hooks/document/useIsNewRevisionAvailable";
import { useUserPermissionsInDocument } from "../../../../app/hooks/permissions/useUserPermissionsInDocument";
import {
  addVisibleButton,
  ActionButton,
  removeVisibleButton,
} from "../../../../app/slices/selfHelpSlice";
import { Identity } from "../../../../models/documentDetails/documentMetadata";
import { useUserApplicationRoles } from "../../../../app/hooks/permissions/roles/useUserApplicationRoles";
import { TooltipTop } from "../../../../controls/Tooltips/ToolipTop";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";

interface CreateButtonProps {
  disabled?: boolean;
  disabledTooltipText?: string;
  isCompactView?: boolean;
  hasPendingApprovals: boolean;
}

export function CreateButton(props: CreateButtonProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { canEditDocument } = useUserCanEditDocument();
  const { hasAccessToAnyDocumentCategory } =
    useUserPermissionsInCategory(undefined);
  const documentIdentity = useAppSelector(
    selectDocumentMetadata
  ).documentIdentity;

  const { canPublish } = useUserApplicationRoles();

  const { hasPermissionToApproveDocument } =
    useUserPermissionsInDocument(false);

  const setIdentity = () => {
    dispatch(setNewDocumentVersionIdentity(documentIdentity));
    dispatch(setShouldRefreshDocumentMetadata(true));
  };

  const {
    checkUserCanCreateNewRevision,
    isNewRevisionAvailable,
    getNewRevisionButtonDisableTooltip,
  } = useIsNewRevisionAvailable({
    hasPendingApprovals: props.hasPendingApprovals,
  });

  const documentIdentityIsEmpty = (documentIdentity: Identity) => {
    return (
      !documentIdentity.documentPart &&
      !documentIdentity.documentNumber &&
      documentIdentity.documentLanguageCodes.length === 0
    );
  };

  useEffect(() => {
    if (!documentIdentityIsEmpty(documentIdentity)) {
      checkUserCanCreateNewRevision({
        identity: {
          revision: documentIdentity.revision,
          documentNumber: documentIdentity.documentNumber,
          documentLanguageCodes: documentIdentity.documentLanguageCodes,
          documentPart: documentIdentity.documentPart,
        },
        workflow: Workflow.NewRevision,
      });
    }
  }, [checkUserCanCreateNewRevision, documentIdentity]);

  const canCreateNewRevision = useMemo(() => {
    return (
      canEditDocument(false).isEditable &&
      hasAccessToAnyDocumentCategory &&
      (!props.hasPendingApprovals || hasPermissionToApproveDocument) &&
      isNewRevisionAvailable
    );
  }, [
    canEditDocument,
    hasAccessToAnyDocumentCategory,
    props.hasPendingApprovals,
    hasPermissionToApproveDocument,
    isNewRevisionAvailable,
  ]);

  const options: Option[] = [
    {
      disabledTooltip: getNewRevisionButtonDisableTooltip(),
      text: "New Revision",
      description: "Create new revision of this document",
      hasAccess: canCreateNewRevision,
      onClick: () => {
        setIdentity();
        navigate(routing.newRevision);
      },
    },
    {
      text: "New Part version",
      hasAccess: true,
      description: "Create new part version of this document",
      onClick: () => {
        setIdentity();
        navigate(routing.newPart);
      },
    },
    {
      text: "New Language version",
      hasAccess: true,
      description: "Create new language version of this document",
      onClick: () => {
        setIdentity();
        navigate(routing.newLanguage);
      },
    },
    {
      text: "New from template",
      hasAccess: true,
      description: "Create new document using this document as a template",
      onClick: () => {
        setIdentity();
        navigate(routing.newTemplate);
      },
    },
  ];

  useEffect(() => {
    dispatch(addVisibleButton(ActionButton.Create));

    return () => {
      dispatch(removeVisibleButton(ActionButton.Create));
    };
  }, [dispatch]);

  return (
    <ButtonDiv>
      <TooltipTop title={!canPublish ? TooltipMsg.CantPublish : undefined}>
        <span>
          <SpeedButtonControl
            colors="primary"
            id="document-details-create-button"
            text="Create"
            options={options}
            isVisible={true}
            disabled={!canPublish}
            isCompactView={props.isCompactView == true}
            icon={<PostAddIcon fontSize="small" />}
          />
        </span>
      </TooltipTop>
    </ButtonDiv>
  );
}
