import {
  AutocompleteRenderOptionState,
  CircularProgress,
  PopperProps,
} from "@mui/material";
import { without } from "lodash";
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import StyledAutoCompleteErrorMessage from "../../app/errorHandling/autoComplete/StyledAutoCompleteErrorMessage";
import { defaultTheme } from "../../app/theme";
import {
  ComboBoxListItem,
  isInCollection,
  isItemWithDataQualityWarning,
} from "../../models/autocompleteValue";
import { ValidationRule } from "../../models/validationRule";
import { AutocompleteChip } from "./AutocompleteChip";
import { AutocompleteDiv } from "./SC/AutocompleteDiv";
import { AutocompleteInputDiv } from "./SC/AutocompleteInputDiv";
import { StyledAutoComplete } from "./SC/StyledAutoComplete";
import { StyledAutoCompletePopper } from "./SC/StyledAutoCompletePopper";
import { ValidationControl } from "./ValidationControl";
import { PopupIconControl } from "./PopupIconControl";
import { TextFieldControl } from "./TextFieldControl";
import { ClearIconControl } from "./ClearIconControl";
import { TooltipMsg } from "../Tooltips/TooltipMessages";

interface AutoCompleteInputProps {
  id: string;
  label?: string;
  items: ComboBoxListItem[];
  missingValueWarningText?: string;
  dataQualityWarningText?: string;
  multiple: boolean;
  isEditable: boolean;
  isError?: boolean;
  isWarning?: boolean;
  warningMessage?: string;
  isDisabled?: boolean;
  isDictionaryDataFetchingError?: boolean;
  onChangeHandler: (values: ComboBoxListItem[]) => void;
  onRefreshHandler?: () => void;
  validationRules?: ValidationRule[];
  isLoading: boolean;
  $paddingTop?: number;
  $paddingLeft?: number;
  selected: ComboBoxListItem[];
  pillsClassName: string;
  onClose?: () => void;
  onOpen?: () => void;
  renderOption:
    | ((
        props: React.HTMLAttributes<HTMLLIElement>,
        option: unknown,
        state: AutocompleteRenderOptionState
      ) => React.ReactNode)
    | undefined;
  isEdited?: boolean;
  inputStartAdornment?: React.ReactNode;
  inputEndAdornment?: React.ReactNode;
  omitDataQualityCheck?: boolean;
  errorMessages?: string[];
  noOptionsText?: string;
  onInputChange?: (value: string, event?: React.SyntheticEvent) => void;
  isFetching?: boolean;
  placeholder?: string;
  closeTrigger?: boolean;
}

export const getPopperWidth = (parentInputWidth: number | undefined) => {
  return (parentInputWidth ?? 200) - 2; // subtracting few pixels to match parent input width with border
};

export function AutoCompleteInput(props: AutoCompleteInputProps) {
  const inputRef = useRef<HTMLDivElement>(null);
  const [inputValues, setInputValues] = useState<ComboBoxListItem[]>(
    props.selected
  );
  const [isMissingValueWarning, setIsMissingValueWarning] = useState(false);
  const [isDataQualityWarning, setIsDataQualityWarning] = useState(false);
  const [showSelect, setShowSelect] = useState(false);

  const handleChange = (event: SyntheticEvent, value: unknown) => {
    let values: ComboBoxListItem[] = [];
    if (props.multiple) {
      values = value as ComboBoxListItem[];
    } else {
      values = [value as ComboBoxListItem];
    }

    changeHandler(values);
    event.stopPropagation();
  };

  const changeHandler = props.onChangeHandler;
  const refreshHandler = props.onRefreshHandler;

  const handleDelete = (e: React.MouseEvent, keyValue: ComboBoxListItem) => {
    props.onChangeHandler(without(inputValues, keyValue));
  };

  useEffect(() => {
    setInputValues(props.selected);
  }, [props.selected]);

  useEffect(() => {
    setIsMissingValueWarning(false);
    setIsDataQualityWarning(false);

    if (props.omitDataQualityCheck) {
      return;
    }

    if (inputValues.length > 0 && props.multiple) {
      const newInputValues: ComboBoxListItem[] = [];
      let changedValues = false as boolean;
      let isMissingValueWarning = false as boolean;
      let isDataQualityWarning = false as boolean;

      inputValues.forEach((item) => {
        let hasMissingValueWarning = false;
        let hasDataQualityWarning = false;

        if (!isInCollection(props.items, item.key)) {
          hasMissingValueWarning = true;
          isMissingValueWarning = true;
        }

        if (isItemWithDataQualityWarning(props.items, item.key)) {
          hasDataQualityWarning = true;
          isDataQualityWarning = true;
        }

        if (
          hasMissingValueWarning !== item.hasMissingValueWarning ||
          hasDataQualityWarning !== item.hasDataQualityWarning
        ) {
          changedValues = true;
        }

        newInputValues.push({
          ...item,
          hasDataQualityWarning,
          hasMissingValueWarning,
        });
      });

      if (isMissingValueWarning) {
        setIsMissingValueWarning(true);
      }

      if (isDataQualityWarning) {
        setIsDataQualityWarning(true);
      }

      if (changedValues) {
        setInputValues(newInputValues);
      }
    }
  }, [props.items, props.omitDataQualityCheck, props.multiple, inputValues]);

  const CustomPopper = useCallback(
    (popperProps: PopperProps) => {
      const anchorEl = inputRef.current; // Or any other element

      return props.isDictionaryDataFetchingError ? (
        <StyledAutoCompleteErrorMessage
          popperProps={popperProps}
          refreshFunction={refreshHandler}
          tooltipTitle={TooltipMsg.AutoCompleteQualityIssue}
          calculatedWidth={getPopperWidth(anchorEl?.clientWidth)}
          anchorEl={anchorEl}
        />
      ) : (
        <StyledAutoCompletePopper
          {...popperProps}
          anchorEl={anchorEl}
          $calculatedWidth={getPopperWidth(anchorEl?.clientWidth)}
        />
      );
    },
    [props.isDictionaryDataFetchingError, refreshHandler]
  );

  const stopEditing = () => {
    if (!showSelect && props.onClose) props.onClose();
  };

  const onItemsOpen = () => {
    setShowSelect(true);
    if (props.onOpen) props.onOpen();
  };

  const onItemsClose = () => {
    setShowSelect(false);
    if (props.onClose) props.onClose();
  };

  useEffect(() => {
    setShowSelect(false);
  }, [props.closeTrigger]);

  return (
    <AutocompleteInputDiv
      onMouseLeave={stopEditing}
      $paddingTop={props.$paddingTop ? props.$paddingTop : 0}
      ref={inputRef}
      id={props.id + "-autocomplete-div"}
    >
      <StyledAutoComplete
        $paddingLeft={props.$paddingLeft}
        disabled={props.isDisabled}
        autoHighlight
        $isEditable={props.isEditable}
        $isEdited={props.isEdited}
        $isDisabled={props.isDisabled}
        $isWarning={props.isWarning}
        $isError={props.isError}
        selectOnFocus={false}
        noOptionsText={props.noOptionsText ? props.noOptionsText : "No options"}
        PopperComponent={CustomPopper}
        getOptionDisabled={(option: unknown) => {
          const item = option as ComboBoxListItem;
          return !!item.isDisabled;
        }}
        renderOption={props.renderOption}
        multiple={props.multiple}
        disableClearable={!props.multiple || !props.isEditable}
        disableCloseOnSelect={props.multiple}
        size="small"
        id={props.id + "-autocomplete"}
        value={
          props.multiple
            ? inputValues
            : inputValues.length > 0
              ? inputValues[0]
              : null
        }
        options={props.items}
        open={showSelect}
        openText={""}
        closeText={""}
        clearText={""}
        loading={props.isLoading}
        onOpen={onItemsOpen}
        onClose={onItemsClose}
        getOptionLabel={(option) => (option as ComboBoxListItem).value}
        filterSelectedOptions={props.multiple ? true : false}
        isOptionEqualToValue={(option: unknown, value: unknown) => {
          if (value) {
            return (
              (option as ComboBoxListItem).key ===
              (value as ComboBoxListItem).key
            );
          }
          return false;
        }}
        popupIcon={
          <>
            {props.isFetching && <CircularProgress color="inherit" size={20} />}
            {!props.isFetching && (
              <PopupIconControl
                id={""}
                isEditable={props.isEditable}
                isError={props.isError}
                isEdited={props.isEdited}
                isWarning={props.isWarning}
                showSelect={showSelect}
              />
            )}
          </>
        }
        clearIcon={<ClearIconControl multiple={props.multiple} />}
        ListboxProps={{
          style: {
            maxHeight: "300px",
            backgroundColor: defaultTheme.palettes.grayscale.lightest,
          },
        }}
        onChange={handleChange}
        onInputChange={(event, value) => {
          if (props.onInputChange) {
            props.onInputChange(value, event);
          }
        }}
        renderInput={(params) => (
          <TextFieldControl
            params={params}
            label={props.label}
            inputEndAdornment={props.inputEndAdornment}
            inputStartAdornment={props.inputStartAdornment}
            isLoading={props.isLoading}
            placeholder={props.placeholder}
          />
        )}
        renderTags={(selected: unknown) => (
          <AutocompleteDiv id={props.id + "-box"}>
            {(selected as ComboBoxListItem[]).map(
              (item: ComboBoxListItem, index) => {
                return (
                  <AutocompleteChip
                    key={index}
                    item={item}
                    isEditable={props.isEditable}
                    isDisabled={props.isDisabled}
                    label={props.label}
                    pillsClassName={props.pillsClassName}
                    handleChange={handleChange}
                    handleDelete={handleDelete}
                    isError={props.isError}
                  />
                );
              }
            )}
          </AutocompleteDiv>
        )}
      />

      <ValidationControl
        isError={props.isError}
        isWarning={props.isWarning}
        isMissingValueWarning={isMissingValueWarning}
        isDataQualityWarning={isDataQualityWarning}
        isDictionaryDataFetchingError={props.isDictionaryDataFetchingError}
        dataQualityWarningText={props.dataQualityWarningText}
        missingValueWarningText={props.missingValueWarningText}
        warningMessage={props.warningMessage}
        errorMessages={props.errorMessages}
      />
    </AutocompleteInputDiv>
  );
}
