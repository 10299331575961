import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import TagsListItem from "../../models/tags/tagsListItem";
import { tagsViewLocalStorageKey } from "../hooks/localStorage";
import { RootState } from "../store";

export interface TagsViewParams {
  navigationFlex: number;
  dashboardFlex: number;
  isMinimized: boolean;
}

export interface TagsViewState {
  viewState: TagsViewParams;
  tagsSearch: string;
  selectedTag?: TagsListItem;
  tagValuesSearch: string;
  expandedCategoriesBreadcrumbsIds: Record<string, string[] | undefined>;
}

const defaultSplitViewParams: TagsViewParams = {
  navigationFlex: 0.2,
  dashboardFlex: 0.8,
  isMinimized: false,
};

export function getInitialParams() {
  try {
    const value = localStorage.getItem(tagsViewLocalStorageKey);
    if (value) {
      return JSON.parse(value) as TagsViewParams;
    }

    return defaultSplitViewParams;
  } catch {
    return defaultSplitViewParams;
  }
}

const initialState: TagsViewState = {
  viewState: getInitialParams(),
  tagsSearch: "",
  tagValuesSearch: "",
  selectedTag: undefined,
  expandedCategoriesBreadcrumbsIds: {},
};

export const tagsViewSlice = createSlice({
  name: "tagsView",
  initialState: initialState,
  reducers: {
    setNavigationFlex(state, action: PayloadAction<number>) {
      state.viewState.navigationFlex = action.payload;
    },
    setDashboardFlex(state, action: PayloadAction<number>) {
      state.viewState.dashboardFlex = action.payload;
    },
    setIsNavigationMinimized(state, action: PayloadAction<boolean>) {
      state.viewState.isMinimized = action.payload;
    },
    setTagsSearch(state, action: PayloadAction<string>) {
      state.tagsSearch = action.payload;
    },
    setTagsValueSearch(state, action: PayloadAction<string>) {
      state.tagValuesSearch = action.payload;
    },
    setSelectedTag(state, action: PayloadAction<TagsListItem | undefined>) {
      state.selectedTag = action.payload;
    },
    expandTagCategoryBreadcrumb: (
      state,
      action: PayloadAction<{ tagId: string; categoryId: string }>
    ) => {
      const { tagId, categoryId } = action.payload;
      if (state.expandedCategoriesBreadcrumbsIds[tagId]) {
        state.expandedCategoriesBreadcrumbsIds[tagId]?.push(categoryId);
      } else {
        state.expandedCategoriesBreadcrumbsIds[tagId] = [categoryId];
      }
    },
    collapseTagCategoryBreadcrumb: (
      state,
      action: PayloadAction<{ tagId: string; categoryId: string }>
    ) => {
      const { tagId, categoryId } = action.payload;
      if (state.expandedCategoriesBreadcrumbsIds[tagId]) {
        state.expandedCategoriesBreadcrumbsIds[tagId] =
          state.expandedCategoriesBreadcrumbsIds[tagId]?.filter(
            (x) => x != categoryId
          );
      }
    },
    collapseAllTagCategoryBreadcrumbs: (state) => {
      state.expandedCategoriesBreadcrumbsIds = {};
    },
  },
});

export const {
  setNavigationFlex,
  setDashboardFlex,
  setIsNavigationMinimized,
  setTagsSearch,
  setSelectedTag,
  setTagsValueSearch,
  expandTagCategoryBreadcrumb,
  collapseTagCategoryBreadcrumb,
  collapseAllTagCategoryBreadcrumbs,
} = tagsViewSlice.actions;

export const selectDashboardFlex = (state: RootState) =>
  state.tagsView.viewState.dashboardFlex;

export const selectNavigationFlex = (state: RootState) =>
  state.tagsView.viewState.navigationFlex;

export const selectIsNavigationMinimized = (state: RootState) =>
  state.tagsView.viewState.isMinimized;

export const selectTagsSearch = (state: RootState) => state.tagsView.tagsSearch;
export const selectTagsValueSearch = (state: RootState) =>
  state.tagsView.tagValuesSearch;

export const selectSelectedTag = (state: RootState) =>
  state.tagsView.selectedTag;

export const selectCanEditSelectedTag = (state: RootState) =>
  state.tagsView.selectedTag?.canEdit;

export const selectExpandedTagCategoriesBreadcrumbsIds = createSelector(
  [
    (state: RootState) => state.tagsView.expandedCategoriesBreadcrumbsIds,
    (state, tagId: string) => tagId,
  ],
  (groups, tagId) => groups[tagId] ?? []
);

export default tagsViewSlice.reducer;
