import styled from "styled-components";
import { WordBreakType } from "../../../../../../models/documentList/types/WordBreakType";

export interface DocumentTextSpanProps {
  bold?: boolean;
  italic?: boolean;
  $wordBreak: WordBreakType;
  setTextAlignRight?: boolean;
  $enableEllipsis?: boolean;
  $hiddenOverflow?: boolean;
  $paddingLeft?: number;
  $paddingRight?: number;
  $flexGrow?: number;
}

function getWordBreak(wordBreak?: WordBreakType) {
  if (wordBreak === WordBreakType.BreakAll) {
    return "break-all";
  } else if (wordBreak === WordBreakType.BreakWord) {
    return "break-word";
  }
  return "unset";
}

export const DocumentTextSpan = styled.span<DocumentTextSpanProps>`
  display: block;
  flex-grow: ${(props) => (props.$flexGrow ? props.$flexGrow : "unset")};
  align-self: center;
  word-break: ${(props) => getWordBreak(props.$wordBreak)};
  text-overflow: ${(props) => (props.$enableEllipsis ? "ellipsis" : "unset")};
  white-space: ${(props) => (props.$enableEllipsis ? "nowrap" : "normal")};
  overflow: ${(props) => (props.$hiddenOverflow ? "hidden" : "unset")};
  padding-left: ${(props) =>
    props.$paddingLeft ? props.$paddingLeft + "px" : "0"};
  padding-right: ${(props) =>
    props.$paddingRight ? props.$paddingRight + "px" : "0"};
  font-style: ${(props) => (props.italic ? "italic" : "normal")};
  text-align: ${(props) => (props.setTextAlignRight ? "right" : "unset")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;
