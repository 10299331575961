import { DialogControl } from "../../../controls/Dialog/DialogControl";
import { StyledLink } from "./SC/StyledLink";
import { StyledDialogContentText } from "../../details/selfHelpPopup/SC/StyledDialogContentText";

interface DocumentUrlPopupProps {
  requestAccessLink: string;
  treeLink: string;
  isOpen: boolean;
  handleClose: () => void;
}

export const getRequestAccessInfo = (
  requestAccessLink: string,
  treeLink: string,
  isSplashScreen?: boolean
) => {
  return (
    <>
      <span>To get access, please submit a </span>
      <StyledLink
        href={`${requestAccessLink}`}
        underline="always"
        rel="noopener noreferrer"
        target="_blank"
        $splashScreen={isSplashScreen}
      >
        request
      </StyledLink>
      <span>
        {
          " in MyIS indicating a category to which access should be granted. If you do not know the category name, please check it in "
        }
      </span>
      <StyledLink
        href={`${treeLink}`}
        underline="always"
        rel="noopener noreferrer"
        target="_blank"
        $splashScreen={isSplashScreen}
      >
        ABB Products Tree
      </StyledLink>
      <span>{" or in the category selection on the left."}</span>
    </>
  );
};

export const RequestAccessPopup = (props: DocumentUrlPopupProps) => {
  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    props.handleClose();
  };

  return (
    <DialogControl
      id={"request-access-popup"}
      isOpen={props.isOpen}
      fullWidth
      useActions={false}
      title={"How to request access"}
      content={
        <StyledDialogContentText>
          {getRequestAccessInfo(props.requestAccessLink, props.treeLink)}
        </StyledDialogContentText>
      }
      onCloseClick={handleClose}
      maxWidth={"sm"}
    />
  );
};
