import { useEffect, useMemo } from "react";
import { useHardDeleteDocumentMutation } from "../../../../../apis/documentApi";
import { openSnackbar } from "../../../../../app/helpers/snackBarHelper";
import { SnackbarType } from "../../../../../models/snackbar";
import { useAppDispatch } from "../../../../../app/hooks/hooks";
import { useNavigate } from "react-router-dom";
import { ErrorApi, ErrorApiResponse } from "../../../../../models/errorApi";
import { ErrorApiType } from "../../../../../models/errorApiType";
import { find } from "lodash";
import { setIsUpdatingDocument } from "../../../../../app/slices/documentDetailsSlice";
import { SnackbarMsg } from "../../../../../controls/Snackbar/SnackbarMessages";

export function useHardDelete() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [
    hardDeleteDocument,
    { isError, isSuccess, isLoading: isHardDeleting, error },
  ] = useHardDeleteDocumentMutation();

  const errors = useMemo(() => {
    if (isError) {
      return !!error &&
        "status" in error &&
        !!(error.data as ErrorApiResponse | undefined)?.errors
        ? (error.data as ErrorApiResponse).errors
        : [{ message: "", code: ErrorApiType.Exception }];
    }

    return [];
  }, [isError, error]);

  useEffect(() => {
    if (isError) {
      let title: string = SnackbarMsg.HardDeleteError;

      const indexingError = find(errors, (error: ErrorApi) => {
        return error.code === ErrorApiType.DocumentNotYetIndexed;
      });

      if (indexingError) {
        title = indexingError.message;
      }

      openSnackbar(dispatch, title, SnackbarType.error);
    }
  }, [dispatch, isError, errors]);

  useEffect(() => {
    if (isSuccess) {
      navigate("/");
      openSnackbar(
        dispatch,
        SnackbarMsg.HardDeleteSuccess,
        SnackbarType.success
      );
    }
  }, [dispatch, isSuccess, navigate]);

  useEffect(() => {
    if (isHardDeleting) {
      dispatch(setIsUpdatingDocument(true));
    } else {
      dispatch(setIsUpdatingDocument(false));
    }
  }, [dispatch, isHardDeleting]);

  return { hardDeleteDocument, isHardDeleting };
}
