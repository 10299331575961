import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dictionary } from "lodash";
import NavigationCategory from "../../models/navigationCategories/navigationCategory";
import { RootState } from "../store";
import { rootCategoryId } from "./navigationSlice";

interface NavigationCategoryTreeState {
  categoriesChildrenByParent: Dictionary<string[] | undefined>;
  categories: Dictionary<NavigationCategory | undefined>;
  startedCategoriesFirstLoad: boolean;
}

const initialNavigationCategoryTreeState: NavigationCategoryTreeState = {
  categoriesChildrenByParent: {},
  categories: {},
  startedCategoriesFirstLoad: false,
};

export const navigationCategoryTreeSlice = createSlice({
  name: "navigationCategoryTreeState",
  initialState: initialNavigationCategoryTreeState,
  reducers: {
    addNavigationCategories(
      state,
      action: PayloadAction<NavigationCategory[]>
    ) {
      action.payload.map((category) => {
        if (
          state.categoriesChildrenByParent[category.parentCategoryId] ===
          undefined
        ) {
          state.categoriesChildrenByParent[category.parentCategoryId] = [];
        }

        state.categoriesChildrenByParent[category.parentCategoryId]?.push(
          category.categoryId
        );

        state.categories[category.categoryId] = {
          ...category,
          isExpanded: category.categoryId === rootCategoryId,
          hasChildrenWithoutFacets: category.hasChildren,
        };
      });
    },
    updateNavigationCategories(
      state,
      action: PayloadAction<{
        parentCategoryId: string;
        categories: NavigationCategory[];
      }>
    ) {
      const { parentCategoryId, categories } = action.payload;
      state.categoriesChildrenByParent[parentCategoryId] = [];

      categories.forEach((category) => {
        if (category.categoryId !== rootCategoryId) {
          const categoryFromDictionary = state.categories[category.categoryId];
          const updated: NavigationCategory = {
            ...category,
            isExpanded: categoryFromDictionary?.isExpanded ?? false,
            hasChildrenWithoutFacets:
              categoryFromDictionary?.hasChildrenWithoutFacets ?? false,
          };
          state.categoriesChildrenByParent[parentCategoryId]?.push(
            category.categoryId
          );
          state.categories[category.categoryId] = updated;
        } else {
          (
            state.categories[category.categoryId] as NavigationCategory
          ).numberOfDocuments = category.numberOfDocuments;
        }
      });
    },
    setIsExpanded(
      state,
      action: PayloadAction<{ categoryId: string; isExpanded: boolean }>
    ) {
      const { categoryId, isExpanded } = action.payload;
      if (state.categories[categoryId] !== undefined) {
        (state.categories[categoryId] as NavigationCategory).isExpanded =
          isExpanded;
      }
    },
    setStartedCategoriesFirstLoad(state) {
      state.startedCategoriesFirstLoad = true;
    },
  },
});

export const {
  addNavigationCategories,
  updateNavigationCategories,
  setIsExpanded,
  setStartedCategoriesFirstLoad,
} = navigationCategoryTreeSlice.actions;

const selectCategoriesByParentDictionary = (state: RootState) =>
  state.navigationCategoryTreeState.categoriesChildrenByParent;

const selectCategoriesDictionary = (state: RootState) =>
  state.navigationCategoryTreeState.categories;

export const selectCategoriesChildrenByParentId = createSelector(
  [
    selectCategoriesByParentDictionary,
    (state, parentCategoryId: string) => parentCategoryId,
  ],
  (dictionary, parentCategoryId) => dictionary[parentCategoryId]
);

export const selectCategoryById = createSelector(
  [selectCategoriesDictionary, (state, categoryId: string) => categoryId],
  (dictionary, categoryId) => dictionary[categoryId]
);

export const selectStartedCategoriesFirstLoad = (state: RootState) =>
  state.navigationCategoryTreeState.startedCategoriesFirstLoad;

export default navigationCategoryTreeSlice.reducer;
