import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { ContextMenuStepProps } from "./models";
import UnpublishedIcon from "@mui/icons-material/Unpublished";

export function DiscardDraftStep(props: ContextMenuStepProps) {
    const theme = useTheme();

    return (
        <StepContentDiv>
            <Paragraph>
                {!props.isVisible && <><UnpublishedIcon style={{
                    fontSize: "16px",
                    verticalAlign: "middle",
                    color: theme.palettes.grayscale.dark
                }} /><b> DISCARD DRAFT</b> - </>}
                This action removes the currently edited Draft/document In Approval.
            </Paragraph>
        </StepContentDiv>
    );
}
