import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";


export function InternalExternalStep() {
    const theme = useTheme();

    return (
        <StepContentDiv>
            <Paragraph>
                Selected combination have following effects (ABB employee and External users need to be logged in):
            </Paragraph>
            <ul>
                <li>
                    <Paragraph>
                        <span>Internal: <b>Limited</b> + External: <b>None</b> <span style={{ color: theme.palettes.secondary.internal }}>(INTERNAL)</span></span>
                        <ul>
                            <li>ABB employee in access groups selected for document will be able to see the document</li>
                            <li>ABB employee <b>NOT</b> in access groups selected for document will <b>NOT</b> be able to see the document</li>
                            <li>External user will <b>NOT</b> be able to see the document</li>
                            <li>Anonymous user will <b>NOT</b> be able to see the document</li>
                        </ul>
                    </Paragraph>
                </li>
                <li>
                    <Paragraph>
                        <span>Internal: <b>All</b> + External: <b>None</b> <span style={{ color: theme.palettes.secondary.internal }}>(INTERNAL)</span></span>
                        <ul>
                            <li>ABB employee will be able to see the document</li>
                            <li>External user will <b>NOT</b> be able to see the document</li>
                            <li>Anonymous user will <b>NOT</b> be able to see the document</li>
                        </ul>
                    </Paragraph>
                </li>
                <li>
                    <Paragraph>
                        <span>Internal: <b>Limited</b> + External: <b>Limited</b> <span style={{ color: theme.palettes.secondary.premium }}>(PREMIUM)</span></span>
                        <ul>
                            <li>ABB employee and external user in access groups selected for document will be able to see the document</li>
                            <li>ABB employee and external user <b>NOT</b> in access groups selected for document will <b>NOT</b> be able to see the document</li>
                            <li>Anonymous user will <b>NOT</b> be able to see the document</li>
                        </ul>
                    </Paragraph>
                </li>
                <li>
                    <Paragraph>
                        <span>Internal: <b>All</b> + External: <b>Limited</b> <span style={{ color: theme.palettes.secondary.premium }}>(PREMIUM)</span></span>
                        <ul>
                            <li>ABB employee will be able to see the document</li>
                            <li>External in access groups selected for document will be able to see the document</li>
                            <li>External user <b>NOT</b> in access groups selected for document will <b>NOT</b> be able to see the document</li>
                            <li>Anonymous user will <b>NOT</b> be able to see the document</li>
                        </ul>
                    </Paragraph>
                </li>
                <li>
                    <Paragraph>
                        <span>Internal: <b>All</b> + External: <b>Hidden</b> <span style={{ color: theme.palettes.secondary.public }}>(PUBLIC)</span></span>
                        <ul>
                            <li>ABB employees will be able to search for and see this document in Download Center and Download Section</li>
                            <li>External and Anonymous user will be able to see the document from a direct link</li>
                        </ul>
                    </Paragraph >
                </li>
                <li>
                    <Paragraph>
                        <span>Internal: <b>All</b> + External: <b>All</b> <span style={{ color: theme.palettes.secondary.public }}>(PUBLIC)</span></span>
                        <ul>
                            <li>Everyone will be able to see the document</li>
                        </ul>
                    </Paragraph >
                </li>
            </ul>

        </StepContentDiv >
    );
}
