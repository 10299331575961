import { TreeItemSkeletonDiv } from "./SC/TreeItemSkeletonDiv";
import { TreeItemSkeleton } from "./TreeItemSkeleton";

interface CategoryTreeViewSkeletonProps {
  width: number;
  numberOfLevels: number;
  defaultPadding: number;
}

export function TreeViewSkeleton(props: CategoryTreeViewSkeletonProps) {
  return (
    <TreeItemSkeletonDiv $padding={props.defaultPadding}>
      <TreeItemSkeleton
        parentCategoryId="skeleton"
        numbersOfSkeleton={1}
        treeDepth={0}
        padding={props.defaultPadding}
        width={props.width}
      />
      {Array.from({ length: props.numberOfLevels }, (_, i) => (
        <div key={i}>
          <TreeItemSkeleton
            parentCategoryId={"skeleton" + i}
            treeDepth={1}
            width={props.width}
            padding={props.defaultPadding}
            numbersOfSkeleton={1}
          />
          <TreeItemSkeleton
            parentCategoryId={"skeleton" + i}
            treeDepth={2}
            width={props.width}
            padding={props.defaultPadding}
            numbersOfSkeleton={3}
          />
        </div>
      ))}
    </TreeItemSkeletonDiv>
  );
}
