import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function ResetFiltersStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The Reset filters button removes all filters, and the results are
        automatically reloaded.
      </Paragraph>
    </StepContentDiv>
  );
}
