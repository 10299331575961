import styled from "styled-components";
import { OutlinedInput } from "@mui/material";

export const PageNumberInput = styled(OutlinedInput)`
  height: 28px;
  max-width: 72px;
  background-color: ${(props) => props.theme.palettes.grayscale.white};
  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palettes.primary.main};
  }

  & input[type="number"] {
    -moz-appearance: textfield;
    text-align: center;
  }
  & input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
