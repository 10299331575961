import styled from "styled-components";

interface ParagraphProps {
  height?: number;
}

export const Paragraph = styled.p<ParagraphProps>`
  margin-top: 0px;
  align-items: center;
  width: 100%;
  height: ${(props) => (props.height ? `${props.height}px` : "auto")};
  object-fit: contain;
`;
