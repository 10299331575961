import { useCallback, useEffect, useRef } from "react";
import {
  publisherCategoriesApi,
  useLazyGetOwnerCategoriesQuery,
} from "../../apis/publisherCategoriesApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import {
  clearOwnerCategories,
  removeSelectedNode,
  updateOwnerCategories,
} from "../../app/slices/ownerCategoryTreeSlice";
import { selectPublishersTabType } from "../../app/slices/publishersViewSlice";
import { Footer } from "../footer/Footer";
import { DashboardDiv } from "./SC/DashboardDiv";
import { PublishersDiv } from "./SC/PublishersDiv";
import { CategoryTab } from "./tabs/categoryTab/CategoryTab";
import { PublisherTab } from "./tabs/publisherTab/PublisherTab";
import { Tabs } from "./tabs/Tabs";
import { setCanExport } from "../../app/slices/publishersListSlice";

export enum PublishersTabType {
  Category = "Category",
  Publisher = "Publisher",
}

const initialCategoriesArgs = {
  maxDepth: 1,
  parentCategoryId: "Root",
  includeHighestParent: true,
  maxCategoryLevelShow: 0,
};

const abortReason = "Page changed";

export function Publishers() {
  const tabIndex = useAppSelector(selectPublishersTabType);
  const dispatch = useAppDispatch();
  const firstOpen = useRef(true);
  const abortController = useRef<AbortController>();

  const [getOwnerCategories, { data, isFetching, isSuccess }] =
    useLazyGetOwnerCategoriesQuery();

  const fetchCategories = useCallback(() => {
    void getOwnerCategories({
      ...initialCategoriesArgs,
      signal: abortController.current?.signal,
    });
  }, [getOwnerCategories]);

  useEffect(() => {
    if (firstOpen.current) {
      fetchCategories();
      firstOpen.current = false;
    }
  }, [fetchCategories]);

  useEffect(() => {
    if (data) {
      dispatch(updateOwnerCategories(data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    abortController.current = new AbortController();

    return () => {
      publisherCategoriesApi.util.resetApiState();
      dispatch(clearOwnerCategories(true));
      dispatch(removeSelectedNode());
      dispatch(setCanExport(false));
      abortController.current?.abort(abortReason);
    };
  }, [dispatch]);

  return (
    <DashboardDiv id="dashboard-div">
      <PublishersDiv id="publishers-div">
        <Tabs />
        {tabIndex === PublishersTabType.Category && (
          <CategoryTab
            isSuccessCategories={isSuccess}
            isLoadingCategories={isFetching}
          />
        )}
        {tabIndex === PublishersTabType.Publisher && <PublisherTab />}
      </PublishersDiv>
      <Footer />
    </DashboardDiv>
  );
}
