import { useEffect, useState } from "react";
import { FormHelperControl } from "../../../../controls/FormHelper/FormHelperControl";
import { ValidationStatus } from "../../../../models/validationRule";
import {
  ConditionValidationResult,
  ConditionValidationRules,
} from "./ConditionValidationRules";

interface SectionValidationProps {
  rules: ConditionValidationRules;
  onValidationHandle: (result?: ConditionValidationResult) => void;
  marginBottom?: number;
  marginTop?: number;
}

function SectionValidation({
  rules,
  onValidationHandle,
  marginBottom,
  marginTop,
}: SectionValidationProps) {
  const [validationResult, setValidationResult] = useState<
    ConditionValidationResult | undefined
  >();

  useEffect(() => {
    const validationResult = rules.rules
      .map((rule) => rule())
      .find((result) => result.isError);

    setValidationResult(validationResult);
    onValidationHandle(validationResult);
  }, [rules, onValidationHandle]);

  if (validationResult) {
    return (
      <FormHelperControl
        isWarning={validationResult.status === ValidationStatus.warning}
        isError={validationResult.status === ValidationStatus.error}
        texts={[validationResult.errorMessage]}
        marginTop={marginTop ? marginTop : -1}
        marginBottom={marginBottom}
        isVisible={true}
      />
    );
  }

  return <></>;
}

export default SectionValidation;
