import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import styled from "styled-components";

export const StyledGridToolbarQuickFilter = styled(GridToolbarQuickFilter)`
  &.MuiDataGrid-toolbarQuickFilter {
    margin-left: auto;
    padding-right: 0px;
    padding-top: 8px;
    width: 420px;

    fieldset {
      border-color: ${(props) => props.theme.palettes.grayscale.medium};
    }

    .Mui-focused fieldset {
      border-color: ${(props) => props.theme.palettes.grayscale.darkest};
    }

    @media only screen and (max-width: 750px) {
      display: none;
    }
  }
`;
