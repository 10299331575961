import { Outlet } from "react-router-dom";
import { useAppSelector } from "../../app/hooks/hooks";
import { selectCurrentSplash } from "../../app/slices/commonSlice";
import { Header } from "../header/Header";
import { PageLayoutDiv } from "./SC/PageLayoutDiv";

export const routing = {
  details: "/document",
  newDocument: "/document/new",
  newPart: "/document/new/part",
  newLanguage: "/document/new/language",
  newTemplate: "/document/new/template",
  newRevision: "/document/new/revision",
  publishers: "/publishers",
};

export function PageLayout() {
  const currentSplashScreen = useAppSelector(selectCurrentSplash);

  return (
    <PageLayoutDiv id="app-div" $splashScreen={currentSplashScreen}>
      <Header />
      <Outlet />
    </PageLayoutDiv>
  );
}
