import { createApi } from "@reduxjs/toolkit/query/react";
import { publishApiBaseQuery } from "./baseQueries";

export const documentAttachmentApi = createApi({
  reducerPath: "documentAttachmentApi",
  baseQuery: publishApiBaseQuery,
  endpoints: (builder) => ({
    getDocumentAttachmentUrl: builder.query<string, string>({
      query: (documentRevisionId) => ({
        url: `DocumentAttachment/${documentRevisionId}`,
        method: "GET",
        responseHandler: "text",
      }),
    }),
    breakDocumentLink: builder.mutation<boolean, bigint>({
      query: (documentRevisionId) => ({
        url: `DocumentAttachment/${documentRevisionId}/Link`,
        method: "Patch",
      }),
    }),
  }),
});

export const {
  useGetDocumentAttachmentUrlQuery,
  useLazyGetDocumentAttachmentUrlQuery,
  useBreakDocumentLinkMutation,
} = documentAttachmentApi;
export default documentAttachmentApi.reducer;
