import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import { ReactNode, useRef, useState } from "react";
import { useTheme } from "styled-components";
import { TextButtonControl } from "./TextButtonControl";
import { SpeedButtonDiv } from "./SC/SpeedButtonDiv";
import { StyledMenuItem } from "./SC/StyledMenuItem";
import { TooltipTop } from "../Tooltips/ToolipTop";
import { Option } from "../../models/option";
import { StyledGrow } from "./SC/StyledGrow";

interface SpeedButtonControlProps {
  id: string;
  text: string;
  options: Option[];
  icon?: ReactNode;
  isVisible: boolean;
  isCompactView: boolean;
  disabled?: boolean;
  disabledTooltipText?: string;
  colors: "primary" | "secondary";
}

export function SpeedButtonControl(props: SpeedButtonControlProps) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClose = (event: Event) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function optionClick(option: Option) {
    setOpen(false);
    option.onClick();
  }

  return (
    <SpeedButtonDiv ref={anchorRef}>
      <TextButtonControl
        colors={props.colors}
        height={theme.shapes.primaryButtonHeight}
        icon={props.icon}
        id={props.id}
        text={props.text}
        onClick={(event) => {
          event.stopPropagation();
          setOpen(true);
        }}
        disabled={props.disabled}
        disabledTooltipText={props.disabledTooltipText}
        isCompactView={props.isCompactView}
        isVertical={false}
        isVisible={props.isVisible}
        endIcon={<ArrowDropDownIcon />}
      />
      <Popper
        sx={{
          zIndex: 100,
        }}
        open={open}
        anchorEl={anchorRef.current}
        onMouseEnter={() => {
          setOpen(true);
        }}
        onMouseLeave={() => {
          setOpen(false);
        }}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <StyledGrow {...TransitionProps} $placement={placement}>
            <Paper>
              <ClickAwayListener
                onClickAway={handleClose}
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
              >
                <MenuList id="split-button-menu">
                  {props.options.map((option, index) => (
                    <TooltipTop
                      key={index}
                      title={
                        !option.hasAccess
                          ? option.disabledTooltip
                            ? option.disabledTooltip
                            : ""
                          : option.description
                      }
                    >
                      <StyledMenuItem
                        key={index}
                        $isDisabled={!option.hasAccess}
                        disableRipple={!option.hasAccess}
                        onClick={
                          option.hasAccess
                            ? () => optionClick(option)
                            : undefined
                        }
                      >
                        {option.text}
                      </StyledMenuItem>
                    </TooltipTop>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </StyledGrow>
        )}
      </Popper>
    </SpeedButtonDiv>
  );
}
