import { IconButton } from "@mui/material";
import { Moment } from "moment";
import styled, { DefaultTheme } from "styled-components";

interface IconButtonProps {
  $marginRight?: number;
  $marginLeft?: number;
  padding: number;
  cursor?: string;
  $colorTheme?: "green" | "yellow" | "red";
  $visibility: Moment | null;
}

function setMargin(marginRight?: number, marginLeft?: number) {
  let marginString = "4px";

  if (marginRight !== undefined) {
    marginString += ` ${marginRight}px`;
  } else {
    marginString += " 0px";
  }

  marginString += " 4px";

  if (marginLeft !== undefined) {
    marginString += ` ${marginLeft}px`;
  } else {
    marginString += " 0px";
  }

  return marginString;
}

export const StyledIconButton = styled(IconButton)<IconButtonProps>`
  visibility: ${(props) => (props.$visibility ? "visible" : "hidden")};
  order: 1;
  padding: 0;
  margin: 0;
  &.MuiButtonBase-root {
    color: ${(props) => getColor(props.theme, props.$colorTheme)};
    cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};
    padding: ${(props) => props.padding}px;
    margin: ${(props) => setMargin(props.$marginRight, props.$marginLeft)};
  }
`;
function getColor(theme: DefaultTheme, color?: "green" | "yellow" | "red") {
  if (color === "red") {
    return theme.palettes.primary.main;
  }

  if (color === "yellow") {
    return theme.palettes.primary.yellow;
  }

  if (color === "green") {
    return theme.palettes.primary.green;
  }

  return theme.palettes.grayscale.medium;
}
