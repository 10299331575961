import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SearchBoxStep3() {
  return (
    <StepContentDiv>
      <Paragraph>
        In this section, document kinds can be searched for in the list using either the full name or part of it.
      </Paragraph>
    </StepContentDiv>
  );
}
