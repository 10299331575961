import { InputAdornment } from "@mui/material";
import {
  getValidationStatusText,
  getSectionHeaderColor,
} from "../../../../app/helpers/validationHelper";
import { TooltipTop } from "../../../../controls/Tooltips/ToolipTop";
import React, { ReactElement, useCallback } from "react";
import { StyledEditIcon } from "../SC/StyledEditIcon";
import { StyledWarningIcon } from "../SC/StyledWarningIcon";

interface TextFieldInputAdornmentProps {
  id: string;
  inputAdornment?: React.ReactNode;
  isEdited?: boolean;
  isError?: boolean;
  isWarning?: boolean;
}

export function TextFieldInputAdornment(props: TextFieldInputAdornmentProps) {
  const getIcon = useCallback((): ReactElement => {
    const editIcon = (
      <StyledEditIcon
        id={props.id + "-edit"}
        fontSize="inherit"
        $color={getSectionHeaderColor(
          props.isError,
          props.isEdited,
          props.isWarning
        )}
      />
    );

    const warningIcon = (
      <StyledWarningIcon
        id={props.id + "-edit"}
        fontSize="inherit"
        $color={getSectionHeaderColor(
          props.isError,
          props.isEdited,
          props.isWarning
        )}
      />
    );

    if (props.isEdited && !props.isWarning && !props.isError) return editIcon;
    if (props.isWarning || props.isError) return warningIcon;

    return <></>;
  }, [props.id, props.isEdited, props.isError, props.isWarning]);

  return (
    <InputAdornment position="end">
      {props.inputAdornment}
      {(props.isEdited || props.isError) && (
        <TooltipTop
          title={getValidationStatusText(
            props.isError,
            props.isEdited,
            props.isWarning
          )}
        >
          {getIcon()}
        </TooltipTop>
      )}
    </InputAdornment>
  );
}
