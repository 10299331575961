import styled from "styled-components";

export const TabDiv = styled.div`
  display: flex;
  height: calc(100% - 48px);
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.palettes.grayscale.light};
  flex-direction: column;
  align-items: center;
`;
