import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { resetPageNumber } from "../../../../../app/slices/documentListSlice";
import {
  selectCategoryId,
  selectCurrentLanguagesFilter,
  setCurrentLanguagesFilter,
  setDocumentLanguageCodesFilter,
} from "../../../../../app/slices/navigationSlice";
import { useGetLanguagesListByCategoryIdQuery } from "../../../../../apis/languagesApi";
import {
  getKeys,
  ComboBoxListItem,
} from "../../../../../models/autocompleteValue";
import { useEffect, useCallback, useState } from "react";
import { openSnackbar } from "../../../../../app/helpers/snackBarHelper";
import { SnackbarType } from "../../../../../models/snackbar";
import { FiltersAutoComplete } from "../FiltersAutoComplete";
import { SnackbarMsg } from "../../../../../controls/Snackbar/SnackbarMessages";

export function LanguagesFilter() {
  const dispatch = useAppDispatch();
  const categoryId = useAppSelector(selectCategoryId);
  const currentLanguagesFilter = useAppSelector(selectCurrentLanguagesFilter);
  const onLanguagesFilterChange = useCallback(
    (values: ComboBoxListItem[]) => {
      dispatch(setCurrentLanguagesFilter(values));
      dispatch(setDocumentLanguageCodesFilter(getKeys(values)));
      dispatch(resetPageNumber());
    },
    [dispatch]
  );

  const {
    data: response,
    refetch,
    isError,
    isLoading,
  } = useGetLanguagesListByCategoryIdQuery(categoryId);
  const [isGetQueryFailed, setIsGetQueryFailed] = useState(false);

  useEffect(() => {
    setIsGetQueryFailed(isError);
  }, [isError]);

  useEffect(() => {
    setIsGetQueryFailed(false);
  }, [categoryId]);

  useEffect(() => {
    if (isGetQueryFailed) {
      openSnackbar(dispatch, SnackbarMsg.LanguagesError, SnackbarType.error);
    }
  }, [dispatch, isGetQueryFailed]);

  return (
    <div id="navigation-filters-languages">
      <FiltersAutoComplete
        id="navigation-languages-combobox"
        pillsClassName="language-pill"
        label="Languages"
        items={
          response?.map<ComboBoxListItem>((item) => {
            return {
              key: item.isoCode,
              value: item.name,
            };
          }) ?? []
        }
        missingValueWarningText={
          "Selected languages are no longer available in the filters list"
        }
        onChangeHandler={onLanguagesFilterChange}
        isLoading={isLoading}
        selected={currentLanguagesFilter}
        isDictionaryDataFetchingError={isError}
        onRefreshHandler={() => void refetch}
      />
    </div>
  );
}
