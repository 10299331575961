import { createStep } from "../../helpers/selfHelpBuilder";
import { DocumentNumberFirstStep } from "./Identity/DocumentNumberFirstStep";
import { DocumentNumberSecondStep } from "./Identity/DocumentNumberSecondStep";
import { LanguageFirstStep } from "./Identity/LanguageFirstStep";
import { LanguageSecondStep } from "./Identity/LanguageSecondStep";
import { PartStep } from "./Identity/PartStep";
import { RevisionStepOne } from "./Identity/RevisionStepOne";
import { RevisionStepTwo } from "./Identity/RevisionStepTwo";

export const identitySteps = () => {
  return [
    createStep("#document-number-input", <DocumentNumberFirstStep />, 820, "auto"),
    createStep("#document-number-input", <DocumentNumberSecondStep />, 520, "auto"),
    createStep("#language-autocomplete", <LanguageFirstStep />, 820, "auto"),
    createStep("#language-autocomplete", <LanguageSecondStep />, 820, "auto"),
    createStep("#part-input", <PartStep />, 820, "auto"),
    createStep("#revision-dropdown", <RevisionStepOne />, 520, "auto"),
    createStep("#revision-dropdown", <RevisionStepTwo />, 520, "auto"),
  ];
};
