import { GridColumnVisibilityModel, GridDensity } from "@mui/x-data-grid";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { publishersListLocalStorageKey } from "../hooks/localStorage";
import { RootState } from "../store";

export interface PublisherDirectCategoriesParams {
  density: GridDensity;
  pageSize: number;
  columnVisibilityModel?: GridColumnVisibilityModel;
}

const defaultListParams: PublisherDirectCategoriesParams = {
  density: "standard",
  pageSize: 50,
  columnVisibilityModel: undefined,
};

export function getInitialParams() {
  try {
    const value = localStorage.getItem(publishersListLocalStorageKey);
    if (value) {
      return JSON.parse(value) as PublisherDirectCategoriesParams;
    }

    return defaultListParams;
  } catch {
    return defaultListParams;
  }
}

const initialState: PublisherDirectCategoriesParams = {
  ...getInitialParams(),
};

export const publisherDirectCategoriesSlice = createSlice({
  name: "publisherDirectCategories",
  initialState: initialState,
  reducers: {
    setPublisherDirectCategoriesDensity(
      state,
      action: PayloadAction<GridDensity>
    ) {
      state.density = action.payload;
    },
    setPublisherDirectCategoriesPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setPublisherDirectCategoriesColumnVisibility(
      state,
      action: PayloadAction<GridColumnVisibilityModel>
    ) {
      state.columnVisibilityModel = action.payload;
    },
  },
});

export const {
  setPublisherDirectCategoriesDensity,
  setPublisherDirectCategoriesPageSize,
  setPublisherDirectCategoriesColumnVisibility,
} = publisherDirectCategoriesSlice.actions;

export const selectPublisherDirectCategoriesDensity = (state: RootState) =>
  state.publisherDirectCategories.density;

export const selectPublishersDirectCategoriesPageSize = (state: RootState) =>
  state.publisherDirectCategories.pageSize;

export const selectPublishersDirectCategoriesColumnVisibility = (
  state: RootState
) => state.publisherDirectCategories.columnVisibilityModel;

export default publisherDirectCategoriesSlice.reducer;
