import styled, { DefaultTheme } from "styled-components";

interface PillProps {
  $isGray: boolean;
}

function getColor(isGray: boolean, theme: DefaultTheme) {
  if (isGray) {
    return theme.palettes.grayscale.lightest;
  }

  return theme.palettes.grayscale.lighter;
}

function getBackgroundColor(isGray: boolean, theme: DefaultTheme) {
  if (isGray) {
    return theme.palettes.grayscale.medium;
  }

  return theme.palettes.grayscale.dark;
}

export const PillSpan = styled.span<PillProps>`
  width: 24px;
  min-width: 24px;
  height: 14px;
  background-color: ${(props) =>
    getBackgroundColor(props.$isGray, props.theme)};
  color: ${(props) => getColor(props.$isGray, props.theme)};
  font-size: ${(props) => props.theme.typography.fontSizes.small}px;
  border-radius: 2px;
  margin-right: 3px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
