import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function CategorySectionStep5() {
    return (
        <StepContentDiv>
            <Paragraph>
                When a category is selected, the publishers list will show publishers that have access to publish in this category, as well as in categories that are nested below it.
            </Paragraph>
        </StepContentDiv>
    );
}
