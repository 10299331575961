import { DragIndicator } from "@mui/icons-material";
import styled from "styled-components";

interface SectionDragIndicatorProps {
  disabled?: boolean;
}

export const SectionDragIndicator = styled(
  DragIndicator
)<SectionDragIndicatorProps>`
  color: ${(props) =>
    props.disabled
      ? props.theme.palettes.grayscale.disabledColor
      : props.theme.palettes.grayscale.dark};
  padding-right: 2px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "move")};
  display: flex;
`;
