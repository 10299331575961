import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/cms/config.png"

export function CategoryWarningStep3() {
  return (
    <StepContentDiv>
      <Paragraph>
        In this field, the tree level until which warning about publishing in high level category is displayed can be defined.
      </Paragraph>
      <Paragraph>
        This results in a warning being displayed when adding categories that are too high level to the document.
      </Paragraph>
      <Paragraph height={340}>
        <Screenshot src={screenshot} />
      </Paragraph>
    </StepContentDiv>
  );
}
