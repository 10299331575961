import { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyGetDirectPublisherCategoriesQuery } from "../../../apis/publishersRolesApi";
import { openSnackbar } from "../../../app/helpers/snackBarHelper";
import { useAppDispatch } from "../../../app/hooks/hooks";
import { SnackbarType } from "../../../models/snackbar";
import { SnackbarMsg } from "../../../controls/Snackbar/SnackbarMessages";

export function usePublisherDirectCategories(email?: string) {
  const dispatch = useAppDispatch();
  const [
    getPublisherCategories,
    {
      data: publisherCategories,
      isError,
      error: categoriesError,
      isUninitialized,
      isFetching,
      isLoading,
    },
  ] = useLazyGetDirectPublisherCategoriesQuery();

  const isLoadingCategories = useMemo(
    () => isLoading || isFetching || isUninitialized,
    [isLoading, isFetching, isUninitialized]
  );
  const [isNotFoundError, setIsNotFoundError] = useState(false);
  const [isCategoriesError, setIsCategoriesError] = useState(false);

  const fetchCategories = useCallback(() => {
    if (email !== undefined) {
      void getPublisherCategories(email);
    }
  }, [getPublisherCategories, email]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    const statusError = categoriesError as { status: number } | undefined;
    const isNotFoundError = statusError?.status === 404;
    setIsCategoriesError(isError && !isNotFoundError);
    setIsNotFoundError(isNotFoundError);

    if (isError && !isNotFoundError) {
      openSnackbar(
        dispatch,
        SnackbarMsg.PublisherCategoriesError,
        SnackbarType.error
      );
    }
  }, [dispatch, categoriesError, isError]);

  return {
    publisherCategories,
    isLoadingCategories,
    isCategoriesError,
    isNotFoundError,
    fetchCategories,
  };
}
