import { BreadcrumbListSeparatorItem } from "./SC/BreadcrumbListSeparatorItem";

interface BreadcrumbSeparatorProps {
  $isVisible: boolean;
  separator: string;
}

export function BreadcrumbSeparator(props: BreadcrumbSeparatorProps) {
  return (
    <BreadcrumbListSeparatorItem $isVisible={props.$isVisible}>
      {props.separator}
    </BreadcrumbListSeparatorItem>
  );
}
