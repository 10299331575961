import { Step } from "react-joyride";
import { createStep } from "../../helpers/selfHelpBuilder";
import { AccessGroupsStep } from "./SecurityLevel/AccessGroupsStep";
import { DropdownStep } from "./SecurityLevel/DropdownStep";
import { InternalExternalStep } from "./SecurityLevel/InternalExternalStep";
import { SectionStepOne } from "./SecurityLevel/SectionStepOne";
import { SectionStepTwo } from "./SecurityLevel/SectionStepTwo";
import { SwapButtonStep } from "./SecurityLevel/SwapButtonStep";
import { SectionStepThree } from "./SecurityLevel/SectionStepThree";

export const securitySteps = (securityTabAlternativeView: boolean, isAccessGroupsControlVisible: boolean) => {
  const steps: Step[] = [];

  steps.push(createStep("#Security", <SectionStepOne />, 520, "auto"));
  steps.push(createStep("#Security-swap", <SwapButtonStep />, 420, "auto"));
  if (securityTabAlternativeView) {
    steps.push(createStep("#internal-external-security-level-control", <InternalExternalStep />, 820, "auto"));
  } else {
    steps.push(createStep("#security-level-dropdownsecurity-select-input-autocomplete-autocomplete-div", <DropdownStep />, 820, "auto"));
  }

  if (isAccessGroupsControlVisible) {
    steps.push(createStep("#access-groups", <AccessGroupsStep />, 720, "auto"));
  } else {
    steps.push(createStep("#Security", <AccessGroupsStep showScreenshot />, 720, "auto"));
  }

  steps.push(createStep("#Security", <SectionStepTwo />, 720, "auto"));
  steps.push(createStep("#Security", <SectionStepThree />, 720, "auto"));

  return steps;

};
