import styled from "styled-components";

interface InputDivProps {
  $paddingTop?: number;
  $marginLeft?: number;
  $paddingBottom?: number;
}

export const InputDiv = styled.div<InputDivProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: ${(props) =>
    props.$paddingTop !== undefined ? props.$paddingTop + "px" : 0};
  padding-bottom: ${(props) =>
    props.$paddingBottom !== undefined ? props.$paddingBottom + "px" : 0};

  .MuiAutocomplete-root {
    margin-left: ${(props) =>
      props.$marginLeft !== undefined ? props.$marginLeft + "px" : 0};
  }
`;
