import EditIcon from "@mui/icons-material/Edit";
import styled from "styled-components";

interface StyledEditIconProps {
  $color: string | undefined;
}

export const StyledEditIcon = styled(EditIcon)<StyledEditIconProps>`
  color: ${(props) => props.$color};
  cursor: default;
`;
