import styled from "styled-components";
import { Density } from "../../../../../../app/slices/models/documentsModels";
import { DocumentsColumnType } from "../../../../../../models/documentList/types/DocumentColumnType";

export interface DocumentsCellDivProps {
  width: number;
  $minWidth: number;
  $maxWidth?: number;
  type: DocumentsColumnType;
  $isVisible: boolean;
  density: Density;
  $fitToScreen: boolean;
}

function getVisibility(isVisible: boolean) {
  if (isVisible) return "flex";

  return "none";
}

function getPadding(density: Density, type: DocumentsColumnType) {
  if (density === Density.Comfort && type !== DocumentsColumnType.Chevron) {
    return "8px";
  } else {
    return "unset";
  }
}

function getMaxWidth(maxWidth: number | undefined) {
  if (maxWidth) {
    return maxWidth + "px";
  } else {
    return "unset";
  }
}

export const DocumentsCellDiv = styled.div<DocumentsCellDivProps>`
  width: ${(props) => props.width}px;
  min-width: ${(props) => props.$minWidth}px;
  max-width: ${(props) => getMaxWidth(props.$maxWidth)};
  margin-top: ${(props) => getPadding(props.density, props.type)};
  margin-bottom: ${(props) => getPadding(props.density, props.type)};
  display: ${(props) => getVisibility(props.$isVisible)};
  align-items: center;
  align-self: center;
`;
