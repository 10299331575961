import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionExpandCollapseStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        Section can be expanded or collapsed by clicking icons on the section header.
      </Paragraph>
    </StepContentDiv>
  );
}
