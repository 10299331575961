import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { Workflow } from "../../../../../models/documentList/types/workflow";
import { resetPageNumber } from "../../../../../app/slices/documentListSlice";
import {
  selectWorkflowsFilter,
  setWorkflowsFilter,
} from "../../../../../app/slices/navigationSlice";
import { SingleFilterDiv } from "../../SC/SingleFilterDiv";
import { TitleSpan } from "../../SC/TitleSpan";
import { StyledToggleButton } from "./styledComponents/StyledToggleButton";
import { StyledToggleButtonGroup } from "./styledComponents/StyledToggleButtonGroup";
import { SectionHeaderDiv } from "../../SC/SectionHeaderDiv";
import { InputDiv } from "../../SC/InputDiv";
import { useTheme } from "styled-components";

export function WorkflowFilter() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const workflowFilters = useAppSelector(selectWorkflowsFilter);

  const handleWorkflowsChange = (
    event: React.MouseEvent,
    value: Workflow[]
  ) => {
    event.preventDefault();
    dispatch(setWorkflowsFilter(value));
    dispatch(resetPageNumber());
  };

  return (
    <SingleFilterDiv id="workflow-filter">
      <SectionHeaderDiv>
        <TitleSpan fontSize={theme.typography.fontSizes.headerT4}>
          Workflow
        </TitleSpan>
      </SectionHeaderDiv>
      <InputDiv>
        <StyledToggleButtonGroup
          id="workflows-toggle-button-group"
          height={theme.shapes.toggleButtonsHeight}
          value={workflowFilters}
          onChange={handleWorkflowsChange}
          size="small"
          fullWidth
        >
          <StyledToggleButton
            id="workflow-draft"
            size="small"
            workflow={Workflow.Draft}
            value={Workflow.Draft}
          >
            DRAFT
          </StyledToggleButton>
          <StyledToggleButton
            id="workflow-approval"
            size="small"
            workflow={Workflow.Approval}
            value={Workflow.Approval}
          >
            IN APPROVAL
          </StyledToggleButton>
          <StyledToggleButton
            id="workflow-published"
            size="small"
            workflow={Workflow.Published}
            value={Workflow.Published}
          >
            PUBLISHED
          </StyledToggleButton>
          <StyledToggleButton
            id="workflow-archived"
            size="small"
            workflow={Workflow.Archived}
            value={Workflow.Archived}
          >
            ARCHIVED
          </StyledToggleButton>
          <StyledToggleButton
            id="workflow-deleted"
            size="small"
            workflow={Workflow.Deleted}
            value={Workflow.Deleted}
          >
            DELETED
          </StyledToggleButton>
        </StyledToggleButtonGroup>
      </InputDiv>
    </SingleFilterDiv>
  );
}
