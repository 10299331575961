import { CategoryTreeContainer } from "./categoryTreeView/CategoryTreeContainer";
import { ListFiltersContainer } from "./categoryTreeView/listFilters/ListFiltersContainer";

import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import {
  categoriesMinHeight,
  filtersMinHeight,
  selectFiltersFlex,
  setFiltersFlex,
} from "../../app/slices/navigationSlice";
import { NavigationDiv } from "./SC/NavigationDiv";
import {
  HandlerProps,
  ReflexContainer,
  ReflexElement,
  ReflexSplitter,
} from "../../controls/Reflex";
import { selectIsWindowTooSmall } from "../../app/slices/commonSlice";

interface NavigationProps {
  onToggleClick: () => void;
  isMinimized: boolean;
}

export function DocumentsNavigation(props: NavigationProps) {
  const dispatch = useAppDispatch();
  const filtersFlex = useAppSelector(selectFiltersFlex);
  const isWindowTooSmall = useAppSelector(selectIsWindowTooSmall);
  const onStopResize = (args: HandlerProps) => {
    if (args.component.props.flex) {
      dispatch(setFiltersFlex(args.component.props.flex));
    }
  };

  if (props.isMinimized || isWindowTooSmall) {
    return <NavigationDiv $isMinimized={true} id="navigation-div" />;
  }

  return (
    <NavigationDiv id="navigation-div" $isMinimized={false}>
      <ReflexContainer orientation="horizontal">
        <ReflexElement className="top-pane" minSize={categoriesMinHeight}>
          <CategoryTreeContainer onToggleClick={props.onToggleClick} />
        </ReflexElement>
        <ReflexSplitter id="dashboard-horizontal-splitter" />
        <ReflexElement
          direction={[-1, 1]}
          onStopResize={onStopResize}
          className="bottom-pane"
          flex={filtersFlex}
          minSize={filtersMinHeight}
        >
          <ListFiltersContainer />
        </ReflexElement>
      </ReflexContainer>
    </NavigationDiv>
  );
}
