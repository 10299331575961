import { PaperControl } from "../../../../controls/Paper/PaperControl";
import { AccessGroupsPaperDiv } from "./SC/AccessGroupsPaperDiv";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import {
  addCurrentAccessGroup,
  removeCurrentAccessGroup,
  selectAccessGroups,
  selectAllAccessGroups,
  selectCurrentAccessGroups,
  selectIsAccessGroupsEdited,
} from "../../../../app/slices/publisherCategoriesSettingsSlice";
import { ListControl } from "../../List/ListControl";
import { CurrentSelfHelp } from "../../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../../models/selfHelp/selfHelpSection";
import { SectionName } from "../../../../app/slices/models/documentDetailsModels";
import { difference } from "lodash";

interface AccessGroupsPaperProps {
  disabled: boolean;
  isLoading: boolean;
}

export function AccessGroupsPaper(props: AccessGroupsPaperProps) {
  const dispatch = useAppDispatch();
  const categoryAccessGroups = useAppSelector(selectAccessGroups);
  const currentCategoryAccessGroups = useAppSelector(selectCurrentAccessGroups);
  const allAccessGroup = useAppSelector(selectAllAccessGroups).map((x) => ({
    id: x.groupId,
    name: x.name,
  }));
  const selectedItems = allAccessGroup.filter((ag) =>
    categoryAccessGroups.includes(ag.id)
  );
  const isShouldBeSorted = currentCategoryAccessGroups === categoryAccessGroups;
  const editedValues = difference(
    currentCategoryAccessGroups,
    categoryAccessGroups
  ).concat(difference(categoryAccessGroups, currentCategoryAccessGroups));
  
  const isEdited = useAppSelector(selectIsAccessGroupsEdited);

  return (
    <PaperControl
      useHeader={true}
      title={"Access groups"}
      disabled={props.disabled}
      currentSelfHelp={CurrentSelfHelp.CmsAccessGroups}
      selfHelpPage={SelfHelpPage.Cms}
      selfHelpSectionName={SectionName.CmsAccessGroups}
      isEdited={isEdited}
    >
      <AccessGroupsPaperDiv id="cms-access-groups-paper">
        <ListControl
          id={"access-group"}
          placeholder="Search for an access group"
          disabled={props.disabled}
          items={allAccessGroup}
          selectedItems={selectedItems}
          sortList={isShouldBeSorted}
          editedValues={editedValues}
          isLoading={props.isLoading}
          selectEventHandler={(e) => dispatch(addCurrentAccessGroup(e))}
          removeEventHandler={(e) => dispatch(removeCurrentAccessGroup(e))}
        />
      </AccessGroupsPaperDiv>
    </PaperControl>
  );
}
