import { includes, sortBy } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import {
  selectLanguages,
  setEditedLanguage,
  setLanguagesDictionary,
} from "../../../../app/slices/documentMetadataSlice";
import { DetailsAutoComplete } from "../../inputs/DetailsAutoComplete";
import { useState, useEffect, useCallback } from "react";
import { ComboBoxListItem } from "../../../../models/autocompleteValue";
import { useLazyGetLanguagesListQuery } from "../../../../apis/languagesApi";
import { openSnackbar } from "../../../../app/helpers/snackBarHelper";
import { SnackbarType } from "../../../../models/snackbar";
import { Editable } from "../common/Editable";
import {
  selectIsLanguagesValidationError,
  selectIsPublishValidationOn,
  setLanguagesValidationStatus,
} from "../../../../app/slices/documentMetadataValidationSlice";
import { ValidationStatus } from "../../../../models/validationRule";
import { useDocumentEditedMode } from "../../../../app/hooks/document/useDocumentEditedMode";
import { SnackbarMsg } from "../../../../controls/Snackbar/SnackbarMessages";

interface LanguageProps extends Editable {
  languageCodes: string[];
  isLoadingDetailsMetaData: boolean;
}

export function Language(props: LanguageProps) {
  const dispatch = useAppDispatch();
  const languagesDictionary = useAppSelector(selectLanguages);
  const isLanguagesValidationError = useAppSelector(
    selectIsLanguagesValidationError
  );
  const isPublishValidationOn = useAppSelector(selectIsPublishValidationOn);
  const { identitySection } = useDocumentEditedMode();
  const [documentLanguages, setDocumentLanguages] = useState<
    ComboBoxListItem[]
  >([]);
  const [
    getLanguagesList,
    {
      data: responseLanguages,
      isError: isLanguagesFetchError,
      isLoading: isLoadingLanguages,
    },
  ] = useLazyGetLanguagesListQuery();

  const loadAllLanguages = useCallback(() => {
    getLanguagesList(null, true)
      .unwrap()
      .catch(() =>
        openSnackbar(
          dispatch,
          SnackbarMsg.LanguagesError,
          SnackbarType.error
        )
      );
  }, [dispatch, getLanguagesList]);

  useEffect(() => {
    if (!props.isLoadingDetailsMetaData) {
      loadAllLanguages();
    }
  }, [loadAllLanguages, props.isLoadingDetailsMetaData]);

  useEffect(() => {
    if (responseLanguages) {
      const enLanguageIndex = responseLanguages.findIndex(
        (x) => x.isoCode === "en"
      );
      const languagesCopy = [...responseLanguages];

      if (enLanguageIndex !== -1) {
        const englishItem = languagesCopy[enLanguageIndex];
        languagesCopy.splice(enLanguageIndex, 1);
        languagesCopy.unshift(englishItem);
      }

      dispatch(setLanguagesDictionary(languagesCopy));
    }
  }, [responseLanguages, dispatch]);

  useEffect(() => {
    if (!props.isLoadingDetailsMetaData && !isLoadingLanguages) {
      const documentLanguages = props.languageCodes.map((x) => {
        const matchingLanguage = languagesDictionary.find(
          (lang) => lang.isoCode === x
        );
        return {
          key: x,
          value: matchingLanguage?.name ?? x,
          isEdited: includes(identitySection.editedLanguages, x),
        };
      });
      setDocumentLanguages(sortBy(documentLanguages, ["isEdited", "value"]));
    }
  }, [
    identitySection.editedLanguages,
    isLoadingLanguages,
    languagesDictionary,
    props.isLoadingDetailsMetaData,
    props.languageCodes,
  ]);

  const onLanguageChangeHandler = (newValues: ComboBoxListItem[]) => {
    dispatch(setEditedLanguage(newValues));
  };

  const handleRefreshView = useCallback(() => {
    loadAllLanguages();
  }, [loadAllLanguages]);

  useEffect(() => {
    if (documentLanguages.length === 0) {
      dispatch(
        setLanguagesValidationStatus(
          isPublishValidationOn
            ? ValidationStatus.error
            : ValidationStatus.notVisibleError
        )
      );
    } else {
      dispatch(setLanguagesValidationStatus(ValidationStatus.success));
    }
  }, [dispatch, documentLanguages.length, isPublishValidationOn]);

  return (
    <DetailsAutoComplete
      id="language-autocomplete"
      labelText="Language"
      selected={documentLanguages}
      items={languagesDictionary.map((x) => {
        return {
          key: x.isoCode,
          value: x.name,
        };
      })}
      isEditable={props.isEditable}
      isDisabled={!props.isEditable}
      onRefreshHandler={handleRefreshView}
      isDictionaryDataFetchingError={isLanguagesFetchError}
      isLoading={props.isLoadingDetailsMetaData || isLoadingLanguages}
      $paddingLeft={13}
      pillsClassName={"language-pill"}
      onChangeHandler={onLanguageChangeHandler}
      isEdited={identitySection.languagesIsEdited}
      multiple={true}
      notEditableMessage={props.notEditableMessage}
      errorMessages={["Language cannot be empty"]}
      isError={isLanguagesValidationError}
    />
  );
}
