import styled from "styled-components";

export const TreeItemIconDiv = styled.div`
  display: flex;
  min-width: 32px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: ${(props) => props.theme.typography.fontSizes.headerT1}px;
  height: 20px;
`;
