import { Button } from "@mui/material";
import styled from "styled-components";

const StyledButton = styled(Button)`
  white-space: owrap;
  text-overflow: ellipsis;
  background-color: ${({ theme }) => theme.palettes.primary.main};
`;

export default StyledButton;
