import {
  ErrorContainerProps,
  ErrorDisplay,
} from "../../app/errorHandling/ErrorDisplay";
import { DialogControl } from "../../controls/Dialog/DialogControl";
import { StyledWarningIcon } from "../../controls/StyledComponents/StyledWarningIcon";
import { CopyButton } from "../details/sections/common/SC/CopyButton";
import { StyledDraftDiv } from "./SC/StyledDraftDiv";
import { StyledLinkDiv } from "./SC/StyledLinkDiv";
import { StyledLinkGroupDiv } from "./SC/StyledLinkGroupDiv";
import { StyledSkeleton } from "./SC/StyledSkeleton";
import { StyledDialogContentText } from "../details/selfHelpPopup/SC/StyledDialogContentText";
import { SnackbarMsg } from "../../controls/Snackbar/SnackbarMessages";

interface DocumentUrlPopupProps {
  open: boolean;
  isLoading?: boolean;
  isError?: boolean;
  isDraft: boolean;
  onClose: () => void;
  onClick?: () => void;
  handleRefresh?: () => void;
  url?: string;
}

export const DocumentUrlPopup = (props: DocumentUrlPopupProps) => {
  const errorContainerStyles: ErrorContainerProps = {
    padding: "0",
  };
  const draftInfo = `This document is in Draft status and the link will start to work once document is published. 
    Changing Document Number, Part or Language will impact the link and new link should be created after changes`;

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    props.onClose();
  };

  return (
    <>
      {props.isError ? (
        <DialogControl
          id={"document-url"}
          isOpen={props.open}
          fullWidth
          useActions={false}
          title={""}
          content={
            <ErrorDisplay
              errorContainerProps={errorContainerStyles}
              doCenterHeader={true}
              errorBoxPadding="0"
              errorId={"document-details-share-url"}
              errorMessageTitle="Something went wrong"
              refreshFunction={props.handleRefresh}
              showReloadButton={true}
              showDash={false}
            >
              Error while loading document URL
            </ErrorDisplay>
          }
          onCloseClick={handleClose}
          maxWidth={"sm"}
        />
      ) : (
        <DialogControl
          id={"document-url"}
          isOpen={props.open}
          fullWidth
          useActions={false}
          title={"Document URL"}
          content={
            <StyledDialogContentText>
              {props.isDraft && (
                <StyledDraftDiv id="document-url-popup-draft-info">
                  <StyledWarningIcon margin={0} fontSize="inherit" />
                  {draftInfo}
                </StyledDraftDiv>
              )}

              <StyledLinkGroupDiv>
                {props.isLoading ? (
                  <StyledSkeleton width={"100%"} height={"100%"} />
                ) : (
                  <StyledLinkDiv id="document-url">{props.url}</StyledLinkDiv>
                )}
                <CopyButton
                  id={"document-url-popup"}
                  errorMessage={SnackbarMsg.CopyUrlError}
                  emptyMessage={SnackbarMsg.UrlEmpty}
                  value={props.url}
                  onHideParentTooltips={props.onClick}
                />
              </StyledLinkGroupDiv>
            </StyledDialogContentText>
          }
          onCloseClick={handleClose}
          maxWidth={"sm"}
        />
      )}
    </>
  );
};
