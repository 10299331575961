import Alert from "@mui/material/Alert";
import styled from "styled-components";

export const StyledAlert = styled(Alert)`
  align-items: center;
  justify-content: center;
  gap: 20px;

  & .MuiAlert-message {
    font-size: ${(props) => props.theme.typography.fontSizes.large}px;
    margin: 0 0 0 5px;
    text-justify: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    min-width: 260px;
    white-space: pre-wrap;
  }

  & .MuiAlert-icon {
    margin: 0;
  }

  & .MuiAlert-icon > svg {
    width: 25px;
    height: 25px;
  }

  & .MuiAlert-action {
    margin: 0;
    padding: 0;
    color: ${(props) => props.theme.palettes.grayscale.light};
  }

  & .MuiAlert-action > button > svg {
    width: 16px;
    height: 16px;
  }

  & .MuiAlert-action > button:hover {
    background-color: ${(props) => props.theme.palettes.grayscale.dark};
  }

  &.MuiAlert-filledSuccess {
    background-color: ${(props) => props.theme.palettes.secondary.success};
  }

  &.MuiAlert-filledWarning {
    background-color: ${(props) => props.theme.palettes.secondary.warning};
  }

  &.MuiAlert-filledInfo {
    background-color: ${(props) => props.theme.palettes.secondary.info};
  }

  &.MuiAlert-filledError {
    background-color: ${(props) => props.theme.palettes.secondary.error};
  }
`;
