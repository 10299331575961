import { PublishedWithChanges } from "@mui/icons-material";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { ContextMenuStepProps } from "./models";
import { useTheme } from "styled-components";

export function PublishStep(props: ContextMenuStepProps) {
    const theme = useTheme();

    return (
        <StepContentDiv>
            <Paragraph>
                {!props.isVisible && <><PublishedWithChanges style={{
                    fontSize: "16px",
                    verticalAlign: "middle",
                    color: theme.palettes.grayscale.dark
                }} /><b> PUBLISH</b> - </>}
                This action publishes the changes to the document, making it available in search interfaces such as Download Center, Download Section, and for downloading. If the document was in Archived status, it remains archived.
            </Paragraph>
        </StepContentDiv>
    );
}
