import styled from "styled-components";
import { ScreenSplashEnum } from "../../../models/screenSplash";

export interface PageLayoutDivProps {
  $splashScreen: ScreenSplashEnum;
}

export const PageLayoutDiv = styled.div<PageLayoutDivProps>`
  visibility: ${(props) =>
    props.$splashScreen !== ScreenSplashEnum.None ? "hidden" : "visible"};
  opacity: ${(props) => (props.$splashScreen !== ScreenSplashEnum.None ? 0 : 1)};
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: ${(props) => props.theme.palettes.grayscale.white};
`;
