import styled from "styled-components";
import { Environment } from "../../../models/environment";
interface HeaderDivProps {
  environment: Environment;
}

export const HeaderDiv = styled.div<HeaderDivProps>`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 50px;
  min-height: 50px;
  z-index: 200;
  order: 1;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  -webkit-box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  -moz-box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background: ${(props) =>
  (props.environment === Environment.Test ?
    `repeating-linear-gradient(45deg, ${props.theme.palettes.secondary.success}, ${props.theme.palettes.secondary.success} 10px, ${props.theme.palettes.grayscale.lighter} 10px, ${props.theme.palettes.grayscale.lighter} 20px)`
    : props.environment === Environment.Stage ?
      `repeating-linear-gradient(45deg, ${props.theme.palettes.secondary.highlight}, ${props.theme.palettes.secondary.highlight} 10px, ${props.theme.palettes.grayscale.lighter} 10px, ${props.theme.palettes.grayscale.lighter} 20px)`
      : props.theme.palettes.grayscale.white)};
`;
