import AssignmentIcon from "@mui/icons-material/Assignment";
import { useState } from "react";
import { useAppSelector } from "../../../../app/hooks/hooks";
import {
  selectDocumentsCount,
  selectIsGroupingEnabled,
  selectPageNumber,
  selectPageSize,
  selectSortBy,
  selectSortDescending,
} from "../../../../app/slices/documentListSlice";
import { useLazyExecuteMetadataExportQuery } from "../../../../apis/metadataExportApi";
import DocumentListSearchCriteria from "../../../../models/documentList/documentListSearchCriteria";
import {
  selectCategoryId,
  selectIncludeDocumentsFromSubcategories,
  selectIncludeDocumentsICannotManage,
  selectNavigationFilters,
  selectShowOnlyUnCategorizedDocuments,
} from "../../../../app/slices/navigationSlice";
import { useTheme } from "styled-components";
import { ButtonDiv } from "../SC/ButtonDiv";
import { DialogControl } from "../../../../controls/Dialog/DialogControl";
import { TextButtonControl } from "../../../../controls/Buttons/TextButtonControl";
import { StyledDialogContentText } from "../../../details/selfHelpPopup/SC/StyledDialogContentText";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";

interface MetadataExportButtonProps {
  isCompactView: boolean;
}

export function MetadataExportButton(props: MetadataExportButtonProps) {
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogAfter, setOpenDialogAfter] = useState(false);
  const [textAfterMetadataSent, setTextAfterMetadataSent] = useState("");

  const theme = useTheme();
  const documentsCount = useAppSelector(selectDocumentsCount);
  const categoryId = useAppSelector(selectCategoryId);
  const filters = useAppSelector(selectNavigationFilters);
  const pageNumber = useAppSelector(selectPageNumber);
  const sortBy = useAppSelector(selectSortBy);
  const sortDescending = useAppSelector(selectSortDescending);
  const pageSize = useAppSelector(selectPageSize);
  const groupedResult = useAppSelector(selectIsGroupingEnabled);
  const includeDocsFromSubcats = useAppSelector(
    selectIncludeDocumentsFromSubcategories
  );
  const exportFailedErrorMessage =
    "Metadata export failed. Please try again. If the problem persists, please contact Service Desk";
  const includeDocsIcannotManage = useAppSelector(
    selectIncludeDocumentsICannotManage
  );
  const showOnlyUnCategorizedDocuments = useAppSelector(
    selectShowOnlyUnCategorizedDocuments
  );
  const [executeMetadataExport, { isError }] =
    useLazyExecuteMetadataExportQuery();

  const handleExportButtonClick = () => {
    if (openDialog) {
      setOpenDialog(false);
    } else {
      setOpenDialog(true);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpenDialogAfter(false);
  };

  const args: DocumentListSearchCriteria = {
    categoryId: categoryId,
    accessGroupIds: filters.accessGroupIds,
    documentKindIds: filters.documentKindIds,
    documentTitle: filters.searchDocumentTitle,
    publisherIds: filters.publisherIds,
    documentNumber: filters.searchDocumentNumber,
    securityLevels: filters.securityLevels,
    documentLanguageCodes: filters.documentLanguageCodes,
    workflows: filters.workflows,
    publishedAfter: filters.publishedAfter,
    publishedBefore: filters.publishedBefore,
    pageNumber: pageNumber,
    pageSize: pageSize,
    includeDocumentsFromSubcategories: includeDocsFromSubcats,
    includeDocumentsICannotManage: includeDocsIcannotManage,
    showOnlyUnCategorizedDocuments: showOnlyUnCategorizedDocuments,
    sortBy: sortBy,
    sortDescending: sortDescending,
    returnGroupedResults: groupedResult,
  };

  const handleConfirmButtonClick = () => {
    setOpenDialog(false);
    executeMetadataExport(args)
      .unwrap()
      .then(() => {
        setTextAfterMetadataSent(
          "Your metadata report is being prepared. You will receive an e-mail message when ready."
        );
        setOpenDialogAfter(true);
      })
      .catch(() => {
        setTextAfterMetadataSent("Your metadata report cannot be prepared.");
        setOpenDialogAfter(true);
      });
  };

  return (
    <ButtonDiv>
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.primaryButtonHeight}
        icon={<AssignmentIcon fontSize="small" />}
        id="header-action-meta-data-export"
        tooltipText={TooltipMsg.ExportMetadata}
        text="Metadata export"
        onClick={() => handleExportButtonClick()}
        isVertical={false}
        isVisible={true}
        isCompactView={props.isCompactView}
        disabled={documentsCount == 0}
      />
      <DialogControl
        id="meta-data-export"
        title="Metadata export"
        onConfirmClick={handleConfirmButtonClick}
        onCloseClick={handleClose}
        maxWidth="sm"
        useActions={true}
        isOpen={openDialog}
        content={
          <StyledDialogContentText id="alert-dialog-description">
            This will export metadata of all documents matching selected search
            criteria. Do you want to proceed?
          </StyledDialogContentText>
        }
      />

      <DialogControl
        id="meta-data-export-info"
        title="Metadata export"
        useActions={true}
        hideCancelButton={true}
        confirmText="Ok"
        onConfirmClick={handleClose}
        maxWidth="sm"
        onCloseClick={handleClose}
        isOpen={openDialogAfter}
        content={
          <StyledDialogContentText id="alert-dialog-description">
            {isError ? exportFailedErrorMessage : textAfterMetadataSent}
          </StyledDialogContentText>
        }
      />
    </ButtonDiv>
  );
}
