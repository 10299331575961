import { ListItem, ListItemProps } from "@mui/material";
import styled from "styled-components";

interface StyledListItemProps extends ListItemProps {
  $clickable?: boolean;
}

export const StyledListItem = styled(ListItem)<StyledListItemProps>`
  &.MuiListItem-root {
    display: flex;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0px;
    cursor: ${(props) => (props.$clickable ? "pointer" : "initial")};
  }
`;
