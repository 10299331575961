import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot_dc from "../../../screenshots/securityLevel/dc_sec_lvl.png";
import { Screenshot } from "../../../SC/Screenshot";

export function SectionStepTwo() {
    const theme = useTheme()

    return (
        <StepContentDiv>
            <Paragraph>
                In both the Download Center and Download Section, users can only see the documents they are authorized to open. In the Download Center, documents are marked with the respective tags: <span style={{ color: theme.palettes.secondary.internal }}>Internal</span>, <span style={{ color: theme.palettes.secondary.premium }}>Premium</span>, or <span style={{ color: theme.palettes.secondary.public }}>Public</span>.
                <Screenshot src={screenshot_dc} />
            </Paragraph>
        </StepContentDiv>
    );
}
