import { DialogControl } from "../../../../../../controls/Dialog/DialogControl";
import { useCallback, useEffect, useState } from "react";
import { StyledDialogContentText } from "../../../../selfHelpPopup/SC/StyledDialogContentText";

interface ErrorPopupProps {
  isOpen: boolean;
  onClose: () => void;
  reason: SaveAsDraftErrorType | null;
}

export enum SaveAsDraftErrorType {
  IdentityChanged,
  CategoriesChanged,
  MainCategoryChanged,
  ApprovalIdentityChanged,
}

interface ErrorPopupContent {
  title: string;
  message: string;
}

const emptyItem: ErrorPopupContent = {
  title: "",
  message: "",
};

const approvalIdentityChangedItem: ErrorPopupContent = {
  title: "Document identity was changed",
  message:
    "Document cannot be sent for approval when document identity was changed. It can only be saved when document identity is not changed. In order to change document identity it needs to be published.",
};

const identityChangedItem: ErrorPopupContent = {
  title: "Document identity was changed",
  message:
    "Draft cannot be saved when document identity was changed. It can only be saved when document identity is not changed. In order to change document identity it needs to be published.",
};

const categoriesChangedItem: ErrorPopupContent = {
  title: "Category was modified on document you cannot edit",
  message:
    "Draft cannot be saved when there is no publishing access to Main Category. In order to modify category on such document it needs to be published.",
};

const mainCategoryChangedItem: ErrorPopupContent = {
  title: "Document Main Category was changed",
  message:
    "Draft cannot be saved when Main Category has changed. In order to change Main Category of a document it needs to be published.",
};

export function ErrorPopup(props: ErrorPopupProps) {
  const [content, setContent] = useState<ErrorPopupContent>(emptyItem);
  const getErrorPopupContent = useCallback(
    (reason: SaveAsDraftErrorType | null) => {
      switch (reason) {
        case SaveAsDraftErrorType.ApprovalIdentityChanged:
          return approvalIdentityChangedItem;
        case SaveAsDraftErrorType.IdentityChanged:
          return identityChangedItem;
        case SaveAsDraftErrorType.CategoriesChanged:
          return categoriesChangedItem;
        case SaveAsDraftErrorType.MainCategoryChanged:
          return mainCategoryChangedItem;
        default:
          return emptyItem;
      }
    },
    []
  );

  useEffect(() => {
    const content = getErrorPopupContent(props.reason);
    setContent(content);
  }, [getErrorPopupContent, props.reason]);

  return (
    <>
      <DialogControl
        id="disabled-save-draft-popup"
        title={content.title}
        onCloseClick={props.onClose}
        maxWidth="sm"
        useActions={false}
        isOpen={props.isOpen}
        content={
          <StyledDialogContentText id="alert-dialog-description">
            {content.message}
          </StyledDialogContentText>
        }
      />
    </>
  );
}
