import { TooltipTop } from "../../../../controls/Tooltips/ToolipTop";
import { StyledEllipsisButton } from "./SC/StyledEllipsisButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

interface BreadcrumbEllipsisButtonProps {
  onClick: () => void;
}

export function BreadcrumbEllipsisButton(props: BreadcrumbEllipsisButtonProps) {
  return (
    <TooltipTop title="Show whole path">
      <StyledEllipsisButton onClick={props.onClick}>
        <MoreHorizIcon fontSize="small" />
      </StyledEllipsisButton>
    </TooltipTop>
  );
}
