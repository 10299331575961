import { find, forEach } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { openSnackbar } from "../../app/helpers/snackBarHelper";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { setIsLoadingDetailsMetaData } from "../../app/slices/documentDetailsSlice";
import {
  collapseAllCategoryBreadcrumbs,
  selectCurrentDocumentMainCategory,
  setEditedAndRealDocumentMetadata,
  setMyPermissionsInCategory,
} from "../../app/slices/documentMetadataSlice";
import DocumentMetadata from "../../models/documentDetails/documentMetadata";
import { SnackbarType } from "../../models/snackbar";
import {
  useLazyGetDocumentMetadataQuery,
  useLazyGetDocumentStatusQuery,
} from "../documentDetailsApi";
import { useLazyGetMyPermissionsInCategoryQuery } from "../publishersApi";
import { useCreateNewDocument } from "./useCreateNewDocument";
import { SnackbarMsg } from "../../controls/Snackbar/SnackbarMessages";

export function useDocumentMetadata() {
  const dispatch = useAppDispatch();
  const mainCategory = useAppSelector(selectCurrentDocumentMainCategory);
  const { prepareDocumentMetadata, prepareDocumentStatus } =
    useCreateNewDocument();

  const [
    getDocumentMetadata,
    {
      isError: documentMetadataError,
      data: documentMetadata,
      isFetching: isLoadingMetadata,
    },
  ] = useLazyGetDocumentMetadataQuery();

  const [
    getDocumentStatus,
    {
      isError: documentStatusError,
      data: documentStatus,
      isFetching: isLoadingStatus,
    },
  ] = useLazyGetDocumentStatusQuery();

  const [
    getMyPermissionInCategory,
    {
      isError: categoryPermissionsError,
      data: permissionsInCategory,
      isFetching: isLoadingPermissions,
    },
  ] = useLazyGetMyPermissionsInCategoryQuery();

  const fetchMainCategoryPermissions = useCallback(
    async (metadata: DocumentMetadata | null) => {
      if (metadata) {
        const mainCategory = find(metadata.categories, ["isMain", true]);
        if (mainCategory) {
          await getMyPermissionInCategory(mainCategory.cid, false);
        }
      }
    },
    [getMyPermissionInCategory]
  );

  const fetchDocumentMetadata = useCallback(
    async (detailsRoute: string, showLoading: boolean) => {
      dispatch(setIsLoadingDetailsMetaData(showLoading));

      const fetchMetadata = getDocumentMetadata(detailsRoute, false);
      const fetchStatus = getDocumentStatus(detailsRoute, false);

      await Promise.all([fetchMetadata, fetchStatus]).then(
        async (responses) => {
          let metadata = null;
          forEach(responses, (response) => {
            if (response.endpointName === "getDocumentMetadata") {
              metadata = response.data;
            }
          });
          await fetchMainCategoryPermissions(metadata);
          dispatch(setIsLoadingDetailsMetaData(false));
        }
      );
    },
    [
      dispatch,
      fetchMainCategoryPermissions,
      getDocumentMetadata,
      getDocumentStatus,
    ]
  );

  useEffect(() => {
    if (documentMetadata && !isLoadingMetadata) {
      const newMetadata = prepareDocumentMetadata(documentMetadata);
      dispatch(setEditedAndRealDocumentMetadata(newMetadata));
      dispatch(collapseAllCategoryBreadcrumbs());
    }
  }, [dispatch, prepareDocumentMetadata, isLoadingMetadata, documentMetadata]);

  useEffect(() => {
    if (documentStatus && !isLoadingStatus) {
      prepareDocumentStatus(documentStatus);
    }
  }, [dispatch, prepareDocumentStatus, documentStatus, isLoadingStatus]);

  useEffect(() => {
    if (permissionsInCategory && !isLoadingPermissions) {
      dispatch(setMyPermissionsInCategory(permissionsInCategory));
    }
  }, [dispatch, permissionsInCategory, isLoadingPermissions]);

  useEffect(() => {
    if (mainCategory) {
      void getMyPermissionInCategory(mainCategory.cid, false);
    }
  }, [getMyPermissionInCategory, mainCategory]);

  useEffect(() => {
    if (categoryPermissionsError) {
      openSnackbar(
        dispatch,
        SnackbarMsg.CategoryPermissionsError,
        SnackbarType.error
      );
    }
  }, [dispatch, categoryPermissionsError]);

  const isDocumentMetadataError = useMemo(() => {
    return documentMetadataError || documentStatusError;
  }, [documentMetadataError, documentStatusError]);

  return {
    isDocumentMetadataError,
    fetchDocumentMetadata,
  };
}
