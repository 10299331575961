import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, PopperProps } from "@mui/material";
import { ListItemSpan } from "../../../controls/Autocomplete/SC/ListItemSpan";
import { StyledErrorIcon } from "../../../controls/FormHelper/SC/StyledErrorIcon";
import { TooltipTop } from "../../../controls/Tooltips/ToolipTop";
import StyledSpan from "../SC/StyledSpan";
import StyledErrorSpan from "../SC/StyledErrorSpan";
import StyledMenuItem from "../SC/StyledMenuItem";
import { StyledAutoCompletePopper } from "../../../controls/Autocomplete/SC/StyledAutoCompletePopper";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";

interface StyledAutoCompleteErrorMessageProps {
  errorMessage?: string;
  tooltipTitle?: string;
  refreshFunction?: () => void;
  popperProps: PopperProps;
  calculatedWidth: number;
  anchorEl: HTMLDivElement | null;
}

const StyledAutoCompleteErrorMessage = ({
  errorMessage = "No options available! ",
  refreshFunction,
  tooltipTitle = TooltipMsg.AutoCompleteQualityIssue,
  popperProps,
  calculatedWidth,
  anchorEl,
}: StyledAutoCompleteErrorMessageProps) => {
  return (
    <StyledAutoCompletePopper
      anchorEl={anchorEl}
      $calculatedWidth={calculatedWidth}
      {...popperProps}
    >
      <StyledMenuItem>
        <ListItemSpan>
          <TooltipTop title={tooltipTitle}>
            <StyledErrorSpan>
              <StyledErrorIcon color="error" fontSize="inherit" />
            </StyledErrorSpan>
          </TooltipTop>
        </ListItemSpan>
        <StyledSpan>{errorMessage}</StyledSpan>
        <IconButton onMouseDown={refreshFunction} size="small">
          <RefreshIcon color="error" fontSize="inherit" />
        </IconButton>
      </StyledMenuItem>
    </StyledAutoCompletePopper>
  );
};

export default StyledAutoCompleteErrorMessage;
