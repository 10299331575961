import Collapse from "@mui/material/Collapse";
import { useCallback, useEffect, useRef, useState } from "react";
import { getLabelWithFacets } from "../../../app/helpers/categoriesHelper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import useNavigationTreeFetch from "../../../app/hooks/useNavigationTreeFetch";
import {
  selectCategoriesChildrenByParentId,
  selectCategoryById,
  setIsExpanded,
} from "../../../app/slices/navigationCategoryTreeSlice";
import { rootCategoryId } from "../../../app/slices/navigationSlice";
import NavigationCategory from "../../../models/navigationCategories/navigationCategory";
import { CategoryItem } from "./CategoryItem";
import CategoryNavigationTreeItemSkeleton from "./CategoryNavigationTreeItemSkeleton";
import { CategoryNavigationTreeUl } from "./SC/CategoryNavigationTreeUl";
import ErrorIcon from "@mui/icons-material/Error";
import StyledCategoryTreeItemErrorMessage from "../../../app/errorHandling/categoryTreeItem/StyledCategoryTreeItemErrorMessage";
import { openSnackbar } from "../../../app/helpers/snackBarHelper";
import { SnackbarType } from "../../../models/snackbar";
import { selectUserRoles } from "../../../app/slices/authenticationSlice";
import { find } from "lodash";
import StyledIconButton from "../../../app/errorHandling/SC/StyledIconButton";
import StyledRefreshIcon from "../../../app/errorHandling/SC/StyledRefreshIcon";
import { SnackbarMsg } from "../../../controls/Snackbar/SnackbarMessages";

interface CategoryNavigationTreeItemProps {
  categoryId: string;
  isCategoryFacetsError: boolean;
  parentCategoryId?: string;
  isParent: boolean;
  depth: number;
}

function CategoryNavigationTreeItem(props: CategoryNavigationTreeItemProps) {
  const dispatch = useAppDispatch();
  const category = useAppSelector((state) =>
    selectCategoryById(state, props.categoryId)
  );
  const [isHoveringRefreshIcon, setIsHoveringRefreshIcon] = useState(false);
  const [isHoveringErrorIcon, setIsHoveringErrorIcon] = useState(false);
  const userRoles = useAppSelector(selectUserRoles);
  const handleIsHoveringRefresh = (isHovering: boolean) => {
    setIsHoveringRefreshIcon(isHovering);
  };
  const children = useAppSelector((state) =>
    selectCategoriesChildrenByParentId(state, props.categoryId)
  );
  const {
    fetchCategories,
    refetchCategoriesTreeFacets,
    categoriesTreeIsError,
    categoriesTreeFacetsIsError,
    categoriesTreeFacetsIsLoading,
  } = useNavigationTreeFetch({
    categoryId: props.categoryId,
    includeHighestParent: false,
    categoryDepth: props.depth,
    hasChildrenWithoutFacets: category?.hasChildrenWithoutFacets ?? false,
  });
  const loadedChildren = useRef(false);
  const [onExpandCategoryId, setOnExpandCategoryId] = useState("");

  useEffect(() => {
    if (categoriesTreeIsError) {
      openSnackbar(dispatch, SnackbarMsg.CategoriesError, SnackbarType.error);
    }
  }, [dispatch, categoriesTreeIsError]);

  const onExpand = useCallback(() => {
    if (
      !loadedChildren.current &&
      children === undefined &&
      category?.hasChildren
    ) {
      loadedChildren.current = true;
      void fetchCategories();
    }

    dispatch(
      setIsExpanded({
        categoryId: props.categoryId,
        isExpanded: true,
      })
    );
    setOnExpandCategoryId(props.categoryId);
  }, [dispatch, fetchCategories, props, children, category]);

  const onCollapse = useCallback(
    () =>
      dispatch(
        setIsExpanded({
          categoryId: props.categoryId,
          isExpanded: false,
        })
      ),
    [dispatch, props.categoryId]
  );

  const isCategoryGray = useCallback(
    (category: NavigationCategory): boolean => {
      if (category.hasUserPermissionInside) {
        return false;
      }

      const companyRole = find(userRoles, (userRole) => {
        return userRole === category.categoryCompany;
      });

      if (companyRole !== undefined && category.hasUserAccess) {
        return false;
      }

      return true;
    },
    [userRoles]
  );

  const renderChildren = () => {
    if (children && children.length > 0) {
      return children.map((childId, index) => (
        <CategoryNavigationTreeItem
          key={index}
          categoryId={childId}
          depth={props.depth + 1}
          parentCategoryId={props.categoryId}
          isParent={false}
          isCategoryFacetsError={
            props.isParent
              ? props.isCategoryFacetsError
              : categoriesTreeFacetsIsError
          }
        />
      ));
    }

    if (categoriesTreeIsError) {
      return (
        <StyledCategoryTreeItemErrorMessage
          treeItemMessageStyles={{
            paddingLeft: `${(props.depth + 1) * 8 + 3}px`,
            marginLeft: "0px",
          }}
          errorMessage="Categories loading failed"
          refreshFunction={() => {
            void fetchCategories();
          }}
        />
      );
    }

    if (category?.hasChildren) {
      return (
        <CategoryNavigationTreeItemSkeleton
          numberOfSkeletons={3}
          padding={(props.depth + 1) * 8 + 8}
          parentCategoryId={props.categoryId}
        />
      );
    }

    return <></>;
  };
  const getCategoryItemLabel = (category: NavigationCategory | undefined) => {
    if (category) {
      const label = getLabelWithFacets(category);
      if (
        props.isCategoryFacetsError &&
        category.parentCategoryId !== props.parentCategoryId
      ) {
        return (
          <>
            {label}
            <StyledIconButton
              onMouseDown={() => {
                void refetchCategoriesTreeFacets(props.categoryId, false);
              }}
            >
              <StyledRefreshIcon
                onMouseEnter={() => setIsHoveringRefreshIcon(true)}
                onMouseLeave={() => setIsHoveringRefreshIcon(false)}
                color="action"
              />
            </StyledIconButton>
          </>
        );
      } else if (props.isCategoryFacetsError) {
        return (
          <>
            {label}
            <ErrorIcon
              onMouseEnter={() => setIsHoveringErrorIcon(true)}
              onMouseLeave={() => setIsHoveringErrorIcon(false)}
              color="error"
              sx={{ marginLeft: "1px", fontSize: 15 }}
            />
          </>
        );
      }

      return <>{label}</>;
    }
  };

  return category ? (
    <CategoryNavigationTreeUl
      id={category.categoryId === rootCategoryId ? "main-tree-root" : undefined}
    >
      <CategoryItem
        categoryId={category.categoryId}
        onExpandCategoryId={onExpandCategoryId}
        handleIsHoveringRefresh={handleIsHoveringRefresh}
        isHoveringRefreshIcon={isHoveringRefreshIcon}
        isHoveringErrorIcon={isHoveringErrorIcon}
        label={getCategoryItemLabel(category)}
        isCategoryGray={isCategoryGray(category)}
        hasUserPermissionsInside={category.hasUserPermissionInside}
        isExpanded={category.isExpanded}
        isLoading={categoriesTreeFacetsIsLoading}
        refetchCategoriesTreeFacets={refetchCategoriesTreeFacets}
        isError={
          props.isParent
            ? props.isCategoryFacetsError
            : categoriesTreeFacetsIsError
        }
        isCategoryTreeError={categoriesTreeIsError}
        isExpandable={category.hasChildren}
        onCollapse={onCollapse}
        onExpand={onExpand}
        treeDepth={props.depth}
        numberOfRevisions={category.numberOfDocuments}
      />
      <Collapse in={category.isExpanded}>{renderChildren()}</Collapse>
    </CategoryNavigationTreeUl>
  ) : (
    <></>
  );
}

export default CategoryNavigationTreeItem;
