import styled from "styled-components";

interface NavigationDivProps {
  $isMinimized: boolean;
}

function getVisibility(isMinimized: boolean) {
  if (isMinimized) {
    return "none";
  }

  return "flex";
}

export const NavigationDiv = styled.div<NavigationDivProps>`
  height: 100%;
  display: ${(props) => getVisibility(props.$isMinimized)};
  flex-grow: 1;
`;
