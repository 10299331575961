import { createApi } from "@reduxjs/toolkit/query/react";
import { Category } from "../models/categoryTree";
import MatchingPublisherCategoriesSearchCriteria from "../models/documentDetails/matchingPublisherCategoriesSearchCriteria";
import PublisherCategoriesSearchCriteria from "../models/documentDetails/publisherCategoriesSearchCriteria";
import { publishApiBaseQuery } from "./baseQueries";
import _ from "lodash";

export const publisherCategoriesApi = createApi({
  reducerPath: "publisherCategoriesApi",
  baseQuery: publishApiBaseQuery,
  endpoints: (builder) => ({
    getPublisherCategories: builder.query<
      Category[],
      PublisherCategoriesSearchCriteria & { signal?: AbortSignal }
    >({
      query: (
        searchCriteria: PublisherCategoriesSearchCriteria & {
          signal?: AbortSignal;
        }
      ) => ({
        url: `PublisherCategories`,
        method: "POST",
        body: searchCriteria,
        keepUnusedDataFor: 300,
        signal: searchCriteria.signal,
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return _.omit(queryArgs, "signal");
      },
    }),
    getOwnerCategories: builder.query<
      Category[],
      PublisherCategoriesSearchCriteria & { signal?: AbortSignal }
    >({
      query: (
        searchCriteria: PublisherCategoriesSearchCriteria & {
          signal?: AbortSignal;
        }
      ) => ({
        url: `OwnerCategories`,
        method: "POST",
        body: searchCriteria,
        keepUnusedDataFor: 3600,
        signal: searchCriteria.signal,
      }),
    }),
    getMatchingPublisherCategories: builder.query<
      Category[],
      MatchingPublisherCategoriesSearchCriteria & { signal?: AbortSignal }
    >({
      query: (
        searchCriteria: MatchingPublisherCategoriesSearchCriteria & {
          signal?: AbortSignal;
        }
      ) => ({
        url: `MatchingPublisherCategories?query=${encodeURIComponent(searchCriteria.query)}`,
        method: "GET",
        keepUnusedDataFor: 300,
        signal: searchCriteria.signal,
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return queryArgs.query;
      },
    }),
    getMatchingOwnerCategories: builder.query<
      Category[],
      MatchingPublisherCategoriesSearchCriteria
    >({
      query: (searchCriteria: MatchingPublisherCategoriesSearchCriteria) => ({
        url: `MatchingOwnerCategories?query=${encodeURIComponent(searchCriteria.query)}`,
        method: "GET",
        keepUnusedDataFor: 300,
      }),
    }),
    getOwnerCategoriesByCategoryId: builder.query<Category[], string>({
      query: (categoryId: string) => ({
        url: `OwnerCategories/${categoryId}`,
        method: "GET",
        keepUnusedDataFor: 300,
      }),
    }),
  }),
});

export const {
  useLazyGetMatchingPublisherCategoriesQuery,
  useLazyGetPublisherCategoriesQuery,
  useLazyGetOwnerCategoriesQuery,
  useLazyGetMatchingOwnerCategoriesQuery,
  useGetMatchingOwnerCategoriesQuery,
  useGetOwnerCategoriesQuery,
  useLazyGetOwnerCategoriesByCategoryIdQuery,
} = publisherCategoriesApi;

export default publisherCategoriesApi.reducer;
