import { Grow } from "@mui/material";
import styled from "styled-components";

interface StyledGrowProps {
  $placement: string;
}

export const StyledGrow = styled(Grow)<StyledGrowProps>`
  transform-origin: ${(props) =>
    props.$placement === "bottom" ? "center top" : "center bottom"};
`;
