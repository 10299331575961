import { createApi } from "@reduxjs/toolkit/query/react";
import {
  SelfHelpSection,
  SelfHelpSectionIdentifier,
} from "../models/selfHelp/selfHelpSection";
import { publishApiBaseQuery } from "./baseQueries";

export const selfHelpApi = createApi({
  reducerPath: "selfHelpApi",
  baseQuery: publishApiBaseQuery,
  endpoints: (builder) => ({
    markSectionAsCompleted: builder.mutation<
      boolean,
      SelfHelpSectionIdentifier
    >({
      query: (params: SelfHelpSectionIdentifier) => ({
        url: `SelfHelp/${params.sectionName}/${params.page}`,
        method: "POST",
      }),
    }),
    getUncompletedSections: builder.query<SelfHelpSection[], null>({
      query: () => ({
        url: `SelfHelp`,
        method: "GET",
        keepUnusedDataFor: 0,
      }),
    }),
  }),
});

export const {
  useMarkSectionAsCompletedMutation,
  useLazyGetUncompletedSectionsQuery,
} = selfHelpApi;

export default selfHelpApi.reducer;
