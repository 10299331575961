import { useCallback } from "react";
import { useAppDispatch } from "../../../../app/hooks/hooks";
import { setEditedPart } from "../../../../app/slices/documentMetadataSlice";
import { setDocumentPartValidationStatus } from "../../../../app/slices/documentMetadataValidationSlice";
import { ValidationStatus } from "../../../../models/validationRule";
import { MultiText } from "../../inputs/MultiText";
import { Editable } from "../common/Editable";
import { useDocumentEditedMode } from "../../../../app/hooks/document/useDocumentEditedMode";
import useDocumentValidationRules from "../../../../app/hooks/document/useDocumentValidationRules";

interface PartProps extends Editable {
  part: string;
  isLoadingDetailsMetaData: boolean;
}

export function Part(props: PartProps) {
  const dispatch = useAppDispatch();
  const { identitySection } = useDocumentEditedMode();
  const { getPartValidationRules } = useDocumentValidationRules();

  const onPartChangeHandler = (newValue: string) => {
    dispatch(setEditedPart(newValue));
  };

  const onInputValidation = useCallback(
    (result: ValidationStatus) => {
      dispatch(setDocumentPartValidationStatus(result));
    },
    [dispatch]
  );

  return (
    <MultiText
      id="part-input"
      isEditable={props.isEditable}
      isLoading={props.isLoadingDetailsMetaData}
      notEditableMessage={props.notEditableMessage}
      labelText="Part"
      inputValue={props.part}
      onChangeHandler={onPartChangeHandler}
      isEdited={identitySection.partIsEdited}
      onInputValidation={onInputValidation}
      placeholder="(optional)"
      validationRules={getPartValidationRules()}
    />
  );
}
