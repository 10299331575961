import { Divider, DividerProps } from "@mui/material";
import styled from "styled-components";

export const SectionDivider = styled((props: DividerProps) => (
  <Divider {...props} />
))`
  &.MuiDivider-root {
    margin: 0 6px;
  }
`;
