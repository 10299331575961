import { useEffect } from "react";
import { useLinkCategoriesToDocumentMutation } from "../../../apis/documentApi";
import { SnackbarType } from "../../../models/snackbar";
import { openSnackbar } from "../../helpers/snackBarHelper";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectDocumentIdentity } from "../../slices/documentMetadataSlice";
import {
  setIsUpdatingDocument,
  setUsePrompt,
} from "../../slices/documentDetailsSlice";
import { getNonEmptyDocumentIdentity } from "../../helpers/documentIdentityHelper";
import { useNavigate } from "react-router-dom";
import { useDocumentCategoriesState } from "./useDocumentCategoriesState";
import { SnackbarMsg } from "../../../controls/Snackbar/SnackbarMessages";

export function useLinkCategoriesToDocument() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const documentIdentity = useAppSelector(selectDocumentIdentity);
  const { categoriesToChange, categoriesToDelete } =
    useDocumentCategoriesState();

  const [
    linkCategoriesToDocument,
    { isError, isSuccess, isLoading: isLinkCategoriesToDocumentLoading },
  ] = useLinkCategoriesToDocumentMutation();

  useEffect(() => {
    if (isError) {
      dispatch(setUsePrompt(true));
      openSnackbar(dispatch, SnackbarMsg.LinkCategoryError, SnackbarType.error);
    }
  }, [dispatch, isError]);

  useEffect(() => {
    if (isSuccess) {
      navigate("/");
      openSnackbar(
        dispatch,
        SnackbarMsg.PublishDocumentSuccess,
        SnackbarType.success
      );
      dispatch(setUsePrompt(true));
    }
  }, [dispatch, isSuccess, navigate]);

  useEffect(() => {
    if (isLinkCategoriesToDocumentLoading) {
      dispatch(setIsUpdatingDocument(true));
    } else {
      dispatch(setIsUpdatingDocument(false));
    }
  }, [dispatch, isLinkCategoriesToDocumentLoading]);

  const linkCategoriesButtonHandler = () => {
    dispatch(setUsePrompt(false));

    const identity = getNonEmptyDocumentIdentity(documentIdentity);

    void linkCategoriesToDocument({
      documentIdentity: identity,
      documentChangedCategories: {
        categoriesToChange: categoriesToChange,
        categoriesToDelete: categoriesToDelete.map((cat) => {
          return cat.cid;
        }),
      },
    });
  };

  return {
    linkCategoriesButtonHandler,
  };
}
