import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { useState } from "react";
import { useTheme } from "styled-components";
import { TextButtonControl } from "../../../../controls/Buttons/TextButtonControl";
import { ZoomPopup } from "../../../popup/ZoomPopup";
import { ButtonDiv } from "../SC/ButtonDiv";

interface ZoomButtonProps {
  isCompactView?: boolean;
}

export function ZoomButton(props: ZoomButtonProps) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ButtonDiv>
      <ZoomPopup isOpen={isOpen} handleClose={() => setIsOpen(false)} />
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.primaryButtonHeight}
        id={"zoom-button"}
        disabled={false}
        onClick={() => setIsOpen(true)}
        isCompactView={!!props.isCompactView}
        isVertical={false}
        isVisible={true}
        icon={<ZoomInIcon fontSize="small" />}
        text={"Zoom"}
      />
    </ButtonDiv>
  );
}
