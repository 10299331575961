import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import AssignmentIcon from "@mui/icons-material/Assignment";

export function MetadataExportStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The{" "}
        <AssignmentIcon
          style={{
            fontSize: "16px",
            verticalAlign: "middle",
          }}
        />{" "}
        &quot;Metadata Export&quot; button generates a request to export the
        metadata of all documents that match the selected search criteria to the
        xlsx format.
      </Paragraph>
      <Paragraph>
        The exported file is then sent via email. It&apos;s important to note
        that the link to access the report will expire after 24 hours.
      </Paragraph>
    </StepContentDiv>
  );
}
