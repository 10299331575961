import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStep2() {
  return (
    <StepContentDiv>
      <Paragraph>
        The category tree shows only the Level 0 categories because settings can only be provided at this level, and they are distributed to subcategories.
      </Paragraph>
    </StepContentDiv>
  );
}
