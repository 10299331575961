import styled from "styled-components";
import { defaultTheme } from "../../../../../../app/theme";

interface StyledScheduledActionDivProps {
  $margin?: number;
}

export const StyledScheduledActionDiv = styled.div<StyledScheduledActionDivProps>`
  font-size: ${defaultTheme.typography.fontSizes.medium}px;
  margin-top: ${(props) => (props.$margin ? props.$margin + "px" : "unset")};
  & .MuiTextField-root {
    margin-top: ${(props) => (props.$margin ? props.$margin + "px" : "unset")};
  }
`;
