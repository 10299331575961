import styled from "styled-components";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export interface CategoryBreadCrumbCopyIconProps {
  $isVisible: boolean;
}

export const CategoryBreadCrumbCopyIcon = styled(
  ContentCopyIcon
)<CategoryBreadCrumbCopyIconProps>`
  color: ${(props) => props.theme.palettes.grayscale.dark};
  :hover {
    cursor: pointer;
  }
  &.MuiSvgIcon-root {
    display: flex;
    visibility: ${(props) => (props.$isVisible ? "visible" : "hidden")};
    align-items: center;
    align-content: center;
    justify-content: center;
    width: ${(props) => props.theme.typography.fontSizes.smallIcon}px;
    height: ${(props) => props.theme.typography.fontSizes.smallIcon}px;
  }
`;
