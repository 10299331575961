import { createStep } from "../../helpers/selfHelpBuilder";
import { ColumnsStep } from "./DocumentListMenu/ColumnsStep";
import { DensityStep } from "./DocumentListMenu/DensityStep";
import { ExpandCollapseStep } from "./DocumentListMenu/ExpandCollapseStep";
import { MetadataExportStep } from "./DocumentListMenu/MetadataExportStep";
import { ResetStep } from "./DocumentListMenu/ResetStep";
import { TableSettingsStep } from "./DocumentListMenu/TableSettingsStep";
import { NewDocumentStep } from "./DocumentListMenu/NewDocumentStep";

export const documentMenuListSteps = () => {
  return [
    createStep(
      "#header-action-new-document-styled-button",
      <NewDocumentStep />,
      420,
      "bottom"
    ),
    createStep(
      "#header-action-meta-data-export",
      <MetadataExportStep />,
      520,
      "bottom"
    ),
    createStep("#density-columns-span", <TableSettingsStep />, 420, "bottom"),
    createStep("#row-density-button", <DensityStep />, 420, "bottom"),
    createStep("#column-visibility-button", <ColumnsStep />, 520, "bottom"),
    createStep(
      "#expand-collapse-group-button",
      <ExpandCollapseStep />,
      520,
      "bottom"
    ),
    createStep("#reset-view-button", <ResetStep />, 420, "bottom-end"),
  ];
};
