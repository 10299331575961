import { Workflow } from "../../models/documentList/types/workflow";

export function getWorkflowName(workflow: Workflow): string {
  switch (workflow) {
    case Workflow.Approval:
      return "In Approval";
    case Workflow.Archived:
      return "Archived";
    case Workflow.Deleted:
      return "Deleted";
    case Workflow.Draft:
      return "Draft";
    case Workflow.Published:
      return "Published";
    case Workflow.NewDocument:
      return "(New document)";
    case Workflow.NewRevision:
      return "New revision";
    default:
      return "";
  }
}
