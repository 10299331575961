export default interface ScheduledActionMessage {
  action?: Action;
  expirationDate?: string;
  userEmail?: string;
  emailContent?: string;
  emailRecipients?: string[];
}

export enum Action {
  Archive = "Archive",
  Delete = "Delete",
  SendEmail = "SendEmail",
}
