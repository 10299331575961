import styled from "styled-components";
import EditIcon from "@mui/icons-material/Edit";

interface StyledEditIconProps {
  $color: string;
}

export const StyledEditIcon = styled(EditIcon)<StyledEditIconProps>`
  color: ${(props) => props.$color};
  cursor: default;
  padding-right: 13px;
`;
