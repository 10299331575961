import { MenuItem } from "@mui/material";
import styled from "styled-components";

interface StyledSecurityItemProps {
  $removeHoverColor: boolean;
  $paddingLeft?: number;
  $paddingRight?: number;
  $paddingTop?: number;
  $paddingBottom?: number;
}

export const StyledSecurityItem = styled(MenuItem)<StyledSecurityItemProps>`
  &.MuiMenuItem-root {
    padding-left: ${(props) => (props.$paddingLeft ? props.$paddingLeft : 0)}px;
    padding-right: ${(props) =>
      props.$paddingRight ? props.$paddingRight : 0}px;
    padding-top: ${(props) => (props.$paddingTop ? props.$paddingTop : 0)}px;
    padding-bottom: ${(props) =>
      props.$paddingBottom ? props.$paddingBottom : 0}px;
    &:hover {
      background-color: ${(props) =>
        props.$removeHoverColor
          ? props.theme.palettes.grayscale.white
          : props.theme.palettes.grayscale.lighter};
    }
  }
`;
