import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks/hooks";
import { removeSelectedNode } from "../../app/slices/ownerCategoryTreeSlice";
import {
  setPublishersRoles,
  setSelectedBreadcrumbCategory,
  setSelectedCategoryId,
} from "../../app/slices/publishersListSlice";
import {
  setPublishersTabType,
  setSelectedPublisher,
} from "../../app/slices/publishersViewSlice";
import { Publishers, PublishersTabType } from "../publishers/Publishers";
import { ContentDiv } from "./SC/ContentDiv";

export function PublishersView() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(removeSelectedNode());
      dispatch(setSelectedCategoryId(""));
      dispatch(setPublishersRoles(undefined));
      dispatch(setSelectedPublisher(undefined));
      dispatch(setPublishersTabType(PublishersTabType.Category));
      dispatch(setSelectedBreadcrumbCategory(""));
    };
  }, [dispatch]);

  return (
    <ContentDiv id="content-div">
      <Publishers />
    </ContentDiv>
  );
}
