export enum SectionName {
  Identity = "Identity",
  Titles = "Titles",
  Description = "Description",
  Products = "Products",
  Attachment = "Attachment",
  Security = "Security",
  CountryMarketAvailability = "CountryMarketAvailability",
  Tags = "Tags",
  Categories = "Categories",
  Navigation = "Navigation",
  Filters = "Filters",
  DocumentListMenu = "DocumentListMenu",
  Documents = "Documents",
  ContextMenuPublish = "ContextMenuPublish",
  ContextMenuSection = "ContextMenuSection",
  AddCategories = "AddCategories",
  CmsMenu = "CmsMenu",
  CmsNavigation = "CmsNavigation",
  CmsAccessGroups = "CmsAccessGroups",
  CmsDocumentKinds = "CmsDocumentKinds",
  CmsPermissions = "CmsPermissions",
  CmsConfigurations = "CmsConfigurations",
  PublishersMenu = "PublishersMenu",
  PublishersPublisher = "PublishersPublisher",
  PublishersNavigation = "PublishersNavigation",
  PublishersPublisherList = "PublishersPublisherList",
  TagsOwners = "TagsOwners",
  ScheduledAction = "ScheduledAction",
}

export enum DetailsColumn {
  Left = "Left",
  Middle = "Middle",
  Right = "Right",
}
