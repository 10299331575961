import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  clone,
  filter,
  find,
  forEach,
  indexOf,
  isEqual,
  remove,
  union,
} from "lodash";
import { ComboBoxListItem } from "../../models/autocompleteValue";
import Country from "../../models/country";
import { Actions } from "../../models/documentDetails/actions";
import CountryMarketAvailability, {
  CountryAvailabilityType,
} from "../../models/documentDetails/countryMarketAvailability";
import { DocumentAttachment } from "../../models/documentDetails/documentAttachment";
import DocumentMetadata, {
  Category,
  Identity,
} from "../../models/documentDetails/documentMetadata";
import {
  DocumentStatus,
  DraftType,
} from "../../models/documentDetails/documentStatus";
import DocumentTag from "../../models/documentDetails/documentTag";
import { DocumentDetailView } from "../../models/documentDetailView";
import { Workflow } from "../../models/documentList/types/workflow";
import { DocumentSecurityLevel } from "../../models/documentSecurityLevel";
import Language from "../../models/language";
import { PermissionInCategory } from "../../models/permissionsInCategory";
import Tag from "../../models/tags/tag";
import {
  getExternalSecurityLevel,
  getInternalSecurityLevel,
} from "../helpers/securityLevelHelper";
import { RootState } from "../store";
import { Action } from "../../models/scheduledActionMessage";

export interface SelectedCategory {
  categoryId: string;
  categoryName: string;
  isHighLevelCategory: boolean;
  hasChildren: boolean;
}

export interface DocumentDetailsMetadata {
  documentMetadata: DocumentMetadata;
  documentStatus: DocumentStatus;
  currentDocumentMetadata: DocumentMetadata;
  languagesDictionary: Language[];
  countriesDictionary: Country[];
  tagsDictionary: DocumentTag[];
  documentTags: Tag[];
  currentDocumentTags: Tag[];
  expandedCategoriesBreadcrumbsIds: string[];
  //long category paths are category paths that are longer than 3 nodes
  longCategoryPathsIds: string[];
  myPermissionsInMainCategory: PermissionInCategory[];
  categorySearch: string;
  visibleCategorySearch: string;
  selectedCategoryNodes: SelectedCategory[];
  fetchedNewDocumentRevision?: string;
  hasScheduledAction: boolean;
  scheduledActionType: Action | undefined;
}

const getInitialStatus = (): DocumentStatus => ({
  isFromServiceIntegration: false,
  isLatest: false,
  draftType: DraftType.NonDraft,
  publisher: "",
  publishedDate: undefined,
  hasPendingApproval: false,
  canApprovePendingApproval: false,
  isLaterRevision: false,
  hasOtherRevisionWithPendingApproval: false,
  latestRevision: "",
  canChangeToPublish: false,
  isApprovalCategory: false,
});

const getInitialMetadata = (): DocumentMetadata => ({
  documentIdentity: getInitialIdentity(),
  documentKind: "",
  publisherEmail: "",
  workflow: Workflow.Draft,
  summary: "",
  titles: [{ title: "", languageIsoCode: "en" }],
  tags: [],
  products: [],
  attachment: { attachmentId: null, uploadId: null, name: "", size: 0 },
  categories: [],
  securityLevel: DocumentSecurityLevel.InternalPublic,
  accessGroups: [],
  countryMarketAvailability: {
    isSynchronizedWithCma: true,
    countryAvailabilityType: CountryAvailabilityType.AllCountries,
    countryIsoCodes: [],
  },
  company: "",
  actions: Actions.ArchivePreviousRevision,
});

const getInitialIdentity = (): Identity => ({
  documentNumber: "",
  documentPart: "",
  revision: "",
  documentLanguageCodes: [],
});

const initialState: DocumentDetailsMetadata = {
  documentMetadata: getInitialMetadata(),
  documentStatus: getInitialStatus(),
  currentDocumentMetadata: getInitialMetadata(),
  languagesDictionary: [],
  countriesDictionary: [],
  tagsDictionary: [],
  expandedCategoriesBreadcrumbsIds: [],
  longCategoryPathsIds: [],
  myPermissionsInMainCategory: [],
  categorySearch: "",
  visibleCategorySearch: "",
  selectedCategoryNodes: [],
  documentTags: [],
  currentDocumentTags: [],
  hasScheduledAction: false,
  scheduledActionType: undefined,
};

function checkIfTagsSectionIsEdited(
  documentTags: DocumentTag[],
  currentTags: DocumentTag[],
  documentTagTypes: Tag[],
  currentDocumentTagTypes: Tag[]
) {
  const detailsMetadataTags = documentTags
    .slice()
    .sort((a, b) => a.value.localeCompare(b.value));
  const currentDetailsMetadataTags = currentTags
    .slice()
    .sort((a, b) => a.value.localeCompare(b.value));

  return (
    !isEqual(documentTagTypes, currentDocumentTagTypes) ||
    !isEqual(detailsMetadataTags, currentDetailsMetadataTags)
  );
}

export const documentMetadataSlice = createSlice({
  name: "documentMetadata",
  initialState,
  reducers: {
    setDocumentIsNew: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.documentMetadata.workflow = Workflow.NewDocument;
        state.currentDocumentMetadata.workflow = Workflow.NewDocument;
        state.documentStatus.draftType = DraftType.NewDocumentDraft;
      } else {
        state.documentMetadata.workflow = Workflow.Draft;
        state.currentDocumentMetadata.workflow = Workflow.Draft;
        state.documentStatus.draftType = DraftType.NonDraft;
      }
    },
    setDocumentWorkflow: (state, action: PayloadAction<Workflow>) => {
      state.documentMetadata.workflow = action.payload;
    },
    setDocumentAction: (state, action: PayloadAction<Actions>) => {
      state.documentMetadata.actions = action.payload;
      state.currentDocumentMetadata.actions = action.payload;
    },
    setEditedDocumentWorkflow: (state, action: PayloadAction<Workflow>) => {
      state.currentDocumentMetadata.workflow = action.payload;
    },
    setDocumentDraftType: (state, action: PayloadAction<DraftType>) => {
      state.documentStatus.draftType = action.payload;
    },
    setIsApprovalCategory: (state, action: PayloadAction<boolean>) => {
      state.documentStatus.isApprovalCategory = action.payload;
    },
    setCategorySearch: (state, action: PayloadAction<string>) => {
      state.categorySearch = action.payload;
    },
    setVisibleCategorySearch: (state, action: PayloadAction<string>) => {
      state.visibleCategorySearch = action.payload;
    },
    addSingleSelectSelectedCategoryNode: (
      state,
      action: PayloadAction<SelectedCategory>
    ) => {
      state.selectedCategoryNodes = [action.payload];
    },
    addSelectedCategoryNode: (
      state,
      action: PayloadAction<SelectedCategory>
    ) => {
      state.selectedCategoryNodes = [
        ...state.selectedCategoryNodes,
        action.payload,
      ];
    },
    removeSelectedCategoryNode: (
      state,
      action: PayloadAction<SelectedCategory>
    ) => {
      const cloneState = [...state.selectedCategoryNodes];
      state.selectedCategoryNodes = filter(
        cloneState,
        (cat: SelectedCategory) => {
          return cat.categoryId !== action.payload.categoryId;
        }
      );
    },
    setSelectedCategoryNodes: (
      state,
      action: PayloadAction<SelectedCategory[]>
    ) => {
      state.selectedCategoryNodes = action.payload;
    },
    expandCategoryBreadcrumb: (state, action: PayloadAction<string>) => {
      if (
        indexOf(state.expandedCategoriesBreadcrumbsIds, action.payload) === -1
      ) {
        state.expandedCategoriesBreadcrumbsIds.push(action.payload);
      }
    },
    expandAllCategoryBreadcrumbs: (state) => {
      state.expandedCategoriesBreadcrumbsIds = [];
      state.longCategoryPathsIds.forEach((id) => {
        state.expandedCategoriesBreadcrumbsIds.push(id);
      });
    },
    collapseCategoryBreadcrumb: (state, action: PayloadAction<string>) => {
      if (
        indexOf(state.expandedCategoriesBreadcrumbsIds, action.payload) !== -1
      ) {
        remove(state.expandedCategoriesBreadcrumbsIds, action.payload);
      }
    },
    collapseAllCategoryBreadcrumbs: (state) => {
      state.expandedCategoriesBreadcrumbsIds = [];
    },
    setLongCategoryPathsIds: (state, action: PayloadAction<string[]>) => {
      state.longCategoryPathsIds = action.payload;
    },
    setEditedAndRealDocumentMetadata: (
      state,
      action: PayloadAction<DocumentMetadata>
    ) => {
      state.documentMetadata = { ...action.payload };
      state.currentDocumentMetadata = { ...action.payload };
    },
    setDocumentMetadata: (state, action: PayloadAction<DocumentMetadata>) => {
      state.documentMetadata = { ...action.payload };
    },
    setDocumentStatus: (state, action: PayloadAction<DocumentStatus>) => {
      state.documentStatus = { ...action.payload };
    },
    setEditedDocumentDetails: (
      state,
      action: PayloadAction<DocumentMetadata>
    ) => {
      state.currentDocumentMetadata = { ...action.payload };
    },
    clearEditedDocumentDetails: (state, action: PayloadAction<boolean>) => {
      state.currentDocumentTags = state.documentTags;
      if (action.payload) {
        state.currentDocumentMetadata = {
          ...state.documentMetadata,
          documentIdentity: {
            ...state.documentMetadata.documentIdentity,
            revision:
              state.fetchedNewDocumentRevision ??
              state.documentMetadata.documentIdentity.revision,
          },
        };
      } else {
        state.currentDocumentMetadata = state.documentMetadata;
      }
    },
    clearEditedDocumentIdentity: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.currentDocumentMetadata.documentIdentity = {
          ...state.documentMetadata.documentIdentity,
          revision:
            state.fetchedNewDocumentRevision ??
            state.currentDocumentMetadata.documentIdentity.revision,
        };
      } else {
        state.currentDocumentMetadata.documentIdentity =
          state.documentMetadata.documentIdentity;
      }
    },
    clearEditedDocumentTitles: (state) => {
      state.currentDocumentMetadata.titles = state.documentMetadata.titles;
    },
    clearEditedDocumentDescription: (state) => {
      state.currentDocumentMetadata.summary = state.documentMetadata.summary;
      state.currentDocumentMetadata.documentKind =
        state.documentMetadata.documentKind;
    },
    clearEditedDocumentProducts: (state) => {
      state.currentDocumentMetadata.products = state.documentMetadata.products;
    },
    clearEditedDocumentAttachment: (state) => {
      state.currentDocumentMetadata.attachment =
        state.documentMetadata.attachment;
    },
    clearEditedDocumentSecurity: (state) => {
      state.currentDocumentMetadata.securityLevel =
        state.documentMetadata.securityLevel;
      state.currentDocumentMetadata.accessGroups =
        state.documentMetadata.accessGroups;
    },
    clearEditedDocumentCma: (state) => {
      state.currentDocumentMetadata.countryMarketAvailability =
        state.documentMetadata.countryMarketAvailability;
    },
    clearEditedDocumentCategories: (state) => {
      state.currentDocumentMetadata.categories =
        state.documentMetadata.categories;
    },
    clearEditedDocumentTags: (state) => {
      state.currentDocumentMetadata.tags = state.documentMetadata.tags;
    },
    addNewDocumentTagType: (state, action: PayloadAction<Tag>) => {
      state.currentDocumentTags = [
        ...state.currentDocumentTags,
        action.payload,
      ];
    },
    clearDocumentKind: (state) => {
      state.currentDocumentMetadata.documentKind =
        state.documentMetadata.documentKind = "";
    },
    clearAccessGroups: (state) => {
      state.currentDocumentMetadata.accessGroups =
        state.documentMetadata.accessGroups = [];
    },
    setEditedDocumentCmaSynchronization: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.currentDocumentMetadata.countryMarketAvailability.isSynchronizedWithCma =
        action.payload;
    },
    setEditedDocumentCountries: (state, action: PayloadAction<string[]>) => {
      state.currentDocumentMetadata.countryMarketAvailability.countryIsoCodes =
        action.payload;
    },
    setEditedDocumentCountryAvailabilityType: (
      state,
      action: PayloadAction<CountryAvailabilityType>
    ) => {
      state.currentDocumentMetadata.countryMarketAvailability.countryAvailabilityType =
        action.payload;
    },
    setEditedAndRealDocumentCma: (
      state,
      action: PayloadAction<CountryMarketAvailability>
    ) => {
      state.currentDocumentMetadata.countryMarketAvailability = {
        ...action.payload,
      };
      state.documentMetadata.countryMarketAvailability = { ...action.payload };
    },
    setEditedPopularCategory: (
      state,
      action: PayloadAction<{ categoryId: string; isPopular: boolean }>
    ) => {
      const category = find(state.currentDocumentMetadata.categories, [
        "cid",
        action.payload.categoryId,
      ]);

      if (category) {
        category.isPrioritized = action.payload.isPopular;
      }
    },
    setEditedMainCategory: (state, action: PayloadAction<string>) => {
      const oldMainCategory = find(state.currentDocumentMetadata.categories, [
        "isMain",
        true,
      ]);

      if (oldMainCategory) {
        oldMainCategory.isMain = false;
      }

      const newMainCategory = find(state.currentDocumentMetadata.categories, [
        "cid",
        action.payload,
      ]);

      if (newMainCategory) {
        newMainCategory.isMain = true;
      }
    },
    removeEditedCategory: (state, action: PayloadAction<string>) => {
      remove(state.currentDocumentMetadata.categories, (cat) => {
        return cat.cid === action.payload;
      });
    },
    removeCategory: (state, action: PayloadAction<string>) => {
      remove(state.documentMetadata.categories, (cat) => {
        return cat.cid === action.payload;
      });
    },
    setEditedCategories: (state, action: PayloadAction<string[]>) => {
      const newIds: string[] = [];

      forEach(action.payload, (categoryId) => {
        if (
          !find(state.currentDocumentMetadata.categories, ["cid", categoryId])
        ) {
          newIds.push(categoryId);
        }
      });

      const newCategories = newIds.map((cid) => {
        return {
          cid: cid,
          isMain: false,
          isFromServiceIntegration: false,
          isPrioritized: false,
          isDeleted: false,
        };
      });

      state.currentDocumentMetadata.categories = union(
        state.currentDocumentMetadata.categories,
        newCategories
      );
    },
    setEditedSecurityLevel: (
      state,
      action: PayloadAction<DocumentSecurityLevel | undefined>
    ) => {
      if (action.payload !== undefined) {
        state.currentDocumentMetadata.securityLevel = action.payload;
      }
    },
    setTagsDictionary: (state, action: PayloadAction<DocumentTag[]>) => {
      state.tagsDictionary = action.payload;
    },
    setLanguagesDictionary: (state, action: PayloadAction<Language[]>) => {
      state.languagesDictionary = action.payload;
    },
    setCountriesDictionary: (state, action: PayloadAction<Country[]>) => {
      state.countriesDictionary = action.payload;
    },
    setEditedRevision: (state, action: PayloadAction<string>) => {
      state.currentDocumentMetadata.documentIdentity.revision = action.payload;
    },
    setEditedAttachmentName: (state, action: PayloadAction<string>) => {
      state.currentDocumentMetadata.attachment.name = action.payload;
    },
    setEditedAttachmentId: (state, action: PayloadAction<string | null>) => {
      state.currentDocumentMetadata.attachment.uploadId = null;
      state.currentDocumentMetadata.attachment.attachmentId = action.payload;
    },
    setPendingUploadId: (state, action: PayloadAction<string | null>) => {
      state.currentDocumentMetadata.attachment.uploadId = action.payload;
      state.currentDocumentMetadata.attachment.attachmentId = null;
    },
    setEditedAttachment: (state, action: PayloadAction<DocumentAttachment>) => {
      state.currentDocumentMetadata.attachment = action.payload;
    },
    setEditedProducts: (state, action: PayloadAction<string[]>) => {
      state.currentDocumentMetadata.products = action.payload;
    },
    setEditedSummary: (state, action: PayloadAction<string>) => {
      state.currentDocumentMetadata.summary = action.payload;
    },
    setEditedDocumentKind: (state, action: PayloadAction<string>) => {
      state.currentDocumentMetadata.documentKind = action.payload;
    },
    setEditedDocumentNumber: (state, action: PayloadAction<string>) => {
      state.currentDocumentMetadata.documentIdentity.documentNumber =
        action.payload;
    },
    setEditedPart: (state, action: PayloadAction<string>) => {
      state.currentDocumentMetadata.documentIdentity.documentPart =
        action.payload;
    },
    setEditedLanguage: (state, action: PayloadAction<ComboBoxListItem[]>) => {
      const newIsoCodes = action.payload
        .map((x) => x.key)
        .sort((a, b) => a.localeCompare(b));

      state.currentDocumentMetadata.documentIdentity.documentLanguageCodes =
        newIsoCodes;
    },
    setEditedAccessGroup: (
      state,
      action: PayloadAction<ComboBoxListItem[]>
    ) => {
      const newAccessGroup = action.payload
        .map((x) => x.value)
        .sort((a, b) => a.localeCompare(b));

      state.currentDocumentMetadata.accessGroups = newAccessGroup;
    },
    setEditedTags: (
      state,
      action: PayloadAction<{
        newValues: ComboBoxListItem[];
        tagKey: string;
      }>
    ) => {
      const filtersTags = state.currentDocumentMetadata.tags.filter(
        (x) => x.key !== action.payload.tagKey
      );
      const newTags = action.payload.newValues.map((x) => {
        return { key: action.payload.tagKey, value: x.value };
      });
      filtersTags.push(...newTags);

      state.currentDocumentMetadata.tags = filtersTags.sort(
        (a, b) => a.key.localeCompare(b.key) || a.value.localeCompare(b.value)
      );
    },
    addEmptyTitle: (state, action: PayloadAction<string>) => {
      const title = state.currentDocumentMetadata.titles.find(
        (x) => x.languageIsoCode === action.payload
      );

      if (title === undefined) {
        const cloneState = clone(state.currentDocumentMetadata.titles);
        cloneState.push({
          title: "",
          languageIsoCode: action.payload,
        });

        state.currentDocumentMetadata.titles = cloneState;
      }
    },
    removeTitle: (state, action: PayloadAction<string>) => {
      remove(state.currentDocumentMetadata.titles, (title) => {
        return title.languageIsoCode === action.payload;
      });
    },
    setEditedTitle: (
      state,
      action: PayloadAction<{ newValue: string; isoCode: string }>
    ) => {
      state.currentDocumentMetadata.titles.first(
        (x) => x.languageIsoCode === action.payload.isoCode
      ).title = action.payload.newValue;
    },
    setDocumentTagTypes(state, action: PayloadAction<Tag[]>) {
      state.documentTags = action.payload;
    },
    setCurrentDocumentTagTypes(state, action: PayloadAction<Tag[]>) {
      state.currentDocumentTags = action.payload;
    },
    resetDocumentMetadata: (state) => {
      state.documentMetadata = getInitialMetadata();
      state.currentDocumentMetadata = getInitialMetadata();
      state.documentStatus = getInitialStatus();
      state.documentTags = [];
      state.currentDocumentTags = [];
      state.fetchedNewDocumentRevision = undefined;
    },
    setDocumentAttachmentId(state, action: PayloadAction<string>) {
      state.currentDocumentMetadata.attachment.attachmentId = action.payload;
      state.documentMetadata.attachment.attachmentId = action.payload;
    },
    setMyPermissionsInCategory(
      state,
      action: PayloadAction<PermissionInCategory[]>
    ) {
      state.myPermissionsInMainCategory = action.payload;
    },
    setFetchedNewDocumentRevision(state, action: PayloadAction<string>) {
      state.fetchedNewDocumentRevision = action.payload;
    },
    setDocumentCategories(state, action: PayloadAction<Category[]>) {
      state.documentMetadata.categories = action.payload;
      state.currentDocumentMetadata.categories = action.payload;
    },
    setScheduledAction(state, action: PayloadAction<boolean>) {
      state.hasScheduledAction = action.payload;
    },
    setScheduledActionType(state, action: PayloadAction<Action | undefined>) {
      state.scheduledActionType = action.payload;
    }
  },
});

const documentMetadataSelector = (state: RootState) => state.documentMetadata;

export const selectCategorySearch = createSelector(
  [documentMetadataSelector],
  (state) => state.categorySearch
);

export const selectVisibleCategorySearch = createSelector(
  [documentMetadataSelector],
  (state) => state.visibleCategorySearch
);

export const selectSelectedCategoryNodes = createSelector(
  [documentMetadataSelector],
  (state) => state.selectedCategoryNodes
);

export const selectExpandedCategoriesBreadcrumbsIds = createSelector(
  [documentMetadataSelector],
  (state) => state.expandedCategoriesBreadcrumbsIds
);

export const selectLongCategoryPathsIds = createSelector(
  [documentMetadataSelector],
  (state) => state.longCategoryPathsIds
);
export const selectDocumentStatus = createSelector(
  [documentMetadataSelector],
  (state) => state.documentStatus
);

export const selectIsDocumentFromServiceIntegration = createSelector(
  [documentMetadataSelector],
  (state) => state.documentStatus.isFromServiceIntegration
);

export const selectDocumentDraftType = createSelector(
  [documentMetadataSelector],
  (state) => state.documentStatus.draftType
);

export const selectIsApprovalMainCategory = createSelector(
  [documentMetadataSelector],
  (state) => state.documentStatus.isApprovalCategory
);

export const selectDocumentMetadata = createSelector(
  [documentMetadataSelector],
  (state) => state.documentMetadata
);

export const selectCurrentDocumentMetadata = createSelector(
  [documentMetadataSelector],
  (state) => state.currentDocumentMetadata
);

export const selectDocumentWorkflow = createSelector(
  [selectDocumentMetadata],
  (state) => state.workflow
);

export const selectDocumentSecurityLevel = createSelector(
  [selectDocumentMetadata],
  (state) => state.securityLevel
);

export const selectDocumentMainCategory = createSelector(
  [selectDocumentMetadata],
  (state) => find(state.categories, ["isMain", true])
);

export const selectDocumentCategories = createSelector(
  [selectDocumentMetadata],
  (state) => state.categories
);

export const selectDocumentIdentity = createSelector(
  [selectDocumentMetadata],
  (state) => state.documentIdentity
);

export const selectCurrentDocumentIdentity = createSelector(
  [selectCurrentDocumentMetadata],
  (state) => state.documentIdentity
);

export const selectLanguages = createSelector(
  [documentMetadataSelector],
  (state) => state.languagesDictionary
);

export const selectDocumentAccessGroups = createSelector(
  [selectDocumentMetadata],
  (state) => state.accessGroups
);

export const selectCurrentDocumentAttachment = createSelector(
  [selectCurrentDocumentMetadata],
  (state) => state.attachment
);

export const selectCurrentDocumentKind = createSelector(
  [selectCurrentDocumentMetadata],
  (state) => state.documentKind
);

export const selectCurrentDocumentSummary = createSelector(
  [selectCurrentDocumentMetadata],
  (state) => state.summary
);

export const selectCurrentDocumentAccessGroups = createSelector(
  [selectCurrentDocumentMetadata],
  (state) => state.accessGroups
);

export const selectCurrentDocumentProducts = createSelector(
  [selectCurrentDocumentMetadata],
  (state) => state.products
);

export const selectCurrentDocumentSecurityLevel = createSelector(
  [selectCurrentDocumentMetadata],
  (state) => state.securityLevel
);

export const selectCurrentDocumentCategories = createSelector(
  [selectCurrentDocumentMetadata],
  (state) => state.categories
);

export const selectCurrentDocumentMainCategory = createSelector(
  [selectCurrentDocumentMetadata],
  (state) => find(state.categories, ["isMain", true])
);

export const selectCurrentDocumentPopularCategories = createSelector(
  [selectCurrentDocumentMetadata],
  (state) => filter(state.categories, ["isPrioritized", true])
);

export const selectAvailableTagsByType = createSelector(
  [documentMetadataSelector, (state, type: string) => type],
  (state, type) => state.tagsDictionary.filter((t) => t.key === type)
);

export const selectAvailableTags = createSelector(
  [documentMetadataSelector],
  (state) => state.tagsDictionary
);

export const selectAvailableTagTypes = createSelector(
  [documentMetadataSelector],
  (state) =>
    state.tagsDictionary.map((t) => {
      return t.key;
    })
);

export const selectCurrentDocumentTags = createSelector(
  [selectCurrentDocumentMetadata],
  (state) => state.tags
);

export const selectCurrentDocumentTagsByType = createSelector(
  [selectCurrentDocumentMetadata, (state, type: string) => type],
  (state, type) => state.tags.filter((t) => t.key === type)
);

export const selectDocumentTagsByType = createSelector(
  [selectDocumentMetadata, (state, type: string) => type],
  (state, type) => state.tags.filter((t) => t.key === type)
);

export const selectDocumentTags = createSelector(
  [selectDocumentMetadata],
  (state) => state.tags
);

export const selectDocumentTagTypes = createSelector(
  [selectDocumentMetadata],
  (state) => state.tags.map((t) => t.key)
);

export const selectCurrentDocumentTitles = createSelector(
  [selectCurrentDocumentMetadata],
  (state) => state.titles
);

export const selectCurrentDocumentLanguages = createSelector(
  [selectCurrentDocumentMetadata],
  (state) => state.documentIdentity.documentLanguageCodes
);

export const selectCurrentDocumentCma = createSelector(
  [selectCurrentDocumentMetadata],
  (state) => state.countryMarketAvailability
);

export const selectCountriesDictionary = createSelector(
  [documentMetadataSelector],
  (state) => state.countriesDictionary
);

export const selectDirectCategoryCompany = createSelector(
  [documentMetadataSelector],
  (state) => state.documentMetadata.company
);

export const selectFetchedNewDocumentRevision = createSelector(
  [documentMetadataSelector],
  (state) => state.fetchedNewDocumentRevision
);

export const selectedAccessGroupIsEdited = createSelector(
  [documentMetadataSelector],
  (state) => {
    if (
      state.currentDocumentMetadata.securityLevel !==
      DocumentSecurityLevel.ExternalPublicAndInternalPublic &&
      state.currentDocumentMetadata.securityLevel !==
      DocumentSecurityLevel.HiddenFromExternalAndInternalPublic &&
      state.currentDocumentMetadata.securityLevel !==
      DocumentSecurityLevel.InternalPublic
    ) {
      const detailsAccessGroups = state.documentMetadata.accessGroups
        .slice()
        .sort((a, b) => a.localeCompare(b));
      const currentDetailsAccessGroups =
        state.currentDocumentMetadata.accessGroups
          .slice()
          .sort((a, b) => a.localeCompare(b));

      return !isEqual(detailsAccessGroups, currentDetailsAccessGroups);
    }
    return false;
  }
);

export const selectCmaCountriesIsEdited = createSelector(
  [documentMetadataSelector],
  (state) => {
    const detailsCountries =
      state.documentMetadata.countryMarketAvailability.countryIsoCodes
        .slice()
        .sort((a, b) => a.localeCompare(b));
    const currentDetailsCountries =
      state.currentDocumentMetadata.countryMarketAvailability.countryIsoCodes
        .slice()
        .sort((a, b) => a.localeCompare(b));

    return !isEqual(detailsCountries, currentDetailsCountries);
  }
);

export const selectDocumentIsEdited = createSelector(
  [documentMetadataSelector],
  (state) => !isEqual(state.documentMetadata, state.currentDocumentMetadata)
);

export const selectRevisionIsEdited = (state: RootState): boolean => {
  if (
    state.documentMetadata.fetchedNewDocumentRevision ===
    state.documentMetadata.currentDocumentMetadata.documentIdentity
      .revision &&
    state.documentDetails.view === DocumentDetailView.NewRevision
  ) {
    return false;
  }

  if (!state.documentMetadata.documentMetadata.documentIdentity.revision)
    return false;

  return (
    state.documentMetadata.documentMetadata.documentIdentity.revision !==
    state.documentMetadata.currentDocumentMetadata.documentIdentity.revision
  );
};

export const selectCategoriesAreEdited = createSelector(
  [documentMetadataSelector],
  (state) =>
    !isEqual(
      state.documentMetadata.categories,
      state.currentDocumentMetadata.categories
    )
);

export const selectNewDocumentCategories = createSelector(
  [documentMetadataSelector],
  (state) => {
    const categoryIds = state.documentMetadata.categories.map((x) => x.cid);
    return state.currentDocumentMetadata.categories
      .filter((x) => !categoryIds.includes(x.cid))
      .map((x) => x.cid);
  }
);

export const selectMainCategoryIsEdited = createSelector(
  [documentMetadataSelector],
  (state) => {
    const oldMainCategory = find(state.documentMetadata.categories, [
      "isMain",
      true,
    ]);

    const newMainCategory = find(state.currentDocumentMetadata.categories, [
      "isMain",
      true,
    ]);

    return !isEqual(oldMainCategory?.cid, newMainCategory?.cid);
  }
);

export const selectDocumentKindIsEdited = createSelector(
  [documentMetadataSelector],
  (state) =>
    state.documentMetadata.documentKind !==
    state.currentDocumentMetadata.documentKind
);

export const selectSummaryIsEdited = createSelector(
  [documentMetadataSelector],
  (state) =>
    state.documentMetadata.summary !== state.currentDocumentMetadata.summary
);

export const selectSecurityIsEdited = createSelector(
  [documentMetadataSelector],
  (state) =>
    state.documentMetadata.securityLevel !==
    state.currentDocumentMetadata.securityLevel
);

export const selectDocumentNumberIsEdited = createSelector(
  [documentMetadataSelector],
  (state) =>
    state.documentMetadata.documentIdentity.documentNumber !==
    state.currentDocumentMetadata.documentIdentity.documentNumber
);

export const selectPartIsEdited = createSelector(
  [documentMetadataSelector],
  (state) =>
    state.documentMetadata.documentIdentity.documentPart !==
    state.currentDocumentMetadata.documentIdentity.documentPart
);

export const selectLanguagesIsEdited = createSelector(
  [documentMetadataSelector],
  (state) => {
    const detailsMetadataLanguages =
      state.documentMetadata.documentIdentity.documentLanguageCodes
        .slice()
        .sort((a, b) => a.localeCompare(b));
    const currentDetailsMetadataLanguages =
      state.currentDocumentMetadata.documentIdentity.documentLanguageCodes
        .slice()
        .sort((a, b) => a.localeCompare(b));

    return !isEqual(detailsMetadataLanguages, currentDetailsMetadataLanguages);
  }
);

export const selectEditedTitles = createSelector(
  [documentMetadataSelector],
  (state) => {
    const detailsMetadataTitles = state.documentMetadata.titles.map(
      (x) => x.languageIsoCode + x.title
    );
    const currentDetailsMetadataTitles =
      state.currentDocumentMetadata.titles.map(
        (x) => x.languageIsoCode + x.title
      );

    return currentDetailsMetadataTitles.filter(
      (x) => !detailsMetadataTitles.includes(x)
    );
  }
);

export const selectEditedAccessGroups = createSelector(
  [documentMetadataSelector],
  (state) => {
    const detailsAccessGroup = state.documentMetadata.accessGroups
      .slice()
      .sort((a, b) => a.localeCompare(b));
    const currentDetailsAccessGroup = state.currentDocumentMetadata.accessGroups
      .slice()
      .sort((a, b) => a.localeCompare(b));

    return currentDetailsAccessGroup.filter(
      (x) => !detailsAccessGroup.includes(x)
    );
  }
);

export const selectEditedLanguages = createSelector(
  [documentMetadataSelector],
  (state) => {
    const detailsMetadataLanguages =
      state.documentMetadata.documentIdentity.documentLanguageCodes
        .slice()
        .sort((a, b) => a.localeCompare(b));
    const currentDetailsMetadataLanguages =
      state.currentDocumentMetadata.documentIdentity.documentLanguageCodes
        .slice()
        .sort((a, b) => a.localeCompare(b));

    return currentDetailsMetadataLanguages.filter(
      (x) => !detailsMetadataLanguages.includes(x)
    );
  }
);

export const selectEditedTags = createSelector(
  [documentMetadataSelector],
  (state) => {
    const detailsMetadataTags = state.documentMetadata.tags.map(
      (x) => x.key + x.value
    );

    const currentDetailsMetadataTags = state.currentDocumentMetadata.tags.map(
      (x) => x.key + x.value
    );

    return state.currentDocumentMetadata.tags
      .filter((x) => !detailsMetadataTags.includes(x.key + x.value))
      .concat(
        state.documentMetadata.tags.filter(
          (x) => !currentDetailsMetadataTags.includes(x.key + x.value)
        )
      );
  }
);

export const selectEditedProducts = createSelector(
  [documentMetadataSelector],
  (state) => {
    const detailsMetadataProducts = state.documentMetadata.products;

    return state.currentDocumentMetadata.products.filter(
      (x) => !detailsMetadataProducts.includes(x)
    );
  }
);

export const selectEditedCmaCountries = createSelector(
  [documentMetadataSelector],
  (state) => {
    const detailsCodes =
      state.documentMetadata.countryMarketAvailability.countryIsoCodes;
    const currentDetailsCodes =
      state.currentDocumentMetadata.countryMarketAvailability.countryIsoCodes;

    return state.currentDocumentMetadata.countryMarketAvailability.countryIsoCodes
      .filter((x) => !detailsCodes.includes(x))
      .concat(
        state.documentMetadata.countryMarketAvailability.countryIsoCodes.filter(
          (x) => !currentDetailsCodes.includes(x)
        )
      );
  }
);

export const selectTitlesIsEdited = createSelector(
  [documentMetadataSelector],
  (state) => {
    const detailsMetadataTitles = state.documentMetadata.titles
      .slice()
      .sort(
        (a, b) =>
          a.title.localeCompare(b.title) ||
          a.languageIsoCode.localeCompare(b.languageIsoCode)
      );

    const currentDetailsMetadataTitles = state.currentDocumentMetadata.titles
      .slice()
      .sort(
        (a, b) =>
          a.title.localeCompare(b.title) ||
          a.languageIsoCode.localeCompare(b.languageIsoCode)
      );

    return !isEqual(detailsMetadataTitles, currentDetailsMetadataTitles);
  }
);

export const selectTagsIsEdited = createSelector(
  [documentMetadataSelector],
  (state) => {
    return checkIfTagsSectionIsEdited(
      state.documentMetadata.tags,
      state.currentDocumentMetadata.tags,
      state.documentTags,
      state.currentDocumentTags
    );
  }
);

export const selectProductsIsEdited = createSelector(
  [documentMetadataSelector],
  (state) => {
    const detailsMetadataProducts = state.documentMetadata.products
      .slice()
      .sort((a, b) => a.localeCompare(b));
    const currentDetailsMetadataProducts =
      state.currentDocumentMetadata.products
        .slice()
        .sort((a, b) => a.localeCompare(b));

    return !isEqual(detailsMetadataProducts, currentDetailsMetadataProducts);
  }
);

export const selectCmaIsEdited = createSelector(
  [documentMetadataSelector],
  (state) => {
    return !isEqual(
      state.documentMetadata.countryMarketAvailability,
      state.currentDocumentMetadata.countryMarketAvailability
    );
  }
);

export const selectExternalSecurityLevelIsEdited = createSelector(
  [documentMetadataSelector],
  (state) => {
    const documentExternalSecurityLevel = getExternalSecurityLevel(
      state.documentMetadata.securityLevel
    );
    const currentDocumentExternalSecurityLevel = getExternalSecurityLevel(
      state.currentDocumentMetadata.securityLevel
    );

    return (
      documentExternalSecurityLevel !== currentDocumentExternalSecurityLevel
    );
  }
);

export const selectInternalSecurityLevelIsEdited = createSelector(
  [documentMetadataSelector],
  (state) => {
    const documentExternalSecurityLevel = getInternalSecurityLevel(
      state.documentMetadata.securityLevel
    );
    const currentDocumentExternalSecurityLevel = getInternalSecurityLevel(
      state.currentDocumentMetadata.securityLevel
    );

    return (
      documentExternalSecurityLevel !== currentDocumentExternalSecurityLevel
    );
  }
);

export const selectAttachmentIsEdited = createSelector(
  [documentMetadataSelector],
  (state) => {
    return !isEqual(
      state.currentDocumentMetadata.attachment,
      state.documentMetadata.attachment
    );
  }
);

export const selectIsTrimmedDocumentNumberEdited = createSelector(
  [documentMetadataSelector],
  (state) =>
    state.documentMetadata.documentIdentity.documentNumber.trim() !==
    state.currentDocumentMetadata.documentIdentity.documentNumber.trim()
);

export const selectIsTrimmedPartEdited = createSelector(
  [documentMetadataSelector],
  (state) =>
    state.documentMetadata.documentIdentity.documentPart.trim() !==
    state.currentDocumentMetadata.documentIdentity.documentPart.trim()
);

export const selectIsOnlyCategoriesSectionEdited = (
  state: RootState
): boolean => {
  return (
    !selectIdentitySectionIsEdited(state) &&
    !selectTitleSectionIsEdited(state) &&
    !selectDescriptionSectionIsEdited(state) &&
    !selectAttachmentIsEdited(state) &&
    !selectSecuritySectionIsEdited(state) &&
    !selectCmaSectionIsEdited(state) &&
    !selectProductsSectionIsEdited(state) &&
    !selectTagsSectionIsEdited(state) &&
    selectCategoriesAreEdited(state)
  );
};

export const selectTitleSectionIsEdited = (state: RootState): boolean =>
  selectTitlesIsEdited(state);

export const selectCmaSectionIsEdited = (state: RootState): boolean =>
  selectCmaIsEdited(state);

export const selectSecuritySectionIsEdited = (state: RootState): boolean =>
  selectSecurityIsEdited(state) || selectedAccessGroupIsEdited(state);

export const selectDescriptionSectionIsEdited = (state: RootState): boolean =>
  selectSummaryIsEdited(state) || selectDocumentKindIsEdited(state);

export const selectIdentitySectionIsEdited = (state: RootState): boolean =>
  selectDocumentNumberIsEdited(state) ||
  selectLanguagesIsEdited(state) ||
  selectPartIsEdited(state) ||
  selectRevisionIsEdited(state);

export const selectIsTrimmedIdentitySectionEdited = (
  state: RootState
): boolean =>
  selectIsTrimmedDocumentNumberEdited(state) ||
  selectLanguagesIsEdited(state) ||
  selectIsTrimmedPartEdited(state) ||
  selectRevisionIsEdited(state);

export const selectTagsSectionIsEdited = (state: RootState): boolean =>
  selectTagsIsEdited(state);

export const selectProductsSectionIsEdited = (state: RootState): boolean =>
  selectProductsIsEdited(state);

export const selectIsRevisionLatest = createSelector(
  [documentMetadataSelector],
  (state) => state.documentStatus.isLatest
);

export const selectIsRevisionLater = createSelector(
  [documentMetadataSelector],
  (state) => state.documentStatus.isLaterRevision
);

export const selectHasPendingApproval = (state: RootState): boolean =>
  state.documentMetadata.documentStatus.hasPendingApproval;

export const selectHasOtherRevisionWithPendingApproval = (
  state: RootState
): boolean =>
  state.documentMetadata.documentStatus.hasOtherRevisionWithPendingApproval;

export const selectCanApprovePendingApproval = (state: RootState): boolean =>
  state.documentMetadata.documentStatus.canApprovePendingApproval;

export const selectAvailableDocumentTags = (state: RootState) =>
  state.documentMetadata.documentTags;

export const selectAvailableCurrentDocumentTags = (state: RootState) =>
  state.documentMetadata.currentDocumentTags;

export const selectAvailableCurrentDocumentTagTypes = (state: RootState) =>
  state.documentMetadata.currentDocumentTags.map((dt) => dt.name);

export const selectPublisherEmail = createSelector(
  [documentMetadataSelector],
  (state) => state.documentMetadata.publisherEmail
);

export const selectMyPermissionsInCategory = createSelector(
  [documentMetadataSelector],
  (state) => state.myPermissionsInMainCategory
);

export const selectHasScheduledAction = (state: RootState) =>
  state.documentMetadata.hasScheduledAction;

export const selectScheduledActionType = (state: RootState) =>
  state.documentMetadata.scheduledActionType;


export const {
  clearEditedDocumentDetails,
  clearDocumentKind,
  clearAccessGroups,
  setDocumentMetadata,
  setCountriesDictionary,
  setLanguagesDictionary,
  setEditedSecurityLevel,
  setEditedAndRealDocumentMetadata,
  setEditedDocumentDetails,
  setEditedDocumentCmaSynchronization,
  setEditedAndRealDocumentCma,
  setEditedRevision,
  setEditedSummary,
  setEditedDocumentNumber,
  setEditedPart,
  setEditedLanguage,
  setEditedAccessGroup,
  setEditedTitle,
  setEditedDocumentKind,
  setEditedTags,
  setEditedProducts,
  setEditedAttachmentName,
  setEditedAttachmentId,
  setPendingUploadId,
  setEditedAttachment,
  setEditedMainCategory,
  setDocumentAction,
  addEmptyTitle,
  removeTitle,
  collapseAllCategoryBreadcrumbs,
  collapseCategoryBreadcrumb,
  expandAllCategoryBreadcrumbs,
  expandCategoryBreadcrumb,
  setLongCategoryPathsIds,
  setEditedPopularCategory,
  removeEditedCategory,
  removeCategory,
  setEditedCategories,
  setDocumentStatus,
  setCategorySearch,
  setVisibleCategorySearch,
  setSelectedCategoryNodes,
  addSelectedCategoryNode,
  addSingleSelectSelectedCategoryNode,
  removeSelectedCategoryNode,
  clearEditedDocumentAttachment,
  clearEditedDocumentCategories,
  clearEditedDocumentCma,
  clearEditedDocumentDescription,
  clearEditedDocumentIdentity,
  clearEditedDocumentProducts,
  clearEditedDocumentSecurity,
  clearEditedDocumentTitles,
  clearEditedDocumentTags,
  setEditedDocumentCountries,
  setEditedDocumentCountryAvailabilityType,
  setDocumentTagTypes,
  setCurrentDocumentTagTypes,
  setDocumentIsNew,
  setDocumentWorkflow,
  setEditedDocumentWorkflow,
  setDocumentDraftType,
  resetDocumentMetadata,
  setDocumentAttachmentId,
  setMyPermissionsInCategory,
  setTagsDictionary,
  addNewDocumentTagType,
  setFetchedNewDocumentRevision,
  setIsApprovalCategory,
  setDocumentCategories,
  setScheduledAction,
  setScheduledActionType
} = documentMetadataSlice.actions;

export default documentMetadataSlice.reducer;
