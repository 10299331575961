import { outlinedInputClasses } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import {
  selectDocumentsCount,
  selectIsDocumentsListLoading,
  selectPageNumber,
  selectPageSize,
  setPageNumber,
  setPageSize,
} from "../../../app/slices/documentListSlice";
import { DocumentsPagingDiv } from "./SC/DocumentsPagingDiv";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { PageDiv } from "./SC/PageDiv";
import { PagingSettingsDiv } from "./SC/PagingSettingsDiv";
import { DocumentsPerPageSelect } from "./SC/DocumentsPerPageSelect";
import { PageNumberInput } from "./SC/PageNumberInput";
import { PageInfoSpan } from "./SC/PageInfoSpan";
import { StyledSkeleton } from "./SC/StyledSkeleton";
import { StyledMenuItem } from "./SC/StyledMenuItem";
import { StyledIconButton } from "../../../controls/StyledComponents/StyledIconButton";
import { TooltipTop } from "../../../controls/Tooltips/ToolipTop";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";

export function DocumentsPagination() {
  const pageSize = useAppSelector(selectPageSize);
  const pageNumber = useAppSelector(selectPageNumber);
  const documentsCount = useAppSelector(selectDocumentsCount);
  const isDocumentsListLoading = useAppSelector(selectIsDocumentsListLoading);
  const dispatch = useAppDispatch();

  return (
    <DocumentsPagingDiv id="documents-paging-div">
      <PagingSettingsDiv>
        <span>Documents per page:</span>
        <DocumentsPerPageSelect
          id="documents-paging-page-size-combobox"
          size="small"
          value={pageSize}
          onChange={(e) => dispatch(setPageSize(e.target.value as number))}
          classes={outlinedInputClasses}
        >
          <StyledMenuItem value={10}>10</StyledMenuItem>
          <StyledMenuItem value={20}>20</StyledMenuItem>
          <StyledMenuItem value={30}>30</StyledMenuItem>
        </DocumentsPerPageSelect>
      </PagingSettingsDiv>
      <PageDiv>
        <TooltipTop title={TooltipMsg.PreviousPage}>
          <StyledIconButton
            padding={2}
            onClick={() => dispatch(setPageNumber(pageNumber - 1))}
            id="documents-paging-down"
            size="medium"
          >
            <ChevronLeftIcon fontSize="inherit" />
          </StyledIconButton>
        </TooltipTop>
        <PageNumberInput
          id="documents-paging-page-size-input"
          size="small"
          type="number"
          value={pageNumber}
          onChange={(e) => dispatch(setPageNumber(parseInt(e.target.value)))}
        />
        <TooltipTop title={TooltipMsg.NextPage}>
          <StyledIconButton
            padding={2}
            id="documents-paging-up"
            size="medium"
            onClick={() => dispatch(setPageNumber(pageNumber + 1))}
          >
            <ChevronRightIcon fontSize="inherit" />
          </StyledIconButton>
        </TooltipTop>
        {isDocumentsListLoading ? (
          <PageInfoSpan>
            <StyledSkeleton variant="text" width={120} />
          </PageInfoSpan>
        ) : (
          <PageInfoSpan>
            {pageSize * (isNaN(pageNumber) ? 1 : pageNumber) - pageSize + 1}-
            {Math.min(pageSize * (isNaN(pageNumber) ? 1 : pageNumber), documentsCount)} of{" "}
            {documentsCount}
          </PageInfoSpan>
        )}
      </PageDiv>
    </DocumentsPagingDiv>
  );
}
