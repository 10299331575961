import Country from "../../models/country";

export function getCountriesKvpFromIsoCodes(
  countryIsoCodes: string[],
  countriesDictionary: Country[]
) {
  return countryIsoCodes.map((isoCode) => {
    const foundCountry = countriesDictionary.find(
      (country) => country.isoCode === isoCode
    );

    return {
      key: isoCode,
      value: foundCountry?.name ?? "",
    };
  });
}
