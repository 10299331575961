import styled from "styled-components";

interface DashDivProps {
  showDash?: boolean;
}

export const DashDiv = styled.div<DashDivProps>`
  margin: 16px 0 20px 0;
  width: 36px;
  height: 5px;
  background-color: ${(props) => props.showDash ? props.theme.palettes.primary.main : "undefined"};
`;
