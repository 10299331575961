import { ConfigTab } from "./Config/ConfigTab";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { selectLogoutPrompt } from "../../app/slices/documentDetailsSlice";
import usePrompt from "../../app/hooks/usePrompt";
import {
  clearCurrentPublisherCategoriesSettings,
  clearPublisherCategoriesSettings,
  selectIsEditable,
  selectIsEdited,
} from "../../app/slices/publisherCategoriesSettingsSlice";
import { useEffect } from "react";

interface SettingsProps {
  isLoading: boolean;
}

export function Settings(props: SettingsProps) {
  const dispatch = useAppDispatch();
  const logoutPrompt = useAppSelector(selectLogoutPrompt);
  const settingsIsEdited = useAppSelector(selectIsEdited);
  const isEditable = useAppSelector(selectIsEditable);

  usePrompt(
    "All changes will be lost. Are you sure you want to leave?",
    settingsIsEdited && !logoutPrompt
  );

  useEffect(() => {
    return () => {
      dispatch(clearCurrentPublisherCategoriesSettings());
      dispatch(clearPublisherCategoriesSettings());
    };
  }, [dispatch]);

  return <ConfigTab isEditable={isEditable} isLoading={props.isLoading} />;
}
