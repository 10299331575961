import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function RevisionStepOne() {
  return (
    <StepContentDiv>
      <Paragraph>
        Revision identifies the sequential version of a document.
      </Paragraph>
      <Paragraph>
        The ABB revisioning standard adheres to the principle of using letters such as A, B, C, ..., Z, AA, AB, ..., ZZ, and so on.
      </Paragraph>
      <Paragraph>
        A new revision should be assigned for any modifications made to the attachment.
      </Paragraph>
      <Paragraph>
        When a new revision is published, the previous revision is automatically archived.
      </Paragraph>
      <Paragraph>
        The revision value should match the one indicated in the file itself.
      </Paragraph>
    </StepContentDiv>
  );
}
