import { createStep } from "../../../helpers/selfHelpBuilder";
import { CategorySectionStep1 } from "./CategorySectionStep1";
import { CategorySectionStep5 } from "./CategorySectionStep5";
import { CategorySectionStep6 } from "./CategorySectionStep6";
import { CollapseStep2 } from "./CollapseStep2";
import { SearchBoxStep4 } from "./SearchBoxStep4";
import { SplitterStep3 } from "./SplitterStep3";

export const publishersNavigationSteps = () => {
  return [
    createStep(".left-pane", <CategorySectionStep1 />, 520, "right"),
    createStep("#navigation-collapse-button", <CollapseStep2 />, 520, "right"),
    createStep(".reflex-splitter", <SplitterStep3 />, 520, "right"),
    createStep("#category-search-input-div", <SearchBoxStep4 />, 520, "right"),
    createStep(".left-pane", <CategorySectionStep5 />, 520, "right"),
    createStep(".left-pane", <CategorySectionStep6 />, 520, "right"),
  ];
};
