import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useLazyGetDocumentTreeQuery } from "../../../../apis/documentsListApi";
import { getSortedLanguageNameFromISOArray } from "../../../../app/helpers/getLanguageName";
import { openSnackbar } from "../../../../app/helpers/snackBarHelper";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { selectFitToScreen } from "../../../../app/slices/contentSlice";
import {
  selectAllGroupAccordionState,
  selectAreAllGroupsExpanded,
  selectDensity,
  selectDocumentGroup,
  selectIsDocumentsListFetching,
  setDocumentGroup,
} from "../../../../app/slices/documentListSlice";
import {
  selectDocumentColumn,
  selectTitleWidthForGroup,
} from "../../../../app/slices/documentListViewSlice";
import {
  Density,
  chevronMinWidth,
  documentNumberMinWidth,
  optionsMinWidth,
} from "../../../../app/slices/models/documentsModels";
import {
  selectIncludeDocumentsFromSubcategories,
  selectIncludeDocumentsICannotManage,
  selectNavigationFilters,
  selectShowOnlyUnCategorizedDocuments,
} from "../../../../app/slices/navigationSlice";
import { TooltipTop } from "../../../../controls/Tooltips/ToolipTop";
import groupIcon from "../../../../icons/mutliple_doc_outlined.svg";
import DocumentListItem from "../../../../models/documentList/documentListItem";
import {
  DocumentLanguageGroup,
  GroupedDocumentTreeResult,
} from "../../../../models/documentList/groupedDocumentTreeResult";
import { DocumentsColumnType } from "../../../../models/documentList/types/DocumentColumnType";
import { WordBreakType } from "../../../../models/documentList/types/WordBreakType";
import { AccordionState } from "../../../../models/documentList/types/accordionState";
import { SnackbarType } from "../../../../models/snackbar";
import { StyledDocumentIcon } from "../../SC/StyledDocumentIcon";
import { DocumentsCell } from "../documentListItem/cells/DocumentsCell";
import { EllipsisText } from "../documentListItem/cells/EllipsisText";
import { DocumentsCellWithHighlight } from "../documentListItem/cells/styledComponents/DocumentsCellWithHighlight";
import DocumentOptions from "../documentListItem/documentOptions/DocumentOptions";
import { ChevronCellDiv } from "../documentListItem/styledComponents/ChevronCellDiv";
import { ChevronDiv } from "../documentListItem/styledComponents/ChevronDiv";
import { WorkflowDiv } from "../documentListItem/styledComponents/WorfklowDiv";
import { getMaxHeight, getMinHeight } from "../helpers/densityHelper";
import { DocumentListGroupError } from "./DocumentListGroupError";
import { GroupSkeleton } from "./GroupSkeleton";
import { LanguagesAccordion } from "./LanguagesAccordion";
import { StyledAccordion } from "./styledComponents/StyledAccordion";
import { StyledAccordionDetails } from "./styledComponents/StyledAccordionDetails";
import { StyledAccordionSummary } from "./styledComponents/StyledAccordionSummary";
import { DocumentListGroupDiv } from "./styledComponents/DocumentListGroupDiv";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import { SnackbarMsg } from "../../../../controls/Snackbar/SnackbarMessages";

export interface DocumentListGroupProps {
  documentListItem: DocumentListItem;
  zebraStripe: boolean;
}

export function DocumentListGroup(props: DocumentListGroupProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const columnWidths = useAppSelector(selectDocumentColumn);
  const density = useAppSelector(selectDensity);
  const fitToScreen = useAppSelector(selectFitToScreen);
  const filters = useAppSelector(selectNavigationFilters);
  const allGroupAccordionState = useAppSelector(selectAllGroupAccordionState);
  const groupedTitleWidth = useAppSelector(selectTitleWidthForGroup);
  const areAllGroupsExpanded = useAppSelector(selectAreAllGroupsExpanded);
  const isDocumentListFetching = useAppSelector(selectIsDocumentsListFetching);
  const includeDocsFromSubcats = useAppSelector(
    selectIncludeDocumentsFromSubcategories
  );
  const includeDocsIcannotManage = useAppSelector(
    selectIncludeDocumentsICannotManage
  );
  const showOnlyUnCategorizedDocuments = useAppSelector(
    selectShowOnlyUnCategorizedDocuments
  );
  const [showVersionsTooltip, setShowVersionsTooltip] = useState(false);
  const isDocumentGroupExpanded = useAppSelector((state) =>
    selectDocumentGroup(state, props.documentListItem.documentNumber)
  );

  const isGray =
    !props.documentListItem.hasAccess ||
    props.documentListItem.isFromServiceIntegration;

  const [documentsTree, setDocumentsTree] = useState<
    GroupedDocumentTreeResult | undefined
  >(undefined);
  const [getDocumentTree, { isLoading: isDocumentsTreeLoading, isError }] =
    useLazyGetDocumentTreeQuery();

  const handleExpandCollapse = useCallback(() => {
    if (isDocumentListFetching) {
      return;
    }

    if (!isDocumentGroupExpanded) {
      dispatch(
        setDocumentGroup({
          documentNumber: props.documentListItem.documentNumber,
          isExpanded: true,
        })
      );
    } else {
      dispatch(
        setDocumentGroup({
          documentNumber: props.documentListItem.documentNumber,
          isExpanded: false,
        })
      );
    }
  }, [
    isDocumentListFetching,
    props.documentListItem.documentNumber,
    isDocumentGroupExpanded,
    dispatch,
  ]);

  useEffect(() => {
    if (
      allGroupAccordionState === AccordionState.Expanded &&
      areAllGroupsExpanded &&
      !isDocumentGroupExpanded
    ) {
      handleExpandCollapse();
    }
  }, [
    allGroupAccordionState,
    areAllGroupsExpanded,
    props.documentListItem.documentNumber,
    isDocumentGroupExpanded,
    handleExpandCollapse,
    dispatch,
  ]);

  const reloadDocumentTree = useCallback(() => {
    getDocumentTree(
      {
        documentIdentity: {
          documentNumber: props.documentListItem.documentNumber,
          documentPart: props.documentListItem.part,
          documentLanguageCodes: props.documentListItem.languageCodes,
        },
        documentNumber: props.documentListItem.documentNumber,
        documentTitle: filters.searchDocumentTitle,
        includeDocumentsFromSubcategories: includeDocsFromSubcats,
        includeDocumentsICannotManage: includeDocsIcannotManage,
        showOnlyUnCategorizedDocuments: showOnlyUnCategorizedDocuments,
        ...filters,
      },
      true
    )
      .unwrap()
      .then((response) => {
        setDocumentsTree(response);
      })
      .catch(() =>
        openSnackbar(
          dispatch,
          SnackbarMsg.DocumentGroupError,
          SnackbarType.error
        )
      );
  }, [
    getDocumentTree,
    filters,
    includeDocsFromSubcats,
    includeDocsIcannotManage,
    props.documentListItem.documentNumber,
    props.documentListItem.languageCodes,
    props.documentListItem.part,
    showOnlyUnCategorizedDocuments,
    dispatch,
  ]);

  useEffect(() => {
    if (isDocumentGroupExpanded && !documentsTree && !isError) {
      reloadDocumentTree();
    }
  }, [isDocumentGroupExpanded, documentsTree, reloadDocumentTree, isError]);

  useEffect(() => {
    if (
      (isDocumentGroupExpanded === undefined &&
        allGroupAccordionState === AccordionState.Unset) ||
      isError
    ) {
      setDocumentsTree(undefined);
    }
  }, [isDocumentGroupExpanded, allGroupAccordionState, isError]);

  function getLanguageNames() {
    return documentsTree?.languages.map((language) => {
      return {
        ...language,
        languageNames: getSortedLanguageNameFromISOArray(language.isoCodes),
      } as DocumentLanguageGroup;
    });
  }

  return (
    <DocumentListGroupDiv
      density={density}
      className="document-list-group-item"
    >
      <StyledAccordion
        id={
          "document-list-group-accordion-" +
          props.documentListItem.documentNumber
        }
        disableGutters={true}
        square={true}
        $isGray={isGray}
        onChange={handleExpandCollapse}
        expanded={!!isDocumentGroupExpanded}
        $zebraStripe={props.zebraStripe}
        $useBorder={true}
      >
        <TooltipTop
          open={showVersionsTooltip}
          onMouseEnter={() => setShowVersionsTooltip(true)}
          onMouseLeave={() => setShowVersionsTooltip(false)}
          title={props.documentListItem.groupItemsCount + TooltipMsg.Versions}
        >
          <StyledAccordionSummary
            $paddingLeft={0}
            $maxHeight={getMaxHeight(density, theme)}
            $minHeight={getMinHeight(density, theme)}
          >
            <DocumentsCell
              width={chevronMinWidth}
              minWidth={chevronMinWidth}
              isVisible={true}
              type={DocumentsColumnType.Chevron}
              fitToScreen={fitToScreen}
            >
              <ChevronCellDiv>
                <ChevronDiv>
                  {isDocumentGroupExpanded ? (
                    <ExpandMoreIcon
                      fontSize="small"
                      id={
                        "document-list-item-group-chevron-expand-" +
                        props.documentListItem.documentNumber
                      }
                      className="document-list-item-group-chevron-expand"
                    />
                  ) : (
                    <KeyboardArrowRightIcon
                      fontSize="small"
                      id={
                        "document-list-item-group-chevron-expand-close-" +
                        props.documentListItem.documentNumber
                      }
                      className="document-list-item-group-chevron-close"
                    />
                  )}
                </ChevronDiv>
                <WorkflowDiv $isGray={isGray}>
                  <TooltipTop title={TooltipMsg.DocumentsGroup}>
                    <StyledDocumentIcon
                      className="group-icon"
                      src={groupIcon}
                      alt="Grouped document icon"
                    />
                  </TooltipTop>
                </WorkflowDiv>
              </ChevronCellDiv>
            </DocumentsCell>
            <DocumentsCell
              width={
                columnWidths.first(
                  (x) => x.type === DocumentsColumnType.DocumentNumber
                ).size
              }
              minWidth={documentNumberMinWidth}
              isVisible={true}
              type={DocumentsColumnType.DocumentNumber}
              fitToScreen={fitToScreen}
            >
              <EllipsisText
                useTooltip={true}
                id={
                  "document-list-item-group-name-number-" +
                  props.documentListItem.documentNumber
                }
                width={
                  columnWidths.first(
                    (x) => x.type === DocumentsColumnType.DocumentNumber
                  ).size
                }
                fillIfEmpty={true}
                $paddingRight={6}
                wordBreak={WordBreakType.BreakAll}
                content={props.documentListItem.documentNumber}
                flexGrow={1}
                enableEllipsis={density !== Density.Comfort}
              />
            </DocumentsCell>
            <DocumentsCellWithHighlight
              width={groupedTitleWidth}
              minWidth={groupedTitleWidth}
              isVisible={true}
              type={DocumentsColumnType.Title}
              fitToScreen={fitToScreen}
            >
              <EllipsisText
                useTooltip={true}
                width={groupedTitleWidth}
                isBolded={
                  props.documentListItem.hasAccess &&
                  !props.documentListItem.isFromServiceIntegration
                }
                id={
                  "document-list-item-group-name-title-" +
                  props.documentListItem.documentNumber
                }
                fillIfEmpty={true}
                emptyContentPlaceholder={props.documentListItem.fileName}
                wordBreak={WordBreakType.BreakAll}
                content={props.documentListItem.title}
                flexGrow={1}
                enableEllipsis={density !== Density.Comfort}
              />
            </DocumentsCellWithHighlight>
            <DocumentsCell
              width={optionsMinWidth}
              minWidth={optionsMinWidth}
              isVisible={true}
              type={DocumentsColumnType.Options}
              fitToScreen={fitToScreen}
            >
              <DocumentOptions
                documentItem={props.documentListItem}
                onClick={() => setShowVersionsTooltip(false)}
                isGrouped={true}
                workflow={props.documentListItem.workflow}
                url={props.documentListItem.url}
              />
            </DocumentsCell>
          </StyledAccordionSummary>
        </TooltipTop>

        <StyledAccordionDetails>
          {isError && (
            <DocumentListGroupError
              errorMessage="Document tree loading failed!"
              reloadDataHandler={reloadDocumentTree}
            />
          )}
          {isDocumentsTreeLoading && <GroupSkeleton />}
          {getLanguageNames()
            ?.sort((languagePrevious, languageNext) =>
              languagePrevious.languageNames.toLocaleString() >
              languageNext.languageNames.toLocaleString()
                ? 1
                : -1
            )
            .map((lang) => {
              return (
                <LanguagesAccordion
                  key={lang.isoCodes.toString()}
                  languageGroupId={lang.isoCodes.toString()}
                  languageGroup={lang}
                  documentNumber={props.documentListItem.documentNumber}
                  zebraStripe={props.zebraStripe}
                />
              );
            })}
        </StyledAccordionDetails>
      </StyledAccordion>
    </DocumentListGroupDiv>
  );
}
