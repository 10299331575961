import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import {
  selectDocumentDraftType,
  setEditedDocumentNumber,
} from "../../../../app/slices/documentMetadataSlice";
import { MultiText } from "../../inputs/MultiText";
import { ButtonControl } from "../../../../controls/Buttons/ButtonControl";
import { useLazyGenerateDocumentNumberQuery } from "../../../../apis/documentDraftApi";
import { useCallback, useEffect } from "react";
import { SnackbarType } from "../../../../models/snackbar";
import { openSnackbar } from "../../../../app/helpers/snackBarHelper";
import { DraftType } from "../../../../models/documentDetails/documentStatus";
import { ValidationStatus } from "../../../../models/validationRule";
import { setDocumentNumberValidationStatus } from "../../../../app/slices/documentMetadataValidationSlice";
import { Editable } from "../common/Editable";
import useDocumentValidationRules from "../../../../app/hooks/document/useDocumentValidationRules";
import { useDocumentEditedMode } from "../../../../app/hooks/document/useDocumentEditedMode";
import { ButtonControlDiv } from "../SC/ButtonControlDiv";
import { SnackbarMsg } from "../../../../controls/Snackbar/SnackbarMessages";

interface DocumentNumberProps extends Editable {
  documentNumber: string;
  isLoadingDetailsMetaData: boolean;
}

export function DocumentNumber(props: DocumentNumberProps) {
  const draftType = useAppSelector(selectDocumentDraftType);
  const dispatch = useAppDispatch();
  const { getDocumentNumberValidationRules } = useDocumentValidationRules();
  const { identitySection } = useDocumentEditedMode();
  const onDocumentNumberChangeHandler = (newValue: string) => {
    dispatch(setEditedDocumentNumber(newValue));
  };

  const [
    generateDocumentNumber,
    { data: newDocumentNumber, isError: isDocumentNumberGenerationError },
  ] = useLazyGenerateDocumentNumberQuery();

  useEffect(() => {
    if (isDocumentNumberGenerationError) {
      openSnackbar(
        dispatch,
        SnackbarMsg.DocumentNumberError,
        SnackbarType.error
      );
    }
  }, [dispatch, isDocumentNumberGenerationError]);

  useEffect(() => {
    if (newDocumentNumber) {
      dispatch(setEditedDocumentNumber(newDocumentNumber));
    }
  }, [dispatch, newDocumentNumber]);

  const generateDocumentNumberHandler = () => {
    void generateDocumentNumber(null, false);
    if (newDocumentNumber) {
      dispatch(setEditedDocumentNumber(newDocumentNumber));
    }
  };

  const onInputValidation = useCallback(
    (result: ValidationStatus) => {
      dispatch(setDocumentNumberValidationStatus(result));
    },
    [dispatch]
  );

  return (
    <MultiText
      id="document-number-input"
      labelText="Document number"
      inputValue={props.documentNumber}
      isLoading={props.isLoadingDetailsMetaData}
      isEditable={props.isEditable}
      notEditableMessage={props.notEditableMessage}
      onChangeHandler={onDocumentNumberChangeHandler}
      onInputValidation={onInputValidation}
      isEdited={identitySection.documentNumberIsEdited}
      validationRules={getDocumentNumberValidationRules()}
      inputAdornment={
        <ButtonControlDiv>
          <ButtonControl
            id={"generate-document-number-button"}
            text={"generate"}
            colors={"secondary"}
            height={24}
            isCompactView={false}
            hasAccess={
              props.isEditable &&
              draftType === DraftType.NewDocumentDraft &&
              !props.documentNumber
            }
            onClick={generateDocumentNumberHandler}
          />
        </ButtonControlDiv>
      }
    />
  );
}
