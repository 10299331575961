import { useMemo } from "react";
import { useAppSelector } from "../../hooks";
import { hasPermission } from "../../../helpers/checkRoles";
import { PermissionInCategory } from "../../../../models/permissionsInCategory";
import { selectMyPermissionsInCategory } from "../../../slices/documentMetadataSlice";

export function useUserPermissionsInMainCategory() {
  const permissionsInMainCategory = useAppSelector(
    selectMyPermissionsInCategory
  );

  const permissions = useMemo(() => {
    return {
      canApproveDocument: hasPermission(
        PermissionInCategory.CanApproveDocument,
        permissionsInMainCategory
      ),
      canChangeUserAccess: hasPermission(
        PermissionInCategory.CanChangeUserAccess,
        permissionsInMainCategory
      ),
      canEditAccessGroups: hasPermission(
        PermissionInCategory.CanEditAccessGroups,
        permissionsInMainCategory
      ),
      canEditDocument: hasPermission(
        PermissionInCategory.CanEditDocument,
        permissionsInMainCategory
      ),
      canEditDocumentIdentity: hasPermission(
        PermissionInCategory.CanEditDocumentIdentity,
        permissionsInMainCategory
      ),
      canEditServiceDocument: hasPermission(
        PermissionInCategory.CanEditServiceDocument,
        permissionsInMainCategory
      ),
      canSetPopularCategory: hasPermission(
        PermissionInCategory.CanSetPopularCategory,
        permissionsInMainCategory
      ),
      canDeleteDocument: hasPermission(
        PermissionInCategory.CanDeleteDocument,
        permissionsInMainCategory
      ),
    };
  }, [permissionsInMainCategory]);

  return permissions;
}
