import React from "react";

export interface ValidationRule {
  regExp: RegExp;
  message: React.ReactNode;
  isVisible?: boolean;
  omitText?: string;
  forceNonVisibleValidation?: boolean;
  successIsInvalid?: boolean;
  validationType: ValidationStatus;
}

export enum ValidationStatus {
  error = "error",
  warning = "warning",
  info = "info",
  success = "success",
  notVisibleError = "notVisibleError",
}
