import { CategoryPathMetadata } from "../../../../../models/documentDetails/documentMetadata";
import { BreadcrumbList } from "./SC/BreadcrumbList";
import { BreadcrumbListItem } from "./SC/BreadcrumbListItem";
import { first, indexOf, last } from "lodash";
import { BreadcrumbEllipsisButton } from "./BreadcrumbEllipsisButton";
import { BreadcrumbSeparator } from "./BreadcrumbSeparator";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import {
  expandCategoryBreadcrumb,
  selectExpandedCategoriesBreadcrumbsIds,
} from "../../../../../app/slices/documentMetadataSlice";
import {
  buildCategoryText,
  buildLifeCycleText,
} from "../../../../../app/helpers/categoriesHelper";

interface BreadcrumbProps {
  isGray: boolean;
  isEdited: boolean;
  categoryId: string;
  separator: string;
  categories: CategoryPathMetadata[];
}

export function Breadcrumb(props: BreadcrumbProps) {
  const firstItem = first(props.categories);
  const lastItem = last(props.categories);
  const dispatch = useAppDispatch();
  const expandedCategoriesBreadcrumbsIds = useAppSelector(
    selectExpandedCategoriesBreadcrumbsIds
  );

  const isExpanded =
    indexOf(expandedCategoriesBreadcrumbsIds, props.categoryId) !== -1;

  const handleExpandClick = () => {
    dispatch(expandCategoryBreadcrumb(props.categoryId));
  };

  if (props.categories.length > 3 && !isExpanded) {
    return (
      <BreadcrumbList>
        <BreadcrumbListItem
          data-cid={firstItem?.categoryId}
          data-lifecycle={buildLifeCycleText(firstItem)}
          $isBolded={false}
          $isGray={props.isGray}
          $isEdited={props.isEdited}
        >
          {buildCategoryText(firstItem)}
        </BreadcrumbListItem>

        <BreadcrumbSeparator $isVisible={true} separator={props.separator} />

        <BreadcrumbListItem
          $isBolded={false}
          $isGray={props.isGray}
          $isEdited={props.isEdited}
        >
          <BreadcrumbEllipsisButton onClick={handleExpandClick} />
        </BreadcrumbListItem>

        <BreadcrumbSeparator $isVisible={true} separator={props.separator} />

        <BreadcrumbListItem
          data-cid={lastItem?.categoryId}
          data-lifecycle={buildLifeCycleText(lastItem)}
          $isBolded={true}
          $isGray={props.isGray}
          $isEdited={props.isEdited}
        >
          {buildCategoryText(lastItem)}
        </BreadcrumbListItem>
      </BreadcrumbList>
    );
  } else {
    return (
      <BreadcrumbList>
        {props.categories.map((category, index) => {
          const lastCategoryIndex = props.categories.length - 1;
          return [
            <BreadcrumbListItem
              key={index}
              data-cid={category.categoryId}
              data-lifecycle={buildLifeCycleText(category)}
              $isBolded={lastCategoryIndex == index}
              $isGray={props.isGray}
              $isEdited={props.isEdited}
            >
              {buildCategoryText(category)}
            </BreadcrumbListItem>,
            <BreadcrumbSeparator
              $isVisible={lastCategoryIndex != index}
              key={index + "separator"}
              separator={props.separator}
            />,
          ];
        })}
      </BreadcrumbList>
    );
  }
}
