import {
  PublisherRole,
  PublisherRoleType,
} from "../../../../../../models/publisherRoles";
import { RoleInCategory } from "../../../../../../models/rolesInCategory";

export function findRole(
  role: RoleInCategory,
  publisherRoles: PublisherRole[],
  selectedCategoryId: string
): PublisherRole {
  for (const publisherRole of publisherRoles) {
    if (publisherRole.role === role) {
      return {
        ...publisherRole,
        roleType:
          publisherRole.assignedInCategoryId === selectedCategoryId
            ? PublisherRoleType.Assigned
            : PublisherRoleType.Inherited,
      };
    }
  }

  return {
    assignedInCategoryId: "",
    assignedInCategoryName: "",
    assignedInCategoryPath: "",
    roleType: PublisherRoleType.NotAssigned,
  };
}
