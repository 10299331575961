import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { Identity } from "../../models/documentDetails/documentMetadata";
import { DocumentDetailView } from "../../models/documentDetailView";
import {
  documentDetailsParamsLocalStorageKey,
  readParamsFromLocalStorage,
} from "../hooks/localStorage";
import { RootState } from "../store";
import { DetailsColumn, SectionName } from "./models/documentDetailsModels";

export interface PersistedDetailsState {
  columns: SectionPanes;
  collapsedSections: SectionName[];
  securityTabAlternativeView: boolean;
  columnsFlex: number[];
}

export interface DetailsState {
  dragStartSection?: SectionName;
  dragEnterSection?: SectionName;
  tabIndex: number;
  maximizedSection?: { name: SectionName; column: DetailsColumn };
  columns: SectionPanes;
  collapsedSections: SectionName[];
  isResize: boolean;
  securityTabAlternativeView: boolean;
  columnsFlex: number[];
  isUserUnauthorizedToAccessMetadata: boolean;
  shouldRefreshDocumentMetadata: boolean;
  shouldRefreshDocumentCma: boolean;
  isLoadingDetailsMetaData: boolean;
  isBreakingDocumentLink: boolean;
  isUpdatingDocument: boolean;
  view: DocumentDetailView | undefined;
  usePrompt: boolean;
  logoutPrompt: boolean;
  newDocumentVersionIdentity: Identity | undefined;
  hasApiErrors: boolean;
  isChangingWorkflow: boolean;
}

type SectionPanes = {
  [key in DetailsColumn]: SectionName[];
};

const defaultState: DetailsState = {
  tabIndex: 0,
  columns: {
    Left: [SectionName.Identity, SectionName.Titles, SectionName.Description],
    Middle: [
      SectionName.Attachment,
      SectionName.Security,
      SectionName.CountryMarketAvailability,
    ],
    Right: [SectionName.Categories, SectionName.Products, SectionName.Tags],
  },
  collapsedSections: [],
  isResize: false,
  securityTabAlternativeView: false,
  columnsFlex: [1, 1, 1],
  isUserUnauthorizedToAccessMetadata: false,
  shouldRefreshDocumentMetadata: false,
  shouldRefreshDocumentCma: false,
  isLoadingDetailsMetaData: true,
  isUpdatingDocument: false,
  usePrompt: true,
  logoutPrompt: false,
  view: undefined,
  isBreakingDocumentLink: false,
  newDocumentVersionIdentity: undefined,
  hasApiErrors: false,
  isChangingWorkflow: false,
};

const initialState: DetailsState = {
  ...defaultState,
  ...(readParamsFromLocalStorage<PersistedDetailsState>(
    documentDetailsParamsLocalStorageKey
  ) ?? {}),
};

export const detailsSlice = createSlice({
  name: "documentDetails",
  initialState,
  reducers: {
    setDocumentDetailView(
      state: DetailsState,
      action: PayloadAction<DocumentDetailView | undefined>
    ) {
      state.view = action.payload;
    },
    setTabIndex(state: DetailsState, action: PayloadAction<number>) {
      state.tabIndex = action.payload;
    },
    setMaximizedSection(
      state: DetailsState,
      action: PayloadAction<SectionName | undefined>
    ) {
      if (action.payload === undefined) {
        state.maximizedSection = undefined;
        return;
      }
      state.maximizedSection = {
        name: action.payload,
        column: getColumnBySection(state, action.payload),
      };
    },
    setDraggingSection(
      state: DetailsState,
      action: PayloadAction<SectionName | undefined>
    ) {
      state.dragStartSection = action.payload;
    },
    setIsResize(state: DetailsState, action: PayloadAction<boolean>) {
      state.isResize = action.payload;
    },
    swapSecurityTabView(state: DetailsState) {
      state.securityTabAlternativeView = !state.securityTabAlternativeView;
    },
    setDragEnterSection(
      state: DetailsState,
      action: PayloadAction<SectionName | undefined>
    ) {
      state.dragEnterSection = action.payload;
    },
    swapSections(
      state: DetailsState,
      action: PayloadAction<{
        source: SectionName;
        target: SectionName;
      }>
    ) {
      let targetColumn: DetailsColumn | undefined;
      let targetIndex: number | undefined;
      _.forEach(state.columns, (c, key) => {
        const idx = c.indexOf(action.payload.target);
        if (idx !== -1) {
          targetColumn = key as DetailsColumn;
          targetIndex = idx;
        }
      });
      if (targetColumn === undefined || targetIndex === undefined) {
        throw new Error("Unable to move columns");
      }

      _.forEach(state.columns, (c) => {
        _.remove(c, (x) => x === action.payload.source);
      });
      state.columns[targetColumn].splice(targetIndex, 0, action.payload.source);
    },
    moveSectionToColumn(
      state: DetailsState,
      action: PayloadAction<{
        source: SectionName;
        target: DetailsColumn;
      }>
    ) {
      _.forEach(state.columns, (c) => {
        _.remove(c, (x) => x === action.payload.source);
      });
      state.columns[action.payload.target].push(action.payload.source);
    },
    collapseSection(state: DetailsState, action: PayloadAction<SectionName>) {
      if (_.includes(state.collapsedSections, action.payload)) {
        return;
      }

      state.collapsedSections.push(action.payload);
    },
    setDetailsColumnFlex(
      state: DetailsState,
      action: PayloadAction<{
        flex: number;
        index: number;
      }>
    ) {
      if (
        action.payload.index >= 0 &&
        action.payload.index < state.columnsFlex.length
      ) {
        state.columnsFlex[action.payload.index] = action.payload.flex;
      }
    },
    expandSection(state: DetailsState, action: PayloadAction<SectionName>) {
      _.remove(state.collapsedSections, (s) => s === action.payload);
    },
    resetDetailsView(state: DetailsState) {
      state.collapsedSections = defaultState.collapsedSections;
      state.columns = defaultState.columns;
      state.tabIndex = defaultState.tabIndex;
      state.columnsFlex = defaultState.columnsFlex;
      state.securityTabAlternativeView =
        defaultState.securityTabAlternativeView;
    },
    setIsUserUnauthorizedToAccessMetadata: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isUserUnauthorizedToAccessMetadata = action.payload;
    },
    setShouldRefreshDocumentMetadata: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.shouldRefreshDocumentMetadata = action.payload;
    },
    setShouldRefreshDocumentCma: (state, action: PayloadAction<boolean>) => {
      state.shouldRefreshDocumentCma = action.payload;
    },
    setIsLoadingDetailsMetaData: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDetailsMetaData = action.payload;
    },
    setIsUpdatingDocument: (state, action: PayloadAction<boolean>) => {
      state.isUpdatingDocument = action.payload;
    },
    setUsePrompt: (state, action: PayloadAction<boolean>) => {
      state.usePrompt = action.payload;
    },
    setLogoutPrompt: (state, action: PayloadAction<boolean>) => {
      state.logoutPrompt = action.payload;
    },
    setIsBreakingDocumentLink: (state, action: PayloadAction<boolean>) => {
      state.isBreakingDocumentLink = action.payload;
    },
    setNewDocumentVersionIdentity: (
      state,
      action: PayloadAction<Identity | undefined>
    ) => {
      state.newDocumentVersionIdentity = action.payload;
    },
    setHasApiErrors: (state, action: PayloadAction<boolean>) => {
      state.hasApiErrors = action.payload;
    },
    setIsChangingWorkflow: (state, action: PayloadAction<boolean>) => {
      state.isChangingWorkflow = action.payload;
    },
  },
});

export const {
  setTabIndex,
  setMaximizedSection,
  setDraggingSection,
  setDragEnterSection,
  setIsResize,
  swapSecurityTabView,
  swapSections,
  moveSectionToColumn,
  collapseSection,
  expandSection,
  resetDetailsView,
  setDetailsColumnFlex,
  setIsUserUnauthorizedToAccessMetadata,
  setShouldRefreshDocumentMetadata,
  setShouldRefreshDocumentCma,
  setIsLoadingDetailsMetaData,
  setIsUpdatingDocument,
  setDocumentDetailView,
  setUsePrompt,
  setIsBreakingDocumentLink,
  setNewDocumentVersionIdentity,
  setHasApiErrors,
  setIsChangingWorkflow,
  setLogoutPrompt,
} = detailsSlice.actions;

export const selectDocumentDetailView = (state: RootState) =>
  state.documentDetails.view;

export const selectTabIndex = (state: RootState) =>
  state.documentDetails.tabIndex;

export const selectSecurityTabAlternativeView = (state: RootState) =>
  state.documentDetails.securityTabAlternativeView;

export const selectIsResize = (state: RootState) =>
  state.documentDetails.isResize;

export const selectUsePromptForEditedDocument = (state: RootState) =>
  state.documentDetails.usePrompt;

export const selectLogoutPrompt = (state: RootState) =>
  state.documentDetails.logoutPrompt;

export const selectNewDocumentVersionIdentity = (state: RootState) =>
  state.documentDetails.newDocumentVersionIdentity;

const detailsSelector = (state: RootState) => state.documentDetails;

export const selectIsLoadingDetailsMetaData = createSelector(
  [detailsSelector],
  (state) => state.isLoadingDetailsMetaData
);

export const selectIsUpdatingDocument = createSelector(
  [detailsSelector],
  (state) => state.isUpdatingDocument
);

export const selectIsUserUnauthorizedToAccessMetadata = createSelector(
  [detailsSelector],
  (state) => state.isUserUnauthorizedToAccessMetadata
);

export const selectShouldRefreshDocumentMetadata = createSelector(
  [detailsSelector],
  (state) => state.shouldRefreshDocumentMetadata
);

export const selectShouldRefreshDocumentCma = createSelector(
  [detailsSelector],
  (state) => state.shouldRefreshDocumentCma
);

export const selectMaximizedSection = createSelector(
  [detailsSelector],
  (state) => state.maximizedSection
);

export const selectDragStartSection = createSelector(
  [detailsSelector],
  (state) => state.dragStartSection
);

export const selectDragEnterSection = createSelector(
  [detailsSelector],
  (state) => state.dragEnterSection
);

export const selectPaneSection = createSelector(
  [detailsSelector, (state, sectionType: DetailsColumn) => sectionType],
  (state, section: DetailsColumn) => getSectionsByPane(state, section)
);

export const selectCollapsedSections = createSelector(
  [detailsSelector],
  (state) => state.collapsedSections
);

export const selectDetailsColumnsFlex = createSelector(
  [detailsSelector],
  (state) => state.columnsFlex
);

export const selectIsBreakingDocumentLink = createSelector(
  [detailsSelector],
  (state) => state.isBreakingDocumentLink
);

export const selectHasApiErrors = createSelector(
  [detailsSelector],
  (state) => state.hasApiErrors
);

export const selectIsChangingWorkflow = createSelector(
  [detailsSelector],
  (state) => state.isChangingWorkflow
);

export default detailsSlice.reducer;

function getSectionsByPane(state: DetailsState, pane: DetailsColumn) {
  return state.columns[pane];
}

function getColumnBySection(
  state: DetailsState,
  section: SectionName
): DetailsColumn {
  let column: DetailsColumn | undefined;
  _.forEach(state.columns, (c, key) => {
    if (c.includes(section)) {
      column = key as DetailsColumn;
    }
  });
  if (column !== undefined) {
    return column;
  }
  throw new Error(`Section ${section} not found`);
}
