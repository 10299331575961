import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStep1() {
  return (
    <StepContentDiv>
      <Paragraph>
        In this section, the category to be managed or viewed needs to be selected first. Then, the category settings will be displayed in the main view.
      </Paragraph>
    </StepContentDiv>
  );
}
