import styled, { DefaultTheme } from "styled-components";
import { Button } from "@mui/material";

export interface TextButtonProps {
  $isVisible: boolean;
  $isCompactView: boolean;
  $isTextVisible?: boolean;
  colors: "primary" | "secondary";
  height: number;
}

function getBackgroundColor(
  colors: "primary" | "secondary",
  theme: DefaultTheme
) {
  if (colors === "primary") {
    return theme.palettes.primary.main;
  }

  return theme.palettes.grayscale.lightest;
}

function getColor(colors: "primary" | "secondary", theme: DefaultTheme) {
  if (colors === "primary") {
    return theme.palettes.grayscale.lightest;
  }

  return theme.palettes.grayscale.darkest;
}

function getHoverColor(colors: "primary" | "secondary", theme: DefaultTheme) {
  if (colors === "primary") {
    return theme.palettes.grayscale.lightest;
  }

  return theme.palettes.primary.main;
}

export const StyledTextButton = styled(Button)<TextButtonProps>`
  &.MuiButtonBase-root {
    height: ${(props) => props.height}px;
    color: ${(props) => getColor(props.colors, props.theme)};
    background-color: ${(props) =>
      getBackgroundColor(props.colors, props.theme)};
    display: ${(props) => (props.$isVisible ? "flex" : "none")};
    max-width: ${(props) =>
      props.$isTextVisible === false ? "45px" : "unset"};

    &div {
      gap: ${(props) => (props.$isTextVisible === false ? "0px" : "4px")};
    }
  }

  &.MuiButtonBase-root.MuiButton-root {
    min-width: ${(props) => (props.$isCompactView ? "32px" : "unset")};
  }

  &.MuiButtonBase-root:hover {
    color: ${(props) => getHoverColor(props.colors, props.theme)};
    background-color: ${(props) =>
      getBackgroundColor(props.colors, props.theme)};
    filter: brightness(90%);
    cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  }

  .text {
    display: ${(props) => (props.$isTextVisible === false ? "none" : "flex")};
  }
`;
