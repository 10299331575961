import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function DocumentDetailsStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        Document details can be accessed by either clicking on the document
        title or selecting the &quot;Open Details&quot; action.
      </Paragraph>
    </StepContentDiv>
  );
}
