import { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { ButtonDiv } from "./SC/ButtonDiv";
import { TextButtonControl } from "../../../../../controls/Buttons/TextButtonControl";

interface CloseButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  isCompactView?: boolean;
  isDocumentEdited?: boolean;
}

export function CloseButton(props: CloseButtonProps) {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <ButtonDiv>
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.primaryButtonHeight}
        id={"document-settings-close-button"}
        text={"Close"}
        icon={<CloseIcon />}
        disabled={props.disabled}
        onClick={() => {
          navigate("/");
          if (props.onClick) props.onClick();
        }}
        isCompactView={props.isCompactView == true}
        isVertical={false}
        isVisible={true}
      />
    </ButtonDiv>
  );
}
