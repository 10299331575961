import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";
import { openSnackbar } from "../../../../../app/helpers/snackBarHelper";
import { useAppDispatch } from "../../../../../app/hooks/hooks";
import { SnackbarType } from "../../../../../models/snackbar";
import { StyledCopyButton } from "../../../../popup/SC/StyledCopyButton";
import { StyledPopupTooltip } from "../../../../popup/SC/StyledPopupTooltip";

interface CopyButtonProps {
  id: string;
  onHideParentTooltips?: () => void;
  value?: string;
  errorMessage: string;
  emptyMessage: string;
  disabled?: boolean;
  fontSize?: number;
  isVisible?: boolean;
  isLoading?: boolean;
}

export const CopyButton = (props: CopyButtonProps) => {
  const dispatch = useAppDispatch();
  const [clickedToCopy, setClickedToCopy] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const hideParentTooltips = () => {
    if (props.onHideParentTooltips) {
      props.onHideParentTooltips();
    }
  };

  const onMouseEnter = () => {
    if (!props.disabled) {
      hideParentTooltips();
      setIsHover(true);
    }
  };

  const onMouseLeave = () => {
    if (!props.disabled) {
      hideParentTooltips();
      setIsHover(false);
      setClickedToCopy(false);
    }
  };

  const copyToClipboard = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (props.value) {
      navigator.clipboard
        .writeText(props.value)
        .catch(() =>
          openSnackbar(dispatch, props.errorMessage, SnackbarType.error)
        )
        .finally(() => setClickedToCopy(true));
    } else {
      openSnackbar(dispatch, props.emptyMessage, SnackbarType.error);
    }
  };

  return (
    <StyledPopupTooltip
      open={!clickedToCopy && isHover}
      title={"Click to copy"}
    >
      <StyledCopyButton
        id={props.id}
        onClick={copyToClipboard}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        disabled={props.disabled}
        $fontSize={props.fontSize}
        $isVisible={props.isVisible}
        $isLoading={props.isLoading}
      >
        {clickedToCopy && isHover ? (
          <CheckIcon id={`${props.id}-check-icon`} fontSize="small" />
        ) : (
          <ContentCopyIcon id={`${props.id}-copy-icon`} fontSize="small" />
        )}
      </StyledCopyButton>
    </StyledPopupTooltip>
  );
};
