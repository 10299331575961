import styled from "styled-components";

interface InfoHeaderDivProps {
  $centerTextAlign?: boolean;
}

export const InfoHeaderDiv = styled.div<InfoHeaderDivProps>`
  font-size: ${(props) => props.theme.typography.fontSizes.splashScreenTitle}px;
  font-weight: bold;
  text-align: ${(props) => (props.$centerTextAlign ? "center" : "unset")};
`;
