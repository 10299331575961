import DownloadIcon from "@mui/icons-material/Download";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { Skeleton, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "styled-components";
import {
  useLazyGetDocumentAttachmentUrlQuery,
  useBreakDocumentLinkMutation,
} from "../../../../apis/documentAttachmentApi";
import {
  convertBytesToMB,
  extractUrl,
} from "../../../../app/helpers/attachmentHelper";
import { openSnackbar } from "../../../../app/helpers/snackBarHelper";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { useUserPermissionsInDocument } from "../../../../app/hooks/permissions/useUserPermissionsInDocument";
import {
  selectIsLoadingDetailsMetaData,
  setIsBreakingDocumentLink,
} from "../../../../app/slices/documentDetailsSlice";
import { selectDocumentStatus } from "../../../../app/slices/documentMetadataSlice";
import { selectFileHash } from "../../../../app/slices/fileUploadSlice";
import { TextButtonControl } from "../../../../controls/Buttons/TextButtonControl";
import { DialogControl } from "../../../../controls/Dialog/DialogControl";
import { TooltipTop } from "../../../../controls/Tooltips/ToolipTop";
import { SnackbarType } from "../../../../models/snackbar";
import { LabelSpan } from "../../SC/LabelSpan";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import { CopyButton } from "../common/SC/CopyButton";
import { AttachmentInfoButtonsDiv } from "./SC/AttachmentInfoButtonsDiv";
import { AttachmentInfoDiv } from "./SC/AttachmentInfoDiv";
import { AttachmentInfoPartDiv } from "./SC/AttachmentInfoPartDiv";
import { AttachmentInfoRowDiv } from "./SC/AttachmentInfoRowDiv";
import { AttachmentInfoSpan } from "./SC/AttachmentInfoSpan";
import { FileHashDiv } from "./SC/FileHashDiv";
import { selectDetailsHasRequiredUncompletedSections } from "../../../../app/slices/selfHelpSlice";
import { StyledDialogContentText } from "../../selfHelpPopup/SC/StyledDialogContentText";
import { SnackbarMsg } from "../../../../controls/Snackbar/SnackbarMessages";
import { ExtensionDiv } from "./SC/ExtensionDiv";

export interface AttachmentInfoProps {
  extension: string | undefined;
  sizeInBytes: number | undefined;
  isFileUploading: boolean;
  hasUserPermissionToDownload: boolean;
  paddingTop?: number;
}

export function AttachmentInfo(props: AttachmentInfoProps) {
  const theme = useTheme();
  const [isDownloading, setIsDownloading] = useState(false);
  const isLoadingDetailsMetaData = useAppSelector(
    selectIsLoadingDetailsMetaData
  );
  const documentStatus = useAppSelector(selectDocumentStatus);
  const fileHash = useAppSelector(selectFileHash);
  const [getAttachmentUrl] = useLazyGetDocumentAttachmentUrlQuery();
  const [breakDocumentLink, { isLoading: isBreakingDocumentLink }] =
    useBreakDocumentLinkMutation();
  const [isBreakLinkPopupOpen, setIsBreakLinkPopupOpen] = useState(false);
  const dispatch = useAppDispatch();
  const isLoading =
    props.isFileUploading ||
    isDownloading ||
    isLoadingDetailsMetaData ||
    isBreakingDocumentLink;
  const hideDownloadButtonText = useMediaQuery("(max-width: 1300px)");
  const { hasPermissionToBreakDocumentLink } =
    useUserPermissionsInDocument(false);
  const fileHashRef = useRef<HTMLDivElement>(null);
  const attachmentInfoRef = useRef<HTMLDivElement>(null);
  const [showCopyButton, setShowCopyButton] = useState(false);
  const hasUncompletedSections = useAppSelector(
    selectDetailsHasRequiredUncompletedSections
  );

  useEffect(() => {
    dispatch(setIsBreakingDocumentLink(isBreakingDocumentLink));
  }, [isBreakingDocumentLink, dispatch]);

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (documentStatus.documentRevisionId) {
      setIsDownloading(true);
      getAttachmentUrl(documentStatus.documentRevisionId.toString())
        .unwrap()
        .then((value) => {
          const downloadUrl = extractUrl(value);
          window.open(downloadUrl, "_blank");
          setIsDownloading(false);
        })
        .catch(() => {
          openSnackbar(
            dispatch,
            SnackbarMsg.AttachmentError,
            SnackbarType.error
          );
          setIsDownloading(false);
        });
    }
  };

  return (
    <AttachmentInfoDiv id="attachment-info-div" $paddingTop={props.paddingTop}>
      <AttachmentInfoRowDiv $paddingTop={6} $minHeight={24}>
        <AttachmentInfoPartDiv
          id="attachment-info-file-hash-div"
          onMouseEnter={() => setShowCopyButton(true)}
          onMouseLeave={() => setShowCopyButton(false)}
          $useMaxWidth={true}
        >
          <LabelSpan id="attachment-info-file-hash-label">
            File SHA256 hash:
          </LabelSpan>
          {!isLoadingDetailsMetaData && fileHash ? (
            <TooltipTop
              title={
                fileHashRef.current &&
                fileHashRef.current.offsetWidth <
                  fileHashRef.current.scrollWidth
                  ? fileHash
                  : ""
              }
            >
              <FileHashDiv
                id="attachment-info-file-hash-value"
                ref={fileHashRef}
              >
                {fileHash}
              </FileHashDiv>
            </TooltipTop>
          ) : isLoadingDetailsMetaData ? (
            <AttachmentInfoSpan>
              <Skeleton height={theme.skeletons.small} width={"auto"} />
            </AttachmentInfoSpan>
          ) : (
            <></>
          )}
          <CopyButton
            id={"copy-file-hash-button"}
            errorMessage={SnackbarMsg.CopyFileHashError}
            emptyMessage={SnackbarMsg.FileHashEmpty}
            value={fileHash}
            fontSize={theme.typography.fontSizes.smallIcon}
            isVisible={
              showCopyButton && !isLoadingDetailsMetaData && !!fileHash
            }
            isLoading={isLoadingDetailsMetaData}
          />
        </AttachmentInfoPartDiv>
      </AttachmentInfoRowDiv>
      <AttachmentInfoRowDiv $paddingTop={6}>
        <AttachmentInfoPartDiv id="attachment-info-extension-div">
          <LabelSpan id="attachment-info-extension-label">Extension:</LabelSpan>
          {!isLoadingDetailsMetaData ? (
            <AttachmentInfoSpan id="attachment-info-extension-value">
              <TooltipTop
                title={
                  attachmentInfoRef.current &&
                  attachmentInfoRef.current.offsetWidth <
                    attachmentInfoRef.current.scrollWidth
                    ? props.extension
                    : ""
                }
              >
                <ExtensionDiv
                  $maxWidth={65}
                  id="attachment-info-file-hash-value"
                  ref={attachmentInfoRef}
                >
                  {props.extension}
                </ExtensionDiv>
              </TooltipTop>
            </AttachmentInfoSpan>
          ) : (
            <AttachmentInfoSpan>
              <Skeleton height={theme.skeletons.small} width={32} />
            </AttachmentInfoSpan>
          )}
        </AttachmentInfoPartDiv>
        <AttachmentInfoPartDiv id="attachment-info-file-size-div">
          <LabelSpan id="attachment-info-file-size-label">File size:</LabelSpan>
          {!isLoadingDetailsMetaData ? (
            <TooltipTop title={props.sizeInBytes + " bytes"}>
              <span id="attachment-info-file-size-value">
                {convertBytesToMB(props.sizeInBytes)} MB
              </span>
            </TooltipTop>
          ) : (
            <AttachmentInfoSpan>
              <Skeleton height={theme.skeletons.small} width={48} />
            </AttachmentInfoSpan>
          )}
        </AttachmentInfoPartDiv>
        <AttachmentInfoButtonsDiv>
          <TextButtonControl
            disabled={
              isLoading ||
              !props.hasUserPermissionToDownload ||
              documentStatus.documentRevisionId === undefined
            }
            disabledTooltipText={
              "You do not have access to this document or new document is uploading"
            }
            id="attachment-download-button"
            icon={<DownloadIcon fontSize="inherit" />}
            onClick={onClick}
            text={"Download"}
            variant={"contained"}
            colors={"secondary"}
            isVertical={false}
            isVisible={true}
            isCompactView={false}
            height={theme.shapes.primaryButtonHeight}
            isTextVisible={!hideDownloadButtonText}
          />
          <TextButtonControl
            disabled={
              hasUncompletedSections ||
              isLoading ||
              !hasPermissionToBreakDocumentLink.isEditable ||
              documentStatus.documentRevisionId === undefined
            }
            disabledTooltipText={
              hasUncompletedSections
                ? TooltipMsg.UncompletedSelfHelpSections
                : !hasPermissionToBreakDocumentLink.isEditable
                  ? hasPermissionToBreakDocumentLink.lockMessage
                  : TooltipMsg.BreakDocumentLink
            }
            tooltipText={TooltipMsg.TemporaryLinkDisclaimer}
            id="break-temporary-document-link-button"
            onClick={() => setIsBreakLinkPopupOpen(true)}
            text={""}
            variant={"contained"}
            colors={"secondary"}
            isVertical={false}
            isVisible={true}
            isCompactView={false}
            height={theme.shapes.primaryButtonHeight}
            icon={<LinkOffIcon fontSize="small" />}
            isTextVisible={false}
          />
          <DialogControl
            id={"break-temporary-document-link-popup"}
            isOpen={isBreakLinkPopupOpen}
            onCloseClick={() => setIsBreakLinkPopupOpen(false)}
            useActions={true}
            title={"Break temporary document link"}
            content={
              <StyledDialogContentText id="break-temporary-document-link-popup-description">
                {
                  "This action breaks temporary document link so it disappears from search engines faster (Google, Bing etc.). Do you want to proceed?"
                }
              </StyledDialogContentText>
            }
            maxWidth={"sm"}
            fullWidth={true}
            onConfirmClick={() => {
              if (documentStatus.documentRevisionId) {
                void breakDocumentLink(documentStatus.documentRevisionId);
                setIsBreakLinkPopupOpen(false);
              }
            }}
          />
        </AttachmentInfoButtonsDiv>
      </AttachmentInfoRowDiv>
    </AttachmentInfoDiv>
  );
}
