import _ from "lodash";
import { DragEvent, useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import {
  DetailsColumn,
  SectionName,
} from "../../../app/slices/models/documentDetailsModels";
import { DetailsDiv } from "./SC/DetailsDiv";
import { SectionAccordion } from "./SC/SectionAccordion";
import { SectionContent } from "./SectionContent";
import { SectionHeader } from "./SectionHeader/SectionHeader";
import {
  selectMaximizedSection,
  selectDragStartSection,
  selectCollapsedSections,
  setDragEnterSection,
  setDraggingSection,
  expandSection,
  collapseSection,
} from "../../../app/slices/documentDetailsSlice";
import { selectIsWindowTooSmall } from "../../../app/slices/commonSlice";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";

interface InnerSectionProps {
  children: React.ReactNode;
  name: string;
  section: SectionName;
  index: number;
  column: DetailsColumn;
  isEdited: boolean;
  isValidationError?: boolean;
  isWarning?: boolean;
  isEditable: boolean;
  customIcon?: "link" | "serviceIntegration";
  notEditableReason?: TooltipMsg;
  onDiscardChanges: () => void;
}

export interface DetailsSectionProps {
  index: number;
  column: DetailsColumn;
  selfHelpType?: CurrentSelfHelp;
  isLoadingDetailsMetaData?: boolean;
  disableInteraction?: boolean;
}

export function DetailsSection(
  props: InnerSectionProps & DetailsSectionProps
): JSX.Element {
  const isTooSmallWindow = useAppSelector(selectIsWindowTooSmall);
  const maximizedSection = useAppSelector(selectMaximizedSection);
  const dragStartSection = useAppSelector(selectDragStartSection);
  const collapsedSections = useAppSelector(selectCollapsedSections);
  const [areIconsVisible, setAreIconsVisible] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(
    _.includes(collapsedSections, props.section)
  );

  const mouseEnter = () => {
    setAreIconsVisible(true);
  };

  const mouseLeave = () => {
    setAreIconsVisible(false);
  };

  const detailsDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseEnter = () => setAreIconsVisible(true);
    const handleMouseLeave = () => setAreIconsVisible(false);

    const node = detailsDivRef.current;
    if (node) {
      node.addEventListener("mouseenter", handleMouseEnter);
      node.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (node) {
        node.removeEventListener("mouseenter", handleMouseEnter);
        node.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  const dispatch = useAppDispatch();

  const dragEnter = useCallback(
    (e: DragEvent<HTMLElement>) => {
      const targetSection = e.currentTarget.getAttribute(
        "id"
      ) as SectionName | null;
      dispatch(
        setDragEnterSection(targetSection === null ? undefined : targetSection)
      );
    },
    [dispatch]
  );

  const dragStart = (e: DragEvent) => {
    e.dataTransfer.effectAllowed = "move";
    e.currentTarget.addEventListener("dragend", dragEnd);
  };

  const dragEnd = (e: Event) => {
    e.currentTarget?.removeEventListener("dragend", dragEnd);
    dispatch(setDraggingSection());
    dispatch(setDragEnterSection());
  };

  const expandedChanged = useCallback(
    (e: React.SyntheticEvent, expanded: boolean) => {
      if (expanded) {
        dispatch(expandSection(props.section));
      } else {
        dispatch(collapseSection(props.section));
      }
    },
    [dispatch, props.section]
  );

  if (maximizedSection && maximizedSection.name !== props.section) {
    return <></>;
  }

  const isExpanded = !_.includes(collapsedSections, props.section);
  return (
    <DetailsDiv
      id={props.section}
      draggable={
        dragStartSection !== undefined &&
        !isTooSmallWindow &&
        !props.disableInteraction
      }
      onDragStart={dragStart}
      onDragEnter={dragEnter}
      onMouseEnter={mouseEnter}
      onMouseOver={mouseEnter}
      onMouseLeave={mouseLeave}
      data-is-loaded={props.isLoadingDetailsMetaData ? "false" : "true"}
    >
      <SectionAccordion
        disableGutters={true}
        expanded={isExpanded}
        onChange={expandedChanged}
        TransitionProps={{
          onEntered: () => setIsCollapsed(false),
          onExited: () => setIsCollapsed(true),
        }}
      >
        <SectionHeader
          disableInteraction={props.disableInteraction}
          section={props.section}
          name={props.name}
          iconsVisible={areIconsVisible}
          isCollapsed={isCollapsed}
          selfHelpType={props.selfHelpType}
          isExpanded={isExpanded}
          isEdited={props.isEdited}
          isValidationError={props.isValidationError}
          isWarning={props.isWarning}
          isEditable={props.isEditable}
          notEditableReason={props.notEditableReason}
          onDiscardChanges={props.onDiscardChanges}
          customIcon={props.customIcon}
        />
        <SectionContent>{props.children}</SectionContent>
      </SectionAccordion>
    </DetailsDiv>
  );
}
