import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function ResetViewStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                This action rearranges tiles and restores them to their default locations. Tiles can be reorganized by using 6-dots Move icon (drag and drop)
            </Paragraph>
        </StepContentDiv>
    );
}
