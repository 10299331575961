import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { StyledLink } from "../../../SC/StyledLink";

export function DocumentNumberSecondStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        Document numbers should be obtained from the <StyledLink href="https://idg.ch.abb.com" target="_blank">ID Generator</StyledLink>, where each business has its designated number series.
      </Paragraph>
      <Paragraph>
        In cases where no number series is defined, the Generate button can be utilized to generate a valid number.
      </Paragraph>
      <Paragraph>
        It is crucial for the document number to consistently match the number specified within the attachment.
      </Paragraph>
    </StepContentDiv>
  );
}
