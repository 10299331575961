import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface AuthenticationState {
  qaAccessToken?: string;
  qaGraphApiAccessToken?: string;
  accountId?: string;
  userRoles?: string[];
  qaAuthenticateAs?: string;
  qaRoles?: string;
  userEmail: string;
  userName: string;
  userPhoto?: string;
}

const initialState = {
  ...getInitialAuthenticationState(),
} as AuthenticationState;

function getInitialAuthenticationState() {
  const qaToken = localStorage.getItem("qa_token");
  const qaGraphApiAccessToken = localStorage.getItem(
    "qa_token_graph_api_token"
  );
  const qaAuth = localStorage.getItem("qa_authenticate_as");
  const qaRoles = localStorage.getItem("qa_roles");

  if (qaToken && qaAuth) {
    return {
      qaAccessToken: qaToken,
      accountId: "qa_account",
      qaGraphApiAccessToken: qaGraphApiAccessToken,
      qaAuthenticateAs: qaAuth,
      qaRoles: qaRoles,
      graphApiToken: undefined,
      userRoles: undefined,
      userEmail: "",
      userName: "",
      userPhoto: undefined,
    } as AuthenticationState;
  }

  return {
    qaAccessToken: undefined,
    accountId: undefined,
    qaAuthenticateAs: undefined,
    qaRoles: undefined,
    graphApiToken: undefined,
    userRoles: undefined,
    userEmail: "",
    userName: "",
    userPhoto: undefined,
  } as AuthenticationState;
}

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setUserRoles(
      state: AuthenticationState,
      action: PayloadAction<string[] | undefined>
    ) {
      state.userRoles = action.payload;
    },
    setUserEmail(state: AuthenticationState, action: PayloadAction<string>) {
      state.userEmail = action.payload;
    },
    setUserName(state: AuthenticationState, action: PayloadAction<string>) {
      state.userName = action.payload;
    },
    setUserPhoto(
      state: AuthenticationState,
      action: PayloadAction<string | undefined>
    ) {
      state.userPhoto = action.payload;
    },
  },
});

export const { setUserRoles, setUserEmail, setUserName, setUserPhoto } =
  authenticationSlice.actions;

export const selectQaAccessToken = (state: RootState) =>
  state.authentication.qaAccessToken;
export const selectQaGraphApiAccessToken = (state: RootState) =>
  state.authentication.qaGraphApiAccessToken;
export const selectIsQaAuth = (state: RootState) => {
  return state.authentication.accountId == "qa_account" ? true : false;
};
export const selectIsQaAuthUser = (state: RootState) => {
  return state.authentication.qaAuthenticateAs;
};
export const selectUserRoles = (state: RootState) =>
  state.authentication.userRoles;
export const selectUserEmail = (state: RootState) =>
  state.authentication.userEmail;
export const selectUserName = (state: RootState) =>
  state.authentication.userName;
export const selectUserPhoto = (state: RootState) =>
  state.authentication.userPhoto;
export default authenticationSlice.reducer;
