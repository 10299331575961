import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/identity/link_with_language.png";

export function LanguageFirstStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The language field indicates the language or languages in which the document is written. It should be populated with all the languages in which the file is written.
      </Paragraph>
      <Paragraph>
        The language field is included in the document download URL and can be used for searching purposes.
      </Paragraph>
      <Paragraph>
        <Screenshot src={screenshot} />
      </Paragraph>
    </StepContentDiv>
  );
}
