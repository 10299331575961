import { useCallback } from "react";
import { useLazyGetTagOwnersQuery } from "../../../../apis/tagsApi";
import { useAppSelector } from "../../../../app/hooks/hooks";
import { selectSelectedTag } from "../../../../app/slices/tagsViewSlice";

export function useTagOwners() {
  const selectedTag = useAppSelector(selectSelectedTag);

  const [getTagOwners, { data, isError, isFetching }] =
    useLazyGetTagOwnersQuery();

  const fetchTagOwners = useCallback(() => {
    if (selectedTag?.id) {
      void getTagOwners(selectedTag.id, true);
    }
  }, [selectedTag?.id, getTagOwners]);

  return {
    tagOwners: data,
    isOwnersError: isError,
    isOwnersFetching: isFetching,
    fetchTagOwners,
  };
}
