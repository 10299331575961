import { useCallback, useEffect, useState } from "react";
import { useBlocker, useNavigate } from "react-router-dom";

export default function usePrompt(message: string, when = true) {
  const navigate = useNavigate();
  const [nextLocationPath, setNextLocationPath] = useState("");

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    setNextLocationPath(nextLocation.pathname);
    return when && currentLocation.pathname !== nextLocation.pathname;
  });

  const beforeUnloadHandler = useCallback(
    (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = true;
      return message;
    },
    [message]
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      const confirm = window.confirm(message);
      if (confirm) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
    if (blocker.state === "proceeding") {
      navigate(nextLocationPath);
    }
  }, [blocker, message, navigate, nextLocationPath]);

  useEffect(() => {
    if (when) {
      onbeforeunload = beforeUnloadHandler;
      return () => {
        onbeforeunload = null;
      };
    }
  }, [beforeUnloadHandler, when]);
}
