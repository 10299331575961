import { ListItemText } from "@mui/material";
import styled from "styled-components";

export const StyledListItemText = styled(ListItemText)`
  &.MuiListItemText-root {
    margin-top: 0px;
    margin-bottom: 0px;

    span,
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
