import moment, { Moment } from "moment";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { useGetAppSettingsQuery } from "../../../../../apis/configApi";
import { resetPageNumber } from "../../../../../app/slices/documentListSlice";
import { DatePickerInput } from "../../../../../controls/DatePicker/DatePickerInput";
import {
  selectPublishedFromFilter,
  selectPublishedToFilter,
  setPublishedFromFilter,
  setPublishedToFilter,
} from "../../../../../app/slices/navigationSlice";
import { SingleFilterDiv } from "../../SC/SingleFilterDiv";
import { TitleSpan } from "../../SC/TitleSpan";
import { PublishDatesDiv } from "./styledComponents/PublishDatesDiv";
import { SectionHeaderDiv } from "../../SC/SectionHeaderDiv";
import { InputDiv } from "../../SC/InputDiv";
import { useTheme } from "styled-components";

export function PublishedDateFilter() {
  const dispatch = useAppDispatch();
  const { data: config } = useGetAppSettingsQuery(null);
  const publishedFrom = useAppSelector(selectPublishedFromFilter);
  const publishedTo = useAppSelector(selectPublishedToFilter);
  const dateFormat = config ? config.localization.dateFormat : "";
  const theme = useTheme();

  function onPublishedFromClick(date: Moment | null) {
    if (date && dateFormat && date.isValid()) {
      const newValue = date.format(dateFormat);
      if (newValue !== publishedFrom) {
        dispatch(setPublishedFromFilter(newValue));
        dispatch(resetPageNumber());
      }
    }

    if (date === null && publishedFrom) {
      dispatch(setPublishedFromFilter(undefined));
      dispatch(resetPageNumber());
    }
  }

  function onPublishedToClick(date: Moment | null) {
    if (date && dateFormat && date.isValid()) {
      const newValue = date.format(dateFormat);
      if (newValue !== publishedTo) {
        dispatch(setPublishedToFilter(newValue));
        dispatch(resetPageNumber());
      }
    }

    if (date === null && publishedTo) {
      dispatch(setPublishedToFilter(undefined));
      dispatch(resetPageNumber());
    }
  }

  return (
    <SingleFilterDiv id="publication-date-filter">
      <SectionHeaderDiv>
        <TitleSpan fontSize={theme.typography.fontSizes.headerT4}>
          Publication date
        </TitleSpan>
      </SectionHeaderDiv>
      <InputDiv id="navigation-filters-published-dates">
        <PublishDatesDiv id="navigation-filters-published-dates-div">
          <DatePickerInput
            id={"published-from-date-picker"}
            label={"From"}
            maxDate={publishedTo ? moment(publishedTo) : moment()}
            value={publishedFrom ? moment(publishedFrom) : null}
            dateFormat={dateFormat}
            onClick={onPublishedFromClick}
          />
          <DatePickerInput
            id={"published-to-date-picker"}
            label={"To"}
            minDate={publishedFrom ? moment(publishedFrom) : undefined}
            maxDate={moment()}
            value={publishedTo ? moment(publishedTo) : null}
            dateFormat={dateFormat}
            onClick={onPublishedToClick}
          />
        </PublishDatesDiv>
      </InputDiv>
    </SingleFilterDiv>
  );
}
