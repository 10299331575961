import styled from "styled-components";

export const StyledLink = styled.a`
  color: ${(props) => props.theme.palettes.grayscale.darkest};
  text-decoration: none;
  border-bottom: 2px solid ${(props) => props.theme.palettes.grayscale.medium};

  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.palettes.primary.main};
  }
`;
