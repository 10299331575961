import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useCallback, useState } from "react";
import { useTheme } from "styled-components";
import { useGetAppSettingsQuery } from "../../../apis/configApi";
import { useAppSelector } from "../../../app/hooks/hooks";
import { selectIsWindowTooSmall } from "../../../app/slices/commonSlice";
import { TextButtonControl } from "../../../controls/Buttons/TextButtonControl";
import { ButtonDiv } from "../../documents/documentsHeader/SC/ButtonDiv";
import { RequestAccessPopup } from "./RequestAccessPopup";

interface RequestAccessButtonProps {
  disabled?: boolean;
  isCompactView?: boolean;
}

export function RequestAccessButton(props: RequestAccessButtonProps) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const isWindowTooSmall = useAppSelector(selectIsWindowTooSmall);
  const { data: appSettings } = useGetAppSettingsQuery(null);

  const handlePopupClose = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <ButtonDiv>
      <RequestAccessPopup
        requestAccessLink={appSettings?.publishersAccessRequestLink ?? ""}
        treeLink={appSettings?.abbProductsTreeLink ?? ""}
        isOpen={isOpen && !isWindowTooSmall}
        handleClose={handlePopupClose}
      />
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.primaryButtonHeight}
        id={"request-access-button"}
        text={"Request Access"}
        tooltipText="Displays request access instruction"
        icon={<PersonAddIcon fontSize="small" />}
        disabled={props.disabled}
        onClick={handlePopupClose}
        isCompactView={!!props.isCompactView}
        isVertical={false}
        isVisible={true}
      />
    </ButtonDiv>
  );
}
