import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SaveChangesStep3() {
  return (
    <StepContentDiv>
      <Paragraph>
        This action allows saving changes made to a selected category. If any changes are made, it is highlighted in red.
      </Paragraph>
    </StepContentDiv>
  );
}
