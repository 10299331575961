import { useEffect, useState } from "react";
import { CustomSnackbar } from "./CustomSnackbar";
import { useSelector } from "react-redux";
import {
  selectSnackbarData,
  selectSnackbarCleared,
  dequeueSnackbar,
} from "../../app/slices/snackbarSlice";
import { useAppDispatch } from "../../app/hooks/hooks";

const SnackbarQueue = () => {
  const dispatch = useAppDispatch();
  const [dequeue, setDequeue] = useState(false);
  const snackbarData = useSelector(selectSnackbarData);
  const snackbarCleared = useSelector(selectSnackbarCleared);
  const [snackbar, setSnackbar] = useState<JSX.Element | undefined>(undefined);

  useEffect(() => {
    if (snackbarData.length > 0 && snackbar === undefined) {
      setSnackbar(
        <CustomSnackbar {...snackbarData[0]} onClose={() => setDequeue(true)} />
      );
    }
  }, [snackbarData, snackbar]);

  useEffect(() => {
    if (snackbarCleared) {
      setSnackbar(undefined);
    }
  }, [snackbarCleared]);

  useEffect(() => {
    if (dequeue) {
      setDequeue(false);
      setTimeout(() => {
        dispatch(dequeueSnackbar());
        setSnackbar(undefined);
      }, 400);
    }
  }, [dequeue, dispatch]);

  return <>{snackbar !== undefined ? snackbar : <></>}</>;
};

export default SnackbarQueue;
