import { InformationControl } from "../../../controls/InformationControl/InformationControl";

export function NoResults() {
  return (
    <InformationControl
      id={"no-publisher-found"}
      title="Publisher not found"
      description="There are no publishers with provided email address."
      showDash={false}
      padding="0px 10% 0 10%"
    />
  );
}
