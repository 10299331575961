import { DialogActions, Tooltip } from "@mui/material";
import { useTheme } from "styled-components";
import { DialogButton } from "../../../../../../controls/Dialog/SC/DialogButton";
import { CircularProgressBar } from "../../../../../../controls/ProgressIndicators/CircularProgressBar";
import { useMemo } from "react";
import { TooltipMsg } from "../../../../../../controls/Tooltips/TooltipMessages";

interface ActionButtonsProps {
  isError: boolean;
  isEditable: boolean;
  isScheduled: boolean;
  isLoading: boolean;
  onConfirmButtonHandler: () => void;
  onDeleteButtonHandeler: () => void;
  onCloseClick: () => void;
}

export function ActionButtons(props: ActionButtonsProps) {
  const theme = useTheme();
  const confirmButtonText = props.isScheduled ? "Overwrite" : "Add";
  const tooltipText = useMemo(() => {
    if (!props.isEditable) return TooltipMsg.NoPermission;
    if (props.isError) {
      return TooltipMsg.IsSomeValidationErrors;
    }

    return "";
  }, [props.isError, props.isEditable]);

  return (
    <DialogActions>
      {props.isLoading && (
        <CircularProgressBar
          size={theme.circularProgress.small}
          color="secondary"
        />
      )}
      {!props.isLoading && (
        <>
          <Tooltip placement="top" title={tooltipText}>
            <DialogButton
              id={"dialog-add-update-button"}
              disabled={!props.isEditable || props.isLoading || props.isError} //eslint-disable-line
              color="primary"
              onClick={props.onConfirmButtonHandler}
              disabledCursor={"default"}
              autoFocus
            >
              <>{confirmButtonText}</>
            </DialogButton>
          </Tooltip>

          {props.isScheduled && (
            <Tooltip placement="top" title={tooltipText}>
              <DialogButton
                id={"dialog-delete-button"}
                color="primary"
                onClick={props.onDeleteButtonHandeler}
                disabled={!props.isEditable || props.isLoading || props.isError} //eslint-disable-line
                disabledCursor={"default"}
              >
                {"Delete"}
              </DialogButton>
            </Tooltip>
          )}
        </>
      )}
      <DialogButton
        id={"dialog-cancel-button"}
        color="secondary"
        onClick={props.onCloseClick}
        disabled={props.isLoading}
      >
        {"Cancel"}
      </DialogButton>
    </DialogActions>
  );
}
