import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import {
  collapseAllCategoryBreadcrumbs,
  expandAllCategoryBreadcrumbs,
  selectExpandedCategoriesBreadcrumbsIds,
  selectLongCategoryPathsIds,
} from "../../../../../app/slices/documentMetadataSlice";
import { TooltipTop } from "../../../../../controls/Tooltips/ToolipTop";
import { StyledUnfoldLessIcon } from "./SC/StyledUnfoldLessIcon";
import { StyledUnfoldMoreIcon } from "./SC/StyledUnfoldMoreIcon";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";

export function ExpandCollapseBreadcrumbsButton() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useAppDispatch();
  const longCategoryPathIds = useAppSelector(selectLongCategoryPathsIds);
  const expandedCategoriesBreadcrumbsIds = useAppSelector(
    selectExpandedCategoriesBreadcrumbsIds
  );

  useEffect(() => {
    if (longCategoryPathIds.length == 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    if (longCategoryPathIds.length > expandedCategoriesBreadcrumbsIds.length) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  }, [longCategoryPathIds, expandedCategoriesBreadcrumbsIds]);

  const handleExpandClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(expandAllCategoryBreadcrumbs());
  };
  const handleCollapseClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(collapseAllCategoryBreadcrumbs());
  };

  let icon = null;

  if (isExpanded) {
    icon = (
      <StyledUnfoldLessIcon
        id="breadcrumb-collapse-button"
        fontSize="small"
        onClick={handleCollapseClick}
        $disabled={disabled}
      />
    );
  } else {
    icon = (
      <StyledUnfoldMoreIcon
        id="breadcrumb-expand-button"
        fontSize="small"
        onClick={handleExpandClick}
      />
    );
  }

  if (disabled) {
    return null;
  } else {
    return (
      <TooltipTop
        title={
          isExpanded ? TooltipMsg.CollapseAllBreadcrumbs : TooltipMsg.ExpandAllBreadcrumbs
        }
      >
        {icon}
      </TooltipTop>
    );
  }
}
