import { useEffect, useState } from "react";
import { StyledDatePicker } from "../../../../../../controls/DatePicker/SC/StyledDatePicker";
import { CalendarIcon } from "../../../../../../controls/DatePicker/Icons/CalendarIcon";
import { LeftDatePickerIcon } from "../../../../../../controls/DatePicker/Icons/LeftDatePickerIcon";
import { RightDatePickerIcon } from "../../../../../../controls/DatePicker/Icons/RightDatePickerIcon";
import { InputDiv } from "../../../../../documentsNavigation/filters/SC/InputDiv";
import { StyledTextField } from "../../../../../../controls/TextField/SC/StyledTextField";
import { FormHelperControl } from "../../../../../../controls/FormHelper/FormHelperControl";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../app/hooks/hooks";
import {
  selectScheduledActionIsErrorVisible,
  setScheduledActionIsError,
} from "../../../../../../app/slices/scheduledActionSlice";
import { SectionFieldDiv } from "../../../../inputs/SC/SectionFieldDiv";
import { AutoCompleteLabelSpan } from "../../../../inputs/SC/AutoCompleteLabelSpan";
import { TooltipMsg } from "../../../../../../controls/Tooltips/TooltipMessages";
import LabelIcon from "../../../../inputs/LabelIcon";
import moment from "moment";
import { LabelContainerDiv } from "../../../../inputs/SC/LabelContainerDiv";

interface TextFieldInputProps {
  id: string;
  label: string;
  minDate?: string;
  dateFormat: string;
  value: string | null;
  disabled: boolean;
  trigger: boolean;
  onClick: (date: string) => void;
}

export function ActionDatePicker(props: TextFieldInputProps) {
  const dispatch = useAppDispatch();
  const [helperText, setHelperText] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [localValue, setLocalValue] = useState<string | null>(props.value);
  const isErrorVisible = useAppSelector(selectScheduledActionIsErrorVisible);

  const validateDate = (value: string) => {
    if (!value) {
      setIsError(true);
      dispatch(setScheduledActionIsError(true));
      setHelperText("Date cannot be empty");
      return false;
    }

    const newValue = moment(value);

    if (!newValue.isValid()) {
      setIsError(true);
      dispatch(setScheduledActionIsError(true));
      setHelperText("Date is invalid");
      return false;
    }

    if (props.minDate && newValue.isBefore(props.minDate)) {
      setIsError(true);
      dispatch(setScheduledActionIsError(true));
      setHelperText(
        "Scheduled action's expiration date is lower than tommorow's date."
      );
      return false;
    }

    setIsError(false);
    setHelperText("");
    dispatch(setScheduledActionIsError(false));
    setLocalValue(value);
    return true;
  };

  const handleChange = (newValue: string) => {
    if (newValue && validateDate(newValue)) {
      props.onClick(newValue);
    }
  };

  useEffect(() => {
    setLocalValue(props.value);
    setIsError(false);
    setHelperText("");
    dispatch(setScheduledActionIsError(false));
  }, [props.trigger, dispatch]); //eslint-disable-line

  useEffect(() => {
    if (isErrorVisible && props.value === null) {
      setIsError(true);
      setHelperText("Date cannot be empty");
      dispatch(setScheduledActionIsError(true));
    }
  }, [isErrorVisible, dispatch]); //eslint-disable-line

  return (
    <SectionFieldDiv id={props.id} $isHidden={false}>
      <LabelContainerDiv>
        <AutoCompleteLabelSpan>{"Action date: "}</AutoCompleteLabelSpan>
        {props.disabled && (
          <LabelIcon message={TooltipMsg.NoAccessToDocument} />
        )}
      </LabelContainerDiv>

      <StyledDatePicker
        $marginBottom={0}
        value={localValue}
        $isEditable={!props.disabled}
        inputFormat={props.dateFormat}
        minDate={props.minDate}
        onChange={(newValue) => {
          handleChange(newValue as string);
        }}
        components={{
          OpenPickerIcon: CalendarIcon,
          LeftArrowIcon: LeftDatePickerIcon,
          RightArrowIcon: RightDatePickerIcon,
        }}
        InputAdornmentProps={{
          position: "end",
          style: { order: 2, marginLeft: 0 },
        }}
        renderInput={(params) => {
          return (
            <InputDiv paddingTop={0}>
              <StyledTextField
                $isEditable={true}
                id={props.id + "-input"}
                error={isError}
                padding={12}
                size="small"
                {...params}
              />
              <FormHelperControl
                isWarning={false}
                isError={isError}
                texts={[helperText]}
              />
            </InputDiv>
          );
        }}
      />
    </SectionFieldDiv>
  );
}
