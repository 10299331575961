import { useAppDispatch } from "../hooks";
import { useEffect } from "react";
import { useSavePublisherCategorySettingsMutation } from "../../../apis/publisherCategoriesSettingsApi";
import {
  selectCategoryId,
  selectCurrentAccessGroups,
  selectCurrentDocumentKinds,
  selectCurrentPermission,
  selectCurrentSettings,
  setIsLoaderVisible,
  setStateAfterSave,
} from "../../slices/publisherCategoriesSettingsSlice";
import { useAppSelector } from "../hooks";
import { openSnackbar } from "../../helpers/snackBarHelper";
import { SnackbarType } from "../../../models/snackbar";
import { SnackbarMsg } from "../../../controls/Snackbar/SnackbarMessages";

export function useSaveSettingsHandler() {
  const dispatch = useAppDispatch();

  const selectedAccessGroupsIds = useAppSelector(selectCurrentAccessGroups);
  const selectedDocumentKindsIds = useAppSelector(selectCurrentDocumentKinds);
  const settings = useAppSelector(selectCurrentSettings);
  const permission = useAppSelector(selectCurrentPermission);
  const categoryId = useAppSelector(selectCategoryId);

  const [
    savePublisherCategorySettings,
    { isError: isError, isSuccess: isSuccess, isLoading: isLoading },
  ] = useSavePublisherCategorySettingsMutation();

  const saveSettingsHandler = () => {
    void savePublisherCategorySettings({
      accessGroupIds: selectedAccessGroupsIds,
      documentKindIds: selectedDocumentKindsIds,
      approvalWorkflowEnabled: settings.approvalWorkflowEnabled,
      categoryId: categoryId,
      changePopularCategory: permission.changePopularCategory,
      deleteDocument: permission.deleteDocument,
      editDocumentIdentity: permission.editDocumentIdentity,
      highLevelWarningThreshold: settings.highLevelWarningThreshold,
      permanentlyDeletedInDays: settings.permanentlyDeletedInDays,
    });
  };

  useEffect(() => {
    if (isError) {
      openSnackbar(dispatch, SnackbarMsg.SaveChangesError, SnackbarType.error);
      dispatch(setIsLoaderVisible(false));
    }
  }, [dispatch, isError]);

  useEffect(() => {
    if (isLoading) {
      dispatch(setIsLoaderVisible(true));
    }
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (isSuccess) {
      openSnackbar(
        dispatch,
        "Settings have been saved successfully.",
        SnackbarType.success
      );
      dispatch(setStateAfterSave());
      dispatch(setIsLoaderVisible(false));
    }
  }, [dispatch, isSuccess]);

  return { saveSettingsHandler, isSaving: isLoading };
}
