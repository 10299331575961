import { SnackbarType } from "../../models/snackbar";
import { enqueueSnackbar } from "../slices/snackbarSlice";
import { AppDispatch } from "../store";

export function openSnackbar(
  dispatch: AppDispatch,
  text: string,
  type: SnackbarType
) {
  dispatch(
    enqueueSnackbar({
      message: text,
      type: type,
    })
  );
}
