import { useCallback, useMemo, useState } from "react";
import {
  usePublishExistingDocumentMutation,
  usePublishNewDocumentDraftMutation,
  usePublishNewDocumentMutation,
  usePublishNewDocumentRevisionMutation,
} from "../../../apis/documentApi";
import DocumentMetadata from "../../../models/documentDetails/documentMetadata";
import { DraftType } from "../../../models/documentDetails/documentStatus";
import { Workflow } from "../../../models/documentList/types/workflow";
import { getNonEmptyDocumentIdentity } from "../../helpers/documentIdentityHelper";
import {
  selectDocumentDraftType,
  selectDocumentIdentity,
} from "../../slices/documentMetadataSlice";
import { useAppSelector } from "../hooks";

enum PublishApiType {
  newDocument,
  newDocumentDraft,
  existingDocument,
  newRevision,
}

function usePublishApi() {
  const draftType = useAppSelector(selectDocumentDraftType);
  const identity = useAppSelector(selectDocumentIdentity);
  const [publishApiType, setPublishApiType] = useState<PublishApiType>();

  const [publishNewDocumentAction, newDocumentActionData] =
    usePublishNewDocumentMutation();

  const [publishNewDocumentDraftAction, newDocumentDraftActionData] =
    usePublishNewDocumentDraftMutation();

  const [publishExistingDocumentAction, existingDocumentDraftActionData] =
    usePublishExistingDocumentMutation();

  const [publishNewDocumentRevisionAction, newDocumentRevisionActionData] =
    usePublishNewDocumentRevisionMutation();

  const publishApiData = useMemo(() => {
    if (publishApiType === undefined) {
      return {
        isSuccess: false,
        isLoading: false,
        error: undefined,
      };
    }

    if (publishApiType === PublishApiType.newDocument) {
      return {
        isSuccess: newDocumentActionData.isSuccess,
        isLoading: newDocumentActionData.isLoading,
        error: newDocumentActionData.error,
      };
    }

    if (publishApiType === PublishApiType.newDocumentDraft) {
      return {
        isSuccess: newDocumentDraftActionData.isSuccess,
        isLoading: newDocumentDraftActionData.isLoading,
        error: newDocumentDraftActionData.error,
      };
    }

    if (publishApiType === PublishApiType.newRevision) {
      return {
        isSuccess: newDocumentRevisionActionData.isSuccess,
        isLoading: newDocumentRevisionActionData.isLoading,
        error: newDocumentRevisionActionData.error,
      };
    }

    return {
      isSuccess: existingDocumentDraftActionData.isSuccess,
      isLoading: existingDocumentDraftActionData.isLoading,
      error: existingDocumentDraftActionData.error,
    };
  }, [
    publishApiType,
    existingDocumentDraftActionData.isSuccess,
    existingDocumentDraftActionData.isLoading,
    existingDocumentDraftActionData.error,
    newDocumentActionData.isSuccess,
    newDocumentActionData.isLoading,
    newDocumentActionData.error,
    newDocumentDraftActionData.isSuccess,
    newDocumentDraftActionData.isLoading,
    newDocumentDraftActionData.error,
    newDocumentRevisionActionData.isSuccess,
    newDocumentRevisionActionData.isLoading,
    newDocumentRevisionActionData.error,
  ]);

  const publishNewDocumentRevision = useCallback(
    (documentMetadata: DocumentMetadata) => {
      const nonEmptyIdentity = getNonEmptyDocumentIdentity(identity);

      setPublishApiType(PublishApiType.newRevision);
      void publishNewDocumentRevisionAction({
        metadata: documentMetadata,
        revisionIdentity: nonEmptyIdentity,
      });
    },
    [publishNewDocumentRevisionAction, identity]
  );

  const publish = useCallback(
    (
      documentMetadata: DocumentMetadata,
      workflow: Workflow,
      shouldOldLinksWorkOnIdentityChange?: boolean
    ) => {
      const nonEmptyIdentity = getNonEmptyDocumentIdentity(identity);

      if (workflow === Workflow.NewDocument) {
        setPublishApiType(PublishApiType.newDocument);
        void publishNewDocumentAction(documentMetadata);
      } else if (
        workflow === Workflow.Draft &&
        draftType === DraftType.NewDocumentDraft
      ) {
        setPublishApiType(PublishApiType.newDocumentDraft);
        void publishNewDocumentDraftAction({
          metadata: documentMetadata,
          revisionIdentity: nonEmptyIdentity,
        });
      } else {
        setPublishApiType(PublishApiType.existingDocument);
        void publishExistingDocumentAction({
          metadata: documentMetadata,
          revisionIdentity: nonEmptyIdentity,
          shouldOldLinksWorkOnIdentityChange,
        });
      }
    },
    [
      draftType,
      publishNewDocumentAction,
      publishNewDocumentDraftAction,
      publishExistingDocumentAction,
      identity,
    ]
  );

  return {
    publishApiData,
    publish,
    publishNewDocumentRevision,
  };
}

export default usePublishApi;
