import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function ListStep8() {
    return (
        <StepContentDiv>
            <Paragraph>
                Clicking on a category opens the Category tab, displaying the publishers having access to this category.
            </Paragraph>
        </StepContentDiv>
    );
}
