import { ToggleDiv } from "../content/SC/ToggleDiv";
import { ToggleIconSpan } from "../content/SC/ToggleIconSpan";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

interface ToggleNavigationProps {
  onToggleClick: () => void;
  isMinimized: boolean;
}

export function ToggleNavigation(props: ToggleNavigationProps) {
  return (
    <ToggleDiv
      id="navigation-toggle-div"
      onClick={props.onToggleClick}
      $isMinimized={props.isMinimized}
    >
      <ToggleIconSpan>
        <KeyboardDoubleArrowRightIcon fontSize="small" />
      </ToggleIconSpan>
    </ToggleDiv>
  );
}
