import { StyledSkeleton } from "./SC/StyledSkeleton";

interface CategoryNavigationTreeItemSkeletonProps {
  padding: number;
  numberOfSkeletons: number;
  parentCategoryId: string;
}

function CategoryNavigationTreeItemSkeleton({
  padding,
  numberOfSkeletons: numbersOfSkeletons,
  parentCategoryId,
}: CategoryNavigationTreeItemSkeletonProps) {
  return (
    <div id={`${parentCategoryId}-category-tree-children-skeleton-loading`}>
      {Array.from({ length: numbersOfSkeletons }, (_, i) => (
        <StyledSkeleton
          key={`skeleton ${i}`}
          $marginLeft={padding}
          $marginRight={17}
          width={"auto"}
          variant="text"
        />
      ))}
    </div>
  );
}

export default CategoryNavigationTreeItemSkeleton;
