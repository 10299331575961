import { StyledCategoryChip } from "../../../../../components/details/sections/categories/SC/StyledCategoryChip";
import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/categories/popular.png";
import screenshotDs from "../../../screenshots/categories/ds_popular.png";
interface PopularCategoryStepProps {
    showScreenshots?: boolean;
    isPopular: boolean;
}


export function PopularCategoryStep(props: PopularCategoryStepProps) {
    return (
      <StepContentDiv>
        <Paragraph>
          The Popular Document icon{" "}
          <StyledCategoryChip
            label="P"
            type="popular"
            $isDisabled={false}
            $isSelected={props.isPopular}
            selected={props.isPopular}
          />{" "}
          can be marked to highlight a document as Popular in one or more nodes.
          This action will result in displaying the document in the
          &quot;Popular&quot; documents section of the Download Section widget
          for the corresponding category. Popular documents are those ABB wish
          to promote.
        </Paragraph>
        <Paragraph>
          <Screenshot src={screenshotDs} />
        </Paragraph>
        <Paragraph>
          It is important to note that the decision to mark a document as
          Popular lies with the publisher, who can choose which document they
          would like to promote. If no documents are marked as Popular in a
          category, the Download Section widget will display the documents with
          the highest number of downloads in the previous quarter.
        </Paragraph>
        {props.showScreenshots && (
          <Paragraph>
            <Screenshot src={screenshot} />
          </Paragraph>
        )}
      </StepContentDiv>
    );
}
