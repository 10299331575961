import { TextField } from "@mui/material";
import styled from "styled-components";

interface NumberTextFieldProps {
  $isEdited?: boolean;
}

export const NumberTextField = styled(TextField)<NumberTextFieldProps>`
  width: 64px;
  .MuiOutlinedInput-notchedOutline {
    border: ${(props) =>
      props.$isEdited
        ? "2px solid " + props.theme.palettes.secondary.info
        : ""};
  }
  &. Mui-focused {
    border: ${(props) =>
      props.$isEdited
        ? "2px solid " + props.theme.palettes.secondary.info
        : "unset"};
  }
`;
