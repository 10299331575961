import { useState, useEffect } from "react";

function getWindowDimensions() {
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  return {
    windowWidth,
    windowHeight,
  };
}

function getScreenInfo() {
  const screenHeight = window.screen.height;
  const screenWidth = window.screen.width;
  const screenPixelRatio = window.devicePixelRatio;
  return {
    screenWidth,
    screenHeight,
    screenPixelRatio,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function useScreenInfo() {
  const [screenInfo, setScreenInfo] = useState(getScreenInfo());

  useEffect(() => {
    setScreenInfo(getScreenInfo());
  }, []);

  return screenInfo;
}
