import { TableRow } from "@mui/material";
import styled from "styled-components";

interface StyledTableRowProps {
  striped: boolean;
}

export const StyledTableRow = styled(TableRow)<StyledTableRowProps>`
  background-color: ${(props) =>
    props.striped
      ? props.theme.palettes.grayscale.lightest2
      : props.theme.palettes.grayscale.white};
`;
