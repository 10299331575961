export enum CountryAvailabilityType {
  AllCountries = "AllCountries",
  AvailableInCountries = "AvailableInCountries",
  NotAvailableInCountries = "NotAvailableInCountries",
}

export default interface CountryMarketAvailability {
  isSynchronizedWithCma: boolean;
  countryAvailabilityType: CountryAvailabilityType;
  countryIsoCodes: string[];
}
