import { Link } from "react-router-dom";
import styled, { DefaultTheme } from "styled-components";

interface StyledLinkProps {
  width?: number;
  isParentElement?: boolean;
  $isGray: boolean;
}

function getColor(isGray: boolean, theme: DefaultTheme) {
  if (isGray) {
    return theme.palettes.grayscale.medium;
  }
  return theme.palettes.grayscale.darkest;
}

function getWidth(width: number | undefined) {
  if (width) {
    return width + "px";
  }
  return "unset";
}

export const StyledLink = styled(Link)<StyledLinkProps>`
  width: ${(props) => getWidth(props.width)};
  color: ${(props) => getColor(props.$isGray, props.theme)};
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.palettes.primary.main};
    cursor: pointer;
  }

  .MuiTouchRipple-root {
    color: ${(props) =>
      props.isParentElement
        ? `${props.theme.palettes.primary.dark}de`
        : "inherit"};
  }
`;
