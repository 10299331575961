import { Switch } from "@mui/material";
import styled from "styled-components";

interface StyledSwitchProps {
  $isEdited?: boolean;
}

export const StyledSwitch = styled(Switch)<StyledSwitchProps>`
  & .MuiSwitch-thumb {
    background-color: ${(props) =>
      props.$isEdited ? props.theme.palettes.secondary.info : ""};
  }

  & .MuiSwitch-track {
    background-color: ${(props) =>
      props.$isEdited ? props.theme.palettes.secondary.info : ""} !important;
  }
`;
