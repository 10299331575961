import { Divider } from "@mui/material";
import { PermissionInfoDiv } from "./SC/PermissionInfoDiv";
import { PermissionsDiv } from "./SC/PermissionsDiv";
import { TitleDiv } from "./SC/TitleDiv";
import { RolesButtons } from "./RolesButtons";
import { RoleInCategory } from "../../../../../models/rolesInCategory";

interface PermissionInfoProps {
  id: string;
  permission: string;
  value: RoleInCategory;
  disabled?: boolean;
  isEdited: boolean;
  onChangeHandler: (role: RoleInCategory) => void;
}

export function PermissionInfo(props: PermissionInfoProps) {
  return (
    <PermissionInfoDiv id={props.id}>
      <PermissionsDiv>
        <TitleDiv>{props.permission}</TitleDiv>
        <RolesButtons
          value={props.value}
          onChangeHandler={props.onChangeHandler}
          disabled={props.disabled ?? false}
          isEdited={props.isEdited}
        />
      </PermissionsDiv>
      <Divider />
    </PermissionInfoDiv>
  );
}
