import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function AddButtonStep() {
  return (
    <StepContentDiv>
      <Paragraph>This action adds a scheduled action to a document.</Paragraph>
    </StepContentDiv>
  );
}
