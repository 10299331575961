
import { useCallback } from "react";
import { useLazyGetTagValuesQuery } from "../../../../apis/tagsApi";
import { selectSelectedTag, selectTagsValueSearch } from "../../../../app/slices/tagsViewSlice";
import { useAppSelector } from "../../../../app/hooks/hooks";

export function useTagValues() {
  const selectedTag = useAppSelector(selectSelectedTag);
  const tagValuesSearch = useAppSelector(selectTagsValueSearch)

  const [getTagValues, { data, isError, isFetching }] = useLazyGetTagValuesQuery();

  const fetchTagValues = useCallback(() => {
    if (selectedTag?.id) {
      void getTagValues({ tagId: selectedTag.id, searchQuery: tagValuesSearch });
    }
  }, [getTagValues, selectedTag?.id, tagValuesSearch]);


  return {
    fetchTagValues,
    tagValuesData: data,
    isValuesError: isError,
    isValuesFetching: isFetching,
  };
}
