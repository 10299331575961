import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function ThirdCheckboxStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        When the &quot;Show uncategorized documents only&quot; option is
        enabled, it will display documents that are classified exclusively under
        deleted categories.
      </Paragraph>
      <Paragraph>
        This option is useful for identifying and cleaning up documents that are
        still attached to categories that have been deleted in ABB Products.
      </Paragraph>
    </StepContentDiv>
  );
}
