import { Typography } from "@mui/material";
import { DialogControl } from "../../../../../controls/Dialog/DialogControl";
import { DocumentOriginalIdentity } from "../../../../../models/documentDetails/documentAttachment";
import { StyledDialogContentText } from "../../../selfHelpPopup/SC/StyledDialogContentText";

interface DuplicateAttachmentPopupProps {
  id: string;
  isOpen: boolean;
  originalIdentity: DocumentOriginalIdentity | undefined;
  publishAnywayButtonText: string;
  onCloseClick: () => void;
  onConfirmClick: () => void;
  onPublishAnywayClick: () => void;
}

function DuplicateAttachmentPopup(props: DuplicateAttachmentPopupProps) {
  return (
    <DialogControl
      fullWidth={true}
      id={props.id}
      title="This attachment is published already"
      onCloseClick={props.onCloseClick}
      onConfirmClick={props.onConfirmClick}
      onExtraButtonClick={props.onPublishAnywayClick}
      showExtraButton={true}
      maxWidth="sm"
      useActions={true}
      isOpen={props.isOpen}
      content={
        props.originalIdentity && (
          <StyledDialogContentText id="alert-dialog-description">
            <Typography gutterBottom>
              This attachment is already published in another document. You can
              publish it anyway, but it will be a duplicate. Instead, you can go
              to original document and add your categories to it.
            </Typography>
            <Typography gutterBottom>
              Document number: {props.originalIdentity.documentNumber}
            </Typography>
            <Typography gutterBottom>
              Part: {props.originalIdentity.documentPart}
            </Typography>
            <Typography gutterBottom>
              Revision: {props.originalIdentity.revision}
            </Typography>
            <Typography gutterBottom>
              Languages: {props.originalIdentity.documentLanguages.join(", ")}
            </Typography>
          </StyledDialogContentText>
        )
      }
      confirmText="Go to original document"
      extraButtonText={props.publishAnywayButtonText}
      cancelText="Cancel"
    />
  );
}

export default DuplicateAttachmentPopup;
