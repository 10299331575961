import React from "react";
import { PillSpan } from "./styledComponents/PillSpan";

export interface PillProps {
  content: string;
  className: string;
  isGray: boolean;
}

export const Pill = React.forwardRef(function Pill(
  props: PillProps,
  ref: React.ForwardedRef<HTMLSpanElement>
) {
  return (
    <PillSpan $isGray={props.isGray} {...props} ref={ref}>
      {props.content}
    </PillSpan>
  );
});
