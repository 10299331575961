
import AddIcon from "@mui/icons-material/Add";
import { AddTagDiv } from "./SC/AddTagDiv";
import { TextFieldInput } from "../../../../controls/TextField/TextFieldInput";
import { useAppDispatch } from "../../../../app/hooks/hooks";
import { addTagValue } from "../../../../app/slices/tagsSlice";
import { useState } from "react";
import useValidationRules from "../../../../app/hooks/useValidationRules";

export function AddTagValue() {
  const dispatch = useAppDispatch();
  const [clean, setClean] = useState(false);
  const { getTagValidationRule } = useValidationRules();

  const addTagsHandler = (value: string) => {
    if (value) {
      dispatch(
        addTagValue({
          value: value,
          countOfDocuments: 0
        })
      );
      setClean((clean) => !clean);
    }
  };

  const validationRegExp = getTagValidationRule.regExp;
  const validationMsg = getTagValidationRule.message;

  return (
    <AddTagDiv id="add-tag-input-div">
      <TextFieldInput
        id={"tag-textfield"}
        label=""
        placeholder="Add new Tag Value..."
        onClick={(value) => addTagsHandler(value)}
        initialValue={""}
        trimEntries={true}
        icon={<AddIcon />}
        iconText={"Add new tag value"}
        clearTrigger={clean}
        validationMsg={validationMsg}
        validationRegExp={validationRegExp}
      />
    </AddTagDiv>
  );
}
