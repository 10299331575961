import styled, { DefaultTheme } from "styled-components";
import { Density } from "../../../../../app/slices/models/documentsModels";

interface DocumentListGroupDivProps {
  density: Density
}

function getFontSize(density: Density, theme: DefaultTheme) {
  if (density === Density.Comfort) {
    return theme.typography.fontSizes.large;
  } else {
    return theme.typography.fontSizes.medium;
  }
}



export const DocumentListGroupDiv = styled.div<DocumentListGroupDivProps>`
  display: flex;
  flex: 1;
  font-size: ${(props) => getFontSize(props.density, props.theme)}px;
`;
