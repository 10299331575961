import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/categories/add_categories_warning.png";

export function SelectCategoriesStepTwo() {
    return (
        <StepContentDiv>
            <Paragraph>
                If a category from a higher level is selected, an orange triangle (add icon) will appear along with a warning. In most cases, documents should be classified to leaf nodes. The only reason for classifying a document above leaf node is to prevent it from appearing when browsing lower level categories.
            </Paragraph>
            <Paragraph>
                <Screenshot src={screenshot} />
            </Paragraph>
        </StepContentDiv>
    );
}
