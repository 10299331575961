import { Avatar } from "@mui/material";
import styled from "styled-components";

export const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    background-color: ${(props) => props.theme.palettes.primary.main};
    width: 40px;
    height: 40px;
  }
`;
