import { forwardRef, useImperativeHandle, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTheme } from "styled-components";
import { useAttachmentUpload } from "../../../../apis/hooks/useAttachmentUpload";
import {
  calculateChunkSizes,
  calculateProgress,
  createAttachmentData,
  extractFileInfoFromFileName,
} from "../../../../app/helpers/attachmentHelper";
import { openSnackbar } from "../../../../app/helpers/snackBarHelper";
import useDocumentValidationRules from "../../../../app/hooks/document/useDocumentValidationRules";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { selectCurrentDocumentAttachment } from "../../../../app/slices/documentMetadataSlice";
import { selectDetailsHasRequiredUncompletedSections } from "../../../../app/slices/selfHelpSlice";
import { TextButtonControl } from "../../../../controls/Buttons/TextButtonControl";
import {
  AttachmentUploadInfo,
  DocumentAttachment,
} from "../../../../models/documentDetails/documentAttachment";
import { UploadStatus } from "../../../../models/documentDetails/uploadStatus";
import { SnackbarType } from "../../../../models/snackbar";
import { StyledUploadContainer } from "../SC/StyledUploadContainer";
import { UploadP } from "../SC/UploadP";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import { SnackbarMsg } from "../../../../controls/Snackbar/SnackbarMessages";
import { pauseUploadReason } from "../../sections/Attachment/AttachmentUploadControl";
import { ValidationRule } from "../../../../models/validationRule";

interface FileUploadProps {
  buttonText: string;
  dragAndDropText: string;
  disabled: boolean;
  abortControllers: AbortController[];
  attachmentUploadInfo?: AttachmentUploadInfo;
  onUploadStart: (data: DocumentAttachment) => void;
  onReportProgress: (progress: number) => void;
  onUploadSuccess: () => void;
  onUploadFailed: () => void;
  onUploadInitialize: () => void;
  onChunkSizeCalculation: (isSingleFileUpload: boolean) => void;
  onUploadInitError: () => void;
}

export interface FileUploadHandler {
  resumeFileUpload: () => void;
  completeFileUpload: (attachmentId: string) => void;
  clearFile: () => void;
}

const FileUpload = forwardRef<FileUploadHandler, FileUploadProps>(
  (props: FileUploadProps, ref) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const { createAttachment, uploadChunk, completeAttachmentUpload } =
      useAttachmentUpload();
    const [file, setFile] = useState<File | undefined>(undefined);
    const currentDocumentAttachment = useAppSelector(
      selectCurrentDocumentAttachment
    );
    const { getFileNameValidationRules } = useDocumentValidationRules();
    const hasUncompletedSections = useAppSelector(
      selectDetailsHasRequiredUncompletedSections
    );

    useImperativeHandle(ref, () => ({
      resumeFileUpload() {
        if (
          file &&
          props.attachmentUploadInfo &&
          shouldResumeUpload(file, props.attachmentUploadInfo)
        ) {
          void resumeFileUpload(file, props.attachmentUploadInfo.chunkSize);
        } else {
          open();
        }
      },
      completeFileUpload(uploadId: string) {
        void completeFileUpload(uploadId, 0);
      },
      clearFile() {
        setFile(undefined);
      },
    }));

    const shouldResumeUpload = (
      file: File,
      attachmentUploadInfo: AttachmentUploadInfo
    ) => {
      return (
        (attachmentUploadInfo.status == UploadStatus.UploadInProgress ||
          attachmentUploadInfo.status == UploadStatus.Created) &&
        file.size === attachmentUploadInfo.fileSize &&
        file.name === attachmentUploadInfo.fileName
      );
    };

    const resumeFileUpload = async (file: File, chunkSize: number) => {
      if (
        !currentDocumentAttachment.uploadId ||
        props.attachmentUploadInfo === undefined
      ) {
        return;
      }

      const attachmentChunks = calculateChunkSizes(file, chunkSize);
      props.onUploadInitialize();
      props.onChunkSizeCalculation(attachmentChunks === 1);

      await uploadFile(
        file,
        currentDocumentAttachment.uploadId,
        attachmentChunks,
        props.attachmentUploadInfo.lastSuccessfullyUploadedChunkNumber + 1,
        chunkSize
      );
    };

    const newFileUpload = async (file: File) => {
      props.onUploadInitialize();
      let uploadId: string | undefined;
      let chunkSize: number | undefined;

      await createAttachment({
        fileName: file.name,
        fileSize: file.size,
      })
        .unwrap()
        .then((response) => {
          uploadId = response.uploadId;
          chunkSize = response.chunkSize;
        })
        .catch(() => {
          props.onUploadInitError();
          setFile(undefined);
        });

      if (!uploadId || !chunkSize) {
        return;
      }

      const chunksNumber = calculateChunkSizes(file, chunkSize);
      props.onChunkSizeCalculation(chunksNumber === 1);

      //initial progress set
      props.onReportProgress(calculateProgress(chunksNumber, 0));

      await uploadFile(file, uploadId, chunksNumber, 1, chunkSize);
    };

    const completeFileUpload = async (uploadId: string, abortIndex: number) => {
      props.abortControllers[abortIndex] = new AbortController();
      await completeAttachmentUpload({
        uploadId,
        signal: props.abortControllers[abortIndex].signal,
      })
        .unwrap()
        .then(() => {
          props.onReportProgress(100);
          props.onUploadSuccess();
        })
        .catch(() => {
          props.onUploadFailed();
        });

      setFile(undefined);
    };

    const uploadFile = async (
      file: File,
      uploadId: string,
      attachmentChunks: number,
      startingChunk: number,
      chunkSize: number
    ) => {
      try {
        let abortIndex = 0;
        props.abortControllers[abortIndex++] = new AbortController();
        props.onUploadStart(createAttachmentData(file, uploadId));

        for (let i = startingChunk; i <= attachmentChunks; i++, abortIndex++) {
          const start = (i - 1) * chunkSize;
          const end = Math.min(i * chunkSize, file.size);
          const chunk = file.slice(start, end);
          const formData = new FormData();
          formData.append("file", chunk, `chunk${i}`);

          props.abortControllers[abortIndex] = new AbortController();
          await uploadChunk({
            uploadId,
            partNumber: i,
            file: formData,
            signal: props.abortControllers[abortIndex].signal,
          }).unwrap();

          props.onReportProgress(calculateProgress(attachmentChunks, i));
        }

        await completeFileUpload(uploadId, abortIndex);
      } catch (error) {
        const isNotPaused =
          (error as { error: string } | undefined)?.error !== pauseUploadReason;
        if (isNotPaused) {
          props.onUploadFailed();
        }
      }
    };

    const onSelectFile = (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) return;

      const newFile = acceptedFiles[0];
      setFile(newFile);
      if (
        props.attachmentUploadInfo &&
        currentDocumentAttachment.uploadId &&
        shouldResumeUpload(newFile, props.attachmentUploadInfo)
      ) {
        void resumeFileUpload(
          newFile,
          props.attachmentUploadInfo.chunkSize
        ).catch(() => null);
      } else {
        void newFileUpload(newFile).catch(() => null);
      }
    };

    const testResult = (inputValue: string, rule: ValidationRule) =>
      rule.successIsInvalid
        ? rule.regExp.test(inputValue)
        : !rule.regExp.test(inputValue);

    const fileSizeValidator = (file: File) => {
      const fileInfo = extractFileInfoFromFileName(file.name);
      const validationRules = getFileNameValidationRules(fileInfo.extension);
      const text: string[] = [];
      let isError = false;

      if (file.size <= 0) {
        isError = true;
        text.push("File size must be greater than 0.");
      }

      validationRules.forEach((rule) => {
        if (
          testResult(fileInfo.name, rule) &&
          (rule.isVisible === undefined ||
            !!rule.isVisible ||
            !!rule.forceNonVisibleValidation)
        ) {
          isError = true;
          text.push(rule.message);
        }
      });

      if (isError) {
        openSnackbar(dispatch, text.join(""), SnackbarType.error);
        return {
          code: "file-name-error",
          message: SnackbarMsg.FileNameError,
        };
      }

      return null;
    };

    const {
      getRootProps,
      getInputProps,
      open,
      isFocused,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      noClick: true,
      noKeyboard: true,
      disabled: props.disabled,
      onDrop: onSelectFile,
      validator: fileSizeValidator,
    });

    return (
      <div className="container">
        <input {...getInputProps()} />
        {file === undefined && (
          <StyledUploadContainer
            {...getRootProps({ isFocused, isDragAccept, isDragReject })}
          >
            <TextButtonControl
              isCompactView={false}
              isVertical={false}
              isVisible={true}
              text={props.buttonText}
              height={theme.shapes.primaryButtonHeight}
              colors="secondary"
              disabled={props.disabled || hasUncompletedSections}
              disabledTooltipText={
                hasUncompletedSections
                  ? TooltipMsg.UncompletedSelfHelpSections
                  : "You do not have access to this document"
              }
              id="attachment-replace-file-button"
              onClick={open}
              variant={"contained"}
            />
            <UploadP>{props.dragAndDropText}</UploadP>
          </StyledUploadContainer>
        )}
      </div>
    );
  }
);

FileUpload.displayName = "FileUpload";
export default FileUpload;
