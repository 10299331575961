import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function CloseStep5() {
  return (
    <StepContentDiv>
      <Paragraph>
        This action closes the window without saving any changes. A prompt will appear if any changes have been made to prevent accidental loss of modifications.
      </Paragraph>
    </StepContentDiv>
  );
}
