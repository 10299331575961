import { createStep } from "../../helpers/selfHelpBuilder";
import { TitleFirstStep } from "./Titles/TitleFirstStep";
import { TitleSecondStep } from "./Titles/TitleSecondStep";
import { TitleThirdStep } from "./Titles/TitleThirdStep";

export const titleSteps = () => {
  return [
    createStep("#Titles", <TitleFirstStep />, 520, "auto"),
    createStep("#Titles", <TitleSecondStep />, 720, "auto"),
    createStep("#Titles", <TitleThirdStep />, 720, "auto")
  ];
};
