import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function AdditionalEmailsStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        In this section, the list of scheduled action additional recipients can
        be specified. By default, the scheduled action creator is included as an
        email recipient.
      </Paragraph>
    </StepContentDiv>
  );
}
