import styled from "styled-components";

interface ProgressSpanProps {
  size?: number;
}

export const ProgressSpan = styled.span<ProgressSpanProps>`
  font-size: ${(props) =>
    props.size ? props.size : props.theme.typography.fontSizes.medium}px;
  padding-top: 12px;
`;
