import styled from "styled-components";

export const ContextMenuHeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 48px;
  font-size: ${(props) => props.theme.typography.fontSizes.large}px;
  font-weight: 700;
  color: ${(props) => props.theme.palettes.grayscale.dark};
`;
