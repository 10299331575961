import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../src/app/hooks/hooks";
import { useLazyGetCategoryIsApprovalQuery } from "../../apis/categoriesListApi";
import { ErrorDisplay } from "../../app/errorHandling/ErrorDisplay";
import { useDocumentEditedMode } from "../../app/hooks/document/useDocumentEditedMode";
import usePrompt from "../../app/hooks/usePrompt";
import {
  selectIsUserUnauthorizedToAccessMetadata,
  selectLogoutPrompt,
  selectUsePromptForEditedDocument,
  setIsUserUnauthorizedToAccessMetadata,
  setLogoutPrompt,
  setUsePrompt,
} from "../../app/slices/documentDetailsSlice";
import {
  selectCurrentDocumentMainCategory,
  setIsApprovalCategory,
} from "../../app/slices/documentMetadataSlice";
import {
  clearAttachmentInfo,
  setLastFetchedAttachmentId,
  setLastFetchedUploadId,
} from "../../app/slices/fileUploadSlice";
import { Footer } from "../footer/Footer";
import { ContextMenuHeader } from "../header/ContextMenu/ContextMenuHeader";
import { Document } from "./Document";
import { DetailsDiv } from "./SC/DetailsDiv";

export function Details() {
  const dispatch = useAppDispatch();
  const [isError, setIsError] = useState(false);
  const mainCategory = useAppSelector(selectCurrentDocumentMainCategory);
  const logoutPrompt = useAppSelector(selectLogoutPrompt);
  const { documentIsEdited } = useDocumentEditedMode();
  const usePromptForEditedDocument = useAppSelector(
    selectUsePromptForEditedDocument
  );

  usePrompt(
    "All changes will be lost. Are you sure you want to leave?",
    documentIsEdited && usePromptForEditedDocument && !logoutPrompt
  );
  const [
    getCategoryIsApproval,
    { isError: categoryIsApprovalError, data: isApprovalCategory },
  ] = useLazyGetCategoryIsApprovalQuery();

  useEffect(() => {
    if (mainCategory) {
      void getCategoryIsApproval(mainCategory.cid, true);
    }
  }, [getCategoryIsApproval, mainCategory]);

  useEffect(() => {
    if (isApprovalCategory !== undefined) {
      dispatch(setIsApprovalCategory(isApprovalCategory));
    } else {
      dispatch(setIsApprovalCategory(false));
    }
  }, [dispatch, isApprovalCategory]);

  useEffect(() => {
    dispatch(setIsUserUnauthorizedToAccessMetadata(false));
    dispatch(setLastFetchedAttachmentId(""));
    dispatch(setLastFetchedUploadId(""));
    dispatch(clearAttachmentInfo(undefined));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setUsePrompt(true));
      dispatch(setLogoutPrompt(false));
    };
  }, [dispatch]);

  const isUserUnauthorized = useAppSelector(
    selectIsUserUnauthorizedToAccessMetadata
  );

  let content = <Document setIsError={setIsError} />;

  if (isUserUnauthorized) {
    content = (
      <ErrorDisplay
        errorId={"screen-for-forbidden"}
        showDash={false}
        errorMessageTitle="Something went wrong"
        errorDescription="Document does not exist or you have no access to open it"
      />
    );
  }

  if (categoryIsApprovalError) {
    content = (
      <ErrorDisplay
        errorId={"details-is-approval"}
        showReloadButton={true}
        errorMessageTitle="Something went wrong"
        errorDescription="Categories approval loading failed"
        showDash={false}
      />
    );
  }

  return (
    <DetailsDiv id="details-div">
      {!isUserUnauthorized && !categoryIsApprovalError && (
        <ContextMenuHeader useLoader isError={isError} />
      )}
      {content}
      <Footer />
    </DetailsDiv>
  );
}
