import styled from "styled-components";

interface TitleSpanProps {
  fontSize?: number;
}

export const TitleSpan = styled.span<TitleSpanProps>`
  font-weight: 500;
  padding-bottom: 6px;
  font-size: ${(props) => 
    props.fontSize 
      ? `${props.fontSize}px` 
      : `${props.theme.typography.fontSizes.headerT2}px`
  };
  flex-grow: 1;
`;
