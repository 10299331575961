import { AccordionSummary, AccordionSummaryProps } from "@mui/material";
import styled from "styled-components";

export const SectionAccordionSummary = styled(
  (props: AccordionSummaryProps) => <AccordionSummary {...props} />
)`
  &.MuiButtonBase-root {
    height: 36px;
    min-height: 36px;
    padding: 0 8px 2px 8px;
  }

  .MuiAccordionSummary-content {
    margin: 0px;
  }
`;
