import { GridComparatorFn, GridFilterOperator } from "@mui/x-data-grid";
import {
  PublisherRole,
  PublisherRoles,
  PublisherRoleType,
} from "../../../models/publisherRoles";
import { RoleTypeInput } from "../tabs/categoryTab/publishers/TableControl/RoleTypeInput";
import { DirectPublisherCategory } from "../../../models/directPublisherCategory";

export const rolesComparer: GridComparatorFn<PublisherRole> = (v1, v2) =>
  v1.roleType
    .toLocaleLowerCase()
    .localeCompare(v2.roleType.toLocaleLowerCase());

export const getRoleTypeOperator = (
  roleTypes?: PublisherRoleType[]
): GridFilterOperator<PublisherRoleType[], PublisherRole>[] => {
  return [
    {
      label: "In",
      value: "In",
      getApplyFilterFn: (filterItem) => {
        const roles = filterItem.value as PublisherRoleType[] | undefined;
        if (roles === undefined) {
          return () => false;
        }

        return (value) => {
          return roles.includes(value.roleType);
        };
      },
      InputComponent: RoleTypeInput,
      InputComponentProps: { roleTypes: roleTypes },
    },
  ];
};

export const gridWrapOperator = (
  operator: GridFilterOperator,
  extractValue: (
    value: PublisherRoles | DirectPublisherCategory | undefined
  ) => string | undefined
) => {
  const getApplyFilterFn: GridFilterOperator["getApplyFilterFn"] = (
    filterItem,
    column
  ) => {
    const innerFilterFn = operator.getApplyFilterFn(filterItem, column);
    if (!innerFilterFn) {
      return innerFilterFn;
    }

    return (value, row, col, apiRef) => {
      const extracted = extractValue(
        value as PublisherRoles | DirectPublisherCategory | undefined
      );

      if (extracted) {
        return innerFilterFn(extracted, row, col, apiRef);
      }

      return innerFilterFn(value, row, col, apiRef);
    };
  };

  return {
    ...operator,
    getApplyFilterFn,
  };
};
