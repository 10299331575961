import styled from "styled-components";

export const SectionIconsDiv = styled.div`
  display: flex;
  height: 30px;
  gap: 12px;
  flex-direction: row;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
`;
