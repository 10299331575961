import styled from "styled-components";

interface DocumentItemsDivProps {
  width?: number;
}

export const DocumentItemsDiv = styled.div<DocumentItemsDivProps>`
  display: flex;
  flex-direction: column;
  align-self: baseline;
  height: auto;
  width: ${(props) => (props.width ? props.width + "px" : "100%")};
  overflow-y: overlay;
  overflow-x: hidden;
  padding-left: 0px;
  padding-right: 8px;
  padding-top: 0px;
  background-color: ${(props) => props.theme.palettes.grayscale.lightest};
`;
