import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function OverwriteStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        This action overwrites the scheduled actions on a document.
      </Paragraph>
    </StepContentDiv>
  );
}
