import { Stack } from "@mui/material";
import {
  GetApplyQuickFilterFn,
  getGridStringOperators,
  GridColDef,
  GridColumnVisibilityModel,
  GridComparatorFn,
  GridFilterModel,
  GridRenderCellParams,
  GridRowsProp,
  GridSlots,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../app/hooks/hooks";
import {
  selectPublishersColumnVisibility,
  selectPublishersDensity,
  selectPublishersPageSize,
  selectPublishersRoles,
  setPublishersColumnVisibility,
  setPublishersDensity,
  setPublishersPageSize,
} from "../../../../../../app/slices/publishersListSlice";
import {
  PublisherRole,
  PublisherRoles,
  PublisherRoleType,
} from "../../../../../../models/publisherRoles";
import { RoleInCategory } from "../../../../../../models/rolesInCategory";
import {
  getRoleTypeOperator,
  gridWrapOperator,
  rolesComparer,
} from "../../../../helpers/dataGridHelpers";
import { findRole } from "../helpers/rolesHelper";
import { usePublishersRoles } from "../helpers/usePublishersRoles";
import { Loader } from "./Loader";
import { NoResults } from "./NoResults";
import { OnError } from "./OnError";
import { PublisherInfo } from "./PublisherInfo";
import { StyledDataGrid } from "./SC/StyledDataGrid";
import { StyledGridColumnsPanel } from "./SC/StyledGridColumnsPanel";
import { StyledGridFilterPanel } from "./SC/StyledGridFilterPanel";
import { RoleCell } from "./TableCell/RoleCell";
import { TableToolbar } from "./TableToolbar";
import { CurrentSelfHelp } from "../../../../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../../../../models/selfHelp/selfHelpSection";
import { SectionName } from "../../../../../../app/slices/models/documentDetailsModels";

interface PublishersTableProps {
  selectedCategoryId: string;
}

enum GridColumns {
  UserInfo = "User Info",
  Publisher = "Publisher",
  SuperUser = "Super User",
  Approver = "Approver",
  Owner = "Owner",
}

const emailComparer: GridComparatorFn<PublisherRoles> = (v1, v2) =>
  v1.email.toLocaleLowerCase().localeCompare(v2.email.toLocaleLowerCase());

const getApplyQuickFilterFnForUser: GetApplyQuickFilterFn<
  any, // eslint-disable-line
  PublisherRoles
> = (value) => {
  if (!value) {
    return null;
  }

  return (cellValue) => {
    const search = (value as string | undefined)?.toLocaleLowerCase();

    if (search) {
      return (
        cellValue.email.toLocaleLowerCase().includes(search) ||
        cellValue.name.toLocaleLowerCase().includes(search)
      );
    }

    return false;
  };
};

const roleColumnNames = Object.values(GridColumns).filter(
  (x) => x !== GridColumns.UserInfo
);

export default function PublishersTable(props: PublishersTableProps) {
  const dispatch = useAppDispatch();
  const pageSize = useAppSelector(selectPublishersPageSize);
  const density = useAppSelector(selectPublishersDensity);
  const columnVisibilityModel = useAppSelector(
    selectPublishersColumnVisibility
  );
  const { fetchPublisherRoles, isLoadingPublishers, isPublishersError } =
    usePublishersRoles(props.selectedCategoryId);
  const publishersRoles = useAppSelector(selectPublishersRoles);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [currentFilterField, setCurrentFilterField] = useState(
    GridColumns.UserInfo as string
  );
  const [previousFilterModels, setPreviousFilterModels] = useState<
    GridFilterModel[]
  >([]);

  useEffect(() => {
    const gridFilterModels: GridFilterModel[] = [];

    for (const columnName of roleColumnNames) {
      gridFilterModels.push({
        items: [
          {
            field: columnName,
            operator: "In",
            value: [
              PublisherRoleType.Assigned,
              PublisherRoleType.Inherited,
              PublisherRoleType.NotAssigned,
            ],
          },
        ],
      });
    }

    setPreviousFilterModels(gridFilterModels);
  }, []);

  useEffect(() => {
    if (!columnVisibilityModel) {
      const newColumnVisibilityModel: GridColumnVisibilityModel = {};

      for (const columnName of roleColumnNames) {
        newColumnVisibilityModel[columnName] = true;
      }
      newColumnVisibilityModel[GridColumns.UserInfo] = true;
      dispatch(setPublishersColumnVisibility(newColumnVisibilityModel));
    }
  }, [columnVisibilityModel, dispatch]);

  const columns: GridColDef[] = useMemo(() => {
    const columns: GridColDef[] = [
      {
        field: GridColumns.UserInfo,
        headerName: GridColumns.UserInfo,
        renderCell: (
          params: GridRenderCellParams<PublisherRoles, PublisherRoles>
        ) => (
          <PublisherInfo
            email={params.value?.email ?? ""}
            name={params.value?.name ?? ""}
          />
        ),
        filterOperators: getGridStringOperators().map((operator) =>
          gridWrapOperator(operator, (value) => {
            const publisher = value as PublisherRoles | undefined;

            if (publisher) {
              return publisher.email;
            }

            return undefined;
          })
        ),
        flex: 1.5,
        sortComparator: emailComparer,
        getApplyQuickFilterFn: getApplyQuickFilterFnForUser,
        hideable: false,
      },
    ];

    for (const columnName of roleColumnNames) {
      columns.push({
        field: columnName,
        headerName: columnName,
        renderCell: (
          params: GridRenderCellParams<PublisherRole, PublisherRole>
        ) => {
          const publisherRole = params.value;
          if (publisherRole) {
            return <RoleCell {...publisherRole} />;
          }

          return <></>;
        },
        filterOperators: getRoleTypeOperator(),
        flex: 1,
        sortComparator: rolesComparer,
      });
    }

    return columns;
  }, []);

  const rows: GridRowsProp = useMemo(() => {
    let gridProps: GridRowsProp = [];

    if (!publishersRoles) {
      return gridProps;
    }

    const categoryRoles = Object.values(RoleInCategory).filter(
      (x) => x !== RoleInCategory.ServiceDocumentsEditor
    );
    for (const [i, row] of publishersRoles.entries()) {
      let categoryRoleIndex = 0;
      const rowModel: GridValidRowModel = {
        id: i,
      };
      rowModel[GridColumns.UserInfo] = row;

      for (const columnName of roleColumnNames) {
        rowModel[columnName] = findRole(
          categoryRoles[categoryRoleIndex++],
          row.roles,
          props.selectedCategoryId
        );
      }
      gridProps = gridProps.concat(rowModel);
    }

    return gridProps;
  }, [publishersRoles, props.selectedCategoryId]);

  const onFilterModelChange = (newFilterModel: GridFilterModel) => {
    if (
      newFilterModel.items.length === 1 &&
      newFilterModel.items[0].field !== currentFilterField
    ) {
      const newFilter = newFilterModel.items[0];
      setFilterModel(
        previousFilterModels.find(
          (x) => x.items.length === 1 && x.items[0].field === newFilter.field
        ) ?? newFilterModel
      );
      setPreviousFilterModels((prev) =>
        prev
          .filter(
            (x) =>
              x.items.length === 1 && x.items[0].field !== currentFilterField
          )
          .concat(filterModel)
      );
      setCurrentFilterField(newFilter.field);
    } else {
      setFilterModel(newFilterModel);

      if (newFilterModel.items.length > 0) {
        setCurrentFilterField(newFilterModel.items[0].field);
      }
    }
  };

  if (isPublishersError) {
    return <OnError refreshFn={fetchPublisherRoles} />;
  }

  if (isLoadingPublishers) {
    return <Loader />;
  }

  if (
    !publishersRoles ||
    publishersRoles.length === 0 ||
    props.selectedCategoryId === ""
  ) {
    return <NoResults />;
  }

  return (
    <StyledDataGrid
      disableRowSelectionOnClick
      rows={rows}
      columns={columns}
      filterModel={filterModel}
      onFilterModelChange={onFilterModelChange}
      onPaginationModelChange={(newModel) =>
        dispatch(setPublishersPageSize(newModel.pageSize))
      }
      onDensityChange={(newDensity) =>
        dispatch(setPublishersDensity(newDensity))
      }
      density={density}
      initialState={{
        pagination: { paginationModel: { pageSize: pageSize } },
        sorting: {
          sortModel: [
            {
              field: GridColumns.UserInfo,
              sort: "asc",
            },
          ],
        },
      }}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(newModel) => {
        dispatch(setPublishersColumnVisibility(newModel));
      }}
      slots={{
        noRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No results found.
          </Stack>
        ),
        toolbar: TableToolbar,
        columnsPanel: StyledGridColumnsPanel as GridSlots["columnsPanel"],
        filterPanel: StyledGridFilterPanel,
      }}
      slotProps={{ toolbar: { displayQuickFilter: true, currentSelfHelp: CurrentSelfHelp.PublishersPublisherList, selfHelpPage: SelfHelpPage.Publishers, sectionName: SectionName.PublishersPublisherList } }}
      pageSizeOptions={[10, 25, 50, 100]}
      localeText={{
        toolbarQuickFilterPlaceholder: "Search users...",
      }}
    />
  );
}
