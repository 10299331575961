import styled from "styled-components";

interface BreadcrumbListSeparatorItemProps {
  $isVisible: boolean;
}

export const BreadcrumbListSeparatorItem = styled.li<BreadcrumbListSeparatorItemProps>`
  font-size: ${(props) => props.theme.typography.fontSizes.small}px;
  display: ${(props) => (props.$isVisible ? "flex" : "none")};
  user-select: none;
  margin-left: 8px;
  margin-right: 8px;
`;
