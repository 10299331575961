import CloseIcon from "@mui/icons-material/Close";
import { DialogActions, DialogContent } from "@mui/material";
import { IconButtonControl } from "../../../controls/Buttons/IconButtonControl";
import { DialogButton } from "../../../controls/Dialog/SC/DialogButton";
import { StyledDialog } from "../../../controls/Dialog/SC/StyledDialog";
import { StyledDialogTitle } from "../../../controls/Dialog/SC/StyledDialogTitle";
import { IconButtonControlDiv } from "../contextMenu/SC/IconButtonControlDiv";
import { StyledDialogContentText } from "../selfHelpPopup/SC/StyledDialogContentText";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";

export interface ScheduledActionInfoPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onDeleteAction: () => void;
  actionText?: string;
}

export function ScheduledActionInfoPopup(props: ScheduledActionInfoPopupProps) {
  return (
    <StyledDialog
      id={"set-scheduled-dialog"}
      open={props.isOpen}
      onClose={props.onClose}
    >
      <StyledDialogTitle id={"set-scheduled-dialog-title"}>
        {"Changing document with scheduled action"}
        <IconButtonControlDiv>
          <IconButtonControl
            id={"set-scheduled-dialog-close-button"}
            popupText={TooltipMsg.Close}
            onClick={props.onClose}
            icon={<CloseIcon fontSize="inherit" />}
          />
        </IconButtonControlDiv>
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText id="set-scheduled-dialog-description">
          This document revision has <span style={{ fontWeight: "bold" }}>{props.actionText}</span> action scheduled. If you proceed with this change, the action will be removed.
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogButton id={"set-scheduled-dialog-proceed-button"} color="primary" onClick={props.onDeleteAction} autoFocus>
          {"Proceed"}
        </DialogButton>
        <DialogButton id={"set-scheduled-dialog-cancel-button"} color="secondary" onClick={props.onClose}>
          {"Cancel"}
        </DialogButton>
      </DialogActions>
    </StyledDialog>
  );
}
