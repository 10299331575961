import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStepOne() {
    return (
        <StepContentDiv>
            <Paragraph>
                The security level indicates the access level to the document and there are a few combinations available with different impacts on end user&apos;s access.
            </Paragraph>
        </StepContentDiv>
    );
}
