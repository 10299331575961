import WarningIcon from "@mui/icons-material/Warning";
import styled from "styled-components";

interface StyledWarningIconProps {
  $color: string | undefined;
}

export const StyledWarningIcon = styled(WarningIcon)<StyledWarningIconProps>`
  color: ${(props) => props.$color};
  cursor: default;
`;
