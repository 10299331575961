import React from "react";
import { CountryAvailabilityType } from "../../../../models/documentDetails/countryMarketAvailability";
import { ToggleButtons } from "../../inputs/ToggleButtons";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";

export interface CountryAvailabilityTypeSectionProps {
  isDisabled: boolean;
  isEditable: boolean;
  isEdited: boolean;
  countryAvailabilityType: CountryAvailabilityType;
  notEditableMessage?: TooltipMsg;
  onCountryAvailabilityTypeChange: (newValue: CountryAvailabilityType) => void;
}

function CountryAvailabilityTypeSection(
  props: CountryAvailabilityTypeSectionProps
) {
  const handleAvailabilityTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    selected: string | null
  ) => {
    if (selected) {
      const newValue =
        CountryAvailabilityType[selected as CountryAvailabilityType];
      props.onCountryAvailabilityTypeChange(newValue);
    }
  };

  return (
    <ToggleButtons
      id="country-availability"
      labelText="Country availability"
      isEdited={props.isEdited}
      value={props.countryAvailabilityType}
      notEditableMessage={props.notEditableMessage}
      values={[
        {
          key: CountryAvailabilityType.AllCountries,
          value: "All Countries",
        },
        {
          key: CountryAvailabilityType.AvailableInCountries,
          value: "Available In Countries",
        },
        {
          key: CountryAvailabilityType.NotAvailableInCountries,
          value: "Not Available In Countries",
        },
      ]}
      handleInputChange={handleAvailabilityTypeChange}
      isEditable={props.isEditable && !props.isDisabled}
    />
  );
}

export default CountryAvailabilityTypeSection;
