import styled from "styled-components";

interface ContextMenuButtonGroupDivProps {
  $paddingTop?: number;
}

export const ContextMenuButtonGroupDiv = styled.div<ContextMenuButtonGroupDivProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 12px;
  padding-left: 8px;
  padding-top: ${(props) =>
    props.$paddingTop ? props.$paddingTop + "px" : ""};
`;
