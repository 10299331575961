import { Box } from "@mui/material";
import { HTMLAttributes } from "react";
import { AutoCompleteInput } from "../../../../controls/Autocomplete/AutoCompleteInput";
import { ListItemSpan } from "../../../../controls/Autocomplete/SC/ListItemSpan";
import { StyledWarningIcon } from "../../../../controls/StyledComponents/StyledWarningIcon";
import { TooltipTop } from "../../../../controls/Tooltips/ToolipTop";
import { ComboBoxListItem } from "../../../../models/autocompleteValue";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";

interface FiltersAutoCompleteProps {
  id: string;
  pillsClassName: string;
  label: string;
  items: ComboBoxListItem[] | undefined;
  missingValueWarningText: string;
  dataQualityWarningText?: string;
  onChangeHandler: (values: ComboBoxListItem[]) => void;
  isLoading: boolean;
  selected: ComboBoxListItem[];
  isDictionaryDataFetchingError: boolean;
  onRefreshHandler: () => void;
}

export function FiltersAutoComplete(props: FiltersAutoCompleteProps) {
  const getMenuItem = (
    props: HTMLAttributes<HTMLLIElement>,
    option: unknown
  ) => {
    const item = option as ComboBoxListItem;
    return (
      <Box component="li" {...props}>
        <ListItemSpan>
          {item.hasDataQualityWarning && (
            <TooltipTop title={TooltipMsg.AutoCompleteQualityIssue}>
              <StyledWarningIcon margin={2} fontSize="inherit" />
            </TooltipTop>
          )}
          {item.value}
        </ListItemSpan>
      </Box>
    );
  };

  return (
    <AutoCompleteInput
      $paddingTop={8}
      id={props.id}
      pillsClassName={props.pillsClassName}
      label={props.label}
      multiple
      items={props.items ?? []}
      missingValueWarningText={props.missingValueWarningText}
      dataQualityWarningText={props.dataQualityWarningText}
      onChangeHandler={props.onChangeHandler}
      isLoading={props.isLoading}
      isEditable={true}
      selected={props.selected}
      isDictionaryDataFetchingError={props.isDictionaryDataFetchingError}
      onRefreshHandler={props.onRefreshHandler}
      renderOption={getMenuItem}
    />
  );
}
