import { List } from "@mui/material";
import styled from "styled-components";

export const StyledList = styled(List)`
  &.MuiList-root {
    position: relative;
    overflow: auto;
    word-wrap: break-word;
    height: calc(-290px + 100vh);
  }
`;
