import styled from "styled-components";

export const InfoBoxDiv = styled.div<{ padding?: string }>`
  padding: ${({ padding = "80px 16% 0 16%" }) => padding};

  @media only screen and (max-width: 600px) {
    padding: 80px 8% 0 8%;
  }

  .paragraph {
    padding-top: 5px;
  }
`;
