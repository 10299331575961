import { useCallback, useMemo } from "react";
import { isSecurityLevelNonLimited } from "../../../../../app/helpers/securityLevelHelper";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { selectCurrentDocumentSecurityLevel } from "../../../../../app/slices/documentMetadataSlice";
import { ComboBoxListItem } from "../../../../../models/autocompleteValue";
import { ValidationStatus } from "../../../../../models/validationRule";
import {
  ConditionValidationResult,
  ConditionValidationRules,
} from "../../common/ConditionValidationRules";
import SectionValidation from "../../common/SectionValidation";
import {
  selectIsPublishValidationOn,
  setAccessGroupsValidationStatus,
} from "../../../../../app/slices/documentMetadataValidationSlice";

export interface SecuritySectionValidationProps {
  selectedAccessGroups: ComboBoxListItem[];
  isLoadingAccessGroups: boolean;
}

function AccessGroupsValidation(props: SecuritySectionValidationProps) {
  const dispatch = useAppDispatch();
  const isPublishValidationOn = useAppSelector(selectIsPublishValidationOn);
  const securityLevelDocumentMetadata = useAppSelector(
    selectCurrentDocumentSecurityLevel
  );

  const validationRules: ConditionValidationRules = useMemo(() => {
    if (
      props.isLoadingAccessGroups ||
      isSecurityLevelNonLimited(securityLevelDocumentMetadata)
    ) {
      return { rules: [] };
    }

    return {
      rules: [
        () => ({
          isError: props.selectedAccessGroups.length === 0,
          errorMessage: "Access groups missing for Limited security level.",
          status: isPublishValidationOn
            ? ValidationStatus.error
            : ValidationStatus.notVisibleError,
        }),
        () => ({
          isError:
            props.selectedAccessGroups.length > 0 &&
            props.selectedAccessGroups.every((x) => x.isWarning),
          errorMessage:
            "All selected access groups are invalid for selected Main Category.",
          status: ValidationStatus.error,
        }),
        () => ({
          isError:
            props.selectedAccessGroups.length > 0 &&
            !!props.selectedAccessGroups.find((x) => x.isWarning),
          errorMessage:
            "Highlighted access groups are not allowed for selected Main Category. They will be removed when document is saved/published.",
          status: ValidationStatus.warning,
        }),
      ],
    };
  }, [
    props.isLoadingAccessGroups,
    props.selectedAccessGroups,
    securityLevelDocumentMetadata,
    isPublishValidationOn,
  ]);

  const onValidationHandle = useCallback(
    (validationResult?: ConditionValidationResult) => {
      dispatch(
        setAccessGroupsValidationStatus(
          validationResult ? validationResult.status : ValidationStatus.success
        )
      );
    },
    [dispatch]
  );

  return (
    <SectionValidation
      rules={validationRules}
      onValidationHandle={onValidationHandle}
    />
  );
}

export default AccessGroupsValidation;
