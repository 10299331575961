import { AccordionSummary } from "@mui/material";
import styled from "styled-components";

interface StyledAccordionSummaryProps {
  $paddingLeft: number;
  $minHeight: string;
  $maxHeight: string;
}

export const StyledAccordionSummary = styled(
  AccordionSummary
)<StyledAccordionSummaryProps>`
  margin: 0 !important;
  padding: 0 !important;
  min-height: ${(props) => props.$minHeight} !important;
  max-height: ${(props) => props.$maxHeight} !important;
  & .MuiAccordionSummary-content {
    margin: 0;
    padding: 0;
    position: unset !important;
    padding-left: ${(props) => props.$paddingLeft}px;
  }
`;
