import { filter } from "lodash";
import { TextFieldInput } from "../../../../controls/TextField/TextFieldInput";
import { SearchInputDiv } from "./SC/SearchInputDiv";
import { ListItems } from "../../List/ListControl";

interface SearchControlProps {
  id: string;
  placeholder: string;
  baseValues?: ListItems[];
  changeHandler?: React.Dispatch<React.SetStateAction<ListItems[]>>;
}

export function SearchControl(props: SearchControlProps) {
  const filterValue = (searchText: string) => {
    if (props.changeHandler && props.baseValues && searchText) {
      const filteredValues = filter(props.baseValues, function (a) {
        return a.name
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase());
      });

      props.changeHandler(filteredValues);
    } else if (props.changeHandler && props.baseValues) {
      props.changeHandler(props.baseValues);
    }
  };

  return (
    <SearchInputDiv id="search-input-div">
      <TextFieldInput
        id={props.id + "-search-textfield"}
        label=""
        placeholder={props.placeholder}
        onClick={(searchText: string) => filterValue(searchText)}
        initialValue={""}
        trimEntries={true}
      />
    </SearchInputDiv>
  );
}
