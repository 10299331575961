import { useCallback, useEffect, useRef } from "react";
import { useLazyGetPublisherCategoriesQuery } from "../../apis/publisherCategoriesApi";
import { rootCategoryId } from "../slices/navigationSlice";
import {
  clearPublisherCategories,
  selectCategoryById,
  updatePublisherCategories,
} from "../slices/publisherCategoryTreeSlice";
import { useAppDispatch, useAppSelector } from "./hooks";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

export interface FetchPublisherCategoriesProps {
  query: string;
  isOpen: boolean;
  minQueryLength?: number;
  visibleQuery?: string;
}

export interface PublisherCategoriesFetchResponse {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  fetch: () => void;
  cancel: () => void;
  error?: FetchBaseQueryError | SerializedError;
}

const initialCategoriesArgs = {
  maxDepth: 2,
  parentCategoryId: "Root",
  includeHighestParent: true,
  parentDepth: 1,
};

export const publishersAbortReason = "Publishers request cancelled";

export function useInitialPublisherCategoriesFetch({
  query,
  isOpen,
}: FetchPublisherCategoriesProps): PublisherCategoriesFetchResponse {
  const dispatch = useAppDispatch();
  const rootCategory = useAppSelector((state) =>
    selectCategoryById(state, rootCategoryId)
  );
  const abortControllerRef = useRef<AbortController>(new AbortController());

  const [
    getPublisherCategories,
    { isError, isFetching, isUninitialized, isSuccess, error },
  ] = useLazyGetPublisherCategoriesQuery();

  const cancel = useCallback(() => {
    abortControllerRef.current.abort(publishersAbortReason);
    abortControllerRef.current = new AbortController();
  }, []);

  const fetchCategories = useCallback(() => {
    if (rootCategory === undefined && query.length === 0 && isOpen) {
      getPublisherCategories(
        { ...initialCategoriesArgs, signal: abortControllerRef.current.signal },
        true
      )
        .unwrap()
        .then((response) => {
          dispatch(updatePublisherCategories(response));
        })
        .catch(() => {
          dispatch(clearPublisherCategories());
        });
    }
  }, [getPublisherCategories, rootCategory, query, isOpen, dispatch]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return {
    isLoading: isFetching || isUninitialized,
    isError,
    isSuccess,
    fetch: fetchCategories,
    cancel,
    error,
  };
}
