import { createStep } from "../../../helpers/selfHelpBuilder";
import { AllButtonStep6 } from "./AllButtonStep6";

import { ColumnButtonStep1 } from "./ColumnButtonStep1";
import { DensityButtonStep5 } from "./DensityButtonStep5";
import { FiltersButtonStep2 } from "./FiltersButtonStep2";
import { FiltersButtonStep3 } from "./FiltersButtonStep3";
import { FiltersButtonStep4 } from "./FiltersButtonStep4";
import { PagingStep12 } from "./PagingStep12";
import { PublisherListStep10 } from "./PublisherListStep10";
import { PublisherListStep11 } from "./PublisherListStep11";
import { PublisherListStep8 } from "./PublisherListStep8";
import { PublisherListStep9 } from "./PublisherListStep9";
import { SearchUserStep7 } from "./SearchUserStep7";

export const publisherListSteps = () => {
  return [
    createStep("#data-grid-columns-button", <ColumnButtonStep1 />, 520, "bottom"),
    createStep("#data-grid-filter-button", <FiltersButtonStep2 />, 520, "bottom"),
    createStep("#data-grid-filter-button", <FiltersButtonStep3 />, 520, "bottom"),
    createStep("#data-grid-filter-button", <FiltersButtonStep4 />, 520, "bottom"),
    createStep("#data-grid-density-button", <DensityButtonStep5 />, 520, "right"),
    createStep("#data-grid-buttons", <AllButtonStep6 />, 520, "bottom"),
    createStep("#data-grid-quick-filter", <SearchUserStep7 />, 520, "left"),
    createStep(".MuiDataGrid-columnHeaders", <PublisherListStep8 />, 520, "bottom"),
    createStep(".MuiDataGrid-columnHeaders", <PublisherListStep9 />, 520, "bottom"),
    createStep(".MuiDataGrid-columnHeaders", <PublisherListStep10 />, 520, "bottom"),
    createStep(".MuiDataGrid-columnHeaders", <PublisherListStep11 />, 520, "bottom"),
    createStep(".MuiTablePagination-root", <PagingStep12 />, 520, "top"),
  ];
};
