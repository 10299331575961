import styled from "styled-components";
import { DocumentsColumnType } from "../../../../../models/documentList/types/DocumentColumnType";

export interface DocumentsListHeaderCellDivProps {
  width: number;
  $minWidth: number;
  $maxWidth: number;
  $isVisible: boolean;
  type: DocumentsColumnType;
  $isSorted: boolean;
}

function getVisibility(isVisible: boolean) {
  if (isVisible) return "flex";

  return "none";
}

function getBorder(isSorted: boolean, color: string) {
  if (isSorted) return "2px solid " + color;
  return "none";
}

function getMaxWidth(maxWidth: number | undefined) {
  if (maxWidth) {
    return maxWidth + "px";
  } else {
    return "unset";
  }
}

export const DocumentsListHeaderCellDiv = styled.div<DocumentsListHeaderCellDivProps>`
  height: 100%;
  width: ${(props) => props.width}px;
  min-width: ${(props) => props.$minWidth}px;
  max-width: ${(props) => getMaxWidth(props.$maxWidth)};
  display: ${(props) => getVisibility(props.$isVisible)};
  align-items: center;
  overflow: hidden;
  border-bottom: ${(props) =>
    getBorder(props.$isSorted, props.theme.palettes.primary.main)};
`;
