import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palettes.grayscale.darkest};
  font-size: ${(props) =>
    props.theme.typography.fontSizes.splashScreenMessage}px;
  :hover {
    color: ${(props) => props.theme.palettes.primary.main};
  }
`;
