import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import {
  selectIsNavigationMinimized,
  selectTagsSearch,
  setTagsSearch,
} from "../../../../app/slices/tagsViewSlice";
import { TextFieldInput } from "../../../../controls/TextField/TextFieldInput";
import { TagTypeSearchDiv } from "./SC/TagTypeSearchDiv";

export function TagTypeSearch() {
  const dispatch = useAppDispatch();
  const tagsSearch = useAppSelector(selectTagsSearch);
  const isMinimized = useAppSelector(selectIsNavigationMinimized);

  if (isMinimized) {
    return <></>;
  }

  return (
    <TagTypeSearchDiv id="tag-type-search-input-div">
      <TextFieldInput
        id="tag-type-search-textfield"
        label=""
        placeholder="Type Tag name to search..."
        onClick={(text: string) => dispatch(setTagsSearch(text))}
        initialValue={tagsSearch}
        trimEntries={false}
      />
    </TagTypeSearchDiv>
  );
}
