import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function DateOfActionStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        In this field, the action date can be determined. Several default
        options are available, but a specific date can also be set manually in
        the Action date section.
      </Paragraph>
    </StepContentDiv>
  );
}
