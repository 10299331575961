import { Step } from "react-joyride";
import { createStep, isColumnVisible } from "../../helpers/selfHelpBuilder";
import { ActionColumnStep } from "./DocumentList/ActionColumnStep";
import { DocumentDetailsStep } from "./DocumentList/DocumentDetailsStep";
import { DocumentGroupStep } from "./DocumentList/DocumentGroupStep";
import { DocumentListHeaderStep } from "./DocumentList/DocumentListHeaderStep";
import { DocumentListItemStep } from "./DocumentList/DocumentListItemStep";
import { DocumentListStep } from "./DocumentList/DocumentListStep";
import { InfoColumnStep } from "./DocumentList/InfoColumnStep";
import { PaginationStep } from "./DocumentList/PaginationStep";
import { SecurityLevelColumnStep } from "./DocumentList/SecurityLevelColumnStep";
import { SplitterStep } from "./DocumentList/SplitterStep";
import { DocumentsColumnType } from "../../../../models/documentList/types/DocumentColumnType";

export const documentsSteps = (
  visibleColumns: DocumentsColumnType[],
  hasAnyDocumentGroupOnView: boolean,
  hasAnySingleDocumentOnView: boolean
): Step[] => {
  const steps: Step[] = [];

  steps.push(
    createStep(".documents-list-items", <DocumentListStep />, 720, "top-start")
  );
  steps.push(
    createStep(
      "#documents-list-header-div",
      <DocumentListHeaderStep />,
      420,
      "bottom"
    )
  );
  steps.push(
    createStep(".document-header-separator", <SplitterStep />, 320, "top")
  );

  if (isColumnVisible(visibleColumns, DocumentsColumnType.SecurityLevel)) {
    steps.push(
      createStep(
        "#documents-list-header-cell-SecurityLevel",
        <SecurityLevelColumnStep />,
        420,
        "bottom"
      )
    );
  } else {
    steps.push(
      createStep(
        ".documents-list-items",
        <SecurityLevelColumnStep />,
        420,
        "top-start"
      )
    );
  }

  if (isColumnVisible(visibleColumns, DocumentsColumnType.Info)) {
    steps.push(
      createStep(
        "#documents-list-header-cell-Info",
        <InfoColumnStep />,
        620,
        "bottom"
      )
    );
  } else {
    steps.push(
      createStep(".documents-list-items", <InfoColumnStep />, 620, "top-start")
    );
  }

  if (hasAnyDocumentGroupOnView) {
    steps.push(
      createStep(
        ".document-list-group-item",
        <DocumentGroupStep />,
        720,
        "bottom-start"
      )
    );
  } else {
    steps.push(
      createStep(
        ".documents-list-items",
        <DocumentGroupStep />,
        720,
        "top-start"
      )
    );
  }

  if (hasAnySingleDocumentOnView || hasAnyDocumentGroupOnView) {
    steps.push(
      createStep(
        ".document-options-div",
        <ActionColumnStep />,
        720,
        "left-start"
      )
    );
  } else {
    steps.push(
      createStep(
        ".documents-list-items",
        <ActionColumnStep />,
        720,
        "top-start"
      )
    );
  }

  if (hasAnySingleDocumentOnView) {
    steps.push(
      createStep(
        ".single-document-list-item",
        <DocumentDetailsStep />,
        720,
        "bottom-start"
      )
    );

    steps.push(
      createStep(
        ".single-document-list-item",
        <DocumentListItemStep />,
        720,
        "bottom-start"
      )
    );
  } else {
    steps.push(
      createStep(
        ".documents-list-items",
        <DocumentDetailsStep />,
        720,
        "top-start"
      )
    );

    steps.push(
      createStep(
        ".documents-list-items",
        <DocumentListItemStep />,
        720,
        "top-start"
      )
    );
  }

  steps.push(
    createStep("#documents-paging-div", <PaginationStep />, 620, "top")
  );
  return steps;
};
