import styled from "styled-components";

export const EmptyPageDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  min-width: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
