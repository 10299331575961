import { Step } from "react-joyride";
import { createStep } from "../../helpers/selfHelpBuilder";
import { CloseStep } from "./ContextMenu/CloseStep";
import { DocumentNumberStep } from "./ContextMenu/DocumentNumberStep";
import { PublishedDateStep } from "./ContextMenu/PublishedDateStep";
import { PublisherStep } from "./ContextMenu/PublisherStep";
import { ResetViewStep } from "./ContextMenu/ResetViewStep";
import { TitleStep } from "./ContextMenu/TitleStep";
import { WorkflowStep } from "./ContextMenu/WorkflowStep";
import { ZoomButtonStep } from "./ContextMenu/ZoomButtonStep";
import { SplitterStep } from "./ContextMenu/SplitterStep";
import { SectionExpandCollapseStep } from "./ContextMenu/SectionExpandCollapseStep";
import { SectionMoveStep } from "./ContextMenu/SectionMoveStep";
import { SectionMaximizeStep } from "./ContextMenu/SectionMaximizeStep";

export const contextMenuSectionSteps = () => {
    const steps: Step[] = [];

    steps.push(createStep("#zoom-button", <ZoomButtonStep />, 520, "auto"));
    steps.push(createStep("#reset-view-button", <ResetViewStep />, 520, "auto"));
    steps.push(
        createStep("#document-details-close-button", <CloseStep />, 520, "auto")
    );
    steps.push(
        createStep(
            "#document-metadata-header-documentnumber-header",
            <DocumentNumberStep />,
            520,
            "auto"
        )
    );
    steps.push(
        createStep(
            "#document-metadata-header-workflow",
            <WorkflowStep />,
            520,
            "auto"
        )
    );
    steps.push(
        createStep(
            "#document-metadata-header-documentTitle",
            <TitleStep />,
            520,
            "auto"
        )
    );
    steps.push(
        createStep(
            "#document-metadata-header-publisher-div",
            <PublisherStep />,
            520,
            "auto"
        )
    );
    steps.push(
        createStep(
            "#document-metadata-header-publishedDate-div",
            <PublishedDateStep />,
            520,
            "auto"
        )
    );

    steps.push(
        createStep(
            "#Identity-move",
            <SectionMoveStep />,
            420,
            "auto"
        )
    );
    steps.push(
        createStep(
            "#Section-Header-Div",
            <SectionMaximizeStep />,
            420,
            "auto"
        )
    );
    steps.push(
        createStep(
            ".MuiAccordionSummary-expandIconWrapper",
            <SectionExpandCollapseStep />,
            420,
            "auto"
        )
    );
    steps.push(
        createStep(
            ".reflex-splitter",
            <SplitterStep />,
            420,
            "auto"
        )
    );
    // steps.push(
    //   createStep("#related-documents-tab", <RelatedDocumentsStep />, 520, "auto")
    // );
    // steps.push(createStep("#history-tab", <HistoryStep />, 520, "auto"));

    return steps;
};
