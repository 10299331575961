import CancelIcon from "@mui/icons-material/Cancel";
import { find, without } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { EllipsisText } from "../../components/documents/documentsList/documentListItem/cells/EllipsisText";
import { FormHelperControl } from "../FormHelper/FormHelperControl";
import { InputDiv } from "../StyledComponents/InputDiv";
import { StyledMuiChips } from "./SC/StyledMuiChips";
import { WordBreakType } from "../../models/documentList/types/WordBreakType";
import { StyledChip } from "../StyledComponents/StyledChip";
import { ComboBoxListItem } from "../../models/autocompleteValue";
import { ValidationRule } from "../../models/validationRule";

interface ChipsInputProps {
  id: string;
  pillsClassName: string;
  isEditable: boolean;
  placeholder: string;
  showDeleteIcons: boolean;
  values: ComboBoxListItem[];
  isEdited: boolean;
  validationRules?: ValidationRule[];
  onChangeHandler: (values: string[]) => void;
}

const splitRegex = new RegExp("[,;]+");

export function ChipsInput(props: ChipsInputProps) {
  const [items, setItems] = useState<string[]>(props.values.map((i) => i.key));

  const [inputValue, setInputValue] = useState<string>("");
  const [isError, setIsError] = useState(false);
  const [helperTexts, setHelperTexts] = useState<React.ReactNode[]>([]);
  const chipRef = useRef<HTMLDivElement>(null);

  const validateNewChipValue = (chipValue: string): boolean => {
    const newValues = chipValue
      .toLocaleLowerCase()
      .split(splitRegex)
      .map((x) => x.trim())
      .filter((x) => x !== "");
    const text: React.ReactNode[] = [];
    let isErrorInner = false;

    if (items.find((x) => newValues.includes(x.toLocaleLowerCase()))) {
      isErrorInner = true;
      text.push("Same product ID has been already added to this document");
    }

    if (new Set(newValues).size !== newValues.length) {
      isErrorInner = true;
      text.push("There are duplicates in the provided product IDs");
    }

    if (
      props.isEditable &&
      props.validationRules &&
      props.validationRules.length > 0
    ) {
      props.validationRules.forEach((rule) => {
        for (const value of newValues) {
          if (!rule.regExp.test(value)) {
            isErrorInner = true;
            text.push(rule.message);
            break;
          }
        }
      });
    }

    setIsError(isErrorInner);
    setHelperTexts(text);

    if (isErrorInner) {
      return false;
    }

    return true;
  };

  const clearValidation = () => {
    setIsError(false);
    setHelperTexts([]);
  };

  const onInputChange = (value: string) => {
    if (!value) {
      clearValidation();
    }

    setInputValue(value);
  };

  const onItemChange = (newValues: string[]) => {
    let lastValues: string[] = [];
    if (newValues.length > 0) {
      lastValues = newValues[newValues.length - 1]
        .split(splitRegex)
        .map((x) => x.trim())
        .filter((x) => x !== "");
    }

    onChangeHandler([...newValues.slice(0, -1), ...lastValues]);
  };

  const onLostFocus = () => {
    clearValidation();
    setInputValue("");
  };

  const onItemDelete = (chipValue: string) => {
    if (props.isEditable) {
      const newValues = without(items, chipValue);
      onChangeHandler(newValues);
    }
  };

  const onChangeHandler = useMemo(() => {
    return props.onChangeHandler;
  }, [props.onChangeHandler]);

  useEffect(() => {
    if (props.values.length === 0) {
      clearValidation();
      setInputValue("");
    }

    setItems(props.values.map((i) => i.key));
  }, [props.values]);

  return (
    <InputDiv onBlur={onLostFocus}>
      <StyledMuiChips
        id={props.id}
        placeholder={props.isEditable ? props.placeholder : ""}
        $isEditable={props.isEditable}
        $idEdited={props.isEdited}
        $showDeleteIcons={props.showDeleteIcons}
        hideClearAll={!props.isEditable || !props.showDeleteIcons}
        size="small"
        value={items}
        onChange={onItemChange}
        onInputChange={onInputChange}
        inputValue={inputValue}
        validate={validateNewChipValue}
        renderChip={(Chip, chipProps) => {
          return (
            <StyledChip
              {...chipProps}
              ref={chipRef}
              className={props.pillsClassName}
              size="small"
              onDelete={() => onItemDelete(chipProps.label)}
              label={
                <EllipsisText
                  width={chipRef.current ? chipRef.current.clientWidth : 0}
                  useTooltip={false}
                  fillIfEmpty={false}
                  content={chipProps.label}
                  wordBreak={WordBreakType.BreakAll}
                />
              }
              deleteIcon={
                props.isEditable && props.showDeleteIcons ? (
                  <CancelIcon />
                ) : (
                  <></>
                )
              }
              onDoubleClick={(event) => event.preventDefault()}
              $isEdited={
                find(props.values, (item) => {
                  return item.key == chipProps.label && item.isEdited;
                })
                  ? true
                  : false
              }
            />
          );
        }}
      />
      <FormHelperControl
        isWarning={false}
        isVisible={props.isEditable}
        isError={isError}
        texts={helperTexts}
      />
    </InputDiv>
  );
}
