import { createApi } from "@reduxjs/toolkit/query/react";
import Country from "../models/country";
import { publishApiBaseQuery } from "./baseQueries";
import { CmaResponse } from "../models/documentDetails/availableCountries";
import { Category } from "../models/documentDetails/documentMetadata";

export const countriesApi = createApi({
  reducerPath: "countriesApi",
  baseQuery: publishApiBaseQuery,
  endpoints: (builder) => ({
    getAllCountries: builder.query<Country[], null>({
      query: () => ({
        url: "Countries",
        method: "GET",
      }),
      // The data will be cached for 1 hour even if the query is not active, because its dictionary data
      keepUnusedDataFor: 3600,
    }),
    getCma: builder.query<CmaResponse, Category[]>({
      query: (categories) => ({
        url: "Countries/Restrictions",
        method: "POST",
        body: categories.map((category) => {
          return {
            cid: category.cid,
            isMain: category.isMain,
          };
        }),
      }),
      // The data will be cached for 1 hour even if the query is not active, because its dictionary data
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const {
  useLazyGetAllCountriesQuery,
  useGetAllCountriesQuery,
  useGetCmaQuery,
  useLazyGetCmaQuery,
} = countriesApi;

export default countriesApi.reducer;
