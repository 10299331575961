import ClearIcon from "@mui/icons-material/Clear";

import { InputAdornment } from "@mui/material";
import { useRegexValidation } from "../../app/hooks/useRegexValidation";
import { FormHelperControl } from "../FormHelper/FormHelperControl";
import { InputDiv } from "../StyledComponents/InputDiv";
import { StyledIconButton } from "../StyledComponents/StyledIconButton";
import { TooltipTop } from "../Tooltips/ToolipTop";
import { StyledTextField } from "./SC/StyledTextField";
import { ReactNode } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { TooltipMsg } from "../Tooltips/TooltipMessages";

interface UncontrolledTextFieldInputProps {
  id: string;
  value: string;
  label: string;
  placeholder?: string;
  validationRegExp?: RegExp;
  validationMsg?: string;
  onClick: () => void;
  onChange: (text: string) => void;
  onClear: () => void;
  icon?: ReactNode;
  iconText?: string;
  autoFocus?: boolean;
}

export function UncontrolledTextFieldInput(
  props: UncontrolledTextFieldInputProps
) {
  const { isError, helperText } = useRegexValidation({
    validationMsg: props.validationMsg,
    validationRegExp: props.validationRegExp,
    inputValue: props.value,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && !isError) {
      props.onClick();
    }
  };

  const handleSearchClick = () => {
    if (!isError) {
      props.onClick();
    }
  };

  return (
    <InputDiv>
      <StyledTextField
        autoFocus={props.autoFocus}
        $isEditable={true}
        placeholder={props.placeholder}
        id={props.id}
        size="small"
        label={props.label}
        error={isError}
        variant="outlined"
        value={props.value}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {props.value && (
                <TooltipTop title={TooltipMsg.Clear}>
                  <StyledIconButton
                    $marginRight={-2}
                    $marginLeft={0}
                    padding={2}
                    onClick={props.onClear}
                  >
                    <ClearIcon />
                  </StyledIconButton>
                </TooltipTop>
              )}
              <TooltipTop
                title={props.iconText ? props.iconText : TooltipMsg.Search}
              >
                <StyledIconButton padding={2} onClick={handleSearchClick}>
                  {props.icon ? props.icon : <SearchIcon />}
                </StyledIconButton>
              </TooltipTop>
            </InputAdornment>
          ),
        }}
      />
      <FormHelperControl
        isWarning={false}
        isError={isError}
        texts={[helperText]}
      />
    </InputDiv>
  );
}
