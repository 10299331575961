import { Button } from "@mui/material";
import styled from "styled-components";

export const StyledEllipsisButton = styled(Button)`
  &.MuiButton-root {
    min-width: 24px;
    width: 24px;
    height: 14px;
    margin: 0px;
    padding: 0px;
    white-space: nowrap;
    color: ${(props) => props.theme.palettes.grayscale.darkest};
    background-color: ${(props) => props.theme.palettes.grayscale.lighter};
  }
  &.MuiButton-root:hover {
    color: ${(props) => props.theme.palettes.primary.main};
    background-color: ${(props) => props.theme.palettes.grayscale.light};
  }
`;
