import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function DeleteDocumentStep2() {
  return (
    <StepContentDiv>
      <Paragraph>
        In this section, permission to change the status of a document to
        “deleted” in the selected category and its subcategories can be granted
        either to super users or to both super users and publishers.
      </Paragraph>
      <Paragraph>The chosen option is highlighted in red.</Paragraph>
    </StepContentDiv>
  );
}
