import { createStep } from "../../../helpers/selfHelpBuilder";
import { ApprovalWorkflowStep1 } from "./ApprovalWorkflowStep1";
import { CategoryWarningStep3 } from "./CategoryWarningStep3";
import { NumberOfDaysStep2 } from "./NumberOfDaysStep2";
import { SectionStep4 } from "./SectionStep4";


export const configurationsSteps = () => {
  return [
    createStep("#cms-approval-workflow-div", <ApprovalWorkflowStep1 />, 520, "left"),
    createStep("#cms-delete-attachment-div", <NumberOfDaysStep2 />, 520, "left"),
    createStep("#cms-category-warning-div", <CategoryWarningStep3 />, 520, "left"),
    createStep("#cms-configurations-paper", <SectionStep4 />, 420, "top"),
  ];
};
