import { useTheme } from "styled-components";
import { TextButtonControl } from "../../../../../controls/Buttons/TextButtonControl";
import SaveIcon from "@mui/icons-material/Save";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";
import { useAppSelector } from "../../../../../app/hooks/hooks";
import { selectCategoryId } from "../../../../../app/slices/publisherCategoriesSettingsSlice";

interface SaveChangesButtonProps {
  isEdited?: boolean;
  permissionMissing?: boolean;
  isLoading: boolean;
  isErrors: boolean;
  saveSettingsHandler: () => void;
}

export function SaveChangesButton(props: SaveChangesButtonProps) {
  const theme = useTheme();
  const categoryId = useAppSelector(selectCategoryId);

  const disableTooltipText = () => {
    switch (true) {
      case !categoryId:
        return TooltipMsg.ChooseCategoryFirst;
      case props.isErrors:
        return TooltipMsg.IsSomeValidationErrors;
      case props.permissionMissing:
        return TooltipMsg.NoAccessToCategory;
      case !props.isEdited:
        return TooltipMsg.NoChangesMade;
      default:
        return "";
    }
  };

  return (
    <TextButtonControl
      id={"document-settings-save-button"}
      colors={"primary"}
      height={theme.shapes.primaryButtonHeight}
      text={"Save changes"}
      onClick={props.saveSettingsHandler}
      isCompactView={false}
      isVertical={false}
      isVisible={true}
      disabled={
        !props.isEdited ||
        props.permissionMissing ||
        props.isLoading ||
        props.isErrors
      }
      disabledTooltipText={disableTooltipText()}
      icon={<SaveIcon fontSize="small" />}
    />
  );
}
