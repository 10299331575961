import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DialogControl } from "../../controls/Dialog/DialogControl";
import { StyledTableRow } from "./SC/StyledTableRow";
import { StyledTableCell } from "./SC/StyledTableCell";
import { useLazyGetAllDocumentKindsQuery } from "../../apis/documentKindsApi";
import { useCallback, useEffect } from "react";
import { CircularProgressBar } from "../../controls/ProgressIndicators/CircularProgressBar";
import { openSnackbar } from "../../app/helpers/snackBarHelper";
import { SnackbarType } from "../../models/snackbar";
import { useAppDispatch } from "../../app/hooks/hooks";
import { ErrorDisplay } from "../../app/errorHandling/ErrorDisplay";
import { sortBy } from "lodash";
import { SnackbarMsg } from "../../controls/Snackbar/SnackbarMessages";

interface DocumentKindsProps {
  open: boolean;
  selected: string;
  onClose: () => void;
}

export const DocumentKindsPopup = (props: DocumentKindsProps) => {
  const dispatch = useAppDispatch();
  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    props.onClose();
  };

  const [
    getAllDocumentKinds,
    {
      data: docKinds,
      isSuccess: isDocumentKindsLoaded,
      isError: isDocumentKindsError,
      isLoading: isDocumentKindsLoading,
      isUninitialized: isDocumentKindsUninitialized,
    },
  ] = useLazyGetAllDocumentKindsQuery();

  useEffect(() => {
    if (isDocumentKindsUninitialized && props.open) {
      void getAllDocumentKinds(null);
    }
  }, [getAllDocumentKinds, props.open, isDocumentKindsUninitialized]);

  const fetchDocumentKindsById = useCallback(() => {
    void getAllDocumentKinds(null);
  }, [getAllDocumentKinds]);

  useEffect(() => {
    if (isDocumentKindsError) {
      openSnackbar(
        dispatch,
        SnackbarMsg.DocumentKindsError,
        SnackbarType.error
      );
    }
  }, [dispatch, isDocumentKindsError]);

  return (
    <DialogControl
      id={"details-document-kinds"}
      isOpen={props.open}
      fullWidth
      maxWidth="md"
      useActions={false}
      title={"Document Kinds with descriptions"}
      content={
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell textAlign="left" header>
                  Name
                </StyledTableCell>
                <StyledTableCell textAlign="left" header>
                  Description
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {isDocumentKindsLoading && (
              <TableBody>
                <StyledTableRow striped={false}>
                  <StyledTableCell
                    textAlign="left"
                    component="th"
                    scope="row"
                    colSpan={2}
                    height={360}
                  >
                    <CircularProgressBar size={80} color={"primary"} />
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
            {isDocumentKindsError && (
              <TableBody>
                <StyledTableCell
                  textAlign="left"
                  component="th"
                  scope="row"
                  colSpan={2}
                  height={360}
                >
                  <ErrorDisplay
                    errorId={"details-all-document-kinds"}
                    doCenterHeader={true}
                    showDash={false}
                    refreshFunction={fetchDocumentKindsById}
                    showReloadButton={true}
                    errorMessageTitle="Something went wrong"
                    errorDescription="Error while loading document kinds dictionary data"
                  />
                </StyledTableCell>
              </TableBody>
            )}
            {isDocumentKindsLoaded && docKinds !== undefined && (
              <TableBody>
                {sortBy(docKinds, ["name"]).map((row, index) => (
                  <StyledTableRow key={row.name} striped={index % 2 != 0}>
                    <StyledTableCell
                      bolded={row.name === props.selected}
                      textAlign="left"
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell
                      bolded={row.name === props.selected}
                      textAlign="left"
                    >
                      {row.description}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      }
      onCloseClick={handleClose}
    />
  );
};
