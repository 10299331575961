import { useTheme } from "styled-components";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function CategoryTreeBoxStep2() {
  const theme = useTheme();

  return (
    <StepContentDiv>
      Categories can exist in two states:
      <ul>
        <li>
          <span style={{ color: theme.palettes.grayscale.medium }}>
            Gray, with a normal font
          </span>{" "}
          - these are categories in which you do not have the access rights to
          publish.
        </li>
        <li>
          <span style={{ fontWeight: "bold" }}>Black, with a bolded font</span>{" "}
          - these are categories in which you can publish or categories that
          contain a category in which you can publish. You can determine the type of category by checking the tooltip.
        </li>
      </ul>

    </StepContentDiv>
  );
}
