import { Typography } from "@mui/material";
import { DialogControl } from "../../../../../controls/Dialog/DialogControl";
import { StyledDialogContentText } from "../../../selfHelpPopup/SC/StyledDialogContentText";

interface DiscardPendingApprovalsProps {
  id: string;
  isOpen: boolean;
  onCloseClick: () => void;
  onConfirmClick: () => void;
}

export function DiscardPendingApprovalPopup(
  props: DiscardPendingApprovalsProps
) {
  return (
    <DialogControl
      fullWidth={true}
      id={props.id}
      title="This document has pending approvals"
      onCloseClick={props.onCloseClick}
      onConfirmClick={props.onConfirmClick}
      maxWidth="sm"
      useActions={true}
      isOpen={props.isOpen}
      content={
        <StyledDialogContentText id="alert-dialog-description">
          <Typography gutterBottom>
            There is at least one version of this document in &quot;In
            approval&quot; state. By publishing this document, these versions
            will be removed. Please &quot;Cancel&quot; if you wish to review
            them first or &quot;Publish anyway&quot; if you wish to proceed.
          </Typography>
        </StyledDialogContentText>
      }
      confirmText="Publish anyway"
      cancelText="Cancel"
    />
  );
}
