import { useEffect, useState } from "react";

interface RegexValidationProps {
  validationRegExp?: RegExp;
  validationMsg?: string;
  inputValue?: string;
}

export function useRegexValidation(props: RegexValidationProps) {
  const [isError, setIsError] = useState(false);
  const [helperText, setHelperText] = useState("");

  useEffect(() => {
    if (props.validationRegExp && props.validationMsg) {
      if (!props.inputValue || props.validationRegExp.test(props.inputValue)) {
        setIsError(false);
        setHelperText("");
      } else {
        setIsError(true);
        setHelperText(props.validationMsg);
      }
    }
  }, [props.inputValue, props.validationMsg, props.validationRegExp]);

  return { isError, helperText };
}
