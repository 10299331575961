import moment from "moment";
import { FooterDiv } from "./SC/FooterDiv";
import { FooterSpan } from "./SC/FooterSpan";

export function Footer() {
  return (
    <FooterDiv id="footer-div">
      <FooterSpan>© ABB, {moment().year()}</FooterSpan>
    </FooterDiv>
  );
}
