import { useState } from "react";
import { ComboBoxListItem } from "../../../../../../models/autocompleteValue";
import { Action } from "../../../../../../models/scheduledActionMessage";
import { DetailsAutoComplete } from "../../../../inputs/DetailsAutoComplete";
import { useScheduledActions } from "../useScheduledActions";
import { TooltipMsg } from "../../../../../../controls/Tooltips/TooltipMessages";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../app/hooks/hooks";
import {
  selectScheduledActionIsErrorVisible,
  setScheduledAction,
} from "../../../../../../app/slices/scheduledActionSlice";

interface ActionDropDownProps {
  action: Action | undefined;
  isEditable: boolean;
  canDelete: boolean;
  canArchive: boolean;
}

export function ActionDropDown(props: ActionDropDownProps) {
  const dispatch = useAppDispatch();
  const { getActionsPicker, findAction } = useScheduledActions();
  const isErrorVisible = useAppSelector(selectScheduledActionIsErrorVisible);

  const [selectedAction, setSelectedAction] = useState(
    findAction(props.action)
  );

  const onActionChange = (values: ComboBoxListItem[]) => {
    const selectedAction = values[0].key as Action;
    setSelectedAction(findAction(selectedAction));
    dispatch(setScheduledAction(selectedAction));
  };

  return (
    <DetailsAutoComplete
      id="scheduled-action-type-dropdown"
      labelText="Action"
      multiple={false}
      selected={
        selectedAction
          ? [
              {
                key: selectedAction,
                value: selectedAction,
              },
            ]
          : []
      }
      alwaysShowBorder={props.isEditable}
      items={getActionsPicker(props.canDelete, props.canArchive)}
      isEditable={props.isEditable}
      isDisabled={!props.isEditable}
      notEditableMessage={TooltipMsg.NoAccessToDocument}
      errorMessages={[TooltipMsg.EmptyScheduledAction]}
      isError={isErrorVisible && selectedAction === undefined}
      onChangeHandler={onActionChange}
      isEdited={false}
      noOptionText="Select action to schedule"
      $paddingTop={6}
    />
  );
}
