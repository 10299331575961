import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/description/ds_selected_doc_kind.png";
import { Screenshot } from "../../../SC/Screenshot";


export function DocumentKindSecondStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                In the Download Center, it functions as a filter that enables users to narrow down their search by selecting specific Document Kinds.
            </Paragraph>
            <Paragraph>
                In the Download Section, documents are grouped based on the document kinds used in them. Additionally, the Download Section can be customized to display specific Document Kinds.
            </Paragraph>
            <Paragraph>
                <Screenshot src={screenshot} />
            </Paragraph>
        </StepContentDiv>
    );
}
